import React, { FC } from "react";
import styled from "styled-components/native";
import { TextStyle, View, ViewStyle } from "react-native";
import { Text, Colors, opacify } from "@ailo/primitives";
import { getEmojiAndStatusMessage } from "./getEmojiAndStatusMessage";
import { DueDate } from "local/domain/ledger";
import { DepositData } from "../../../TenancyDepositCard/parseDepositData";
interface Props {
  style: ViewStyle;
  dueDate: string;
  paidToDate: string;
  terminationDate: string | null | undefined;
  autoPaymentEnabled: boolean;
  deposit?: DepositData;
}

interface Statics {
  Loading: FC<{ style?: ViewStyle }>;
}

const RentStatusBoxContainer = styled(View)`
  padding: 16px;
  background: ${Colors.WHITE};
  border: 1px solid ${opacify(Colors.SPACE_BLACK, 0.1)};
  border-radius: 12px;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const RentStatusBox: FC<Props> & Statics = ({
  style,
  dueDate,
  paidToDate,
  terminationDate,
  autoPaymentEnabled,
  deposit
}) => {
  const inArrears = new DueDate(paidToDate).isOverdue();

  if (!inArrears && autoPaymentEnabled && !deposit?.isPaidOrPending)
    return null;

  const { emoji, status } = getEmojiAndStatusMessage(
    dueDate,
    paidToDate,
    terminationDate,
    deposit
  );

  const statusStyle: TextStyle = deposit?.isPaidOrPending
    ? { textAlign: "center" }
    : {};

  return (
    <RentStatusBoxContainer style={style}>
      {!!emoji && (
        <View style={{ marginRight: 16 }}>
          <Text.Emoji>{emoji}</Text.Emoji>
        </View>
      )}
      <View style={{ flexShrink: 1 }}>
        <Text.BodyS style={statusStyle}>{status}</Text.BodyS>
      </View>
    </RentStatusBoxContainer>
  );
};

const Loading: FC<{ style?: ViewStyle }> = ({ style }) => (
  <RentStatusBoxContainer style={style}>
    <View style={{ padding: 2 }}>
      <Text.BodyS.Loading width={160} />
    </View>
  </RentStatusBoxContainer>
);
RentStatusBox.Loading = Loading;

export { RentStatusBox };
