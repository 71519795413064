import React, { FC } from "react";
import { View } from "react-native";
import { Colors, EditIcon } from "@ailo/primitives";
import { ListItemHeader, ListItemAction } from "@ailo/ui";
import { PaymentMethodListItem } from "@ailo/domains";
import { Screens, useNavigation, useNavigateToThisScreen } from "local/common";

interface Props {
  paymentMethodId: string;
  showOtherPaymentOptions?: boolean;
  onPaymentMethodChanged?: (paymentMethodId: string) => void;
  showAccountName?: boolean;
}

interface Statics {
  Loading: FC;
}

const PaymentMethodDisplay: FC<Props> & Statics = ({
  paymentMethodId,
  onPaymentMethodChanged,
  showOtherPaymentOptions,
  showAccountName
}) => {
  const navigation = useNavigation();

  const returnToThisScreen = useNavigateToThisScreen();

  const onPressChange = onPaymentMethodChanged
    ? (): void => {
        navigation.navigate(Screens.ChangePaymentMethod, {
          onClose: returnToThisScreen,
          onSuccess: onPaymentMethodChanged,
          showOtherPaymentOptions
        });
      }
    : undefined;

  return (
    <View testID={"payment-method-view"}>
      <ListItemHeader
        text={"Payment Method"}
        style={{ backgroundColor: Colors.CLOUD }}
      />
      <PaymentMethodListItem
        paymentMethodId={paymentMethodId}
        rightComponent={
          onPressChange && (
            <ListItemAction
              label={"Change"}
              icon={EditIcon}
              onPress={onPressChange}
              accessibilityLabel={"change-payment-method-button"}
            />
          )
        }
        showAccountName={showAccountName}
      />
    </View>
  );
};

const Loading: FC = () => {
  return (
    <View>
      <ListItemHeader style={{ marginTop: 12 }} text={"Payment Method"} />
      <View style={{ marginTop: 8 }} />
      <PaymentMethodListItem.Loading />
    </View>
  );
};

PaymentMethodDisplay.Loading = Loading;

export { PaymentMethodDisplay };
