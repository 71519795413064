import React, { FC } from "react";
import styled from "styled-components/native";
import { Button, Money } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";

const PayNowButton: FC<PayNowButtonProps> = ({ liabilityId, amount }) => {
  const navigation = useNavigation<Screens.TenancyDepositKeyAction>();
  const navigateToPaymentFlow = (): void =>
    navigation.navigate(Screens.TenancyDepositAddPaymentMethod, {
      liabilityId,
      amount
    });

  return <PayButton onPress={navigateToPaymentFlow}>{"Pay Now"}</PayButton>;
};

interface PayNowButtonProps {
  liabilityId: string;
  amount: Money;
}

const PayButton = styled(Button)`
  border-radius: 30px;
  min-height: 52px;
  margin: 16px 0px 40px;
`;

export { PayNowButton };
