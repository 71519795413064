import React from "react";
import { SFC } from "@ailo/primitives";
import styled from "styled-components/native";
import { Button } from "@ailo/ui";
import { useCompleteOnboardingTask } from "local/domain/onboarding";
import { OnboardingTaskId } from "local/graphql";
import { useSetupNotifications } from "@ailo/domains";

export const AllowNotificationsButton: SFC = ({ style }) => {
  const completeOnboardingTask = useCompleteOnboardingTask();
  const { registerPushNotificationToken, grantPushNotificationPermission } =
    useSetupNotifications();

  const onPress = (): void => {
    grantPushNotificationPermission().then(registerPushNotificationToken);
    completeOnboardingTask(OnboardingTaskId.AllowPushNotifications);
  };

  return (
    <StyledButton type={"large"} style={style} onPress={onPress}>
      {"Allow notifications"}
    </StyledButton>
  );
};

const StyledButton = styled(Button.Primary)`
  margin-left: 40px;
  margin-right: 40px;
`;
