import { RightChevronIcon } from "@ailo/primitives";
import { useEnvironment, withScreenComponent } from "@ailo/services";
import { ListItem } from "@ailo/ui";
import * as WebBrowser from "expo-web-browser";
import { Screens, useNavigation } from "local/common";
import React, { useCallback } from "react";

const AboutScreen = withScreenComponent((): React.ReactElement => {
  const navigation = useNavigation();

  const { PRIVACY_POLICY_URL } = useEnvironment();
  const privacyPress = useCallback(() => {
    WebBrowser.openBrowserAsync(PRIVACY_POLICY_URL);
  }, [PRIVACY_POLICY_URL]);

  const navigateToToSScreen = useCallback(() => {
    navigation.navigate(Screens.TermsOfServiceView);
  }, [navigation]);

  return (
    <>
      <ListItem
        header={"Terms of Service"}
        onPress={navigateToToSScreen}
        rightComponent={<RightChevronIcon width={20} height={20} />}
      />
      <ListItem
        header={"Privacy Policy"}
        onPress={privacyPress}
        rightComponent={<RightChevronIcon width={20} height={20} />}
      />
    </>
  );
});

export { AboutScreen };
