import { TypedStackNavigator } from "@react-navigation/stack";
import { AppNavigatorParamList, Screens } from "local/common";
import React from "react";
import { PaidBillsScreen } from "./PaidBillsScreen";
import { UpcomingBillsScreen } from "./UpcomingBillsScreen";

export const getBillsTabModalScreens = (
  Stack: TypedStackNavigator<AppNavigatorParamList>
): React.ReactElement => {
  return (
    <>
      <Stack.Screen
        name={Screens.UpcomingBills}
        component={UpcomingBillsScreen}
        options={{ title: "Outstanding" }}
      />
      <Stack.Screen
        name={Screens.PaidBills}
        component={PaidBillsScreen}
        options={{ title: "Paid" }}
      />
    </>
  );
};
