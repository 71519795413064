import React from "react";
import {
  Colors,
  LearnMoreBiggerGraphics,
  opacify,
  Text
} from "@ailo/primitives";
import { View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { FloatingClouds } from "local/common";

export const Heading: React.FC = () => {
  const colors = [
    opacify(Colors.WHITE, 0),
    opacify("rgb(110, 143, 203)", 0.15)
  ];

  return (
    <View
      style={{
        paddingTop: 64
      }}
    >
      <Text.Subheading
        weight={"medium"}
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginBottom: 8, textAlign: "center" }}
      >
        {"Getting Started"}
      </Text.Subheading>
      <Text.DisplayL style={{ paddingBottom: 56, textAlign: "center" }}>
        {"Get to know Ailo"}
      </Text.DisplayL>
      <LinearGradient colors={colors} style={{ alignItems: "center" }}>
        <FloatingClouds
          style={{
            left: 0,
            right: 0,
            top: 0,
            position: "absolute",
            zIndex: -1
          }}
        />

        <LearnMoreBiggerGraphics />
      </LinearGradient>
    </View>
  );
};
