import React, { FC } from "react";
import { ScreenComponent } from "@ailo/services";
import { SpinnerOverlay } from "@ailo/ui";
import { Colors } from "@ailo/primitives";

const LoadingScreen: FC = () => {
  return (
    <ScreenComponent>
      <SpinnerOverlay style={{ backgroundColor: Colors.WHITE }} />
    </ScreenComponent>
  );
};

export { LoadingScreen };
