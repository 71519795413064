import React, { FC } from "react";
import styled from "styled-components/native";
import { useRoute, Screens, ScreenComponent } from "local/common";
import { WalletOwnerTransactionsList } from "@ailo/domains";

const AllInvestorTransactionsScreen: FC = () => {
  const { ownerRef } = useRoute<Screens.AllInvestorTransactions>().params;

  return (
    <Container>
      <WalletOwnerTransactionsList ownerRef={ownerRef} />
    </Container>
  );
};

const Container = styled(ScreenComponent)`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export { AllInvestorTransactionsScreen };
