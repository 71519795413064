import { GetTenancyLiabilityQuery } from "local/graphql";
import { Money, MoneyInterface } from "@ailo/ui";

export function parseLiability(
  data?: GetTenancyLiabilityQuery
): LiabilityData | undefined {
  const liability = data?.tenancy?.liability;
  if (
    !liability ||
    !liability.paidTo ||
    !liability.dueDate ||
    !liability.nextDueDate ||
    !liability.nextDueAmount
  ) {
    return undefined;
  }

  const autoPaymentEnabled = Boolean(
    data?.tenancy?.liability?.autoPaymentDetails
  );
  const paymentMethodId =
    data?.tenancy?.liability?.autoPaymentDetails?.paymentMethod?.id;

  const liabilityPaymentPlansEnabled =
    (data?.tenancy?.liability?.paymentPlans?.pageInfo?.total || 0) > 0;

  const onDueAutoPaymentEnabled = !!(autoPaymentEnabled && paymentMethodId);

  return {
    liabilityId: liability.id,
    paidTo: liability.paidTo,
    overdueAmount: Money.from(liability.overdueAmount),
    paidToDate: liability.paidTo,
    dueDate: liability.dueDate,
    nextDueAmount: Money.from(liability.nextDueAmount),
    nextDueDate: liability.nextDueDate,
    terminationAmountDue: orMoney(liability?.terminationAmountDue),
    terminationDate: liability?.terminationDate,
    onDueAutoPaymentEnabled: !!(autoPaymentEnabled && paymentMethodId),
    anyAutoPaymentEnabled:
      onDueAutoPaymentEnabled || liabilityPaymentPlansEnabled
  };
}

function orMoney(amount?: MoneyInterface | null): Money | undefined {
  return amount ? Money.from(amount) : undefined;
}

export interface LiabilityData {
  liabilityId: string;
  paidTo: string;
  overdueAmount: Money;
  paidToDate: string;
  dueDate: string;
  nextDueAmount: Money;
  nextDueDate: string;
  terminationAmountDue?: Money;
  terminationDate?: string | null;
  onDueAutoPaymentEnabled: boolean;
  anyAutoPaymentEnabled: boolean;
}
