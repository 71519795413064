import { alert, Button } from "@ailo/ui";
import { Screens, useCurrentLegalEntity, useNavigation } from "local/common";
import React, { FC, useEffect } from "react";
import {
  useSetupAutoPayRentMutation,
  GetRentLiabilitiesWithAutoPaymentDetailsAndAddressDocument
} from "local/graphql";
import { useAutoPayAnalytics } from "local/domain/liabilityPaymentPlan";

interface Props {
  paymentMethodId: string;
  liabilityId: string;
  autoPaymentEnabled: boolean | undefined;
  onSuccess: () => void;
}

const SaveAutoPayRentButton: FC<Props> = ({
  paymentMethodId,
  liabilityId,
  autoPaymentEnabled,
  onSuccess
}) => {
  const navigation = useNavigation();
  const analytics = useAutoPayAnalytics();
  const [legalEntity] = useCurrentLegalEntity();

  const [setupAutoPayRent, { loading }] = useSetupAutoPayRentMutation({
    variables: { liabilityId, paymentMethodId },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GetRentLiabilitiesWithAutoPaymentDetailsAndAddressDocument,
        variables: { payerLegalEntityId: legalEntity.id.toString() }
      }
    ]
  });

  useEffect(() => {
    if (loading) {
      navigation.setParams({ showLoadingOverlay: loading });
    }
  }, [loading, navigation]);

  const onSubmit = (): void => {
    setupAutoPayRent()
      .then(({ data }) => {
        const status = data?.createUpdateAutoPay?.status;
        switch (status) {
          case "SUCCESS":
            if (autoPaymentEnabled) {
              onSuccess();
            } else {
              analytics.track(true, "When Rent Is Due", liabilityId);
              navigation.navigate(Screens.SetupAutoPayRentSuccess, {
                onClose: onSuccess
              });
            }
            break;
          case "UNAUTHORIZED":
            alert(
              "Unauthorised",
              "You don’t have permission to process this transaction. Please check your permissions and try again",
              [{ text: "Dismiss" }]
            );
            navigation.setParams({ showLoadingOverlay: false });
            break;
          case "FAIL":
          default:
            throw new Error("setting up auto rent payment failed");
        }
      })
      .catch(() => {
        alert(
          "Error",
          "We were unable to save your automatic payment. Please try again and if the problem persists, contact Ailo support",
          [{ text: "Dismiss" }]
        );
        navigation.setParams({ showLoadingOverlay: false });
      });
  };

  return (
    <Button.Primary onPress={onSubmit} disabled={loading}>
      {autoPaymentEnabled ? "Save Changes" : "Save"}
    </Button.Primary>
  );
};

export { SaveAutoPayRentButton };
