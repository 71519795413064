import {
  GetPaymentMethodWithFeesQuery,
  useGetPaymentMethodWithFeesQuery
} from "local/graphql";
import { FeeInfo } from "@ailo/domains";
import { extractFeeInfo } from "local/domain/ledger";

interface PaymentMethodWithFees {
  loading: boolean;
  data: GetPaymentMethodWithFeesQuery | undefined;
  topUpFee: FeeInfo | undefined;
}

export const useGetPaymentMethodWithFees = (
  paymentMethodId: string
): PaymentMethodWithFees => {
  const { data, loading } = useGetPaymentMethodWithFeesQuery({
    variables: { paymentMethodId }
  });

  const topUpFee = loading ? undefined : extractFeeInfo(data, paymentMethodId);

  return { loading, data, topUpFee };
};
