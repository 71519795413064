import React, { ReactElement } from "react";
import { Screens, useRoute } from "local/common";
import { MessageScreen, useWatchChatNavigationParams } from "@ailo/domains";
import { useEnsureMessageScreenCanGoBack } from "local/tabs";

export function ConsumerMessageScreen(): ReactElement {
  const { threadId } = useRoute<Screens.Messages>().params || {};
  useWatchChatNavigationParams({ params: { threadId } });

  useEnsureMessageScreenCanGoBack();

  return <MessageScreen />;
}
