import React from "react";
import { OnboardingTaskId } from "local/graphql";
import { OnboardingTask } from "./types";

export interface OnboardingContextValue {
  completeOnboardingTaskIds: OnboardingTaskId[];
  incompleteOnboardingTasks: OnboardingTask[];
  cacheCompleteOnboardingTask: (onboardingTaskId: OnboardingTaskId) => void;
}

export const OnboardingContext = React.createContext<OnboardingContextValue>({
  completeOnboardingTaskIds: [],
  incompleteOnboardingTasks: [],
  cacheCompleteOnboardingTask() {
    throw new TypeError(`OnboardingContext needs to be provided`);
  }
});

export function useOnboardingContext(): OnboardingContextValue {
  return React.useContext(OnboardingContext);
}

export function useIsInOnboardingContext(): boolean {
  const { incompleteOnboardingTasks } = useOnboardingContext();
  return incompleteOnboardingTasks.length > 0;
}
