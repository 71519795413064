import React, { FC, useCallback } from "react";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import { Screens, useNavigation, useRoute } from "local/common";
import { Alert, ErrorAlertScreen, ListItem, SpinnerOverlay } from "@ailo/ui";
import { View } from "react-native";
import { useAutoPayLiabilityStatusForm } from "../../../profile/SetupAutoPayBillsScreen/useAutoPayLiabilityStatusForm";
import {
  didMutationFail,
  didQueryNotLoadYet,
  didQuerySucceed,
  logApolloResultFailed
} from "@ailo/services";
import { Text } from "@ailo/primitives";

const ConfirmAutoPayBillsScreen: FC = () => {
  const navigation =
    useNavigation<Screens.AutoWithdrawPlanConfirmAutoPayBills>();
  const params = useRoute<Screens.AutoWithdrawPlanConfirmAutoPayBills>().params;

  const { statusResult, submitResult, submitCalledWith, submit } =
    useAutoPayLiabilityStatusForm(params.walletOwnerRef);

  const loading = didQueryNotLoadYet(statusResult) || submitResult.loading;

  const onSuccess = useCallback(
    (): void =>
      navigation.replace(Screens.AutoWithdrawPlanConfirmDetails, params || {}),
    [navigation, params]
  );

  if (!didQuerySucceed(statusResult)) {
    logApolloResultFailed(statusResult, {
      operationId: "SelectStartDateScreen.statusResult"
    });
    return (
      <ErrorAlertScreen variant={"large"} onRetry={statusResult.refetch} />
    );
  }

  if (didMutationFail(submitResult)) {
    logApolloResultFailed(submitResult, {
      operationId: "SetupAutoPayBillsScreenContent.submitResult"
    });
    return (
      <ErrorAlertScreen
        variant={"large"}
        title={"There was a problem loading\nSetup Auto Bill Payments"}
        onRetry={(): void =>
          submit({ data: submitCalledWith!, skipConfirmAlert: true })
        }
      />
    );
  }

  if (!params.walletOwnerRef) {
    return <Alert type={"error"} message={"Wallet owner missing"} />;
  }

  const onYesSelected = (): void => {
    navigation.navigate(Screens.ManageAutoPayBills, {
      ...params,
      submitButtonLabel: "Next",
      onSuccess,
      payerId: params.walletOwnerRef
    });
  };

  const onNoSelected = async (): Promise<void> => {
    if (statusResult.data?.enabled) {
      await submit({
        data: { enabled: false }
      });
    }
    navigation.navigate(Screens.AutoWithdrawPlanConfirmDetails, {
      ...params
    });
  };

  return (
    <>
      <ScreenWithHeaderAndAction
        header={"Would you like to turn on automatic bill payments?"}
        subHeader={
          "Automatically pay your bills before your transfer is made to your bank account"
        }
      >
        <ListItem
          key={"Yes"}
          header={<Text.DisplayM weight={"medium"}>{"Yes"}</Text.DisplayM>}
          style={{ paddingHorizontal: 0 }}
          leftComponent={<View style={{ width: 16 }} />}
          onPress={onYesSelected}
        />
        <ListItem
          key={"No"}
          header={<Text.DisplayM weight={"medium"}>{"No"}</Text.DisplayM>}
          style={{ paddingHorizontal: 0 }}
          leftComponent={<View style={{ width: 16 }} />}
          onPress={onNoSelected}
        />
      </ScreenWithHeaderAndAction>
      {loading && <SpinnerOverlay style={{ marginTop: 24 }} />}
    </>
  );
};

export { ConfirmAutoPayBillsScreen };
