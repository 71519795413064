import React, { FC } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Text, Colors } from "@ailo/primitives";

const ErrorScreen: FC = () => {
  return (
    <Container>
      <HeaderText>{"Sorry"}</HeaderText>
      <BodyText>
        {
          "An unexpected error has occurred.\nWe’ve been notified and will investigate."
        }
      </BodyText>
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
`;

const HeaderText = styled(Text.DisplayM)`
  color: ${Colors.TEXT.DARK.PRIMARY};
  text-align: center;
`;

const BodyText = styled(Text.BodyM)`
  color: ${Colors.TEXT.DARK.SECONDARY};
  text-align: center;
  margin-top: 8px;
`;

export { ErrorScreen };
