import { AiloRN } from "@ailo/ailorn";
import { Property } from "local/common";
import { reverse, sortBy } from "lodash";
import { isPresent } from "ts-is-present";
import { SetupData } from "../SetupData";

export function createPropertiesFromSetupData(
  setupData: SetupData
): Property[] {
  const legalEntities = [setupData.person, ...setupData.companies];
  const legalEntitiesIdsAsString = legalEntities.map((c) => c.id.toString());

  const rentedProperties = setupData.tenancies.items
    .filter(isPresent)
    .map((tenancy) => {
      if (!tenancy.property) {
        throw new TypeError(`tenancy.property is empty`);
      }

      if (!tenancy.managingEntity?.organisationId) {
        throw new TypeError(`tenancy.managingEntity.organisationId is empty`);
      }

      const tenantship = tenancy.tenantships.items.find((tenantship) =>
        legalEntitiesIdsAsString.includes(tenantship.tenantId)
      );
      if (!tenantship) {
        throw new TypeError(
          `Tenancy does not include tenantship for any of the current user's legal entities`
        );
      }
      const userLegalEntity = legalEntities.find(
        (le) => le.id.toString() === tenantship.tenantId
      )!;

      const managementFolioAilorn = AiloRN.from(tenancy.managementFolioAilorn, {
        expected: "propertymanagement:managementfolio"
      });

      return {
        __typename: "RentedProperty" as const,
        id: AiloRN.of("propertymanagement:property", tenancy.property.id),
        address: tenancy.property.address!,
        userLegalEntity,
        tenancy: {
          id: AiloRN.from(tenancy.ailoRN!, {
            expected: "propertymanagement:tenancy"
          }),
          startDate: tenancy.startDate ?? undefined,
          endDate: tenancy.endDate ?? undefined
        },
        management: {
          id: AiloRN.from(tenancy.managementAiloRN, {
            expected: "propertymanagement:management"
          })
        },
        managementFolio: {
          id: managementFolioAilorn.internalId,
          ailorn: managementFolioAilorn
        },
        tenantship: {
          tenantId: AiloRN.from(tenantship.tenantId, {
            expected: "authz:legalentity"
          })
        },
        agency: {
          organisationAilorn: AiloRN.of(
            "authz:organisation",
            tenancy.managingEntity.organisationId
          ),
          organisationName: tenancy.managingEntity.organisation.name,
          timeZone: tenancy.managingEntity.timezone
        }
      };
    });

  const ownedProperties = setupData.managements.items
    .filter(isPresent)
    .map((management) => {
      if (!management.property) {
        throw new TypeError("management.property is empty");
      }

      if (!management.managingEntity?.organisationId) {
        throw new TypeError(
          `management.managingEntity.organisationId is empty`
        );
      }

      const lastAgreement = reverse(
        sortBy([...(management.managementAgreements?.items ?? [])], (item) =>
          item.startDate ? new Date(item.startDate) : undefined
        )
      )[0];

      const ownership = management.ownerships.find((ownership) =>
        legalEntitiesIdsAsString.includes(ownership.ownerId)
      );
      if (!ownership) {
        throw new TypeError(
          "Management does not include ownership for any of the current user's legal entities"
        );
      }
      const userLegalEntity = legalEntities.find(
        (le) => le.id.toString() === ownership.ownerId
      )!;

      const sharesTotal = management.ownerships
        .map((ownership) => ownership.sharesOwned)
        .reduce((a, b) => a + b, 0);
      const sharesOwned = ownership.sharesOwned;
      const sharesOwnedRatio = sharesTotal ? sharesOwned / sharesTotal : 0;

      return {
        __typename: "OwnedProperty" as const,
        id: AiloRN.of("propertymanagement:property", management.property.id),
        address: management.property.address!,
        userLegalEntity,
        management: {
          id: AiloRN.of("propertymanagement:management", management.id),
          lastAgreementStartDate: lastAgreement?.startDate ?? undefined,
          endDate: management.endDate ?? undefined
        },
        managementFolio: {
          id: management.managementFolio.id,
          ailorn: AiloRN.from(management.managementFolio.ailorn, {
            expected: "propertymanagement:managementfolio"
          })
        },
        ownership: {
          ownerId: AiloRN.of("authz:legalentity", ownership.ownerId),
          sharesOwnedRatio
        },
        agency: {
          organisationAilorn: AiloRN.of(
            "authz:organisation",
            management.managingEntity.organisationId
          ),
          organisationName: management.managingEntity.organisation.name,
          timezone: management.managingEntity.timezone
        }
      };
    });

  return [...rentedProperties, ...ownedProperties];
}
