import { useIsMountedRef } from "@ailo/primitives";
import {
  AiloSentry,
  useApolloClient,
  useAuth,
  useUserStorage
} from "@ailo/services";
import { isPermissionUndetermined } from "@ailo/ui";
import {
  OnboardingContext,
  OnboardingContextValue,
  OnboardingTask
} from "local/domain/onboarding";
import {
  OnboardingTaskId,
  TenancyDepositStatus,
  useGetTenancyDepositQuery,
  GetUserOnboardingStateDocument,
  GetUserOnboardingStateQuery,
  useCompleteOnboardingTaskMutation,
  PlatformFeatureId
} from "local/graphql";
import {
  RentedProperty,
  Screens,
  usePropertySelectorContext
} from "local/common";
import { remove } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import usePromise from "react-promise";
import { getOnboardingData } from "./mapQueryToOnboardingData";
import { Platform } from "react-native";
import { useHasFeature } from "@ailo/domains";

export interface OnboardingContextProviderProps {
  renderLoading?(): React.ReactElement;
  children: React.ReactNode;
}

const ONBOARDING_TASKS: OnboardingTask[] = [
  {
    taskId: OnboardingTaskId.AcceptTermsOfService,
    screen: Screens.TermsOfServiceAccept,
    storedRemotely: true
  },
  {
    taskId: OnboardingTaskId.CompleteAiloOverview,
    screen: Screens.AiloOverview,
    storedRemotely: true
  },
  {
    taskId: OnboardingTaskId.VerifyPhoneNumber,
    screen: Screens.SecureYourAccount,
    storedRemotely: true
  },
  {
    taskId: OnboardingTaskId.AddPaymentMethod,
    screen: Screens.AddPaymentMethodKeyAction,
    storedRemotely: true
  },
  {
    taskId: OnboardingTaskId.SetupAutoRentPayment,
    screen: Screens.SetupAutoRentPaymentKeyAction,
    storedRemotely: true
  },
  {
    taskId: OnboardingTaskId.AllowPushNotifications,
    screen: Screens.AllowNotificationsKeyAction,
    storedRemotely: false
  },
  {
    taskId: OnboardingTaskId.PayTenancyDeposit,
    screen: Screens.TenancyDepositKeyAction,
    storedRemotely: true
  }
];

const ONBOARDING_STATE_STORAGE_KEY =
  "OnboardingContextProvider.completeOnboardingTasks";

export function OnboardingContextProvider({
  renderLoading,
  children
}: OnboardingContextProviderProps): React.ReactElement | null {
  const isMountedRef = useIsMountedRef();
  const { isAuthenticated } = useAuth();

  const userStorage = useUserStorage();
  const client = useApolloClient();
  const [completeOnboardingTaskMutation] = useCompleteOnboardingTaskMutation();
  const [completedOnboardingTaskIds, setCompleteOnboardingTaskIds] = useState<
    OnboardingTaskId[] | undefined
  >();
  const {
    hasDeposit: hasTenancyDeposit,
    hasUnpaidDeposit: hasUnpaidTenancyDeposit,
    loading: depositLoading
  } = useHasUnpaidDeposit();

  const {
    value: shouldAskForNotifications,
    loading: checkingForNotificationsPermission
  } = usePromise(
    useCallback(() => isPermissionUndetermined("notifications"), [])
  );

  const depositsFeatureDisabled = !useHasFeature(PlatformFeatureId.Deposits);

  const loadUserData = useCallback(async (): Promise<OnboardingTaskId[]> => {
    if (!isAuthenticated) throw new Error("Not Authenticated");

    const cachedCompleteOnboardingTasks = await userStorage.getItem<string>(
      ONBOARDING_STATE_STORAGE_KEY
    );

    const { data } = await client.query<GetUserOnboardingStateQuery>({
      query: GetUserOnboardingStateDocument,
      fetchPolicy: "no-cache"
    });

    const {
      isImpersonating,
      isTenant,
      isInvestor,
      notificationPrimingEnabled,
      hasBankAccount,
      hasCreditCard,
      phoneNoVerified,
      completedOnboardingTasks
    } = getOnboardingData(data);
    if (isImpersonating) return [];

    const shouldNotSetupAutoPayment = !isTenant || hasTenancyDeposit;
    if (
      shouldNotSetupAutoPayment &&
      !completedOnboardingTasks.includes(OnboardingTaskId.SetupAutoRentPayment)
    ) {
      completedOnboardingTasks.push(OnboardingTaskId.SetupAutoRentPayment);
      completeOnboardingTaskMutation({
        variables: { onboardingTaskId: OnboardingTaskId.SetupAutoRentPayment }
      });
    }

    const nonInvestorAndHasPaymentMethod =
      !isInvestor && (hasCreditCard || hasBankAccount);

    const shouldNotAddPaymentMethod =
      isInvestor || nonInvestorAndHasPaymentMethod || hasTenancyDeposit;
    if (
      shouldNotAddPaymentMethod &&
      !completedOnboardingTasks.includes(OnboardingTaskId.AddPaymentMethod)
    ) {
      completedOnboardingTasks.push(OnboardingTaskId.AddPaymentMethod);
      completeOnboardingTaskMutation({
        variables: { onboardingTaskId: OnboardingTaskId.AddPaymentMethod }
      });
    }

    if (
      phoneNoVerified &&
      !completedOnboardingTasks.includes(OnboardingTaskId.VerifyPhoneNumber)
    ) {
      completedOnboardingTasks.push(OnboardingTaskId.VerifyPhoneNumber);
      completeOnboardingTaskMutation({
        variables: { onboardingTaskId: OnboardingTaskId.VerifyPhoneNumber }
      });
    }

    if (
      depositsFeatureDisabled ||
      (!depositLoading &&
        !hasUnpaidTenancyDeposit &&
        !completedOnboardingTasks.includes(OnboardingTaskId.PayTenancyDeposit))
    ) {
      completedOnboardingTasks.push(OnboardingTaskId.PayTenancyDeposit);
      completeOnboardingTaskMutation({
        variables: { onboardingTaskId: OnboardingTaskId.PayTenancyDeposit }
      });
    }

    if (
      cachedCompleteOnboardingTasks?.includes(
        OnboardingTaskId.AllowPushNotifications
      ) &&
      !completedOnboardingTasks.includes(
        OnboardingTaskId.AllowPushNotifications
      )
    ) {
      completedOnboardingTasks.push(OnboardingTaskId.AllowPushNotifications);
    }

    if (
      !notificationPrimingEnabled ||
      (!checkingForNotificationsPermission && !shouldAskForNotifications) ||
      Platform.OS === "web"
    ) {
      remove(
        ONBOARDING_TASKS,
        ({ taskId }) => taskId === OnboardingTaskId.AllowPushNotifications
      );
    }

    if (isMountedRef.current)
      setCompleteOnboardingTaskIds(completedOnboardingTasks);

    return completedOnboardingTasks;
  }, [
    checkingForNotificationsPermission,
    client,
    completeOnboardingTaskMutation,
    isAuthenticated,
    isMountedRef,
    shouldAskForNotifications,
    userStorage,
    hasTenancyDeposit,
    hasUnpaidTenancyDeposit,
    depositLoading,
    depositsFeatureDisabled
  ]);

  const { loading: dataLoading, error: dataError } = usePromise(loadUserData);

  const incompleteOnboardingTasks = useMemo(() => {
    if (!completedOnboardingTaskIds) return [];

    return ONBOARDING_TASKS.filter(
      ({ taskId }) => !completedOnboardingTaskIds.includes(taskId)
    );
  }, [completedOnboardingTaskIds]);

  useEffect(() => {
    if (!completedOnboardingTaskIds) return;

    userStorage.setItem(
      ONBOARDING_STATE_STORAGE_KEY,
      completedOnboardingTaskIds
    );
  }, [completedOnboardingTaskIds, userStorage]);

  useEffect(() => {
    if (!dataError) return;
    AiloSentry.captureMessage(
      `Failed to get onboarding data: ${
        dataError.message || String(dataError)
      }`,
      {
        severity: AiloSentry.Severity.Warning,
        extras: {
          originalError: dataError
        }
      }
    );
  }, [dataError]);

  const contextValue: OnboardingContextValue = useMemo(() => {
    function cacheCompleteOnboardingTask(
      onboardingTaskId: OnboardingTaskId
    ): void {
      if (!completedOnboardingTaskIds)
        throw new Error(
          "Onboarding Context provider is not fully initialised yet"
        );

      if (!completedOnboardingTaskIds.includes(onboardingTaskId)) {
        if (isMountedRef.current) {
          setCompleteOnboardingTaskIds([
            ...completedOnboardingTaskIds,
            onboardingTaskId
          ]);
        }
      }
    }

    return {
      completeOnboardingTaskIds: completedOnboardingTaskIds || [],
      incompleteOnboardingTasks,
      cacheCompleteOnboardingTask
    };
  }, [completedOnboardingTaskIds, incompleteOnboardingTasks, isMountedRef]);

  if (dataLoading) {
    return renderLoading?.() ?? null;
  }

  return (
    <OnboardingContext.Provider value={contextValue}>
      {children}
    </OnboardingContext.Provider>
  );
}

function useHasUnpaidDeposit(): {
  hasDeposit: boolean;
  hasUnpaidDeposit: boolean;
  loading: boolean;
} {
  const { currentProperty } = usePropertySelectorContext();
  const currentPropertyIsRented =
    currentProperty?.__typename === "RentedProperty";
  const tenancyId = currentPropertyIsRented
    ? (currentProperty as RentedProperty)?.tenancy?.id.internalId
    : undefined;

  const { data, loading } = useGetTenancyDepositQuery({
    variables: tenancyId ? { tenancyId } : undefined,
    skip: !tenancyId
  });
  const hasDeposit = !!data?.tenancy?.deposit;
  let hasUnpaidDeposit = false;
  if (data?.tenancy?.deposit) {
    hasUnpaidDeposit = [
      TenancyDepositStatus.New,
      TenancyDepositStatus.Due,
      TenancyDepositStatus.Failed
    ].includes(data?.tenancy?.deposit.status);
  }
  return { hasDeposit, hasUnpaidDeposit, loading };
}
