import { useHasFeature, FeeInfo, FeeUIHelpers } from "@ailo/domains";
import { Button } from "@ailo/ui";
import { toCents } from "local/domain/ledger";
import { Money, PlatformFeatureId } from "local/graphql";
import React, { FC } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { View } from "react-native";
import { FeesDisclaimerDisplay } from "../FeesDisclaimerDisplay";
import { PaymentAmountInputField } from "./form";

interface PayLiabilityFormData {
  paymentAmount: string;
}

interface Props {
  payLiability: (amountWithoutFees: Money, amountWithFees: Money) => void;
  topUpFee?: FeeInfo;
  loading: boolean;
}

interface Statics {
  Loading: FC;
}

const EditablePaymentDisplay: FC<Props> & Statics = ({
  payLiability,
  topUpFee,
  loading
}) => {
  const formMethods = useForm<PayLiabilityFormData>({
    mode: "onChange",
    shouldFocusError: true
  });

  const { handleSubmit, watch } = formMethods;
  const paymentAmount = watch("paymentAmount");

  const enableTransactionFees =
    useHasFeature(PlatformFeatureId.TransactionFees) && topUpFee;

  const feesAppliedCentsAmount = FeeUIHelpers.getFeeAppliedAmount(
    enableTransactionFees ? topUpFee : undefined,
    paymentAmount
  );

  const feesAppliedDollarAmount = FeeUIHelpers.format(feesAppliedCentsAmount);

  const onSubmit = handleSubmit(
    ({ paymentAmount }: { paymentAmount: string }) => {
      const amountWithoutFees = { cents: toCents(paymentAmount) };
      const amountWithFees = { cents: feesAppliedCentsAmount };
      payLiability(amountWithoutFees, amountWithFees);
    }
  );

  return (
    <View style={{ marginTop: 16 }}>
      <FormProvider {...formMethods}>
        <PaymentAmountInputField
          accessibilityLabel={"rent-payment-amount-input"}
        />
        {enableTransactionFees && (
          <FeesDisclaimerDisplay
            dollarAmount={feesAppliedDollarAmount}
            style={{ marginTop: 16 }}
          />
        )}
        <Button.Primary
          disabled={loading || toCents(paymentAmount) <= 0}
          onPress={onSubmit}
          style={{ marginTop: 16 }}
          accessibilityLabel={"pay-rent-amount-button"}
        >
          {`Pay ${feesAppliedDollarAmount}`}
        </Button.Primary>
      </FormProvider>
    </View>
  );
};

const Loading: FC = () => {
  const enableTransactionFees = useHasFeature(
    PlatformFeatureId.TransactionFees
  );

  return (
    <>
      <PaymentAmountInputField.Loading />
      {enableTransactionFees && (
        <FeesDisclaimerDisplay.Loading style={{ marginTop: 16 }} />
      )}
      <Button.Loading style={{ marginTop: 16 }} />
    </>
  );
};

EditablePaymentDisplay.Loading = Loading;

export { EditablePaymentDisplay, PayLiabilityFormData };
