import { Text } from "@ailo/primitives";
import { View } from "react-native";
import styled from "styled-components/native";
import { Badge } from "@ailo/ui";

export const Container = styled(View)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px 12px;
`;

export const Notification = styled(View)`
  flex-direction: row;
  margin-bottom: 20px;
`;

export const NotificationDescription = styled(View)`
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
`;

export const Title = styled(Text.DisplayS).attrs({
  weight: "medium"
})`
  text-align: center;
  margin: 24px 0;
`;

export const ComingSoonBadge = styled(Badge).attrs({
  children: "Coming Soon",
  type: "attention"
})`
  align-self: flex-start;
  margin-top: 2px;
`;
