import React from "react";
import { ViewStyle } from "react-native";
import { StyleProps } from "@ailo/primitives";
import { useGetInvestorMostRecentRentHistoryQuery } from "@ailo/domains";
import {
  didQueryFail,
  didQueryNotLoadYet,
  useClearPreviousDataOnVariablesChange
} from "@ailo/services";
import { useOnFocus } from "local/common";
import { RentHistoryCard } from "../RentHistoryCard";

interface Props {
  managementId: string;
  style?: StyleProps<ViewStyle>;
}

function InvestorRentHistoryCard({
  managementId,
  style
}: Props): React.ReactElement {
  const result = useClearPreviousDataOnVariablesChange(
    useGetInvestorMostRecentRentHistoryQuery,
    { variables: { managementId, pageSize: 1000 } }
  );
  const { data, refetch } = result;
  useOnFocus(refetch);

  if (didQueryNotLoadYet(result)) {
    return <RentHistoryCard.Loading style={style} />;
  }

  const liability = data?.management?.mostRecentTenancy?.liability;

  if (didQueryFail(result) || liability?.paidToDate == null) {
    return <RentHistoryCard.Error refetch={refetch} style={style} />;
  }

  const totalItems = liability?.entries?.pageInfo?.total;

  if (!totalItems) {
    return <RentHistoryCard.Empty style={style} />;
  }

  return (
    <RentHistoryCard
      managementId={managementId}
      tenancyId={data?.management?.mostRecentTenancy?.id}
      nextDueDate={data?.management?.mostRecentTenancy?.liability?.nextDueDate}
      overdueAmount={
        data?.management?.mostRecentTenancy?.liability?.overdueAmount
      }
      nextDueAmount={
        data?.management?.mostRecentTenancy?.liability?.nextDueAmount
      }
      style={style}
    />
  );
}

InvestorRentHistoryCard.Loading = RentHistoryCard.Loading;
InvestorRentHistoryCard.Empty = RentHistoryCard.Empty;

export { InvestorRentHistoryCard };
