import { Colors } from "@ailo/primitives";
import { useEnvironment, withScreenComponent } from "@ailo/services";
import { WebView } from "@ailo/ui";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";

const TermsOfServiceViewScreen = withScreenComponent((): React.ReactElement => {
  const { EULA_URL } = useEnvironment();

  return (
    <Container>
      <WebView uri={EULA_URL} />
    </Container>
  );
});

const Container = styled(View)`
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  background-color: ${Colors.CLOUD};
`;

export { TermsOfServiceViewScreen };
