import React, { useCallback } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { getSupplierName } from "@ailo/domains";
import {
  Colors,
  opacify,
  AFC,
  useSynchronisedLoad,
  Text
} from "@ailo/primitives";
import { Button } from "@ailo/ui";
import {
  useNavigation,
  Screens,
  useNavigateToThisScreen,
  useOnFocus,
  useRoute
} from "local/common";
import { useGetDefaultPaymentMethodIdsForUser } from "local/domain/ledger/hooks";
import { useGetPayBillDetailsQuery } from "local/graphql";

interface Props {
  billId: string;
}

const PayBillButton: AFC<Props> = ({ billId }) => {
  const {
    data,
    loading,
    refetch: billDetailRefetch
  } = useGetPayBillDetailsQuery({
    variables: { billId }
  });

  const {
    data: defaultPaymentMethodData,
    loading: defaultPaymentMethodLoading,
    refetch: defaultPaymentMethodRefetch,
    defaultInPaymentMethodId
  } = useGetDefaultPaymentMethodIdsForUser();

  const isLoading = useSynchronisedLoad(
    (loading && !data) ||
      (defaultPaymentMethodLoading && !defaultPaymentMethodData)
  );

  const { onClose } = useRoute<Screens.Bill>().params;

  const refetch = useCallback(() => {
    billDetailRefetch();
    defaultPaymentMethodRefetch();
  }, [billDetailRefetch, defaultPaymentMethodRefetch]);

  useOnFocus(refetch);

  const navigation = useNavigation();

  const returnToThisScreen = useNavigateToThisScreen();

  if (isLoading)
    return (
      <Container>
        <Button.Loading style={{ marginTop: 8, width: "100%" }} />
      </Container>
    );

  const liabilityId = data?.billById.liability?.id;
  const dueAmount = data?.billById.liability?.dueAmount?.cents;

  const supplierName = getSupplierName(data?.billById?.supplier);
  const amount = data?.billById.amount.cents;

  if (!liabilityId || !amount || dueAmount == null || !supplierName) {
    throw new Error(`Cannot find payment details for bill with id ${billId}`);
  }

  const navigateToPayBillScreen = (paymentMethodId?: string): void => {
    navigation.navigate(Screens.PayBillLiability, {
      liabilityId,
      title: "Pay Bill",
      description: supplierName,
      defaultPaymentAmount: amount,
      amountEditable: false,
      paymentMethodId,
      SuccessScreen: Screens.BillPaymentSuccess,
      reference: billId,
      onClose: returnToThisScreen,
      onFinalClose: onClose
    });
  };

  const navigateToSetupPaymentMethod = (
    onSuccess: (paymentMethodId: string) => void
  ): void => {
    navigation.navigate(Screens.SetupPaymentMethod, {
      onClose: returnToThisScreen,
      onSuccess
    });
  };

  const onPress = (): void => {
    if (defaultInPaymentMethodId) {
      navigateToPayBillScreen();
    } else {
      navigateToSetupPaymentMethod(navigateToPayBillScreen);
    }
  };

  return (
    <Container>
      <Text.BodyXS color={Colors.TEXT.DARK.SECONDARY}>
        {"Payments take up to 3 banking days to process"}
      </Text.BodyXS>
      <Button.Primary
        style={{ marginTop: 8, width: "100%" }}
        disabled={dueAmount <= 0}
        onPress={onPress}
        testID={"PayBillButton"}
      >
        {"Approve & Pay"}
      </Button.Primary>
    </Container>
  );
};

const Container = styled(View)`
  background: ${Colors.WHITE};
  box-shadow: 0px -1px 0px ${opacify(Colors.SPACE_BLACK, 0.1)};
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
`;

export { PayBillButton };
