import React, { useCallback } from "react";
import { useGlobal } from "reactn";
import { WalletCard } from "@ailo/domains";
import {
  PlatformFeatureId,
  useGetAllWalletsBalancesQuery
} from "local/graphql";
import {
  useNavigation,
  Screens,
  useNavigateToThisScreen,
  useOnFocus,
  useCurrentLegalEntity
} from "local/common";

function WalletsSummaryCard(): React.ReactElement {
  const [legalEntity] = useCurrentLegalEntity();
  const walletResult = useGetAllWalletsBalancesQuery({
    variables: {
      legalEntityId: legalEntity.id.toString()
    }
  });
  useOnFocus(walletResult.refetch);

  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();
  const [availableFeatures] = useGlobal("availableFeatures");

  const onTransfer = useCallback(() => {
    navigation.navigate(Screens.TransferFundsSelectWallet, {
      onClose: returnToThisScreen
    });
  }, [navigation, returnToThisScreen]);

  if (!walletResult.data && walletResult.loading) return <WalletCard.Loading />;

  const legalEntityData = walletResult.data?.legalEntity;
  const availableBalance = legalEntityData?.allWalletsAvailableBalance;
  const totalBalance = legalEntityData?.allWalletsTotalBalance;
  const transferDisabled =
    (availableBalance?.cents || 0) <= 0 &&
    !availableFeatures.includes(PlatformFeatureId.AutoWalletWithdraw);

  return (
    <WalletCard
      onTransfer={onTransfer}
      transferDisabled={transferDisabled}
      availableBalance={availableBalance}
      totalBalance={totalBalance}
    />
  );
}

WalletsSummaryCard.Loading = WalletCard.Loading;

export { WalletsSummaryCard };
