import { useState, useRef, useEffect } from "react";
import { useGetWalletKycVerificationStatusQuery } from "local/graphql";

/**
 * When the component wants to do the action, useWaitForLedgerKyc starts polling for
 * the Ledger service's knowledge of the current user's kyc verification status. Once
 * this has verified = true, the action is executed once and never again.
 **/
export function useWaitForLedgerKyc(action: () => void): {
  doAction: () => void;
  loading: boolean;
} {
  const [trying, setTrying] = useState(false);
  const actionDoneRef = useRef(false);
  const ledgerKycVerificationStatusResult =
    useGetWalletKycVerificationStatusQuery({
      skip: !trying && !actionDoneRef.current,
      pollInterval: trying ? 500 : 0
    });

  const shouldDoAction =
    trying &&
    ledgerKycVerificationStatusResult.data?.effectiveUser?.person
      .kycVerifiedAccordingToLedgerService &&
    !actionDoneRef.current;

  useEffect(() => {
    if (shouldDoAction) {
      action();
      actionDoneRef.current = true;
    }
    // Dependencies not listed exhaustively, because effect not required unless shouldDoAction changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDoAction]);

  return {
    doAction: (): void => {
      setTrying(true);
    },
    loading: trying
  };
}
