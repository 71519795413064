import { useFormContext, FieldErrors } from "react-hook-form";
import { FormData } from "./formData";
import {
  DEFAULT_ERROR_MESSAGE,
  PhoneVerificationErrors,
  getErrorMessage
} from "local/domain/onboarding/phoneVerification";
import {
  useVerifyVerificationCodeMutation,
  useSendPhoneVerificationCodeMutation
} from "local/graphql";
import { useState, useCallback } from "react";
import { useIsMountedRef } from "@ailo/primitives";
import { useNavigation, Screens } from "local/common";
import { AiloSentry } from "@ailo/services";

interface Params {
  phoneNumber: string;
}

interface Result {
  onSubmit: (onSuccess: () => void) => () => Promise<void>;
  sending: boolean;
  formError?: string;
  validationErrors: FieldErrors<FormData>;
  onReset: () => void;
}

const useVerificationCodeForm = ({ phoneNumber }: Params): Result => {
  const isMountedRef = useIsMountedRef();
  const navigation = useNavigation();

  const [formError, setFormError] = useState<string | undefined>();
  const {
    handleSubmit,
    errors: validationErrors,
    reset
  } = useFormContext<FormData>();

  const [verifyCodeMutation, { loading, called }] =
    useVerifyVerificationCodeMutation();
  const [sendCodeMutation] = useSendPhoneVerificationCodeMutation();

  const onSubmit = (onSuccess: () => void): (() => Promise<void>) =>
    handleSubmit(({ code }) => {
      if (!code) return;

      setFormError(undefined);

      verifyCodeMutation({
        variables: { phoneNumber, code }
      })
        .then(({ data, errors }): void => {
          const ok = data?.verifyPhoneVerificationCode?.ok;
          if (ok) onSuccess();
          if (errors) throw new Error(DEFAULT_ERROR_MESSAGE);

          const errorCode = data?.verifyPhoneVerificationCode?.error?.code;
          if (
            errorCode === PhoneVerificationErrors.PHONE_NUMBER_ALREADY_VERIFIED
          ) {
            navigation.navigate(Screens.YourAccountIsSecure);
          }
          if (errorCode) throw new Error(getErrorMessage(errorCode));
        })
        .catch((error: Error) => {
          AiloSentry.captureException(error);
          if (isMountedRef.current) setFormError(error.message);
        });
    });

  const onReset = useCallback((): void => {
    reset();

    sendCodeMutation({
      variables: { phoneNumber }
    })
      .then(({ data, errors }): void => {
        if (errors) throw new Error(DEFAULT_ERROR_MESSAGE);

        const errorCode = data?.sendPhoneVerificationCode?.error?.code;
        if (errorCode) throw new Error(getErrorMessage(errorCode));
      })
      .catch((error: Error) => {
        if (isMountedRef.current) setFormError(error.message);
      });
  }, [isMountedRef, phoneNumber, reset, sendCodeMutation]);

  return {
    onSubmit,
    sending: called && loading,
    validationErrors,
    formError,
    onReset
  };
};

export { useVerificationCodeForm };
