import { Colors, Text, Link } from "@ailo/primitives";
import {
  didQueryNotLoadYet,
  didQuerySucceed,
  logApolloResultFailed,
  ScreenComponent,
  ensureMutationSucceeds,
  useAnalytics,
  AiloSentry
} from "@ailo/services";
import {
  DateTimeWithTimeZone,
  Card,
  ErrorAlertScreen,
  formatAddress,
  SpinnerOverlay,
  ImagePicker,
  ImagePickerRef,
  PickFileAbortedError,
  PickFileError,
  useToastContext,
  Alert
} from "@ailo/ui";
import { ScreenContainer, Screens, useNavigation } from "local/common";

import React from "react";
import { View } from "react-native";
import { UserProfileIntro } from "../common";
import {
  useGetCurrentPersonProfileDetailsQuery,
  useUpdatePersonProfileDetailsMutation,
  PlatformFeatureId
} from "local/graphql";
import { FormFieldListItem } from "./FormFieldListItem";
import { useHasFeature } from "@ailo/domains";
import { AiloRN } from "@ailo/ailorn";

function EditProfileScreenContent(): React.ReactElement {
  const toasts = useToastContext();
  const navigation = useNavigation();
  const analytics = useAnalytics();
  const result = useGetCurrentPersonProfileDetailsQuery({
    notifyOnNetworkStatusChange: true
  });
  const imagePickerRef = React.useRef<ImagePickerRef>(null);
  const [updateMutation, updateMutationResult] =
    useUpdatePersonProfileDetailsMutation();
  const canEditEmail = useHasFeature(
    PlatformFeatureId.ConsumerAppUserEmailChange
  );

  if (didQueryNotLoadYet(result)) {
    return <SpinnerOverlay />;
  }

  if (!didQuerySucceed(result) || !result.data.effectiveUser) {
    logApolloResultFailed(result, {
      operationId: "result"
    });
    return (
      <ErrorAlertScreen
        title={"There was a problem loading profile data"}
        onRetry={result.refetch}
      />
    );
  }
  const { person } = result.data.effectiveUser;

  const editPersonPhoto = async (): Promise<void> => {
    try {
      const file = await imagePickerRef.current?.pickImage();
      if (file) {
        navigation.navigate(Screens.EditProfilePhoto, { file });
      }
    } catch (error) {
      if (error instanceof PickFileAbortedError) {
        return;
      }
      if (error instanceof PickFileError) {
        toasts.show({
          type: "error",
          message:
            "Error happened while picking the picture file. Try again, choose a different picture, or contact Ailo support if the problem persists."
        });
        return;
      }
      throw error;
    }
  };

  const removePersonPhoto = async (): Promise<void> => {
    try {
      await ensureMutationSucceeds(
        updateMutation({
          variables: {
            input: {
              id: person.ailoRN,
              photoFileId: null
            }
          }
        }),
        {
          dataKey: "updatePersonProfileDetails"
        }
      );
    } catch (error) {
      AiloSentry.captureException(error);
      toasts.showFormSubmitError();
      return;
    }

    toasts.show({
      type: "success",
      message: "Profile photo has been removed"
    });
    analytics.track("Profile Updated", {
      fields: ["Profile Photo"]
    });
  };

  return (
    <ScreenContainer padding={false}>
      <ImagePicker
        ref={imagePickerRef}
        destructiveOption={
          person.photo
            ? {
                label: "Remove Current Photo",
                onPress: removePersonPhoto
              }
            : undefined
        }
        nativeOptions={{
          imagePickerOptions: { aspect: [1, 1], allowsEditing: true }
        }}
      />

      <UserProfileIntro
        legalEntity={{
          ...person,
          id: AiloRN.fromString(person.ailoRN)
        }}
        photoEditButton={{
          disabled: updateMutationResult.loading,
          onPress: editPersonPhoto
        }}
      />

      <Card
        style={{
          marginTop: 24,
          marginBottom: 0
        }}
        innerStyle={{
          paddingTop: 20
        }}
      >
        <Text.BodyM
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ paddingLeft: 16, marginBottom: 16 }}
        >
          {"Personal"}
        </Text.BodyM>
        <FormFieldListItem
          label={"First Name"}
          value={person.legalFirstName}
          style={{
            borderType: "none",
            backgroundColor: "transparent"
          }}
        />
        {person.legalMiddleName ? (
          <FormFieldListItem
            label={"Middle Name"}
            value={person.legalMiddleName}
            style={{
              borderType: "none",
              paddingTop: 0,
              backgroundColor: "transparent"
            }}
          />
        ) : undefined}
        <FormFieldListItem
          label={"Last Name"}
          value={person.lastName || undefined}
          placeholder={"Add Last Name"}
          style={{
            paddingTop: 0,
            backgroundColor: "transparent"
          }}
        />
        <FormFieldListItem
          label={"Preferred Name"}
          value={person.preferredName || undefined}
          placeholder={"Add Preferred Name"}
          editable
          onPress={(): void => navigation.navigate(Screens.EditPreferredName)}
        />
        <FormFieldListItem
          label={"Date of Birth"}
          value={
            person.birthDate
              ? DateTimeWithTimeZone.fromLocalDate(person.birthDate).format(
                  "D MMMM YYYY"
                )
              : undefined
          }
          placeholder={"Add Date of Birth"}
          editable
          onPress={(): void => navigation.navigate(Screens.EditBirthDate)}
          style={{
            borderType: "none"
          }}
        />
      </Card>

      <Card
        style={{
          marginTop: 16,
          marginBottom: 32
        }}
        innerStyle={{
          paddingTop: 20
        }}
      >
        <Text.BodyM
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ paddingLeft: 16, marginBottom: 16 }}
        >
          {"Contact Details"}
        </Text.BodyM>
        <FormFieldListItem
          label={
            person.pendingEmailAddress
              ? "Current Email Address"
              : "Email Address"
          }
          value={person.emailAddress!}
          editable={canEditEmail}
          style={{
            borderType: person.pendingEmailAddress ? "none" : undefined
          }}
          onPress={
            canEditEmail
              ? (): void => navigation.navigate(Screens.EditEmailAddress)
              : undefined
          }
        />
        {person.pendingEmailAddress && (
          <>
            <FormFieldListItem
              label={"Pending Email Address"}
              value={person.pendingEmailAddress!}
              style={{
                paddingTop: 0,
                borderType: "none"
              }}
            />
            <View
              style={{
                marginHorizontal: 16,
                paddingBottom: 16,
                borderBottomWidth: 1,
                borderBottomColor: Colors.GRAYSCALE.OUTLINE
              }}
            >
              <Alert type={"warning"}>
                {"Verification required. Please check your email. "}
                <Link
                  variant={"primary"}
                  onPress={(): void =>
                    navigation.navigate(Screens.VerifyEmailAddress, {
                      emailAddress: person.pendingEmailAddress!
                    })
                  }
                >
                  {"Click here to enter code."}
                </Link>
              </Alert>
            </View>
          </>
        )}
        <FormFieldListItem
          label={"Phone Number"}
          value={person.phoneNo || undefined}
          placeholder={"Add Phone Number"}
        />
        <FormFieldListItem
          label={"Residential Address"}
          value={formatAddress(person)}
          placeholder={"Add Residential Address"}
          editable
          onPress={(): void => navigation.navigate(Screens.EditAddress)}
          style={{
            borderType: "none"
          }}
        />
      </Card>
    </ScreenContainer>
  );
}

export function EditProfileScreen(): React.ReactElement {
  return (
    <ScreenComponent statusBarStyle={"dark-content"}>
      <EditProfileScreenContent />
    </ScreenComponent>
  );
}
