import { Screens, useNavigation, useRoute } from "local/common";
import React, { FC } from "react";
import { FrequencyEnum } from "local/domain/ledger";
import { SelectFrequencyScreen as Screen } from "../../../frequencyBasedComponents/SelectFrequencyScreen";
import { EnsureKycVerified } from "local/domain/authz";

const SelectFrequencyScreen: FC = () => {
  const navigation = useNavigation<Screens.AutoWithdrawPlanSelectFrequency>();
  const params = useRoute<Screens.AutoWithdrawPlanSelectFrequency>().params;

  const onFrequencySelected = (frequency: FrequencyEnum): void => {
    navigation.navigate(Screens.AutoWithdrawPlanSelectStartDate, {
      ...params,
      frequency
    });
  };

  return (
    <EnsureKycVerified onKycCheckDismiss={params.onClose}>
      <Screen
        heading={`How often do you want\ntransfers to occur?`}
        onFrequencySelected={onFrequencySelected}
      />
    </EnsureKycVerified>
  );
};

export { SelectFrequencyScreen };
