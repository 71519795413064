import { SFC } from "@ailo/primitives";
import { useClearPreviousDataOnVariablesChange } from "@ailo/services";
import { Card } from "@ailo/ui";
import { PaidBillsList } from "local/domain/bill";
import { Screens, useNavigation, useOnFocus } from "local/common";
import React, { FC, useCallback } from "react";
import { ViewStyle } from "react-native";
import {
  PaidBillsCardErrorBoundary,
  PaidBillsCardErrorComponent,
  PaidBillsHeader,
  ViewAllButton
} from "./components";
import { useGetPaidBillsQuery } from "local/graphql";

interface Props {
  reference: string;
  address: string;
  onBillPress: (billId: string) => void;
  style?: ViewStyle;
}

interface Statics {
  Loading: SFC;
  Empty: SFC;
}

const Loading: SFC = ({ style }) => {
  return (
    <Card style={style} testID={undefined}>
      <PaidBillsHeader />
      <PaidBillsList.Loading itemsLimit={3} />
      <ViewAllButton.Loading />
    </Card>
  );
};

const Empty: SFC = ({ style }) => {
  return (
    <Card style={style} testID={undefined}>
      <PaidBillsHeader />
      <PaidBillsList.Empty
        text={"No bills paid yet"}
        style={{ marginBottom: 20 }}
      />
    </Card>
  );
};

const PaidBillsCard: FC<Props> & Statics = ({
  reference,
  address,
  onBillPress,
  style
}) => {
  const navigation = useNavigation();
  const navigateToAllPaidBills = useCallback(() => {
    navigation.navigate(Screens.PaidBills, {
      reference,
      address
    });
  }, [navigation, reference, address]);

  const pageCursor = {
    pageSize: 3,
    paginateBackward: false
  };
  const { loading, data, refetch } = useClearPreviousDataOnVariablesChange(
    useGetPaidBillsQuery,
    {
      variables: {
        reference,
        pageCursor
      }
    }
  );

  useOnFocus(refetch);

  if (!data && loading) {
    return (
      <PaidBillsCardErrorBoundary>
        <Loading style={style} />
      </PaidBillsCardErrorBoundary>
    );
  }

  const totalPaidBills = data?.paidBills?.pageInfo?.total;

  if (totalPaidBills == null) {
    return <PaidBillsCardErrorComponent style={style} />;
  }

  if (totalPaidBills === 0) {
    return (
      <PaidBillsCardErrorBoundary>
        <Empty style={style} />
      </PaidBillsCardErrorBoundary>
    );
  }

  return (
    <PaidBillsCardErrorBoundary>
      <Card style={style}>
        <PaidBillsHeader />
        <PaidBillsList
          reference={reference}
          address={address}
          itemsLimit={3}
          onBillPress={onBillPress}
        />
        {totalPaidBills > 3 && (
          <ViewAllButton onPress={navigateToAllPaidBills} />
        )}
      </Card>
    </PaidBillsCardErrorBoundary>
  );
};

PaidBillsCard.Loading = Loading;
PaidBillsCard.Empty = Empty;

export { PaidBillsCard };
