import React from "react";
import { withScreenComponent } from "local/common";

import { TenantAddPaymentMethodScreen } from "./TenantAddPaymentMethodScreen";

export const AddPaymentMethodKeyActionScreen = withScreenComponent(
  (): React.ReactElement => {
    return <TenantAddPaymentMethodScreen />;
  }
);
