import React, { FC } from "react";
import { useEnvironment } from "@ailo/services";
import { useOpenAuthorizedHelpInBrowser } from "@ailo/domains";
import { useUserOnboardedRecently } from "local/domain/onboarding";
import { Colors, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { Alert } from "@ailo/ui";

const ScheduledPayOffAppPaymentInfoBox: FC = () => {
  const { RENT_PAYMENT_HELP_URL } = useEnvironment();
  const openHelpInBrowser = useOpenAuthorizedHelpInBrowser(
    RENT_PAYMENT_HELP_URL
  );

  const { onboardedRecently, loading } = useUserOnboardedRecently();

  if (loading || !(onboardedRecently === undefined || onboardedRecently)) {
    return null;
  }

  return (
    <StyledAlert type={"info"}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {
          "Please select a date that takes into account any rent payments you've made outside of Ailo in the past 7 days. It can take up to a week for your payment history to reach Ailo and update your balance. "
        }
        <Text.BodyM
          color={Colors.AILO_RED}
          weight={"medium"}
          onPress={openHelpInBrowser}
        >
          {"Learn more."}
        </Text.BodyM>
      </Text.BodyM>
    </StyledAlert>
  );
};

const StyledAlert = styled(Alert)`
  margin-top: 24px;
  margin-right: 12px;
  margin-left: 12px;
`;

export { ScheduledPayOffAppPaymentInfoBox };
