import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import styled from "styled-components/native";
import moment from "moment";
import { AiloRN, services } from "@ailo/ailorn";
import { Colors, Text } from "@ailo/primitives";
import { Button, SpinnerOverlay } from "@ailo/ui";
import { PaymentSuccessAlert } from "@ailo/domains";
import { MoneyDeprecated } from "local/domain/ledger";
import {
  Screens,
  useNavCloseButton,
  useRoute,
  useTitle,
  withScreenComponent
} from "local/common";
import {
  useSetBillLiabilityStateMutation,
  useGetPaymentMethodPaidWithQuery
} from "local/graphql";

export const BillPaymentSuccessScreen = withScreenComponent(
  (): React.ReactElement => {
    const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();

    const { title, paymentAmount, paymentMethodId, reference, onClose } =
      useRoute<Screens.BillPaymentSuccess>().params;

    const [closePressed, setClosePressed] = useState(false);

    const [setBillLiabilityStateMutation, { loading }] =
      useSetBillLiabilityStateMutation({
        variables: {
          billPaymentDetails: {
            billId: AiloRN.of(services.Bill.bill, reference).toString(),
            paymentDate: moment.utc().toISOString(),
            paidAmount: paymentAmount
          }
        }
      });

    const { data: paymentMethodData } = useGetPaymentMethodPaidWithQuery({
      variables: { paymentMethodId }
    });

    useEffect(() => {
      setBillLiabilityStateMutation();
    }, [setBillLiabilityStateMutation]);

    useEffect(() => {
      if (!loading && closePressed) onClose();
    }, [loading, closePressed, onClose]);

    useNavCloseButton();
    useTitle(title);

    const paymentDollarAmount = new MoneyDeprecated(
      paymentAmount.cents
    ).format();

    const isBankAccount =
      paymentMethodData?.paymentMethodById?.__typename === "BankAccount";

    return (
      <View
        style={{
          paddingVertical: 16,
          marginBottom: bottomSafeAreaInset,
          flex: 1
        }}
      >
        <Container>
          <Text.DisplayM>{"Bill paid successfully"}</Text.DisplayM>
          <Text.BodyM
            color={Colors.TEXT.DARK.SECONDARY}
            style={{
              textAlign: "center",
              marginTop: 8,
              paddingHorizontal: 32
            }}
          >
            {`Thanks for your payment of ${paymentDollarAmount}.`}
          </Text.BodyM>
        </Container>

        {isBankAccount && (
          <PaymentSuccessAlert
            style={{ marginBottom: 40, marginHorizontal: 16 }}
          />
        )}

        <View style={{ paddingHorizontal: 16 }}>
          <Button.Primary
            onPress={(): void => {
              setClosePressed(true);
            }}
            accessibilityLabel={"close-button"}
          >
            {"Close"}
          </Button.Primary>
        </View>
        {loading && closePressed && <SpinnerOverlay />}
      </View>
    );
  }
);

const Container = styled(View)`
  justify-content: center;
  align-items: center;
  flex: 1;
`;
