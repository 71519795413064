import { Screens, useNavigation, useRoute } from "local/common";
import React, { FC, ReactElement } from "react";
import { ListItem, ListItemProps } from "@ailo/ui";
import { RightChevronIcon } from "@ailo/primitives";
import { ListHeader } from "../../components";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";

const ChooseAutoPayTypeScreen: FC = () => {
  const navigation = useNavigation<Screens.LiabilityPlanChooseType>();
  const params = useRoute<Screens.LiabilityPlanChooseType>().params;
  const enablePayWhenDue = useHasFeature(PlatformFeatureId.AutoPayRent);
  const enablePlan = useHasFeature(PlatformFeatureId.LiabilityPaymentPlan);

  const onPayWhenDueClick = (): void => {
    navigation.navigate(Screens.LiabilityPlanAutoPaySelectProperty, params);
  };

  const onPaymentScheduleClick = (): void => {
    navigation.navigate(Screens.LiabilityPlanSelectProperty, params);
  };

  return (
    <ScreenWithHeaderAndAction backgroundColor={"default"}>
      <ListHeader>{"How do you want to pay?"}</ListHeader>
      {enablePayWhenDue && (
        <ListItemWithChevron
          header={"Pay all rent when it’s due"}
          onPress={onPayWhenDueClick}
        />
      )}
      {enablePlan && (
        <ListItemWithChevron
          header={"Setup a payment schedule"}
          onPress={onPaymentScheduleClick}
        />
      )}
    </ScreenWithHeaderAndAction>
  );
};

const ListItemWithChevron = ({ header, ...r }: ListItemProps): ReactElement => {
  return (
    <ListItem
      header={header}
      rightComponent={<RightChevronIcon width={20} height={20} />}
      {...r}
    />
  );
};

export { ChooseAutoPayTypeScreen };
