import { BaseSlide } from "./BaseSlide";
import React, { FC } from "react";
import { Dimensions, Image, ImageSourcePropType, View } from "react-native";
import styled from "styled-components/native";

interface Props {
  titleText: string;
  descriptionText: string;
  imageSource: ImageSourcePropType;
}

const FeatureShowcaseSlide: FC<Props> = ({
  titleText,
  descriptionText,
  imageSource
}) => {
  const imageWidth = Dimensions.get("window").width - 120;
  const imageHeight = 2 * imageWidth;
  return (
    <BaseSlide titleText={titleText} descriptionText={descriptionText}>
      <ShadowBox>
        <Image
          source={imageSource}
          resizeMode={"contain"}
          style={{ height: imageHeight, width: imageWidth, marginTop: 64 }}
        />
      </ShadowBox>
    </BaseSlide>
  );
};

const ShadowBox = styled(View)`
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`;

export { FeatureShowcaseSlide };
