import { Screens, useNavigation } from "local/common";
import { AiloRN } from "@ailo/ailorn";
import { OpenChat } from "@ailo/domains";
import { useCallbackRef } from "@ailo/primitives";

export function useOpenChat(): OpenChat {
  const navigation = useNavigation();

  return useCallbackRef(
    (chatAilorn: AiloRN<"chat:chat">) => {
      navigation.navigate(Screens.Threads, { chatId: chatAilorn.internalId });
    },
    [navigation]
  );
}
