import React from "react";
import { Card } from "@ailo/ui";
import { PaidBillsHeader } from "./PaidBillsHeader";
import { PaidBillsList } from "local/domain/bill";
import { StyleProp, ViewStyle } from "react-native";

export const PaidBillsCardErrorComponent: React.FC<{
  style?: StyleProp<ViewStyle>;
}> = ({ style }) => {
  return (
    <Card style={style}>
      <PaidBillsHeader />
      <PaidBillsList.Empty
        text={"Error loading Paid"}
        style={{ marginBottom: 20 }}
      />
    </Card>
  );
};
