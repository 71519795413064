import {
  didQueryNotLoadYet,
  didQuerySucceed,
  ensureMutationSucceeds,
  logApolloResultFailed,
  withScreenComponent,
  useAnalytics,
  AiloSentry
} from "@ailo/services";
import {
  Button,
  ErrorAlertScreen,
  FormField,
  SpinnerOverlay,
  TextInput,
  useToastContext
} from "@ailo/ui";
import {
  ScreenContainer,
  ScreenHeader,
  Screens,
  useNavigation
} from "local/common";

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { nameValidationRules } from "../common";
import {
  useGetCurrentPersonProfileDetailsQuery,
  useUpdatePersonProfileDetailsMutation
} from "local/graphql";
import { Keyboard } from "react-native";

interface FormData {
  preferredName: string | null;
}

export const EditPreferredNameScreen = withScreenComponent(
  {
    statusBarStyle: "dark-content",
    viewTitle: null
  },
  (): React.ReactElement => {
    const toasts = useToastContext();
    const navigation = useNavigation();
    const analytics = useAnalytics();
    const result = useGetCurrentPersonProfileDetailsQuery({
      notifyOnNetworkStatusChange: true
    });

    const [updateMutation, mutationResult] =
      useUpdatePersonProfileDetailsMutation();

    const { control, handleSubmit, formState, errors } = useForm<FormData>();

    if (didQueryNotLoadYet(result)) {
      return <SpinnerOverlay />;
    }

    if (!didQuerySucceed(result) || !result.data.effectiveUser) {
      logApolloResultFailed(result, {
        operationId: "result"
      });
      return (
        <ErrorAlertScreen
          title={"There was a problem loading profile data"}
          onRetry={result.refetch}
        />
      );
    }

    const { person } = result.data.effectiveUser;

    const submit = handleSubmit(async (data: FormData) => {
      Keyboard.dismiss();
      try {
        await ensureMutationSucceeds(
          updateMutation({
            variables: {
              input: {
                id: person.ailoRN,
                preferredName: data.preferredName || null
              }
            }
          }),
          {
            dataKey: "updatePersonProfileDetails"
          }
        );
      } catch (error) {
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      toasts.show({
        type: "success",
        message: "Preferred name updated"
      });
      navigation.navigate(Screens.EditProfile);
      analytics.track("Profile Updated", {
        fields: ["Preferred Name"]
      });
    });

    return (
      <>
        <ScreenContainer
          stickyBottom={
            <Button.Primary
              disabled={formState.isSubmitting || !formState.isDirty}
              onPress={submit}
            >
              {"Update Preferred Name"}
            </Button.Primary>
          }
        >
          <ScreenHeader
            title={"Update Preferred Name"}
            description={
              "We will use this name to replace your first name in places you name appear in the app like Chat and email communication."
            }
          />

          <FormField
            label={"Preferred Name"}
            error={errors.preferredName?.message}
            style={{ marginTop: 24 }}
          >
            <Controller
              control={control}
              name={"preferredName"}
              defaultValue={person.preferredName ?? null}
              rules={nameValidationRules}
              render={({ onChange, onBlur, value }): React.ReactElement => (
                <TextInput
                  autoCapitalize={"words"}
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FormField>
        </ScreenContainer>
        {mutationResult.loading && <SpinnerOverlay />}
      </>
    );
  }
);
