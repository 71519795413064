import { AiloRN } from "@ailo/ailorn";
import { LocalDate, RecurringDate } from "@ailo/date";
import { formatAddress } from "@ailo/domain-helpers";
import {
  BankAccountListItem,
  smallPaymentMethodListItemStyle
} from "@ailo/domains";
import { Colors, Text } from "@ailo/primitives";
import {
  didQueryNotLoadYet,
  didQuerySucceed,
  logApolloResultFailed
} from "@ailo/services";
import {
  alert,
  EditableListItemWithLeftHeading,
  ErrorAlertScreen,
  formatMonthlyFrequency,
  ListItemValue,
  Money,
  Separator
} from "@ailo/ui";
import Big from "big.js";
import {
  Screens,
  useCurrentLegalEntity,
  useNavigation,
  usePropertySelectorContext,
  useRoute
} from "local/common";
import {
  findPropertyByManagementOrFolioAilorn,
  FrequencyEnum
} from "local/domain/ledger";
import {
  Props as ScreenWithActionProps,
  ScreenWithHeaderAndAction
} from "local/domain/liabilityPaymentPlan";
import {
  CreateAutoWithdrawPlanInputV2,
  QuartzPlanFrequency
} from "local/graphql";
import { upperFirst } from "lodash";
import moment from "moment";
import React, { FC } from "react";
import { View } from "react-native";
import { useAutoPayLiabilityStatusForm } from "../../../profile/SetupAutoPayBillsScreen/useAutoPayLiabilityStatusForm";
import {
  AutoWithdrawPlanSetupData,
  BankAccountPaymentMethodOption
} from "../../types";
import { createPaymentMethodDestinationInput } from "./createPaymentMethodDestinationInput";
import { LastModified } from "./LastModified";
import { PropertyAddressComponent } from "./PropertyAddressComponent";

type Props = {
  canModify?: boolean;
  mode: "create" | "edit";
  showTitle?: boolean;
  onSaveClick: (data: CreateAutoWithdrawPlanInputV2) => void;
  onPressNextOnSelectPaymentDestinationPercentageScreen?: (
    bankPaymentMethods: BankAccountPaymentMethodOption[]
  ) => void;
  walletOwnerRef?: AiloRN;
  modifiedAt?: string;
  modifiedBy?: string;
} & AutoWithdrawPlanSetupData &
  ScreenWithActionProps;

const DetailsEditComponent: FC<Props> = (props) => {
  const [legalEntity] = useCurrentLegalEntity();
  const navigation = useNavigation();

  const params = useRoute<Screens.AutoWithdrawPlanEdit>().params;

  const {
    walletId,
    frequency,
    startDate,
    anniversary,
    anniversaryDaysOfMonth,
    bankPaymentMethods,
    mode,
    onSaveClick,
    onPressNextOnSelectPaymentDestinationPercentageScreen,
    showTitle = true,
    primaryAction,
    canModify = true,
    isLastDayOfTheMonth = false,
    nextFireTime,
    modifiedAt,
    modifiedBy,
    ...rest
  } = props;

  const nextTransfer = nextFireTime
    ? LocalDate.from(nextFireTime).format("D MMMM YYYY")
    : formatDate(startDate);

  const walletOwnerRef = params.walletOwnerRef ?? props.walletOwnerRef;

  if (!frequency) {
    throw "frequency is missing.";
  }

  if (!bankPaymentMethods) {
    throw "bankPaymentMethods is missing";
  }

  const { allProperties } = usePropertySelectorContext();
  const property = findPropertyByManagementOrFolioAilorn(
    allProperties,
    walletOwnerRef
  );

  const propertyAddress = property?.address
    ? formatAddress(property?.address, {
        format: "street, suburb, state, postcode"
      })
    : null;

  const formattedFrequency =
    frequency === FrequencyEnum.monthly
      ? formatMonthlyFrequency(
          anniversaryDaysOfMonth ?? [],
          isLastDayOfTheMonth ?? false
        )
      : upperFirst(
          new RecurringDate({
            frequency,
            startDate: moment(startDate).format("YYYY-MM-DD")
          }).format({ type: "F-ly on D" })
        );

  const { statusResult } = useAutoPayLiabilityStatusForm(walletOwnerRef);

  const onEditClick = (screen: Screens): void => {
    navigation.navigate(screen, props);
  };

  const getPayload = (): CreateAutoWithdrawPlanInputV2 => {
    const format = "YYYY-MM-DD";

    const allAnniversaryRelatedFieldsEmpty =
      (frequency !== FrequencyEnum.monthly && anniversary == null) ||
      (frequency === FrequencyEnum.monthly &&
        !(anniversaryDaysOfMonth ?? []).length &&
        !isLastDayOfTheMonth);

    if (
      !frequency ||
      !startDate ||
      !walletId ||
      !bankPaymentMethods ||
      allAnniversaryRelatedFieldsEmpty
    ) {
      throw "Provide all fields";
    }

    const payload = {
      startDate: moment(startDate).format(format),
      endDate: undefined,
      frequency: frequency.toString() as QuartzPlanFrequency,
      walletId: walletId,
      anniversary,
      anniversaryDaysOfMonth,
      paymentMethodDestinations: bankPaymentMethods
        .filter((paymentMethod) => paymentMethod.selected)
        .map(createPaymentMethodDestinationInput),
      payerLegalEntityId: legalEntity.id.toString(),
      lastDayOfTheMonth: isLastDayOfTheMonth,
      userFacingDescription: propertyAddress
    };
    return payload;
  };

  if (didQueryNotLoadYet(statusResult)) {
    return (
      <ScreenWithHeaderAndAction
        header={showTitle ? "Confirm details" : undefined}
        primaryAction={
          canModify
            ? {
                text: "Save",
                onClick: (): void => onSaveClick(getPayload()),
                ...primaryAction
              }
            : undefined
        }
        {...rest}
      >
        <DetailsEditComponentContentLoading />
      </ScreenWithHeaderAndAction>
    );
  }

  if (!didQuerySucceed(statusResult)) {
    logApolloResultFailed(statusResult, {
      operationId: "DetailsEditComponent.statusResult"
    });
    return (
      <ErrorAlertScreen
        variant={"large"}
        title={"There was a problem loading\nSetup Auto Transfers"}
        onRetry={statusResult.refetch}
      />
    );
  }

  const billPaymentDetails = statusResult.data?.enabled ? (
    <ListItemValue
      value={`Bills will be paid automatically from ${
        statusResult.data?.paymentMethodId ? "credit card" : "rent"
      } before transferring`}
      subValue={`Up to ${Money.from(
        statusResult.data?.maximumPaymentAmount
      ).format()} limit`}
    />
  ) : (
    <ListItemValue value={"Automatic bill payments not turned on"} />
  );

  return (
    <ScreenWithHeaderAndAction
      header={showTitle ? "Confirm details" : undefined}
      primaryAction={
        canModify
          ? {
              text: "Save",
              onClick: (): void => onSaveClick(getPayload()),
              ...primaryAction
            }
          : undefined
      }
      {...rest}
    >
      <View>
        <Separator />
        <PropertyAddressComponent walletOwnerRef={walletOwnerRef} />
        <EditableListItemWithLeftHeading
          name={"Payment Destination"}
          editable={canModify}
          onEditClick={(): void =>
            onEditClick(Screens.AutoWithdrawPlanSelectPaymentDestinations)
          }
        >
          {bankPaymentMethods
            .filter((paymentMethod) => paymentMethod.selected)
            .map((paymentMethod, index) => (
              <BankAccountListItem
                testID={"payment-method-list-item"}
                key={paymentMethod.id}
                accountName={paymentMethod.accountName}
                maskedAccountNumber={paymentMethod.accountNumber ?? ""}
                showIcon={false}
                showAutoBadges={false}
                rightComponent={
                  <Text.BodyM
                    color={Colors.TEXT.DARK.SECONDARY}
                    weight={"book"}
                    style={{ marginRight: 13 }}
                  >
                    {`${Big(paymentMethod.percentage ?? 0)
                      .mul(100)
                      .toNumber()}%`}
                  </Text.BodyM>
                }
                style={{
                  ...smallPaymentMethodListItemStyle,
                  marginTop: index === 0 ? 0 : 16
                }}
              />
            ))}
        </EditableListItemWithLeftHeading>
        <EditableListItemWithLeftHeading
          name={"Frequency"}
          editable={mode === "create" || canModify}
          onEditClick={(): void => {
            mode === "create"
              ? onEditClick(Screens.AutoWithdrawPlanSelectFrequency)
              : alert(
                  "Edit frequency",
                  "To change the frequency, turn off the current Auto Transfer and add a new one",
                  [{ text: "Done" }]
                );
          }}
        >
          <ListItemValue value={formattedFrequency} />
        </EditableListItemWithLeftHeading>

        <EditableListItemWithLeftHeading
          name={"Next Transfer"}
          editable={mode === "create"}
          onEditClick={(): void =>
            onEditClick(Screens.AutoWithdrawPlanSelectStartDate)
          }
        >
          <ListItemValue value={nextTransfer} />
        </EditableListItemWithLeftHeading>
        <EditableListItemWithLeftHeading name={"Bill payments"}>
          {billPaymentDetails}
        </EditableListItemWithLeftHeading>
        {modifiedAt && (
          <LastModified
            modifiedAt={modifiedAt}
            modifiedBy={modifiedBy}
            style={{ marginVertical: 20, marginHorizontal: 16 }}
          />
        )}
      </View>
    </ScreenWithHeaderAndAction>
  );
};

function DetailsEditComponentContentLoading(): React.ReactElement {
  return (
    <View>
      <Separator />
      <EditableListItemWithLeftHeading name={"Bill payments"}>
        <ListItemValue.Loading />
      </EditableListItemWithLeftHeading>
      <EditableListItemWithLeftHeading name={"Payment Destination"}>
        <ListItemValue.Loading />
      </EditableListItemWithLeftHeading>
      <EditableListItemWithLeftHeading name={"Frequency"}>
        <ListItemValue.Loading />
      </EditableListItemWithLeftHeading>
      <EditableListItemWithLeftHeading name={"Start Date"}>
        <ListItemValue.Loading />
      </EditableListItemWithLeftHeading>
    </View>
  );
}

function formatDate(date?: Date | undefined | null, def?: string): string {
  return date ? moment(date).format("D MMM YYYY") : def || "Unknown";
}

export { DetailsEditComponent };
