import { useHeaderHeight } from "@react-navigation/stack";
import React, { ReactElement } from "react";
import {
  KeyboardAvoidingView as NativeKeyboardAvoidingView,
  KeyboardAvoidingViewProps as NativeKeyboardAvoidingViewProps,
  Platform
} from "react-native";

interface Props
  extends Omit<
    NativeKeyboardAvoidingViewProps,
    "behavior" | "keyboardVerticalOffset"
  > {
  children: React.ReactNode;
}

export function KeyboardAvoidingScreenView({
  style,
  ...props
}: Props): ReactElement {
  const headerHeight = useHeaderHeight();
  return (
    <NativeKeyboardAvoidingView
      {...props}
      style={[{ flex: 1 }, style]}
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      // This component is usually always put inside of the screen
      // which is already offsetted `headerHeight` px from the top because of the navigation bar
      // - and thus it has to be passed into this prop.
      // (Without it, the keyboard may cover elements that are sticky to the bottom,
      //  e.g. `StickyBottom` component.)
      keyboardVerticalOffset={headerHeight}
    />
  );
}
