import { BPayTypeName } from "@ailo/domains";

export enum PaymentMethodType {
  BankAccount = "BankAccount",
  CreditCard = "CreditCard",
  VirtualAccount = "VirtualAccount"
}
export const BankAccountTypeName = "BankAccount";
export const CreditCardTypeName = "CreditCard";
export const VirtualAccountTypeName = "VirtualAccount";

export interface CreditCardMethod {
  __typename: typeof CreditCardTypeName;
  id: string;
  isAutoPay?: boolean;
  isAutoWithdraw?: boolean;
  type?: string | null;
  expiry?: string | null;
  number?: string | null;
}

export interface BankAccountMethod {
  __typename: typeof BankAccountTypeName;
  id: string;
  isAutoPay?: boolean;
  isAutoWithdraw?: boolean;
  accountName?: string | null;
  accountNumber?: string | null;
}

export interface BPay {
  __typename: typeof BPayTypeName;
  id: string;
}

export interface VirtualAccountMethod {
  __typename: typeof VirtualAccountTypeName;
  id: string;
  description?: string | null;
  reference?: string | null;
  accountNumber?: string | null;
  bsb?: string | null;
}

export type PaymentMethod =
  | CreditCardMethod
  | BankAccountMethod
  | BPay
  | VirtualAccountMethod;
