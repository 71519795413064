import React from "react";
import { Text, SFC } from "@ailo/primitives";
import { View } from "react-native";
import {
  NotificationIcon,
  Container,
  Title,
  Notification,
  NotificationDescription,
  ComingSoonBadge
} from "./components";

export const InvestorNotificationsDescription: SFC = ({ style }) => {
  return (
    <Container style={style}>
      <Title>{"Don’t miss any important\nupdates about your investment"}</Title>
      <View>
        <Notification>
          <NotificationIcon.Chat />
          <NotificationDescription>
            <Text.BodyM>
              {"Responses to chats with"}
              {"\n"}
              {"your property manager"}
            </Text.BodyM>
          </NotificationDescription>
        </Notification>
        <Notification>
          <NotificationIcon.Rent />
          <NotificationDescription>
            <Text.BodyM>{"Rental income notifications"}</Text.BodyM>
            <ComingSoonBadge />
          </NotificationDescription>
        </Notification>
        <Notification>
          <NotificationIcon.Bill />
          <NotificationDescription>
            <Text.BodyM>{"Bill payment reminders"}</Text.BodyM>
            <ComingSoonBadge />
          </NotificationDescription>
        </Notification>
      </View>
    </Container>
  );
};
