import React from "react";
import styled from "styled-components/native";
import { SFC, HelpIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { useOpenAuthorizedHelpInBrowser } from "@ailo/domains";

export const Header: SFC = ({ style }) => {
  const openHelpInBrowser = useOpenAuthorizedHelpInBrowser();

  return (
    <StyledButton onPress={openHelpInBrowser} leftIcon={HelpIcon} style={style}>
      {"Get Help"}
    </StyledButton>
  );
};

const StyledButton = styled(Button.Text)`
  margin: 14px;
`;
