import React from "react";
import { ListItemHeader } from "@ailo/ui";
import { SFC } from "@ailo/primitives";

const RentHeader: SFC = () => (
  <ListItemHeader
    text={"Auto Pay Rent"}
    style={{ paddingTop: 12, paddingBottom: 8 }}
  />
);
export { RentHeader };
