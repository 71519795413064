import { AiloRN } from "@ailo/ailorn";
import { Contact } from "@ailo/domains";
import { Screens, useNavigation } from "local/common";
import { useCallbackRef } from "@ailo/primitives";

export function useNavigateToNewChatCompose(): (
  organisationAilorn: AiloRN<"authz:organisation">,
  contacts: Contact[]
) => void {
  const navigation = useNavigation();

  return useCallbackRef(
    (organisationAilorn: AiloRN<"authz:organisation">, contacts: Contact[]) => {
      navigation.push(Screens.NewChatMessage, {
        organisationId: organisationAilorn.internalId,
        contacts
      });
    },
    [navigation]
  );
}
