import styled from "styled-components/native";
import { Colors, Text } from "@ailo/primitives";
import { View } from "react-native";

export const ListHeader = styled(Text.BodyM)`
  padding: 20px 16px 16px;
  color: ${Colors.TEXT.DARK.SECONDARY};
`;

export const DisplaySRed = styled(Text.DisplayS)`
  color: ${Colors.AILO_RED};
`;

export const PaddedContainer = styled(View)`
  padding-left: 16px;
  padding-right: 16px;
`;
