import { AiloRN } from "@ailo/ailorn";
import { DateTimeWithTimeZone } from "@ailo/date";
import { maskNumber } from "@ailo/domains";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import Big from "big.js";
import { useCurrentLegalEntity } from "local/common";
import { FrequencyEnum } from "local/domain/ledger";
import {
  GetAutoWithdrawPlanByIdQueryVariables,
  useGetAutoWithdrawPlanByIdQuery,
  BankAccount,
  Money
} from "local/graphql";

export type AutoWithdrawPlan = {
  walletId: string;
  wallet: {
    owner: {
      reference: AiloRN;
    };
  };
  owner: AiloRN;
  enabled: boolean;
  details: {
    frequency: FrequencyEnum;
    startDate: string;
    endDate: string | null | undefined;
    setAsideAmount?: Money;
    anniversary?: number | null;
    anniversaryDaysOfMonth?: number[] | null;
    paymentMethodDestinations: Array<{
      paymentMethodId: string;
      percentage: number;
      accountName?: string | null;
      accountNumber?: string | null;
    }>;
    isLastDayOfTheMonth: boolean | null | undefined;
    nextFireTime: string;
    createdAt: string;
    createdBy: string | undefined;
  };
};

export function useAutoWithdrawPlanById(
  autoWithdrawPlanId: string
): MappedQueryResult<AutoWithdrawPlan, GetAutoWithdrawPlanByIdQueryVariables> {
  const [legalEntity] = useCurrentLegalEntity();
  const result = useGetAutoWithdrawPlanByIdQuery({
    variables: { autoWithdrawPlanId, legalEntityId: legalEntity.id.toString() },
    fetchPolicy: "cache-and-network"
  });

  return useMappedQueryResult(result, (data) => {
    if (!data.autoWithdrawPlanById) {
      throw new ApolloError({
        errorMessage: "autoWithdrawPlanById query returned null"
      });
    }

    const { walletId, enabled, details, wallet, owner } =
      data.autoWithdrawPlanById;

    const totalShares = details.paymentMethodDestinations.reduce(
      (sum, current) => sum + current.share,
      0
    );

    return {
      walletId,
      wallet: {
        owner: {
          reference: AiloRN.fromString(wallet.owner.reference)
        }
      },
      owner: AiloRN.fromString(owner),
      enabled,
      details: {
        frequency: details.frequency as any as FrequencyEnum,
        startDate: details.startDate,
        endDate: details.endDate,
        setAsideAmount: details.setAsideAmount || undefined,
        anniversary: details.anniversary,
        anniversaryDaysOfMonth: details.anniversaryDaysOfMonth,
        paymentMethodDestinations: details.paymentMethodDestinations
          .filter(
            (destination) =>
              destination.paymentMethod.__typename === "BankAccount"
          )
          .map((destination) => ({
            paymentMethodId: destination.paymentMethodId,
            percentage: Big(destination.share)
              .div(totalShares)
              .round(4)
              .toNumber(),
            accountNumber:
              maskNumber(
                (destination.paymentMethod as BankAccount).accountNumber
              ) ?? "",
            accountName:
              (destination.paymentMethod as BankAccount).accountName ?? ""
          })),
        isLastDayOfTheMonth: details.isLastDayOfTheMonth,
        nextFireTime: details.nextFireTime,
        createdAt: DateTimeWithTimeZone.from(
          details.createdAt,
          details.timezoneId
        ).format("h:mma D MMM YYYY"),
        createdBy: undefined
      }
    };
  });
}
