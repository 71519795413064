import React, { FC } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { isEmpty } from "lodash";
import { Colors, LoadingSynchroniser, Text } from "@ailo/primitives";
import { AddPaymentMethodButton, PaymentMethodType } from "local/domain/ledger";
import {
  ScreenComponent,
  useCurrentLegalEntity,
  useOnFocus
} from "local/common";
import { PaymentMethodList, SectionListSection } from "./PaymentMethodList";
import { parsePaymentMethods } from "local/domain/ledger/components/PaymentMethodsList/parsePaymentMethods";
import { ButtonVariant } from "@ailo/ui";
import { useGetPaymentMethodsQuery } from "local/graphql";

type Props = {
  onAddPaymentMethodPress: () => void;
  onPaymentMethodSelection: (paymentMethodId: string, title: string) => void;
  addPaymentMethodButtonText?: string;
  contentHeader?: string;
  paymentMethodFilter?: PaymentMethodType;
  moreSections?: Array<SectionListSection>;
  showAutoBadges?: boolean;
  buttonVariant?: ButtonVariant;
};

const PaymentMethodListContainer: FC<Props> = ({
  onPaymentMethodSelection,
  onAddPaymentMethodPress,
  addPaymentMethodButtonText,
  paymentMethodFilter,
  contentHeader,
  moreSections,
  showAutoBadges,
  buttonVariant
}) => {
  const { bottom } = useSafeAreaInsets();

  const [legalEntity] = useCurrentLegalEntity();
  const { loading, data, refetch } = useGetPaymentMethodsQuery({
    variables: {
      methodType: paymentMethodFilter,
      legalEntityId: legalEntity.id.toString()
    }
  });
  useOnFocus(refetch);

  if (loading) {
    return (
      <ScreenContainer>
        <PaymentMethodList.Loading />
        <View
          style={{
            paddingBottom: bottom,
            backgroundColor: Colors.WHITE
          }}
        >
          <AddPaymentMethodButton.Loading />
        </View>
      </ScreenContainer>
    );
  }

  const nonNullPaymentMethods = parsePaymentMethods(data);
  const hasPaymentMethods = !isEmpty(nonNullPaymentMethods);

  return (
    <LoadingSynchroniser>
      <ScreenContainer>
        {!!contentHeader && (
          <HeaderContainer>
            <Text.BodyM color={Colors.TEXT.DARK.PRIMARY}>
              {contentHeader}
            </Text.BodyM>
          </HeaderContainer>
        )}
        <PaymentMethodList
          paymentMethods={nonNullPaymentMethods}
          onPaymentMethodSelection={onPaymentMethodSelection}
          showAutoBadges={showAutoBadges}
          moreSections={moreSections}
        />
        {hasPaymentMethods && (
          <View
            style={{
              paddingBottom: bottom,
              backgroundColor: Colors.WHITE
            }}
          >
            <AddPaymentMethodButton
              onPress={onAddPaymentMethodPress}
              text={addPaymentMethodButtonText}
              variant={buttonVariant}
            />
          </View>
        )}
      </ScreenContainer>
    </LoadingSynchroniser>
  );
};

const ScreenContainer = styled(ScreenComponent)`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderContainer = styled(View)`
  padding: 16px;
  text-align: center;
  border-bottom-width: 1px;
  border-color: ${Colors.GRAYSCALE.OUTLINE};
  background: ${Colors.WHITE};
`;

export { PaymentMethodListContainer };
