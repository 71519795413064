import { Colors } from "@ailo/primitives";
import {
  ErrorBoundary,
  ScreenComponent,
  useHasBeenFocused,
  useOnFocus
} from "@ailo/services";
import { ExtendableHeaderList, SpinnerOverlay } from "@ailo/ui";
import {
  useCurrentLegalEntity,
  usePropertySelectorContext,
  useShowInAppReview
} from "local/common";
import React from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  GetToKnowAiloCard,
  InvestorManagementRentCard,
  InvestorRenterSetupStatusMessageCard,
  InvestorRentHistoryCard,
  PendingTasksCard
} from "../common";
import { InvestorRentCardErrorBoundary } from "./components";
import {
  PlatformFeatureId,
  useGetTotalClearedRentCountForUserQuery
} from "local/graphql";
import {
  InvestorFormerTenanciesCard,
  InvestorFormerTenanciesErrorCard
} from "../../../domain/ledger/components/InvestorFormerTenanciesCard";
import { useHasFeature } from "@ailo/domains";

export function InvestorRentTabScreen(): React.ReactElement {
  return (
    <ScreenComponent statusBarStyle={"light-content"} name={"Rent Overview"}>
      <ScreenContent />
    </ScreenComponent>
  );
}

export function ScreenContent(): React.ReactElement | null {
  const [legalEntity] = useCurrentLegalEntity();

  const hasBeenFocused = useHasBeenFocused();
  const forceLoadingState = !hasBeenFocused;

  const { currentProperty } = usePropertySelectorContext();
  const showFormerTenanciesCard = useHasFeature(
    PlatformFeatureId.InvestorFormerTenanciesCard
  );

  if (!currentProperty?.management.id) {
    throw new Error("No current management id");
  }

  const totalClearedRentResult = useGetTotalClearedRentCountForUserQuery({
    variables: { ownerRef: legalEntity.id.toString() }
  });

  const managementClearedRentResult = useGetTotalClearedRentCountForUserQuery({
    variables: { ownerRef: currentProperty?.management.id.toString() }
  });

  useOnFocus(totalClearedRentResult.refetch);
  useOnFocus(managementClearedRentResult.refetch);
  useShowInAppReview();

  if (currentProperty?.__typename !== "OwnedProperty") {
    return null;
  }
  const hasSharesOwned = !!currentProperty.ownership.sharesOwnedRatio;

  const totalClearedRentCount =
    totalClearedRentResult?.data?.totalClearedRentReceivedInWallets?.count;

  const managementTotalClearedRentCount =
    managementClearedRentResult?.data?.totalClearedRentReceivedInWallets?.count;

  if (forceLoadingState) {
    return <ScreenContent.Loading />;
  }

  return (
    <ExtendableHeaderList
      headerBackgroundColor={Colors.CHARCOAL}
      headerInitialHeight={64}
    >
      <PaddedContainer>
        <InvestorRentCardErrorBoundary>
          <InvestorManagementRentCard
            managementId={currentProperty.management.id.internalId}
            totalClearedRentCount={totalClearedRentCount}
            hasSharesOwned={hasSharesOwned}
          />
          <InvestorRenterSetupStatusMessageCard
            totalClearedRentCount={totalClearedRentCount}
            managementTotalClearedRentCount={managementTotalClearedRentCount}
            hasSharesOwned={hasSharesOwned}
          />
          <PendingTasksCard />

          <InvestorRentHistoryCard
            managementId={currentProperty.management.id.internalId}
            totalClearedRentCount={totalClearedRentCount}
            hasSharesOwned={hasSharesOwned}
          />
        </InvestorRentCardErrorBoundary>
        {showFormerTenanciesCard && (
          <ErrorBoundary
            fallbackComponent={() =>
              InvestorFormerTenanciesErrorCard(undefined)
            }
          >
            <InvestorFormerTenanciesCard
              managementId={currentProperty.management.id.internalId}
            />
          </ErrorBoundary>
        )}
        <GetToKnowAiloCard />
      </PaddedContainer>
    </ExtendableHeaderList>
  );
}

ScreenContent.Loading =
  function InvestorTransactionsTabScreenContentLoading(): React.ReactElement {
    return (
      <ExtendableHeaderList
        headerBackgroundColor={Colors.CHARCOAL}
        headerInitialHeight={64}
      >
        <SpinnerOverlay style={{ marginTop: 24 }} />
      </ExtendableHeaderList>
    );
  };

const PaddedContainer: React.FC = ({ children }) => {
  const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
  return (
    <View style={{ marginTop: -64, marginBottom: 32 + bottomSafeAreaInset }}>
      {children}
    </View>
  );
};
