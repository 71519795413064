import React from "react";
import { ScreenComponent } from "@ailo/services";
import { Colors } from "@ailo/primitives";
import {
  ChatScreen,
  FloatingNewMessageButton,
  useChatListContext
} from "@ailo/domains";

export function CommsChatTabScreen(): React.ReactElement {
  const {
    chatList: { loading }
  } = useChatListContext();

  return (
    <ScreenComponent
      statusBarStyle={"light-content"}
      style={{ backgroundColor: Colors.CHARCOAL }}
    >
      <ChatScreen
        emptyStateText={"Create a new chat to message your property manager"}
      />
      {!loading && <FloatingNewMessageButton />}
    </ScreenComponent>
  );
}
