import { useCallback, useState } from "react";
import { LayoutRectangle } from "react-native";

type onLayout = (event: any) => void;

export function useLayout(): [LayoutRectangle, onLayout] {
  const [layout, setLayout] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const onLayout = useCallback(
    (event) => setLayout(event.nativeEvent.layout),
    [setLayout]
  );

  return [layout, onLayout];
}
