import React, { FC } from "react";
import {
  ScreenWithHeaderAndAction,
  SuccessAlertMessage
} from "local/domain/liabilityPaymentPlan";
import { Text } from "@ailo/primitives";
import { Screens, useNavNoButton, useRoute } from "local/common";
import { Frequency } from "local/domain/ledger";
import { FeeUIHelpers } from "@ailo/domains";
import moment from "moment";

const SaveScreen: FC = () => {
  const params = useRoute<Screens.LiabilityPlanSave>().params;
  useNavNoButton();

  const {
    frequency,
    startDate,
    amount,
    paymentMethodId,
    anniversary,
    onSuccess
  } = params;

  if (
    !frequency ||
    !startDate ||
    !amount ||
    !paymentMethodId ||
    anniversary == null
  ) {
    throw "Provide all fields";
  }

  return (
    <ScreenWithHeaderAndAction
      primaryAction={{
        text: "Done",
        onClick: onSuccess
      }}
      scrollable={false}
    >
      <SuccessAlertMessage
        style={{ flex: 1 }}
        header={"Auto Rent Payments Setup"}
        summary={
          <Text.BodyM style={{ textAlign: "center" }}>
            <Text.BodyM>
              {`Your automatic rent payments have been setup.\n`}
            </Text.BodyM>
            <Text.BodyM weight={"medium"}>
              {FeeUIHelpers.format(amount.cents)}
            </Text.BodyM>
            <Text.BodyM>{` will be paid `}</Text.BodyM>
            <Text.BodyM>{Frequency.humanReadablePrefix(frequency)} </Text.BodyM>
            <Text.BodyM weight={"medium"}>
              {Frequency.humanReadable(startDate, frequency)}
            </Text.BodyM>
            <Text.BodyM>{`, beginning `}</Text.BodyM>
            <Text.BodyM weight={"medium"}>{formatDate(startDate)}</Text.BodyM>
          </Text.BodyM>
        }
      />
    </ScreenWithHeaderAndAction>
  );
};

function formatDate(date?: Date | undefined | null, def?: string): string {
  return date ? moment(date).format("D MMM YYYY") : def || "Unknown";
}

export { SaveScreen };
