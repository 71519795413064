import { Screens } from "../Screens";
import { useNavigateToPreviousScreen as useNavigateToPreviousScreenFromServices } from "@ailo/services";

/**
 * {@link useNavigateToPreviousScreenFromServices}
 */
export function useNavigateToPreviousScreen(): ReturnType<
  typeof useNavigateToPreviousScreenFromServices
> {
  return useNavigateToPreviousScreenFromServices({
    fallbackScreen: Screens.TabNavigator
  });
}
