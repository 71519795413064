import React, { FC } from "react";
import { Colors } from "@ailo/primitives";
import {
  AllowNotificationsButton,
  SkipButton,
  AllowNotificationsImage,
  InvestorNotificationsDescription,
  TenantNotificationsDescription,
  MixedNotificationsDescription
} from "./components";
import { HeaderOverlay } from "local/domain/onboarding";
import { View } from "react-native";
import styled from "styled-components/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface Props {
  isInvestor: boolean;
  isTenant: boolean;
}

export const BaseAllowNotificationsKeyActionScreen: FC<Props> = ({
  isInvestor,
  isTenant
}) => {
  const { bottom } = useSafeAreaInsets();

  return (
    <Container style={{ paddingBottom: bottom }}>
      <Wrapper>
        <AllowNotificationsImage />
        {isInvestor && !isTenant && <InvestorNotificationsDescription />}
        {isTenant && !isInvestor && <TenantNotificationsDescription />}
        {isInvestor && isTenant && <MixedNotificationsDescription />}
      </Wrapper>
      <View>
        <AllowNotificationsButton />
        <SkipButton style={{ marginTop: 16, marginBottom: 20 }} />
      </View>
      <HeaderOverlay />
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  flex-direction: column;
  background-color: ${Colors.WHITE};
`;

const Wrapper = styled(View)`
  flex: 1;
`;
