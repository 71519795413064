import React, { FC, useCallback } from "react";
import { ListItem } from "@ailo/ui";
import { RightChevronIcon } from "@ailo/primitives";
import { Screens, useNavigation } from "local/common";

type Props = {
  onSuccess: (paymentMethodId: string) => void;
};

const OtherPaymentMethodListItem: FC<Props> = ({ onSuccess }) => {
  const navigation = useNavigation();

  const addOtherPaymentMethod = useCallback(() => {
    navigation.navigate(Screens.OtherPaymentMethodsScreen, { onSuccess });
  }, [navigation, onSuccess]);

  return (
    <ListItem
      header={"Other Payment Method"}
      onPress={addOtherPaymentMethod}
      style={{ alignItems: "center" }}
      rightComponent={<RightChevronIcon width={20} height={20} />}
      accessibilityLabel={"other-payment-method-list-item"}
    />
  );
};

export { OtherPaymentMethodListItem };
