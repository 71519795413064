import { createStackNavigator } from "@react-navigation/stack";
import { AppNavigatorParamList, Screens } from "local/common";
import React from "react";
import {
  getInvestorRentTabModalScreens,
  getTenantRentTabModalScreens
} from "../../tabs";
import { CardStackNavigator } from "./CardStackNavigator";

const Stack = createStackNavigator<AppNavigatorParamList>();

export function AppNavigator(): React.ReactElement {
  return (
    <Stack.Navigator mode={"modal"}>
      <Stack.Screen
        name={Screens.Home}
        options={{ headerShown: false, animationEnabled: false }}
        component={CardStackNavigator}
      />

      {getInvestorRentTabModalScreens(Stack)}
      {getTenantRentTabModalScreens(Stack)}
    </Stack.Navigator>
  );
}
