import React from "react";
import { View, ViewStyle } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { formatMoney, MoneyTextInput, TextInput } from "@ailo/ui";
import { useRoute, Screens } from "local/common";
import { formatMoneyInputWithoutDollar } from "@ailo/domains";
import { PayLiabilityFormData } from "../EditablePaymentDisplay";

interface Props {
  inputDisabled?: boolean;
  style?: ViewStyle;
  accessibilityLabel?: string;
}

interface Statics {
  Loading: React.FC;
}

const PaymentAmountInputField: React.FC<Props> & Statics = ({
  style,
  accessibilityLabel
}) => {
  const { control, errors } = useFormContext<PayLiabilityFormData>();

  const { defaultPaymentAmount } = useRoute<Screens.PayBillLiability>().params;

  const displayPaymentAmount = formatMoney(
    { cents: defaultPaymentAmount },
    { withSign: false }
  );

  return (
    <View style={style} accessible={true}>
      <Controller
        name={"paymentAmount"}
        control={control}
        defaultValue={displayPaymentAmount}
        render={({ onChange, onBlur, value }): React.ReactElement => (
          <MoneyTextInput
            value={value}
            onChangeText={(text): void =>
              onChange(formatMoneyInputWithoutDollar([text]))
            }
            onBlur={onBlur}
            error={errors?.paymentAmount?.message}
            keyboardType={"decimal-pad"}
            returnKeyType={"done"}
            accessibilityLabel={accessibilityLabel}
          />
        )}
        rules={{
          required: "Payment amount is required",
          max: {
            value: 100000.0,
            message: "Maximum Limit Reached"
          },
          pattern: {
            value: /^\$[0-9]+(\.[0-9]{2})?$/,
            message: "Payment amount must be valid"
          }
        }}
      />
    </View>
  );
};

interface LoadingProps {
  style?: ViewStyle;
}

const Loading: React.FC<LoadingProps> = ({ style }) => {
  return (
    <View style={style}>
      <TextInput editable={false} />
    </View>
  );
};

PaymentAmountInputField.Loading = Loading;

export { PaymentAmountInputField };
