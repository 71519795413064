import React, { useState } from "react";
import { SFC } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { OnboardingTaskId } from "local/graphql";
import { useCompleteOnboardingTask } from "local/domain/onboarding";

const AcceptButton: SFC = ({ style }) => {
  const [disabled, setDisabled] = useState(false);
  const completeOnboardingTask = useCompleteOnboardingTask();

  const acceptButtonPressed = async (): Promise<void> => {
    setDisabled(true);
    completeOnboardingTask(OnboardingTaskId.AcceptTermsOfService);
  };
  const buttonText = "I Accept";

  return (
    <Button.Primary
      disabled={disabled}
      type={"large"}
      style={style}
      onPress={acceptButtonPressed}
    >
      {buttonText}
    </Button.Primary>
  );
};

export { AcceptButton };
