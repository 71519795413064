import React from "react";
import { View } from "react-native";
import { Colors, opacify, SFC } from "@ailo/primitives";
import { SetupAutoWalletTransferButton, WalletsList } from "./components";
import { ListItemHeader } from "@ailo/ui";

function Container({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <View>
      <ListItemHeader text={"Auto Transfers"} />
      {children}
    </View>
  );
}

const WalletSection: SFC = () => {
  const enableElement = (
    <View
      style={{
        backgroundColor: Colors.WHITE,
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: opacify(Colors.SPACE_BLACK, 0.1),
        borderTopWidth: 0,
        padding: 16
      }}
    >
      <SetupAutoWalletTransferButton style={{ paddingHorizontal: 50 }} />
    </View>
  );

  return (
    <Container>
      <WalletsList />
      {enableElement}
    </Container>
  );
};

export { WalletSection };
