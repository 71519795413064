import React, { FC, useCallback } from "react";
import { Button, Money } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";

type Props = {
  failed: boolean;
  liabilityId: string;
  amount: Money;
};

export const PayButton: FC<Props> = ({ failed, liabilityId, amount }) => {
  const navigation = useNavigation<Screens.TenantRentTab>();
  const onPayClicked = useCallback(() => {
    navigation.navigate(Screens.TenancyDepositSelectPaymentMethod, {
      liabilityId,
      amount
    });
  }, [navigation, liabilityId, amount]);

  return (
    <Button.Primary
      type={"medium"}
      style={{ marginTop: 16 }}
      onPress={onPayClicked}
    >
      {failed ? "Try Again" : "Pay Now"}
    </Button.Primary>
  );
};
