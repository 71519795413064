import React from "react";
import { Card } from "@ailo/ui";
import { UpcomingBillsList } from "local/domain/bill";
import { UpcomingBillsHeader } from "./UpcomingBillsHeader";
import { StyleProp, ViewStyle } from "react-native";

export const UpcomingBillsCardErrorComponent: React.FC<{
  style?: StyleProp<ViewStyle>;
}> = ({ style }) => {
  return (
    <Card style={style}>
      <UpcomingBillsHeader />
      <UpcomingBillsList.Empty
        text={"Error loading Outstanding"}
        style={{ marginBottom: 20 }}
      />
    </Card>
  );
};
