import { GetPaymentMethodWithFeesQuery } from "local/graphql";
import { FeeInfo, PaymentMethodTypeDomainEnum } from "@ailo/domains";

export function extractFeeInfo(
  paymentMethodData: GetPaymentMethodWithFeesQuery | undefined,
  paymentMethodId: string
): FeeInfo | undefined {
  const paymentMethod = paymentMethodData?.paymentMethodById;
  if (!paymentMethod) {
    throw new Error(`No payment method data for id ${paymentMethodId}`);
  }

  if (
    paymentMethod.__typename === PaymentMethodTypeDomainEnum.BankAccount ||
    paymentMethod.__typename === PaymentMethodTypeDomainEnum.CreditCard
  ) {
    const topUpFee = paymentMethod?.topUpFee;

    if (topUpFee && topUpFee.feeBps == 0 && topUpFee.feeFlatCents == 0) {
      return undefined;
    }

    return topUpFee
      ? { feeBps: topUpFee.feeBps, feeFlatCents: topUpFee.feeFlatCents }
      : undefined;
  }
}
