import React from "react";
import { ViewStyle } from "react-native";
import { Text } from "@ailo/primitives";

export function CardHeader({
  style
}: {
  style?: ViewStyle;
}): React.ReactElement {
  return (
    <Text.BodyM style={style} weight={"medium"}>
      {"My Rent"}
    </Text.BodyM>
  );
}
