import { PaymentMethod } from "local/domain/ledger";
import { GetPaymentMethodsQuery } from "local/graphql";
import { isPresent } from "ts-is-present";

export function parsePaymentMethods(
  data?: GetPaymentMethodsQuery
): PaymentMethod[] {
  const paymentMethods = data?.legalEntity?.paymentMethods?.items;
  if (paymentMethods == null) {
    throw new Error(
      "Unable to get payment methods for FilteredPaymentMethodsList"
    );
  }
  return paymentMethods.filter(isPresent);
}
