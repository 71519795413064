import { Screens, useNavigation, useRoute } from "local/common";
import React, { FC } from "react";
import { FrequencyEnum } from "local/domain/ledger";
import { SelectFrequencyScreen as Screen } from "../../../frequencyBasedComponents/SelectFrequencyScreen";

const SelectFrequencyScreen: FC = () => {
  const navigation = useNavigation<Screens.LiabilityPlanSelectFrequency>();
  const params = useRoute<Screens.LiabilityPlanSelectFrequency>().params;

  const onFrequencySelected = (frequency: FrequencyEnum): void => {
    navigation.navigate(Screens.LiabilityPlanSelectStartDate, {
      ...params,
      frequency
    });
  };

  return (
    <Screen
      heading={`How often do you want\ntransfers to occur?`}
      onFrequencySelected={onFrequencySelected}
    />
  );
};

export { SelectFrequencyScreen };
