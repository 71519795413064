import { EditIcon, Skeleton, Text } from "@ailo/primitives";
import { logApolloResultFailed, useOnFocus } from "@ailo/services";
import { Card, ListItem, ListItemAction, ListItemStyle } from "@ailo/ui";
import {
  useAutoPayLiabilityStatuses,
  useCanModifyAutoPayLiability
} from "local/domain/ledger";
import React, { FC } from "react";
import {
  Screens,
  useCurrentLegalEntity,
  useNavigation,
  usePropertySelectorContext
} from "local/common";
import { AiloRN } from "@ailo/ailorn";

function Container({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <Card title={"My Bills"} padding>
      {children}
    </Card>
  );
}

const listItemStyle: ListItemStyle = {
  borderType: "around",
  borderRadius: 4
};

export function MyBillsCard(): React.ReactElement | null {
  const navigation = useNavigation();
  const [legalEntity] = useCurrentLegalEntity();
  const { currentProperty } = usePropertySelectorContext();
  const autoPayStatusesResult = useAutoPayLiabilityStatuses();
  const { canModifyAutoPayLiability } = useCanModifyAutoPayLiability(
    currentProperty?.management?.id
  );

  useOnFocus(autoPayStatusesResult.refetch);

  if (autoPayStatusesResult.loading && !autoPayStatusesResult.data) {
    return <MyBillsCard.Loading />;
  }

  if (!autoPayStatusesResult.data || autoPayStatusesResult.error) {
    logApolloResultFailed(autoPayStatusesResult, {
      operationId: "MyBillsCard.autoPayStatusResult"
    });
    return null;
  }

  const enabledAutoPayBills = autoPayStatusesResult.data.filter((s) => {
    return (
      (s.payerId.equals(currentProperty?.management.id) ||
        s.payerId.equals(currentProperty?.managementFolio.ailorn) ||
        s.payerId.equals(legalEntity.id)) &&
      s.enabled
    );
  });

  const findEnabledManagementOrPersonalWalletPayerId = ():
    | AiloRN
    | undefined => {
    const forManagement = enabledAutoPayBills.find((s) =>
      s.payerId.equals(currentProperty?.management?.id)
    );
    const forManagementFolio = enabledAutoPayBills.find((s) =>
      s.payerId.equals(currentProperty?.managementFolio.ailorn)
    );
    const forPersonalWallet = enabledAutoPayBills.find((s) =>
      s.payerId.equals(legalEntity.id)
    );

    return (
      forManagement?.payerId ||
      forManagementFolio?.payerId ||
      forPersonalWallet?.payerId ||
      currentProperty?.management?.id
    );
  };

  const handleSetupAutoPayClick = (): void => {
    navigation.navigate(Screens.ManageAutoPayBills, {
      payerId: findEnabledManagementOrPersonalWalletPayerId()
    });
  };

  const isAnyAutoBillPayEnabled = (enabledAutoPayBills?.length || 0) > 0;

  if (!isAnyAutoBillPayEnabled) {
    return (
      <Container>
        <ListItem
          header={<Text.BodyS>{"Auto bill payments not setup"}</Text.BodyS>}
          rightComponent={
            canModifyAutoPayLiability ? (
              <ListItemAction
                label={"Setup now"}
                onPress={handleSetupAutoPayClick}
              />
            ) : undefined
          }
          style={listItemStyle}
        />
      </Container>
    );
  }

  return (
    <EnabledItem
      onPress={handleSetupAutoPayClick}
      payerId={findEnabledManagementOrPersonalWalletPayerId()}
    />
  );
}

const EnabledItem: FC<{
  onPress: () => void;
  payerId: AiloRN | undefined;
}> = ({ onPress, payerId }) => {
  const { canModifyAutoPayLiability } = useCanModifyAutoPayLiability(payerId);

  return (
    <Container>
      <ListItem
        header={
          <Text.BodyS>
            {"Auto bill payments "}
            <Text.BodyS weight={"medium"}>{"turned on"}</Text.BodyS>
          </Text.BodyS>
        }
        rightComponent={
          canModifyAutoPayLiability ? (
            <ListItemAction label={"Edit"} icon={EditIcon} onPress={onPress} />
          ) : (
            <ListItemAction label={"View"} onPress={onPress} />
          )
        }
        style={listItemStyle}
      />
    </Container>
  );
};

MyBillsCard.Loading = function MyBillsCardLoading(): React.ReactElement {
  return (
    <Container>
      <Skeleton style={{ width: 200, height: 15 }} />
    </Container>
  );
};
