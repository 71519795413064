import React, { FC } from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { Controller, Control } from "react-hook-form";
import { Text } from "@ailo/primitives";
import { TextInput } from "@ailo/ui";

interface Props {
  control: Control<{ accountNumber: string }>;
  hasError: boolean;
  style?: ViewStyle;
}

const AccountNumberInputField: FC<Props> = ({ control, hasError, style }) => {
  return (
    <View style={style}>
      <Label>{"Account number"}</Label>
      <Controller
        name={"accountNumber"}
        rules={{
          required: "Account number is required",
          pattern: {
            value: /^[0-9]{6,9}$/,
            message: "Account number must contain 6 to 9 digits"
          }
        }}
        defaultValue={""}
        render={({ onChange, onBlur, value }): React.ReactElement => (
          <TextInput
            {...{ onBlur, value }}
            digitsOnly
            onChangeText={onChange}
            hasError={hasError}
            keyboardType={"number-pad"}
            maxLength={9}
            testID={"account-number-input"}
          />
        )}
        control={control}
      />
    </View>
  );
};

const Label = styled(Text.BodyS)`
  margin-bottom: 8px;
`;

export { AccountNumberInputField };
