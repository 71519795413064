import React, { FC } from "react";
import { Colors, Text } from "@ailo/primitives";
import { Card, ListItem } from "@ailo/ui";
import { formatMoney } from "accounting";
import { ChangeAutoWithdrawPlanDisplay } from "../../../../../../../components";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";

interface Wallet {
  id: string;
  name: string;
  availableBalance: { cents: number };
  totalBalance: { cents: number };
  autoWithdrawPlans?: {
    items?: Array<{
      id: string;
    }> | null;
  } | null;
}

interface Props {
  wallet: Wallet;
}

const WalletListItem: FC<Props> = ({ wallet }) => {
  const { name, availableBalance, totalBalance, autoWithdrawPlans } = wallet;

  const availableBalanceSign = availableBalance.cents < 0 ? "-" : "";
  const formattedAvailableBalance =
    availableBalanceSign + formatMoney(Math.abs(availableBalance.cents) / 100);
  const totalBalanceSign = totalBalance.cents < 0 ? "-" : "";
  const formattedTotalBalance =
    totalBalanceSign + formatMoney(Math.abs(totalBalance.cents) / 100);

  const balances = (
    <>
      <Text.BodyS
        color={
          availableBalance.cents < 0
            ? Colors.STATUS.WARNING
            : Colors.TEXT.DARK.SECONDARY
        }
      >{`Available: ${formattedAvailableBalance}`}</Text.BodyS>
      <Text.BodyS
        color={
          totalBalance.cents < 0
            ? Colors.STATUS.WARNING
            : Colors.TEXT.DARK.SECONDARY
        }
      >{`Total: ${formattedTotalBalance}`}</Text.BodyS>
    </>
  );

  const withdrawPlanEnabled = useHasFeature(PlatformFeatureId.AutoWithdrawPlan);

  return (
    <Card>
      <ListItem header={name} subHeader={balances} />
      {withdrawPlanEnabled &&
        (autoWithdrawPlans?.items || []).map(({ id }) => (
          <ChangeAutoWithdrawPlanDisplay key={id} autoWithdrawPlanId={id} />
        ))}
    </Card>
  );
};

export { WalletListItem };
