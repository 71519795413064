import { Alert, ListItem, PercentInput } from "@ailo/ui";
import Big from "big.js";
import { Screens, useNavigation, useRoute } from "local/common";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import React, { ReactElement, useState } from "react";
import { BankAccountPaymentMethodOption } from "../../types";
import { unselectZeroPercentagePaymentMethods } from "./unselectZeroPercentagePaymentMethods";

export function SelectPaymentDestinationPercentagesScreen(): ReactElement {
  const navigation = useNavigation<Screens.AutoWithdrawPlanSelectFrequency>();
  const params =
    useRoute<Screens.AutoWithdrawPlanSelectPaymentDestinations>().params;

  const {
    bankPaymentMethods: initialBankPaymentMethods,
    onPressNextOnSelectPaymentDestinationPercentageScreen
  } = params;

  const [bankPaymentMethods, setBankPaymentMethods] = useState<
    BankAccountPaymentMethodOption[]
  >(initialBankPaymentMethods);

  const selectedPaymentMethods = bankPaymentMethods.filter(
    (method) => method.selected
  );
  const lastPaymentMethodId =
    selectedPaymentMethods[selectedPaymentMethods.length - 1].id;

  const onNext = (): void => {
    const paymentMethodsWithZeroUnselected =
      unselectZeroPercentagePaymentMethods(bankPaymentMethods);

    if (onPressNextOnSelectPaymentDestinationPercentageScreen) {
      onPressNextOnSelectPaymentDestinationPercentageScreen(
        paymentMethodsWithZeroUnselected
      );
    } else {
      navigation.navigate(Screens.AutoWithdrawPlanSelectFrequency, {
        ...params,
        bankPaymentMethods: paymentMethodsWithZeroUnselected
      });
    }
  };

  const totalPercentage = bankPaymentMethods
    .filter((method) => method.selected)
    .reduce(
      (sum, current) =>
        Big(sum)
          .add(current.percentage ?? 0)
          .toNumber(),
      0
    );

  const onChangePaymentDestinationPercentage = (
    paymentMethodId: string,
    percentage: number
  ): void => {
    if (paymentMethodId === lastPaymentMethodId)
      throw new Error("Cannot change last payment method percentage");

    setBankPaymentMethods((previous) => {
      const penultimateTotal = previous
        .filter((method) => method.selected)
        .slice(0, -1)
        .map((paymentMethod) =>
          paymentMethod.id === paymentMethodId
            ? { ...paymentMethod, percentage }
            : paymentMethod
        )
        .reduce(
          (acc, current) =>
            Big(acc)
              .add(current.percentage ?? 0)
              .toNumber(),
          0
        );

      return previous.map((paymentMethod) =>
        paymentMethod.id === paymentMethodId
          ? { ...paymentMethod, percentage }
          : paymentMethod.id === lastPaymentMethodId
          ? {
              ...paymentMethod,
              percentage: Math.max(
                Big(1).sub(penultimateTotal).round(4).toNumber(),
                0
              )
            }
          : paymentMethod
      );
    });
  };

  return (
    <ScreenWithHeaderAndAction
      header={
        "What percentage of income should be transferred into each account?"
      }
      primaryAction={{
        disabled: totalPercentage !== 1,
        onClick: onNext,
        text: "Next"
      }}
      buttonInfo={
        totalPercentage > 1 ? (
          <Alert
            type={"error"}
            message={`Percentages must add to 100%. Current total is ${Big(
              totalPercentage
            )
              .mul(100)
              .toString()}%.`}
          />
        ) : undefined
      }
    >
      {bankPaymentMethods
        .filter((method) => method.selected)
        .map((bankPaymentMethod, index) => (
          <ListItem
            key={index}
            header={bankPaymentMethod.accountName ?? ""}
            subHeader={bankPaymentMethod.accountNumber ?? ""}
            rightComponent={
              <PercentInput
                value={bankPaymentMethod.percentage ?? 0}
                onChange={(value) => {
                  onChangePaymentDestinationPercentage(
                    bankPaymentMethod.id,
                    Math.min(value, 1)
                  );
                }}
                disabled={bankPaymentMethod.id === lastPaymentMethodId}
                containerStyle={{ minWidth: 90 }}
              />
            }
          />
        ))}
    </ScreenWithHeaderAndAction>
  );
}
