import { CommsContextProviders } from "@ailo/domains";
import React, { ReactElement, ReactNode } from "react";
import { usePropertySelectorContext } from "local/common";
import {
  useNavigateToChatList,
  useNavigateToNewChatParticipants,
  useOpenChat,
  useOpenThread
} from "local/tabs";

export function CommsContextProviderFromProperty({
  children
}: {
  children: ReactNode;
}): ReactElement {
  const { currentProperty } = usePropertySelectorContext();

  const agencyAilorn = currentProperty?.agency.organisationAilorn;
  const agencyName = currentProperty?.agency.organisationName;

  if (!agencyAilorn) {
    throw new Error("Invalid Agency Ailorn");
  }

  if (!agencyName) {
    throw new Error("Invalid Agency name");
  }

  return (
    <CommsContextProviders
      organisationAilorn={agencyAilorn}
      agencyName={agencyName}
      navigationHooks={{
        useNavigateToNewChatMessage: useNavigateToNewChatParticipants,
        useNavigateToChatList,
        useOpenChat,
        useOpenThread,
        useGenerateOpenChatLink: () => undefined
      }}
    >
      {children}
    </CommsContextProviders>
  );
}
