import React, { FC } from "react";
import {
  RenterSettingUpMessageCard,
  RenterSetUpNoAutoWithdrawMessageCard,
  RenterSetUpWithAutoWithdrawMessageCard
} from "../RenterSetupStatusMessageCard";
import { didQueryNotLoadYet, useOnFocus } from "@ailo/services";
import { View } from "react-native";
import { CloseButton } from "../CloseButton";
import { useClosableAction, useCurrentPropertyWalletData } from "./hooks";

interface Props {
  managementTotalClearedRentCount: number | undefined;
  totalClearedRentCount: number | undefined;
  hasSharesOwned: boolean;
}

export function InvestorRenterSetupStatusMessageCard({
  managementTotalClearedRentCount,
  totalClearedRentCount,
  hasSharesOwned
}: Props): React.ReactElement | null {
  const { result, enabledAlready } = useCurrentPropertyWalletData();
  useOnFocus(result.refetch);

  if (
    didQueryNotLoadYet(result) ||
    totalClearedRentCount === undefined ||
    !hasSharesOwned
  ) {
    return null;
  }

  if (totalClearedRentCount <= 0) {
    return <RenterSettingUpMessageCard />;
  } else if (
    totalClearedRentCount == 1 &&
    managementTotalClearedRentCount == 1
  ) {
    return <ClosableCard autoWithdrawEnabled={enabledAlready} />;
  }

  return null;
}

const ClosableCard: FC<{ autoWithdrawEnabled?: boolean }> = ({
  autoWithdrawEnabled
}) => {
  const { dismissed, onClosePress } = useClosableAction(
    "investor_rent_received_card_dismissed"
  );

  if (dismissed) return null;

  return (
    <View style={{ position: "relative" }}>
      <CloseButton
        onClose={onClosePress}
        size={"small"}
        hideShadow={true}
        containerStyle={{
          position: "absolute",
          top: 18,
          right: 22
        }}
      />
      {autoWithdrawEnabled ? (
        <RenterSetUpWithAutoWithdrawMessageCard />
      ) : (
        <RenterSetUpNoAutoWithdrawMessageCard />
      )}
    </View>
  );
};
