import * as WebBrowser from "expo-web-browser";
import {
  CheckBoxOffIcon,
  CheckBoxOnIcon,
  Colors,
  EditIcon,
  Link
} from "@ailo/primitives";
import {
  didQueryNotLoadYet,
  didQuerySucceed,
  logApolloResultFailed,
  useEnvironment,
  useNavigateToThisScreen,
  withScreenComponent
} from "@ailo/services";
import {
  Button,
  DateTimeWithTimeZone,
  ErrorAlertScreen,
  formatAddress,
  ListItem,
  SpinnerOverlay
} from "@ailo/ui";
import {
  ScreenContainer,
  ScreenHeader,
  ScreenPadding,
  Screens,
  useNavCloseButton,
  useNavigation,
  useRoute
} from "local/common";

import React from "react";
import { useWaitForLedgerKyc } from "./useWaitForLedgerKyc";
import { useGetCurrentPersonProfileDetailsQuery } from "local/graphql";
import { useHelpUrl } from "@ailo/domains";
import { formatPersonName } from "@ailo/domain-helpers";

export const AddProfileDetailsScreen = withScreenComponent(
  {
    statusBarStyle: "dark-content",
    viewTitle: null
  },
  (): React.ReactElement | null => {
    const { ZENDESK_KYC_LEARN_MORE_PATH } = useEnvironment();
    const [, learnMoreUrlPromise] = useHelpUrl({
      path: ZENDESK_KYC_LEARN_MORE_PATH
    });
    const navigation = useNavigation();
    const result = useGetCurrentPersonProfileDetailsQuery();
    const returnToThisScreen = useNavigateToThisScreen();
    const {
      params: { onProceed, title, message }
    } = useRoute<Screens.AddProfileDetails>();
    useNavCloseButton();

    const { doAction: proceed, loading: waitingToProceed } =
      useWaitForLedgerKyc(onProceed);

    if (didQueryNotLoadYet(result)) {
      return <SpinnerOverlay />;
    }

    if (!didQuerySucceed(result) || !result.data.effectiveUser) {
      logApolloResultFailed(result, {
        operationId: "result"
      });
      return <ErrorAlertScreen onRetry={result.refetch} />;
    }

    const person = result.data.effectiveUser.person;

    const items = [
      {
        label: "Given Name",
        value: person.firstName ? formatPersonName(person) : undefined,
        sufficient: !!(person.firstName && person.lastName),
        onPress(): void {
          navigation.navigate(Screens.EditGivenName, {
            title: "Add Given Name",
            submitButtonLabel: "Next",
            onSuccess: returnToThisScreen
          });
        }
      },
      {
        label: "Residential Address",
        value: formatAddress(person),
        sufficient: !!(
          person.unitStreetNumber &&
          person.streetName &&
          person.suburb &&
          person.state &&
          person.postcode
        ),
        onPress(): void {
          navigation.navigate(Screens.EditAddress, {
            title: "Add Residential Address",
            submitButtonLabel: "Next",
            onSuccess: returnToThisScreen
          });
        }
      },
      {
        label: "Date of Birth",
        value: person.birthDate
          ? DateTimeWithTimeZone.fromLocalDate(person.birthDate).format(
              "D MMMM YYYY"
            )
          : undefined,
        sufficient: !!person.birthDate,
        onPress(): void {
          navigation.navigate(Screens.EditBirthDate, {
            title: "Add Date of Birth",
            submitButtonLabel: "Next",
            onSuccess: returnToThisScreen
          });
        }
      }
    ];

    const titleText = title || "More details required";
    const defaultMessage = `Before you’re able to transfer funds, please complete your profile so that we can verify your identity.`;
    const messageText = message || defaultMessage;

    return (
      <>
        <ScreenContainer
          padding={false}
          stickyBottom={
            <Button.Primary disabled={!person.kycVerified} onPress={proceed}>
              {"Next"}
            </Button.Primary>
          }
        >
          <ScreenPadding>
            <ScreenHeader
              title={titleText}
              description={
                <>
                  {messageText}{" "}
                  <Link
                    variant={"primary"}
                    onPress={async () => {
                      const url = await learnMoreUrlPromise;
                      WebBrowser.openBrowserAsync(url);
                    }}
                  >
                    {"Learn more"}
                  </Link>{" "}
                  {"about why we need this information and how we keep it safe"}
                </>
              }
            />
          </ScreenPadding>

          {items.map((item) => (
            <ListItem
              key={item.label}
              leftComponent={
                item.sufficient ? (
                  <CheckBoxOnIcon
                    color={Colors.STATUS.SUCCESS}
                    width={20}
                    height={20}
                    style={{ marginRight: 18 }}
                  />
                ) : (
                  <CheckBoxOffIcon
                    color={Colors.GRAYSCALE.OUTLINE}
                    width={20}
                    height={20}
                    style={{ marginRight: 18 }}
                  />
                )
              }
              rightComponent={<EditIcon width={20} height={20} />}
              header={item.label}
              subHeader={item.value}
              onPress={item.onPress}
            />
          ))}
        </ScreenContainer>
        {waitingToProceed && <SpinnerOverlay />}
      </>
    );
  }
);
