import React from "react";
import { Card, ListItem } from "@ailo/ui";
import { Text, SFC } from "@ailo/primitives";
import { WalletListItem } from "./components";

interface Props {
  wallets: Array<{
    id: string;
    name: string;
    availableBalance: { cents: number };
    totalBalance: { cents: number };
    autoWithdrawPlans?: {
      items?: Array<{
        id: string;
      }> | null;
    } | null;
  }>;
}

interface Statics {
  Loading: SFC;
}

const WalletsList: SFC<Props> & Statics = ({ wallets }) => {
  return (
    <>
      {wallets.map((wallet) => (
        <WalletListItem key={wallet.id} wallet={wallet} />
      ))}
    </>
  );
};

const LoadingListItem: SFC = () => (
  <ListItem
    header={<Text.BodyM.Loading width={160} />}
    subHeader={
      <>
        <Text.BodyS.Loading width={120} />
        <Text.BodyS.Loading width={120} />
      </>
    }
  />
);

const Loading: SFC = () => (
  <Card>
    <LoadingListItem />
  </Card>
);
WalletsList.Loading = Loading;

export { WalletsList };
