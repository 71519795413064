import React from "react";
import { Screens, AppNavigatorStack } from "local/common";
import { AddPaymentMethodScreen } from "./AddPaymentMethodScreen";
import { EnterCreditDebitCardScreen } from "./EnterCreditDebitCardScreen";
import { ConfirmPaymentScreen } from "./ConfirmPaymentScreen";
import { PaymentSuccessScreen } from "./PaymentSuccessScreen";
import { SelectPaymentMethodScreen } from "./SelectPaymentMethodScreen";

const getPayTenancyDepositScreens = (
  Stack: AppNavigatorStack
): React.ReactElement => {
  return (
    <>
      <Stack.Screen
        name={Screens.TenancyDepositSelectPaymentMethod}
        component={SelectPaymentMethodScreen}
      />
      <Stack.Screen
        name={Screens.TenancyDepositAddPaymentMethod}
        component={AddPaymentMethodScreen}
      />
      <Stack.Screen
        name={Screens.TenancyDepositEnterCreditDebitCard}
        component={EnterCreditDebitCardScreen}
      />
      <Stack.Screen
        name={Screens.TenancyDepositConfirmPayment}
        component={ConfirmPaymentScreen}
      />
      <Stack.Screen
        name={Screens.TenancyDepositPaymentSuccess}
        component={PaymentSuccessScreen}
      />
    </>
  );
};

export { getPayTenancyDepositScreens };
