import React from "react";
import { Screens, AppNavigatorStack } from "local/common";
import { TransferFundsSelectWalletScreen } from "./TransferFundsSelectWalletScreen";
import { TransferFundsScreen } from "./TransferFundsScreen";
import { FundsTransferredScreen } from "./FundsTransferredScreen";

const getTransferFundsScreens = (
  Stack: AppNavigatorStack
): React.ReactElement => {
  return (
    <>
      <Stack.Screen
        name={Screens.TransferFundsSelectWallet}
        component={TransferFundsSelectWalletScreen}
        options={{
          title: "Transfer Funds"
        }}
      />
      <Stack.Screen
        name={Screens.TransferFunds}
        component={TransferFundsScreen}
        options={{
          title: "Transfer Funds"
        }}
      />
      <Stack.Screen
        name={Screens.FundsTransferred}
        component={FundsTransferredScreen}
        options={{
          title: "Transfer Funds"
        }}
      />
    </>
  );
};

export { getTransferFundsScreens };
