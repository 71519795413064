import {
  Screens,
  useNavigation,
  useNavigateToThisScreen,
  useCurrentLegalEntity
} from "local/common";
import React, { FC, useCallback } from "react";
import {
  Props as ScreenWithActionProps,
  ScreenWithHeaderAndAction
} from "local/domain/liabilityPaymentPlan";
import { View } from "react-native";
import { Frequency } from "local/domain/ledger";
import {
  FeeCalculator,
  FeeUIHelpers,
  PaymentMethodListItem
} from "@ailo/domains";
import { useGetPaymentMethodWithFees } from "local/domain/ledger/hooks";
import { LiabilityPaymentPlanSetupData } from "../../types";
import {
  CreateLiabilityPaymentPlanInput,
  QuartzPlanFrequency,
  GetTenanciesWithLiabilitiesQuery,
  useGetTenanciesWithLiabilitiesQuery
} from "local/graphql";
import moment from "moment";
import { capitalCase } from "capital-case";
import { FeesDisclaimerDisplay } from "../../../payLiability/PayBillLiabilityScreen/components/PaymentDisplay/components/FeesDisclaimerDisplay";
import {
  EditableListItemWithLeftHeading,
  ListItemValue,
  Separator
} from "@ailo/ui";

type Props = {
  editAllDetails: boolean;
  showTitle?: boolean;
  onSaveClick: (data: CreateLiabilityPaymentPlanInput) => void;
  onPaymentMethodChange: (paymentMethodId: string) => void;
} & LiabilityPaymentPlanSetupData &
  ScreenWithActionProps;

const DetailsEditComponent: FC<Props> = (props) => {
  const [legalEntity] = useCurrentLegalEntity();
  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();

  const {
    frequency,
    startDate,
    endDate,
    tenancy,
    amount,
    anniversary,
    paymentMethodId,
    editAllDetails,
    onSaveClick,
    onPaymentMethodChange,
    showTitle = true,
    primaryAction,
    ...rest
  } = props;

  const { liabilityId } = tenancy || {};

  const { data: tenancies } = useGetTenanciesWithLiabilitiesQuery();

  const { topUpFee } = useGetPaymentMethodWithFees(paymentMethodId || "");
  const feeCalculator = new FeeCalculator(topUpFee);
  const dueCents = feeCalculator.apply(amount?.cents || 0);
  const address = getAddressOfMatchingLiability(tenancies, liabilityId);

  const onPressPaymentMethodChange = useCallback((): void => {
    navigation.navigate(Screens.ChangePaymentMethod, {
      onClose: returnToThisScreen,
      onSuccess: onPaymentMethodChange
    });
  }, [onPaymentMethodChange, navigation, returnToThisScreen]);

  const onEditClick = (screen: Screens): void => {
    navigation.navigate(screen, props);
  };

  const getPayload = (): CreateLiabilityPaymentPlanInput => {
    const format = "YYYY-MM-DD";
    if (
      !frequency ||
      !startDate ||
      !liabilityId ||
      !amount ||
      !paymentMethodId ||
      anniversary == null
    ) {
      throw "Provide all fields";
    }

    return {
      amount: amount,
      startDate: moment(startDate).format(format),
      endDate: endDate ? moment(endDate).format(format) : undefined,
      frequency: frequency.toString() as QuartzPlanFrequency,
      liabilityId: liabilityId,
      anniversary: anniversary,
      paymentMethodId: paymentMethodId,
      payerLegalEntityId: legalEntity.id.toString()
    };
  };

  const body = (
    <View>
      <Separator />
      <EditableListItemWithLeftHeading
        name={"Property"}
        editable={editAllDetails}
        onEditClick={(): void =>
          onEditClick(Screens.LiabilityPlanSelectProperty)
        }
      >
        <ListItemValue value={address || "Unknown"} />
      </EditableListItemWithLeftHeading>
      <EditableListItemWithLeftHeading
        name={"Amount"}
        editable={editAllDetails}
        onEditClick={(): void => onEditClick(Screens.LiabilityPlanSelectAmount)}
      >
        <ListItemValue
          value={FeeUIHelpers.format(amount?.cents || 0) || "Unknown"}
        />
        <FeesDisclaimerDisplay
          dollarAmount={FeeUIHelpers.format(dueCents)}
          addLineBreakBeforeLink={true}
        />
      </EditableListItemWithLeftHeading>
      <EditableListItemWithLeftHeading
        name={"Payment Method"}
        editable={true}
        onEditClick={onPressPaymentMethodChange}
      >
        <PaymentMethodListItem.Small
          paymentMethodId={paymentMethodId || "Unknown"}
        />
      </EditableListItemWithLeftHeading>
      <EditableListItemWithLeftHeading
        name={"Frequency"}
        editable={editAllDetails}
        onEditClick={(): void =>
          onEditClick(Screens.LiabilityPlanSelectFrequency)
        }
      >
        <ListItemValue value={capitalCase(Frequency.value(frequency))} />
      </EditableListItemWithLeftHeading>
      <EditableListItemWithLeftHeading
        name={"Start Date"}
        editable={editAllDetails}
        onEditClick={(): void =>
          onEditClick(Screens.LiabilityPlanSelectStartDate)
        }
      >
        <ListItemValue value={formatDate(startDate)} />
      </EditableListItemWithLeftHeading>
      <EditableListItemWithLeftHeading
        name={"End Date"}
        editable={editAllDetails}
        onEditClick={(): void =>
          onEditClick(Screens.LiabilityPlanSelectEndDate)
        }
      >
        <ListItemValue value={formatDate(endDate, "When my rent finishes")} />
      </EditableListItemWithLeftHeading>
    </View>
  );

  return (
    <ScreenWithHeaderAndAction
      header={showTitle ? "Confirm details" : undefined}
      subHeader={"Tap to edit details"}
      primaryAction={{
        text: "Save",
        onClick: (): void => onSaveClick(getPayload()),
        ...primaryAction
      }}
      {...rest}
    >
      {body}
    </ScreenWithHeaderAndAction>
  );
};

function formatDate(date?: Date | undefined | null, def?: string): string {
  return date ? moment(date).format("D MMM YYYY") : def || "Unknown";
}

function getAddressOfMatchingLiability(
  data?: GetTenanciesWithLiabilitiesQuery,
  liabilityId?: string
): string | undefined {
  return data?.tenancies?.items
    .filter((t) => t?.liability?.id == liabilityId)
    .map((t) => t?.property?.address)
    .map((a) => [a?.unitStreetNumber, a?.streetName].filter(Boolean).join(" "))
    .join(",");
}

export { DetailsEditComponent };
