import React from "react";
import { Text } from "@ailo/primitives";
import { DateTimeWithTimeZone, isLedgerMaxDate } from "@ailo/ui";
import { DepositData } from "../../../TenancyDepositCard/parseDepositData";

const getRandomEmoji = (): string => {
  const emojis = ["🐹", "🐶", "🐱", "🐝", "🐳", "🐬", "🏡", "🦄"];
  const randomIndex = Math.floor(Math.random() * emojis.length);
  return emojis[randomIndex];
};

const getEmojiAndStatusMessage = (
  dueDate: string,
  paidToDate: string,
  terminationDate: string | null | undefined,
  deposit?: DepositData
): { emoji: string; status: string | React.ReactNode } => {
  const rentDueDate = isLedgerMaxDate(dueDate)
    ? null
    : DateTimeWithTimeZone.fromDateSubtractingAMillisecond(dueDate);

  const paidToDateWithTimeZone =
    DateTimeWithTimeZone.fromDateSubtractingAMillisecond(paidToDate);

  let daysOverdue = paidToDateWithTimeZone.daysBeforeToday();

  if (
    terminationDate &&
    DateTimeWithTimeZone.fromString(terminationDate).isBefore(
      DateTimeWithTimeZone.now()
    )
  ) {
    daysOverdue = paidToDateWithTimeZone.daysBeforeDate(
      DateTimeWithTimeZone.fromString(terminationDate)
    );
  }

  if (deposit?.isPaidOrPending) {
    const paymentDate = rentDueDate
      ? `on ${rentDueDate.format("D MMMM YYYY")} `
      : "";
    return {
      emoji: "",
      status: `Your initial payment will automatically be used towards your first rent payment ${paymentDate}🎉`
    };
  }

  if (!rentDueDate)
    return {
      emoji: "🎉",
      status: "You’re up to date"
    };
  if (daysOverdue >= 2)
    return {
      emoji: "⚠️",
      status: (
        <>
          {"Your rent is "}
          <Text.BodyS weight={"medium"}>
            {`${Math.floor(daysOverdue)} days overdue `}
          </Text.BodyS>
        </>
      )
    };
  if (rentDueDate.isOverdue())
    return {
      emoji: "⚠️",
      status: "Please complete payment towards your overdue rent"
    };
  if (rentDueDate.isToday())
    return {
      emoji: getRandomEmoji(),
      status: "Please pay your rent today"
    };
  return {
    emoji: "🎉",
    status: "You’re up to date"
  };
};

export { getEmojiAndStatusMessage };
