import { ScreenComponent, useCurrentLegalEntity } from "local/common";
import React, { FunctionComponent } from "react";
import styled from "styled-components/native";
import { WalletOwnerTransactionsList } from "@ailo/domains";

const PaymentHistoryScreen: FunctionComponent = () => {
  const [legalEntity] = useCurrentLegalEntity();
  return (
    <Container>
      <WalletOwnerTransactionsList ownerRef={legalEntity.id.toString()} />
    </Container>
  );
};

const Container = styled(ScreenComponent)`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export { PaymentHistoryScreen };
