import React, { FC } from "react";
import { Colors, Text } from "@ailo/primitives";
import { Screens, useNavigation, useOnFocus, useRoute } from "local/common";
import { View } from "react-native";
import {
  extractFeeInfo,
  PaymentMethodDisplay,
  PaymentStatus
} from "local/domain/ledger";
import styled from "styled-components/native";
import { ListItemHeader, Separator, SpinnerOverlay } from "@ailo/ui";
import { FeeUIHelpers } from "@ailo/domains";
import {
  useGetPaymentKeyQuery,
  useGetPaymentMethodWithFeesQuery,
  usePayLiabilityMutation,
  OnboardingTaskId
} from "local/graphql";
import { LoadingScreen, PrimaryButton, SimpleScreenContainer } from "../common";
import { handleUnsuccessfulPaymentStatus } from "local/domain/ledger/payLiability/errors";
import { useRemovePreviousScreenFromNavigationStack } from "./useRemovePreviousScreenFromNavigationStack";
import { useCompleteOnboardingTask } from "local/domain/onboarding";

const ConfirmPaymentScreen: FC = () => {
  const navigation = useNavigation<Screens.TenancyDepositConfirmPayment>();
  useRemovePreviousScreenFromNavigationStack();

  const completeOnboardingTaskCallback = useCompleteOnboardingTask();

  const { paymentMethodId, amount, liabilityId } =
    useRoute<Screens.TenancyDepositConfirmPayment>().params;

  const { data: feeInfoData, loading: feeInfoLoading } =
    useGetPaymentMethodWithFeesQuery({
      variables: { paymentMethodId }
    });

  const {
    data: paymentKeyData,
    loading: paymentKeyLoading,
    refetch: paymentKeyRefetch
  } = useGetPaymentKeyQuery({ variables: { liabilityId } });

  useOnFocus(paymentKeyRefetch);

  const [payLiabilityMutation, { loading: payMutationLoading }] =
    usePayLiabilityMutation();

  if (feeInfoLoading || paymentKeyLoading) {
    return <LoadingScreen />;
  }

  const feeInfo = extractFeeInfo(feeInfoData, paymentMethodId);
  const fee = FeeUIHelpers.getFee(feeInfo, amount);
  const total = FeeUIHelpers.getFeeAppliedAmountFromMoney(feeInfo, amount);

  const onPayClicked = async (): Promise<void> => {
    const goBackToSelectPaymentMethod = (): void => {
      navigation.goBack();
    };

    const { data } = await payLiabilityMutation({
      variables: {
        amount: {
          cents: amount.cents
        },
        idempotentKey: paymentKeyData?.liabilityById?.paymentKey || "",
        liabilityId,
        paymentMethodId
      }
    });

    const paymentStatus = data?.payLiability?.status;
    if (
      paymentStatus === PaymentStatus.Pending ||
      paymentStatus === PaymentStatus.Success
    ) {
      navigation.navigate(Screens.TenancyDepositPaymentSuccess, {
        paymentMethodId,
        paymentDetails: {
          createdAt: data?.payLiability?.businessTransaction?.createdAt!,
          amount: amount,
          fee,
          total
        },
        onClose: () => {
          completeOnboardingTaskCallback(OnboardingTaskId.PayTenancyDeposit);
        }
      });
      return;
    } else {
      handleUnsuccessfulPaymentStatus(
        feeInfoData?.paymentMethodById?.__typename,
        data?.payLiability?.status,
        goBackToSelectPaymentMethod
      );
    }
  };

  return (
    <SimpleScreenContainer style={{ padding: 0, paddingBottom: 48 }}>
      <View>
        <ListItemHeader
          text={"Payment Amount"}
          style={{ backgroundColor: Colors.CLOUD }}
        />
        <ForegroundView>
          <ListRow style={{ paddingBottom: 0 }}>
            <Text.BodyM>{"Initial Payment"}</Text.BodyM>
            <Text.BodyM>{amount.format()}</Text.BodyM>
          </ListRow>
          <ListRow>
            <Text.BodyM>{"Service Fee"}</Text.BodyM>
            <Text.BodyM>{fee.format()}</Text.BodyM>
          </ListRow>
          <Separator />
          <ListRow>
            <Text.DisplayS>{"Total"}</Text.DisplayS>
            <Text.DisplayS>{total.format()}</Text.DisplayS>
          </ListRow>
        </ForegroundView>

        <PaymentMethodDisplay
          paymentMethodId={paymentMethodId}
          showAccountName={true}
        />
      </View>

      <View style={{ marginTop: "auto" }}>
        <PrimaryButton onPress={onPayClicked}>{"Pay Now"}</PrimaryButton>
      </View>
      {payMutationLoading && <SpinnerOverlay />}
    </SimpleScreenContainer>
  );
};

const ForegroundView = styled(View)`
  background: ${Colors.WHITE};
`;

const ListRow = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
`;

export { ConfirmPaymentScreen };
