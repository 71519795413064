import React, { useCallback } from "react";
import { SFC } from "@ailo/primitives";
import { useGetWalletsForTransferFundsQuery } from "local/graphql";
import { WalletsList } from "./components";
import { useOnFocus, useCurrentLegalEntity } from "local/common";
import {
  useGetBankAccountPaymentMethodsForUser,
  useGetDefaultPaymentMethodIdsForUser
} from "local/domain/ledger/hooks";
import { didQueryNotLoadYet } from "@ailo/services";

const MyWalletsList: SFC = ({ style }) => {
  const [legalEntity] = useCurrentLegalEntity();
  const walletResult = useGetWalletsForTransferFundsQuery({
    variables: { legalEntityId: legalEntity.id.toString() }
  });
  const defaultPaymentMethodResult = useGetDefaultPaymentMethodIdsForUser();
  const bankPaymentMethodsResult = useGetBankAccountPaymentMethodsForUser();

  const { refetch: walletRefetch } = walletResult;
  const { refetch: defaultPaymentMethodRefetch } = defaultPaymentMethodResult;
  const { refetch: bankPaymentMethodsRefetch } = bankPaymentMethodsResult;

  const refetch = useCallback((): void => {
    walletRefetch();
    defaultPaymentMethodRefetch();
    bankPaymentMethodsRefetch();
  }, [walletRefetch, defaultPaymentMethodRefetch, bankPaymentMethodsRefetch]);

  useOnFocus(refetch);

  if (
    didQueryNotLoadYet(walletResult) ||
    didQueryNotLoadYet(defaultPaymentMethodResult) ||
    didQueryNotLoadYet(bankPaymentMethodsResult)
  ) {
    return <WalletsList.Loading style={style} />;
  }

  const wallets = walletResult?.data?.legalEntity?.wallets;

  if (!wallets || wallets.length === 0) {
    throw new Error("There are no wallets to transfer funds from.");
  }

  const defaultBankAccountId =
    defaultPaymentMethodResult?.defaultOutPaymentMethodId;
  const bankPaymentMethods = bankPaymentMethodsResult?.bankPaymentMethods;

  return (
    <WalletsList
      style={style}
      defaultBankAccountId={defaultBankAccountId}
      bankPaymentMethods={bankPaymentMethods}
      wallets={wallets}
    />
  );
};

export { MyWalletsList };
