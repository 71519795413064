import React from "react";
import { Colors, SFC } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { useRoute, useNavigation, Screens } from "local/common";

const MakeThisAutomaticButton: SFC = ({ style }) => {
  const { liabilityId, onClose, tenancyId, paymentMethodId } =
    useRoute<Screens.RentPaymentSuccess>().params;
  const navigation = useNavigation<Screens.RentPaymentSuccess>();

  const navigateToSetupAutoPaymentScreen = (): void => {
    navigation.navigate(Screens.SetupAutoPayRent, {
      paymentMethodId,
      tenancyId,
      liabilityId,
      onClose,
      onSuccess: onClose,
      autoPaymentEnabled: false
    });
  };

  return (
    <Button.Secondary
      onPress={navigateToSetupAutoPaymentScreen}
      style={style}
      contentColor={Colors.TEXT.DARK.PRIMARY}
    >
      {"Make this automatic?"}
    </Button.Secondary>
  );
};

export { MakeThisAutomaticButton };
