import {
  AnalyticsContext as BaseAnalyticsContext,
  AnalyticsService as BaseAnalyticsService,
  useAnalytics as useBaseAnalytics
} from "@ailo/services";
import Big from "big.js";
import { capitalCase } from "capital-case";
import {
  PaymentMethodDestinationInput,
  QuartzPlanFrequency
} from "local/graphql";

export interface AnalyticsContext extends BaseAnalyticsContext {
  organisation_id?: string[];
  management_id?: string[];
  legal_entity_id?: string;
}

export const useAnalytics = useBaseAnalytics as () => AnalyticsService;

export class AnalyticsService extends BaseAnalyticsService<AnalyticsContext> {
  public trackOnboardingTaskDone(
    payload: { name: string },
    skipped: boolean
  ): void {
    const eventName = skipped
      ? "Onboarding Task Skipped"
      : "Onboarding Task Completed";
    this.track(eventName, payload);
  }

  public trackAddPaymentMethod(
    payload: {
      type: "Card" | "Bank Account";
      cardCategory?: string;
      cardType?: string;
      errorCode?: string | string[];
      errorMessage?: string | string[];
    },
    failed: boolean
  ): void {
    const eventName = failed
      ? "Payment Method Failed to Add"
      : "Payment Method Added";
    this.track(eventName, payload);
  }

  public trackRemovePaymentMethod(
    payload: {
      type: "Card" | "Bank Account";
      cardCategory?: string;
      cardType?: string;
      errorCode?: string | string[];
      errorMessage?: string | string[];
    },
    failed: boolean
  ): void {
    const eventName = failed
      ? "Payment Method Failed to Remove"
      : "Payment Method Removed";
    this.track(eventName, payload);
  }

  public trackWithdraw(
    payload: {
      withdrawType: "auto" | "manual";
      errorCode?: string | string[];
      errorMessage?: string | string[];
    },
    failed: boolean
  ): void {
    const eventName = failed ? "Funds Withdraw Failed" : "Funds Withdrawn";
    this.track(eventName, payload);
  }

  public trackAutoWithdrawPlanChanged({
    eventName,
    frequency,
    paymentMethodDestinations
  }: {
    eventName:
      | "Auto Withdraw Enabled"
      | "Auto Withdraw Disabled"
      | "Auto Withdraw Edited";
    frequency?: QuartzPlanFrequency;
    paymentMethodDestinations: PaymentMethodDestinationInput[];
  }): void {
    const totalShares = paymentMethodDestinations.reduce(
      (sum, current) => sum + current.share,
      0
    );
    const payload = {
      autoWithdrawFrequency: frequency ? capitalCase(frequency) : undefined,
      destinationAccountsPercentSplit: paymentMethodDestinations.map(
        (destination) =>
          Big(destination.share).div(totalShares).round(4).toNumber()
      )
    };
    this.track(eventName, payload);
  }

  public trackPropertyChanged({
    previousManagementAiloRN,
    nextManagementAiloRN
  }: {
    previousManagementAiloRN: string | undefined;
    nextManagementAiloRN: string | undefined;
  }): void {
    this.track("Property Switched", {
      previous_management_id: [previousManagementAiloRN],
      management_id: [nextManagementAiloRN]
    });
  }
}
