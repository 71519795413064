import {
  RentedProperty,
  Screens,
  usePropertySelectorContext,
  useRoute
} from "local/common";

import React, { FC } from "react";
import { Colors, LockTenancy, Text } from "@ailo/primitives";
import { formatAddress } from "@ailo/domain-helpers";
import { Dimensions, Image, View } from "react-native";
import styled from "styled-components/native";
import { Money } from "@ailo/ui";
import { PayNowButton } from "./PayNowButton";
import { useGetPayDepositDetailsQuery } from "local/graphql";
import { LoadingScreen, SimpleScreenContainer } from "../common";

const PayTenancyDepositKeyActionScreen: FC = () => {
  useRoute<Screens.TenancyDepositKeyAction>();

  const tenancyId = useGetTenancyId();
  const { data, loading } = useGetPayDepositDetailsQuery({
    variables: tenancyId ? { tenancyId } : undefined,
    skip: !tenancyId
  });

  if (loading || !data?.tenancy) {
    return <LoadingScreen />;
  }
  const tenancy = data.tenancy;

  const deposit = tenancy.deposit;
  const amountDue = Money.from(deposit?.amount || { cents: 0 });
  const agencyName = tenancy?.managingEntity?.registeredEntityName || "";
  const property = tenancy.property;

  const address = formatAddress(property.address, { format: "street, suburb" });

  const description = `Your property manager at ${agencyName} has requested an initial payment before you move in to ${address}`;

  const imageWidth = Dimensions.get("window").width - 80;
  const imageHeight = imageWidth * 0.62;
  return (
    <SimpleScreenContainer style={{ padding: 16 }}>
      <VerticallyCenteredContentContainer>
        <ContentContainer>
          <Image
            source={LockTenancy}
            resizeMode={"contain"}
            style={{
              height: imageHeight,
              width: imageWidth,
              alignSelf: "center"
            }}
          />
          <TitleText>{"Make an initial payment"}</TitleText>
          <DescriptionText>{description}</DescriptionText>
          <GreyCard>
            <MoneyText>{amountDue.format()}</MoneyText>
            <AmountDueText>{"Amount Due"}</AmountDueText>
          </GreyCard>
        </ContentContainer>
      </VerticallyCenteredContentContainer>

      <View style={{ marginTop: "auto" }}>
        <PayNowButton
          liabilityId={deposit?.liability?.id || ""}
          amount={amountDue}
        />
      </View>
    </SimpleScreenContainer>
  );
};

const VerticallyCenteredContentContainer = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const ContentContainer = styled(View)`
  width: 100%;
`;

const TitleText = styled(Text.DisplayS)`
  text-align: center;
  margin-top: 40px;
`;

const DescriptionText = styled(Text.BodyM)`
  text-align: center;
  margin: 6px 16px 0px 16px;
`;

const GreyCard = styled(View)`
  background: ${Colors.CLOUD};
  border-radius: 12px;
  margin: 16px 0px;
  padding: 12px;
`;

const MoneyText = styled(Text.DisplayM)`
  text-align: center;
`;

const AmountDueText = styled(Text.BodyS)`
  text-align: center;
  color: ${Colors.TEXT.DARK.SECONDARY};
`;

function useGetTenancyId(): string | undefined {
  const { currentProperty } = usePropertySelectorContext();
  const currentPropertyIsRented =
    currentProperty?.__typename === "RentedProperty";
  return currentPropertyIsRented
    ? (currentProperty as RentedProperty)?.tenancy?.id.internalId
    : undefined;
}

export { PayTenancyDepositKeyActionScreen };
