import { Colors, Text } from "@ailo/primitives";
import {
  Screens,
  useNavCloseButton,
  useRoute,
  withScreenComponent
} from "local/common";
import { EnsureKycVerified } from "local/domain/authz";
import React from "react";
import { MyWalletsList } from "./components";

export const TransferFundsSelectWalletScreen = withScreenComponent(
  (): React.ReactElement => {
    const {
      params: { onClose }
    } = useRoute<Screens.TransferFundsSelectWallet>();
    useNavCloseButton();

    return (
      <EnsureKycVerified onKycCheckDismiss={onClose}>
        <Text.BodyL
          style={{
            marginTop: 20,
            marginLeft: 16,
            marginRight: 16,
            marginBottom: 24
          }}
          color={Colors.TEXT.DARK.PRIMARY}
        >
          {"Select a wallet to transfer funds from"}
        </Text.BodyL>
        <MyWalletsList style={{ flex: 1 }} />
      </EnsureKycVerified>
    );
  }
);
