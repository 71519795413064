import { Colors } from "@ailo/primitives";
import { MaterialTopTabBarProps } from "@react-navigation/material-top-tabs";
import { Route } from "@react-navigation/native";
import React from "react";
import { StyleSheet } from "react-native";
import { TabBar } from "react-native-tab-view";

const styles = StyleSheet.create({
  tabBar: {
    backgroundColor: Colors.CHARCOAL,
    elevation: 0
  }
});

export function AiloTabBar(props: MaterialTopTabBarProps): React.ReactElement {
  const {
    state,
    navigation,
    descriptors,
    style,
    showLabel,
    showIcon,
    ...rest
  } = props;

  return (
    <TabBar
      {...rest}
      navigationState={state}
      style={[styles.tabBar, style]}
      getAccessibilityLabel={({ route }): string | undefined =>
        descriptors[route.key].options.tabBarAccessibilityLabel
      }
      getTestID={({ route }): string | undefined =>
        descriptors[route.key].options.tabBarTestID ?? "AiloTabBarItem"
      }
      onTabPress={({ route, preventDefault }): void => {
        const event = navigation.emit({
          type: "tabPress",
          target: route.key,
          canPreventDefault: true
        });

        if (event.defaultPrevented) {
          preventDefault();
        }
      }}
      onTabLongPress={({ route }): void => {
        navigation.emit({
          type: "tabLongPress",
          target: route.key
        });
      }}
      renderIcon={({ route, focused, color }): React.ReactNode => {
        if (showIcon === false) {
          return null;
        }
        const { options } = descriptors[route.key];

        if (options.tabBarIcon !== undefined) {
          return options.tabBarIcon({ focused, color });
        }

        return null;
      }}
      renderLabel={({ route, focused, color }): React.ReactNode => {
        if (showLabel === false) {
          return null;
        }
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : (route as Route<string>).name;

        if (typeof label === "string") {
          return label;
        }

        return label({ focused, color });
      }}
    />
  );
}
