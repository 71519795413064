import React, { FC } from "react";
import { AddPaymentTenant } from "@ailo/primitives";
import { FeatureShowcaseSlide } from "local/domain/onboarding";

const TenantAddPaymentSlide: FC = () => {
  return (
    <FeatureShowcaseSlide
      titleText={"Pay rent your way"}
      descriptionText={
        "Choose to pay rent using a credit or debit card or your bank account."
      }
      imageSource={AddPaymentTenant}
    />
  );
};

export { TenantAddPaymentSlide };
