import { ScreenComponent, useEnvironment } from "@ailo/services";
import React, { useCallback } from "react";
import {
  AiloHouses,
  AiloWholeWordIcon,
  Colors,
  HereToHelpGraphics,
  PayBillsYourWayGraphics,
  SayHiToPMGraphics,
  TrackYourCashGraphics
} from "@ailo/primitives";
import { Image, ScrollView, View } from "react-native";
import { Screens, useNavigation } from "local/common";
import { GetToKnowItem, Heading } from "./components";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useGenerateHelpUrl } from "@ailo/domains";
import * as WebBrowser from "expo-web-browser";
import { CloseButton, useInvestorOverviewAnalytics } from "../common";

export const GetToKnowAiloScreen: React.FC = () => {
  const { top } = useSafeAreaInsets();
  const navigation = useNavigation();
  const analytics = useInvestorOverviewAnalytics();
  const generateHelpUrl = useGenerateHelpUrl();
  const { ZENDESK_HELP_CENTER_PATH, ZENDESK_CONTACT_US_PATH } =
    useEnvironment();

  const helpCentrePress = useCallback(async () => {
    analytics.trackGetToKnowAilo("Open Help Centre");
    WebBrowser.openBrowserAsync(
      await generateHelpUrl({ returnTo: ZENDESK_HELP_CENTER_PATH })
    );
  }, [analytics, generateHelpUrl, ZENDESK_HELP_CENTER_PATH]);

  const contactSupportPress = useCallback(async () => {
    analytics.trackGetToKnowAilo("Open Contact Ailo");
    WebBrowser.openBrowserAsync(
      await generateHelpUrl({ returnTo: ZENDESK_CONTACT_US_PATH })
    );
  }, [analytics, generateHelpUrl, ZENDESK_CONTACT_US_PATH]);

  return (
    <ScreenComponent
      style={{
        backgroundColor: Colors.WHITE
      }}
    >
      <CloseButton
        hideShadow={false}
        onClose={navigation.goBack}
        containerStyle={{ position: "absolute", top: top + 16, left: 16 }}
      />
      <ScrollView>
        <View style={{ height: top + 16 }} />
        <Heading />

        <GetToKnowItem
          heading={"Say hi to your PM"}
          body={`In-app chat makes it fast and easy to communicate, collaborate or just check-in.\nStay in the know with a direct line to your property manager and say goodbye to phone tag!`}
          button={{
            text: "Open Chat",
            onPress: (): void => {
              analytics.trackGetToKnowAilo("Open Chat");
              navigation.navigate(Screens.ChatTab);
            }
          }}
          image={<SayHiToPMGraphics />}
        />

        <GetToKnowItem
          heading={"Track your cash flow"}
          body={`Ailo unlocks real-time visibility on your rental income and management fees. You can see your rent as soon as it’s paid - even before it’s cleared by the banks! If your funds are due to clear, they will appear as Pending in your Transaction tab.\n\nTo learn more visit our Help Centre.`}
          button={{
            text: "Ailo Help Centre",
            onPress: helpCentrePress
          }}
          image={<TrackYourCashGraphics />}
        />

        <GetToKnowItem
          heading={"Pay bills your way"}
          body={`You don't need to keep rental income sitting dormant, not earning interest and waiting for a bill. Choose to pay bills with rent or when they’re due from your credit card to earn rewards. You can also set-up autopay to set and forget!`}
          button={{
            text: "Open Bills",
            onPress: (): void => {
              analytics.trackGetToKnowAilo("Open Bills");
              navigation.navigate(Screens.BillsTab);
            }
          }}
          image={<PayBillsYourWayGraphics />}
        />

        <GetToKnowItem
          heading={"We’re here to help"}
          body={`Ailo’s helpful Support Team are here to make sure you have the best possible experience. You can call us or email if you have any questions or feedback.`}
          button={{
            text: "Contact Ailo Support",
            onPress: contactSupportPress
          }}
          image={<HereToHelpGraphics />}
          underline={false}
        />

        <View
          style={{ alignItems: "center", paddingLeft: 32, paddingRight: 32 }}
        >
          <AiloWholeWordIcon
            height={40}
            width={98}
            style={{ marginTop: 138 }}
          />
          <Image
            style={{
              width: "100%",
              height: undefined,
              aspectRatio: 327 / 106,
              marginTop: 80
            }}
            source={AiloHouses}
            resizeMode={"stretch"}
          />
        </View>
      </ScrollView>
    </ScreenComponent>
  );
};
