import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import { Keyboard, View } from "react-native";
import { Alert, alert, Money as FormattedMoney } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import { Screens, useNavigation, useRoute } from "local/common";
import {
  useGetRentPaymentSuccessDetailsLazyQuery,
  useGetPaymentMethodWithFeesQuery,
  usePayLiabilityMutation,
  Money
} from "local/graphql";
import { handleUnsuccessfulPaymentStatus } from "local/domain/ledger/payLiability/errors";
import { EditablePaymentDisplay } from "./components";
import { AiloDate } from "@ailo/domains";
import { extractFeeInfo, PaymentStatus } from "local/domain/ledger";

interface Props {
  paymentMethodId: string;
  idempotentKey: string;
  autoPaymentEnabled: boolean;
  terminationAmountDueCents?: number;
  tenancyEndDate?: AiloDate;
}

interface Statics {
  Loading: React.FC;
}

// TODO: Move the logic below to a hook. Something like "usePaymentDisplayData({ paymentMethodId, ... })"
const PaymentDisplay: React.FC<Props> & Statics = ({
  paymentMethodId,
  idempotentKey,
  terminationAmountDueCents,
  tenancyEndDate
}) => {
  const { liabilityId, description, tenancyId, onClose, onFinalClose } =
    useRoute<Screens.PayRentLiability>().params;
  const navigation = useNavigation<Screens.PayRentLiability>();

  const [payLiabilityMutation, { loading: payMutationLoading }] =
    usePayLiabilityMutation();

  const { data: paymentMethodData, loading: paymentMethodLoading } =
    useGetPaymentMethodWithFeesQuery({ variables: { paymentMethodId } });

  const [
    paymentSuccessQuery,
    {
      data: successQueryData,
      loading: successQueryLoading,
      called: successQueryCalled
    }
  ] = useGetRentPaymentSuccessDetailsLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      tenancyId,
      liabilityId
    }
  });

  const loading =
    payMutationLoading ||
    paymentMethodLoading ||
    (successQueryCalled && successQueryLoading);

  useEffect(() => {
    navigation.setParams({ showLoadingOverlay: loading });
  }, [loading, navigation]);

  const [paymentAmount, setPaymentAmount] = useState<Money | undefined>(
    undefined
  );

  useEffect(() => {
    if (
      successQueryCalled &&
      !successQueryLoading &&
      paymentAmount !== undefined
    ) {
      navigation.navigate(Screens.RentPaymentSuccess, {
        title: "Pay Rent",
        paymentAmount: paymentAmount,
        liabilityId,
        tenancyId,
        paymentMethodId,
        nextDueDate: successQueryData?.liabilityById?.nextDueDate || undefined,
        autoPaymentEnabled:
          !!successQueryData?.liabilityById?.autoPaymentDetails?.paymentMethod
            ?.id,
        outstandingAmountInCents:
          successQueryData?.liabilityById?.terminationAmountDue?.cents,
        terminationDate: successQueryData?.tenancy?.endDate || undefined,
        onClose: onFinalClose || onClose
      });
    }
  }, [
    successQueryLoading,
    successQueryCalled,
    paymentAmount,
    navigation,
    liabilityId,
    tenancyId,
    paymentMethodId,
    successQueryData,
    onFinalClose,
    onClose
  ]);

  const payLiability = async (
    amountWithoutFees: Money,
    amountWithFees: Money
  ): Promise<void> => {
    const goBack = navigation.goBack;
    Keyboard.dismiss();
    setPaymentAmount(amountWithFees);
    try {
      const { data } = await payLiabilityMutation({
        variables: {
          amount: amountWithoutFees,
          idempotentKey,
          liabilityId,
          paymentMethodId
        }
      });
      const paymentStatus = data?.payLiability?.status;
      if (
        paymentStatus === PaymentStatus.Pending ||
        paymentStatus === PaymentStatus.Success
      ) {
        paymentSuccessQuery();
      } else {
        handleUnsuccessfulPaymentStatus(
          paymentMethodData?.paymentMethodById?.__typename,
          paymentStatus,
          goBack
        );
      }
    } catch (e) {
      alert(
        "Error",
        "We were unable to process your transfer. Please check the details and try again or contact Ailo support",
        [
          {
            text: "Dismiss",
            onPress: (): void => {
              navigation.goBack();
            }
          }
        ]
      );
    }
  };

  const topUpFee = paymentMethodLoading
    ? undefined
    : extractFeeInfo(paymentMethodData, paymentMethodId);

  return (
    <PaymentDisplayContainer>
      {!!terminationAmountDueCents && (
        <Alert
          type={"warning"}
          style={{ marginBottom: 16 }}
          message={`${
            tenancyEndDate
              ? `Your rent payments will end on ${tenancyEndDate.simpleFormatWithYear()}. `
              : ""
          }You have ${FormattedMoney.fromCents(
            terminationAmountDueCents
          ).format()} rent remaining.`}
        />
      )}
      {description && (
        <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
          {description}
        </Text.BodyM>
      )}

      <EditablePaymentDisplay
        payLiability={payLiability}
        topUpFee={topUpFee}
        maxPaymentAmountInCents={terminationAmountDueCents}
        loading={loading}
      />
    </PaymentDisplayContainer>
  );
};

const Loading: React.FC = () => {
  const { description } = useRoute<Screens.PayRentLiability>().params;

  return (
    <PaymentDisplayContainer>
      {description && (
        <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
          {description}
        </Text.BodyM>
      )}

      <EditablePaymentDisplay.Loading />
    </PaymentDisplayContainer>
  );
};

PaymentDisplay.Loading = Loading;

const PaymentDisplayContainer = styled(View)`
  padding: 20px 16px;
  background: ${Colors.WHITE};
`;

export { PaymentDisplay };
