import React from "react";
import { Property } from "./Property";

export * from "./Property";

export interface PropertySelectorContextValue {
  currentProperty: Property | undefined;

  /**
   * Does the user have any properties in which they are a tenant?
   */
  hasRentedProperties: boolean;

  /**
   * Does the user have any properties in which they are an owner?
   */
  hasOwnedProperties: boolean;

  /**
   * All properties of the user in which they are owner/tenant.
   */
  allProperties: Property[];

  /**
   * Sets the `currentProperty`.
   */
  setCurrentProperty(property: Property | undefined): void;
}

export const PropertySelectorContext =
  React.createContext<PropertySelectorContextValue>({
    currentProperty: undefined,
    hasRentedProperties: false,
    hasOwnedProperties: false,
    allProperties: [],
    setCurrentProperty() {
      throw new TypeError(`PropertySelectorContext needs to be provided`);
    }
  });

export function usePropertySelectorContext(): PropertySelectorContextValue {
  return React.useContext(PropertySelectorContext);
}
