import React from "react";
import { View, ViewStyle } from "react-native";
import { ListItemHeader } from "@ailo/ui";
import { MyWalletsCard } from "./components";
import { StyleProps } from "@ailo/primitives";

export function MyWallets({
  style
}: {
  style?: StyleProps<ViewStyle>;
}): React.ReactElement {
  return (
    <View style={style}>
      <ListItemHeader
        text={"My Wallets"}
        style={{ marginHorizontal: 12, paddingBottom: 0 }}
      />
      <MyWalletsCard />
    </View>
  );
}

MyWallets.Loading = function MyWalletsLoading({
  style
}: {
  style?: StyleProps<ViewStyle>;
}): React.ReactElement {
  return (
    <View style={style}>
      <ListItemHeader
        text={"My Wallets"}
        style={{ marginHorizontal: 12, paddingBottom: 0 }}
      />
      <MyWalletsCard.Loading />
    </View>
  );
};
