import { TypedStackNavigator } from "@react-navigation/stack";
import { AppNavigatorParamList, Screens } from "local/common";
import React from "react";
import { AllInvestorTransactionsScreen } from "./AllInvestorTransactionsScreen";

export const getInvestorTabModalScreens = (
  Stack: TypedStackNavigator<AppNavigatorParamList>
): React.ReactElement => {
  return (
    <>
      <Stack.Screen
        name={Screens.AllInvestorTransactions}
        component={AllInvestorTransactionsScreen}
        options={{
          title: "All Transactions"
        }}
      />
    </>
  );
};
