import React, { FC } from "react";
import { Colors } from "@ailo/primitives";
import { TenantAddPaymentSlide, TenantSetupButton } from "./components";
import { HeaderOverlay } from "local/domain/onboarding";
import { View } from "react-native";
import styled from "styled-components/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { SkipButton } from "../../components";
import { OnboardingTaskId } from "local/graphql";

const TenantAddPaymentMethodScreen: FC = () => {
  const { bottom } = useSafeAreaInsets();

  return (
    <Container style={{ paddingBottom: bottom }}>
      <View style={{ flex: 1 }}>
        <TenantAddPaymentSlide />
      </View>
      <View>
        <TenantSetupButton />
        <SkipButton
          style={{ marginTop: 16, marginBottom: 20 }}
          taskToSkip={OnboardingTaskId.AddPaymentMethod}
        />
      </View>
      <HeaderOverlay />
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  background-color: ${Colors.WHITE};
`;

export { TenantAddPaymentMethodScreen };
