import React from "react";
import { ViewStyle } from "react-native";
import { DateTimeWithTimeZone, Money } from "@ailo/ui";
import { didQueryNotLoadYet, useOnFocus } from "@ailo/services";
import { InvestorRentCard } from "../InvestorRentCard";
import { useGetManagementLatestTenancyDetailsQuery } from "local/graphql";

interface Props {
  style?: ViewStyle;
  managementId: string;
  totalClearedRentCount: number | undefined;
  hasSharesOwned: boolean;
}

export function InvestorManagementRentCard({
  style,
  managementId,
  totalClearedRentCount,
  hasSharesOwned
}: Props): React.ReactElement | null {
  const result = useGetManagementLatestTenancyDetailsQuery({
    variables: { managementId }
  });
  const { data, refetch } = result;
  useOnFocus(refetch);

  if (didQueryNotLoadYet(result)) {
    return <InvestorRentCard.Loading />;
  }

  const liability = data?.management?.mostRecentTenancy?.liability;
  const currentRent = data?.management?.mostRecentTenancy?.currentRent;

  return (totalClearedRentCount || !hasSharesOwned) &&
    liability?.lastPaymentDate ? (
    <InvestorRentCard
      style={style}
      rent={Money.fromCents(currentRent?.amountInCents || 0)}
      frequency={currentRent?.period}
      paidToDate={
        liability?.effectivePaidToDate
          ? DateTimeWithTimeZone.fromLocalDate(liability.effectivePaidToDate)
          : undefined
      }
    />
  ) : null;
}
