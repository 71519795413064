import {
  NotificationSetup,
  parseCommsNotificationResponse
} from "@ailo/domains";
import { AiloSentry, Severity } from "@ailo/services";
import { NotificationResponse } from "expo-notifications";
import { Screens, useNavigation } from "local/common";
import React, { useCallback, useEffect } from "react";
import { TabNavigatorScreenContent } from "./TabNavigatorScreenContent";
import { useHandleCommsNotificationResponse } from "local/tabs";

export const TabNavigatorScreen: React.FC = () => {
  const navigation = useNavigation();
  const handleCommsNotificationResponse = useHandleCommsNotificationResponse();

  useEffect(() => {
    navigation.setOptions({ header: () => null });
  }, [navigation]);

  const onNotificationReceive = useCallback(
    (notificationResponse: NotificationResponse) => {
      const { notification } = notificationResponse;
      const { data } = notification.request.content;

      AiloSentry.addBreadcrumb({
        category: "notification",
        level: AiloSentry.Severity.Info,
        data: {
          notificationType:
            typeof data.type == "string" ? data.type : "UNKNOWN",
          payload: JSON.stringify(data)
        }
      });

      const commsNotificationResponse =
        parseCommsNotificationResponse(notificationResponse);
      if (commsNotificationResponse) {
        handleCommsNotificationResponse(commsNotificationResponse);
        return;
      }

      if (data.type) {
        switch (data.type) {
          case "rentPaymentReceived":
            navigation.navigate(Screens.InvestorRentTab);
            break;
          default:
            AiloSentry.captureMessage(`Unhandled Notification Type`, {
              severity: Severity.Warning
            });
        }
      }
    },
    [navigation, handleCommsNotificationResponse]
  );

  return (
    <NotificationSetup onNotificationReceive={onNotificationReceive}>
      <TabNavigatorScreenContent />
    </NotificationSetup>
  );
};
