import React, { FC, useCallback, useState } from "react";
import { KeyboardAvoidingView, Platform, ViewStyle, View } from "react-native";
import { WebView, WebViewMessageEvent } from "react-native-webview";
import { useAnalytics, useCurrentLegalEntity } from "local/common";
import { useAddCreditCardMutation } from "local/graphql";
import { alert, Spinner } from "@ailo/ui";
import { Colors } from "@ailo/primitives";
import styled from "styled-components/native";
import { useHeaderHeight } from "@react-navigation/stack";

interface Data {
  message: string;
  data?: {
    card?: object;
  };
}

interface Props {
  uri: string;
  onSuccess: (paymentMethodId: string) => void;
  onError?: () => void;
  style?: ViewStyle;
  header?: React.ReactNode;
}

interface Statics {
  Loading: FC<{ style?: ViewStyle }>;
}

enum FormState {
  Loading,
  Ready,
  Submitting,
  Error,
  Success
}

const setupMessagePosting = `
function passMessageToReactNative(event) {
  window.ReactNativeWebView.postMessage(JSON.stringify(event.data));
}
window.addEventListener("message", passMessageToReactNative);
true;
`;

const parseMessage = (event: WebViewMessageEvent): string => {
  const data = (
    typeof event.nativeEvent.data === "string"
      ? JSON.parse(event.nativeEvent.data)
      : event.nativeEvent.data
  ) as Data;
  return data.message;
};

const parseCardData = (event: WebViewMessageEvent): string => {
  const data = (
    typeof event.nativeEvent.data === "string"
      ? JSON.parse(event.nativeEvent.data)
      : event.nativeEvent.data
  ) as Data;
  const card = data?.data?.card;
  if (!card) throw new Error(`No card found in event: ${data.message}`);
  return JSON.stringify(card);
};

const AddCreditDebitCardForm: FC<Props> & Statics = ({
  uri,
  onSuccess,
  onError,
  style,
  header
}) => {
  const analytics = useAnalytics();
  const [formState, setFormState] = useState<FormState>(FormState.Loading);
  const [legalEntity] = useCurrentLegalEntity();

  const [addCreditCardMutation, { data }] = useAddCreditCardMutation();

  const handleMessage = useCallback(
    async (event: WebViewMessageEvent): Promise<void> => {
      const message = parseMessage(event);
      switch (message) {
        case "gateway.transaction.complete": {
          try {
            const cardData = parseCardData(event);
            const { data, errors } = await addCreditCardMutation({
              variables: {
                owner: legalEntity.id.toString(),
                details: {
                  rawCreditCardPayload: cardData
                }
              }
            });

            const paymentMethodId = data?.addCreditCard?.id;

            if (!paymentMethodId)
              throw new Error("Credit Card Form Did Not Return Payment Id");

            const cardCategory = data?.addCreditCard?.category || undefined;
            const cardType = data?.addCreditCard?.type || undefined;

            if (errors) {
              analytics.trackAddPaymentMethod(
                {
                  type: "Card",
                  cardCategory,
                  cardType,
                  errorMessage: errors.map((error) => error.message)
                },
                true
              );
              setFormState(FormState.Error);
              return;
            }

            analytics.trackAddPaymentMethod(
              {
                type: "Card",
                cardCategory,
                cardType
              },
              false
            );

            setFormState(FormState.Success);
            onSuccess(paymentMethodId);
          } catch (error: any) {
            analytics.trackAddPaymentMethod(
              {
                type: "Card",
                cardCategory: data?.addCreditCard?.category || undefined,
                cardType: data?.addCreditCard?.type || undefined,
                errorMessage: error.message
              },
              true
            );
            setFormState(FormState.Error);
          }
          return;
        }
        case "gateway.transaction.processing":
          setFormState(FormState.Submitting);
          return;
        case "gateway.iframe.loaded":
          setFormState(FormState.Ready);
          return;
        case "transaction.failed":
        case "transaction.cancelled":
        case "gateway.transaction.failed":
        case "gateway.transaction.cancelled": {
          analytics.trackAddPaymentMethod(
            {
              type: "Card",
              cardCategory: data?.addCreditCard?.category || undefined,
              cardType: data?.addCreditCard?.type || undefined,
              errorCode: message
            },
            true
          );

          if (onError) {
            alert(
              "Failed to add card",
              "Please make sure your card details are correct. Otherwise please contact the card issuer.",
              [
                {
                  text: "Dismiss"
                }
              ]
            );

            onError();
          } else {
            setFormState(FormState.Error);
          }

          return;
        }
        default:
          return;
      }
    },
    [addCreditCardMutation, legalEntity.id, analytics, onSuccess, onError, data]
  );

  const onLoadEnd = useCallback((): void => {
    if (Platform.OS === "android") {
      setFormState(FormState.Ready);
    }
  }, []);

  const headerHeight = useHeaderHeight();

  if (formState === FormState.Submitting)
    return <Loading style={{ flex: 1 }} />;

  if (formState === FormState.Error)
    throw new Error("Failed to process payment");

  return (
    <View style={style}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : undefined}
        keyboardVerticalOffset={headerHeight}
      >
        {header && <Container>{header}</Container>}
        <WebView
          source={{ uri }}
          injectedJavaScript={setupMessagePosting}
          onLoadEnd={onLoadEnd}
          onMessage={handleMessage}
        />
      </KeyboardAvoidingView>
      {formState === FormState.Loading && (
        <Loading
          style={{ position: "absolute", right: 0, top: 0, bottom: 0, left: 0 }}
        />
      )}
    </View>
  );
};

const Loading: FC<{ style?: ViewStyle }> = ({ style }) => {
  return (
    <LoadingOverlay style={style}>
      <Spinner />
    </LoadingOverlay>
  );
};
AddCreditDebitCardForm.Loading = Loading;

const LoadingOverlay = styled(View)`
  align-items: center;
  justify-content: center;
  background-color: ${Colors.CLOUD};
`;

const Container = styled(View)`
  padding: 16px;
  text-align: center;
  border-bottom-width: 1px;
  border-color: ${Colors.GRAYSCALE.OUTLINE};
`;

export { AddCreditDebitCardForm };
