import React from "react";
import { ViewStyle } from "react-native";
import { StyleProps } from "@ailo/primitives";
import { didQueryNotLoadYet, useOnFocus } from "@ailo/services";
import { InvestorRentHistoryCard as RentHistoryCard } from "local/domain/ledger";
import { useGetManagementLatestTenancyDetailsWithPaymentDatesQuery } from "local/graphql";

interface Props {
  style?: StyleProps<ViewStyle>;
  managementId: string;
  totalClearedRentCount: number | undefined;
  hasSharesOwned: boolean;
}

function InvestorRentHistoryCard({
  style,
  managementId,
  totalClearedRentCount,
  hasSharesOwned
}: Props): React.ReactElement | null {
  const managementDetailsResult =
    useGetManagementLatestTenancyDetailsWithPaymentDatesQuery({
      variables: { managementId }
    });
  useOnFocus(managementDetailsResult.refetch);

  if (didQueryNotLoadYet(managementDetailsResult)) {
    return <RentHistoryCard.Loading style={{ flex: 1 }} />;
  }

  const { data } = managementDetailsResult;
  const liability = data?.management?.mostRecentTenancy?.liability;

  return (totalClearedRentCount || !hasSharesOwned) &&
    liability?.lastPaymentDate ? (
    <RentHistoryCard managementId={managementId} style={style} />
  ) : totalClearedRentCount ? (
    <RentHistoryCard.Empty />
  ) : null;
}

export { InvestorRentHistoryCard };
