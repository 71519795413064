import { AiloRN } from "@ailo/ailorn";
import { RecurringDate } from "@ailo/date";
import { QuartzPlanFrequency, useHasFeature } from "@ailo/domains";
import { SFC, Text } from "@ailo/primitives";
import { didQueryNotLoadYet } from "@ailo/services";
import { formatMonthlyFrequency, ListItem } from "@ailo/ui";
import {
  useCurrentLegalEntity,
  useOnFocus,
  usePropertySelectorContext
} from "local/common";
import {
  findPropertyByManagementOrFolioAilorn,
  ManagementFormatter
} from "local/domain/ledger";
import {
  PlatformFeatureId,
  useGetAutoWithdrawPlansWithWalletQuery
} from "local/graphql";
import { orderBy, upperFirst } from "lodash";
import moment from "moment";
import React from "react";
import { isNotOffboarded } from "../../../../isNotOffboarded";
import { AutoWithdrawPlanListItem } from "./components";

interface Statics {
  Loading: SFC;
}

const WalletsList: SFC & Statics = () => {
  const [legalEntity] = useCurrentLegalEntity();
  const { allProperties } = usePropertySelectorContext();
  const plansResult = useGetAutoWithdrawPlansWithWalletQuery({
    variables: { legalEntityId: legalEntity.id.toString() }
  });

  const withdrawPlanEnabled = useHasFeature(PlatformFeatureId.AutoWithdrawPlan);

  useOnFocus(plansResult.refetch);

  if (didQueryNotLoadYet(plansResult)) {
    return <WalletsList.Loading />;
  }

  const findManagementAddressByManagementOrFolioAilorn = (
    ailorn?: AiloRN
  ): string => {
    const property = findPropertyByManagementOrFolioAilorn(
      allProperties,
      ailorn
    );
    return property
      ? ManagementFormatter(property).address || "Unknown"
      : "Unknown";
  };

  const walletsWithAutoWithdrawPlanEnabled = (
    plansResult.data?.autoWithdrawPlans?.items || []
  )
    .filter((p) =>
      isNotOffboarded(allProperties, AiloRN.from(p?.wallet?.owner?.reference))
    )
    .map((p) => {
      const ownerRef = AiloRN.from(p?.wallet?.owner?.reference);
      const isManagementOrFolio = ["management", "managementfolio"].includes(
        ownerRef.entity
      );
      const isLegalEntity = ownerRef.entity === "legalentity";

      const formattedFrequency =
        p.details.frequency === QuartzPlanFrequency.Monthly
          ? formatMonthlyFrequency(
              p.details.anniversaryDaysOfMonth ?? [],
              p.details.isLastDayOfTheMonth ?? false
            )
          : upperFirst(
              new RecurringDate({
                frequency: p.details.frequency,
                startDate: moment(p.details.startDate).format("YYYY-MM-DD")
              }).format({ type: "F-ly on D" })
            );

      return {
        id: p.id,
        name: isLegalEntity
          ? "Personal Wallet"
          : isManagementOrFolio
          ? findManagementAddressByManagementOrFolioAilorn(ownerRef)
          : "Unknown",
        frequency: formattedFrequency,
        sortKey: isManagementOrFolio
          ? findPropertyByManagementOrFolioAilorn(allProperties, ownerRef)
              ?.address?.streetName
          : "ZZZ"
      };
    });

  const walletsWithAutoWithdrawPlanEnabledAndSorted = orderBy(
    walletsWithAutoWithdrawPlanEnabled,
    ["sortKey"],
    ["asc"]
  );

  return (
    <>
      {withdrawPlanEnabled &&
        walletsWithAutoWithdrawPlanEnabledAndSorted.map((item) => (
          <AutoWithdrawPlanListItem
            key={item.id}
            id={item.id}
            name={item.name}
            frequency={item.frequency}
          />
        ))}
    </>
  );
};

const Loading: SFC = () => (
  <ListItem
    header={<Text.BodyM.Loading width={160} />}
    subHeader={<Text.BodyS.Loading width={120} />}
  />
);

WalletsList.Loading = Loading;

export { WalletsList };
