import { alert } from "@ailo/ui";
import { Screens, useAnalytics, useNavigation, useRoute } from "local/common";
import {
  CreateAutoWithdrawPlanInputV2,
  useCreateAutoWithdrawPlanMutation
} from "local/graphql";
import React, { FC, useCallback } from "react";
import { DetailsEditComponent } from "../../components";
import { BankAccountPaymentMethodOption } from "../../types";

const ConfirmDetailsScreen: FC = () => {
  const navigation = useNavigation<Screens.AutoWithdrawPlanConfirmDetails>();
  const params = useRoute<Screens.AutoWithdrawPlanConfirmDetails>().params;
  const [createAutoWithdrawPlanMutation] = useCreateAutoWithdrawPlanMutation();
  const analytics = useAnalytics();

  const showErrorAlert = useCallback((): void => {
    navigation.setParams({ showLoadingOverlay: false });

    alert(
      "Error",
      "We were unable to create an Auto Transfer. Please try again and if the problem persists, contact Ailo support",
      [{ text: "Dismiss" }]
    );
  }, [navigation]);

  const onPressNextOnSelectPaymentDestinationPercentageScreen = useCallback(
    (bankPaymentMethods: BankAccountPaymentMethodOption[]) => {
      navigation.navigate(Screens.AutoWithdrawPlanConfirmDetails, {
        ...params,
        bankPaymentMethods
      });
    },
    [navigation, params]
  );

  const onSaveClick = useCallback(
    (autoWithdrawPlanInput: CreateAutoWithdrawPlanInputV2): Promise<any> => {
      navigation.setParams({ showLoadingOverlay: true });
      return createAutoWithdrawPlanMutation({
        variables: {
          createAutoWithdrawPlanInput: autoWithdrawPlanInput
        }
      })
        .then(({ data }) => {
          const plan = data?.createAutoWithdrawPlan;
          if (plan) {
            analytics.trackAutoWithdrawPlanChanged({
              eventName: "Auto Withdraw Enabled",
              frequency: autoWithdrawPlanInput.frequency,
              paymentMethodDestinations:
                autoWithdrawPlanInput.paymentMethodDestinations
            });
            navigation.navigate(Screens.AutoWithdrawPlanSave, params);
          } else {
            showErrorAlert();
          }
        })
        .catch(showErrorAlert);
    },
    [
      navigation,
      showErrorAlert,
      createAutoWithdrawPlanMutation,
      params,
      analytics
    ]
  );

  return (
    <DetailsEditComponent
      {...params}
      mode={"create"}
      onSaveClick={onSaveClick}
      onPressNextOnSelectPaymentDestinationPercentageScreen={
        onPressNextOnSelectPaymentDestinationPercentageScreen
      }
    />
  );
};

export { ConfirmDetailsScreen };
