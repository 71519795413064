import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Colors } from "@ailo/primitives";
import { Alert, Button } from "@ailo/ui";
import { useChatAnalytics } from "@ailo/domains";
import { BaseProperty, Screens, useNavigation } from "local/common";
import { useNavigateToNewChatCompose } from "local/tabs";
import { AiloRN } from "@ailo/ailorn";
import { UseNewChatParticipants } from "./useNewChatParticipants";

interface Props {
  chatAilorn: AiloRN<"chat:chat"> | undefined;
  currentAgency: BaseProperty["agency"];
  newChatParticipants: UseNewChatParticipants;
  loadingFoundOrNewChat: boolean;
}

export const NewChatParticipantsFooter = React.memo(
  function NewChatParticipantsFooter({
    chatAilorn,
    currentAgency,
    newChatParticipants: { selectedContacts, isAPropertyManagerSelected },
    loadingFoundOrNewChat
  }: Props): ReactElement {
    const navigation = useNavigation();
    const navigateToNewChatCompose = useNavigateToNewChatCompose();
    const { trackChatOpened } = useChatAnalytics();

    const [nextPressed, setNextPressed] = useState(false);

    const showValidationError = useMemo(
      () => nextPressed && !isAPropertyManagerSelected,
      [isAPropertyManagerSelected, nextPressed]
    );

    const onNextPressed = useCallback(() => {
      setNextPressed(true);
      if (!isAPropertyManagerSelected) return;

      if (chatAilorn) {
        trackChatOpened({ chatAilorn, from: "Consumer New Chat Screen" });
        navigation.replace(Screens.Threads, { chatId: chatAilorn.internalId });
        return;
      }

      navigateToNewChatCompose(
        currentAgency.organisationAilorn,
        selectedContacts
      );
    }, [
      chatAilorn,
      currentAgency.organisationAilorn,
      isAPropertyManagerSelected,
      navigateToNewChatCompose,
      navigation,
      selectedContacts,
      trackChatOpened
    ]);

    return (
      <Footer>
        {showValidationError && (
          <FooterAlert
            message={
              "Please select at least one property manager to create a new message"
            }
          />
        )}
        <Button onPress={onNextPressed} loading={loadingFoundOrNewChat}>
          {"Next"}
        </Button>
      </Footer>
    );
  }
);

export const NewChatParticipantsFooterLoading = React.memo(
  function NewChatParticipantsFooterLoading() {
    return (
      <Footer>
        <Button disabled>{"Next"}</Button>
      </Footer>
    );
  }
);

const Footer = styled(View)`
  padding: 8px 16px;
  background-color: ${Colors.WHITE};
  box-shadow: 0px -1px 0px ${Colors.GRAYSCALE.OUTLINE};
`;

const FooterAlert = styled(Alert).attrs({
  type: "error"
})`
  margin-bottom: 16px;
`;
