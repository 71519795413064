import React, { useEffect, useCallback } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Colors, Text, SFC } from "@ailo/primitives";
import { ErrorMessage } from "local/domain/onboarding/phoneVerification";
import { inputName } from "./formData";
import { useGlobalCoolDown } from "./hooks";
import { ResendButton, CooldownMessage } from "./components";
import { useVerificationCodeForm } from "./useVerificationCodeForm";
import { VerificationCodeInput } from "./VerificationCodeInput";

interface Props {
  phoneNumber: string;
  onSuccess: () => void;
  onLoadingStateChange: (state: boolean) => void;
}

export const VerificationCodeForm: SFC<Props> = ({
  style,
  phoneNumber,
  onSuccess,
  onLoadingStateChange
}) => {
  const RESEND_COOLDOWN = 20;
  const [cooldown, setCooldown] = useGlobalCoolDown({
    coolDownTarget: phoneNumber
  });

  const { onSubmit, sending, validationErrors, formError, onReset } =
    useVerificationCodeForm({
      phoneNumber
    });

  useEffect((): void => {
    onLoadingStateChange(sending);
  }, [onLoadingStateChange, sending]);

  const error = validationErrors[inputName]?.message || formError;

  const onBlurCallback = useCallback((): void => {
    onSubmit(onSuccess)();
  }, [onSubmit, onSuccess]);

  useEffect(() => {
    setCooldown(RESEND_COOLDOWN);
  }, [setCooldown]);

  const onResetCallback = useCallback((): void => {
    setCooldown(RESEND_COOLDOWN);
    onReset();
  }, [onReset, setCooldown]);

  return (
    <Container style={style}>
      <StyledText>
        {"Please enter the 4 digit code\nwe sent to "}
        <StyledText color={Colors.AILO_RED} weight={"medium"}>
          {phoneNumber}
        </StyledText>
      </StyledText>
      <VerificationCodeInput onBlur={onBlurCallback} />
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
      {cooldown ? (
        <StyledCooldownMessage cooldown={cooldown} />
      ) : (
        <StyledResendButton onPress={onResetCallback} />
      )}
    </Container>
  );
};

const Container = styled(View)`
  padding: 0px 40px;
`;

const StyledText = styled(Text.BodyM)`
  margin-top: 20px;
  margin-bottom: 32px;
`;

const StyledResendButton = styled(ResendButton)`
  margin-top: 20px;
`;

const StyledCooldownMessage = styled(CooldownMessage)`
  margin-top: 20px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 8px;
`;
