import { AiloSentry } from "@ailo/services";
import { CommonActions } from "@react-navigation/native";
import { capitalCase } from "capital-case";
import { useAnalytics, Screens, useNavigation } from "local/common";
import {
  OnboardingTaskId,
  useCompleteOnboardingTaskMutation
} from "local/graphql";
import { useCallback } from "react";
import { titleCase } from "title-case";
import { useOnboardingContext } from "../OnboardingContext";

export function useCompleteOnboardingTask(): (
  taskId: OnboardingTaskId,
  options?: {
    skipped?: boolean;
    skipNavigation?: boolean;
    forceStoreRemotely?: boolean;
  }
) => void {
  const { incompleteOnboardingTasks, cacheCompleteOnboardingTask } =
    useOnboardingContext();
  const [completeOnboardingTaskMutation] = useCompleteOnboardingTaskMutation();
  const analytics = useAnalytics();
  const navigation = useNavigation();

  const navigateFromOnboardingTask = useCallback(
    (taskId: OnboardingTaskId) => {
      const remainingOnboardingTasks = incompleteOnboardingTasks.filter(
        (task) => task.taskId != taskId
      );

      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: remainingOnboardingTasks[0]?.screen || Screens.TabNavigator
            }
          ]
        })
      );
    },
    [incompleteOnboardingTasks, navigation]
  );

  return useCallback(
    (
      taskId: OnboardingTaskId,
      options?: {
        skipped?: boolean;
        skipNavigation?: boolean;
        forceStoreRemotely?: boolean;
      }
    ): void => {
      if (
        incompleteOnboardingTasks.find(
          ({ taskId: incompleteTaskId }) => incompleteTaskId === taskId
        )?.storedRemotely ||
        options?.forceStoreRemotely
      ) {
        completeOnboardingTaskMutation({
          variables: { onboardingTaskId: taskId }
        }).catch((error) => {
          AiloSentry.captureException(error);
        });
      }

      cacheCompleteOnboardingTask(taskId);

      analytics.trackOnboardingTaskDone(
        {
          name: titleCase(capitalCase(taskId))
        },
        !!options?.skipped
      );

      if (!options?.skipNavigation) {
        navigateFromOnboardingTask(taskId);
      }
    },
    [
      analytics,
      cacheCompleteOnboardingTask,
      completeOnboardingTaskMutation,
      incompleteOnboardingTasks,
      navigateFromOnboardingTask
    ]
  );
}
