import { Colors, Text } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import {
  Screens,
  useNavCloseButton,
  useRoute,
  ScreenComponent
} from "local/common";
import React, { FC } from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import styled from "styled-components/native";
import { useEnvironment } from "@ailo/services";
import * as WebBrowser from "expo-web-browser";

const FundsTransferredScreen: FC = () => {
  const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
  const { amount, bankAccountNumber, channel, onClose } =
    useRoute<Screens.FundsTransferred>().params;

  useNavCloseButton();

  const isNppTransfer = channel && channel.toUpperCase() === "NPP";
  const clearanceMessage = isNppTransfer
    ? " Transfers are typically available within minutes, but may take 1-2 business days. "
    : " Please allow 1 - 2 business days for funds to be accessible.";

  return (
    <ScreenComponent style={{ padding: 16, marginBottom: bottomSafeAreaInset }}>
      <Container>
        <Text.DisplayM
          color={Colors.TEXT.DARK.PRIMARY}
          style={{
            textAlign: "center"
          }}
        >
          {"Funds Transferred"}
        </Text.DisplayM>
        <Text.BodyM
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ textAlign: "center", marginTop: 8 }}
        >
          {amount +
            " has successfully been transferred from your wallet to the bank account " +
            bankAccountNumber +
            "." +
            clearanceMessage}
        </Text.BodyM>
        {isNppTransfer ? <LearnMoreLink /> : undefined}
      </Container>
      <Button.Primary onPress={onClose} accessibilityLabel={"close-button"}>
        {"Close"}
      </Button.Primary>
    </ScreenComponent>
  );
};

const LearnMoreLink: FC = () => {
  const { WALLET_TRANSFER_TIMING_HELP_URL } = useEnvironment();

  return (
    <Text.BodyS
      onPress={() =>
        WebBrowser.openBrowserAsync(WALLET_TRANSFER_TIMING_HELP_URL)
      }
      color={Colors.AILO_RED}
      weight={"medium"}
      style={{ textDecorationLine: "underline" }}
    >
      {"Learn more"}
    </Text.BodyS>
  );
};

const Container = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export { FundsTransferredScreen };
