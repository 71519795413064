import React, { ReactNode } from "react";
import { Card, ErrorAlertScreen } from "@ailo/ui";
import { ErrorBoundary } from "@ailo/services";

export function InvestorRentCardErrorBoundary({
  children
}: {
  children?: ReactNode;
}): React.ReactElement {
  return (
    <ErrorBoundary
      fallbackComponent={({ retry }): React.ReactElement => (
        <Card>
          <ErrorAlertScreen
            variant={"medium"}
            title={"There was a problem loading\nRent History"}
            onRetry={retry}
            style={{ paddingVertical: 40 }}
          />
        </Card>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}
