import React from "react";
import { TenancyListItem } from "./components";
import { ListItem } from "@ailo/ui";
import { SFC, Text } from "@ailo/primitives";
import { flatMap } from "lodash";
import { useGetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery } from "local/graphql";
import { useOnFocus, useCurrentLegalEntity } from "local/common";
import { LiabilityPaymentPlanListItem } from "./components/LiabilityPaymentPlanListItem";

interface Statics {
  Loading: SFC;
}

interface AutoPaymentTenancyDetails {
  tenancyId: string;
  liabilityId: string;
  autoPayPayment: {
    paymentMethodId: string;
  };
  liabilityPaymentPlans: {
    id: string;
    frequency: string;
  }[];
  unitStreetNumber: string;
  streetName: string;
}

const TenanciesList: SFC & Statics = () => {
  const [legalEntity] = useCurrentLegalEntity();

  const { loading, data, refetch } =
    useGetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery({
      variables: { payerLegalEntityId: legalEntity.id.toString() }
    });

  useOnFocus(refetch);

  if (loading && !data) {
    return <TenanciesList.Loading />;
  }

  const tenancies = (data?.tenancies?.items || []).map((tenancy) => ({
    tenancyId: tenancy?.id,
    liabilityId: tenancy?.liability?.id,
    autoPayPayment: {
      paymentMethodId: tenancy?.liability?.autoPaymentDetails?.paymentMethod?.id
    },
    liabilityPaymentPlans: tenancy?.liability?.paymentPlans?.items?.map(
      (liabilityPaymentPlan) => ({
        id: liabilityPaymentPlan?.id,
        frequency: liabilityPaymentPlan?.details?.frequency.toString()
      })
    ),
    unitStreetNumber: tenancy?.property?.address?.unitStreetNumber,
    streetName: tenancy?.property?.address?.streetName
  })) as AutoPaymentTenancyDetails[];

  const tenanciesWithAutoPayRentEnabled = tenancies.filter((tenancy) => {
    const { tenancyId, liabilityId } = tenancy;
    const { paymentMethodId } = tenancy?.autoPayPayment;
    return Boolean(tenancyId && liabilityId && paymentMethodId);
  });

  const liabilityPaymentPlanEnabledTenancies = tenancies.filter(
    (t) => t.liabilityPaymentPlans && t.liabilityPaymentPlans.length
  );

  const liabilityPaymentPlans = flatMap(
    liabilityPaymentPlanEnabledTenancies,
    (t) =>
      t.liabilityPaymentPlans.map((p) => ({
        id: p.id,
        frequency: p.frequency,
        unitStreetNumber: t.unitStreetNumber,
        streetName: t.streetName
      }))
  );

  return (
    <>
      {tenanciesWithAutoPayRentEnabled.map(
        ({
          tenancyId,
          liabilityId,
          autoPayPayment,
          unitStreetNumber,
          streetName
        }) => (
          <TenancyListItem
            key={tenancyId}
            tenancyId={tenancyId}
            liabilityId={liabilityId}
            paymentMethodId={autoPayPayment.paymentMethodId}
            unitStreetNumber={unitStreetNumber}
            streetName={streetName}
          />
        )
      )}
      {liabilityPaymentPlans.map(
        ({ id, frequency, unitStreetNumber, streetName }) => (
          <LiabilityPaymentPlanListItem
            key={id}
            id={id}
            frequency={frequency}
            unitStreetNumber={unitStreetNumber}
            streetName={streetName}
          />
        )
      )}
    </>
  );
};

const Loading: SFC = () => (
  <ListItem
    header={<Text.BodyM.Loading width={160} />}
    subHeader={<Text.BodyS.Loading width={120} />}
  />
);

TenanciesList.Loading = Loading;

export { TenanciesList };
