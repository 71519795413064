import { Screens, useNavigation, useRoute } from "local/common";
import React, { FC, useState } from "react";
import { Alert, DateInputFormField, ListItem } from "@ailo/ui";
import styled from "styled-components/native";
import { SelectableRadioIcon } from "../../components";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import { View } from "react-native";
import { Colors, opacify } from "@ailo/primitives";
import moment from "moment";

enum EndType {
  moveOut,
  selectDate
}

const SelectEndDateScreen: FC = () => {
  const navigation = useNavigation<Screens.LiabilityPlanSelectEndDate>();
  const params = useRoute<Screens.LiabilityPlanSelectEndDate>().params;
  const { endDate, startDate } = params;
  const [selectedDate, setSelectedDate] = useState(endDate ?? undefined);
  const [selectedEndType, setEndType] = useState(
    endDate ? EndType.selectDate : EndType.moveOut
  );

  const onNextClick = (): void => {
    navigation.navigate(Screens.LiabilityPlanConfirmDetails, {
      ...params,
      endDate: selectedDate
    });
  };

  const onMoveOuTSelected = (): void => {
    setEndType(EndType.moveOut);
    setSelectedDate(undefined);
  };

  const onSelectDateSelected = (): void => {
    setEndType(EndType.selectDate);
  };

  const body = (
    <Body>
      <RadioListItem
        type={EndType.moveOut}
        selected={selectedEndType}
        header={"When my rent finishes"}
        onClick={onMoveOuTSelected}
      />

      <RadioListItem
        type={EndType.selectDate}
        selected={selectedEndType}
        header={"Choose end date"}
        onClick={onSelectDateSelected}
      >
        <DateInputFormField
          value={
            selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : undefined
          }
          minDate={moment(startDate).format("YYYY-MM-DD")}
          onChange={(date): void =>
            setSelectedDate(
              date ? moment(date, "YYYY-MM-DD").toDate() : undefined
            )
          }
          placeholder={"Select end date"}
        />
      </RadioListItem>

      <Alert type={"info"} style={{ marginTop: 24 }}>
        {"Your autopayment will automatically be stopped when you move out"}
      </Alert>
    </Body>
  );

  const selectedEndDateValid =
    selectedEndType == EndType.selectDate &&
    selectedDate &&
    moment(selectedDate).isSameOrAfter(moment(startDate), "day");

  return (
    <ScreenWithHeaderAndAction
      header={`What day should your\nauto rent payments end?`}
      primaryAction={{
        disabled: !selectedEndDateValid && selectedEndType != EndType.moveOut,
        onClick: onNextClick,
        text: "Next"
      }}
    >
      {body}
    </ScreenWithHeaderAndAction>
  );
};
interface RadioListItemProps {
  header: string;
  selected: EndType;
  type: EndType;
  onClick: (type: EndType) => void;
}
const RadioListItem: FC<RadioListItemProps> = ({
  header,
  selected,
  type,
  onClick,
  children
}) => {
  return (
    <RadioListItemContainer>
      <ListItem
        header={header}
        style={{ paddingHorizontal: 0, borderType: "none" }}
        leftComponent={
          <SelectableRadioIcon
            style={{ paddingRight: 18 }}
            checked={selected == type}
          />
        }
        onPress={(): void => onClick(type)}
      />
      {selected == type && children}
      {selected == type && children && <View style={{ paddingBottom: 20 }} />}
    </RadioListItemContainer>
  );
};

const RadioListItemContainer = styled(View)`
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;

const Body = styled(View)`
  margin-left: 16px;
  margin-right: 16px;
`;

export { SelectEndDateScreen };
