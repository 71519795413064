import {
  didQueryNotLoadYet,
  didQuerySucceed,
  ensureMutationSucceeds,
  logApolloResultFailed,
  withScreenComponent,
  useAnalytics,
  AiloSentry
} from "@ailo/services";
import {
  Button,
  ErrorAlertScreen,
  FormField,
  SpinnerOverlay,
  TextInput,
  useToastContext
} from "@ailo/ui";
import {
  ScreenContainer,
  ScreenHeader,
  Screens,
  useNavigation,
  useRoute
} from "local/common";

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { nameValidationRules } from "../common";
import {
  useGetCurrentPersonProfileDetailsQuery,
  useUpdatePersonProfileDetailsMutation
} from "local/graphql";
import { Keyboard } from "react-native";

interface FormData {
  legalFirstName: string;
  legalMiddleName: string | null;
  lastName: string | null;
}

export const EditGivenNameScreen = withScreenComponent(
  {
    statusBarStyle: "dark-content",
    viewTitle: null
  },
  (): React.ReactElement => {
    const toasts = useToastContext();
    const navigation = useNavigation();
    const {
      params: {
        title = "Update Given Name",
        submitButtonLabel = "Update Given Name",
        onSuccess
      } = {}
    } = useRoute<Screens.EditGivenName>();
    const analytics = useAnalytics();
    const result = useGetCurrentPersonProfileDetailsQuery({
      notifyOnNetworkStatusChange: true
    });

    const [updateMutation, mutationResult] =
      useUpdatePersonProfileDetailsMutation();

    const { control, handleSubmit, formState, errors } = useForm<FormData>();

    if (didQueryNotLoadYet(result)) {
      return <SpinnerOverlay />;
    }

    if (!didQuerySucceed(result) || !result.data.effectiveUser) {
      logApolloResultFailed(result, {
        operationId: "result"
      });
      return (
        <ErrorAlertScreen
          title={"There was a problem loading profile data"}
          onRetry={result.refetch}
        />
      );
    }

    const { person } = result.data.effectiveUser;

    const submit = handleSubmit(async (data: FormData) => {
      Keyboard.dismiss();
      try {
        await ensureMutationSucceeds(
          updateMutation({
            variables: {
              input: {
                id: person.ailoRN,
                legalFirstName: data.legalFirstName,
                legalMiddleName: data.legalMiddleName || null,
                lastName: data.lastName || null
              }
            }
          }),
          {
            dataKey: "updatePersonProfileDetails"
          }
        );
      } catch (error) {
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      if (onSuccess) {
        onSuccess();
      } else {
        toasts.show({
          type: "success",
          message: "Given name updated"
        });
        navigation.navigate(Screens.EditProfile);
      }
      analytics.track("Profile Updated", {
        fields: ["First Name", "Last Name"]
      });
    });

    return (
      <>
        <ScreenContainer
          stickyBottom={
            <Button.Primary
              disabled={formState.isSubmitting || !formState.isDirty}
              onPress={submit}
            >
              {submitButtonLabel}
            </Button.Primary>
          }
        >
          <ScreenHeader title={title} />

          <FormField
            label={"First Name"}
            error={errors.legalFirstName?.message}
            style={{ marginTop: 24 }}
          >
            <Controller
              control={control}
              name={"legalFirstName"}
              rules={{
                required: "First Name is required.",
                ...nameValidationRules
              }}
              defaultValue={person.legalFirstName}
              render={({ onChange, onBlur, value }): React.ReactElement => (
                <TextInput
                  textContentType={"givenName"}
                  autoCapitalize={"words"}
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FormField>
          <FormField
            label={"Middle Name (optional)"}
            error={errors.legalMiddleName?.message}
            style={{ marginTop: 12 }}
          >
            <Controller
              control={control}
              name={"legalMiddleName"}
              rules={nameValidationRules}
              defaultValue={person.legalMiddleName ?? null}
              render={({ onChange, onBlur, value }): React.ReactElement => (
                <TextInput
                  textContentType={"middleName"}
                  autoCapitalize={"words"}
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FormField>
          <FormField
            label={"Last Name"}
            error={errors.lastName?.message}
            style={{ marginTop: 12 }}
          >
            <Controller
              control={control}
              name={"lastName"}
              rules={{
                required: "Last Name is required.",
                ...nameValidationRules
              }}
              defaultValue={person.lastName ?? null}
              render={({ onChange, onBlur, value }): React.ReactElement => (
                <TextInput
                  textContentType={"familyName"}
                  autoCapitalize={"words"}
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FormField>
        </ScreenContainer>
        {mutationResult.loading && <SpinnerOverlay />}
      </>
    );
  }
);
