import React, { useCallback } from "react";
import { AppNavigatorStack, Screens } from "local/common";
import { useLastCommsNotificationResponse } from "@ailo/domains";
import { NewChatParticipantsScreen } from "./NewChatParticipantsScreen";
import { ConsumerThreadScreen } from "./ConsumerThreadScreen";
import { ConsumerMessageScreen } from "./ConsumerMessageScreen";
import { NewChatMessageScreen } from "./NewChatScreen";

export function useGetCommsChatScreens():
  | ((Stack: AppNavigatorStack) => JSX.Element)
  | undefined {
  const lastCommsNotificationResponse = useLastCommsNotificationResponse();

  const getCommsChatScreens = useCallback(
    (Stack: AppNavigatorStack) => (
      <>
        <Stack.Screen
          name={Screens.NewChatParticipants}
          component={NewChatParticipantsScreen}
          options={{ title: "New Message" }}
        />
        <Stack.Screen
          name={Screens.NewChatMessage}
          component={NewChatMessageScreen}
          options={{ title: "New Message" }}
        />
        <Stack.Screen
          name={Screens.Threads}
          component={ConsumerThreadScreen}
          initialParams={{
            chatId:
              lastCommsNotificationResponse?.type === "chat"
                ? lastCommsNotificationResponse.chatId
                : undefined
          }}
        />
        <Stack.Screen
          name={Screens.Messages}
          component={ConsumerMessageScreen}
          initialParams={{
            threadId:
              lastCommsNotificationResponse?.type === "thread"
                ? lastCommsNotificationResponse.threadId
                : undefined
          }}
        />
      </>
    ),
    [lastCommsNotificationResponse]
  );

  if (lastCommsNotificationResponse === undefined) return undefined;
  return getCommsChatScreens;
}
