import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { useGetAutoPayLiabilityStatusQuery } from "local/graphql";
import { ApolloError } from "@apollo/client";
import { useCurrentLegalEntity } from "local/common";
import { AiloRN } from "@ailo/ailorn";

export type AutoPayLiabilityStatus = {
  enabled: boolean;
  payerId: AiloRN;
  maximumPaymentAmount: { cents: number };
  paymentMethodId?: string;
};

/**
 * Returns information on whether "Auto Pay Bills" is enabled.
 */
export function useAutoPayLiabilityStatus(
  payerId?: AiloRN
): MappedQueryResult<AutoPayLiabilityStatus | undefined> {
  const [legalEntity] = useCurrentLegalEntity();
  const result = useGetAutoPayLiabilityStatusQuery({
    variables: {
      payerLegalEntityId: legalEntity.id.toString(),
      payerId: payerId ? payerId.toString() : legalEntity.id.toString()
    }
  });
  return useMappedQueryResult(result, (data) => {
    const status = data.autoPayLiabilityStatus;

    if (!status) {
      throw new ApolloError({
        errorMessage: "autoPayLiabilityStatus query returned null"
      });
    }

    return {
      enabled: status.enabled,
      payerId: AiloRN.fromString(status.payerId),
      maximumPaymentAmount: status.maximumPaymentAmount || { cents: 0 },
      paymentMethodId: status.paymentMethodId || undefined
    };
  });
}
