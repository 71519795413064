import React, { FC } from "react";
import { hasPresentKey } from "ts-is-present";
import { DateRange, LocalDate, LocalDateFormat } from "@ailo/date";
import { Colors, RightChevronIcon, Text } from "@ailo/primitives";
import {
  didQuerySucceed,
  didQueryNotLoadYet,
  useOnFocus
} from "@ailo/services";
import { Card, ErrorAlertScreen, ListItem } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import { useGetManagementFormerTenanciesQuery } from "local/graphql";
import styled from "styled-components/native";

interface Props {
  managementId: string;
}

function InvestorFormerTenanciesCard({
  managementId
}: Props): React.ReactElement | null {
  const result = useGetManagementFormerTenanciesQuery({
    variables: { managementId }
  });
  useOnFocus(result.refetch);
  const navigation = useNavigation();

  if (didQueryNotLoadYet(result)) {
    return null;
  }

  if (!didQuerySucceed(result)) {
    return InvestorFormerTenanciesErrorCard(result.refetch);
  }

  const formerTenancies = result?.data?.management?.formerTenancies?.items
    ?.filter(hasPresentKey("id"))
    ?.filter(hasPresentKey("startDate"))
    ?.filter(hasPresentKey("endDate"));
  if (!formerTenancies || formerTenancies.length === 0) {
    return null;
  }
  // safe to assume that there is at least 1 former tenancy at this point
  return (
    <CardContainer>
      <Text.BodyM
        color={Colors.TEXT.DARK.PRIMARY}
        style={{ paddingBottom: 16, paddingHorizontal: 16 }}
        weight={"medium"}
      >
        {"Former tenancies"}
      </Text.BodyM>

      {formerTenancies.map((formerTenancy, i) => {
        return (
          <FormerTenancyListItem
            key={i}
            startDate={new LocalDate(formerTenancy.startDate)}
            endDate={new LocalDate(formerTenancy.endDate)}
            hideBottomBorder={i === formerTenancies.length - 1}
            onPress={() => {
              navigation.navigate(Screens.InvestorRentHistory, {
                managementId: managementId!,
                tenancyId: formerTenancy.id!
              });
            }}
          />
        );
      })}
    </CardContainer>
  );
}

function InvestorFormerTenanciesErrorCard(
  retry: (() => void) | undefined
): React.ReactElement {
  return (
    <Card>
      <ErrorAlertScreen
        variant={"medium"}
        title={"There was a problem loading\nFormer Tenancies"}
        onRetry={retry}
        style={{ paddingVertical: 40 }}
      />
    </Card>
  );
}

const CardContainer = styled(Card)`
  padding: 20px 0 0;
  background-color: ${Colors.WHITE};
`;

interface ListProps {
  hideBottomBorder: boolean;
  endDate: LocalDate;
  startDate: LocalDate;
  onPress: () => void;
}

const FormerTenancyListItem: FC<ListProps> = ({
  hideBottomBorder,
  endDate,
  startDate,
  onPress
}) => {
  return (
    <ListItem
      onPress={onPress}
      header={
        <Text.BodyM weight={"medium"}>
          {new DateRange({
            start: startDate,
            end: endDate
          }).format({
            dateFormat: "D MMM YYYY" as LocalDateFormat
          })}
        </Text.BodyM>
      }
      rightComponent={<RightChevronIcon width={20} height={20} />}
      style={{
        borderType: hideBottomBorder ? "none" : "under-center-and-right"
      }}
    />
  );
};

export { InvestorFormerTenanciesCard, InvestorFormerTenanciesErrorCard };
