import React from "react";
import { View } from "react-native";
import {
  DateTimeWithTimeZone,
  HouseViewWithDetails,
  GreenTruckViewWithDetails
} from "@ailo/ui";
import { FloatingClouds } from "local/common";
import { Color } from "react-native-svg";

interface Props {
  variant: "medium" | "small";
  terminationDate: string;
}

function TenancyEndDisplay({
  variant,
  terminationDate
}: Props): React.ReactElement {
  const terminationDateWithTimeZone =
    DateTimeWithTimeZone.fromString(terminationDate);
  const formattedTerminationDate =
    terminationDateWithTimeZone.format("D MMMM YYYY");

  const tenancyHasEnded = terminationDateWithTimeZone.daysAfterToday() < 0;

  if (variant === "small") {
    if (!tenancyHasEnded) {
      return (
        <GreenTruckViewWithDetails
          variant={"footer"}
          heading={"Tenancy Ends"}
          subHeading={formattedTerminationDate}
        >
          <View style={{ paddingBottom: 16 }} />
          <FooterTwinFloatingClouds color={"rgba(71, 158, 50)"} />
        </GreenTruckViewWithDetails>
      );
    }

    return (
      <HouseViewWithDetails
        variant={"footer"}
        heading={"Tenancy Ended"}
        subHeading={formattedTerminationDate}
      >
        <View style={{ paddingBottom: 16 }} />
        <FooterTwinFloatingClouds />
      </HouseViewWithDetails>
    );
  }

  if (!tenancyHasEnded) {
    return (
      <GreenTruckViewWithDetails
        heading={"Tenancy Ends"}
        subHeading={formattedTerminationDate}
      >
        <View style={{ paddingBottom: 36 }} />
        <TwinFloatingClouds color={"rgba(71, 158, 50)"} />
      </GreenTruckViewWithDetails>
    );
  }

  return (
    <HouseViewWithDetails
      heading={"Tenancy Ended"}
      subHeading={formattedTerminationDate}
      houseStyle={{ paddingTop: 8 }}
    >
      <View style={{ paddingBottom: 36 }} />
      <TwinFloatingClouds />
    </HouseViewWithDetails>
  );
}

function TwinFloatingClouds({ color }: { color?: Color }): React.ReactElement {
  return (
    <FloatingClouds
      style={{
        left: 0,
        right: 0,
        top: 44,
        position: "absolute",
        zIndex: -1
      }}
      config={[
        {
          delay: 4000,
          topOverlap: -6,
          duration: 37000,
          opacity: { start: 0.1, end: 0.05 }
        },
        { delay: 0, duration: 25000, opacity: { start: 0.05, end: 0.025 } }
      ]}
      color={color}
    />
  );
}

function FooterTwinFloatingClouds({
  color
}: {
  color?: Color;
}): React.ReactElement {
  return (
    <>
      <FloatingClouds
        style={{
          left: -100,
          right: 0,
          top: 5,
          position: "absolute",
          zIndex: -1
        }}
        config={[
          {
            delay: 0,
            duration: 25000,
            scale: 0.57,
            opacity: { start: 0.1, end: 0.05 }
          }
        ]}
        color={color}
      />
      <FloatingClouds
        style={{
          left: 178,
          right: 0,
          top: 30,
          position: "absolute",
          zIndex: -1
        }}
        config={[
          {
            delay: 0,
            duration: 37000,
            scale: 0.57,
            opacity: { start: 0.1, end: 0.05 }
          }
        ]}
        color={color}
      />
    </>
  );
}

export { TenancyEndDisplay };
