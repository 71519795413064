import React from "react";
import { RecommendOtherApp } from "@ailo/ui";
import { withScreenComponent, useAuth, useEnvironment } from "@ailo/services";

export const RecommendAiloInsightScreen = withScreenComponent(
  (): React.ReactElement => {
    const { logout } = useAuth();
    const envVars = useEnvironment();

    return (
      <RecommendOtherApp
        primaryMessage={`Oops! Are you looking for Ailo Insights?`}
        secondaryMessage={`You've logged into the Ailo app for managing renters and property investors.`}
        recommendingApp={{
          name: "Ailo Insights",
          url: envVars.INSIGHT_URL
        }}
        onTryAgainButtonPress={logout}
      />
    );
  }
);
