import React from "react";
import { View } from "react-native";
import { Colors, opacify, SFC } from "@ailo/primitives";
import { RentHeader, TenanciesList } from "./components";
import { SetupAutoPayRentButton } from "local/domain/liabilityPaymentPlan";
import styled from "styled-components/native";

const RentSection: SFC = () => {
  return (
    <View>
      <RentHeader />
      <TenanciesList />
      <PayButtonContainer>
        <SetupAutoPayRentButton />
      </PayButtonContainer>
    </View>
  );
};

const PayButtonContainer = styled(View)`
  padding: 16px;
  background: white;
  border-bottom-width: 1px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;

export { RentSection };
