import { useAnalytics, useCurrentLegalEntity } from "local/common";
import {
  GetPaymentMethodsDocument,
  GetPaymentMethodsQuery,
  useDeletePaymentMethodMutation
} from "local/graphql";
import { useCallback } from "react";
import { AiloSentry } from "@ailo/services";
import { CreditCardTypeName, PaymentMethodDomainType } from "@ailo/domains";
import { cloneDeep } from "lodash";

export function useDeletePaymentMethod(): (
  paymentMethodDetails: PaymentMethodDomainType
) => Promise<boolean> {
  const [legalEntity] = useCurrentLegalEntity();
  const [deletePaymentMethodMutation] = useDeletePaymentMethodMutation();
  const analytics = useAnalytics();

  return useCallback(
    (paymentMethodDetails: PaymentMethodDomainType) => {
      return deletePaymentMethodMutation({
        variables: { paymentMethodId: paymentMethodDetails.id },
        update: (store, { data }) => {
          if (data?.deletePaymentMethod) {
            const data = cloneDeep(
              store.readQuery<GetPaymentMethodsQuery>({
                query: GetPaymentMethodsDocument,
                variables: {
                  legalEntityId: legalEntity.id.toString()
                }
              })
            );

            if (data) {
              if (data?.legalEntity?.paymentMethods?.items.length) {
                data.legalEntity.paymentMethods.items =
                  data.legalEntity.paymentMethods.items.filter(
                    (pm) => pm?.id !== paymentMethodDetails.id
                  );
              }
              store.writeQuery({ query: GetPaymentMethodsDocument, data });
            }
          }
        }
      })
        .then((result) => {
          const { data, errors } = result;
          const success = data?.deletePaymentMethod;

          if (!success || errors) {
            analytics.trackRemovePaymentMethod(
              {
                type:
                  paymentMethodDetails.__typename === CreditCardTypeName
                    ? "Card"
                    : "Bank Account",
                errorMessage: errors
                  ? errors.map((error) => error.message)
                  : "Unknown Error Removing a Payment Method",
                ...(paymentMethodDetails.__typename === CreditCardTypeName && {
                  cardType: paymentMethodDetails.type
                    ? paymentMethodDetails.type
                    : undefined
                })
              },
              true
            );
          } else {
            analytics.trackRemovePaymentMethod(
              {
                type:
                  paymentMethodDetails.__typename === CreditCardTypeName
                    ? "Card"
                    : "Bank Account",
                ...(paymentMethodDetails.__typename === CreditCardTypeName && {
                  cardType: paymentMethodDetails.type
                    ? paymentMethodDetails.type
                    : undefined
                })
              },
              false
            );
          }
          return !!success;
        })
        .catch((error) => {
          AiloSentry.captureException(error);
          return false;
        });
    },
    [deletePaymentMethodMutation, analytics, legalEntity]
  );
}
