import React, { useState } from "react";
import { SFC, Colors, Text } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { useCompleteOnboardingTask } from "local/domain/onboarding";
import { OnboardingTaskId } from "local/graphql";

const SkipButton: SFC = ({ style }) => {
  const [disabled, setDisabled] = useState(false);
  const completeOnboardingTask = useCompleteOnboardingTask();

  const onPress = (): void => {
    setDisabled(true);
    completeOnboardingTask(OnboardingTaskId.AllowPushNotifications, {
      skipped: true
    });
  };

  return (
    <Button.Text style={style} onPress={onPress} disabled={disabled}>
      <Text.BodyM
        weight={"medium"}
        color={Colors.AILO_RED}
        testID={"notifications-skip-button"}
      >
        {"I"}&apos;{"ll do this later"}
      </Text.BodyM>
    </Button.Text>
  );
};

export { SkipButton };
