import React, { ReactElement } from "react";
import { Screens, useRoute } from "local/common";
import {
  ConsumerWelcomeMessage,
  ThreadScreen,
  useWatchChatNavigationParams
} from "@ailo/domains";
import { useEnsureThreadScreenCanGoBack, useOpenThread } from "local/tabs";

export function ConsumerThreadScreen(): ReactElement {
  const { chatId } = useRoute<Screens.Threads>().params || {};
  useWatchChatNavigationParams({ params: { chatId } });

  useEnsureThreadScreenCanGoBack();

  const openThread = useOpenThread();

  return (
    <ThreadScreen
      onOpenThread={openThread}
      welcomeMessage={<ConsumerWelcomeMessage />}
      readonlyBannerType={"consumer-chat"}
    />
  );
}
