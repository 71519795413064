import { RightChevronIcon } from "@ailo/primitives";
import { ListItem } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import {
  AutoPayLiabilityStatus,
  useAutoPayLiabilityStatusDetails
} from "local/domain/ledger";
import React, { FC, useCallback } from "react";

interface Props {
  status: AutoPayLiabilityStatus;
}

const AutoPayLiabilityStatusListItem: FC<Props> = ({ status }) => {
  const navigation = useNavigation();

  const {
    fromPaymentMethod,
    isManagementOrManagementFolio,
    isLegalEntity,
    managementAddress
  } = useAutoPayLiabilityStatusDetails(status);

  const handleSetupAutoPayClick = useCallback(() => {
    navigation.navigate(Screens.ManageAutoPayBills, {
      payerId: status.payerId
    });
  }, [navigation, status]);

  return (
    <ListItem
      onPress={handleSetupAutoPayClick}
      header={`${
        fromPaymentMethod
          ? "Payment Method"
          : isLegalEntity
          ? "Personal Wallet"
          : isManagementOrManagementFolio
          ? managementAddress
          : "Unknown"
      }`}
      subHeader={`Pay from ${
        fromPaymentMethod
          ? "Payment Method"
          : isLegalEntity
          ? "Wallet"
          : isManagementOrManagementFolio
          ? "Property Wallet"
          : "Unknown"
      }`}
      rightComponent={<RightChevronIcon width={20} height={20} />}
    />
  );
};

export { AutoPayLiabilityStatusListItem, useAutoPayLiabilityStatusDetails };
