import React, { FC, useEffect, useState } from "react";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  View
} from "react-native";
import styled from "styled-components/native";
import { useHeaderHeight } from "@react-navigation/stack";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useForm } from "react-hook-form";
import { Button, SpinnerOverlay, FormErrorBox, alert } from "@ailo/ui";
import {
  useAnalytics,
  useCurrentLegalEntity,
  ScreenComponent,
  Screens,
  useRoute,
  useNavCloseButton
} from "local/common";
import { useAddBankAccountMutation } from "local/graphql";
import {
  AccountNameInputField,
  AccountNumberInputField,
  BsbInputField,
  ErrorScreen
} from "./components";
import { GraphQLError } from "graphql";
import { Colors } from "@ailo/primitives";
import { BankAccountPaymentMethod } from "local/domain/ledger/hooks";
import { maskNumber } from "@ailo/domains";

interface FormData {
  accountName: string;
  accountNumber: string;
  bsb: string;
}

interface Props {
  buttonText: string;
  onceOff: boolean;
  onSuccess: (paymentMethod: BankAccountPaymentMethod) => void;
  background?: string;
}

const AddBankAccountScreenComponent: FC<Props> = ({
  buttonText,
  onceOff,
  onSuccess,
  background
}) => {
  const headerHeight = useHeaderHeight();
  const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
  const { control, errors, handleSubmit } = useForm<FormData>({
    shouldFocusError: true
  });
  const [legalEntity] = useCurrentLegalEntity();

  const [addBankAccountMutation, { data, loading, error }] =
    useAddBankAccountMutation();

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const { id, accountNumber, accountName } = data?.addBankAccount ?? {};
    if (id) {
      setIsSuccess(true);
      onSuccess({ id, accountNumber: maskNumber(accountNumber), accountName });
    }
  }, [data, onSuccess]);

  const analytics = useAnalytics();

  const addBankAccount = handleSubmit(
    async ({ accountName, accountNumber, bsb }) => {
      Keyboard.dismiss();

      const { errors } = await addBankAccountMutation({
        variables: {
          owner: legalEntity.id.toString(),
          details: {
            accountName: accountName.replace(/[‘’]/g, "'"),
            accountNumber,
            bsb: bsb.replace(/\s/g, ""),
            onceOff
          }
        }
      });

      if (!errors) {
        analytics.trackAddPaymentMethod({ type: "Bank Account" }, false);
      } else {
        showErrorIfBlacklisted(errors);
        analytics.trackAddPaymentMethod(
          {
            type: "Bank Account",
            errorMessage: errors.map((error) => error.message)
          },
          true
        );
      }
    }
  );

  const showErrorIfBlacklisted = (errors: readonly GraphQLError[]): void => {
    const hasBlacklistedError = errors.some((e) =>
      e.message.includes("account is blacklisted")
    );

    if (hasBlacklistedError) {
      alert(
        "Error",
        "This bank account cannot be used. Please check the details and try again or contact Ailo support",
        [{ text: "Dismiss" }]
      );
    }
  };

  const isLoading = (!data && loading) || isSuccess;
  if (!data && error) return <ErrorScreen />;

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      keyboardVerticalOffset={headerHeight}
      style={{ marginBottom: bottomSafeAreaInset, flex: 1 }}
    >
      <ScreenContainer style={{ backgroundColor: background || Colors.CLOUD }}>
        <ScrollView>
          <FormErrorBox errors={errors} />
          <View style={{ flexDirection: "row" }}>
            <AccountNameInputField
              control={control}
              hasError={!!errors.accountName}
              style={{ flex: 1 }}
            />
          </View>
          <View style={{ flexDirection: "row", marginTop: 16 }}>
            <BsbInputField
              control={control}
              hasError={!!errors.bsb}
              style={{ flex: 1 }}
            />
            <AccountNumberInputField
              control={control}
              hasError={!!errors.accountNumber}
              style={{ marginLeft: 12, flex: 1 }}
            />
          </View>
        </ScrollView>
        <Button.Primary
          disabled={isLoading}
          onPress={addBankAccount}
          style={{ marginTop: 20 }}
          testID={"add-bank-account-button"}
        >
          {isLoading ? "Loading..." : buttonText}
        </Button.Primary>
      </ScreenContainer>
      {isLoading && <SpinnerOverlay />}
    </KeyboardAvoidingView>
  );
};

const AddBankAccountScreen: React.FC = () => {
  useNavCloseButton();
  const { params } = useRoute<Screens.AddBankAccount>();
  const { onSuccess } = params;

  return (
    <ScreenComponent>
      <AddBankAccountScreenComponent
        buttonText={"Add Bank Account"}
        onceOff={false}
        onSuccess={onSuccess}
      />
    </ScreenComponent>
  );
};

const ScreenContainer = styled(View)`
  flex: 1;
  padding: 20px 16px 16px 16px;
  justify-content: space-between;
`;

export { AddBankAccountScreen, AddBankAccountScreenComponent };
