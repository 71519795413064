import React, { useEffect } from "react";
import { View } from "react-native";
import { Text, SFC } from "@ailo/primitives";
import styled from "styled-components/native";
import { SubmitButton } from "./components";
import { PhoneNumberInput } from "./PhoneNumberInput";
import { usePhoneNumberForm } from "./usePhoneNumberForm";
import { ErrorMessage } from "local/domain/onboarding/phoneVerification";
import { inputName } from "./formData";

interface Props {
  onSuccess: (phoneNumber: string) => void;
  onLoadingStateChange: (state: boolean) => void;
}

const PhoneNumberForm: SFC<Props> = ({
  style,
  onSuccess,
  onLoadingStateChange
}) => {
  const { onSubmit, sending, validationErrors, formError } =
    usePhoneNumberForm();

  useEffect((): void => {
    onLoadingStateChange(sending);
  }, [onLoadingStateChange, sending]);

  const error = validationErrors[inputName]?.message || formError;

  return (
    <Container style={style}>
      <Title weight={"medium"}>{"Let’s secure your account"}</Title>
      <Subtitle weight={"book"}>
        {"We’ll start by sending a verification code to your mobile number."}
      </Subtitle>
      <StyledPhoneInput />
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
      <StyledSubmitButton onPress={onSubmit(onSuccess)} disabled={sending} />
    </Container>
  );
};

const Container = styled(View)`
  padding: 0px 40px;
`;

const Title = styled(Text.DisplayS)``;

const Subtitle = styled(Text.BodyM)`
  margin-top: 12px;
`;

const StyledPhoneInput = styled(PhoneNumberInput)`
  margin-top: 32px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 8px;
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 24px;
`;

export { PhoneNumberForm };
