import React, { ReactElement } from "react";
import { AppNavigatorStack, Screens } from "local/common";
import { ChooseAutoPayTypeScreen } from "./screens/ChooseAutoPayTypeScreen";
import {
  SelectLiabilityAutoPropertyScreen,
  SelectLiabilityPropertyScreen
} from "./screens/SelectPropertyScreen";
import { SelectAmountScreen } from "./screens/SelectAmountScreen";
import { SelectFrequencyScreen } from "./screens/SelectFrequencyScreen";
import { SelectStartDateScreen } from "./screens/SelectStartDateScreen";
import { SelectEndDateScreen } from "./screens/SelectEndDateScreen";
import { ConfirmDetailsScreen } from "./screens/ConfirmDetailsScreen";
import { SaveScreen } from "./screens/SaveScreen";
import { EditDetailsScreen } from "./screens/EditDetailsScreen";

const getLiabilityPaymentPlanScreens = (
  Stack: AppNavigatorStack
): ReactElement => {
  const defaultTitle = "Setup Auto Rent Payments";

  const screens = [
    {
      name: Screens.LiabilityPlanChooseType,
      component: ChooseAutoPayTypeScreen
    },
    {
      name: Screens.LiabilityPlanSelectProperty,
      component: SelectLiabilityPropertyScreen
    },
    {
      name: Screens.LiabilityPlanAutoPaySelectProperty,
      component: SelectLiabilityAutoPropertyScreen
    },
    {
      name: Screens.LiabilityPlanSelectAmount,
      component: SelectAmountScreen
    },
    {
      name: Screens.LiabilityPlanSelectFrequency,
      component: SelectFrequencyScreen
    },
    {
      name: Screens.LiabilityPlanSelectStartDate,
      component: SelectStartDateScreen
    },
    {
      name: Screens.LiabilityPlanSelectEndDate,
      component: SelectEndDateScreen
    },
    {
      name: Screens.LiabilityPlanConfirmDetails,
      component: ConfirmDetailsScreen
    },
    {
      name: Screens.LiabilityPlanSave,
      component: SaveScreen,
      options: {
        gestureEnabled: false
      }
    },
    {
      name: Screens.LiabilityPlanEdit,
      component: EditDetailsScreen,
      options: { title: "Edit Auto Rent Payment" }
    }
  ];

  return (
    <>
      {screens.map(({ name, component, options }) => {
        return (
          <Stack.Screen
            key={name}
            name={name}
            component={component}
            options={{ ...options, title: options?.title ?? defaultTitle }}
          />
        );
      })}
    </>
  );
};

export { getLiabilityPaymentPlanScreens };
