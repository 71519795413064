import React, { ReactNode } from "react";
import { AFC, Text, Colors, opacify } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useSafeAreaInsets } from "react-native-safe-area-context";

interface Props {
  topContainerColor?: string;
  titleText: string;
  descriptionText: string;
  additionalInfo?: ReactNode;
}

const BaseSlide: AFC<Props> = ({
  topContainerColor,
  titleText,
  descriptionText,
  children,
  additionalInfo
}) => {
  const { top } = useSafeAreaInsets();

  const colors = topContainerColor
    ? [topContainerColor, topContainerColor]
    : [opacify(Colors.AILO_RED, 0.025), opacify(Colors.AILO_RED, 0.15)];

  return (
    <Container>
      <TopContainer colors={colors} style={{ paddingTop: top }}>
        {children}
      </TopContainer>
      <BottomContainer>
        <TitleText>{titleText}</TitleText>
        <DescriptionText>{descriptionText}</DescriptionText>
        {additionalInfo ? <>{additionalInfo}</> : null}
      </BottomContainer>
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  width: 100%;
`;

const TopContainer = styled(LinearGradient)`
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

const BottomContainer = styled(View)`
  flex-direction: column;
  padding-right: 40px;
  padding-left: 40px;
  align-items: center;
  min-height: 172px;
  background-color: ${Colors.WHITE};
`;

const TitleText = styled(Text.DisplayS)`
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const DescriptionText = styled(Text.BodyM)`
  text-align: center;
  flex-direction: column;
  align-items: center;
`;

export { BaseSlide };
