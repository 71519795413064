import React, { FC } from "react";
import { View, ViewStyle } from "react-native";
import { TextInput } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { Controller, Control } from "react-hook-form";

interface Props {
  control: Control<{ accountName: string }>;
  hasError: boolean;
  style?: ViewStyle;
}

const formatAccountNameText = (text: string): string => {
  return text.replace(/[‘’]/g, "'");
};

const AccountNameInputField: FC<Props> = ({ control, hasError, style }) => {
  return (
    <View style={style}>
      <Label>{"Account name"}</Label>
      <Controller
        name={"accountName"}
        rules={{
          required: "Account name is required",
          maxLength: {
            value: 32,
            message: "Account name must contain at most 32 characters"
          }
        }}
        defaultValue={""}
        render={({ onChange, onBlur, value }): React.ReactElement => (
          <TextInput
            {...{ onBlur, value }}
            onChangeText={(text: string): void =>
              onChange(formatAccountNameText(text))
            }
            hasError={hasError}
            returnKeyType={"done"}
            keyboardType={"default"}
            autoCapitalize={"words"}
            autoCorrect={false}
            autoCompleteType={"name"}
            textContentType={"name"}
            maxLength={32}
            testID={"account-name-input"}
          />
        )}
        control={control}
      />
    </View>
  );
};

const Label = styled(Text.BodyS)`
  margin-bottom: 8px;
`;

export { AccountNameInputField };
