import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Money } from "@ailo/ui";
import { Colors } from "@ailo/primitives";
import { RentBalance } from "@ailo/domains";

interface Props {
  label?: string;
  color?: string;
  amount?: Money;
}

export function RentBalanceHeader({
  label,
  color,
  amount
}: Props): React.ReactElement {
  return (
    <>
      {label && color && amount && (
        <RentBalanceContainer>
          <RentBalance
            rentBalanceLabel={label}
            rentBalanceColor={color}
            rentBalance={amount}
          />
        </RentBalanceContainer>
      )}
    </>
  );
}

const RentBalanceContainer = styled(View)`
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: ${Colors.WHITE};
  padding: 24px 16px 16px 16px;
`;
