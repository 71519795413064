import { ListItem } from "@ailo/ui";
import React, { useState } from "react";
import { Colors, ErrorSolidIcon, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { staticEnvironment } from "@ailo/services";

const AILO_DEV_TOOLS_ENABLED =
  __DEV__ || ["local", "dev"].includes(staticEnvironment.releaseChannel);

export function DeveloperToolsLinksDev(): React.ReactElement | null {
  const [triggerError, setTriggerError] = useState(false);

  if (triggerError) {
    throw new Error("Test error (triggered by DeveloperToolsLinks component)");
  }

  return (
    <>
      <ListHeader>{"Developer tools (dev only)"}</ListHeader>
      <ListItem
        leftComponent={<ErrorSolidIcon style={{ marginRight: 18 }} />}
        header={"Trigger error"}
        onPress={(): void => {
          setTriggerError(true);
        }}
      />
    </>
  );
}

const ListHeader = styled(Text.BodyM)`
  padding-left: 16px;
  padding-top: 24px;
  padding-bottom: 8px;
  color: ${Colors.TEXT.DARK.SECONDARY};
`;

function DeveloperToolsLinksProd(): React.ReactElement | null {
  return null;
}

export const DeveloperToolsLinks = AILO_DEV_TOOLS_ENABLED
  ? DeveloperToolsLinksDev
  : DeveloperToolsLinksProd;
