import { FeeInfo, FeeUIHelpers, useHasFeature } from "@ailo/domains";
import { useEnvironment } from "@ailo/services";
import { Button } from "@ailo/ui";
import { toCents } from "local/domain/ledger";
import { Money, PlatformFeatureId } from "local/graphql";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { View } from "react-native";
import { FeesDisclaimerDisplay } from "../FeesDisclaimerDisplay";
import { PaymentAmountInputField } from "./form";

interface PayLiabilityFormData {
  paymentAmount: string;
}

interface Props {
  payLiability: (amountWithoutFees: Money, amountWithFees: Money) => void;
  topUpFee?: FeeInfo;
  loading: boolean;
  maxPaymentAmountInCents?: number;
}

interface Statics {
  Loading: React.FC;
}

const EditablePaymentDisplay: React.FC<Props> & Statics = ({
  payLiability,
  topUpFee,
  loading,
  maxPaymentAmountInCents
}) => {
  const formMethods = useForm<PayLiabilityFormData>({
    mode: "onChange",
    shouldFocusError: true
  });

  const { handleSubmit, watch } = formMethods;
  const paymentAmount = watch("paymentAmount");
  const { MAX_RENT_PAYMENT_IN_CENTS, BYPASSED_MAX_RENT_PAYMENT_IN_CENTS } =
    useEnvironment();
  const bypassRentLimit = useHasFeature(PlatformFeatureId.BypassRentLimit);

  const maxRentAmountInCents = bypassRentLimit
    ? BYPASSED_MAX_RENT_PAYMENT_IN_CENTS
    : MAX_RENT_PAYMENT_IN_CENTS;
  const rentPaymentLimit = maxPaymentAmountInCents
    ? Math.min(maxRentAmountInCents, maxPaymentAmountInCents)
    : maxRentAmountInCents;

  const enableTransactionFees =
    useHasFeature(PlatformFeatureId.TransactionFees) && topUpFee;

  const feesAppliedCentsAmount = FeeUIHelpers.getFeeAppliedAmount(
    enableTransactionFees ? topUpFee : undefined,
    paymentAmount
  );

  const feesAppliedDollarAmount = FeeUIHelpers.format(feesAppliedCentsAmount);

  const onSubmit = handleSubmit(
    ({ paymentAmount }: { paymentAmount: string }) => {
      const amountWithoutFees = { cents: toCents(paymentAmount) };
      const amountWithFees = { cents: feesAppliedCentsAmount };
      payLiability(amountWithoutFees, amountWithFees);
    }
  );

  return (
    <View style={{ marginTop: 16 }}>
      <FormProvider {...formMethods}>
        <PaymentAmountInputField
          maxPaymentAmountInCents={rentPaymentLimit}
          accessibilityLabel={"rent-payment-amount-input"}
        />
        {enableTransactionFees && (
          <FeesDisclaimerDisplay
            dollarAmount={feesAppliedDollarAmount}
            style={{ marginTop: 16 }}
          />
        )}
        <Button.Primary
          disabled={
            loading ||
            toCents(paymentAmount) <= 0 ||
            (!!rentPaymentLimit && toCents(paymentAmount) > rentPaymentLimit)
          }
          onPress={onSubmit}
          style={{ marginTop: 16 }}
          testID={"pay-rent-amount-button"}
        >
          {`Pay ${feesAppliedDollarAmount}`}
        </Button.Primary>
      </FormProvider>
    </View>
  );
};

const Loading: React.FC = () => {
  const enableTransactionFees = useHasFeature(
    PlatformFeatureId.TransactionFees
  );

  return (
    <>
      <PaymentAmountInputField.Loading />
      {enableTransactionFees && (
        <FeesDisclaimerDisplay.Loading style={{ marginTop: 16 }} />
      )}
      <Button.Loading style={{ marginTop: 16 }} />
    </>
  );
};

EditablePaymentDisplay.Loading = Loading;

export { EditablePaymentDisplay, PayLiabilityFormData };
