import React from "react";
import { TypedStackNavigator } from "@react-navigation/stack";
import { AppNavigatorParamList, Screens } from "local/common";
import { GetToKnowAiloScreen } from "./GetToKnowAiloScreen";
import { InvestorRentHistoryScreen } from "./InvestorRentHistoryScreen";
import { InvestorRentHistoryDetailScreen } from "./InvestorRentHistoryDetailScreen";

export const getInvestorRentTabModalScreens = (
  Stack: TypedStackNavigator<AppNavigatorParamList>
): React.ReactElement => {
  return (
    <>
      <Stack.Screen
        name={Screens.GetToKnowAilo}
        component={GetToKnowAiloScreen}
        options={{
          title: "Get To Know Ailo",
          headerShown: false
        }}
      />
      <Stack.Screen
        name={Screens.InvestorRentHistory}
        component={InvestorRentHistoryScreen}
        options={{
          title: "Rent History"
        }}
      />
      <Stack.Screen
        name={Screens.InvestorRentHistoryDetail}
        component={InvestorRentHistoryDetailScreen}
        options={{
          title: ""
        }}
      />
    </>
  );
};
