import { RadioIcon, SFC } from "@ailo/primitives";
import React from "react";
import { View } from "react-native";

interface RadioProps {
  checked?: boolean;
}

export const SelectableRadioIcon: SFC<RadioProps> = ({
  checked = false,
  style
}) => {
  return (
    <View style={{ ...style, justifyContent: "center" }}>
      <RadioIcon checked={checked} />
    </View>
  );
};
