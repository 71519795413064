import { useFormContext, FieldErrors } from "react-hook-form";
import { FormData } from "./formData";
import {
  DEFAULT_ERROR_MESSAGE,
  getErrorMessage
} from "local/domain/onboarding/phoneVerification";
import { useSendPhoneVerificationCodeMutation } from "local/graphql";
import { useState } from "react";
import { useIsMountedRef } from "@ailo/primitives";

interface Result {
  onSubmit: (onSuccess: (phoneNumber: string) => void) => () => Promise<void>;
  sending: boolean;
  formError?: string;
  validationErrors: FieldErrors<FormData>;
}

const usePhoneNumberForm = (): Result => {
  const isMountedRef = useIsMountedRef();

  const [formError, setFormError] = useState<string | undefined>();
  const { handleSubmit, errors: validationErrors } = useFormContext<FormData>();
  const [sendCodeMutation, { loading, called }] =
    useSendPhoneVerificationCodeMutation();

  const onSubmit = (
    onSuccess: (phoneNumber: string) => void
  ): (() => Promise<void>) =>
    handleSubmit(({ phoneNumber }) => {
      setFormError(undefined);

      sendCodeMutation({
        variables: { phoneNumber }
      })
        .then(({ data, errors }): void => {
          const ok = data?.sendPhoneVerificationCode?.ok;
          if (ok) onSuccess(phoneNumber);
          if (errors) throw new Error(DEFAULT_ERROR_MESSAGE);

          const errorCode = data?.sendPhoneVerificationCode?.error?.code;
          if (errorCode) throw new Error(getErrorMessage(errorCode));
        })
        .catch((error: Error) => {
          if (isMountedRef.current) setFormError(error.message);
        });
    });

  return {
    onSubmit,
    sending: called && loading,
    formError,
    validationErrors
  };
};

export { usePhoneNumberForm };
