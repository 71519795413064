import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Text, Colors, SFC, ErrorSolidIcon } from "@ailo/primitives";

export const ErrorMessage: SFC = ({ style, children }) => {
  return (
    <Container style={style}>
      <ErrorSolidIcon
        color={Colors.STATUS.ERROR}
        secondaryColor={Colors.WHITE}
        height={20}
        width={20}
      />
      <Message weight={"book"}>{children}</Message>
    </Container>
  );
};

const Container = styled(View)`
  flex-direction: row;
  align-items: flex-start;
`;

const Message = styled(Text.BodyXS)`
  margin-left: 8px;
`;
