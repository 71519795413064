import { AiloRN, services } from "@ailo/ailorn";
import { AiloSentry, useApolloClient, useAuth } from "@ailo/services";
import { AnalyticsService } from "local/common";
import { flatten, uniq } from "lodash";
import { useCallback, useEffect } from "react";
import { isPresent } from "ts-is-present";
import {
  GetAnalyticsDataDocument,
  GetAnalyticsDataQuery,
  useGetAnalyticsDataQuery
} from "local/graphql";

function setAnalyticsContext(
  analytics: AnalyticsService,
  data: GetAnalyticsDataQuery | undefined
): void {
  analytics.identify({
    traits: {
      firstName: data?.effectiveUser?.person.firstName,
      lastName: data?.effectiveUser?.person.lastName,
      email: data?.effectiveUser?.person.emailAddress
    }
  });

  const personalOrganisations = data?.effectiveUser?.organisations;
  const tenancyOrganisations = data?.tenancies?.items?.map(
    (tenancy) => tenancy?.managingEntity?.organisation
  );
  const managementOrganisations = data?.managements?.items?.map(
    (management) => management?.managingEntity?.organisation
  );
  const organisationAiloRns = uniq(
    flatten([
      personalOrganisations,
      tenancyOrganisations,
      managementOrganisations
    ])
      .filter(isPresent)
      .map((organisation) =>
        AiloRN.of(services.AuthZ.organisation, organisation.id).toString()
      )
  );

  analytics.mergeContext({
    organisation_id: organisationAiloRns
  });
}

export function useAnalyticsContext(analytics: AnalyticsService): {
  reload(): Promise<void>;
} {
  const client = useApolloClient();
  const { isAuthenticated } = useAuth();
  const result = useGetAnalyticsDataQuery({
    skip: !isAuthenticated
  });

  const reload = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }

    const { data } = await client
      .query<GetAnalyticsDataQuery, {}>({
        query: GetAnalyticsDataDocument
      })
      .catch((error) => {
        if (error.networkError) {
          // eslint-disable-next-line no-console
          console.warn(
            "useAnalyticsContext.reload() failed because of a network error. Analytics events may contain incomplete information."
          );
        } else {
          AiloSentry.captureException(error);
        }
        return { data: undefined };
      });

    setAnalyticsContext(analytics, data);
  }, [analytics, client, isAuthenticated]);

  useEffect(() => {
    setAnalyticsContext(analytics, result.data);
  }, [analytics, result.data]);

  return { reload };
}
