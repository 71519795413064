import { AiloRN } from "@ailo/ailorn";
import { Screens, useNavigation } from "local/common";
import { useCallbackRef } from "@ailo/primitives";

export function useResetNavAndOpenChat(): (
  chatAilorn: AiloRN<"chat:chat">
) => void {
  const navigation = useNavigation();

  return useCallbackRef(
    (chatAilorn: AiloRN<"chat:chat">) => {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: Screens.Threads,
            params: { chatId: chatAilorn.internalId }
          }
        ]
      });
    },
    [navigation]
  );
}
