import { Screens, useNavigation } from "local/common";
import { AiloRN } from "@ailo/ailorn";
import { OpenThread } from "@ailo/domains";
import { useCallbackRef } from "@ailo/primitives";

export function useOpenThread(): OpenThread {
  const navigation = useNavigation();

  return useCallbackRef(
    (threadAilorn: AiloRN<"chat:thread">) => {
      navigation.navigate(Screens.Messages, {
        threadId: threadAilorn.internalId
      });
    },
    [navigation]
  );
}
