import {
  AiloSentry,
  ensureMutationSucceeds,
  useAnalytics,
  withScreenComponent
} from "@ailo/services";
import {
  Button,
  FormField,
  SpinnerOverlay,
  TextInput,
  useToastContext
} from "@ailo/ui";
import {
  ScreenContainer,
  ScreenHeader,
  Screens,
  useNavigation
} from "local/common";

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Keyboard } from "react-native";
import { getEmailErrorMessage } from "../common";
import { useCurrentUser } from "@ailo/domains";
import {
  useCancelEmailVerificationsMutation,
  useSendEmailVerificationCodeMutation,
  GetCurrentPersonProfileDetailsDocument
} from "local/graphql";

interface FormData {
  emailAddress: string;
}

export const EditEmailAddressScreen = withScreenComponent(
  {
    statusBarStyle: "dark-content",
    viewTitle: null
  },
  (): React.ReactElement => {
    const toasts = useToastContext();
    const navigation = useNavigation();
    const analytics = useAnalytics();
    const {
      person: { emailAddress: currentEmailAddress }
    } = useCurrentUser();

    const [sendEmailMutation, mutationResult] =
      useSendEmailVerificationCodeMutation({
        refetchQueries: [
          {
            query: GetCurrentPersonProfileDetailsDocument
          }
        ],
        awaitRefetchQueries: true
      });

    const [cancelEmailVerificationsMutation] =
      useCancelEmailVerificationsMutation({
        refetchQueries: [
          {
            query: GetCurrentPersonProfileDetailsDocument
          }
        ],
        awaitRefetchQueries: true
      });

    const { control, handleSubmit, formState, errors } = useForm<FormData>();

    async function cancelEmailVerifications(): Promise<void> {
      try {
        await ensureMutationSucceeds(cancelEmailVerificationsMutation(), {
          dataKey: "cancelEmailVerifications"
        });
      } catch (error) {
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      toasts.show({
        type: "success",
        message: "Email address updated"
      });
      navigation.navigate(Screens.EditProfile);
      analytics.track("Profile Updated", {
        fields: ["Email Address"]
      });
    }

    const submit = handleSubmit(async (data: FormData) => {
      Keyboard.dismiss();
      if (data.emailAddress === currentEmailAddress) {
        await cancelEmailVerifications();
        return;
      }

      try {
        const result = await ensureMutationSucceeds(
          sendEmailMutation({
            variables: {
              emailAddress: data.emailAddress
            }
          }),
          {
            dataKey: "sendEmailVerificationCode"
          }
        );
        if (
          result.data?.sendEmailVerificationCode?.__typename ===
          "SendEmailVerificationErrorResponse"
        ) {
          toasts.show({
            type: "error",
            message: getEmailErrorMessage(
              result.data.sendEmailVerificationCode.errorCode
            )
          });
          return;
        }
      } catch (error) {
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      navigation.replace(Screens.VerifyEmailAddress, {
        emailAddress: data.emailAddress
      });
      analytics.track("Email Change Requested");
    });

    return (
      <>
        <ScreenContainer
          stickyBottom={
            <Button.Primary
              disabled={formState.isSubmitting || !formState.isDirty}
              onPress={submit}
            >
              {"Update Email Address"}
            </Button.Primary>
          }
        >
          <ScreenHeader
            title={"Update Email Address"}
            description={
              "We will send you a verification email to your email address to confirm."
            }
          />

          <FormField
            label={"Email Address"}
            error={errors.emailAddress?.message}
            style={{ marginTop: 24 }}
          >
            <Controller
              control={control}
              name={"emailAddress"}
              defaultValue={null}
              rules={{ required: true }}
              render={({ onChange, onBlur, value }): React.ReactElement => (
                <TextInput
                  keyboardType={"email-address"}
                  textContentType={"emailAddress"}
                  autoCompleteType={"email"}
                  autoCapitalize={"none"}
                  spellCheck={false}
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FormField>
        </ScreenContainer>
        {mutationResult.loading && <SpinnerOverlay />}
      </>
    );
  }
);
