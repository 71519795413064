import { Colors, Text } from "@ailo/primitives";
import React, { ReactElement } from "react";
import { StyleProp, View, ViewStyle } from "react-native";

interface LastModifiedProps {
  modifiedAt: string;
  modifiedBy?: string;
  style?: StyleProp<ViewStyle>;
}

export function LastModified({
  modifiedAt,
  modifiedBy,
  style
}: LastModifiedProps): ReactElement {
  return (
    <View style={style}>
      <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
        {"Last updated"}
        {modifiedBy ? ` by ${modifiedBy}` : ""}
      </Text.BodyS>
      <Text.BodyS weight={"book"} color={Colors.TEXT.DARK.SECONDARY}>
        {modifiedAt}
      </Text.BodyS>
    </View>
  );
}
