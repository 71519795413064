import { Screens, useNavCloseButton, useRoute, useTitle } from "local/common";
import React, { FC } from "react";
import { SimpleScreenContainer } from "../common";
import { SelectCreditDebitCardListItem } from "./SelectCreditDebitCardListItem";
import { SelectBankAccountListItem } from "./SelectBankAccountListItem";

const AddPaymentMethodScreen: FC = () => {
  useNavCloseButton();
  useRoute<Screens.TenancyDepositAddPaymentMethod>();
  useTitle("Select a Payment Method");

  return (
    <SimpleScreenContainer style={{ paddingTop: 20 }}>
      <SelectCreditDebitCardListItem />
      <SelectBankAccountListItem />
    </SimpleScreenContainer>
  );
};

export { AddPaymentMethodScreen };
