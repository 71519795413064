import { Colors, Text } from "@ailo/primitives";
import { Button, DateTimeWithTimeZone, Money } from "@ailo/ui";
import {
  PlatformFeatureId,
  useGetPaymentMethodPaidWithQuery
} from "local/graphql";
import {
  Screens,
  useNavCloseButton,
  useRoute,
  useTitle,
  withScreenComponent
} from "local/common";
import React from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useGlobal } from "reactn";
import styled from "styled-components/native";
import { MakeThisAutomaticButton } from "./components";
import { PaymentSuccessAlert } from "@ailo/domains";

export const RentPaymentSuccessScreen = withScreenComponent(
  (): React.ReactElement => {
    const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();

    const {
      title,
      paymentAmount,
      onClose,
      paymentMethodId,
      nextDueDate,
      autoPaymentEnabled,
      outstandingAmountInCents,
      terminationDate
    } = useRoute<Screens.RentPaymentSuccess>().params;
    const [availableFeatures] = useGlobal("availableFeatures");

    const { data: paymentMethodData } = useGetPaymentMethodPaidWithQuery({
      variables: { paymentMethodId }
    });

    useNavCloseButton();
    useTitle(title);

    const paymentDollarAmount = Money.from(paymentAmount).format();
    const isOnceOff = paymentMethodData?.paymentMethodById?.isOnceOff;
    const isBankAccount =
      paymentMethodData?.paymentMethodById?.__typename === "BankAccount";

    let bodyText = `Thanks for your payment of ${paymentDollarAmount}.`;

    const finalPaymentMade = terminationDate && outstandingAmountInCents == 0;

    if (finalPaymentMade) {
      bodyText += " You have no more rent payments required.";
    } else if (nextDueDate != null) {
      const formattedNextDueDate =
        DateTimeWithTimeZone.fromDateSubtractingAMillisecond(
          nextDueDate
        ).friendlyFormat();
      bodyText += ` Your next payment is due on ${formattedNextDueDate}`;
    }

    return (
      <View
        style={{
          paddingVertical: 16,
          marginBottom: bottomSafeAreaInset,
          flex: 1
        }}
      >
        <Container>
          <Text.DisplayM testID={"rent-payment-status-text"}>
            {"Rent paid successfully"}
          </Text.DisplayM>
          <Text.BodyM
            color={Colors.TEXT.DARK.SECONDARY}
            style={{
              textAlign: "center",
              marginTop: 8,
              paddingHorizontal: 16
            }}
          >
            {bodyText}
          </Text.BodyM>
          {!finalPaymentMade &&
            !autoPaymentEnabled &&
            availableFeatures.includes(PlatformFeatureId.AutoPayRent) &&
            !isOnceOff && <MakeThisAutomaticButton style={{ marginTop: 24 }} />}
        </Container>

        {isBankAccount && (
          <PaymentSuccessAlert
            style={{ marginBottom: 40, marginHorizontal: 16 }}
          />
        )}

        <Button.Primary
          onPress={onClose}
          testID={"close-button"}
          style={{ marginHorizontal: 16 }}
        >
          {"Close"}
        </Button.Primary>
      </View>
    );
  }
);

const Container = styled(View)`
  justify-content: center;
  align-items: center;
  flex: 1;
`;
