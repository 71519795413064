import { useCurrentLegalEntity } from "local/common";
import { useGetPersonDataQuery } from "local/graphql";
import moment from "moment";
import { useEnvironment } from "@ailo/services";

export function useUserOnboardedRecently(): {
  onboardedRecently: boolean | undefined;
  loading: boolean;
} {
  const [legalEntity] = useCurrentLegalEntity();
  const { CONSUMER_ONBOARDING_CUTOFF } = useEnvironment();
  const { data, loading } = useGetPersonDataQuery({
    variables: {
      id: legalEntity.id.toString()
    }
  });

  if (loading) {
    return { onboardedRecently: undefined, loading: true };
  }

  if (!data || data.legalEntity?.__typename !== "Person") {
    return { onboardedRecently: undefined, loading: false };
  }

  const onboardedAt = moment(data.legalEntity?.user?.onboardingCompletedAt);
  const daysSinceOnboarded = moment().diff(onboardedAt, "days");

  if (daysSinceOnboarded < CONSUMER_ONBOARDING_CUTOFF) {
    return { onboardedRecently: true, loading: false };
  }
  return { onboardedRecently: false, loading: false };
}
