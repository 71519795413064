import styled from "styled-components/native";
import { View } from "react-native";
import { Colors, Text } from "@ailo/primitives";
import React from "react";

export function AddBankAccountHeader(): React.ReactElement {
  return (
    <StyledView>
      <Text.BodyM style={{ textAlign: "center" }}>
        {"No service fees apply for one-off bank account payments"}
      </Text.BodyM>
    </StyledView>
  );
}

const StyledView = styled(View)`
  text-align: center;
  padding: 16px;
  border-bottom-width: 1px;
  border-color: ${Colors.GRAYSCALE.OUTLINE};
  background-color: ${Colors.WHITE};
`;
