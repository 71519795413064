import React from "react";
import { View } from "react-native";
import { SFC } from "@ailo/primitives";
import { Controller, useFormContext } from "react-hook-form";
import { FormData, inputName } from "./formData";
import { usePhoneNumberForm } from "./usePhoneNumberForm";
import { validatePhoneFormat } from "./validation";
import { PhoneInput } from "./components";

const PhoneNumberInput: SFC = ({ style }) => {
  const { control } = useFormContext<FormData>();
  const { validationErrors } = usePhoneNumberForm();

  return (
    <View style={style}>
      <Controller
        control={control}
        render={({ onChange }): React.ReactElement => (
          <PhoneInput
            hasError={!!validationErrors[inputName]}
            onChange={onChange}
          />
        )}
        name={inputName}
        defaultValue={""}
        rules={{
          validate: (value: string): boolean | string =>
            validatePhoneFormat(value)
        }}
      />
    </View>
  );
};

export { PhoneNumberInput };
