import React, { ReactElement, useCallback } from "react";
import { AiloRN } from "@ailo/ailorn";
import { Colors, Text } from "@ailo/primitives";
import { Avatar, Contact } from "@ailo/domains";
import { CheckboxInput, ListItem } from "@ailo/ui";
import styled from "styled-components/native";

interface RenderParticipantListItemProps {
  title: string;
  participantContact: Contact;
  currentPropertyAgencyName?: string;
  isLastItem: boolean;
  isParticipantSelected: (contactAilorn: AiloRN<"contact:contact">) => boolean;
  onParticipantSelect: (participant: {
    contactAilorn: AiloRN<"contact:contact">;
    isPM: boolean;
  }) => void;
}

export const ParticipantListItem = React.memo(function ParticipantListItem({
  title,
  participantContact,
  currentPropertyAgencyName,
  isLastItem,
  onParticipantSelect,
  isParticipantSelected
}: RenderParticipantListItemProps): ReactElement {
  const personContact = participantContact.owner ?? participantContact;
  const companyContact = participantContact.owner
    ? participantContact
    : undefined;
  const contactAilorn = personContact.ailorn;

  const onListItemPressed = useCallback(() => {
    const participant = {
      contactAilorn,
      isPM: title === currentPropertyAgencyName
    };
    onParticipantSelect(participant);
  }, [contactAilorn, currentPropertyAgencyName, onParticipantSelect, title]);

  return (
    <ListItem
      style={isLastItem ? { borderType: "none" } : {}}
      leftComponent={<StyledAvatar contactAilorn={personContact.ailorn} />}
      onPress={onListItemPressed}
      header={
        <>
          <Text.BodyM weight={"medium"} numberOfLines={1}>
            {personContact.displayName}
          </Text.BodyM>
          {companyContact && (
            <Text.BodyS numberOfLines={1} color={Colors.TEXT.DARK.SECONDARY}>
              {companyContact.displayName}
            </Text.BodyS>
          )}
        </>
      }
      rightComponent={
        <StyledCheckboxInput
          onChange={onListItemPressed}
          value={isParticipantSelected(contactAilorn)}
        />
      }
    />
  );
});

const StyledAvatar = styled(Avatar).attrs({ size: 40 })`
  margin-right: 16px;
`;

const StyledCheckboxInput = styled(CheckboxInput)`
  margin-left: 12px;
`;
