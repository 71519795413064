import React, { FC, useState } from "react";
import { Frequency, FrequencyEnum } from "local/domain/ledger";
import { DisplaySRed } from "../../liabilityPaymentPlans/components";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import { DateTimeWithTimeZone, Alert, DateInputFormField } from "@ailo/ui";
import styled from "styled-components/native";
import { Colors, Text } from "@ailo/primitives";

import { View } from "react-native";
import moment from "moment";
import { capitalCase } from "capital-case";
import { ScheduledPayOffAppPaymentInfoBox } from "./ScheduledPayOffAppPaymentInfoBox";

interface Props {
  heading: string;
  confirmationPrefix: string;
  startDate: Date | undefined;
  frequency: FrequencyEnum | undefined;
  onDateSelected: (date: Date | undefined, anniversary: number) => void;
  offAppInfoEnabled?: Boolean;
}

const SelectFrequencyDateScreen: FC<Props> = ({
  heading,
  frequency,
  startDate,
  onDateSelected,
  confirmationPrefix,
  offAppInfoEnabled
}) => {
  const [selectedDate, setSelectedDate] = useState(startDate ?? undefined);

  const header = (
    <>
      {`You selected `}
      {<DisplaySRed>{capitalCase(Frequency.value(frequency))}</DisplaySRed>}
      {`,\n`}
      {heading}
    </>
  );

  const isMonthlyAndNotWithin28 = (): boolean => {
    return Frequency.value(frequency) === FrequencyEnum.monthly && selectedDate
      ? moment(selectedDate).date() > 28
      : false;
  };

  const onNextClick = (): void => {
    onDateSelected(
      selectedDate,
      Frequency.anniversary(selectedDate, frequency)
    );
  };

  const body = (
    <Body>
      <DateInputFormField
        value={
          selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : undefined
        }
        minDate={DateTimeWithTimeZone.now().add(1, "days").format("YYYY-MM-DD")}
        onChange={(date): void =>
          setSelectedDate(
            date ? moment(date, "YYYY-MM-DD").toDate() : undefined
          )
        }
        placeholder={"Select start date"}
      />

      {selectedDate && !isMonthlyAndNotWithin28() && (
        <Text.BodyS
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ marginTop: 12 }}
        >
          {`${confirmationPrefix}${Frequency.humanReadablePrefix(frequency)} `}
          {Frequency.humanReadable(selectedDate, frequency)}
          {`, beginning `}
          {formatDate(selectedDate)}
        </Text.BodyS>
      )}

      {isMonthlyAndNotWithin28() && (
        <Alert type={"error"} style={{ marginTop: 16 }}>
          {"Date should be 28 or less for monthly frequency"}
        </Alert>
      )}
      {offAppInfoEnabled && <ScheduledPayOffAppPaymentInfoBox />}
    </Body>
  );

  return (
    <ScreenWithHeaderAndAction
      header={header}
      primaryAction={{
        disabled: !selectedDate || (frequency && isMonthlyAndNotWithin28()),
        onClick: onNextClick,
        text: "Next"
      }}
    >
      {body}
    </ScreenWithHeaderAndAction>
  );
};

function formatDate(date?: Date | undefined | null, def?: string): string {
  return date ? moment(date).format("D MMM YYYY") : def || "Unknown";
}

const Body = styled(View)`
  margin-left: 16px;
  margin-right: 16px;
`;

export { SelectFrequencyDateScreen };
