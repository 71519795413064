import React from "react";
import { RecommendOtherApp } from "@ailo/ui";
import { withScreenComponent, useAuth, useEnvironment } from "@ailo/services";

export const RecommendAgencyAppScreen = withScreenComponent(
  (): React.ReactElement => {
    const { logout } = useAuth();
    const envVars = useEnvironment();

    return (
      <RecommendOtherApp
        primaryMessage={`Oops! Are you looking for Ailo for Property Managers?`}
        secondaryMessage={`You’ve logged into the Ailo app for renters and property investors.`}
        recommendingApp={{
          name: "Ailo PM",
          url: envVars.AGENCY_APP_URL
        }}
        onTryAgainButtonPress={logout}
      />
    );
  }
);
