import { useHasFeature } from "@ailo/domains";
import { Colors, LearnMoreGraphics, opacify, Text } from "@ailo/primitives";
import { Button, Card } from "@ailo/ui";
import { LinearGradient } from "expo-linear-gradient";
import {
  Screens,
  useNavigation,
  usePropertySelectorContext
} from "local/common";
import {
  useCompleteOnboardingTask,
  useOnboardingContext
} from "local/domain/onboarding";
import { OnboardingTaskId, PlatformFeatureId } from "local/graphql";
import React, { FC } from "react";
import { View, ViewStyle } from "react-native";
import { CloseButton } from "../CloseButton";
import { useInvestorOverviewAnalytics } from "../useInvestorOverviewAnalytics";

interface Props {
  style?: ViewStyle;
}

const GetToKnowAiloCard: FC<Props> = ({ style }) => {
  const navigation = useNavigation();
  const analytics = useInvestorOverviewAnalytics();
  const isInvestor = useHasFeature(PlatformFeatureId.InvestorAccess);
  const { currentProperty } = usePropertySelectorContext();

  const { completeOnboardingTaskIds } = useOnboardingContext();
  const completeOnboardingTask = useCompleteOnboardingTask();
  const isOnboardingCardDismissed = completeOnboardingTaskIds.includes(
    OnboardingTaskId.GetToKnowAilo
  );

  const onClosePress = (): void => {
    analytics.trackGetToKnowAilo("Card Close");
    completeOnboardingTask(OnboardingTaskId.GetToKnowAilo, {
      skipped: false,
      skipNavigation: true,
      forceStoreRemotely: true
    });
  };

  const colors = [Colors.WHITE, opacify(Colors.WHITE, 0)];

  return isInvestor &&
    currentProperty?.__typename === "OwnedProperty" &&
    !isOnboardingCardDismissed ? (
    <View style={{ position: "relative" }}>
      <CloseButton
        onClose={onClosePress}
        size={"small"}
        hideShadow={true}
        containerStyle={{
          position: "absolute",
          top: 18,
          right: 22
        }}
      />

      <Card style={{ ...style, flex: 1 }}>
        <View style={{ zIndex: 1, paddingTop: 20 }}>
          <Text.DisplayS
            weight={"medium"}
            style={{ marginBottom: 8, textAlign: "center" }}
          >
            {"Get to know Ailo"}
          </Text.DisplayS>

          <Text.BodyS
            color={Colors.TEXT.DARK.SECONDARY}
            style={{ textAlign: "center", marginBottom: 12 }}
          >
            {`Ailo makes working with your property\nmanager to maximise your investment simple`}
          </Text.BodyS>

          <LinearGradient
            colors={colors}
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "center",
              height: 72
            }}
          >
            <Button.Secondary
              style={{ marginBottom: 20 }}
              onPress={(): void => navigation.navigate(Screens.GetToKnowAilo)}
            >
              {"Learn More"}
            </Button.Secondary>
          </LinearGradient>
        </View>

        <View style={{ alignItems: "center", marginTop: -68 }}>
          <LearnMoreGraphics />
        </View>
      </Card>
    </View>
  ) : null;
};

export { GetToKnowAiloCard };
