import { DateTimeWithTimeZone, Card, EmptyContent, Money } from "@ailo/ui";
import React, { FC } from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { CalendarIcon, Colors, Text } from "@ailo/primitives";

interface Props {
  style?: ViewStyle;
  rent?: Money;
  frequency?: string;
  paidToDate?: DateTimeWithTimeZone;
}

interface Statics {
  Loading: FC<{ style?: ViewStyle }>;
}

const InvestorRentCard: FC<Props> & Statics = ({
  style,
  rent,
  frequency,
  paidToDate
}) => {
  return (
    <CardContainer style={style}>
      <CardHeader />
      <InnerContainer>
        {rent && (
          <Container>
            <RentText>{rent.format()}</RentText>
            {frequency && <RentFrequencyText>{frequency}</RentFrequencyText>}
            {paidToDate && <RentPaidToDateDisplay date={paidToDate} />}
          </Container>
        )}
        {!rent && (
          <EmptyContent message={"No rent information currently available"} />
        )}
      </InnerContainer>
    </CardContainer>
  );
};

const CardHeader: FC = () => {
  return (
    <Text.BodyM color={Colors.TEXT.DARK.PRIMARY} weight={"medium"}>
      {"Rent"}
    </Text.BodyM>
  );
};

const RentText: FC = ({ children }) => {
  return <Text.DisplayM weight={"medium"}>{children}</Text.DisplayM>;
};

const RentFrequencyText: FC = ({ children }) => {
  return <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>{children}</Text.BodyM>;
};

const RentPaidToDateDisplay: FC<{ date: DateTimeWithTimeZone }> = ({
  date
}) => {
  return (
    <View style={{ flexDirection: "row", alignItems: "center", marginTop: 8 }}>
      <CalendarIcon
        style={{ marginRight: 8 }}
        width={16}
        height={16}
        color={Colors.TEXT.DARK.SECONDARY}
      />
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
        {"Paid to "}
        {date.format("DD MMM YYYY")}
      </Text.BodyS>
    </View>
  );
};

const Loading: FC<{ style?: ViewStyle }> = ({ style }) => {
  return (
    <CardContainer style={style}>
      <CardHeader />
      <InnerContainer>
        <Container>
          <Text.DisplayM.Loading width={100} />
          <Text.BodyM.Loading />
          <Text.BodyS.Loading width={125} style={{ marginTop: 8 }} />
        </Container>
      </InnerContainer>
    </CardContainer>
  );
};

const CardContainer = styled(Card)`
  padding: 20px 16px 16px 16px;
`;

const Container = styled(View)`
  background: ${Colors.CLOUD};
  padding: 16px;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled(View)`
  margin-top: 16px;
  overflow: hidden;
  border-radius: 4px;
`;

InvestorRentCard.Loading = Loading;

export { InvestorRentCard };
