import {
  WalletIcon,
  RightChevronIcon,
  Text,
  ErrorSolidIcon,
  Colors
} from "@ailo/primitives";
import {
  Screens,
  useNavigation,
  useOnFocus,
  useCurrentLegalEntity
} from "local/common";
import React, { FC, useCallback } from "react";
import { useGetWalletTotalBalanceQuery } from "local/graphql";
import { formatMoney, ListItem } from "@ailo/ui";
import styled from "styled-components/native";
import { View } from "react-native";

const WalletListItem: FC = () => {
  const navigation = useNavigation();
  const [legalEntity] = useCurrentLegalEntity();

  const navigateToWalletOverviewScreen = useCallback(() => {
    navigation.navigate(Screens.WalletOverview, { showCloseButton: true });
  }, [navigation]);

  const { data, loading, refetch } = useGetWalletTotalBalanceQuery({
    variables: {
      legalEntityId: legalEntity.id.toString()
    }
  });

  useOnFocus(refetch);

  const legalEntityData = data?.legalEntity;
  const totalBalance = legalEntityData?.allWalletsTotalBalance;

  const subheaderComponent = loading ? (
    <Text.BodyS.Loading width={130} />
  ) : !totalBalance ? (
    <WalletLoadingError />
  ) : (
    `Total Balance: ${formatMoney({ cents: totalBalance.cents })}`
  );

  return (
    <ListItem
      header={"Personal Wallet"}
      subHeader={subheaderComponent}
      leftComponent={
        <WalletIcon style={{ marginRight: 18, alignSelf: "center" }} />
      }
      onPress={navigateToWalletOverviewScreen}
      rightComponent={<RightChevronIcon width={20} height={20} />}
      style={{ verticalAlignLeft: "middle" }}
    />
  );
};

const WalletLoadingError: FC = () => {
  return (
    <ErrorContainer>
      <ErrorSolidIcon
        color={Colors.TEXT.DARK.SECONDARY}
        secondaryColor={Colors.WHITE}
      />
      <ErrorText>{"Wallet balance unavailable"}</ErrorText>
    </ErrorContainer>
  );
};

const ErrorContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const ErrorText = styled(Text.BodyS)`
  color: ${Colors.TEXT.DARK.SECONDARY};
  margin-left: 5px;
`;

export { WalletListItem };
