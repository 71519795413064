import { CloseIcon, Colors, IconProps } from "@ailo/primitives";
import { Button, ButtonChildProps } from "@ailo/ui";
import { StackHeaderLeftButtonProps } from "@react-navigation/stack";
import React, { ComponentType, useLayoutEffect } from "react";
import { Screens } from "../Screens";
import { useNavigation } from "./useNavigation";
import { useRoute } from "./useRoute";

/**
 * Replaces the default "Go back" button with a "Close" button in the navigation header of the current screen.
 * Uses `useNavigation().setOptions({ headerLeft ... })` under the hood.
 *
 * It will also try to get `onClose` function from the current route params (`useRoute().params?.onClose`).
 * If not present, the button will just trigger the default "Go back" behavior instead.
 *
 * @example
 * ```tsx
 * function MyScreen() {
 *   useNavCloseButton();
 *   return <View>...</View>;
 * };
 * ```
 */
export function useNavCloseButton({
  disabled,
  color = Colors.AILO_RED,
  icon = CloseIcon
}: {
  /**
   * Set this to true/false if you want the close button to be shown/hidden once the component is mounted.
   */
  disabled?: boolean;
  color?: string;
  icon?: ComponentType<ButtonChildProps & IconProps>;
} = {}): void {
  const onClose: () => void | undefined = (useRoute<Screens>().params as any)
    ?.onClose;

  const navigation = useNavigation<Screens>();

  useLayoutEffect(() => {
    const headerLeft = !disabled
      ? function NavCloseButton(
          props: StackHeaderLeftButtonProps
        ): React.ReactElement {
          return (
            <Button.Text
              onPress={onClose ?? props.onPress}
              leftIcon={icon}
              contentColor={color}
              style={{ marginLeft: 16 }}
            />
          );
        }
      : undefined;

    navigation.setOptions({
      headerLeft
    });
  }, [disabled, navigation, onClose, color, icon]);
}
