import {
  didQueryNotLoadYet,
  didQuerySucceed,
  ensureMutationSucceeds,
  logApolloResultFailed,
  withScreenComponent,
  useAnalytics,
  AiloSentry
} from "@ailo/services";
import {
  Button,
  ErrorAlertScreen,
  FormField,
  SpinnerOverlay,
  DateInputFormField,
  useToastContext,
  DateTimeWithTimeZone
} from "@ailo/ui";
import {
  ScreenContainer,
  ScreenHeader,
  Screens,
  useNavigation,
  useRoute
} from "local/common";

import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useGetCurrentPersonProfileDetailsQuery,
  useUpdatePersonProfileDetailsMutation
} from "local/graphql";
import { Keyboard } from "react-native";

const initialDate = "1980-01-01";

interface FormData {
  birthDate: string | null;
}

export const EditBirthDateScreen = withScreenComponent(
  {
    statusBarStyle: "dark-content",
    viewTitle: null
  },
  (): React.ReactElement => {
    const toasts = useToastContext();
    const navigation = useNavigation();
    const {
      params: {
        title = "Update Date of Birth",
        submitButtonLabel = "Update Date of Birth",
        onSuccess
      } = {}
    } = useRoute<Screens.EditBirthDate>();
    const analytics = useAnalytics();
    const result = useGetCurrentPersonProfileDetailsQuery({
      notifyOnNetworkStatusChange: true
    });

    const maxDate = useMemo(() => {
      return DateTimeWithTimeZone.now()
        .subtract(13, "years")
        .toLocalDateString();
    }, []);

    const [updateMutation, mutationResult] =
      useUpdatePersonProfileDetailsMutation();

    const { control, handleSubmit, formState, errors } = useForm<FormData>();

    if (didQueryNotLoadYet(result)) {
      return <SpinnerOverlay />;
    }

    if (!didQuerySucceed(result) || !result.data.effectiveUser) {
      logApolloResultFailed(result, {
        operationId: "result"
      });
      return (
        <ErrorAlertScreen
          title={"There was a problem loading profile data"}
          onRetry={result.refetch}
        />
      );
    }

    const { person } = result.data.effectiveUser;

    const submit = handleSubmit(async (data: FormData) => {
      Keyboard.dismiss();
      try {
        await ensureMutationSucceeds(
          updateMutation({
            variables: {
              input: {
                id: person.ailoRN,
                birthDate: data.birthDate || null
              }
            }
          }),
          {
            dataKey: "updatePersonProfileDetails"
          }
        );
      } catch (error) {
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      if (onSuccess) {
        onSuccess();
      } else {
        navigation.navigate(Screens.EditProfile);
        toasts.show({
          type: "success",
          message: "Date of Birth updated"
        });
      }
      analytics.track("Profile Updated", {
        fields: ["Date of Birth"]
      });
    });

    return (
      <>
        <ScreenContainer
          stickyBottom={
            <Button.Primary
              disabled={formState.isSubmitting || !formState.isDirty}
              onPress={submit}
            >
              {submitButtonLabel}
            </Button.Primary>
          }
        >
          <ScreenHeader title={title} />

          <FormField
            label={"Date of Birth"}
            error={errors.birthDate?.message}
            style={{ marginTop: 24 }}
          >
            <Controller
              control={control}
              name={"birthDate"}
              rules={{
                required: "Date of Birth is required.",
                validate(value: string | null): string | undefined {
                  if (
                    value &&
                    DateTimeWithTimeZone.fromLocalDate(value).toDate() >
                      DateTimeWithTimeZone.fromLocalDate(maxDate).toDate()
                  ) {
                    return "You must be at least 13 years old.";
                  }
                }
              }}
              defaultValue={person.birthDate ?? null}
              render={({ onChange, onBlur, value }): React.ReactElement => (
                <DateInputFormField
                  value={value ?? undefined}
                  initialDate={initialDate}
                  maxDate={maxDate}
                  onChange={(date): void => onChange(date ?? null)}
                  onBlur={onBlur}
                />
              )}
            />
          </FormField>
        </ScreenContainer>
        {mutationResult.loading && <SpinnerOverlay />}
      </>
    );
  }
);
