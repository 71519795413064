import { Colors, DownCaretIcon, opacify, Text } from "@ailo/primitives";
import {
  useCurrentLegalEntity,
  usePropertySelectorContext
} from "local/common";
import React from "react";
import { TouchableOpacity, View } from "react-native";
import styled from "styled-components/native";

export function PropertySelect({
  onPress
}: {
  onPress?(): void;
}): React.ReactElement {
  const [legalEntity] = useCurrentLegalEntity();
  const { currentProperty, allProperties } = usePropertySelectorContext();
  const currentPropertyName = currentProperty
    ? [
        currentProperty.address.unitStreetNumber,
        currentProperty.address.streetName
      ]
        .filter(Boolean)
        .join(" ")
    : undefined;
  const legalEntityHasProperties = allProperties.find((p) =>
    p.userLegalEntity.id.equals(legalEntity.id)
  );
  const label =
    currentPropertyName ??
    (legalEntityHasProperties ? "All properties" : "No properties");
  const hasDropdown =
    allProperties.length > 1 || (!currentProperty && allProperties.length >= 1);

  if (!hasDropdown) {
    return <StyledPropertyTitle>{label}</StyledPropertyTitle>;
  }

  return (
    <TouchableOpacity
      accessible
      accessibilityRole={"button"}
      accessibilityLabel={`Current property: ${label}`}
      accessibilityHint={`Press to select different property`}
      style={{
        alignItems: "center",
        flexDirection: "row"
      }}
      onPress={onPress}
    >
      <StyledPropertyTitle
        style={{
          // Padding for the DownCaretIcon
          paddingRight: 24
        }}
      >
        {label}
      </StyledPropertyTitle>
      <View
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          bottom: 0
        }}
      >
        <DownCaretIcon color={opacify(Colors.WHITE, 0.5)} />
      </View>
    </TouchableOpacity>
  );
}

const StyledPropertyTitle = styled(Text.BodyM).attrs({
  numberOfLines: 1,
  weight: "medium",
  color: Colors.WHITE
})``;
