import React from "react";
import { Colors, opacify, Text } from "@ailo/primitives";
import { useOpenAuthorizedHelpInBrowser } from "@ailo/domains";
import { useEnvironment } from "@ailo/services";
import { Alert } from "@ailo/ui";
import styled from "styled-components/native";
import { View } from "react-native";
import { useUserOnboardedRecently } from "local/domain/onboarding";

export function OffAppRentPaymentAlert(): React.ReactElement | null {
  const { onboardedRecently, loading } = useUserOnboardedRecently();

  const { RENT_PAYMENT_HELP_URL } = useEnvironment();
  const openHelpInBrowser = useOpenAuthorizedHelpInBrowser(
    RENT_PAYMENT_HELP_URL
  );

  if (loading || !onboardedRecently) {
    return null;
  }

  return (
    <>
      <StyledDivider />
      <Container>
        <Alert type={"info"}>
          <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
            {
              "Rent payments made in the past 7 days outside of Ailo may not be visible in your app yet. "
            }
            <Text.BodyM
              color={Colors.AILO_RED}
              weight={"medium"}
              onPress={openHelpInBrowser}
            >
              {"Learn more."}
            </Text.BodyM>
          </Text.BodyM>
        </Alert>
      </Container>
    </>
  );
}

const Container = styled(View)`
  padding: 16px 16px 16px 16px;
`;

const StyledDivider = styled(View)`
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  border-bottom-width: 1px;
`;
