import { useCallback, useMemo, useState } from "react";
import { AiloRN } from "@ailo/ailorn";
import { Contact } from "@ailo/domains";
import { Property } from "local/common";
import {
  NewChatParticipantsListSection,
  useParticipantListSections
} from "./useParticipantListSections";
import { useFindContactsByProperty } from "./useFindContactsByProperty";

interface SelectedParticipant {
  contactAilorn: AiloRN<"contact:contact">;
  isPM: boolean;
}

export interface UseNewChatParticipants {
  participantListSections: NewChatParticipantsListSection[];
  selectedContacts: Contact[];
  loading: boolean;
  isAPropertyManagerSelected: boolean;
  isParticipantSelected: (contactAilorn: AiloRN<"contact:contact">) => boolean;
  onParticipantSelect: (participant: SelectedParticipant) => void;
}

export function useNewChatParticipants({
  currentProperty
}: {
  currentProperty: Property | undefined;
}): UseNewChatParticipants {
  const [selectedParticipants, setSelectedParticipants] = useState<
    SelectedParticipant[]
  >([]);

  const { loading, contactsByManagement, contactsByTenancy } =
    useFindContactsByProperty({ currentProperty });

  const participantListSections = useParticipantListSections({
    currentProperty,
    contactsByManagement,
    contactsByTenancy
  });

  const contactList = useMemo(
    () =>
      participantListSections
        .flatMap((section) => section.data)
        .map((contact) => contact.owner ?? contact),
    [participantListSections]
  );

  const selectedContacts = useMemo(
    () =>
      contactList.filter((contact) =>
        selectedParticipants.some((participant) =>
          participant.contactAilorn.equals(contact.ailorn)
        )
      ),
    [contactList, selectedParticipants]
  );

  const isAPropertyManagerSelected = useMemo(
    () => selectedParticipants.some((participant) => participant.isPM),
    [selectedParticipants]
  );

  const isParticipantSelected = useCallback(
    (contactAilorn: AiloRN<"contact:contact">): boolean => {
      return selectedParticipants.some((participant) =>
        participant.contactAilorn.equals(contactAilorn)
      );
    },
    [selectedParticipants]
  );

  const onParticipantSelect = useCallback(
    ({ contactAilorn, isPM }: SelectedParticipant): void => {
      setSelectedParticipants((prev) => {
        const isSelected = prev.some((participant) =>
          participant.contactAilorn.equals(contactAilorn)
        );

        if (!isSelected) {
          return [...prev, { contactAilorn: contactAilorn, isPM }];
        }

        return prev.filter(
          (selectedParticipant) =>
            !selectedParticipant.contactAilorn.equals(contactAilorn)
        );
      });
    },
    []
  );

  return useMemo(
    () => ({
      participantListSections,
      selectedContacts,
      loading,
      isAPropertyManagerSelected,
      isParticipantSelected,
      onParticipantSelect
    }),
    [
      isAPropertyManagerSelected,
      isParticipantSelected,
      loading,
      onParticipantSelect,
      participantListSections,
      selectedContacts
    ]
  );
}
