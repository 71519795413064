import { useMemo } from "react";
import {
  Contact,
  FindContactsByManagementResponse,
  FindContactsByTenancyResponse
} from "@ailo/domains";
import { Property } from "local/common";

export interface NewChatParticipantsListSection {
  title: string;
  data: Contact[];
}

interface Params {
  currentProperty: Property | undefined;
  contactsByManagement: FindContactsByManagementResponse | undefined;
  contactsByTenancy: FindContactsByTenancyResponse | undefined;
}

export function useParticipantListSections({
  currentProperty,
  contactsByManagement,
  contactsByTenancy
}: Params): NewChatParticipantsListSection[] {
  return useMemo(() => {
    if (!currentProperty) return [];
    if (!contactsByManagement && !contactsByTenancy) return [];

    const agencyContacts = [
      ...(contactsByManagement?.agencyContacts ?? []),
      ...(contactsByTenancy?.agencyContacts ?? [])
    ];
    const investorContacts = contactsByManagement?.investorContacts ?? [];
    const tenantContacts = contactsByTenancy?.tenantContacts ?? [];
    const consumerContacts = [...investorContacts, ...tenantContacts];

    const participantListSections = [
      {
        title: currentProperty.agency.organisationName,
        data: agencyContacts.sort(compareContactsByDisplayName)
      }
    ];

    if (consumerContacts.length > 0) {
      participantListSections.push({
        title: `${currentProperty.address.unitStreetNumber} ${currentProperty.address.streetName}`,
        data: consumerContacts.sort(compareContactsByDisplayName)
      });
    }

    return participantListSections;
  }, [contactsByManagement, contactsByTenancy, currentProperty]);
}

function compareContactsByDisplayName(a: Contact, b: Contact): number {
  const aDisplayName = a.owner?.displayName ?? a.displayName;
  const bDisplayName = b.owner?.displayName ?? b.displayName;

  return aDisplayName.localeCompare(bDisplayName);
}
