import React, { useState } from "react";
import { View, ViewStyle } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { formatMoney, MoneyTextInput, TextInput } from "@ailo/ui";
import { formatMoneyInputWithoutDollar, toCents } from "@ailo/domains";
import { useRoute, Screens } from "local/common";
import { PayLiabilityFormData } from "../EditablePaymentDisplay";

interface Props {
  maxPaymentAmountInCents: number;
  accessibilityLabel?: string;
  style?: ViewStyle;
}

export function PaymentAmountInputField({
  maxPaymentAmountInCents,
  accessibilityLabel,
  style
}: Props): React.ReactElement {
  const { control, errors } = useFormContext<PayLiabilityFormData>();
  const { defaultPaymentAmount } = useRoute<Screens.PayRentLiability>().params;

  const formattedMaxPaymentAmount = formatMoney(
    { cents: maxPaymentAmountInCents },
    { withSign: false }
  );
  const formattedDefaultPaymentAmount = formatMoney(
    { cents: defaultPaymentAmount },
    { withSign: false }
  );

  const displayPaymentAmount =
    maxPaymentAmountInCents < defaultPaymentAmount
      ? formattedMaxPaymentAmount
      : formattedDefaultPaymentAmount;

  const [maxInputLength, setMaxInputLength] = useState<number>(
    formattedMaxPaymentAmount.length
  );

  return (
    <View style={style} accessible={true}>
      <Controller
        name={"paymentAmount"}
        control={control}
        defaultValue={displayPaymentAmount}
        render={({ onChange, onBlur, value }): React.ReactElement => (
          <MoneyTextInput
            value={value}
            onChangeText={(text): void => {
              const formattedMoneyInput = formatMoneyInputWithoutDollar([text]);
              if (formattedMoneyInput.indexOf(".") !== -1) {
                setMaxInputLength(formattedMaxPaymentAmount.length);
              } else {
                setMaxInputLength(formattedMaxPaymentAmount.length - 2);
              }
              onChange(formattedMoneyInput);
            }}
            onBlur={onBlur}
            maxLength={maxInputLength}
            error={errors?.paymentAmount?.message}
            keyboardType={"decimal-pad"}
            returnKeyType={"done"}
            accessibilityLabel={accessibilityLabel}
          />
        )}
        rules={{
          validate: (paymentAmount): boolean | string =>
            (maxPaymentAmountInCents
              ? toCents(paymentAmount) <= maxPaymentAmountInCents
              : true) ||
            `Maximum payment ${formatMoney({
              cents: maxPaymentAmountInCents!
            })}`
        }}
      />
    </View>
  );
}

function Loading({ style }: { style?: ViewStyle }): React.ReactElement {
  return (
    <View style={style}>
      <TextInput editable={false} />
    </View>
  );
}

PaymentAmountInputField.Loading = Loading;
