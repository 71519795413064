import React, { FunctionComponent, useCallback } from "react";
import { Screens, useNavigation, useNavigateToThisScreen } from "local/common";
import { PaymentMethodListContainer } from "local/domain/ledger";

const PaymentMethodsScreen: FunctionComponent = () => {
  const navigation = useNavigation();
  const onPaymentMethodListItemPress = useCallback(
    (paymentMethodId: string, title: string): void => {
      navigation.navigate(Screens.PaymentMethodDetails, {
        paymentMethodId,
        title
      });
    },
    [navigation]
  );

  const returnToThisScreen = useNavigateToThisScreen();

  const openAddPaymentMethodModal = useCallback(() => {
    navigation.navigate(Screens.SetupPaymentMethod, {
      onClose: returnToThisScreen,
      onSuccess: returnToThisScreen
    });
  }, [navigation, returnToThisScreen]);

  return (
    <PaymentMethodListContainer
      onAddPaymentMethodPress={openAddPaymentMethodModal}
      onPaymentMethodSelection={onPaymentMethodListItemPress}
      showAutoBadges={true}
    />
  );
};

export { PaymentMethodsScreen };
