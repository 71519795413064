import React, { useCallback, useState, useMemo, ReactElement } from "react";
import { isPresent } from "ts-is-present";
import { AiloRN } from "@ailo/ailorn";
import { BillsList, ExpenseStatusBadge } from "@ailo/domains";
import { SFC } from "@ailo/primitives";
import {
  didQueryFail,
  didQueryNotLoadYet,
  logApolloResultFailed
} from "@ailo/services";
import { useOnFocus } from "local/common";
import { fetchMorePaidBills } from "./fetchMore";
import { BillAgencyStatus, useGetPaidBillsQuery } from "local/graphql";

interface Props {
  reference: string;
  address: string;
  /**
   * How many bills in total should we load in maximum?
   * Needs to be specified if `lazyLoad` is false.
   */
  itemsLimit?: number;
  /**
   * If set bills will be lazy loaded in bulks of `lazyLoadBulkLength` items each.
   * Bills will be loaded once the user scrolls to the bottom of the list.
   *
   * Requires `useVirtualList` to be true.
   */
  lazyLoad?: boolean;
  lazyLoadBulkLength?: number;
  /**
   * Should we use `FlatList` instead of the `View` for the list container?
   */
  useVirtualList?: boolean;
  onBillPress?: (billId: string) => void;
}

interface Statics {
  Loading: SFC<{ itemsLimit?: number }>;
  Empty: SFC<{ text: string }>;
}

const PaidBillsList: SFC<Props> & Statics = ({
  reference,
  address,
  itemsLimit,
  lazyLoad = false,
  lazyLoadBulkLength = 10,
  useVirtualList = lazyLoad,
  onBillPress,
  style
}) => {
  if (lazyLoad && !useVirtualList) {
    throw new TypeError(
      "When `lazyLoad` is true, `useVirtualList` must be true as well"
    );
  }
  if (!lazyLoad && !itemsLimit) {
    throw new TypeError(
      "When `lazyLoad` is false, `itemsLimit` must be provided"
    );
  }

  const pageCursor = useMemo(
    () => ({
      pageSize: lazyLoad ? lazyLoadBulkLength : itemsLimit!,
      paginateBackward: false
    }),
    [lazyLoad, lazyLoadBulkLength, itemsLimit]
  );
  const result = useGetPaidBillsQuery({
    variables: {
      reference,
      pageCursor
    }
  });
  const { loading, data, fetchMore, refetch } = result;
  useOnFocus(refetch);

  const hasMore = data?.paidBills?.pageInfo?.hasMore ?? false;
  const canLoadMore = lazyLoad && !loading && hasMore;
  const [loadingMore, setLoadingMore] = useState(false);
  const nextCursor = data?.paidBills?.pageInfo?.nextCursor;
  const loadMore = useCallback((): void => {
    setLoadingMore(true);
    fetchMore({
      variables: {
        pageCursor: {
          ...pageCursor,
          cursor: nextCursor
        }
      },
      updateQuery: fetchMorePaidBills
    }).finally(() => {
      setLoadingMore(false);
    });
  }, [fetchMore, nextCursor, pageCursor]);
  const onLoadMore = canLoadMore ? loadMore : undefined;

  const renderStatusBadge = useCallback(
    ({ status }: { status: BillAgencyStatus }): ReactElement => (
      <ExpenseStatusBadge status={status} />
    ),
    []
  );

  if (didQueryNotLoadYet(result)) {
    return <BillsList.Loading itemsLimit={itemsLimit} style={style} />;
  }

  if (didQueryFail(result) || !data?.paidBills?.items) {
    logApolloResultFailed(result, {
      operationId: "PaidBillsList.useGetPaidBillsQuery"
    });
    return (
      <BillsList.Empty
        text={"Error occured while loading paid bills"}
        style={style}
      />
    );
  }
  const bills = data.paidBills.items.filter(isPresent).map((bill) => ({
    id: AiloRN.fromString(bill.ailoRN).internalId,
    type: "paid" as const
  }));
  if (bills.length === 0) {
    return <BillsList.Empty text={"No bills paid yet"} style={style} />;
  }

  return (
    <BillsList
      style={style}
      bills={itemsLimit ? bills.slice(0, itemsLimit) : bills}
      subHeader={address}
      loadingMore={loadingMore}
      hasMore={hasMore}
      useVirtualList={useVirtualList}
      onBillPress={onBillPress}
      onLoadMore={onLoadMore}
      renderStatusBadge={renderStatusBadge}
    />
  );
};

PaidBillsList.Loading = BillsList.Loading;
PaidBillsList.Empty = BillsList.Empty;

export { PaidBillsList };
