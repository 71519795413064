import { AiloSentry } from "@ailo/services";
import {
  LegalEntity,
  LegalEntityContext,
  useAnalytics,
  usePropertySelectorContext
} from "local/common";
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { SetupData } from "./SetupData";

export function LegalEntityContextProvider({
  setupData: { person, companies },
  children
}: {
  setupData: SetupData;
  children?: React.ReactNode;
}): React.ReactElement {
  const analytics = useAnalytics();

  const { allProperties, currentProperty, setCurrentProperty } =
    usePropertySelectorContext();
  const [selectedLegalEntityId, setSelectedLegalEntityId] = useState(
    (currentProperty?.userLegalEntity ?? person).id
  );
  const selectedLegalEntityIdRef = useRef(selectedLegalEntityId);
  selectedLegalEntityIdRef.current = selectedLegalEntityId;

  // Whenever current property gets changed,
  // immediately set the legal entity to the one belonging to it.
  const currentPropertyIdAsString = currentProperty?.id.toString();
  useLayoutEffect(() => {
    if (
      currentProperty &&
      !selectedLegalEntityIdRef.current.equals(
        currentProperty.userLegalEntity.id
      )
    ) {
      setSelectedLegalEntityId(currentProperty?.userLegalEntity.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPropertyIdAsString]);

  const legalEntity = useMemo(() => {
    const le = [person, ...companies].find((le) =>
      le.id.equals(selectedLegalEntityId)
    );
    if (!le) {
      AiloSentry.captureException(
        new TypeError(
          `User has no access anymore to the selected legal entity? Very weird. Defaulting to user's person.`
        ),
        {
          extras: {
            selectedLegalEntityId: selectedLegalEntityId.toString(),
            userPersonId: person.id.toString(),
            userCompaniesIds: companies.map((c) => c.id.toString())
          }
        }
      );
      return person;
    }
    return le;
  }, [companies, person, selectedLegalEntityId]);

  useLayoutEffect(() => {
    analytics.mergeContext({
      legal_entity_id: legalEntity.id.toString()
    });
    return (): void => {
      analytics.mergeContext({
        legal_entity_id: undefined
      });
    };
  }, [analytics, legalEntity.id]);

  const setLegalEntity = useCallback(
    (nextLegalEntity: LegalEntity) => {
      setSelectedLegalEntityId(nextLegalEntity.id);

      const shouldUpdateCurrentProperty =
        !currentProperty ||
        !currentProperty.userLegalEntity.id.equals(nextLegalEntity.id);
      if (shouldUpdateCurrentProperty) {
        const propertyForNextLegalEntity = allProperties.find((property) =>
          property.userLegalEntity.id.equals(nextLegalEntity.id)
        );
        setCurrentProperty(propertyForNextLegalEntity);
      }
    },
    [currentProperty, allProperties, setCurrentProperty]
  );

  const contextValue: React.ContextType<typeof LegalEntityContext> = useMemo(
    () => [legalEntity, setLegalEntity],
    [legalEntity, setLegalEntity]
  );

  return (
    <LegalEntityContext.Provider value={contextValue}>
      {children}
    </LegalEntityContext.Provider>
  );
}
