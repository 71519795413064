import { formatLegalEntityName } from "@ailo/domain-helpers";
import { didQueryNotLoadYet, didQuerySucceed } from "@ailo/services";
import {
  LegacyBottomSheet,
  BottomSheetScrollViewProps,
  ErrorAlertScreen,
  ListSubheader
} from "@ailo/ui";
import { Property, usePropertySelectorContext } from "local/common";
import { useGetUserLegalEntities } from "local/domain/authz";
import React, { useCallback, useMemo } from "react";
import { FlatList } from "react-native";
import { groupPropertiesByLegalEntity } from "./groupPropertiesByLegalEntity";
import { PropertyListItem } from "./PropertyListItem";

interface Props {
  open: boolean;
  onDismiss(): void;
  onSubmit(
    nextProperty: Property | undefined,
    prevProperty: Property | undefined
  ): void;
}

export function Sheet({ open, ...props }: Props): React.ReactElement {
  if (!open) {
    return <LoadingSheet open={false} {...props} />;
  }

  return <OpenSheet {...props} />;
}

function OpenSheet({
  onDismiss,
  onSubmit
}: Omit<Props, "open">): React.ReactElement {
  const { currentProperty, allProperties } = usePropertySelectorContext();
  const entitiesResult = useGetUserLegalEntities();

  const items = useMemo(() => {
    if (!entitiesResult.data) {
      return [];
    }
    const propertiesByLegalEntity = groupPropertiesByLegalEntity({
      properties: allProperties,
      legalEntities: entitiesResult.data
    });
    const showSubheaders =
      propertiesByLegalEntity.length > 1 ||
      propertiesByLegalEntity[0].legalEntity.__typename === "Company";
    return propertiesByLegalEntity.flatMap(({ legalEntity, properties }) => [
      ...(showSubheaders
        ? [
            {
              key: legalEntity.id.toString(),
              subheader: true,
              node: (
                <ListSubheader
                  key={legalEntity.id.toString()}
                  label={formatLegalEntityName(legalEntity).toUpperCase()}
                />
              )
            }
          ]
        : []),
      ...properties.map((property, index) => ({
        key: getPropertyKey(property),
        node: (
          <PropertyListItem
            key={getPropertyKey(property)}
            property={property}
            checked={
              currentProperty &&
              currentProperty.id.toString() === property.id.toString() &&
              currentProperty.management.id.toString() ===
                property.management.id.toString() &&
              ((currentProperty.__typename === "OwnedProperty" &&
                property.__typename === "OwnedProperty") ||
                (currentProperty.__typename === "RentedProperty" &&
                  property.__typename === "RentedProperty" &&
                  currentProperty.tenancy.id.toString() ===
                    property.tenancy.id.toString()))
            }
            style={{
              borderType: index === properties.length - 1 ? "none" : "under-all"
            }}
            onPress={(): void => {
              onSubmit?.(property, currentProperty);
            }}
          />
        )
      }))
    ]);
  }, [currentProperty, allProperties, entitiesResult.data, onSubmit]);

  const renderScrollView = useCallback(
    (props: BottomSheetScrollViewProps) => (
      <FlatList
        {...props}
        ref={(list): void => {
          props.scrollViewRef.current = {
            scrollTo({ y, animated }): void {
              list?.scrollToOffset({ offset: y, animated });
            }
          };
        }}
        data={items}
        renderItem={({ item: { node } }): React.ReactElement => node}
        stickyHeaderIndices={items
          .map((item, index) => ("subheader" in item ? index : -1))
          .filter((i) => i !== -1)}
      />
    ),
    [items]
  );

  if (didQueryNotLoadYet(entitiesResult)) {
    return <LoadingSheet open onDismiss={onDismiss} />;
  }

  if (!didQuerySucceed(entitiesResult)) {
    return (
      <LegacyBottomSheet onClose={onDismiss}>
        <ErrorAlertScreen onRetry={entitiesResult.refetch} />
        {";"}
      </LegacyBottomSheet>
    );
  }

  if (items.length <= 5) {
    return (
      <LegacyBottomSheet onClose={onDismiss}>
        {items.map((item) => item.node)}
      </LegacyBottomSheet>
    );
  }

  return (
    <LegacyBottomSheet
      scroll
      renderScrollView={renderScrollView}
      onClose={onDismiss}
    />
  );
}

function LoadingSheet({
  open,
  onDismiss
}: Pick<Props, "open" | "onDismiss">): React.ReactElement {
  return (
    <LegacyBottomSheet open={open} onClose={onDismiss}>
      <ListSubheader.Loading />
      <PropertyListItem.Loading />
      <ListSubheader.Loading />
      <PropertyListItem.Loading style={{ opacity: 0.6 }} />
      <PropertyListItem.Loading style={{ opacity: 0.3 }} />
    </LegacyBottomSheet>
  );
}

function getPropertyKey(property: Property): string {
  return `${property.id.toString()}-${property.management.id.toString()}-${
    property.__typename === "OwnedProperty"
      ? property.__typename
      : property.tenancy.id.toString()
  }`;
}
