import { useHasFeature } from "@ailo/domains";
import { useDimensions } from "@react-native-community/hooks";
import {
  createMaterialTopTabNavigator,
  MaterialTopTabNavigationOptions
} from "@react-navigation/material-top-tabs";
import { Screens, usePropertySelectorContext } from "local/common";
import { PlatformFeatureId } from "local/graphql";
import React, { useCallback } from "react";
import {
  BillsTabScreen,
  CommsChatTabScreen,
  InvestorRentTabScreen,
  InvestorTransactionsTabScreen,
  TenantRentTabScreen
} from "local/tabs";
import { AiloTabBar } from "./AiloTabBar";
import { AiloTabBarItem } from "./AiloTabBarItem";
import { PropertyWalletTabScreen } from "../../../../tabs/propertyWallet/PropertyWalletTabScreen/PropertyWalletTabScreen";
import { TabBarItemProps } from "react-native-tab-view";
import { ChatUnreadIndicatorIcon } from "./ChatUnreadIndicatorIcon";

const TabNav = createMaterialTopTabNavigator();

interface TabProps {
  screenName: Screens;
  screenComponent: React.FC<any>;
  label: string;
  icon?: MaterialTopTabNavigationOptions["tabBarIcon"];
  accessibilityLabel?: string;
}

function renderTab(props: TabProps): JSX.Element {
  return (
    <TabNav.Screen
      key={props.screenName}
      name={props.screenName}
      component={props.screenComponent}
      options={{
        tabBarLabel: props.label,
        tabBarIcon: props.icon,
        tabBarAccessibilityLabel: props.accessibilityLabel
      }}
    />
  );
}

export const TabNavigator: React.FC = () => {
  const isTenant = useHasFeature(PlatformFeatureId.TenantAccess);
  const isInvestor = useHasFeature(PlatformFeatureId.InvestorAccess);
  const hasInvestorRentTab = useHasFeature(PlatformFeatureId.InvestorRentTab);

  const {
    window: { width: windowWidth }
  } = useDimensions();

  const renderTabBarItem = useCallback(
    (props: TabBarItemProps<any>): React.ReactElement => (
      <AiloTabBarItem {...props} windowWidth={windowWidth} />
    ),
    [windowWidth]
  );

  const { currentProperty } = usePropertySelectorContext();

  const showTenantTab =
    currentProperty?.__typename === "RentedProperty" && isTenant;
  const isInvestorWithManagements =
    currentProperty?.__typename === "OwnedProperty" && isInvestor;
  const showChatTab = useHasFeature(PlatformFeatureId.Chat);
  const billsTabEnabled = useHasFeature(PlatformFeatureId.Bills);
  const showBillsTab =
    (showTenantTab || isInvestorWithManagements) && billsTabEnabled;
  const enableInvestorRentTab = hasInvestorRentTab && isInvestorWithManagements;
  const propertyWalletEnabled =
    useHasFeature(PlatformFeatureId.InvestorPropertyWallet) &&
    isInvestorWithManagements;

  const tabsProps: TabProps[] = [
    ...(showChatTab
      ? [
          {
            screenName: Screens.ChatTab,
            screenComponent: CommsChatTabScreen,
            label: "Chat",
            icon: ChatUnreadIndicatorIcon
          }
        ]
      : []),
    ...(showTenantTab
      ? [
          {
            screenName: Screens.TenantRentTab,
            screenComponent: TenantRentTabScreen,
            label: "Rent"
          }
        ]
      : []),
    ...(enableInvestorRentTab
      ? [
          {
            screenName: Screens.InvestorRentTab,
            screenComponent: InvestorRentTabScreen,
            label: "Rent"
          }
        ]
      : []),
    ...(isInvestorWithManagements && !propertyWalletEnabled
      ? [
          {
            screenName: Screens.InvestorTransactionsTab,
            screenComponent: InvestorTransactionsTabScreen,
            label: "Transactions"
          }
        ]
      : []),
    ...(showBillsTab
      ? [
          {
            screenName: Screens.BillsTab,
            screenComponent: BillsTabScreen,
            label: "Bills"
          }
        ]
      : []),
    ...(propertyWalletEnabled
      ? [
          {
            screenName: Screens.InvestorPropertyWalletTab,
            screenComponent: PropertyWalletTabScreen,
            label: "Property Wallet"
          }
        ]
      : [])
  ];

  const initialScreenName = showTenantTab
    ? Screens.TenantRentTab
    : isInvestorWithManagements
    ? enableInvestorRentTab
      ? Screens.InvestorRentTab
      : Screens.InvestorTransactionsTab
    : showChatTab
    ? Screens.ChatTab
    : showBillsTab
    ? Screens.BillsTab
    : undefined;

  return (
    <TabNav.Navigator
      initialRouteName={initialScreenName}
      lazy
      lazyPreloadDistance={0}
      tabBar={AiloTabBar}
      tabBarOptions={{
        scrollEnabled: true,
        tabStyle: {
          width: tabsProps.length === 1 ? "100%" : "auto"
        },
        renderIndicator(): null {
          return null;
        },
        renderTabBarItem
      }}
    >
      {tabsProps.map(renderTab)}
    </TabNav.Navigator>
  );
};
