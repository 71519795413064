import { AiloRN } from "@ailo/ailorn";
import React from "react";

interface BaseLegalEntity {
  id: AiloRN;
}

export interface Person extends BaseLegalEntity {
  __typename: "Person";
  firstName: string;
  lastName?: string | null;
  emailAddress?: string | null;
  photo?: {
    url?: string | null;
    thumbnailUrl?: string | null;
  } | null;
}

export interface Company extends BaseLegalEntity {
  __typename: "Company";
  registeredEntityName: string;
  registeredEntityId?: string | null;
}

export type LegalEntity = Person | Company;

export const LegalEntityContext = React.createContext<
  [legalEntity: LegalEntity, setLegalEntity: (entity: LegalEntity) => void]
>([
  undefined as unknown as LegalEntity,
  (): void => {
    throw new Error("LegalEntityContext not provided");
  }
]);
