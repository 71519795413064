import React, { FC } from "react";
import { Dimensions, Image, View } from "react-native";
import { TermsOfService } from "@ailo/primitives";

const TermsOfServiceImage: FC = () => {
  const imageWidth = calculateImageWidth();
  const imageHeight = imageWidth * 1.05;
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignSelf: "center",
        marginTop: 64
      }}
    >
      <Image
        source={TermsOfService}
        resizeMode={"contain"}
        style={{ width: imageWidth, height: imageHeight }}
      />
    </View>
  );
};

const calculateImageWidth = (): number => {
  /**
   * This is a linear equation that results in an image width of
   * 190px when the phone is 320px wide, and
   * 267px when the phone is 375px wide.
   */
  return 1.38 * Dimensions.get("window").width - 251;
};

export { TermsOfServiceImage };
