import { LoadingSynchroniser } from "@ailo/primitives";
import { withScreenComponent } from "@ailo/services";
import { useLayout } from "@react-native-community/hooks";
import { Screens, useNavCloseButton, useTitle, useRoute } from "local/common";
import React from "react";
import { SafeAreaView, ScrollView } from "react-native";
import {
  BillAttachments,
  BillDetails,
  BillLineItems,
  BillPrimaryInfo,
  PayBillButton
} from "./components";
import styled from "styled-components/native";
import { useGetMainBillDetailsQuery } from "local/graphql";

export const BillScreen = withScreenComponent((): React.ReactElement => {
  const { onLayout } = useLayout();

  const { billId } = useRoute<Screens.Bill>().params;
  const billResult = useGetMainBillDetailsQuery({
    variables: {
      billId
    },
    // This query will be done also in `BillPrimaryInfo`,
    // so no need to trigger refetching it here
    fetchPolicy: "cache-first"
  });
  const bill = billResult.data?.billById;
  const payable = bill
    ? (bill.liabilityState?.paymentStatus ?? "DUE") === "DUE" &&
      bill.status === "APPROVED"
    : false;

  useNavCloseButton();
  useTitle("");

  return (
    <LoadingSynchroniser>
      <StyledSafeAreaView testID={"BillScreen"}>
        <ScrollView onLayout={onLayout} style={{ flex: 1 }}>
          <BillPrimaryInfo billId={billId} />
          <BillDetails billId={billId} />
          <BillLineItems billId={billId} style={{ marginTop: 8 }} />
          <BillAttachments billId={billId} style={{ marginTop: 24 }} />
        </ScrollView>
        {payable && <PayBillButton billId={billId} />}
      </StyledSafeAreaView>
    </LoadingSynchroniser>
  );
});

const StyledSafeAreaView = styled(SafeAreaView)`
  flex: 1;
  background: white;
`;
