import { Colors, HeartIcon, HelpIcon, InfoIcon, Text } from "@ailo/primitives";
import React, { FC, useCallback } from "react";
import styled from "styled-components/native";
import { ProfileListItem } from "./ProfileListItem";
import {
  Screens,
  useNavigation,
  usePropertySelectorContext
} from "local/common";
import { useHasFeature, useOpenAuthorizedHelpInBrowser } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";

const AiloSection: FC = () => {
  const navigation = useNavigation();

  const isInvestor = useHasFeature(PlatformFeatureId.InvestorAccess);
  const { currentProperty } = usePropertySelectorContext();
  const hasInvestorRentTab = useHasFeature(PlatformFeatureId.InvestorRentTab);

  const navigateToAboutScreen = useCallback(() => {
    navigation.navigate(Screens.About);
  }, [navigation]);

  const navigateToGetToKnowAiloScreen = useCallback(() => {
    navigation.navigate(Screens.GetToKnowAilo);
  }, [navigation]);

  const openHelpInBrowser = useOpenAuthorizedHelpInBrowser();

  return (
    <>
      <ListHeader>{"Ailo"}</ListHeader>
      {currentProperty?.__typename === "OwnedProperty" &&
        isInvestor &&
        hasInvestorRentTab && (
          <ProfileListItem
            icon={<HeartIcon />}
            header={"Get To Know Ailo"}
            onPress={navigateToGetToKnowAiloScreen}
          />
        )}
      <ProfileListItem
        icon={<HelpIcon />}
        header={"Help Centre"}
        onPress={openHelpInBrowser}
      />
      <ProfileListItem
        icon={<InfoIcon />}
        header={"About"}
        onPress={navigateToAboutScreen}
      />
    </>
  );
};

const ListHeader = styled(Text.BodyM)`
  padding-left: 16px;
  padding-top: 24px;
  padding-bottom: 8px;
  color: ${Colors.TEXT.DARK.SECONDARY};
`;

export { AiloSection };
