import { AiloRN } from "@ailo/ailorn";
import { formatAddress } from "@ailo/domain-helpers";
import { EditableListItemWithLeftHeading, ListItemValue } from "@ailo/ui";
import { usePropertySelectorContext } from "local/common";
import { findPropertyByManagementOrFolioAilorn } from "local/domain/ledger";
import React, { FC } from "react";

type Props = {
  walletOwnerRef?: AiloRN;
};

/**
 * Property address related to a wallet owner.
 *
 * TODO: Change this to show a list of properties or no properties if there are
 * zero or multiple managements under a management folio
 */
const PropertyAddressComponent: FC<Props> = (props) => {
  const { walletOwnerRef } = props;
  const { allProperties } = usePropertySelectorContext();
  const property = findPropertyByManagementOrFolioAilorn(
    allProperties,
    walletOwnerRef
  );

  if (!walletOwnerRef) {
    throw "Wallet owner is missing.";
  }

  const formattedAddress = property?.address
    ? formatAddress(property.address, { format: "street" })
    : "";

  return (
    <EditableListItemWithLeftHeading name={"Transfer from"} editable={false}>
      <ListItemValue
        value={"Property Wallet"}
        subValue={`${formattedAddress}`}
      />
    </EditableListItemWithLeftHeading>
  );
};

export { PropertyAddressComponent };
