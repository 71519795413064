import React from "react";
import {
  View,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  TouchableWithoutFeedback
} from "react-native";
import { useHeaderHeight } from "@react-navigation/stack";
import styled from "styled-components/native";
import { SFC, Colors } from "@ailo/primitives";
import { ScreenComponent } from "local/common";

interface Props {
  keyboardSpace?: number;
}

export const ScreenContainer: SFC<Props> = ({ style, children }) => {
  const headerHeight = useHeaderHeight();

  if (Platform.OS === "web") {
    return <ScreenComponent>{children}</ScreenComponent>;
  }

  return (
    <ScreenComponent>
      <StyledKeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : undefined}
        style={style}
        keyboardVerticalOffset={headerHeight}
      >
        <StyledSafeAreaView>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <InnerContainer>{children}</InnerContainer>
          </TouchableWithoutFeedback>
        </StyledSafeAreaView>
      </StyledKeyboardAvoidingView>
    </ScreenComponent>
  );
};

const StyledKeyboardAvoidingView = styled(KeyboardAvoidingView)`
  flex: 1;
  background-color: ${Colors.WHITE};
`;

const StyledSafeAreaView = styled(SafeAreaView)`
  flex: 1;
`;

const InnerContainer = styled(View)`
  flex: 1;
`;
