import { SFC } from "@ailo/primitives";
import { useEnvironment } from "@ailo/services";
import { Screens, useNavigation, useRoute } from "local/common";
import { BankAccountPaymentMethod } from "local/domain/ledger/hooks";
import React, { ReactElement, useCallback } from "react";
import { FlatList, View } from "react-native";
import { useShowTransferOrAutoTransferScreen } from "../../../../../hooks";
import { WalletListItem } from "./components";

interface Wallet {
  id: string;
  name: string;
  availableBalance: {
    cents: number;
  };
  autoWithdrawPlans?: {
    items?: Array<{
      id: string;
    }> | null;
  } | null;
}

interface Props {
  defaultBankAccountId: string | undefined;
  bankPaymentMethods: BankAccountPaymentMethod[];
  wallets: Wallet[];
}

interface Statics {
  Loading: SFC;
}

const WalletListItemWithTransferAction: SFC<{
  wallet: Wallet;
  onClose: () => void;
  onSuccess: () => void;
  defaultBankAccountId: string | undefined;
  bankPaymentMethods: BankAccountPaymentMethod[];
}> = ({
  wallet,
  onClose,
  onSuccess,
  defaultBankAccountId,
  bankPaymentMethods
}) => {
  const { ENABLE_AUTO_TRANSFER_FROM_PERSONAL_WALLET } = useEnvironment();

  const { showTransferSheet } = useShowTransferOrAutoTransferScreen({
    wallet,
    onClose,
    onSuccess,
    defaultBankAccountId,
    bankPaymentMethods,
    transferNowEnabled: true,
    autoTransferEnabled: ENABLE_AUTO_TRANSFER_FROM_PERSONAL_WALLET
  });

  return <WalletListItem wallet={wallet} onPress={showTransferSheet} />;
};

const WalletsList: SFC<Props> & Statics = ({
  style,
  defaultBankAccountId,
  bankPaymentMethods,
  wallets
}) => {
  const navigation = useNavigation();
  const { onClose } = useRoute<Screens.TransferFundsSelectWallet>().params;

  const onSuccess = useCallback((): void => {
    navigation.navigate(Screens.WalletOverview);
  }, [navigation]);

  const renderWalletListItem = useCallback(
    ({ item: wallet }: { item: Wallet }): ReactElement => {
      return (
        <WalletListItemWithTransferAction
          wallet={wallet}
          onSuccess={onSuccess}
          onClose={onClose}
          defaultBankAccountId={defaultBankAccountId}
          bankPaymentMethods={bankPaymentMethods}
        />
      );
    },
    [onSuccess, onClose, defaultBankAccountId, bankPaymentMethods]
  );

  return (
    <FlatList
      style={style}
      data={wallets}
      renderItem={renderWalletListItem}
      keyExtractor={(item): string => item.id}
    />
  );
};

const Loading: SFC = ({ style }) => (
  <View style={style}>
    <WalletListItem.Loading />
  </View>
);
WalletsList.Loading = Loading;

export { WalletsList };
