import { CommsNotificationResponse } from "@ailo/domains";
import { Screens, useNavigation } from "local/common";
import { useCallback } from "react";
import { assertNever } from "@ailo/primitives";

export function useHandleCommsNotificationResponse(): (
  commsNotificationResponse: CommsNotificationResponse
) => void {
  const navigation = useNavigation();

  return useCallback(
    (commsNotificationResponse: CommsNotificationResponse) => {
      switch (commsNotificationResponse.type) {
        case "chat":
          navigation.navigate(Screens.Threads, {
            chatId: commsNotificationResponse.chatId
          });
          break;
        case "thread":
          navigation.navigate(Screens.Messages, {
            threadId: commsNotificationResponse.threadId
          });
          break;
        default:
          assertNever(commsNotificationResponse);
      }
    },
    [navigation]
  );
}
