import { Screens, useNavigation } from "local/common";
import { CommonActions } from "@react-navigation/native";
import { useEffect } from "react";

const SCREENS_TO_REMOVE = [
  Screens.TenancyDepositKeyAction.toString(),
  Screens.TenancyDepositAddPaymentMethod.toString(),
  Screens.TenancyDepositSelectPaymentMethod.toString(),
  Screens.TenancyDepositEnterCreditDebitCard.toString(),
  Screens.TenancyDepositConfirmPayment.toString()
];
export function useRemovePreviousScreensFromNavigationStack(): void {
  const navigation = useNavigation<Screens.TenancyDepositPaymentSuccess>();
  useEffect(() => {
    navigation.dispatch((state) => {
      const routes = state.routes.filter(
        (r: any) => !SCREENS_TO_REMOVE.includes(r.name)
      );

      return CommonActions.reset({
        ...state,
        routes,
        index: routes.length - 1
      });
    });
  }, [navigation]);
}
