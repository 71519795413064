import React, { useContext } from "react";
import { LegalEntityContext } from "./LegalEntityContext";

export function useCurrentLegalEntity(): React.ContextType<
  typeof LegalEntityContext
> {
  const context = useContext(LegalEntityContext);
  if (!context[0]) {
    throw new Error("LegalEntityContext not provided");
  }
  return context;
}
