import { useAutoPayLiabilityPossible } from "local/domain/ledger";
import { PlatformFeatureId } from "local/graphql";
import { withScreenComponent } from "local/common";
import React from "react";
import { useGlobal } from "reactn";
import { BillsSection, RentSection, WalletSection } from "./components";
import { ScrollView } from "react-native";
import { useAutoPayRentPossible } from "local/domain/ledger/autoPayRent";

const AutomaticPaymentsScreen = withScreenComponent((): React.ReactElement => {
  const [availableFeatures] = useGlobal("availableFeatures");
  const rentSectionEnabled = useAutoPayRentPossible();
  const { featureEnabled } = useAutoPayLiabilityPossible();

  return (
    <ScrollView>
      {availableFeatures.includes(PlatformFeatureId.AutoWalletWithdraw) && (
        <WalletSection />
      )}
      {rentSectionEnabled && <RentSection />}
      {featureEnabled && <BillsSection />}
    </ScrollView>
  );
});

export { AutomaticPaymentsScreen };
