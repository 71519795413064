import { AiloRN } from "@ailo/ailorn";
import { didQueryNotLoadYet } from "@ailo/services";
import { Money } from "@ailo/ui";
import { QueryResult } from "@apollo/client";
import {
  GetBillsDueSoonQuery,
  GetBillsDueSoonQueryVariables,
  useGetBillsDueSoonQuery
} from "local/graphql";
import moment from "moment-timezone";

export const useGetBillAmountDue = ({
  payers,
  withinDays = 30,
  timezone = "Australia/Sydney"
}: {
  payers?: AiloRN<string>[];
  withinDays?: number;
  timezone?: string;
}): {
  skipped: boolean;
  loading: boolean;
  error: boolean;
  amountDue: Money;
  refetch: QueryResult<
    GetBillsDueSoonQuery,
    GetBillsDueSoonQueryVariables
  >["refetch"];
} => {
  const now = moment.tz(timezone);
  const dateRangeEnd = now.clone().add(withinDays, "days");

  const result = useGetBillsDueSoonQuery({
    variables: {
      payerAilorns: payers?.map((payer) => payer.toString()) ?? [],
      dueDate: {
        lt: dateRangeEnd.format("YYYY-MM-DD")
      }
    },
    skip: !payers?.length
  });

  const bills = result.data?.bills?.items ?? [];
  const amountDueCents = bills
    .map((bill) => bill.amount.cents)
    .filter((cents) => cents > 0)
    .reduce((sum, current) => sum + current, 0);
  const loading = didQueryNotLoadYet(result);

  return {
    skipped: !payers?.length,
    loading,
    error: !!result.error,
    amountDue: Money.fromCents(amountDueCents),
    refetch: result.refetch
  };
};
