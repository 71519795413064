import { Colors } from "@ailo/primitives";
import {
  ScreenComponent,
  useHasBeenFocused,
  useNavigateToThisScreen
} from "@ailo/services";
import { formatPropertyAddressName } from "@ailo/domains";
import { ExtendableHeaderList } from "@ailo/ui";
import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { PaidBillsCard, UpcomingBillsCard, MyBillsCard } from "./components";
import {
  usePropertySelectorContext,
  Screens,
  useNavigation
} from "local/common";
import { useAutoPayLiabilityPossible } from "local/domain/ledger";
import { View } from "react-native";

function BillsTabScreenContent(): React.ReactElement {
  const hasBeenFocused = useHasBeenFocused();
  const forceLoadingState = !hasBeenFocused;
  const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
  const { currentProperty } = usePropertySelectorContext();
  const { featureEnabled } = useAutoPayLiabilityPossible();

  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();
  const onBillPress = (billId: string): void => {
    navigation.navigate(Screens.Bill, {
      billId,
      onClose: returnToThisScreen
    });
  };

  if (forceLoadingState) {
    return <BillsTabScreenContent.Loading />;
  }

  const mgmtOrTenancy =
    currentProperty &&
    (currentProperty.__typename === "OwnedProperty"
      ? currentProperty.management
      : currentProperty.tenancy);
  if (!currentProperty) {
    // This should never happen, as the screen should be unmounted in such case.
    // (see `OldTabNavigator` / `NewTabNavigator`)
    throw new TypeError("Property not found in BillsOverviewScreen");
  }
  if (!mgmtOrTenancy) {
    // This should never happen, as the screen should be unmounted in such case.
    // (see `OldTabNavigator` / `NewTabNavigator`)
    throw new TypeError(
      "Management or Tenancy reference not found in BillsOverviewScreen"
    );
  }

  const address = formatPropertyAddressName(currentProperty);
  if (!address) {
    throw new TypeError("Address details not found in BillsOverviewScreen");
  }

  return (
    <ExtendableHeaderList
      headerBackgroundColor={Colors.CHARCOAL}
      headerInitialHeight={64}
    >
      <View style={{ marginTop: -56 }}>
        {featureEnabled && <MyBillsCard />}
        <UpcomingBillsCard
          reference={mgmtOrTenancy.id.toString()}
          address={address}
          onBillPress={onBillPress}
        />
        <PaidBillsCard
          reference={mgmtOrTenancy.id.toString()}
          address={address}
          style={{ marginBottom: 32 + bottomSafeAreaInset }}
          onBillPress={onBillPress}
        />
      </View>
    </ExtendableHeaderList>
  );
}

BillsTabScreenContent.Loading =
  function BillsOverviewScreenContentLoading(): React.ReactElement {
    const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();

    return (
      <ExtendableHeaderList
        headerBackgroundColor={Colors.CHARCOAL}
        headerInitialHeight={64}
      >
        <View style={{ marginTop: -56 }}>
          <MyBillsCard.Loading />
          <UpcomingBillsCard.Loading />
          <PaidBillsCard.Loading
            style={{ marginBottom: 32 + bottomSafeAreaInset }}
          />
        </View>
      </ExtendableHeaderList>
    );
  };

export const BillsTabScreen: React.FC = () => {
  return (
    <ScreenComponent statusBarStyle={"light-content"}>
      <BillsTabScreenContent />
    </ScreenComponent>
  );
};
