import { useNavigation as useReactNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppNavigatorParamList } from "../screenParams";
import { Screens } from "../Screens";

// This seems more valid but somehow if I do that,
// I get "Type 'StackNavigationState' is not generic." error in tslint,
// but only in `Ailo-Consumer-App-Test` pipeline.
// ( https://gocd.ailohq.com/go/tab/build/detail/Ailo-Consumer-App-Test/1574/Test/1/test )
// (I can't repro it locally nor on PR pipeline.)
// Probably something cause by of inconsistency of `node_modules`
// when `yarn install` is done in root repo or in a package dir separately.
// To be investigated later...
//
// export type NavigationState = StackNavigationState<AppNavigatorParamList>;
export type NavigationState = any;

export type NavigationType<TRouteName extends Screens> = Omit<
  StackNavigationProp<AppNavigatorParamList, TRouteName>,
  "dangerouslyGetState"
> & {
  /**
   * See docs of `@react-navigation` on why this method is dangerous to use.
   */
  // We replace this type definition here because without this
  // `dangerouslyGetState()` somehow exists but returns `any`.
  dangerouslyGetState(): NavigationState;
};

export type RootNavigationType = NavigationType<Screens>;

export function useNavigation<
  TRouteName extends Screens = Screens
>(): NavigationType<TRouteName> {
  return useReactNavigation();
}
