import React, { FC, useEffect } from "react";
import styled from "styled-components/native";
import { Keyboard, View } from "react-native";
import { alert } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import { Screens, useNavigation, useRoute } from "local/common";
import { handleUnsuccessfulPaymentStatus } from "./errors";
import { EditablePaymentDisplay, UneditablePaymentDisplay } from "./components";
import {
  Money,
  useGetPaymentMethodWithFeesQuery,
  usePayLiabilityMutation
} from "local/graphql";
import { extractFeeInfo, PaymentStatus } from "local/domain/ledger";

interface Props {
  paymentMethodId: string;
  idempotentKey: string;
  autoPaymentEnabled: boolean;
}

interface Statics {
  Loading: FC;
}

// TODO: Move the logic below to a hook. Something like "usePaymentDisplayData({ paymentMethodId, ... })"
const PaymentDisplay: FC<Props> & Statics = ({
  paymentMethodId,
  idempotentKey
}) => {
  const {
    title,
    liabilityId,
    description,
    amountEditable,
    SuccessScreen,
    reference,
    onClose,
    onFinalClose
  } = useRoute<Screens.PayBillLiability>().params;
  const navigation = useNavigation<Screens.PayBillLiability>();

  const [payLiabilityMutation, { loading: payMutationLoading }] =
    usePayLiabilityMutation();

  const { data: paymentMethodData, loading: paymentMethodLoading } =
    useGetPaymentMethodWithFeesQuery({ variables: { paymentMethodId } });

  const loading = payMutationLoading || paymentMethodLoading;

  useEffect(() => {
    navigation.setParams({ showLoadingOverlay: loading });
  }, [loading, navigation]);

  const payLiability = (
    amountWithoutFees: Money,
    amountWithFees: Money
  ): void => {
    const goBack = navigation.goBack;
    Keyboard.dismiss();
    payLiabilityMutation({
      variables: {
        amount: amountWithoutFees,
        idempotentKey,
        liabilityId,
        paymentMethodId
      }
    })
      .then(({ data }) => {
        const paymentStatus = data?.payLiability?.status;
        if (
          paymentStatus === PaymentStatus.Pending ||
          paymentStatus === PaymentStatus.Success
        ) {
          navigation.navigate(SuccessScreen, {
            title,
            paymentAmount: amountWithFees,
            liabilityId,
            reference,
            paymentMethodId,
            onClose: onFinalClose || onClose
          });
        } else {
          handleUnsuccessfulPaymentStatus(
            paymentMethodData?.paymentMethodById?.__typename,
            paymentStatus,
            goBack
          );
        }
      })
      .catch((): void => {
        alert(
          "Error",
          "We were unable to process your payment. Please check the details and try again or contact Ailo support",
          [
            {
              text: "Dismiss",
              onPress: (): void => {
                navigation.goBack();
              }
            }
          ]
        );
      });
  };

  const topUpFee = paymentMethodLoading
    ? undefined
    : extractFeeInfo(paymentMethodData, paymentMethodId);

  return (
    <PaymentDisplayContainer>
      {description && (
        <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
          {description}
        </Text.BodyM>
      )}

      {amountEditable ? (
        <EditablePaymentDisplay
          payLiability={payLiability}
          topUpFee={topUpFee}
          loading={loading}
        />
      ) : (
        <UneditablePaymentDisplay
          payLiability={payLiability}
          topUpFee={topUpFee}
          loading={loading}
        />
      )}
    </PaymentDisplayContainer>
  );
};

const Loading: FC = () => {
  const { description, amountEditable } =
    useRoute<Screens.PayBillLiability>().params;

  return (
    <PaymentDisplayContainer>
      {description && (
        <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
          {description}
        </Text.BodyM>
      )}

      {amountEditable ? (
        <EditablePaymentDisplay.Loading />
      ) : (
        <UneditablePaymentDisplay.Loading />
      )}
    </PaymentDisplayContainer>
  );
};

PaymentDisplay.Loading = Loading;

const PaymentDisplayContainer = styled(View)`
  padding: 20px 16px;
  background: ${Colors.WHITE};
`;

export { PaymentDisplay };
