import { useCallback } from "react";
import { Screens, useNavigation } from "local/common";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { PlatformFeatureId } from "local/graphql";
import { useHasFeature } from "@ailo/domains";
import { alert } from "@ailo/ui";
import { BankAccountPaymentMethod } from "local/domain/ledger/hooks";

interface Wallet {
  id: string;
  availableBalance: {
    cents: number;
  };
  autoWithdrawPlans?: {
    items?: Array<{
      id: string;
    }> | null;
  } | null;
}

enum ActionSheetOptions {
  SetupTransferPlan = "Setup a Transfer Schedule",
  TransferNow = "Transfer Now",
  Cancel = "Cancel"
}

type ActionSheetOptionsKeys = keyof typeof ActionSheetOptions;

type ActionSheetOptionsDetails = {
  options: ActionSheetOptions[];
  cancelButtonIndex: number;
  showSeparators: boolean;
};

const actionSheetButtonTitles = Object.keys(ActionSheetOptions).map(
  (key) => ActionSheetOptions[key as ActionSheetOptionsKeys]
);

const actionSheetOptions = (
  wallet: Wallet,
  withdrawPlanFeatureEnabled: boolean,
  transferNowEnabled: boolean,
  autoTransferEnabled: boolean
): ActionSheetOptionsDetails => {
  const walletEnabledAutoWithdrawPlan = wallet.autoWithdrawPlans?.items?.length
    ? wallet.autoWithdrawPlans?.items?.length > 0
    : false;

  const options = actionSheetButtonTitles
    .filter(
      (option: string) =>
        (wallet.availableBalance.cents > 0 && transferNowEnabled) ||
        option !== ActionSheetOptions.TransferNow
    )
    .filter((option: string) =>
      option == ActionSheetOptions.SetupTransferPlan
        ? withdrawPlanFeatureEnabled &&
          autoTransferEnabled &&
          !walletEnabledAutoWithdrawPlan
        : true
    );

  return {
    options,
    cancelButtonIndex: options.indexOf(ActionSheetOptions.Cancel),
    showSeparators: true
  };
};

type Props = {
  wallet?: Wallet | null;
  onClose: () => void;
  onSuccess: () => void;
  defaultBankAccountId: string | undefined;
  bankPaymentMethods?: BankAccountPaymentMethod[];
  transferNowEnabled: boolean;
  fromPropertyWallet?: boolean;
  autoTransferEnabled?: boolean;
};

const useShowTransferOrAutoTransferScreen = ({
  wallet,
  onClose,
  onSuccess,
  defaultBankAccountId,
  bankPaymentMethods,
  transferNowEnabled,
  autoTransferEnabled = true,
  fromPropertyWallet = false
}: Props): {
  showTransferSheet: () => void;
  startTransferNowFlow: () => void;
  startSetupTransferPlanFlow: () => void;
} => {
  const navigation = useNavigation();
  const { showActionSheetWithOptions } = useActionSheet();
  const withdrawPlanEnabled = useHasFeature(PlatformFeatureId.AutoWithdrawPlan);

  const navigateToAddBankAccountScreen = useCallback(
    (onSuccess: (paymentMethodId: string) => void) => {
      navigation.navigate(Screens.AddBankAccount, {
        onSuccess: (paymentMethod) => onSuccess(paymentMethod.id)
      });
    },
    [navigation]
  );

  const navigateToTransferFundsScreen = useCallback(
    (paymentMethodId: string): void => {
      if (!wallet?.id) {
        throw new Error("Wallet for transfer not available");
      }
      navigation.navigate(Screens.TransferFunds, {
        walletId: wallet.id,
        paymentMethodId,
        onClose,
        onSuccess,
        fromPropertyWallet
      });
    },
    [navigation, onClose, onSuccess, fromPropertyWallet, wallet?.id]
  );

  const startTransferNowFlow = useCallback((): void => {
    if (!defaultBankAccountId) {
      navigateToAddBankAccountScreen(navigateToTransferFundsScreen);
    } else {
      navigateToTransferFundsScreen(defaultBankAccountId);
    }
  }, [
    defaultBankAccountId,
    navigateToAddBankAccountScreen,
    navigateToTransferFundsScreen
  ]);

  const navigateToSetupTransferPlanScreen = useCallback(
    (bankPaymentMethods: BankAccountPaymentMethod[]): void => {
      if (!wallet?.id) {
        throw new Error("Wallet for transfer not available");
      }

      navigation.navigate(Screens.AutoWithdrawPlanSelectPaymentDestinations, {
        walletId: wallet.id,
        bankPaymentMethods,
        onSuccess: onSuccess
      });
    },
    [navigation, onSuccess, wallet?.id]
  );

  const startSetupTransferPlanFlow = useCallback((): void => {
    navigateToSetupTransferPlanScreen(bankPaymentMethods ?? []);
  }, [bankPaymentMethods, navigateToSetupTransferPlanScreen]);

  const navigateTo = useCallback(
    (option: string): void => {
      switch (option) {
        case ActionSheetOptions.TransferNow:
          startTransferNowFlow();
          break;
        case ActionSheetOptions.SetupTransferPlan:
          startSetupTransferPlanFlow();
          break;
        default:
          break;
      }
    },
    [startSetupTransferPlanFlow, startTransferNowFlow]
  );

  return {
    showTransferSheet: !wallet
      ? () => {}
      : (): void => {
          const optionDetails = actionSheetOptions(
            wallet,
            withdrawPlanEnabled,
            transferNowEnabled,
            autoTransferEnabled
          );
          const actions = optionDetails.options.filter(
            (option: string) => option != ActionSheetOptions.Cancel
          );
          if (actions.length == 0) {
            alert(
              "No available balance",
              `No balance to transfer. Automatic withdrawals already enabled or not available`,
              [{ text: "Close", style: "cancel" }]
            );
          } else if (
            actions.length == 1 &&
            actions[0] == ActionSheetOptions.TransferNow
          ) {
            startTransferNowFlow();
          } else {
            showActionSheetWithOptions(optionDetails, (buttonIndex) => {
              const selectedOption = optionDetails.options[buttonIndex];
              navigateTo(selectedOption);
            });
          }
        },
    startTransferNowFlow,
    startSetupTransferPlanFlow
  };
};

export { useShowTransferOrAutoTransferScreen };
