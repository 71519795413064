import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";

interface Props {
  title: string;
  lastSectionTitle: string;
}

export const ParticipantListFooter = React.memo(function ParticipantListFooter({
  title,
  lastSectionTitle
}: Props): ReactElement | null {
  return title === lastSectionTitle ? <SectionFooter /> : null;
});

const SectionFooter = styled(View)`
  margin-bottom: 32px;
`;
