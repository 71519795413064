import React, { ReactElement } from "react";
import { View, TextInputProps } from "react-native";
import styled from "styled-components/native";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell
} from "react-native-confirmation-code-field";
import { Text, Colors, opacify } from "@ailo/primitives";

const StyledDigitInput = styled(View)<{ focused: boolean }>`
  flex: 1;
  width: 52px;
  max-width: 52px;
  height: 52px;
  border: ${({ focused }): string =>
    focused ? `1px solid ${Colors.OCEAN}` : `1px solid transparent`};
  border-radius: 4px;
`;
const StyledDigitInputInner = styled(View)<{ focused: boolean }>`
  border: ${({ focused }): string =>
    focused
      ? `1px solid ${Colors.OCEAN}`
      : `1px solid ${opacify(Colors.SPACE_BLACK, 0.2)}`};
  border-radius: 4px;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export interface CodeInputProps
  extends Pick<TextInputProps, "value" | "autoFocus" | "style"> {
  codeLength?: number;
  value?: string;
  onChange?(value: string): void;
  onBlur?: () => void;
}

export function CodeInput({
  value,
  onChange,
  codeLength = 4,
  style,
  ...rest
}: CodeInputProps): React.ReactElement {
  const ref = useBlurOnFulfill({ value, cellCount: codeLength });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue: (value: string): void => {
      onChange && onChange(value);
    }
  });

  return (
    <CodeField
      ref={ref}
      {...props}
      value={value ?? ""}
      rootStyle={[style, { justifyContent: "flex-start" }]}
      onChangeText={onChange}
      cellCount={codeLength}
      keyboardType={"number-pad"}
      textContentType={"oneTimeCode"}
      contextMenuHidden={true}
      caretHidden={true}
      selectionColor={"transparent"}
      renderCell={({
        index,
        symbol,
        isFocused: focused
      }: {
        index: number;
        symbol: string;
        isFocused: boolean;
      }): ReactElement => (
        <StyledDigitInput
          key={index}
          focused={focused}
          style={{
            marginLeft: index === 0 ? 0 : 8
          }}
          onLayout={getCellOnLayoutHandler(index)}
        >
          <StyledDigitInputInner focused={focused}>
            <Text.BodyL
              style={[focused && { borderColor: Colors.OCEAN }]}
              weight={"book"}
            >
              {symbol || (focused ? <Cursor /> : null)}
            </Text.BodyL>
          </StyledDigitInputInner>
        </StyledDigitInput>
      )}
      {...rest}
    />
  );
}
