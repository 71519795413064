import React from "react";
import { AFC, Colors, opacify } from "@ailo/primitives";
import styled from "styled-components/native";
import { Button } from "@ailo/ui";
import { useAuth } from "@ailo/services";

const LogoutButton: AFC = ({ style }) => {
  const { logout } = useAuth();

  return (
    <StyledButton
      style={style}
      onPress={(): void => {
        logout();
      }}
      accessibilityLabel={"logout-button"}
      type={"medium"}
    >
      {"Logout"}
    </StyledButton>
  );
};

const StyledButton = styled(Button.Text)`
  margin-left: 16px;
  margin-right: 16px;
  border-width: 1px;
  border-radius: 20px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;

export { LogoutButton };
