export enum PaymentStatus {
  Success = "SUCCESS",
  Pending = "PENDING",
  Fail = "FAIL",
  InsufficientFunds = "INSUFFICIENT_FUNDS",
  Unauthorized = "UNAUTHORIZED",
  TransactionLimitReached = "TRANSACTION_LIMIT_REACHED",
  DuplicateFinancialTransaction = "DUPLICATE_FINANCIAL_TRANSACTION",
  PaymentAmountLimitExceeded = "PAYMENT_AMOUNT_LIMIT_EXCEEDED",
  FailedTransaction = "FAILED_TRANSACTION",
  LiabilityArchived = "LIABILITY_ARCHIVED"
}
