import React, { FC } from "react";
import { KeyboardAvoidingView, Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useHeaderHeight } from "@react-navigation/stack";

const Container: FC = ({ children }) => {
  const headerHeight = useHeaderHeight();
  const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      keyboardVerticalOffset={headerHeight}
      style={{ display: "flex", flex: 1, marginBottom: bottomSafeAreaInset }}
    >
      {children}
    </KeyboardAvoidingView>
  );
};

export { Container };
