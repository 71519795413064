import React, { ReactNode } from "react";
import { RightChevronIcon, Text } from "@ailo/primitives";
import { ListItem } from "@ailo/ui";
import { View } from "react-native";
import styled from "styled-components/native";

interface Props {
  header: ReactNode;
  icon?: ReactNode;
  testID?: string;
  onPress?: () => void;
}

const ProfileListItem: React.FC<Props> = ({
  header,
  icon,
  onPress,
  testID
}) => {
  return (
    <ListItem
      leftComponent={<View style={{ marginRight: 18 }}>{icon}</View>}
      header={
        <HeaderContainer>
          <Text.BodyM weight={"medium"} testID={testID}>
            {header}
          </Text.BodyM>
        </HeaderContainer>
      }
      onPress={onPress}
      rightComponent={<RightChevronIcon width={20} height={20} />}
      style={{ alignItems: "center" }}
    />
  );
};

const HeaderContainer = styled(View)`
  flex: 1;
  justify-content: center;
`;

export { ProfileListItem };
