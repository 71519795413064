import React, { ReactElement } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Colors, Text } from "@ailo/primitives";
import { ContactAvatar, ListItemHeader } from "@ailo/ui";
import { NewChatParticipantsFooterLoading } from "./NewChatParticipantsFooter";

export const NewChatParticipantsContentLoading = React.memo(
  function NewChatParticipantsContentLoading(): ReactElement {
    return (
      <>
        <ListItemHeaderLoading />
        <ListItemLoading />
        <ListItemLoading opacity={0.75} />
        <ListItemLoading opacity={0.5} />
        <SpaceFiller />
        <NewChatParticipantsFooterLoading />
      </>
    );
  }
);

const ListItemLoading = React.memo(function ListItemLoading({
  opacity
}: {
  opacity?: number;
}): ReactElement {
  return (
    <ListItemContainer opacity={opacity}>
      <ContactAvatarLoading size={40} />
      <Text.BodyM.Loading width={120} />
    </ListItemContainer>
  );
});

const ListItemHeaderLoading = styled(ListItemHeader.Loading)`
  background-color: ${Colors.CLOUD};
  padding-horizontal: 16px;
  padding-vertical: 4px;
`;

const ContactAvatarLoading = styled(ContactAvatar.Loading)`
  margin-right: 16px;
`;

const ListItemContainer = styled(View)<{ opacity?: number }>`
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  opacity: ${({ opacity = 1 }) => opacity};
`;

const SpaceFiller = styled(View)`
  flex: 1;
`;
