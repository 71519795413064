import { Colors } from "@ailo/primitives";
import { ScreenComponent, useHasBeenFocused, useOnFocus } from "@ailo/services";
import { ExtendableHeaderList } from "@ailo/ui";
import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { TenantRentHistoryCard } from "local/domain/ledger";
import { RentCard } from "./components";
import { View } from "react-native";
import {
  parseDepositData,
  TenancyDepositCard
} from "./components/TenancyDepositCard";
import { useGetCurrentRentedProperty } from "./hooks";
import {
  PlatformFeatureId,
  TenancyDepositStatus,
  useGetTenancyDepositDetailsQuery
} from "local/graphql";
import { useHasFeature } from "@ailo/domains";
import { useShowInAppReview } from "local/common";

export function TenantRentTabScreen(): React.ReactElement {
  return (
    <ScreenComponent statusBarStyle={"light-content"} name={"Rent Overview"}>
      <TenantRentTabScreenContent />
    </ScreenComponent>
  );
}

export function TenantRentTabScreenContent(): React.ReactElement | null {
  const depositsFeatureEnabled = useHasFeature(PlatformFeatureId.Deposits);
  const hasBeenFocused = useHasBeenFocused();
  const forceLoadingState = !hasBeenFocused;
  const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
  const currentProperty = useGetCurrentRentedProperty();

  const tenancyId = currentProperty?.tenancy.id.internalId;
  const address = currentProperty?.address;

  const {
    data: tenancyDepositData,
    loading: tenancyDepositLoading,
    refetch: refetchDeposit
  } = useGetTenancyDepositDetailsQuery({
    variables: tenancyId ? { tenancyId } : undefined,
    skip: !tenancyId || !depositsFeatureEnabled
  });

  useOnFocus(refetchDeposit);
  useShowInAppReview();

  if (forceLoadingState || tenancyDepositLoading) {
    return <TenantRentTabScreenContent.Loading />;
  }

  if (!currentProperty || !tenancyId) {
    return null;
  }

  const deposit = parseDepositData(tenancyDepositData);
  const showDepositInfo =
    deposit &&
    ![TenancyDepositStatus.Released, TenancyDepositStatus.Void].includes(
      deposit.status
    );

  return (
    <ExtendableHeaderList
      headerBackgroundColor={Colors.CHARCOAL}
      headerInitialHeight={64}
    >
      <View style={{ marginTop: -56 }}>
        {showDepositInfo && <TenancyDepositCard deposit={deposit!} />}
        <RentCard
          tenancyId={tenancyId}
          address={address}
          deposit={showDepositInfo ? deposit : undefined}
        />
        <TenantRentHistoryCard
          tenancyId={tenancyId}
          style={{ flex: 1, marginBottom: 32 + bottomSafeAreaInset }}
        />
      </View>
    </ExtendableHeaderList>
  );
}

TenantRentTabScreenContent.Loading =
  function TenantRentTabScreenContentLoading(): React.ReactElement {
    const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();

    return (
      <ExtendableHeaderList
        headerBackgroundColor={Colors.CHARCOAL}
        headerInitialHeight={64}
      >
        <RentCard.Loading style={{ marginTop: -56 }} />
        <TenantRentHistoryCard.Loading
          style={{ marginBottom: 32 + bottomSafeAreaInset }}
        />
      </ExtendableHeaderList>
    );
  };
