import React from "react";
import styled from "styled-components/native";
import { Colors } from "@ailo/primitives";
import {
  useRoute,
  Screens,
  ScreenComponent,
  useNavCloseButton
} from "local/common";
import { TenantRentHistoryList } from "local/domain/ledger";
import { RentBalanceHeader } from "./components";

export function TenantRentHistoryScreen(): React.ReactElement {
  useNavCloseButton();
  const { tenancyId } = useRoute<Screens.TenantRentHistory>().params;

  return (
    <Container>
      <TenantRentHistoryList
        tenancyId={tenancyId}
        hasFooter={true}
        lastPaymentListItemBorderType={"none"}
        listHeaderComponent={<RentBalanceHeader />}
        style={{ backgroundColor: "transparent" }}
      />
    </Container>
  );
}

const Container = styled(ScreenComponent)`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.WHITE};
`;
