import { createStackNavigator } from "@react-navigation/stack";
import { AppNavigatorParamList, Screens } from "local/common";
import { useInitialRoute, useOnboardingFlag } from "local/domain/onboarding";
import React from "react";
import {
  getAddPaymentMethodScreens,
  getAutoWithdrawPlanScreens,
  getBillScreens,
  getChangePaymentMethodScreens,
  getLiabilityPaymentPlanScreens,
  getOnboardingScreens,
  getPayLiabilityScreens,
  getProfileScreens,
  getTransferFundsScreens,
  getWalletScreens,
  useGetCommsChatScreens
} from "../../modals";
import {
  getBillsTabModalScreens,
  getInvestorTabModalScreens
} from "../../tabs";
import { TabNavigatorScreen } from "./TabNavigatorScreen";
import { getPayTenancyDepositScreens } from "../../modals/payTenancyDeposit/getPayTenancyDepositScreens";

const Stack = createStackNavigator<AppNavigatorParamList>();

export const CardStackNavigator: React.FC = () => {
  const onBoardingEnabled = useOnboardingFlag();
  const initialRoute = useInitialRoute();
  const getCommsChatScreens = useGetCommsChatScreens();

  if (!initialRoute || !getCommsChatScreens) return null;

  return (
    <Stack.Navigator initialRouteName={initialRoute} mode={"card"}>
      <Stack.Screen
        name={Screens.TabNavigator}
        component={TabNavigatorScreen}
      />
      {getAddPaymentMethodScreens(Stack)}
      {getAutoWithdrawPlanScreens(Stack)}
      {getBillScreens(Stack)}
      {getBillsTabModalScreens(Stack)}
      {getChangePaymentMethodScreens(Stack)}
      {getCommsChatScreens(Stack)}
      {getInvestorTabModalScreens(Stack)}
      {getLiabilityPaymentPlanScreens(Stack)}
      {getPayLiabilityScreens(Stack)}
      {getProfileScreens(Stack)}
      {getTransferFundsScreens(Stack)}
      {getWalletScreens(Stack)}
      {getPayTenancyDepositScreens(Stack)}
      {onBoardingEnabled && getOnboardingScreens(Stack)}
    </Stack.Navigator>
  );
};
