import { Alert, Money } from "@ailo/ui";
import React, { ReactElement } from "react";
import { StyleProp, ViewStyle } from "react-native";

interface LiabilitiesDueAlertProps {
  feeAmount: Money;
  billAmount: Money;
  maxTransferAmount: Money;
  style?: StyleProp<ViewStyle>;
}

export function LiabilitiesDueAlert({
  feeAmount,
  billAmount,
  maxTransferAmount,
  style
}: LiabilitiesDueAlertProps): ReactElement | null {
  const recommendedTransferAmount = Money.fromCents(
    Math.max(maxTransferAmount.cents - billAmount.cents, 0)
  );

  const message =
    feeAmount.cents > 0
      ? `${feeAmount.format()} in agency fees are due to be paid today. The maximum amount that can be transferred is ${maxTransferAmount.format()}.`
      : billAmount.cents > maxTransferAmount.cents
      ? `${billAmount.format()} in bills are due in the next 30 days`
      : billAmount.cents > 0
      ? `${billAmount.format()} in bills are due in the next 30 days. The maximum recommended transfer amount is ${recommendedTransferAmount.format()}.`
      : undefined;

  return message ? <Alert message={message} style={style} /> : null;
}
