import { useAnalytics } from "@ailo/services";

export function useInvestorOverviewAnalytics(): {
  trackGetToKnowAilo: (eventName: string) => void;
  trackPendingTask: (eventName: string) => void;
} {
  const analytics = useAnalytics();

  return {
    trackGetToKnowAilo: (eventName: string): void => {
      analytics.track(`Get to Know Ailo: ${eventName}`);
    },
    trackPendingTask: (eventName: string): void => {
      analytics.track(`Pending Task: ${eventName}`);
    }
  };
}
