import { useEffect, useMemo } from "react";
import {
  FindContactsByManagementResponse,
  FindContactsByTenancyResponse,
  useFindContactsByManagement,
  useFindContactsByTenancy,
  useHasFeature
} from "@ailo/domains";
import { Property } from "local/common";
import { PlatformFeatureId } from "local/graphql";

interface Params {
  currentProperty: Property | undefined;
}

interface Response {
  contactsByManagement: FindContactsByManagementResponse | undefined;
  contactsByTenancy: FindContactsByTenancyResponse | undefined;
  loading: boolean;
}

export function useFindContactsByProperty({
  currentProperty
}: Params): Response {
  const isInvestor = useHasFeature(PlatformFeatureId.InvestorAccess);
  const isTenant = useHasFeature(PlatformFeatureId.TenantAccess);

  const [getContactsByManagement, contactsByManagementResult] =
    useFindContactsByManagement();
  const [getContactsByTenancy, contactsByTenancyResult] =
    useFindContactsByTenancy();

  const loading = useMemo(
    () =>
      (contactsByManagementResult.loading &&
        !contactsByManagementResult.data) ||
      (contactsByTenancyResult.loading && !contactsByTenancyResult.data),
    [
      contactsByManagementResult.data,
      contactsByManagementResult.loading,
      contactsByTenancyResult.data,
      contactsByTenancyResult.loading
    ]
  );

  useEffect(() => {
    if (!currentProperty) return;

    if (isInvestor) {
      getContactsByManagement({
        variables: {
          managementAilorn: currentProperty.management.id.toString()
        }
      });
    }

    if (isTenant && currentProperty.__typename === "RentedProperty") {
      getContactsByTenancy({
        variables: {
          tenancyAilorn: currentProperty.tenancy.id.toString()
        }
      });
    }
  }, [
    getContactsByManagement,
    getContactsByTenancy,
    currentProperty,
    isInvestor,
    isTenant
  ]);

  return useMemo(
    () => ({
      loading,
      contactsByManagement: contactsByManagementResult.data,
      contactsByTenancy: contactsByTenancyResult.data
    }),
    [contactsByManagementResult.data, contactsByTenancyResult.data, loading]
  );
}
