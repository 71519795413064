import React, { useCallback } from "react";
import { Keyboard } from "react-native";
import styled from "styled-components/native";
import { Text, Colors, SFC } from "@ailo/primitives";
import { Button } from "@ailo/ui";

interface Props {
  onPress: () => void;
  disabled?: boolean;
}

export const SubmitButton: SFC<Props> = ({ style, onPress, disabled }) => {
  const onPressCallback = useCallback((): void => {
    Keyboard.dismiss();
    onPress();
  }, [onPress]);

  return (
    <StyledButton style={style} onPress={onPressCallback} disabled={disabled}>
      <Text.BodyL weight={"medium"} color={Colors.TEXT.LIGHT.PRIMARY}>
        {"Send Verification Code"}
      </Text.BodyL>
    </StyledButton>
  );
};

const StyledButton = styled(Button.Primary)`
  border-radius: 30px;
  padding: 16px 0;
`;
