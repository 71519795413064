import { AFC, useSynchronisedLoad } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import { Block, DescriptionList, LocalDate } from "@ailo/ui";
import React from "react";
import { View } from "react-native";
import { isPresent } from "ts-is-present";
import { useGetSecondaryBillDetailsQuery } from "local/graphql";

type Props = {
  billId: string;
};

export const BillDetails: AFC<Props> = ({ billId, style }) => {
  const { data, refetch, loading } = useGetSecondaryBillDetailsQuery({
    variables: { billId }
  });
  useOnFocus(refetch);
  const isLoading = useSynchronisedLoad(loading && !data);

  if (isLoading) return <Loading />;

  const bill = data?.billById;
  const billerCode =
    bill?.paymentReference?.supplierPaymentMethodCompanion?.paymentMethod
      ?.__typename === "BPay"
      ? bill.paymentReference?.supplierPaymentMethodCompanion?.paymentMethod
          ?.billerCode
      : undefined;

  const isBPay = !!billerCode;

  const mainItems = [
    {
      label: "Invoice Number",
      description: data?.billById.invoiceNumber
    },
    {
      label: "Biller Code",
      description: billerCode
    },
    {
      label: "Customer Reference",
      description: isBPay ? data?.billById.paymentReference?.crn : undefined
    },

    {
      label: "Category",
      description: data?.billById.taxCategory?.name
    },
    {
      label: "Description",
      description: data?.billById.description
    }
  ].filter((item) => item.description);
  if (!mainItems.length) {
    return null;
  }

  const dateItems = [
    {
      label: "Issued",
      description: data?.billById.issueDate
        ? LocalDate.from(data.billById.issueDate).format("D MMMM YYYY")
        : undefined
    },
    {
      label: "Created in Ailo",
      description: data?.billById.createdAt
        ? LocalDate.from(data.billById.createdAt, {
            keepTimeZone: false
          }).format("D MMMM YYYY")
        : undefined
    },
    {
      label: "Due on",
      description: data?.billById.dueDate
        ? LocalDate.from(data.billById.dueDate, {
            keepTimeZone: false
          }).format("D MMMM YYYY")
        : undefined
    },
    data?.billById.liabilityState?.paidAt &&
    data?.billById.agencyStatus === "Paid"
      ? {
          label: "Paid on",
          description: LocalDate.from(data.billById.liabilityState.paidAt, {
            keepTimeZone: false
          }).format("D MMMM YYYY")
        }
      : undefined,
    data?.billById?.liability?.lastPaymentDate &&
    data?.billById?.agencyStatus === "Refunded"
      ? {
          label: "Refunded on",
          description: LocalDate.from(
            data.billById?.liability?.lastPaymentDate,
            {
              keepTimeZone: false
            }
          ).format("D MMMM YYYY")
        }
      : undefined
  ]
    .filter(isPresent)
    .filter((item) => item.description);
  if (!dateItems.length) {
    return null;
  }

  return (
    <View>
      <Block style={style} separator>
        <DescriptionList style={style} items={mainItems} />
      </Block>
      <Block style={style} separator={false}>
        <DescriptionList style={style} items={dateItems} />
      </Block>
    </View>
  );
};

const Loading: AFC = ({ style }) => {
  return (
    <Block style={style}>
      <DescriptionList.Loading />
    </Block>
  );
};
