export const DEFAULT_ERROR_MESSAGE = "Whoops, something went wrong...";

export enum PhoneVerificationErrors {
  INVALID_VERIFICATION_CODE = "invalid-verification-code",
  INVALID_PHONE_NUMBER = "invalid-phone-number",
  PHONE_NUMBER_ALREADY_VERIFIED = "phone-number-already-verified",
  LANDLINE_NUMBER = "sms-is-not-supported-by-landline-phone-number",
  MAX_VERIFICATION_CHECKS = "max-verification-check-attempts-reached",
  TOO_MANY_REQUESTS = "too-many-concurrent-requests-for-phone-number",
  MAX_SEND_ATTEMPTS = "max-send-attempts-reached",
  INTERNAL_SERVER_ERROR = "internal-server-error",
  VERIFICATION_NOT_FOUND = "verification-not-found"
}

export function getErrorMessage(code: string): string {
  switch (code) {
    case PhoneVerificationErrors.INVALID_VERIFICATION_CODE:
      return "Sorry, that code is incorrect. Please try again.";
    case PhoneVerificationErrors.INVALID_PHONE_NUMBER:
      return "Invalid phone number";
    case PhoneVerificationErrors.PHONE_NUMBER_ALREADY_VERIFIED:
      return "Phone number already verified.";
    case PhoneVerificationErrors.LANDLINE_NUMBER:
      return "Please enter a mobile phone number.";
    case PhoneVerificationErrors.MAX_VERIFICATION_CHECKS:
      return "Too many failed attempts. Please request a new code.";
    default:
      return DEFAULT_ERROR_MESSAGE;
  }
}
