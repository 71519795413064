import { Money } from "@ailo/ui";
import {
  GetTenancyDepositDetailsQuery,
  TenancyDepositStatus
} from "local/graphql";

type DepositData = {
  status: TenancyDepositStatus;
  isDue: boolean;
  failed: boolean;
  isPending: boolean;
  isPaid: boolean;
  isPaidOrPending: boolean;
  liabilityId: string;
  amount: Money;
  expectedClearedAt?: string;
  paidAt?: string;
};

function parseDepositData(
  depositData?: GetTenancyDepositDetailsQuery
): DepositData | undefined {
  if (
    !depositData ||
    !depositData.tenancy?.deposit?.id ||
    !depositData.tenancy.deposit.liability?.id
  ) {
    return undefined;
  }

  const deposit = depositData.tenancy.deposit;

  const paymentEntry = getFirstPaymentEntry(deposit.liability?.entries?.items);
  const isPending = deposit.status === TenancyDepositStatus.Pending;
  const isPaid = deposit.status === TenancyDepositStatus.Paid;
  return {
    status: deposit.status,
    isDue: [TenancyDepositStatus.New, TenancyDepositStatus.Due].includes(
      deposit.status
    ),
    failed: deposit.status === TenancyDepositStatus.Failed,
    isPending,
    isPaid,
    isPaidOrPending: isPaid || isPending,
    liabilityId: deposit.liability?.id!,
    amount: Money.from(deposit.amount),
    paidAt: deposit.paidAt || undefined,
    expectedClearedAt:
      paymentEntry?.businessTransaction?.expectedClearedAt || undefined
  };
}

function getFirstPaymentEntry(
  paymentEntryItems?: any
): PaymentLiabilityEntryItem | undefined {
  return ((paymentEntryItems || []) as PaymentLiabilityEntryItem[]).find(
    () => true
  );
}

type PaymentLiabilityEntryItem = {
  businessTransaction: {
    expectedClearedAt: string;
  };
};

export { parseDepositData, DepositData };
