import { useHasFeature } from "@ailo/domains";
import {
  AiloSentry,
  AnalyticsService,
  useAnalytics,
  UserStorage,
  useUserStorage
} from "@ailo/services";
import * as StoreReview from "expo-store-review";
import { useEffect } from "react";
import { PlatformFeatureId } from "local/graphql";

const MAIN_SCREEN_LOADED_COUNT = "MAIN_SCREEN_LOADED_TIMES_COUNT";
const DISPLAY_AT_LOADED_COUNT = 2;
const DISPLAY_AFTER_MS = 3000;

export const useShowInAppReview = (): void => {
  const shouldShowInAppReview = useHasFeature(PlatformFeatureId.InAppReview);
  const storage = useUserStorage();
  const analytics = useAnalytics();

  useEffect(() => {
    if (!shouldShowInAppReview) {
      return;
    }

    shouldDisplayReview(storage)
      .then((shouldDisplay) => shouldDisplay && displayReview(analytics))
      .catch((error) => AiloSentry.captureException(error));
  }, [analytics, shouldShowInAppReview, storage]);
};

async function shouldDisplayReview(storage: UserStorage): Promise<boolean> {
  const canReview = await StoreReview.isAvailableAsync();
  if (!canReview) {
    throw new Error("Store review not available");
  }

  const screenLoadedTimes = await storage
    .getItem<number>(MAIN_SCREEN_LOADED_COUNT)
    .then((loadedTimes = 0) => loadedTimes + 1);

  await storage.setItem(MAIN_SCREEN_LOADED_COUNT, screenLoadedTimes);

  return screenLoadedTimes === DISPLAY_AT_LOADED_COUNT;
}

function displayReview(analytics: AnalyticsService): void {
  setTimeout(() => {
    StoreReview.requestReview();
    analytics.track("In-app Review Requested");
  }, DISPLAY_AFTER_MS);
}
