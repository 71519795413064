import { useLayoutEffect } from "react";
import { Screens } from "../Screens";
import { useNavigation } from "./useNavigation";

export function useNavNoButton(): void {
  const navigation = useNavigation<Screens>();

  useLayoutEffect(() => {
    navigation.setOptions({ header: () => null });
  }, [navigation]);
}
