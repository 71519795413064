import { Colors, SFC, Text } from "@ailo/primitives";
import { TextInput } from "@ailo/ui";
import { formatMoneyInput } from "local/domain/ledger";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StyleProp, View, ViewStyle } from "react-native";
import { FormData } from "../types";

interface Props {
  inputName: keyof FormData;
  style?: StyleProp<ViewStyle>;
}

interface Statics {
  Loading: FC;
}

const FundsInput: FC<Props> & Statics = ({ inputName, style }) => {
  const { control, errors } = useFormContext<FormData>();
  const hasError = !!errors?.[inputName];

  return (
    <View style={style}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>{"How much?"}</Text.BodyM>
      <Controller
        style={{ marginTop: 16 }}
        name={inputName}
        control={control}
        defaultValue={""}
        render={({ onChange, onBlur, value }): React.ReactElement => (
          <TextInput
            value={value}
            onChangeText={(text: string): void =>
              onChange(formatMoneyInput([text]))
            }
            onBlur={onBlur}
            hasError={hasError}
            returnKeyType={"done"}
            keyboardType={"decimal-pad"}
            placeholder={"$0"}
            accessibilityLabel={"transfer-amount-input"}
          />
        )}
        rules={{
          required: "Amount is required",
          pattern: {
            value: /^\$[0-9]+(\.[0-9]{2})?$/,
            message: "Amount must be valid"
          }
        }}
      />
    </View>
  );
};

const Loading: SFC = ({ style }) => (
  <View style={style}>
    <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>{"How much?"}</Text.BodyM>
    <TextInput editable={false} style={{ marginTop: 16 }} placeholder={"$0"} />
  </View>
);

FundsInput.Loading = Loading;

export { FundsInput };
