import { FetchMoreOptions } from "@apollo/client";
import {
  GetUpcomingBillsQuery,
  GetUpcomingBillsQueryVariables
} from "local/graphql";
import { unionBy } from "lodash";

const fetchMoreUpcomingBills: FetchMoreOptions<
  GetUpcomingBillsQuery,
  GetUpcomingBillsQueryVariables
>["updateQuery"] = (previousResult, { fetchMoreResult }) => {
  const previousBills = previousResult.upcomingBills;
  if (!previousBills) throw new Error("Not enough data to display bills");

  const newBills = fetchMoreResult?.upcomingBills;
  if (!newBills) return previousResult;

  if (previousBills.pageInfo.nextCursor === newBills.pageInfo.nextCursor)
    return previousResult;

  const mergedBillItems = unionBy(
    previousBills.items,
    newBills.items,
    (bill) => bill?.ailoRN
  );

  return {
    ...previousResult,
    ...fetchMoreResult,
    upcomingBills: {
      ...previousBills,
      ...newBills,
      items: mergedBillItems
    }
  };
};

export { fetchMoreUpcomingBills };
