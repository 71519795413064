import Big from "big.js";
import { BankAccountPaymentMethodOption } from "../../types";

export function initialisePercentages(
  bankPaymentMethods: BankAccountPaymentMethodOption[]
): BankAccountPaymentMethodOption[] {
  const selectedPaymentMethods = bankPaymentMethods.filter(
    (method) => method.selected
  );
  if (selectedPaymentMethods.length === 0) return bankPaymentMethods;

  const lastPaymentMethodId =
    selectedPaymentMethods[selectedPaymentMethods.length - 1].id;

  const percentage = Big(1)
    .div(selectedPaymentMethods.length)
    .round(4)
    .toNumber();

  return bankPaymentMethods.map((paymentMethod) =>
    paymentMethod.selected
      ? {
          ...paymentMethod,
          percentage:
            paymentMethod.id !== lastPaymentMethodId
              ? percentage
              : Big(1)
                  .sub(Big(percentage).mul(selectedPaymentMethods.length - 1))
                  .toNumber()
        }
      : paymentMethod
  );
}
