import React, { FC } from "react";
import { OnboardingTaskId } from "local/graphql";
import { ScreenComponent } from "local/common";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { View } from "react-native";
import { SetupButton, Slide } from "./components";
import { HeaderOverlay } from "local/domain/onboarding";
import styled from "styled-components/native";
import { Colors } from "@ailo/primitives";
import { SkipButton } from "../components/SkipButton";

export const SetupAutoRentPaymentKeyActionScreen: FC = () => {
  const { bottom } = useSafeAreaInsets();

  return (
    <ScreenComponent>
      <Container style={{ paddingBottom: bottom }}>
        <View style={{ flex: 1 }}>
          <Slide />
        </View>
        <View>
          <SetupButton />
          <SkipButton
            style={{ marginTop: 16, marginBottom: 20 }}
            taskToSkip={OnboardingTaskId.SetupAutoRentPayment}
          />
        </View>
        <HeaderOverlay />
      </Container>
    </ScreenComponent>
  );
};

const Container = styled(View)`
  flex: 1;
  background-color: ${Colors.WHITE};
`;
