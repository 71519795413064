import React from "react";
import { SFC } from "@ailo/primitives";
import styled from "styled-components/native";
import { Button } from "@ailo/ui";
import { OnboardingTaskId } from "local/graphql";
import { useCompleteOnboardingTask } from "local/domain/onboarding";
import { Screens, useNavigation } from "local/common";
import { useActionSheet } from "@expo/react-native-action-sheet";

const TenantSetupButton: SFC = ({ style }) => {
  const completeOnboardingTask = useCompleteOnboardingTask();
  const navigation = useNavigation();
  const { showActionSheetWithOptions } = useActionSheet();

  const buttonText = "Setup my rent payments";

  const navigateToAddBankAccount = (): void => {
    navigation.navigate(Screens.AddBankAccount, {
      onSuccess: () => completeOnboardingTask(OnboardingTaskId.AddPaymentMethod)
    });
  };

  const navigateToAddCard = (): void => {
    navigation.navigate(Screens.AddCreditDebitCard, {
      onSuccess: () => completeOnboardingTask(OnboardingTaskId.AddPaymentMethod)
    });
  };

  const handleCompleteAddPaymentMethod = (): void => {
    showActionSheetWithOptions(
      {
        options: ["Credit or Debit Card", "Bank Account", "Cancel"],
        cancelButtonIndex: 2
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          navigateToAddCard();
        } else if (buttonIndex === 1) {
          navigateToAddBankAccount();
        }
      }
    );
  };

  return (
    <StyledButton
      type={"large"}
      style={style}
      onPress={handleCompleteAddPaymentMethod}
    >
      {buttonText}
    </StyledButton>
  );
};

const StyledButton = styled(Button.Primary)`
  margin-left: 40px;
  margin-right: 40px;
`;

export { TenantSetupButton };
