import React from "react";
import { withScreenComponent } from "local/common";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { View } from "react-native";
import {
  AcceptButton,
  TermsOfServiceImage,
  HeaderText,
  DisclaimerText
} from "./components";
import { HeaderOverlay } from "local/domain/onboarding";
import styled from "styled-components/native";
import { Colors } from "@ailo/primitives";

export const TermsOfServiceAcceptScreen = withScreenComponent(
  (): React.ReactElement => {
    const { top, bottom } = useSafeAreaInsets();

    return (
      <Container style={{ paddingBottom: bottom, paddingTop: top }}>
        <TermsOfServiceImage />
        <FooterContainer style={{ marginBottom: 12 }}>
          <HeaderText style={{ marginBottom: 24, marginTop: 24 }} />
          <AcceptButton style={{ marginBottom: 24 }} />
          <DisclaimerText />
        </FooterContainer>
        <HeaderOverlay />
      </Container>
    );
  }
);

const Container = styled(View)`
  flex: 1;
  background-color: ${Colors.WHITE};
`;

const FooterContainer = styled(View)`
  min-height: 280px;
  margin-left: 40px;
  margin-right: 40px;
`;
