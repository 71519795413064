import { Colors } from "@ailo/primitives";
import { ScreenComponent, useHasBeenFocused } from "@ailo/services";
import { ExtendableHeaderList } from "@ailo/ui";
import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  useCurrentLegalEntity,
  usePropertySelectorContext
} from "local/common";
import { useGlobal } from "reactn";
import { PlatformFeatureId } from "local/graphql";
import { TransactionsCard } from "./TransactionsCard";

export function InvestorTransactionsTabScreen(): React.ReactElement {
  return (
    <ScreenComponent statusBarStyle={"light-content"}>
      <InvestorTransactionsTabScreenContent />
    </ScreenComponent>
  );
}

function InvestorTransactionsTabScreenContent(): React.ReactElement | null {
  const hasBeenFocused = useHasBeenFocused();
  const forceLoadingState = !hasBeenFocused;
  const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
  const { currentProperty } = usePropertySelectorContext();
  const [legalEntity] = useCurrentLegalEntity();
  const [availableFeatures] = useGlobal("availableFeatures");

  if (forceLoadingState) {
    return <InvestorTransactionsTabScreenContent.Loading />;
  }

  if (currentProperty?.__typename !== "OwnedProperty") {
    return null;
  }

  const transactionsOwnerId = availableFeatures.includes(
    PlatformFeatureId.TransactionFees
  )
    ? currentProperty.management.id.toString()
    : legalEntity.id.toString();

  return (
    <ExtendableHeaderList
      headerBackgroundColor={Colors.CHARCOAL}
      headerInitialHeight={64}
    >
      <TransactionsCard
        ownerRef={transactionsOwnerId}
        style={{ marginTop: -56, marginBottom: 32 + bottomSafeAreaInset }}
      />
    </ExtendableHeaderList>
  );
}

InvestorTransactionsTabScreenContent.Loading =
  function InvestorTransactionsTabScreenContentLoading(): React.ReactElement {
    const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();

    return (
      <ExtendableHeaderList
        headerBackgroundColor={Colors.CHARCOAL}
        headerInitialHeight={64}
      >
        <TransactionsCard.Loading
          style={{ marginTop: -56, marginBottom: 32 + bottomSafeAreaInset }}
        />
      </ExtendableHeaderList>
    );
  };
