import React, { useState } from "react";
import { AFC, Colors } from "@ailo/primitives";
import Swiper from "react-native-swiper/src";
import { NextButton, SwiperDots } from "./components";
import { HeaderOverlay } from "local/domain/onboarding";
import { View } from "react-native";
import styled from "styled-components/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const BaseWelcomeScreen: AFC = ({ children }) => {
  // TODO: we now have a new hook: useCarouselSlide in ui package
  // can consider refactor these to use the new hook
  const ref = React.useRef<Swiper>(null);

  const { bottom } = useSafeAreaInsets();

  const [slideIndex, setSlideIndex] = useState(0);

  const scrollForward = (): void => {
    scrollToSlide(slideIndex + 1);
  };

  const scrollBackward = (): void => {
    scrollToSlide(slideIndex - 1);
  };

  const scrollToSlide = (newSlideIndex: number): void => {
    if (ref.current) {
      ref.current.scrollTo(newSlideIndex);
    }
  };

  const numberOfSlides = React.Children.count(children);

  return (
    <Container style={{ paddingBottom: bottom }}>
      <View style={{ flex: 1 }}>
        <Swiper
          ref={ref}
          onIndexChanged={setSlideIndex}
          loop={false}
          showsPagination={false}
        >
          {children}
        </Swiper>
      </View>
      <FooterContainer>
        <NextButton
          scrollForwardFunction={scrollForward}
          totalSlides={numberOfSlides}
          currentSlideIndex={slideIndex}
        />
        <SwiperDots
          totalSlides={numberOfSlides}
          currentSlideIndex={slideIndex}
        />
      </FooterContainer>
      <HeaderOverlay
        currentSlideIndex={slideIndex}
        scrollBackFunction={scrollBackward}
      />
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  background-color: ${Colors.WHITE};
`;

const FooterContainer = styled(View)`
  background-color: ${Colors.WHITE};
`;

export { BaseWelcomeScreen };
