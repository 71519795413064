import { Screens, useNavigation } from "local/common";
import { useCallbackRef } from "@ailo/primitives";

export function useNavigateToNewChatParticipants(): () => void {
  const navigation = useNavigation();

  return useCallbackRef(() => {
    navigation.navigate(Screens.NewChatParticipants);
  }, [navigation]);
}
