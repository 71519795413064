import React, { ReactNode, FC, useCallback, useState, useEffect } from "react";
import { Image, Dimensions, View } from "react-native";
import styled from "styled-components/native";
import { AccountSecureInit } from "@ailo/primitives";
import { useNavigation, Screens } from "local/common";
import {
  ScreenContainer,
  Header
} from "local/domain/onboarding/phoneVerification";
import { PhoneNumberForm, PhoneNumberFormContext } from "./forms";
import { SpinnerOverlay } from "@ailo/ui";

export const SecureYourAccountScreen: FC = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    navigation.setOptions({
      title: "",
      headerRight: HeaderTitle,
      headerLeft: HeaderLeft
    });
  }, [navigation]);

  const navigateToCodeVerification = useCallback(
    (phoneNumber: string): void => {
      navigation.navigate(Screens.CodeVerification, { phoneNumber });
    },
    [navigation]
  );

  const toggleOverlay = useCallback((state: boolean): void => {
    setLoading(state);
  }, []);

  return (
    <>
      <StyledScreenContainer>
        <Container>
          <ImageContainer>
            <InnerImageContainer>
              <StyledImage source={AccountSecureInit} />
            </InnerImageContainer>
          </ImageContainer>
          <PhoneNumberFormContext>
            <StyledPhoneNumberForm
              onSuccess={navigateToCodeVerification}
              onLoadingStateChange={toggleOverlay}
            />
          </PhoneNumberFormContext>
        </Container>
      </StyledScreenContainer>
      {loading && <SpinnerOverlay />}
    </>
  );
};

const HeaderTitle = (): ReactNode => {
  return <Header />;
};

const HeaderLeft = (): ReactNode => {
  return null;
};

const StyledScreenContainer = styled(ScreenContainer)`
  padding-bottom: 16px;
`;

const StyledPhoneNumberForm = styled(PhoneNumberForm)`
  margin-top: 24px;
`;

const Container = styled(View)`
  flex: 1;
  margin-bottom: 32px;
`;

const ImageContainer = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const InnerImageContainer = styled(View)`
  padding-top: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const imageWidth = Dimensions.get("window").width * 1.38 - 340;
const imageHeight = imageWidth * 1.57;
const StyledImage = styled(Image)`
  width: ${imageWidth}px;
  height: ${imageHeight}px;
`;
