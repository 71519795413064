import React, { FC } from "react";
import styled from "styled-components/native";
import { Text, Colors } from "@ailo/primitives";

const PaidBillsHeader: FC = () => {
  return <Container color={Colors.TEXT.DARK.PRIMARY}>{"Paid"}</Container>;
};

const Container = styled(Text.BodyM)`
  padding: 20px 16px 16px 16px;
`;

export { PaidBillsHeader };
