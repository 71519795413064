import { AiloRN } from "@ailo/ailorn";
import {
  didQueryNotLoadYet,
  didQuerySucceed,
  logApolloResultFailed,
  useEnvironment
} from "@ailo/services";
import { Alert, ErrorAlertScreen, SpinnerOverlay } from "@ailo/ui";
import {
  Screens,
  useCurrentLegalEntity,
  useNavigation,
  useOnFocus,
  useRoute
} from "local/common";
import {
  FrequencyEnum,
  useAutoPayLiabilityPossible,
  useCanModifyAutoPayLiability
} from "local/domain/ledger";
import { useGetOwnerForWalletQuery } from "local/graphql";
import React, { FC } from "react";
import { SelectFrequencyDateScreen } from "../../../frequencyBasedComponents/SelectFrequencyDateScreen";
import { SelectMonthlyFrequencyDateScreen } from "../../../frequencyBasedComponents/SelectMonthlyFrequencyDateScreen";
import { useAutoPayLiabilityStatusForm } from "../../../profile/SetupAutoPayBillsScreen/useAutoPayLiabilityStatusForm";

const SelectStartDateScreen: FC = () => {
  const params = useRoute<Screens.AutoWithdrawPlanSelectStartDate>().params;

  const result = useGetOwnerForWalletQuery({
    variables: {
      walletId: params.walletId || "unknown"
    }
  });

  useOnFocus(result?.refetch);

  if (didQueryNotLoadYet(result)) {
    return <SpinnerOverlay style={{ marginTop: 24 }} />;
  }

  if (!result.data?.walletById?.owner?.reference) {
    return <Alert type={"error"} message={"Wallet owner not found"} />;
  }

  return (
    <SelectStartDateScreenWithWalletOwner
      walletOwnerRef={AiloRN.fromString(
        result.data?.walletById?.owner?.reference
      )}
    />
  );
};

const SelectStartDateScreenWithWalletOwner: FC<{ walletOwnerRef: AiloRN }> = ({
  walletOwnerRef
}) => {
  const navigation = useNavigation<Screens.AutoWithdrawPlanSelectStartDate>();
  const params = useRoute<Screens.AutoWithdrawPlanSelectStartDate>().params;
  const { ENABLE_AUTO_BILL_PAY_FROM_PERSONAL_WALLET } = useEnvironment();
  const { completelyOwnsAllProperties } = useAutoPayLiabilityPossible();

  const [legalEntityId] = useCurrentLegalEntity();

  const { statusResult } = useAutoPayLiabilityStatusForm(walletOwnerRef);
  const autoPayBillsEnabled = statusResult.data?.enabled;

  const { frequency, startDate } = params;

  const isPersonalWalletOwner = (): boolean => {
    return walletOwnerRef.equals(legalEntityId.id);
  };

  const isPersonalWalletAndBillPayCanBeEnabled = (): boolean => {
    return (
      ENABLE_AUTO_BILL_PAY_FROM_PERSONAL_WALLET &&
      completelyOwnsAllProperties &&
      isPersonalWalletOwner()
    );
  };

  const { canModifyAutoPayLiability, isManagementOrManagementFolio } =
    useCanModifyAutoPayLiability(walletOwnerRef);

  const shouldAskToEnableBill =
    !autoPayBillsEnabled &&
    (isPersonalWalletAndBillPayCanBeEnabled() ||
      (isManagementOrManagementFolio && canModifyAutoPayLiability));

  const nextScreen = shouldAskToEnableBill
    ? Screens.AutoWithdrawPlanConfirmAutoPayBills
    : Screens.AutoWithdrawPlanConfirmDetails;

  const onDateSelected = (
    selectedDate: Date | undefined,
    anniversary: number
  ): void => {
    navigation.navigate(nextScreen, {
      ...params,
      startDate: selectedDate,
      anniversary,
      anniversaryDaysOfMonth: undefined,
      isLastDayOfTheMonth: false,
      walletOwnerRef
    });
  };

  const onDateSelectedForMonthlyFrequency = (
    selectedDate: Date | undefined,
    anniversaryDaysOfMonth: number[],
    isLastDayOfTheMonth: boolean
  ): void => {
    navigation.navigate(nextScreen, {
      ...params,
      startDate: selectedDate,
      anniversary: undefined,
      anniversaryDaysOfMonth,
      isLastDayOfTheMonth,
      walletOwnerRef
    });
  };

  if (didQueryNotLoadYet(statusResult)) {
    return <SpinnerOverlay style={{ marginTop: 24 }} />;
  }

  if (!didQuerySucceed(statusResult)) {
    logApolloResultFailed(statusResult, {
      operationId: "SelectStartDateScreen.statusResult"
    });
    return (
      <ErrorAlertScreen
        variant={"large"}
        title={"There was a problem loading\nSetup Auto Transfers"}
        onRetry={statusResult.refetch}
      />
    );
  }

  return frequency === FrequencyEnum.monthly ? (
    <SelectMonthlyFrequencyDateScreen
      frequency={frequency}
      onDaySelected={onDateSelectedForMonthlyFrequency}
    />
  ) : (
    <SelectFrequencyDateScreen
      heading={"what day should it start?"}
      confirmationPrefix={"Transferred"}
      frequency={frequency}
      startDate={startDate}
      onDateSelected={onDateSelected}
    />
  );
};

export { SelectStartDateScreen };
