import { AlertScreen, SpinnerOverlay } from "@ailo/ui";
import { PaymentMethodDisplay } from "local/domain/ledger";
import { useGetDefaultPaymentMethodIdsForUser } from "local/domain/ledger/hooks";
import {
  ScreenComponent,
  Screens,
  useNavCloseButton,
  useNavigation,
  useOnFocus,
  useRoute,
  useTitle,
  usePropertySelectorContext
} from "local/common";
import { EnsureKycVerified } from "local/domain/authz";
import React, { FC } from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { PaymentDisplay } from "./components";
import { useGetPaymentKeyAndAutoPaymentQuery } from "local/graphql";

const PayBillLiabilityScreenContent: FC = () => {
  const navigation = useNavigation<Screens.PayBillLiability>();
  const { params, name } = useRoute<Screens.PayBillLiability>();
  const {
    liabilityId,
    paymentMethodId,
    showLoadingOverlay,
    title,
    showOtherPaymentOptions
  } = params;

  useNavCloseButton();

  const { loading, data, refetch } = useGetPaymentKeyAndAutoPaymentQuery({
    variables: { liabilityId }
  });

  useOnFocus(refetch);
  useTitle(title);

  const onPaymentMethodChanged = (paymentMethodId: string): void => {
    navigation.navigate(name, { ...params, paymentMethodId });
  };

  const {
    defaultInPaymentMethodId,
    loading: defaultPaymentMethodLoading,
    refetch: defaultPaymentMethodRefetch
  } = useGetDefaultPaymentMethodIdsForUser();

  if ((!data && loading) || defaultPaymentMethodLoading) {
    return (
      <Container>
        <PaymentDisplay.Loading />
        <PaymentMethodDisplay.Loading />
      </Container>
    );
  }

  const selectedPaymentMethodId =
    paymentMethodId || defaultInPaymentMethodId || undefined;

  const idempotentKey = data?.liabilityById?.paymentKey;
  const autoPaymentEnabled = Boolean(data?.liabilityById?.autoPaymentDetails);

  if (selectedPaymentMethodId === undefined || idempotentKey == null) {
    return (
      <AlertScreen
        variant={"large"}
        title={"There's a problem\nloading this page"}
        description={
          "We're sorry, there was a technical problem loading this page. Try reloading this page."
        }
        inlineButton={{
          label: "Reload this page",
          onPress(): void {
            idempotentKey == null && refetch();
            selectedPaymentMethodId === undefined &&
              defaultPaymentMethodRefetch();
          }
        }}
      />
    );
  }

  return (
    <Container>
      <ScrollView keyboardShouldPersistTaps={"handled"}>
        <PaymentDisplay
          paymentMethodId={selectedPaymentMethodId}
          idempotentKey={idempotentKey}
          autoPaymentEnabled={autoPaymentEnabled}
        />
        <PaymentMethodDisplay
          paymentMethodId={selectedPaymentMethodId}
          onPaymentMethodChanged={onPaymentMethodChanged}
          showOtherPaymentOptions={showOtherPaymentOptions}
        />
      </ScrollView>
      {showLoadingOverlay && <SpinnerOverlay />}
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
`;

const PayBillLiabilityScreen: React.FC = () => {
  const { params } = useRoute<Screens.PayBillLiability>();
  const { currentProperty } = usePropertySelectorContext();
  const browsingAsInvestor =
    !!currentProperty && currentProperty.__typename === "OwnedProperty";
  let screenContent = <PayBillLiabilityScreenContent />;
  if (browsingAsInvestor) {
    screenContent = (
      <EnsureKycVerified onKycCheckDismiss={params.onClose}>
        {screenContent}
      </EnsureKycVerified>
    );
  }

  return <ScreenComponent>{screenContent}</ScreenComponent>;
};

export { PayBillLiabilityScreen };
