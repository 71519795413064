import {
  didQueryNotLoadYet,
  didQuerySucceed,
  ensureMutationSucceeds,
  logApolloResultFailed,
  withScreenComponent,
  useAnalytics,
  AiloSentry
} from "@ailo/services";
import {
  Button,
  CountryInput,
  ErrorAlertScreen,
  FormField,
  PostcodeInput,
  SpinnerOverlay,
  TextInput,
  useToastContext,
  validatePostcode
} from "@ailo/ui";
import {
  ScreenContainer,
  ScreenHeader,
  Screens,
  useNavigation,
  useRoute
} from "local/common";

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { View, Keyboard } from "react-native";
import {
  useGetCurrentPersonProfileDetailsQuery,
  useUpdatePersonProfileDetailsMutation
} from "local/graphql";

interface FormData {
  unitStreetNumber: string | null;
  streetName: string | null;
  suburb: string | null;
  state: string | null;
  postcode: string | null;
  country: string | null;
}

export const EditAddressScreen = withScreenComponent(
  {
    statusBarStyle: "dark-content",
    viewTitle: null
  },
  (): React.ReactElement => {
    const toasts = useToastContext();
    const navigation = useNavigation();
    const {
      params: {
        title = "Your Residential Address",
        submitButtonLabel = "Update Residential Address",
        onSuccess
      } = {}
    } = useRoute<Screens.EditAddress>();
    const analytics = useAnalytics();
    const result = useGetCurrentPersonProfileDetailsQuery({
      notifyOnNetworkStatusChange: true
    });

    const [updateMutation, mutationResult] =
      useUpdatePersonProfileDetailsMutation();

    const { control, handleSubmit, formState, errors, watch } =
      useForm<FormData>();

    const country = watch("country");

    if (didQueryNotLoadYet(result)) {
      return <SpinnerOverlay />;
    }

    if (!didQuerySucceed(result) || !result.data.effectiveUser) {
      logApolloResultFailed(result, {
        operationId: "result"
      });
      return (
        <ErrorAlertScreen
          title={"There was a problem loading profile data"}
          onRetry={result.refetch}
        />
      );
    }

    const { person } = result.data.effectiveUser;

    const submit = handleSubmit(async (data: FormData) => {
      Keyboard.dismiss();
      try {
        await ensureMutationSucceeds(
          updateMutation({
            variables: {
              input: {
                id: person.ailoRN,
                unitStreetNumber: data.unitStreetNumber || null,
                streetName: data.streetName || null,
                suburb: data.suburb || null,
                state: data.state || null,
                postcode: data.postcode || null,
                country: data.country || null
              }
            }
          }),
          {
            dataKey: "updatePersonProfileDetails"
          }
        );
      } catch (error) {
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      if (onSuccess) {
        onSuccess();
      } else {
        navigation.navigate(Screens.EditProfile);
        toasts.show({
          type: "success",
          message: "Residential Address updated"
        });
      }
      analytics.track("Profile Updated", {
        fields: ["Residential Address"]
      });
    });

    return (
      <>
        <ScreenContainer
          stickyBottom={
            <Button.Primary
              disabled={formState.isSubmitting || !formState.isDirty}
              onPress={submit}
            >
              {submitButtonLabel}
            </Button.Primary>
          }
        >
          <ScreenHeader title={title} />

          <FormField
            label={"Street number"}
            error={errors.unitStreetNumber?.message}
            style={{ marginTop: 24 }}
          >
            <Controller
              control={control}
              name={"unitStreetNumber"}
              rules={{ required: "Street number is required." }}
              defaultValue={person.unitStreetNumber}
              render={({ onChange, onBlur, value }): React.ReactElement => (
                <TextInput
                  autoCorrect={false}
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FormField>
          <FormField
            label={"Street Name"}
            error={errors.streetName?.message}
            style={{ marginTop: 12 }}
          >
            <Controller
              control={control}
              name={"streetName"}
              rules={{ required: "Street Name is required." }}
              defaultValue={person.streetName}
              render={({ onChange, onBlur, value }): React.ReactElement => (
                <TextInput
                  autoCapitalize={"sentences"}
                  autoCompleteType={"street-address"}
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FormField>
          <FormField
            label={"Suburb"}
            error={errors.suburb?.message}
            style={{ marginTop: 12 }}
          >
            <Controller
              control={control}
              name={"suburb"}
              rules={{ required: "Suburb is required." }}
              defaultValue={person.suburb}
              render={({ onChange, onBlur, value }): React.ReactElement => (
                <TextInput
                  textContentType={"addressCity"}
                  autoCapitalize={"sentences"}
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FormField>
          <View style={{ flexDirection: "row", marginTop: 12 }}>
            <FormField
              label={"State"}
              error={errors.state?.message}
              style={{ flex: 1, marginRight: 12 }}
            >
              <Controller
                control={control}
                name={"state"}
                // TODO switch this to SelectInput with a limited set of state options
                rules={{ required: "State is required." }}
                defaultValue={person.state}
                render={({ onChange, onBlur, value }): React.ReactElement => (
                  <TextInput
                    textContentType={"addressState"}
                    autoCapitalize={"sentences"}
                    value={value}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormField>

            <FormField
              label={"Post code"}
              error={errors.postcode?.message}
              style={{ flex: 1 }}
            >
              <Controller
                control={control}
                name={"postcode"}
                rules={{
                  required: "Post code is required.",
                  validate: (postcode) => validatePostcode(postcode, country)
                }}
                defaultValue={person.postcode}
                render={({ onChange, onBlur, value }): React.ReactElement => (
                  <PostcodeInput
                    country={country || person.country}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormField>
          </View>

          <FormField
            label={"Country"}
            error={errors.country?.message}
            style={{ marginTop: 12 }}
          >
            <Controller
              control={control}
              name={"country"}
              rules={{ required: "Country is required." }}
              defaultValue={person.country || "Australia"}
              render={({ onChange, value }): React.ReactElement => (
                <CountryInput value={value} onChange={onChange} />
              )}
            />
          </FormField>
        </ScreenContainer>
        {mutationResult.loading && <SpinnerOverlay />}
      </>
    );
  }
);
