import React from "react";
import { SFC, Text, Link } from "@ailo/primitives";
import styled from "styled-components/native";
import { Screens, useNavigation } from "local/common";

const HeaderText: SFC = ({ style }) => {
  const navigation = useNavigation();

  const navigateToToSViewScreen = (): void => {
    navigation.navigate(Screens.TermsOfServiceView);
  };

  return (
    <StyledText style={style}>
      {"Please read and accept our"}
      <Link onPress={navigateToToSViewScreen} variant={"primary"}>
        {" terms of service "}
      </Link>
      {"before you proceed"}
    </StyledText>
  );
};

const StyledText = styled(Text.DisplayS)`
  text-align: center;
`;

export { HeaderText };
