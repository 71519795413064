import { useNavigation } from "@react-navigation/native";
import { Screens } from "local/common";
import { NavigateToChatList } from "@ailo/domains";
import { useCallbackRef } from "@ailo/primitives";

export function useNavigateToChatList(): NavigateToChatList {
  const navigation = useNavigation();

  return useCallbackRef(() => {
    navigation.navigate(Screens.TabNavigator, { screen: Screens.ChatTab });
  }, [navigation]);
}
