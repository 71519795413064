import { hasValueAtKey } from "ts-is-present";
import { useUserStorage } from "@ailo/services";
import {
  useAnalytics,
  Property,
  PropertySelectorContext,
  PropertySelectorContextValue
} from "local/common";
import { PlatformFeatureId } from "local/graphql";
import { sortBy } from "lodash";
import React, {
  useLayoutEffect,
  useMemo,
  useState,
  useCallback,
  useEffect
} from "react";
import usePromise from "react-promise";
import { useGlobal } from "reactn";
import { SetupData } from "../SetupData";
import { createPropertiesFromSetupData } from "./createPropertiesFromSetupData";

export interface PropertySelectorContextProviderProps {
  setupData: SetupData;
  renderLoading?(): React.ReactElement;
  children: React.ReactNode;
}

function sortPropertiesAlphabetically(properties: Property[]): Property[] {
  return sortBy(
    properties,
    (p) => p.address.streetName,
    (p) => p.address.unitStreetNumber,
    (p) => p.address.suburb
  );
}

const SELECTED_PROPERTY_ID_STORAGE_KEY =
  "PropertySelectorContextProvider.initialPropertyId";

export function PropertySelectorContextProvider({
  setupData,
  renderLoading,
  children
}: PropertySelectorContextProviderProps): React.ReactElement | null {
  const [availableFeatures] = useGlobal("availableFeatures");
  const isTenant = availableFeatures.includes(PlatformFeatureId.TenantAccess);
  const isInvestor = availableFeatures.includes(
    PlatformFeatureId.InvestorAccess
  );
  const analytics = useAnalytics();
  const userStorage = useUserStorage();

  const properties: Property[] = useMemo(() => {
    const propertiesData = createPropertiesFromSetupData(setupData);
    const rentedProperties = isTenant
      ? propertiesData.filter(
          hasValueAtKey("__typename", "RentedProperty" as const)
        )
      : [];

    const ownedProperties = isInvestor
      ? propertiesData.filter(
          hasValueAtKey("__typename", "OwnedProperty" as const)
        )
      : [];

    return [
      ...sortPropertiesAlphabetically(rentedProperties),
      ...sortPropertiesAlphabetically(ownedProperties)
    ];
  }, [isInvestor, isTenant, setupData]);

  const cachedInitialPropertyIdResult = usePromise(
    useCallback(
      () => userStorage.getItem<string>(SELECTED_PROPERTY_ID_STORAGE_KEY),
      [userStorage]
    )
  );

  const [currentProperty, setCurrentProperty] = useState<Property>();
  const [initialPropertySet, setInitialPropertySet] = useState(false);
  useEffect(() => {
    if (initialPropertySet) {
      return;
    }

    const { loading, value: cachedId } = cachedInitialPropertyIdResult;
    if (loading) {
      return;
    }

    const nextProperty =
      (cachedId && properties.find((p) => p.id.internalId === cachedId)) ||
      properties[0];
    setCurrentProperty(nextProperty);
    setInitialPropertySet(true);
  }, [initialPropertySet, cachedInitialPropertyIdResult, properties]);
  const loading = !initialPropertySet;

  const currentManagementId = currentProperty?.management.id;
  // This ideally should be in a `useEffect`
  // (as it's not needed for rendering purposes),
  // but it's done in `useLayoutEffect` so that the analytics properties are set
  // before `ScreenComponent` calls `analytics.trackScreenVisited()`,
  // so that the first screen analytics event (once the user logs in)
  // already has the `management_id` property.
  useLayoutEffect(() => {
    analytics.mergeContext({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      management_id: currentManagementId ? [currentManagementId.toString()] : []
    });
    return (): void => {
      analytics.mergeContext({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        management_id: undefined
      });
    };
  }, [analytics, currentManagementId]);

  const contextValue: PropertySelectorContextValue = useMemo(() => {
    const hasRentedProperties =
      properties.filter((p) => p.__typename === "RentedProperty").length > 0;
    const hasOwnedProperties =
      properties.filter((p) => p.__typename === "OwnedProperty").length > 0;

    function setAndCacheCurrentProperty(property: Property | undefined): void {
      setCurrentProperty(property);
      userStorage.setItem(
        SELECTED_PROPERTY_ID_STORAGE_KEY,
        property?.id.internalId
      );
    }

    return {
      currentProperty,
      allProperties: properties,
      hasRentedProperties,
      hasOwnedProperties,
      setCurrentProperty: setAndCacheCurrentProperty
    };
  }, [currentProperty, properties, userStorage]);

  if (loading) {
    return renderLoading?.() ?? null;
  }

  return (
    <PropertySelectorContext.Provider value={contextValue}>
      {children}
    </PropertySelectorContext.Provider>
  );
}
