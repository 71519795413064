import React, { FC } from "react";
import CountryPickerButton, {
  Country,
  CountryCode
} from "react-native-country-picker-modal";
import { THEME } from "./theme";

interface Props {
  countryCode: CountryCode;
  onSelect: (country: Country) => void;
  onClose: () => void;
  onOpen: () => void;
  modalVisible: boolean;
}

export const CountryPicker: FC<Props> = ({
  countryCode,
  onSelect,
  onOpen,
  onClose,
  modalVisible
}) => {
  return (
    <CountryPickerButton
      withFilter={true}
      withEmoji={false}
      withFlag={true}
      countryCode={countryCode}
      onSelect={onSelect}
      visible={modalVisible}
      onClose={onClose}
      onOpen={onOpen}
      theme={THEME}
      containerButtonStyle={{
        marginLeft: 9,
        marginTop: 0,
        paddingTop: 16,
        paddingBottom: 16,
        width: 27
      }}
      modalProps={{ presentationStyle: "pageSheet" }}
    />
  );
};
