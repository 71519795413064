import moment from "moment-timezone";

/**
 * @deprecated Try extending and using `DateTimeWithTimeZone` from UI package instead.
 */
class DateTimeWithTimeZone {
  private dueDate: moment.Moment;

  constructor(dueDate: string, private timeZone = "Australia/Sydney") {
    this.dueDate = moment.tz(dueDate, timeZone);
  }

  private now(): moment.Moment {
    return moment().tz(this.timeZone);
  }

  public friendlyFormat(): string {
    return this.dueDate.calendar(this.now(), calendarDateFormat);
  }

  public simpleFormat(): string {
    return this.dueDate.format("DD MMMM");
  }
}

const calendarDateFormat = {
  sameDay: "[today]",
  nextDay: "[tomorrow]",
  nextWeek: "dddd",
  lastDay: "[yesterday]",
  lastWeek: "ddd, DD MMM",
  sameElse: "ddd, DD MMM"
};

export { DateTimeWithTimeZone };
