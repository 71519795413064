import React, { FC } from "react";
import { ListItem } from "@ailo/ui";
import { View } from "react-native";
import { useGetTenanciesWithLiabilitiesQuery } from "local/graphql";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";

export interface SelectedProperty {
  id?: string;
  liabilityId?: string;
  address?: string;
  autoPaymentDetails?: {
    paymentMethodId?: string;
  };
}

interface Props {
  onPropertySelected: (tenancy: SelectedProperty) => void;
}

const SelectPropertyScreenComponent: FC<Props> = ({ onPropertySelected }) => {
  const { data } = useGetTenanciesWithLiabilitiesQuery();

  return (
    <ScreenWithHeaderAndAction header={"Which property?"}>
      {data?.tenancies?.items.map((tenancy, i) => {
        const { address } = tenancy?.property || {};

        const addressString = [address?.unitStreetNumber, address?.streetName]
          .filter(Boolean)
          .join(" ");

        return (
          <ListItem
            key={i}
            header={addressString}
            subHeader={[address?.suburb, address?.state, address?.postcode]
              .filter(Boolean)
              .join(" ")}
            style={{ paddingHorizontal: 0 }}
            leftComponent={<View style={{ width: 16 }} />}
            onPress={(): void =>
              onPropertySelected({
                id: tenancy?.id,
                liabilityId: tenancy?.liability?.id,
                address: addressString,
                autoPaymentDetails: {
                  paymentMethodId:
                    tenancy?.liability?.autoPaymentDetails?.paymentMethod?.id
                }
              })
            }
          />
        );
      })}
    </ScreenWithHeaderAndAction>
  );
};

export { SelectPropertyScreenComponent };
