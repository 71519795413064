import { RightChevronIcon } from "@ailo/primitives";
import { ListItem } from "@ailo/ui";
import { Screens, useNavigateToThisScreen, useNavigation } from "local/common";
import React, { FC, useCallback } from "react";

interface Props {
  id: string;
  name: string;
  frequency: string;
}

const AutoWithdrawPlanListItem: FC<Props> = ({ id, frequency, name }) => {
  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();

  const onPressEdit = useCallback(() => {
    navigation.navigate(Screens.AutoWithdrawPlanEdit, {
      id,
      onSuccess: returnToThisScreen
    });
  }, [id, navigation, returnToThisScreen]);

  return (
    <ListItem
      onPress={onPressEdit}
      header={name}
      subHeader={frequency}
      rightComponent={<RightChevronIcon width={20} height={20} />}
    />
  );
};

export { AutoWithdrawPlanListItem };
