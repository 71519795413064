import { SFC } from "@ailo/primitives";
import { useClearPreviousDataOnVariablesChange } from "@ailo/services";
import { Card } from "@ailo/ui";
import { UpcomingBillsList } from "local/domain/bill";
import { Screens, useNavigation, useOnFocus } from "local/common";
import React, { FC, useCallback } from "react";
import { ViewStyle } from "react-native";
import {
  UpcomingBillsCardErrorBoundary,
  UpcomingBillsCardErrorComponent,
  UpcomingBillsHeader,
  ViewAllButton
} from "./components";
import { useGetUpcomingBillsQuery } from "local/graphql";

interface Props {
  reference: string;
  address: string;
  onBillPress: (billId: string) => void;
  style?: ViewStyle;
}

interface Statics {
  Loading: SFC;
  Empty: SFC;
}

const Loading: SFC = ({ style }) => {
  return (
    <Card style={style} testID={undefined}>
      <UpcomingBillsHeader />
      <UpcomingBillsList.Loading itemsLimit={3} />
      <ViewAllButton.Loading />
    </Card>
  );
};

const Empty: SFC = ({ style }) => {
  return (
    <Card style={style} testID={undefined}>
      <UpcomingBillsHeader />
      <UpcomingBillsList.Empty
        text={"No new outstanding bills"}
        style={{ marginBottom: 20 }}
      />
    </Card>
  );
};

const UpcomingBillsCard: FC<Props> & Statics = ({
  reference,
  address,
  onBillPress,
  style
}) => {
  const navigation = useNavigation();
  const navigateToAllUpcomingBills = useCallback(() => {
    navigation.navigate(Screens.UpcomingBills, {
      reference,
      address
    });
  }, [navigation, reference, address]);

  const pageCursor = {
    pageSize: 3,
    paginateBackward: false
  };
  const { loading, data, refetch } = useClearPreviousDataOnVariablesChange(
    useGetUpcomingBillsQuery,
    {
      variables: {
        reference,
        pageCursor
      }
    }
  );

  useOnFocus(refetch);

  if (!data && loading) {
    return (
      <UpcomingBillsCardErrorBoundary style={style}>
        <Loading style={style} />
      </UpcomingBillsCardErrorBoundary>
    );
  }

  const totalUpcomingBills = data?.upcomingBills?.pageInfo?.total;

  if (totalUpcomingBills == null) {
    return <UpcomingBillsCardErrorComponent style={style} />;
  }

  if (totalUpcomingBills === 0) {
    return (
      <UpcomingBillsCardErrorBoundary style={style}>
        <Empty style={style} />
      </UpcomingBillsCardErrorBoundary>
    );
  }

  return (
    <UpcomingBillsCardErrorBoundary style={style}>
      <Card style={style}>
        <UpcomingBillsHeader />
        <UpcomingBillsList
          reference={reference}
          address={address}
          itemsLimit={3}
          onBillPress={onBillPress}
        />
        {totalUpcomingBills > 3 && (
          <ViewAllButton onPress={navigateToAllUpcomingBills} />
        )}
      </Card>
    </UpcomingBillsCardErrorBoundary>
  );
};

UpcomingBillsCard.Loading = Loading;
UpcomingBillsCard.Empty = Empty;

export { UpcomingBillsCard };
