import React from "react";
import {
  AFC,
  WelcomeTenant1,
  WelcomeTenant2,
  WelcomeTenant3
} from "@ailo/primitives";
import { TenantWelcomeSlide } from "./components";
import { FeatureShowcaseSlide } from "local/domain/onboarding";
import { BaseWelcomeScreen } from "./BaseWelcomeScreen";

const TenantWelcomeScreen: AFC = () => {
  return (
    <BaseWelcomeScreen>
      <TenantWelcomeSlide />
      <FeatureShowcaseSlide
        titleText={"Chat with your \nproperty manager"}
        descriptionText={
          "Get issues sorted faster. No more phone calls back and forth."
        }
        imageSource={WelcomeTenant1}
      />
      <FeatureShowcaseSlide
        titleText={"Know exactly when your next rent payment is due"}
        descriptionText={
          "See what rent has been paid and what is due soon. No more guessing."
        }
        imageSource={WelcomeTenant2}
      />
      <FeatureShowcaseSlide
        titleText={"Pay rent your way"}
        descriptionText={
          "Choose to pay rent using a credit or debit card or your bank account."
        }
        imageSource={WelcomeTenant3}
      />
    </BaseWelcomeScreen>
  );
};

export { TenantWelcomeScreen };
