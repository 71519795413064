import React, { useCallback } from "react";
import { AlertScreen } from "@ailo/ui";
import { Screens, useNavigation, useNavigateToThisScreen } from "local/common";

const EmptyPaymentMethodList: React.FC = () => {
  const navigation = useNavigation();

  const returnToThisScreen = useNavigateToThisScreen();

  const handleAddPaymentMethod = useCallback(() => {
    navigation.navigate(Screens.SetupPaymentMethod, {
      onClose: returnToThisScreen,
      onSuccess: returnToThisScreen
    });
  }, [navigation, returnToThisScreen]);

  return (
    <AlertScreen
      title={`No payment methods\nhave been added yet`}
      type={"wallet"}
      inlineButton={{
        label: "Add Payment Method",
        onPress: handleAddPaymentMethod,
        type: "primary"
      }}
    />
  );
};

export { EmptyPaymentMethodList };
