import React, { FC, useCallback } from "react";
import { ListItem } from "@ailo/ui";
import { useNavigation, useRoute, Screens } from "local/common";
import { RightChevronIcon } from "@ailo/primitives";

const AddBankAccountListItem: FC = () => {
  const { onSuccess } = useRoute<Screens.SetupPaymentMethod>().params;
  const navigation = useNavigation<Screens.SetupPaymentMethod>();

  const addBankAccount = useCallback(() => {
    navigation.navigate(Screens.AddBankAccount, {
      onSuccess: (paymentMethod) => onSuccess(paymentMethod.id)
    });
  }, [navigation, onSuccess]);

  return (
    <ListItem
      header={"Bank Account"}
      testID={"bank-account-list-item"}
      onPress={addBankAccount}
      style={{ alignItems: "center" }}
      rightComponent={<RightChevronIcon width={20} height={20} />}
    />
  );
};

export { AddBankAccountListItem };
