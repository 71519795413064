import { formatLegalEntityName } from "@ailo/domain-helpers";
import { CameraIcon, Colors, Text } from "@ailo/primitives";
import { Button, LegalEntityAvatar } from "@ailo/ui";
import { LegalEntity } from "local/common";
import React, { ReactElement, useState } from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import styled from "styled-components/native";

const StyledContainer = styled(View)`
  flex-direction: column;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
`;

const AVATAR_EDIT_BUTTON_OUTLINE_WIDTH = 4;
const AVATAR_EDIT_BUTTON_SIZE = 40;
const AVATAR_EDIT_BUTTON_CONTAINER_SIZE =
  2 * AVATAR_EDIT_BUTTON_OUTLINE_WIDTH + AVATAR_EDIT_BUTTON_SIZE;
const StyledAvatarEditButtonContainer = styled(View)`
  position: absolute;
  bottom: -${AVATAR_EDIT_BUTTON_OUTLINE_WIDTH}px;
  right: -${AVATAR_EDIT_BUTTON_CONTAINER_SIZE / 2}px;
  background-color: ${Colors.WHITE};
  width: ${AVATAR_EDIT_BUTTON_CONTAINER_SIZE}px;
  height: ${AVATAR_EDIT_BUTTON_CONTAINER_SIZE}px;
  border-radius: ${AVATAR_EDIT_BUTTON_CONTAINER_SIZE}px;
  padding: ${AVATAR_EDIT_BUTTON_OUTLINE_WIDTH}px;
`;

const StyledAvatarEditButton = styled(Button.Secondary)`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 0px;
`;

const StyledName = styled(Text.DisplayM)`
  margin-top: 8px;
  text-align: center;
`;

const StyledEmail = styled(Text.BodyM)`
  color: ${Colors.TEXT.DARK.SECONDARY};
  text-align: center;
`;

interface UserProfileIntroProps {
  legalEntity: LegalEntity;
  viewProfileButton?: {
    onPress?(): void;
  };
  photoEditButton?: {
    disabled?: boolean;
    onPress?(): void;
  };
  switchAccountButton?: {
    onPress?(): void;
  };
}

export function UserProfileIntro({
  legalEntity,
  viewProfileButton,
  switchAccountButton,
  photoEditButton
}: UserProfileIntroProps): ReactElement {
  const [avatarPressed, setAvatarPressed] = useState(false);

  const avatar = (
    <LegalEntityAvatar
      legalEntity={legalEntity}
      size={112}
      initialsColor={Colors.TEXT.LIGHT.PRIMARY}
    />
  );

  return (
    <StyledContainer>
      <View>
        {photoEditButton ? (
          <>
            <TouchableWithoutFeedback
              disabled={photoEditButton.disabled}
              onPress={photoEditButton.onPress}
              onPressIn={(): void => setAvatarPressed(true)}
              onPressOut={(): void => setAvatarPressed(false)}
            >
              <View>{avatar}</View>
            </TouchableWithoutFeedback>
            <StyledAvatarEditButtonContainer>
              <StyledAvatarEditButton
                pressed={avatarPressed ? true : undefined}
                disabled={photoEditButton.disabled}
                onPress={photoEditButton.onPress}
              >
                <CameraIcon width={20} height={20} />
              </StyledAvatarEditButton>
            </StyledAvatarEditButtonContainer>
          </>
        ) : (
          avatar
        )}
      </View>
      <StyledName>{formatLegalEntityName(legalEntity)}</StyledName>
      {"emailAddress" in legalEntity && (
        <StyledEmail>{legalEntity.emailAddress}</StyledEmail>
      )}
      {(viewProfileButton || switchAccountButton) && (
        <View style={{ marginTop: 20, flexDirection: "row" }}>
          {viewProfileButton && (
            <Button.Secondary
              type={"small"}
              onPress={viewProfileButton.onPress}
            >
              {"View Profile"}
            </Button.Secondary>
          )}
          {switchAccountButton && (
            <Button.Secondary
              type={"small"}
              style={{ marginLeft: viewProfileButton ? 10 : 0 }}
              onPress={switchAccountButton.onPress}
            >
              {"Switch Account"}
            </Button.Secondary>
          )}
        </View>
      )}
    </StyledContainer>
  );
}
