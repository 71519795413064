import { AlertScreen, SpinnerOverlay } from "@ailo/ui";
import { PaymentMethodDisplay } from "local/domain/ledger";
import {
  Screens,
  useNavCloseButton,
  useNavigation,
  useOnFocus,
  useTitle,
  useRoute,
  withScreenComponent
} from "local/common";
import { useGetDefaultPaymentMethodIdsForUser } from "local/domain/ledger/hooks";
import React from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import { PaymentDisplay, PayRentOffAppPaymentInfoBox } from "./components";
import { useGetPayRentLiabilityDetailsQuery } from "local/graphql";
import { AiloDate } from "@ailo/domains";

export const PayRentLiabilityScreen = withScreenComponent(
  (): React.ReactElement => {
    const navigation = useNavigation<Screens.PayRentLiability>();
    const { params, name } = useRoute<Screens.PayRentLiability>();
    const {
      tenancyId,
      liabilityId,
      paymentMethodId,
      showLoadingOverlay,
      showOtherPaymentOptions
    } = params;

    useNavCloseButton();

    const { loading, data, refetch } = useGetPayRentLiabilityDetailsQuery({
      variables: { tenancyId, liabilityId }
    });

    const {
      defaultInPaymentMethodId,
      loading: defaultPaymentMethodLoading,
      refetch: defaultPaymentMethodRefetch
    } = useGetDefaultPaymentMethodIdsForUser();

    useOnFocus(refetch);
    useTitle("Pay Rent");

    const onPaymentMethodChanged = (paymentMethodId: string): void => {
      navigation.navigate(name, { ...params, paymentMethodId });
    };

    if ((!data && loading) || defaultPaymentMethodLoading) {
      return (
        <Container>
          <PaymentDisplay.Loading />
          <PaymentMethodDisplay.Loading />
        </Container>
      );
    }

    const selectedPaymentMethodId =
      paymentMethodId || defaultInPaymentMethodId || undefined;

    const idempotentKey = data?.liabilityById?.paymentKey;
    const autoPaymentEnabled = Boolean(data?.liabilityById?.autoPaymentDetails);
    const terminationAmountDueCents =
      data?.liabilityById?.terminationAmountDue?.cents;
    const tenancyEndDate = data?.tenancy?.endDate
      ? new AiloDate(data?.tenancy?.endDate)
      : undefined;

    if (selectedPaymentMethodId === undefined || idempotentKey == null) {
      return (
        <AlertScreen
          variant={"large"}
          title={"There's a problem\nloading this page"}
          description={
            "We're sorry, there was a technical problem loading this page. Try reloading this page."
          }
          inlineButton={{
            label: "Reload this page",
            onPress(): void {
              idempotentKey == null && refetch();
              selectedPaymentMethodId === undefined &&
                defaultPaymentMethodRefetch();
            }
          }}
        />
      );
    }

    return (
      <Container>
        <ScrollView keyboardShouldPersistTaps={"handled"}>
          <PaymentDisplay
            paymentMethodId={selectedPaymentMethodId}
            idempotentKey={idempotentKey}
            autoPaymentEnabled={autoPaymentEnabled}
            terminationAmountDueCents={terminationAmountDueCents}
            tenancyEndDate={tenancyEndDate}
          />
          <PaymentMethodDisplay
            paymentMethodId={selectedPaymentMethodId}
            onPaymentMethodChanged={onPaymentMethodChanged}
            showOtherPaymentOptions={showOtherPaymentOptions}
          />
          <PayRentOffAppPaymentInfoBox />
        </ScrollView>
        {showLoadingOverlay && <SpinnerOverlay />}
      </Container>
    );
  }
);

const Container = styled(View)`
  flex: 1;
`;
