import { Screens, useNavigation, useRoute } from "local/common";
import React, { FC } from "react";
import { SelectFrequencyDateScreen } from "../../../frequencyBasedComponents/SelectFrequencyDateScreen";

const SelectStartDateScreen: FC = () => {
  const navigation = useNavigation<Screens.LiabilityPlanSelectStartDate>();
  const params = useRoute<Screens.LiabilityPlanSelectStartDate>().params;
  const { frequency, startDate } = params;

  const onDateSelected = (
    selectedDate: Date | undefined,
    anniversary: number
  ): void => {
    navigation.navigate(Screens.LiabilityPlanSelectEndDate, {
      ...params,
      startDate: selectedDate,
      anniversary: anniversary
    });
  };

  return (
    <SelectFrequencyDateScreen
      heading={"what day should it start?"}
      confirmationPrefix={"Paid"}
      frequency={frequency}
      startDate={startDate}
      onDateSelected={onDateSelected}
      offAppInfoEnabled={true}
    />
  );
};

export { SelectStartDateScreen };
