import {
  ChatSubscriptionContextProvider,
  ContactCardSetup,
  SharedNavigationSetup,
  useSetCurrentUserEmailInErrorContext,
  useSetupNotifications
} from "@ailo/domains";
import {
  didQueryFail,
  didQueryNotLoadYet,
  staticEnvironment,
  useAuth,
  useLogApolloResultFailed
} from "@ailo/services";
import {
  AlertScreen,
  BottomSheetSetup,
  FileCarouselSetup,
  LoadingSplashPage
} from "@ailo/ui";
import React, { useEffect } from "react";
import { OnboardingContextProvider } from "./OnboardingContextProvider";
import { useSetupData } from "./SetupData";
import { Platform } from "react-native";
import { useOnboardingFlag } from "local/domain/onboarding";
import { NetworkStatus } from "@apollo/client";
import { PropertySelectorContextProvider } from "./PropertySelectorContextProvider";
import { LegalEntityContextProvider } from "./LegalEntityContextProvider";
import { CommsContextProviderFromProperty } from "./CommsContextProviderFromProperty";

const SET_USER_EMAIL_IN_ERROR_CONTEXT = !staticEnvironment.isProd;

// I'm only 99.9% sure that everything works well in here,
// and I don't want to break prod on such an important piece of code,
// so we'll keep previous behaviour for a moment on prod
// - instead of showing an alert screen, we'll just logout the user.
const FORCE_LOGOUT_ON_SETUP_DATA_FETCH_ERROR = staticEnvironment.isProd;

function AppDataSetup({
  children
}: {
  children: React.ReactElement;
}): React.ReactElement {
  const notificationsEnabled = Platform.OS !== "web";
  const { registerPushNotificationToken, grantPushNotificationPermission } =
    useSetupNotifications();

  const onboardingEnabled = useOnboardingFlag();

  const { isAuthenticated, logout } = useAuth();

  const { result: setupResult, awaitingGlobalStateSetupDataSet } =
    useSetupData();
  const logSetupResultError = useLogApolloResultFailed({
    operationName: "getSetupData"
  });

  if (SET_USER_EMAIL_IN_ERROR_CONTEXT) {
    // Ignoring the rule because this hook can be conditional,
    // as `ENV` won't be changed during the runtime
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSetCurrentUserEmailInErrorContext({ isAuthenticated });
  }

  useEffect(() => {
    if (!notificationsEnabled || onboardingEnabled === undefined) return;

    if (onboardingEnabled) {
      registerPushNotificationToken();
    } else {
      grantPushNotificationPermission().then(registerPushNotificationToken);
    }
  }, [
    grantPushNotificationPermission,
    notificationsEnabled,
    onboardingEnabled,
    registerPushNotificationToken
  ]);

  const loading = isAuthenticated
    ? (didQueryNotLoadYet(setupResult) && awaitingGlobalStateSetupDataSet) ||
      setupResult.networkStatus === NetworkStatus.refetch
    : false;

  const setupResultFailed = !loading && didQueryFail(setupResult);

  useEffect(() => {
    if (setupResultFailed && FORCE_LOGOUT_ON_SETUP_DATA_FETCH_ERROR) {
      logout({ afterLoginRedirectToCurrentUrl: true });
      return;
    }
  }, [setupResultFailed, logout]);

  if (
    loading ||
    (setupResultFailed && FORCE_LOGOUT_ON_SETUP_DATA_FETCH_ERROR)
  ) {
    return <LoadingSplashPage />;
  }

  if (setupResultFailed) {
    logSetupResultError(setupResult);
    // TODO ask designers if they want a better design for this
    return (
      <AlertScreen
        variant={"large"}
        title={"There's a problem\nloading this page"}
        description={
          "We're sorry, there was a technical problem loading this page. Try reloading this page, or contact Ailo support if the problem persists."
        }
        inlineButton={{
          label: "Retry",
          onPress: (): void => {
            // TODO if 401 error, logout instead
            setupResult.refetch();
          }
        }}
      />
    );
  }

  if (!setupResult.data) {
    return children;
  }

  return (
    <BottomSheetSetup>
      <PropertySelectorContextProvider
        setupData={setupResult.data}
        renderLoading={(): React.ReactElement => {
          return <LoadingSplashPage />;
        }}
      >
        <OnboardingContextProvider
          renderLoading={(): React.ReactElement => {
            return <LoadingSplashPage />;
          }}
        >
          <LegalEntityContextProvider setupData={setupResult.data}>
            <SharedNavigationSetup>
              <ContactCardSetup>
                <FileCarouselSetup>
                  <ChatSubscriptionContextProvider>
                    <CommsContextProviderFromProperty>
                      {children}
                    </CommsContextProviderFromProperty>
                  </ChatSubscriptionContextProvider>
                </FileCarouselSetup>
              </ContactCardSetup>
            </SharedNavigationSetup>
          </LegalEntityContextProvider>
        </OnboardingContextProvider>
      </PropertySelectorContextProvider>
    </BottomSheetSetup>
  );
}

export { AppDataSetup };
