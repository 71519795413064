import React from "react";
import { ViewStyle } from "react-native";
import { StyleProps } from "@ailo/primitives";
import { Card, ErrorAlertScreen } from "@ailo/ui";

interface Props {
  refetch?(): void;
  style?: StyleProps<ViewStyle>;
}

export function ErrorRentHistoryCard({
  refetch,
  style
}: Props): React.ReactElement {
  return (
    <Card style={style}>
      <ErrorAlertScreen
        variant={"medium"}
        title={"There was a problem loading\nRent History"}
        onRetry={refetch}
        style={{ paddingVertical: 40 }}
      />
    </Card>
  );
}
