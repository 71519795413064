import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import {
  ScreenComponent,
  Screens,
  useNavCloseButton,
  useRoute
} from "local/common";
import React, { FC } from "react";
import styled from "styled-components/native";
import {
  AddBankAccountListItem,
  AddCreditDebitCardListItem,
  AddOtherListItem
} from "./components";

const SetupPaymentMethodScreen: FC = () => {
  useNavCloseButton();
  const route = useRoute<Screens.SetupPaymentMethod>();
  const { showOtherPaymentOptions } = route.params;
  const enableFeeFreeRent = useHasFeature(PlatformFeatureId.FeeFreeRent);

  return (
    <Container>
      <AddCreditDebitCardListItem />
      <AddBankAccountListItem />
      {showOtherPaymentOptions && enableFeeFreeRent && <AddOtherListItem />}
    </Container>
  );
};

const Container = styled(ScreenComponent)`
  padding-top: 20px;
`;

export { SetupPaymentMethodScreen };
