import React from "react";
import { Screens, AppNavigatorStack } from "local/common";
import { WalletOverviewScreen } from "./WalletOverviewScreen";

export function getWalletScreens(Stack: AppNavigatorStack): React.ReactElement {
  return (
    <>
      <Stack.Screen
        name={Screens.WalletOverview}
        component={WalletOverviewScreen}
        options={{
          title: "Personal Wallet"
        }}
      />
    </>
  );
}
