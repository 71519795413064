import React, { useState } from "react";
import { SFC, Colors, Text } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { OnboardingTaskId } from "local/graphql";
import { useCompleteOnboardingTask } from "local/domain/onboarding";

interface Props {
  taskToSkip: OnboardingTaskId;
}

const SkipButton: SFC<Props> = ({ style, taskToSkip }) => {
  const completeOnboardingTask = useCompleteOnboardingTask();
  const [disabled, setDisabled] = useState(false);

  const buttonText = "I'll do this later";

  const skipButtonPressed = async (): Promise<void> => {
    setDisabled(true);
    completeOnboardingTask(taskToSkip, {
      skipped: true
    });
  };

  return (
    <Button.Text style={style} onPress={skipButtonPressed} disabled={disabled}>
      <Text.BodyM weight={"medium"} color={Colors.AILO_RED}>
        {buttonText}
      </Text.BodyM>
    </Button.Text>
  );
};

export { SkipButton };
