import { Text } from "@ailo/primitives";
import {
  didMutationFail,
  didMutationSucceed,
  didQueryNotLoadYet,
  didQuerySucceed,
  logApolloResultFailed
} from "@ailo/services";
import {
  Alert as AlertComponent,
  AlertScreen,
  Button,
  EditableListItemWithLeftHeading,
  ErrorAlertScreen,
  ListItemValue,
  Money,
  MoneyInterface,
  Separator,
  SpinnerOverlay,
  StickyBottom
} from "@ailo/ui";
import {
  withScreenComponent,
  useNavigation,
  useRoute,
  Screens
} from "local/common";
import React, { useCallback, useState } from "react";
import { ScrollView, View } from "react-native";
import { ChangePaymentLimitBottomSheet } from "./ChangePaymentLimitBottomSheet";
import {
  SubmitAutoPayLiabilityStatusFormOptions,
  useAutoPayLiabilityStatusForm
} from "./useAutoPayLiabilityStatusForm";
import { EnsureKycVerified } from "local/domain/authz";
import {
  useAutoPayLiabilityStatusDetails,
  useCanModifyAutoPayLiability
} from "local/domain/ledger";
import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";

const ManageAutoPayBillsScreenContent = (): React.ReactElement => {
  const navigation = useNavigation<Screens.ManageAutoPayBills>();

  const params = useRoute<Screens.ManageAutoPayBills>().params;

  const {
    statusResult,
    submitResult,
    submitCalledWith,
    submit: submitForm
  } = useAutoPayLiabilityStatusForm(params?.payerId);

  const {
    fromPaymentMethod,
    isManagementOrManagementFolio,
    isLegalEntity,
    managementAddress
  } = useAutoPayLiabilityStatusDetails(statusResult.data);

  const { canModifyAutoPayLiability } = useCanModifyAutoPayLiability(
    params?.payerId
  );

  async function submit(
    opts: SubmitAutoPayLiabilityStatusFormOptions
  ): Promise<void> {
    await submitForm(opts);
    params?.onSuccess?.();
  }

  const showLoadingOverlay = submitResult.loading;

  const [
    changePaymentLimitBottomSheetOpen,
    setChangePaymentLimitBottomSheetOpen
  ] = useState(false);
  const [paymentLimitToBeSet, setPaymentLimitToBeSet] =
    useState<MoneyInterface>();
  const defaultPaymentLimit = { cents: 40000 * 100 };
  const paymentLimitFromStatusResult = statusResult.data?.enabled
    ? statusResult.data.maximumPaymentAmount
    : undefined;
  const paymentLimit =
    paymentLimitToBeSet ?? paymentLimitFromStatusResult ?? defaultPaymentLimit;
  const paymentLimitFormatted = Money.from(paymentLimit).format();

  const hasEnableBillAutoPayLimit = useHasFeature(
    PlatformFeatureId.EnableBillAutoPayLimit
  );

  const canViewAutoPayLimit =
    hasEnableBillAutoPayLimit ||
    (paymentLimitFromStatusResult &&
      paymentLimitFromStatusResult.cents != defaultPaymentLimit.cents);

  const handleClose = useCallback(() => {
    (params?.onClose || navigation.goBack)();
  }, [navigation, params]);

  if (didMutationSucceed(submitResult)) {
    if (submitCalledWith?.enabled) {
      return (
        <AlertScreen
          title={"Auto Bill Payments Setup"}
          description={
            "Your automatic bill payments have been setup. Bills will now be paid when funds are available in your wallet."
          }
          bottomButtons={[
            {
              label: "Done",
              onPress: handleClose
            }
          ]}
        />
      );
    }

    return (
      <AlertScreen
        title={"Automatic Bill Payments have been cancelled"}
        bottomButtons={[
          {
            label: "Done",
            onPress: handleClose
          }
        ]}
      />
    );
  }

  if (didMutationFail(submitResult)) {
    logApolloResultFailed(submitResult, {
      operationId: "SetupAutoPayBillsScreenContent.submitResult"
    });
    return (
      <ErrorAlertScreen
        variant={"large"}
        title={"There was a problem loading\nSetup Auto Bill Payments"}
        onRetry={(): Promise<void> =>
          submit({
            data: submitCalledWith!,
            skipConfirmAlert: true
          })
        }
      />
    );
  }

  if (didQueryNotLoadYet(statusResult)) {
    return (
      <View
        style={{
          flex: 1
        }}
      >
        <ScrollView>
          <Separator />
          <EditableListItemWithLeftHeading name={"Pay When"} headingWidth={100}>
            <ListItemValue.Loading />
          </EditableListItemWithLeftHeading>
          <EditableListItemWithLeftHeading
            name={"Properties"}
            headingWidth={100}
          >
            <ListItemValue.Loading />
          </EditableListItemWithLeftHeading>
          <EditableListItemWithLeftHeading
            name={"Payment Method"}
            headingWidth={100}
          >
            <ListItemValue.Loading />
          </EditableListItemWithLeftHeading>
          {canViewAutoPayLimit && (
            <EditableListItemWithLeftHeading
              name={"Payment Limit"}
              headingWidth={100}
            >
              <ListItemValue.Loading />
            </EditableListItemWithLeftHeading>
          )}
          <EditableListItemWithLeftHeading
            name={"Bill Payment Range"}
            headingWidth={100}
          >
            <ListItemValue.Loading />
          </EditableListItemWithLeftHeading>
        </ScrollView>
        <StickyBottom.Loading />
      </View>
    );
  }

  if (!didQuerySucceed(statusResult)) {
    logApolloResultFailed(statusResult, {
      operationId: "SetupAutoPayBillsScreenContent.statusResult"
    });
    return (
      <ErrorAlertScreen variant={"large"} onRetry={statusResult.refetch} />
    );
  }

  return (
    <View
      style={{
        flex: 1
      }}
    >
      <ScrollView>
        <Text.DisplayS
          style={{ paddingTop: 24, paddingHorizontal: 16, paddingBottom: 20 }}
        >
          {canModifyAutoPayLiability
            ? "Confirm auto bill payment details"
            : "Auto bill payment details"}
        </Text.DisplayS>
        <Separator />
        <EditableListItemWithLeftHeading name={"Pay When"} headingWidth={100}>
          <ListItemValue value={"Funds are available"} />
        </EditableListItemWithLeftHeading>
        <EditableListItemWithLeftHeading name={"Properties"} headingWidth={100}>
          <ListItemValue
            value={
              fromPaymentMethod || isLegalEntity
                ? "All portfolio properties"
                : managementAddress
            }
          />
        </EditableListItemWithLeftHeading>
        <EditableListItemWithLeftHeading
          name={"Payment Method"}
          headingWidth={100}
        >
          <ListItemValue
            value={
              fromPaymentMethod
                ? "Payment Method"
                : isLegalEntity
                ? "Personal Wallet"
                : isManagementOrManagementFolio
                ? "Property Wallet"
                : "Unknown"
            }
          />
        </EditableListItemWithLeftHeading>
        {canViewAutoPayLimit && (
          <EditableListItemWithLeftHeading
            name={"Bill Payment Limit"}
            onEditClick={
              canModifyAutoPayLiability && hasEnableBillAutoPayLimit
                ? (): void => setChangePaymentLimitBottomSheetOpen(true)
                : undefined
            }
            headingWidth={100}
          >
            <ListItemValue value={paymentLimitFormatted} />
          </EditableListItemWithLeftHeading>
        )}
        <EditableListItemWithLeftHeading
          name={"Bill Payment Range"}
          headingWidth={100}
        >
          <ListItemValue value={"60 days in future"} />
        </EditableListItemWithLeftHeading>
        {canViewAutoPayLimit && (
          <AlertComponent
            type={"info"}
            message={`Bills larger than ${paymentLimitFormatted} will require you to pay manually.`}
            style={{
              margin: 16
            }}
          />
        )}
      </ScrollView>

      {canModifyAutoPayLiability && (
        <StickyBottom>
          {statusResult.data?.enabled && (
            <Button.Secondary
              style={{ marginBottom: 12 }}
              onPress={(): Promise<void> =>
                submit({
                  data: { enabled: false }
                })
              }
            >
              {"Turn off Auto Bill Payments"}
            </Button.Secondary>
          )}
          <Button.Primary
            onPress={(): Promise<void> =>
              submit({
                data: { enabled: true, maximumPaymentAmount: paymentLimit }
              })
            }
          >
            {params?.submitButtonLabel || "Save"}
          </Button.Primary>
        </StickyBottom>
      )}

      {showLoadingOverlay && <SpinnerOverlay />}

      <ChangePaymentLimitBottomSheet
        open={changePaymentLimitBottomSheetOpen}
        initialValue={paymentLimit}
        onSubmit={(value): void => {
          setPaymentLimitToBeSet(value);
          setChangePaymentLimitBottomSheetOpen(false);
        }}
        onClose={(): void => setChangePaymentLimitBottomSheetOpen(false)}
      />
    </View>
  );
};

export const ManageAutoPayBillsScreen = withScreenComponent(
  (): React.ReactElement => {
    return (
      <EnsureKycVerified>
        <ManageAutoPayBillsScreenContent />
      </EnsureKycVerified>
    );
  }
);
