import { Colors, Text } from "@ailo/primitives";
import { Money } from "@ailo/ui";
import React, { FC } from "react";
import { ViewStyle } from "react-native";

interface Props {
  amount: Money;
  style?: ViewStyle;
}

interface LoadingProps {
  style?: ViewStyle;
}

interface Statics {
  Loading: FC<LoadingProps>;
}

const MaxTransferAmount: FC<Props> & Statics = ({ style, amount }) => {
  return (
    <Text.BodyM color={Colors.TEXT.DARK.SECONDARY} style={style}>
      {`Maximum transfer amount: ${amount.format()}`}
    </Text.BodyM>
  );
};

const Loading: FC<LoadingProps> = ({ style }) => (
  <Text.BodyM.Loading style={style} width={160} />
);

MaxTransferAmount.Loading = Loading;

export { MaxTransferAmount };
