import { RightChevronIcon } from "@ailo/primitives";
import { ListItem } from "@ailo/ui";
import { capitalCase } from "capital-case";
import { Screens, useNavigateToThisScreen, useNavigation } from "local/common";
import React, { FC, useCallback } from "react";

interface Props {
  id: string;
  frequency: string;
  unitStreetNumber: string;
  streetName: string;
}

const LiabilityPaymentPlanListItem: FC<Props> = ({
  id,
  frequency,
  unitStreetNumber,
  streetName
}) => {
  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();

  const onPressEdit = useCallback(() => {
    navigation.navigate(Screens.LiabilityPlanEdit, {
      id,
      onSuccess: returnToThisScreen
    });
  }, [id, navigation, returnToThisScreen]);

  return (
    <ListItem
      onPress={onPressEdit}
      header={`${unitStreetNumber} ${streetName}`}
      subHeader={capitalCase(frequency)}
      rightComponent={<RightChevronIcon width={20} height={20} />}
    />
  );
};

export { LiabilityPaymentPlanListItem };
