import {
  useCurrentLegalEntity,
  usePropertySelectorContext
} from "local/common";
import { useGetAutoWithdrawPlansForOwnerQuery } from "local/graphql";

const useCurrentPropertyWalletData = (): {
  result: ReturnType<typeof useGetAutoWithdrawPlansForOwnerQuery>;
  enabledAlready: boolean;
} => {
  const { currentProperty } = usePropertySelectorContext();
  const [legalEntity] = useCurrentLegalEntity();

  if (!currentProperty?.management?.id) {
    throw new Error("No current management id");
  }

  const result = useGetAutoWithdrawPlansForOwnerQuery({
    variables: {
      walletOwnerReference: currentProperty?.management?.id.toString(),
      currentUserLegalEntity: legalEntity.id.toString()
    }
  });

  const enabledAlready =
    !!result?.data?.walletByWalletOwnerReference?.autoWithdrawPlans?.items?.find(
      (p) => p.enabled
    );

  return {
    result,
    enabledAlready
  };
};

export { useCurrentPropertyWalletData };
