import React, { ReactElement, useEffect, useMemo } from "react";
import { useFoundOrNewChat } from "@ailo/domains";
import { usePropertySelectorContext } from "local/common";
import { useNewChatParticipants } from "./useNewChatParticipants";
import { NewChatParticipantsContentLoading } from "./NewChatParticipantsContentLoading";
import { NewChatParticipantsList } from "./NewChatParticipantsList";
import { NewChatParticipantsFooter } from "./NewChatParticipantsFooter";

export const NewChatParticipantsContent = React.memo(
  function NewChatParticipantsContent(): ReactElement {
    const { currentProperty } = usePropertySelectorContext();

    const currentAgency = useMemo(() => {
      if (!currentProperty) {
        throw new Error(
          "shouldn't be on new chat screen without a property selected"
        );
      }
      return currentProperty.agency;
    }, [currentProperty]);

    const newChatParticipants = useNewChatParticipants({ currentProperty });

    const {
      chatAilorn,
      selectParticipants,
      loading: loadingFoundOrNewChat
    } = useFoundOrNewChat({
      currentAgencyOrg: currentAgency.organisationAilorn
    });

    useEffect(() => {
      selectParticipants(newChatParticipants.selectedContacts, []);
    }, [selectParticipants, newChatParticipants.selectedContacts]);

    if (newChatParticipants.loading) {
      return <NewChatParticipantsContentLoading />;
    }

    return (
      <>
        <NewChatParticipantsList
          currentAgency={currentAgency}
          newChatParticipants={newChatParticipants}
        />
        <NewChatParticipantsFooter
          chatAilorn={chatAilorn}
          currentAgency={currentAgency}
          newChatParticipants={newChatParticipants}
          loadingFoundOrNewChat={loadingFoundOrNewChat}
        />
      </>
    );
  }
);
