import React, { ReactNode, FC, useCallback, useState, useEffect } from "react";
import { useNavigation, Screens, useRoute } from "local/common";
import {
  ScreenContainer,
  Header
} from "local/domain/onboarding/phoneVerification";
import { CommonActions } from "@react-navigation/native";
import { VerificationCodeForm, VerificationCodeFormContext } from "./forms";
import { SpinnerOverlay } from "@ailo/ui";

export const CodeVerificationScreen: FC = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const { phoneNumber } = useRoute<Screens.CodeVerification>().params;

  const HeaderTitle = (): ReactNode => {
    return <Header />;
  };

  useEffect(() => {
    navigation.setOptions({
      title: "",
      headerRight: HeaderTitle
    });
  }, [navigation]);

  const navigateToSuccessScreen = useCallback((): void => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: Screens.YourAccountIsSecure }]
      })
    );
  }, [navigation]);

  const toggleOverlay = useCallback((state: boolean): void => {
    setLoading(state);
  }, []);

  return (
    <>
      <ScreenContainer>
        <VerificationCodeFormContext>
          <VerificationCodeForm
            phoneNumber={phoneNumber}
            onSuccess={navigateToSuccessScreen}
            onLoadingStateChange={toggleOverlay}
          />
        </VerificationCodeFormContext>
      </ScreenContainer>
      {loading && <SpinnerOverlay />}
    </>
  );
};
