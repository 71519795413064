import React from "react";
import { Screens, AppNavigatorStack } from "local/common";
import { ChangePaymentMethodScreen } from "./ChangePaymentMethodScreen";
import { ChangeBankAccountScreen } from "./ChangeBankAccountScreen";
import { OtherPaymentMethodsScreen } from "./OtherPaymentMethodsScreen";

const getChangePaymentMethodScreens = (
  Stack: AppNavigatorStack
): React.ReactElement => {
  return (
    <>
      <Stack.Screen
        name={Screens.ChangePaymentMethod}
        component={ChangePaymentMethodScreen}
      />
      <Stack.Screen
        name={Screens.ChangeBankAccount}
        component={ChangeBankAccountScreen}
      />
      <Stack.Screen
        name={Screens.OtherPaymentMethodsScreen}
        component={OtherPaymentMethodsScreen}
      />
    </>
  );
};

export { getChangePaymentMethodScreens };
