import React, { FC } from "react";
import styled from "styled-components/native";
import { OnboardingTaskId } from "local/graphql";
import { useCompleteOnboardingTask } from "local/domain/onboarding";
import { SetupAutoPayRentButton } from "local/domain/liabilityPaymentPlan";

const SetupButton: FC = () => {
  const completeOnboardingTask = useCompleteOnboardingTask();
  const onSetupComplete = (): void => {
    completeOnboardingTask(OnboardingTaskId.SetupAutoRentPayment);
  };
  return (
    <StyledButton
      showPrimaryStyle={true}
      onSuccess={onSetupComplete}
      type={"large"}
    />
  );
};

const StyledButton = styled(SetupAutoPayRentButton)`
  margin-left: 40px;
  margin-right: 40px;
`;

export { SetupButton };
