import { FeeInfo, FeeUIHelpers, useHasFeature } from "@ailo/domains";
import { toCents } from "local/domain/ledger";
import { Money, PlatformFeatureId } from "local/graphql";
import { UseFormMethods, useForm } from "react-hook-form";
import { useCallback } from "react";
import { FormData } from "./components";
import { formatMoney } from "@ailo/ui";

export interface Amounts {
  amountWithFees: Money;
  amountWithoutFees: Money;
  amountWithFeesDisplay: string;
  amountWithoutFeesDisplay: string;
}

export interface LiabilityFormContext {
  amounts: Amounts;
  form: UseFormMethods<FormData>;
  handleSubmit: (callback: (amount: Amounts) => void) => () => void;
}

export const usePayLiabilityForm = (
  topUpFee: FeeInfo | undefined,
  defaultPaymentAmount: number
): LiabilityFormContext => {
  const displayPaymentAmount = formatMoney(
    { cents: defaultPaymentAmount },
    { withSign: false }
  );

  const form = useForm<FormData>({
    defaultValues: {
      paymentAmount:
        defaultPaymentAmount > 0 ? displayPaymentAmount.toString() : ""
    },
    mode: "onChange",
    shouldFocusError: true
  });

  const paymentAmount = form.watch("paymentAmount");

  const enableFees =
    useHasFeature(PlatformFeatureId.TransactionFees) && !!topUpFee;
  const amounts = getAmounts(paymentAmount, topUpFee, enableFees);

  const handleSubmit = useCallback(
    (callback: (amount: Amounts) => void) => {
      return form.handleSubmit(
        ({ paymentAmount }: { paymentAmount: string }) => {
          callback(getAmounts(paymentAmount, topUpFee, enableFees));
        }
      );
    },
    [topUpFee, form, enableFees]
  );

  return { form, amounts, handleSubmit };
};

const getAmounts = (
  amount: string,
  topUpFee: FeeInfo | undefined,
  enableFees: boolean
): Amounts => {
  const feeInfo = enableFees ? topUpFee : undefined;
  const feesAppliedCents = FeeUIHelpers.getFeeAppliedAmount(feeInfo, amount);

  const amountWithFees = { cents: feesAppliedCents };
  const amountWithoutFees = { cents: toCents(amount) };
  const amountWithFeesDisplay = FeeUIHelpers.format(feesAppliedCents);
  const amountWithoutFeesDisplay = FeeUIHelpers.format(toCents(amount));

  return {
    amountWithFees,
    amountWithoutFees,
    amountWithFeesDisplay,
    amountWithoutFeesDisplay
  };
};
