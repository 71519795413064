import { BaseSlide } from "local/domain/onboarding";
import React, { FC } from "react";
import { Colors, Welcome0 } from "@ailo/primitives";
import { Dimensions, Image, View } from "react-native";

const TenantWelcomeSlide: FC = () => {
  const imageWidth = calculateImageWidth();
  const imageHeight = imageWidth * 0.67;
  return (
    <BaseSlide
      titleText={"Welcome to Ailo"}
      descriptionText={
        "The easiest way for renters to \ntrack and pay their rent"
      }
      topContainerColor={Colors.WHITE}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignSelf: "flex-start",
          marginLeft: 40,
          marginTop: 64
        }}
      >
        <Image
          source={Welcome0}
          resizeMode={"contain"}
          style={{ width: imageWidth, height: imageHeight }}
        />
      </View>
    </BaseSlide>
  );
};

const calculateImageWidth = (): number => {
  /**
   * This is a linear equation that results in an image width of
   * 305px when the phone is 320px wide, and
   * 420px when the phone is 375px wide.
   */
  return 2.09 * Dimensions.get("window").width - 364;
};

export { TenantWelcomeSlide };
