import { useEffect, useCallback, useState } from "react";
import { useGlobal } from "reactn";
import moment from "moment";

interface Params {
  coolDownTarget: string;
}

type Result = [number, (value: number) => void];

export const useGlobalCoolDown = ({ coolDownTarget }: Params): Result => {
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [globalCooldown, setGlobalCooldown] = useGlobal("cooldown");

  const setCooldown = useCallback(
    (value: number): void => {
      const countdownTimestamp = moment().add(value, "seconds");
      setGlobalCooldown({
        ...globalCooldown,
        [coolDownTarget]: countdownTimestamp.toISOString()
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coolDownTarget, setGlobalCooldown]
  );

  useEffect(() => {
    if (!globalCooldown[coolDownTarget]) return;

    const countdownTimestamp = moment(globalCooldown[coolDownTarget]);
    const secondsFromNow = countdownTimestamp.diff(moment(), "seconds");

    if (secondsFromNow > 0) setSecondsLeft(secondsFromNow);
  }, [coolDownTarget, globalCooldown]);

  useEffect(() => {
    let interval: number | undefined = undefined;
    const cleanup = (): void => {
      clearInterval(interval);
    };

    if (secondsLeft > 0) {
      interval = Number(
        setInterval(() => {
          setSecondsLeft(secondsLeft - 1);
        }, 1000)
      );
    } else {
      cleanup();
    }

    return cleanup;
  }, [secondsLeft]);

  return [secondsLeft, setCooldown];
};
