import {
  didQueryNotLoadYet,
  didQuerySucceed,
  logApolloResultFailed
} from "@ailo/services";
import { alert, ErrorAlertScreen, SpinnerOverlay } from "@ailo/ui";
import {
  Screens,
  useAnalytics,
  useNavCloseButton,
  useNavigation,
  useRoute
} from "local/common";
import {
  useAutoWithdrawPlanById,
  useUpdateAutoWithdrawPlan
} from "local/domain/ledger/autoWithdrawPlan";
import { useCanModifyAutoWithdrawPlan } from "local/domain/ledger/autoWithdrawPlan/hooks";
import { useGetBankAccountPaymentMethodsForUser } from "local/domain/ledger/hooks";
import {
  CreateAutoWithdrawPlanInputV2,
  useCancelAutoWithdrawPlanMutation
} from "local/graphql";
import moment from "moment";
import React, { FC, useCallback } from "react";
import { DetailsEditComponent } from "../../components";
import {
  AutoWithdrawPlanSetupData,
  BankAccountPaymentMethodOption
} from "../../types";

export interface EditDetailsScreenProps {
  id: string;
  onSuccess: () => void;
}

const EditDetailsScreen: FC = () => {
  useNavCloseButton();
  const navigation = useNavigation<Screens.AutoWithdrawPlanEdit>();
  const params = useRoute<Screens.AutoWithdrawPlanEdit>().params;
  const { id, bankPaymentMethods, onSuccess, showLoadingOverlay } = params;

  const analytics = useAnalytics();
  const [cancelMutation] = useCancelAutoWithdrawPlanMutation();
  const updateAutoWithdrawPlan = useUpdateAutoWithdrawPlan();

  const result = useAutoWithdrawPlanById(id);
  const canModify = useCanModifyAutoWithdrawPlan(result?.data);
  const details = result.data?.details;

  const bankPaymentMethodsResult = useGetBankAccountPaymentMethodsForUser();

  const selectedPaymentMethods =
    details?.paymentMethodDestinations.map((destination) => ({
      id: destination.paymentMethodId,
      accountName: destination.accountName,
      accountNumber: destination.accountNumber,
      percentage: destination.percentage,
      selected: true
    })) ?? [];

  const unselectedPaymentMethods = bankPaymentMethodsResult.bankPaymentMethods
    .filter(
      (paymentMethod) =>
        !selectedPaymentMethods.some((pm) => pm.id === paymentMethod.id)
    )
    .map((paymentMethod) => ({
      ...paymentMethod,
      selected: false
    }));

  const initialBankPaymentMethods = [
    ...selectedPaymentMethods,
    ...unselectedPaymentMethods
  ];

  const editDetails: AutoWithdrawPlanSetupData = {
    walletId: result.data?.walletId,
    frequency: details?.frequency,
    startDate: moment(details?.startDate).toDate(),
    setAsideAmount: details?.setAsideAmount
      ? { cents: details?.setAsideAmount.cents }
      : undefined,
    anniversary: details?.anniversary,
    anniversaryDaysOfMonth: details?.anniversaryDaysOfMonth,
    bankPaymentMethods: bankPaymentMethods || initialBankPaymentMethods,
    isLastDayOfTheMonth: details?.isLastDayOfTheMonth,
    nextFireTime: details?.nextFireTime
  };

  const onPressNextOnSelectPaymentDestinationPercentageScreen = useCallback(
    (bankPaymentMethods: BankAccountPaymentMethodOption[]) => {
      navigation.navigate(Screens.AutoWithdrawPlanEdit, {
        ...params,
        bankPaymentMethods
      });
    },
    [navigation, params]
  );

  const showCancelErrorAlert = useCallback((): void => {
    navigation.setParams({ showLoadingOverlay: false });

    alert(
      "Error",
      "We were unable to turn off your Auto Transfer. Please try again and if the problem persists, contact Ailo support",
      [{ text: "Dismiss" }]
    );
  }, [navigation]);

  const showEditErrorAlert = useCallback((): void => {
    navigation.setParams({ showLoadingOverlay: false });

    alert(
      "Error",
      "We were unable to edit your Auto Transfer. Please try again and if the problem persists, contact Ailo support",
      [{ text: "Dismiss" }]
    );
  }, [navigation]);

  const onSaveClick = useCallback(
    async (
      autoWithdrawPlanInput: CreateAutoWithdrawPlanInputV2
    ): Promise<void> => {
      navigation.setParams({ showLoadingOverlay: true });

      const success = await updateAutoWithdrawPlan({
        id,
        ...autoWithdrawPlanInput
      });
      if (success) {
        onSuccess();
      } else {
        showEditErrorAlert();
      }
    },
    [showEditErrorAlert, id, navigation, updateAutoWithdrawPlan, onSuccess]
  );

  const cancelAutoPaymentClick = useCallback((): void => {
    alert(
      "Turn off Auto Transfer?",
      "Are you sure you want to turn off your Auto Transfer?",
      [
        { text: "No", style: "cancel" },
        {
          text: "Turn off",
          style: "destructive",
          onPress: (): void => {
            navigation.setParams({ showLoadingOverlay: true });

            cancelMutation({
              variables: {
                cancelAutoWithdrawInput: {
                  autoWithdrawPlanId: id
                }
              }
            })
              .then(({ data }) => {
                const plan = data?.cancelAutoWithdrawPlan;
                if (plan) {
                  analytics.trackAutoWithdrawPlanChanged({
                    eventName: "Auto Withdraw Disabled",
                    paymentMethodDestinations:
                      plan.details.paymentMethodDestinations
                  });
                  onSuccess();
                } else {
                  showCancelErrorAlert();
                }
              })
              .catch(showCancelErrorAlert);
          }
        }
      ]
    );
  }, [
    cancelMutation,
    id,
    navigation,
    onSuccess,
    showCancelErrorAlert,
    analytics
  ]);

  if (
    didQueryNotLoadYet(result) ||
    didQueryNotLoadYet(bankPaymentMethodsResult)
  )
    return <SpinnerOverlay />;

  if (!didQuerySucceed(result) || !details) {
    logApolloResultFailed(result, {
      operationId: "EditDetailsScreen.result"
    });
    return (
      <ErrorAlertScreen
        variant={"large"}
        title={"There was a problem loading\nSetup Auto Transfers"}
        onRetry={result.refetch}
      />
    );
  }

  if (!didQuerySucceed(bankPaymentMethodsResult)) {
    logApolloResultFailed(bankPaymentMethodsResult, {
      operationId: "EditDetailsScreen.bankPaymentMethodsResult"
    });
    return (
      <ErrorAlertScreen
        variant={"large"}
        title={"There was a problem loading\nSetup Auto Transfers"}
        onRetry={bankPaymentMethodsResult.refetch}
      />
    );
  }

  return (
    <DetailsEditComponent
      {...editDetails}
      canModify={canModify}
      walletOwnerRef={result.data?.wallet.owner.reference}
      showTitle={false}
      mode={"edit"}
      onPressNextOnSelectPaymentDestinationPercentageScreen={
        onPressNextOnSelectPaymentDestinationPercentageScreen
      }
      onSaveClick={onSaveClick}
      primaryAction={
        canModify
          ? {
              text: "Save"
            }
          : undefined
      }
      secondaryAction={
        canModify
          ? {
              text: "Turn off Auto Transfer",
              onClick: cancelAutoPaymentClick
            }
          : undefined
      }
      showLoadingOverlay={showLoadingOverlay}
      modifiedAt={details.createdAt}
      modifiedBy={details?.createdBy}
    />
  );
};

export { EditDetailsScreen };
