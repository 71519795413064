import React, { FC } from "react";
import { TouchableOpacity, View } from "react-native";
import styled from "styled-components/native";
import { Text, Colors, RightChevronIcon } from "@ailo/primitives";

interface Props {
  onPress: () => void;
}

interface Statics {
  Loading: FC;
}

const ViewAllTransactionsButton: FC<Props> & Statics = ({ onPress }) => {
  return (
    <StyledTouchableOpacity onPress={onPress}>
      <Text.BodyM weight={"medium"} color={Colors.AILO_RED}>
        {"View all transactions"}
      </Text.BodyM>
      <RightChevronIcon color={Colors.AILO_RED} width={20} height={20} />
    </StyledTouchableOpacity>
  );
};

const Loading: FC = () => {
  return (
    <StyledView>
      <Text.BodyM.Loading width={200} />
    </StyledView>
  );
};

const StyledTouchableOpacity = styled(TouchableOpacity)`
  flex-direction: row;
  padding: 24px 16px;
  justify-content: space-between;
`;

const StyledView = styled(View)`
  padding: 24px 16px;
`;

ViewAllTransactionsButton.Loading = Loading;

export { ViewAllTransactionsButton };
