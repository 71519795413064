import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { MoneyInterface } from "@ailo/ui";
import { ApolloError, QueryHookOptions } from "@apollo/client";
import { useCurrentLegalEntity } from "local/common";
import { useGetWalletAvailableBalanceQuery } from "local/graphql";

export function useWalletAvailableBalance({
  skip
}: Pick<QueryHookOptions, "skip">): MappedQueryResult<
  MoneyInterface | undefined
> {
  const [legalEntity] = useCurrentLegalEntity();
  const result = useGetWalletAvailableBalanceQuery({
    skip,
    variables: {
      legalEntityId: legalEntity.id.toString()
    }
  });
  return useMappedQueryResult(result, (data) => {
    if (!data.legalEntity) {
      throw new ApolloError({
        errorMessage: "data.legalEntity is empty"
      });
    }

    return data.legalEntity.allWalletsAvailableBalance;
  });
}
