import React from "react";
import { Screens, AppNavigatorStack } from "local/common";
import { BillScreen } from "./BillScreen";

const getBillScreens = (Stack: AppNavigatorStack): React.ReactElement => {
  return (
    <>
      <Stack.Screen name={Screens.Bill} component={BillScreen} />
    </>
  );
};

export { getBillScreens };
