import { Text } from "@ailo/primitives";
import {
  LegacyBottomSheet,
  Button,
  defaultMaxMoney,
  FormField,
  Money,
  MoneyInput,
  MoneyInterface,
  StickyBottom
} from "@ailo/ui";
import React, { ReactElement, useState } from "react";
import { View } from "react-native";

interface Props {
  open?: boolean;
  initialValue: MoneyInterface;
  onClose?(): void;
  onSubmit?(value: MoneyInterface): void;
}

const minLimit = { cents: 100 };
const maxLimit = { cents: defaultMaxMoney.cents };

export function ChangePaymentLimitBottomSheet({
  open,
  initialValue,
  onSubmit,
  onClose
}: Props): ReactElement {
  const [value, setValue] = useState(initialValue);
  const error =
    value.cents < minLimit.cents
      ? `Payment limit can't be less than ${Money.from(minLimit).format()}.`
      : value.cents > maxLimit.cents
      ? `Payment limit can't be more than ${Money.from(maxLimit).format()}.`
      : undefined;

  return (
    <LegacyBottomSheet dragHandleHeader={false} open={open} onClose={onClose}>
      <View
        style={{
          paddingHorizontal: 16,
          paddingTop: 32,
          minHeight: 160
        }}
      >
        <Text.DisplayS style={{ marginBottom: 16 }}>
          {"Change payment limit"}
        </Text.DisplayS>
        <FormField error={error}>
          <MoneyInput
            value={value}
            onChange={(value): void => {
              if (!value) setValue(Money.fromCents(0));
              else setValue(value);
            }}
          />
        </FormField>
      </View>
      <StickyBottom style={{ borderTopWidth: 0 }}>
        <Button.Primary
          disabled={!!error}
          onPress={(): void => {
            onSubmit?.(value!);
          }}
        >
          {"Proceed"}
        </Button.Primary>
      </StickyBottom>
    </LegacyBottomSheet>
  );
}
