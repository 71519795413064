import { Colors, opacify, SFC, Text } from "@ailo/primitives";
import { Button, SpinnerOverlay } from "@ailo/ui";
import { useHeaderHeight } from "@react-navigation/stack";
import { ScreenComponent } from "local/common";
import React from "react";
import { KeyboardAvoidingView, Platform, ScrollView, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import styled from "styled-components/native";

export interface Props {
  showLoadingOverlay?: boolean;

  header?: React.ReactNode;
  subHeader?: React.ReactNode;

  backgroundColor?: "white" | "default";
  scrollable?: boolean;

  primaryAction?: {
    text?: string;
    disabled?: boolean;
    onClick?: () => void;
  };

  secondaryAction?: {
    text?: string;
    disabled?: boolean;
    onClick?: () => void;
  };

  buttons?: React.ReactNode[];
  buttonInfo?: string | React.ReactNode;
}

const ScreenWithHeaderAndAction: SFC<Props> = ({
  showLoadingOverlay,
  header,
  subHeader,
  scrollable = true,
  backgroundColor = "white",
  primaryAction,
  secondaryAction,
  children,
  buttons,
  buttonInfo
}) => {
  const isIos = Platform.OS === "ios";
  const headerHeight = useHeaderHeight();
  const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
  const ViewContainer = scrollable ? ScrollParent : View;
  const actionBarEnabled =
    !!primaryAction ||
    !!secondaryAction ||
    (buttons || []).filter(Boolean).length > 0;

  const headerComponent = header && (
    <ScreenHeader style={{ paddingBottom: subHeader ? 8 : 20 }}>
      {header}
    </ScreenHeader>
  );

  const subHeaderComponent = subHeader && (
    <SubHeader
      style={{
        marginTop: header ? 0 : 20,
        color: header ? Colors.TEXT.DARK.SECONDARY : Colors.TEXT.DARK.PRIMARY
      }}
      weight={header ? "book" : "medium"}
    >
      {subHeader}
    </SubHeader>
  );

  const secondaryButton: React.ReactNode = secondaryAction?.text && (
    <Button
      variant={"secondary"}
      disabled={secondaryAction?.disabled}
      onPress={secondaryAction?.onClick}
    >
      {secondaryAction?.text}
    </Button>
  );

  const primaryButton: React.ReactNode = primaryAction?.text && (
    <Button
      variant={"primary"}
      disabled={primaryAction?.disabled}
      onPress={primaryAction?.onClick}
    >
      {primaryAction?.text}
    </Button>
  );

  const allButtons = [secondaryButton, primaryButton]
    .concat(buttons)
    .filter(Boolean);

  const buttonInfoStyle =
    typeof buttonInfo === "string"
      ? { alignItems: "center" as const, justifyContent: "center" as const }
      : undefined;
  const displayButtonInfo = buttonInfo && (
    <ButtonInfo style={buttonInfoStyle}>
      {typeof buttonInfo === "string" ? (
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{buttonInfo}</Text.BodyS>
      ) : (
        buttonInfo
      )}
    </ButtonInfo>
  );

  return (
    <ScreenComponent
      style={{
        flex: 1,
        backgroundColor: backgroundColor === "white" ? "white" : undefined
      }}
    >
      <KeyboardAvoidingView
        behavior={isIos ? "padding" : undefined}
        keyboardVerticalOffset={headerHeight}
        style={{ marginBottom: bottomSafeAreaInset, flex: 1 }}
      >
        <ViewContainer style={{ flex: 1 }}>
          {headerComponent}
          {subHeaderComponent}
          <View style={{ flex: 1 }}>{children}</View>
        </ViewContainer>

        {actionBarEnabled && (
          <ButtonContainer>
            {displayButtonInfo}
            {allButtons.map((button, i) => {
              const marginBottom = i != allButtons.length - 1 ? 12 : 0;
              return (
                <View key={i} style={{ marginBottom }}>
                  {button}
                </View>
              );
            })}
          </ButtonContainer>
        )}
      </KeyboardAvoidingView>
      {showLoadingOverlay && <SpinnerOverlay />}
    </ScreenComponent>
  );
};

const ScrollParent: SFC = ({ children, style }) => {
  return (
    <ScrollView style={style} keyboardShouldPersistTaps={"handled"}>
      {children}
    </ScrollView>
  );
};

const SubHeader = styled(Text.BodyM)`
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
`;

const ButtonContainer = styled(View)`
  padding: 8px 16px;
  border-top-width: 1px;
  background-color: ${Colors.WHITE};
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
`;

const ButtonInfo = styled(View)`
  margin-bottom: 8px;
`;

const ScreenHeader = styled(Text.DisplayS)`
  padding: 24px 16px 20px 16px;
`;

export { ScreenWithHeaderAndAction };
