import { useCurrentLegalEntity } from "local/common";
import { AiloRN } from "@ailo/ailorn";

const useCanModifyAutoWithdrawPlan = (
  plan: { owner: AiloRN } | undefined
): boolean => {
  const [legalEntity] = useCurrentLegalEntity();
  return !!plan?.owner?.equals(legalEntity.id);
};

export { useCanModifyAutoWithdrawPlan };
