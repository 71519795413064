import { RightChevronIcon } from "@ailo/primitives";
import { ListItem } from "@ailo/ui";
import { Screens, useNavigateToThisScreen, useNavigation } from "local/common";
import React, { FC, useCallback } from "react";

interface Props {
  tenancyId: string;
  liabilityId: string;
  paymentMethodId: string;
  unitStreetNumber: string;
  streetName: string;
}

const TenancyListItem: FC<Props> = ({
  tenancyId,
  liabilityId,
  paymentMethodId,
  unitStreetNumber,
  streetName
}) => {
  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();

  const onPressEdit = useCallback(() => {
    navigation.navigate(Screens.EditAutoPayRent, {
      paymentMethodId,
      tenancyId,
      liabilityId,
      onClose: returnToThisScreen,
      onSuccess: returnToThisScreen,
      autoPaymentEnabled: true
    });
  }, [liabilityId, navigation, paymentMethodId, returnToThisScreen, tenancyId]);

  return (
    <ListItem
      onPress={onPressEdit}
      header={`${unitStreetNumber} ${streetName}`}
      subHeader={"Pay rent when my rent is due"}
      rightComponent={<RightChevronIcon width={20} height={20} />}
    />
  );
};

export { TenancyListItem };
