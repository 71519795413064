import { useCallback } from "react";
import { Screens } from "local/common";
import {
  CommonActions,
  NavigationState,
  useNavigation
} from "@react-navigation/native";
import { useOnNavigationCompleted } from "@ailo/domains";

function defaultStateToRootNavigator(
  state: NavigationState
): CommonActions.Action {
  if (state.routes.length !== 1 || state.routes[0].name !== Screens.Threads) {
    return CommonActions.reset(state);
  }

  return CommonActions.reset({
    index: 1,
    routes: [
      {
        name: Screens.TabNavigator,
        state: { routes: [{ name: Screens.ChatTab }] }
      },
      {
        name: state.routes[0].name,
        key: state.routes[0].key,
        params: state.routes[0].params
      }
    ]
  });
}

export function useEnsureThreadScreenCanGoBack(): void {
  const navigation = useNavigation();

  const focusListener = useCallback(() => {
    navigation.dispatch(defaultStateToRootNavigator);
  }, [navigation]);

  useOnNavigationCompleted(focusListener);
}
