import React, { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { TabNavigator } from "../TabNavigator";
import { HeaderBar } from "./HeaderBar";
import { PropertySelectorBottomSheet } from "./PropertySelectorBottomSheet";

export function TabNavigatorScreenContent(): React.ReactElement {
  const [propertySelectorOpen, setPropertySelectorOpen] = useState(false);
  const openPropertySelector = useCallback(() => {
    setPropertySelectorOpen(true);
  }, []);

  return (
    <View style={StyleSheet.absoluteFill} testID={"TabNavigatorScreenContent"}>
      <HeaderBar onPropertySelectorOpen={openPropertySelector} />
      <TabNavigator />
      <PropertySelectorBottomSheet
        open={propertySelectorOpen}
        onClose={(): void => setPropertySelectorOpen(false)}
      />
    </View>
  );
}
