import { didQuerySucceed } from "@ailo/services";
import {
  GetKycVerificationStatusQueryHookResult,
  useGetKycVerificationStatusQuery,
  PlatformFeatureId
} from "local/graphql";
import { useCurrentLegalEntity } from "local/common";
import { useHasFeature } from "@ailo/domains";

export function useKycStatus(): {
  required: boolean;
  missing: boolean | null | undefined;
  result: GetKycVerificationStatusQueryHookResult;
} {
  const [legalEntity] = useCurrentLegalEntity();
  const hasKycCheckFeature = useHasFeature(
    PlatformFeatureId.ConsumerAppKycCheck
  );
  const kycRequired = legalEntity.__typename === "Person" && hasKycCheckFeature;
  const kycResult = useGetKycVerificationStatusQuery({
    skip: !kycRequired
  });
  const kycMissing =
    kycRequired &&
    didQuerySucceed(kycResult) &&
    kycResult.data.actualUser &&
    !kycResult.data.actualUser.person.kycVerified;

  return { required: kycRequired, result: kycResult, missing: kycMissing };
}
