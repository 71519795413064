import React, { FC, useCallback } from "react";
import { ListItem } from "@ailo/ui";
import { Screens, useNavigation, useRoute } from "local/common";
import { Colors, RightChevronIcon, Text } from "@ailo/primitives";
import { AddBankAccountHeader } from "./AddBankAccountHeader";

const SelectBankAccountListItem: FC = () => {
  const { liabilityId, amount } =
    useRoute<Screens.TenancyDepositAddPaymentMethod>().params;
  const navigation = useNavigation<Screens.TenancyDepositAddPaymentMethod>();

  const addBankAccount = useCallback(() => {
    const onSuccess = (paymentMethodId: string): void => {
      navigation.navigate(Screens.TenancyDepositConfirmPayment, {
        liabilityId,
        amount,
        paymentMethodId
      });
    };
    navigation.navigate(Screens.AddOnceOffBankAccount, {
      onSuccess,
      options: {
        title: "Enter Bank Account Details",
        header: AddBankAccountHeader(),
        background: Colors.WHITE
      }
    });
  }, [navigation, liabilityId, amount]);

  return (
    <ListItem
      header={"Bank Account Payment"}
      subHeader={
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY} weight={"book"}>
          {"1-2 business days to clear"}
        </Text.BodyS>
      }
      testID={"bank-account-list-item"}
      onPress={addBankAccount}
      style={{ alignItems: "center" }}
      rightComponent={<RightChevronIcon width={20} height={20} />}
    />
  );
};

export { SelectBankAccountListItem };
