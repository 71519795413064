import React, { ReactNode } from "react";
import { Colors, opacify, RightChevronIcon, Text } from "@ailo/primitives";
import { View } from "react-native";
import { Button } from "@ailo/ui";

interface Props {
  heading: string;
  body: string;
  button: {
    text: string;
    onPress: () => void;
  };
  underline?: boolean;
  image?: ReactNode;
}
export const GetToKnowItem: React.FC<Props> = ({
  heading,
  body,
  button,
  image,
  underline = true
}) => {
  return (
    <View
      style={{
        paddingTop: 84,
        paddingBottom: 56,
        marginLeft: 40,
        marginRight: 24,
        ...(underline
          ? {
              borderBottomWidth: 2,
              borderBottomColor: opacify(Colors.SPACE_BLACK, 0.1)
            }
          : {})
      }}
    >
      <Text.DisplayM weight={"medium"} style={{ marginBottom: 32 }}>
        {heading}
      </Text.DisplayM>

      {image}

      <View
        style={{
          paddingBottom: 32,
          marginTop: 32,
          marginBottom: 24,
          borderBottomWidth: 1,
          borderBottomColor: opacify(Colors.SPACE_BLACK, 0.1)
        }}
      >
        <Text.BodyL color={Colors.TEXT.DARK.SECONDARY}>{body}</Text.BodyL>
      </View>

      <Button.Text
        style={{ alignSelf: "stretch", justifyContent: "space-between" }}
        rightIcon={RightChevronIcon}
        onPress={button.onPress}
      >
        {button.text}
      </Button.Text>
    </View>
  );
};
