import React from "react";
import {
  AFC,
  WelcomeInvestor1,
  WelcomeInvestor2,
  WelcomeInvestor3
} from "@ailo/primitives";
import { InvestorWelcomeSlide } from "./components";
import { FeatureShowcaseSlide } from "local/domain/onboarding";
import { BaseWelcomeScreen } from "./BaseWelcomeScreen";

const InvestorWelcomeScreen: AFC = () => {
  return (
    <BaseWelcomeScreen>
      <InvestorWelcomeSlide />
      <FeatureShowcaseSlide
        titleText={"Track your cash flow"}
        descriptionText={
          "Unlock real-time visibility into your rental income, bills and fees."
        }
        imageSource={WelcomeInvestor1}
      />
      <FeatureShowcaseSlide
        titleText={"Resolve issues quickly"}
        descriptionText={
          "Chat directly with your property managers to get answers quickly and easily."
        }
        imageSource={WelcomeInvestor2}
      />
      <FeatureShowcaseSlide
        titleText={"Access your rent sooner"}
        descriptionText={
          "Access your income when rent is paid, so you can put your money to work sooner than before."
        }
        imageSource={WelcomeInvestor3}
      />
    </BaseWelcomeScreen>
  );
};

export { InvestorWelcomeScreen };
