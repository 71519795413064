import React from "react";
import styled from "styled-components/native";
import { Colors } from "@ailo/primitives";
import {
  useRoute,
  Screens,
  ScreenComponent,
  useNavCloseButton
} from "local/common";
import { InvestorRentHistoryList } from "local/domain/ledger";

export function InvestorRentHistoryScreen(): React.ReactElement {
  useNavCloseButton();

  const { managementId, tenancyId } =
    useRoute<Screens.InvestorRentHistory>().params;

  return (
    <Container>
      <InvestorRentHistoryList
        managementId={managementId}
        tenancyId={tenancyId}
        hasFooter={true}
        lastPaymentListItemBorderType={"none"}
        showRentBalanceHeader={true}
        style={{ backgroundColor: "transparent" }}
      />
    </Container>
  );
}

const Container = styled(ScreenComponent)`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.WHITE};
`;
