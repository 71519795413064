import { AiloRN } from "@ailo/ailorn";
import { WalletCard } from "@ailo/domains";
import { Colors } from "@ailo/primitives";
import {
  didQueryNotLoadYet,
  useNavigateToThisScreen,
  useOnFocus
} from "@ailo/services";
import { ExtendableHeaderList, Money, Separator } from "@ailo/ui";
import {
  useCurrentLegalEntity,
  usePropertySelectorContext
} from "local/common";
import { useCanModifyAutoWithdrawPlan } from "local/domain/ledger/autoWithdrawPlan/hooks";
import {
  useGetBankAccountPaymentMethodsForUser,
  useGetDefaultPaymentMethodIdsForUser
} from "local/domain/ledger/hooks";
import React, { FC } from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useShowTransferOrAutoTransferScreen } from "../../../modals/transferFunds/hooks";
import { ChangeAutoWithdrawPlanDisplay } from "../../../modals/wallet/components";
import { TransactionsCard } from "../../investorTransactions/InvestorTransactionsTabScreen/TransactionsCard";
import { useGetPropertyWalletWithAutoWithdrawInfoQuery } from "local/graphql";
import { SetupAutoTransferButton } from "./SetupAutoTransferButton";

export function PropertyWalletTabScreen(): React.ReactElement | null {
  const { currentProperty } = usePropertySelectorContext();
  const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
  const [currentLegalEntity] = useCurrentLegalEntity();
  const returnToThisScreen = useNavigateToThisScreen();

  let sharesOwnedRatio;
  if (currentProperty?.__typename === "OwnedProperty") {
    sharesOwnedRatio = currentProperty.ownership.sharesOwnedRatio;
  }

  const walletResult = useGetPropertyWalletWithAutoWithdrawInfoQuery({
    variables: {
      walletOwnerReference: currentProperty!.management.id.toString(),
      currentUserLegalEntity: currentLegalEntity.id.toString()
    }
  });

  const { data: walletData } = walletResult;
  const defaultPaymentMethodResult = useGetDefaultPaymentMethodIdsForUser();
  const bankPaymentMethodsResult = useGetBankAccountPaymentMethodsForUser();

  useOnFocus(walletResult?.refetch);
  useOnFocus(defaultPaymentMethodResult?.refetch);

  const defaultBankAccountId =
    defaultPaymentMethodResult?.defaultOutPaymentMethodId;

  const bankPaymentMethods = bankPaymentMethodsResult.bankPaymentMethods;

  const loading =
    didQueryNotLoadYet(walletResult) ||
    didQueryNotLoadYet(defaultPaymentMethodResult) ||
    didQueryNotLoadYet(bankPaymentMethodsResult);

  const data = walletData?.walletByWalletOwnerReference;

  const outstandingFeeAmount = Money.fromCents(
    data?.owner?.dueFeeAmount.cents || 0
  );
  const availableBalance = Money.fromCents(data?.availableBalance?.cents || 0);

  const transferDisabled =
    availableBalance.cents <= 0
      ? true
      : availableBalance.cents <= outstandingFeeAmount.cents
      ? `Transfers have been temporarily disabled because there is ${outstandingFeeAmount.format()} in outstanding agency fees`
      : (sharesOwnedRatio || 0) !== 1
      ? true
      : false;

  const { startTransferNowFlow, startSetupTransferPlanFlow } =
    useShowTransferOrAutoTransferScreen({
      wallet: data,
      onClose: returnToThisScreen,
      onSuccess: returnToThisScreen,
      defaultBankAccountId,
      bankPaymentMethods: bankPaymentMethods,
      transferNowEnabled: !transferDisabled,
      fromPropertyWallet: true
    });

  const autoWithdrawPlans =
    data?.autoWithdrawPlans?.items
      ?.map((i) => ({
        id: i.id,
        enabled: i.enabled,
        owner: AiloRN.fromString(i.owner)
      }))
      ?.filter(({ enabled }) => enabled) || [];

  const autoWithdrawItems = autoWithdrawPlans.length ? (
    <>
      <Separator style={{ marginTop: 16, marginLeft: 0 }} />
      {autoWithdrawPlans.map((p) => (
        <ChangeOrViewAutoWithdrawPlanDisplay {...p} key={p.id} />
      ))}
    </>
  ) : (
    sharesOwnedRatio === 1 && (
      <>
        <Separator style={{ marginTop: 16, marginLeft: 0 }} />
        <SetupAutoTransferButton
          onPress={startSetupTransferPlanFlow}
          style={{ marginTop: 16, paddingVertical: 0, paddingHorizontal: 0 }}
        />
      </>
    )
  );
  return (
    <ExtendableHeaderList
      headerBackgroundColor={Colors.CHARCOAL}
      headerInitialHeight={64}
    >
      <View style={{ marginTop: -48, marginHorizontal: 0, marginBottom: 8 }}>
        {loading ? (
          <WalletCard.Loading transferButtonLocation={"upper-right"} />
        ) : (
          <WalletCard
            onTransfer={startTransferNowFlow}
            transferDisabled={transferDisabled}
            transferButtonVisible={true}
            transferButtonLocation={"upper-right"}
            availableBalance={data?.availableBalance}
            totalBalance={data?.totalBalance}
          >
            {autoWithdrawItems}
          </WalletCard>
        )}
      </View>
      <TransactionsCard
        style={{ marginBottom: 32 + bottomSafeAreaInset }}
        ownerRef={currentProperty!.management.id.toString()}
      />
    </ExtendableHeaderList>
  );
}

const ChangeOrViewAutoWithdrawPlanDisplay: FC<{
  id: string;
  enabled: boolean;
  owner: AiloRN;
}> = (props) => {
  const canModify = useCanModifyAutoWithdrawPlan(props);
  return (
    <ChangeAutoWithdrawPlanDisplay
      key={props.id}
      textSize={"medium"}
      canModify={canModify}
      autoWithdrawPlanId={props.id}
      style={{
        paddingTop: 16,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        alignItems: "flex-start"
      }}
    />
  );
};
