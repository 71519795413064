import React, { FC } from "react";
import styled from "styled-components/native";
import { Text, Colors } from "@ailo/primitives";

const MyTransactionsHeader: FC = () => {
  return <Header color={Colors.TEXT.DARK.SECONDARY}>{"Transactions"}</Header>;
};

const Header = styled(Text.BodyM)`
  padding: 20px 16px 16px 16px;
`;

export { MyTransactionsHeader };
