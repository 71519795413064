import { useHasBeenFocused, withScreenComponent } from "@ailo/services";
import { Screens, useNavCloseButton, useRoute } from "local/common";
import React, { useMemo } from "react";
import { ScrollView } from "react-native";
import styled from "styled-components/native";
import { MyWallets } from "./MyWallets";
import { WalletsSummaryCard } from "./WalletsSummaryCard";

export const WalletOverviewScreen = withScreenComponent(
  (): React.ReactElement => {
    const { params } = useRoute<Screens.WalletOverview>();

    const showClosedButtonBoolean = !!params?.showCloseButton;
    const hasBeenFocused = useHasBeenFocused();

    useNavCloseButton({
      disabled: showClosedButtonBoolean
    });

    const screenContent = useMemo(() => {
      if (!hasBeenFocused) {
        return (
          <>
            <WalletsSummaryCard.Loading />
            <MyWallets.Loading style={myWalletsStyle} />
          </>
        );
      }
      return (
        <>
          <WalletsSummaryCard />
          <MyWallets style={myWalletsStyle} />
        </>
      );
    }, [hasBeenFocused]);

    return <Container>{screenContent}</Container>;
  }
);

const myWalletsStyle = { marginTop: 4, marginBottom: 32, flex: 1 };

const Container = styled(ScrollView)`
  flex: 1;
  padding-top: 12px;
`;
