import { useGetAutoPayTenancyDetailsQuery } from "local/graphql";

interface Props {
  tenancyId: string;
}

interface ReturnProps {
  loading: boolean;
  address: string | undefined;
  nextChargeAmount: number | undefined;
}

export const useGetAutoPayTenancyDetails = ({
  tenancyId
}: Props): ReturnProps => {
  const { loading, data } = useGetAutoPayTenancyDetailsQuery({
    variables: { tenancyId }
  });

  const optionalAddress = data?.tenancy?.property?.address;
  const address = optionalAddress
    ? [optionalAddress?.unitStreetNumber, optionalAddress?.streetName]
        .filter(Boolean)
        .join(" ")
    : undefined;

  const nextChargeAmount = data?.tenancy?.liability?.nextChargeAmount?.cents;

  return { loading, address, nextChargeAmount };
};
