import { Colors, Text } from "@ailo/primitives";
import React, { FC } from "react";
import styled from "styled-components/native";
import { Alert } from "@ailo/ui";
import { useEnvironment } from "@ailo/services";
import { useOpenAuthorizedHelpInBrowser } from "@ailo/domains";
import { useUserOnboardedRecently } from "local/domain/onboarding";

const PayRentOffAppPaymentInfoBox: FC = () => {
  const { RENT_PAYMENT_HELP_URL } = useEnvironment();
  const openHelpInBrowser = useOpenAuthorizedHelpInBrowser(
    RENT_PAYMENT_HELP_URL
  );

  const { onboardedRecently, loading } = useUserOnboardedRecently();

  if (loading || !onboardedRecently) {
    return null;
  }

  return (
    <StyledAlert type={"info"}>
      <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
        {
          "Please review your rent due balance and ensure it factors in any payments you've made outside of Ailo in the past 7 days. "
        }
        <Text.BodyM
          color={Colors.AILO_RED}
          weight={"medium"}
          onPress={openHelpInBrowser}
        >
          {"Learn more."}
        </Text.BodyM>
      </Text.BodyM>
    </StyledAlert>
  );
};

const StyledAlert = styled(Alert)`
  margin: 20px;
`;

export { PayRentOffAppPaymentInfoBox };
