import { SFC } from "@ailo/primitives";
import { Screens, useNavNoButton, useRoute } from "local/common";
import React from "react";
import {
  ScreenWithHeaderAndAction,
  SuccessAlertMessage
} from "local/domain/liabilityPaymentPlan";

const AutoPayRentSuccessScreen: SFC = () => {
  const { onClose } = useRoute<Screens.SetupAutoPayRentSuccess>().params;
  useNavNoButton();

  return (
    <ScreenWithHeaderAndAction
      primaryAction={{
        text: "Close",
        onClick: onClose
      }}
      scrollable={false}
    >
      <SuccessAlertMessage
        style={{ flex: 1 }}
        header={"Auto Rent Payments Setup"}
        summary={
          "Your rent will now be paid automatically whenever rent is due."
        }
      />
    </ScreenWithHeaderAndAction>
  );
};

export { AutoPayRentSuccessScreen };
