import { filterBankAccountPaymentMethods, maskNumber } from "@ailo/domains";
import { ApolloQueryResult, NetworkStatus } from "@apollo/client";
import { useCurrentLegalEntity } from "local/common";
import {
  BankAccount,
  GetPaymentMethodIdsForUserQuery,
  GetPaymentMethodIdsForUserQueryVariables,
  useGetPaymentMethodIdsForUserQuery
} from "local/graphql";

export type BankAccountPaymentMethod = Pick<
  BankAccount,
  "id" | "accountName" | "accountNumber"
>;

interface BankAccountPaymentMethodsForUser {
  loading: boolean;
  data: GetPaymentMethodIdsForUserQuery | undefined;
  refetch: (
    variables?: GetPaymentMethodIdsForUserQueryVariables | undefined
  ) => Promise<ApolloQueryResult<GetPaymentMethodIdsForUserQuery>>;
  networkStatus: NetworkStatus;
  bankPaymentMethods: BankAccountPaymentMethod[];
  legalEntityAilorn: string | undefined;
}

export const useGetBankAccountPaymentMethodsForUser =
  (): BankAccountPaymentMethodsForUser => {
    const [legalEntity] = useCurrentLegalEntity();
    const { loading, data, refetch, networkStatus } =
      useGetPaymentMethodIdsForUserQuery({
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables: {
          legalEntityId: legalEntity.id.toString()
        }
      });
    const legalEntityData = data?.legalEntity;
    const legalEntityAilorn = legalEntityData?.ailorn;
    const paymentMethods = legalEntityData?.paymentMethods?.items || [];

    const bankPaymentMethods = filterBankAccountPaymentMethods(paymentMethods)
      .map((paymentMethod) => ({
        id: paymentMethod.id,
        accountName: paymentMethod.accountName,
        accountNumber: maskNumber(paymentMethod.accountNumber)
      }))
      .sort((a, b) => (a.accountName ?? "").localeCompare(b.accountName ?? ""));

    return {
      loading,
      data,
      refetch,
      networkStatus,
      bankPaymentMethods,
      legalEntityAilorn
    };
  };
