import { Button } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import { BankAccountPaymentMethod } from "local/domain/ledger/hooks";
import React, { ReactElement } from "react";
import { ViewStyle } from "react-native";

interface AddBankAccountButtonProps {
  onSuccess: (paymentMethod: BankAccountPaymentMethod) => void;
  style?: ViewStyle;
}

export function AddBankAccountButton({
  onSuccess,
  style
}: AddBankAccountButtonProps): ReactElement {
  const navigation = useNavigation<Screens.SetupPaymentMethod>();
  const onPress = (): void => {
    navigation.navigate(Screens.AddBankAccount, {
      onSuccess
    });
  };

  return (
    <Button variant={"secondary"} onPress={onPress} style={style}>
      {"Add Bank Account"}
    </Button>
  );
}
