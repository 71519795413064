import {
  ScreenComponent,
  Screens,
  useNavigation,
  useRoute
} from "local/common";
import React, { FC } from "react";
import {
  SelectedProperty,
  SelectPropertyScreenComponent
} from "./components/SelectPropertyScreenComponent";
import { useGetDefaultPaymentMethodIdsForUser } from "local/domain/ledger/hooks";
import { AddPaymentMethodInfo } from "../../components/AddPaymentMethodInfo";
import { SpinnerOverlay } from "@ailo/ui";
import { useOnFocus } from "@ailo/services";

const SelectLiabilityAutoPropertyScreen: FC = () => {
  const nav = useNavigation<Screens.LiabilityPlanAutoPaySelectProperty>();
  const params = useRoute<Screens.LiabilityPlanAutoPaySelectProperty>().params;
  const { defaultInPaymentMethodId, loading, refetch } =
    useGetDefaultPaymentMethodIdsForUser();
  const { onClose, onSuccess } = params;
  const noOp = (): void => {};

  useOnFocus(refetch);

  const onPaymentMethodAdded = (): void => {
    nav.navigate(Screens.LiabilityPlanAutoPaySelectProperty, params);
  };

  if (loading)
    return (
      <ScreenComponent>
        <SpinnerOverlay />
      </ScreenComponent>
    );
  if (!loading && !defaultInPaymentMethodId) {
    return <AddPaymentMethodInfo onPaymentMethodAdded={onPaymentMethodAdded} />;
  }

  const onPropertySelected = (tenancy: SelectedProperty): void => {
    if (tenancy?.autoPaymentDetails?.paymentMethodId) {
      nav.navigate(Screens.EditAutoPayRent, {
        paymentMethodId: tenancy?.autoPaymentDetails?.paymentMethodId,
        tenancyId: tenancy?.id || "",
        liabilityId: tenancy?.liabilityId || "",
        onClose: onClose || noOp,
        onSuccess: onSuccess || noOp,
        autoPaymentEnabled: true
      });
    } else {
      nav.navigate(Screens.SetupAutoPayRent, {
        paymentMethodId: defaultInPaymentMethodId || "",
        tenancyId: tenancy?.id || "",
        liabilityId: tenancy?.liabilityId || "",
        onClose: onClose || noOp,
        onSuccess: onSuccess || noOp,
        autoPaymentEnabled: false
      });
    }
  };

  return (
    <SelectPropertyScreenComponent onPropertySelected={onPropertySelected} />
  );
};

export { SelectLiabilityAutoPropertyScreen };
