import { Screens, useNavigation } from "local/common";
import { CommonActions } from "@react-navigation/native";
import { useEffect } from "react";

export function useRemovePreviousScreenFromNavigationStack(): void {
  const navigation = useNavigation<Screens.TenancyDepositConfirmPayment>();
  useEffect(() => {
    navigation.dispatch((state) => {
      const routes = state.routes.filter(
        (r: any) =>
          ![
            Screens.TenancyDepositEnterCreditDebitCard.valueOf(),
            Screens.AddOnceOffBankAccount.valueOf()
          ].includes(r.name)
      );

      return CommonActions.reset({
        ...state,
        routes,
        index: routes.length - 1
      });
    });
  });
}
