import React from "react";
import { AFC } from "@ailo/primitives";
import { Controller, useFormContext } from "react-hook-form";
import { FormData, inputName } from "./formData";
import { CodeInput } from "local/common";

interface Props {
  onBlur: () => void;
}

const VerificationCodeInput: AFC<Props> = ({ onBlur: parentOnBlur }) => {
  const { control } = useFormContext<FormData>();

  return (
    <Controller
      control={control}
      name={inputName}
      defaultValue={""}
      render={({ onChange, onBlur, value }): React.ReactElement => (
        <CodeInput
          value={value}
          autoFocus
          onChange={onChange}
          onBlur={(): void => {
            onBlur();
            parentOnBlur();
          }}
        />
      )}
    />
  );
};

export { VerificationCodeInput };
