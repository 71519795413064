export const nameValidationRules = {
  minLength: {
    value: 2,
    message: "Name must be at least 2 characters long."
  },
  maxLength: {
    value: 50,
    message: "Name must be no longer than 50 characters."
  },
  pattern: {
    value: /^[\p{L}/p{Pd} \-'’]*$/u,
    message: "Name must contain only letters, spaces, hyphens or apostrophes."
  },
  validate(value: string | null): string | undefined {
    if (!value) {
      return;
    }

    value = value.trim();
    if (value.length < 2) {
      return "Name must be at least 2 characters long.";
    }
    if (value.length > 50) {
      return "Name must be no longer than 50 characters.";
    }
    if (!/^[\p{L}/p{Pd} \-'’]*$/u.test(value)) {
      return "Name must contain only letters, spaces, hyphens or apostrophes.";
    }
  }
};
