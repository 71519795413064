import React from "react";
import styled from "styled-components/native";
import { SFC } from "@ailo/primitives";
import { Button } from "@ailo/ui";

interface Props {
  onPress: () => void;
}

export const ResendButton: SFC<Props> = ({ style, onPress }) => {
  return (
    <StyledButton style={style} onPress={onPress}>
      {"Resend code"}
    </StyledButton>
  );
};

const StyledButton = styled(Button.Text)`
  border-radius: 30px;
  align-items: flex-start;
`;
