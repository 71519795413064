import React from "react";
import { ListItem } from "@ailo/ui";
import { Colors, DeleteIcon, opacify, Skeleton } from "@ailo/primitives";

interface Props {
  onPress?(): void;
}

interface Statics {
  Loading: typeof Loading;
}

const RemoveListItem: React.FC<Props> & Statics = (props) => {
  const { onPress } = props;

  return (
    <ListItem
      header={"Remove"}
      leftComponent={
        <DeleteIcon
          color={opacify(Colors.SPACE_BLACK, 0.6)}
          style={{ marginVertical: 8, marginRight: 24, marginLeft: 8 }}
        />
      }
      style={{ alignItems: "center", borderType: "under-all" }}
      onPress={onPress}
    />
  );
};

const Loading: React.FC = () => {
  return (
    <ListItem
      header={<Skeleton style={{ width: 55, height: 12 }} />}
      leftComponent={
        <Skeleton
          style={{
            width: 24,
            height: 24,
            marginVertical: 8,
            marginLeft: 8,
            marginRight: 26
          }}
        />
      }
      style={{ alignItems: "center", borderType: "under-all" }}
    />
  );
};

RemoveListItem.Loading = Loading;

export { RemoveListItem };
