import { Alert, SelectableCard } from "@ailo/ui";
import { Screens, useNavigation, useRoute } from "local/common";
import { BankAccountPaymentMethod } from "local/domain/ledger/hooks";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import React, { ReactElement, useState } from "react";
import { View } from "react-native";
import { BankAccountPaymentMethodOption } from "../../types";
import { AddBankAccountButton } from "./AddBankAccountButton";
import { initialisePercentages } from "./initialisePercentages";

const MAX_PAYMENT_METHODS = 5;

export function SelectPaymentDestinationsScreen(): ReactElement {
  const navigation = useNavigation<Screens.AutoWithdrawPlanSelectFrequency>();
  const params =
    useRoute<Screens.AutoWithdrawPlanSelectPaymentDestinations>().params;
  const {
    bankPaymentMethods: initialBankPaymentMethods,
    onPressNextOnSelectPaymentDestinationPercentageScreen
  } = params;
  const [bankPaymentMethods, setBankPaymentMethods] = useState<
    BankAccountPaymentMethodOption[]
  >(initialBankPaymentMethods);

  const numberOfSelectedPaymentMethods = bankPaymentMethods.filter(
    (paymentMethod) => paymentMethod.selected
  ).length;

  const onNext = (): void => {
    if (numberOfSelectedPaymentMethods > 1) {
      navigation.navigate(
        Screens.AutoWithdrawPlanSelectPaymentDestinationPercentages,
        {
          ...params,
          bankPaymentMethods
        }
      );
    } else {
      if (onPressNextOnSelectPaymentDestinationPercentageScreen) {
        onPressNextOnSelectPaymentDestinationPercentageScreen(
          bankPaymentMethods
        );
      } else {
        navigation.navigate(Screens.AutoWithdrawPlanSelectFrequency, {
          ...params,
          bankPaymentMethods
        });
      }
    }
  };

  const setBankPaymentMethodSelected = (
    id: string,
    selected: boolean
  ): void => {
    setBankPaymentMethods(
      initialisePercentages(
        bankPaymentMethods.map((paymentMethod) =>
          paymentMethod.id === id
            ? { ...paymentMethod, selected }
            : paymentMethod
        )
      )
    );
  };

  const onAddBankAccount = (paymentMethod: BankAccountPaymentMethod): void => {
    navigation.goBack();
    setBankPaymentMethods([
      ...bankPaymentMethods,
      {
        ...paymentMethod,
        selected: false
      }
    ]);
  };

  return (
    <ScreenWithHeaderAndAction
      header={"What bank account should funds be deposited into?"}
      subHeader={"You can select more than one"}
      primaryAction={{
        disabled:
          numberOfSelectedPaymentMethods === 0 ||
          numberOfSelectedPaymentMethods > MAX_PAYMENT_METHODS,
        onClick: onNext,
        text: "Next"
      }}
      buttonInfo={
        numberOfSelectedPaymentMethods > MAX_PAYMENT_METHODS ? (
          <Alert
            type={"error"}
            message={`The maximum number of accounts you can setup Auto Transfer for is ${MAX_PAYMENT_METHODS}`}
          />
        ) : undefined
      }
    >
      <View style={{ paddingHorizontal: 16, paddingBottom: 20 }}>
        {bankPaymentMethods.map((bankPaymentMethod, index) => (
          <SelectableCard
            key={index}
            title={bankPaymentMethod.accountName ?? ""}
            subHeader={bankPaymentMethod.accountNumber ?? ""}
            style={{ marginTop: index === 0 ? 0 : 12 }}
            value={!!bankPaymentMethod.selected}
            onChange={(value) => {
              setBankPaymentMethodSelected(bankPaymentMethod.id, value);
            }}
          />
        ))}
        <AddBankAccountButton
          onSuccess={onAddBankAccount}
          style={{ marginTop: 20 }}
        />
      </View>
    </ScreenWithHeaderAndAction>
  );
}
