import React, { FC } from "react";
import { ViewStyle } from "react-native";
import { Button } from "@ailo/ui";
import { useOnFocus } from "local/common";
import { useGetDefaultPaymentMethodIdsForUser } from "local/domain/ledger/hooks";
import { PayRentButton } from "./components";

interface Props {
  style?: ViewStyle;
  liabilityId: string;
  disabled?: boolean;
  tenancyId: string;
  address?: Address | null;
  defaultPaymentAmount: number;
}

interface Address {
  streetName: string;
  unitStreetNumber: string;
}

interface Statics {
  Loading: FC<{ style?: ViewStyle }>;
}

const PayMyRentButton: FC<Props> & Statics = ({
  style,
  tenancyId,
  disabled = false,
  ...rest
}) => {
  const { loading, data, refetch, defaultInPaymentMethodId } =
    useGetDefaultPaymentMethodIdsForUser();
  useOnFocus(refetch);

  if (!data && loading) {
    return <Loading style={style} />;
  }

  return (
    <PayRentButton
      style={style}
      paymentMethodId={defaultInPaymentMethodId}
      tenancyId={tenancyId}
      disabled={disabled}
      {...rest}
    />
  );
};

const Loading: FC<{ style?: ViewStyle }> = ({ style }) => (
  <Button.Loading style={style} />
);
PayMyRentButton.Loading = Loading;

export { PayMyRentButton };
