import React, { FC } from "react";
import { PaddedContainer } from "../../../components";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import {
  Amounts,
  PaymentAmountInput,
  usePayLiabilityForm
} from "../../../../payLiability/PayBillLiabilityScreen/components/PaymentDisplay/components/PaymentAmountInput";
import { PaymentMethodDisplay } from "local/domain/ledger";
import { View } from "react-native";
import { Colors } from "@ailo/primitives";
import { useGetPaymentMethodWithFees } from "local/domain/ledger/hooks";

interface Props {
  defaultAmount: number;
  paymentMethodId: string;
  onNextClick: (amounts: Amounts) => void;
  onPaymentMethodChanged: (id: string) => void;
}

const header = `What amount do you want\nto transfer towards rent?`;

const SelectAmountComponent: FC<Props> = ({
  onNextClick,
  defaultAmount,
  paymentMethodId,
  onPaymentMethodChanged
}) => {
  const { loading, topUpFee } = useGetPaymentMethodWithFees(paymentMethodId);
  const context = usePayLiabilityForm(topUpFee, defaultAmount);
  const onActionPress = context.handleSubmit(onNextClick);

  return (
    <ScreenWithHeaderAndAction
      header={header}
      primaryAction={{
        disabled:
          loading ||
          context.amounts.amountWithoutFees.cents <= 0 ||
          !!context.form.errors.paymentAmount,
        onClick: onActionPress,
        text: "Next"
      }}
    >
      <PaddedContainer>
        <PaymentAmountInput
          editable={true}
          context={context}
          placeholder={"0.00"}
        />
      </PaddedContainer>

      <View style={{ backgroundColor: Colors.CLOUD, marginTop: 16 }}>
        <PaymentMethodDisplay
          paymentMethodId={paymentMethodId}
          onPaymentMethodChanged={onPaymentMethodChanged}
        />
      </View>
    </ScreenWithHeaderAndAction>
  );
};

export { SelectAmountComponent };
