import { FrequencyEnum } from "./types";
import moment from "moment";

// TODO: potential refactor idea: https://github.com/ailohq/ailo-frontend/pull/109#discussion_r462823764
export class Frequency {
  public static value(f: FrequencyEnum | undefined): string {
    return f ? FrequencyEnum[f] : "unknown";
  }

  public static values(): FrequencyEnum[] {
    return Object.keys(FrequencyEnum) as Array<FrequencyEnum>;
  }

  public static anniversary(date?: Date, frequency?: FrequencyEnum): number {
    if (!date || !frequency) return -1;

    switch (Frequency.value(frequency)) {
      case FrequencyEnum.fortnightly:
        return moment(date).isoWeekday();
      case FrequencyEnum.daily:
        return 0;
      case FrequencyEnum.monthly:
        return moment(date).date();
      case FrequencyEnum.weekly:
        return moment(date).isoWeekday();
      default:
        return -1;
    }
  }

  public static humanReadable(date?: Date, frequency?: FrequencyEnum): string {
    if (!date || !frequency) return "unknown";

    switch (Frequency.value(frequency)) {
      case FrequencyEnum.fortnightly:
        return moment(date).format("dddd") + " of every fortnight";
      case FrequencyEnum.daily:
        return "every day";
      case FrequencyEnum.monthly:
        return moment(date).format("Do") + " of every month";
      case FrequencyEnum.weekly:
        return moment(date).format("dddd") + " of every week";
      default:
        return "unknown";
    }
  }

  public static humanReadablePrefix(frequency?: FrequencyEnum): string {
    switch (Frequency.value(frequency)) {
      case FrequencyEnum.fortnightly:
      case FrequencyEnum.monthly:
      case FrequencyEnum.weekly:
        return " on the";
      default:
        return "";
    }
  }
}
