import { ApolloQueryResult, NetworkStatus } from "@apollo/client";
import {
  BankAccountTypeName,
  filterBankAccountPaymentMethods
} from "@ailo/domains";
import {
  GetPaymentMethodIdsForUserQuery,
  GetPaymentMethodIdsForUserQueryVariables,
  useGetPaymentMethodIdsForUserQuery
} from "local/graphql";
import { useCurrentLegalEntity } from "local/common";

interface PaymentMethodIdsForUser {
  loading: boolean;
  data: GetPaymentMethodIdsForUserQuery | undefined;
  refetch: (
    variables?: GetPaymentMethodIdsForUserQueryVariables | undefined
  ) => Promise<ApolloQueryResult<GetPaymentMethodIdsForUserQuery>>;
  networkStatus: NetworkStatus;
  defaultInPaymentMethodId: string | undefined;
  defaultOutPaymentMethodId: string | undefined;
  legalEntityAilorn: string | undefined;
}

export const useGetDefaultPaymentMethodIdsForUser =
  (): PaymentMethodIdsForUser => {
    const [legalEntity] = useCurrentLegalEntity();
    const { loading, data, refetch, networkStatus } =
      useGetPaymentMethodIdsForUserQuery({
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        variables: {
          legalEntityId: legalEntity.id.toString()
        }
      });
    const legalEntityData = data?.legalEntity;
    const legalEntityAilorn = legalEntityData?.ailorn;
    const paymentMethods = legalEntityData?.paymentMethods?.items || [];

    let defaultInPaymentMethodId = paymentMethods.filter(
      (pm) => pm?.isDefaultIn
    )[0]?.id;
    if (defaultInPaymentMethodId === undefined) {
      defaultInPaymentMethodId = paymentMethods[paymentMethods.length - 1]?.id;
    }

    let defaultOutPaymentMethodId = paymentMethods.filter(
      (item) => item?.isDefaultOut && item?.__typename === BankAccountTypeName
    )[0]?.id;
    if (defaultOutPaymentMethodId === undefined) {
      const bankPaymentMethods =
        filterBankAccountPaymentMethods(paymentMethods);
      defaultOutPaymentMethodId =
        bankPaymentMethods[bankPaymentMethods.length - 1]?.id;
    }

    return {
      loading,
      data,
      refetch,
      networkStatus,
      defaultInPaymentMethodId,
      defaultOutPaymentMethodId,
      legalEntityAilorn
    };
  };
