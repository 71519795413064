import { useHasFeature, FeeInfo, FeeUIHelpers } from "@ailo/domains";
import { Text } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import * as accounting from "accounting";
import { Money, PlatformFeatureId } from "local/graphql";
import { Screens, useRoute } from "local/common";
import React, { FC } from "react";
import { FeesDisclaimerDisplay } from "../FeesDisclaimerDisplay";

interface Props {
  payLiability: (amountWithoutFees: Money, amountWithFees: Money) => void;
  topUpFee?: FeeInfo;
  loading: boolean;
}

interface Statics {
  Loading: FC;
}

const UneditablePaymentDisplay: FC<Props> & Statics = ({
  payLiability,
  topUpFee,
  loading
}) => {
  const { defaultPaymentAmount } = useRoute<Screens.PayBillLiability>().params;
  const defaultPaymentDollarAmount = accounting.formatMoney(
    accounting.toFixed(defaultPaymentAmount / 100, 2),
    {
      thousand: ""
    }
  );

  const enableTransactionFees = useHasFeature(
    PlatformFeatureId.TransactionFees
  );

  const feesAppliedCentsAmount = FeeUIHelpers.getFeeAppliedAmount(
    enableTransactionFees ? topUpFee : undefined,
    defaultPaymentDollarAmount
  );

  const feesAppliedDollarAmount = FeeUIHelpers.format(feesAppliedCentsAmount);

  const onSubmit = (): void => {
    const amountWithoutFees = { cents: defaultPaymentAmount };
    const amountWithFees = { cents: feesAppliedCentsAmount };
    payLiability(amountWithoutFees, amountWithFees);
  };

  return (
    <>
      <Text.DisplayL>{defaultPaymentDollarAmount}</Text.DisplayL>
      {enableTransactionFees && (
        <FeesDisclaimerDisplay
          dollarAmount={feesAppliedDollarAmount}
          style={{ marginTop: 16 }}
        />
      )}
      <Button.Primary
        disabled={loading}
        onPress={onSubmit}
        style={{ marginTop: 16 }}
        accessibilityLabel={"pay-rent-amount-button"}
      >
        {`Pay ${feesAppliedDollarAmount}`}
      </Button.Primary>
    </>
  );
};

const Loading: FC = () => {
  const enableTransactionFees = useHasFeature(
    PlatformFeatureId.TransactionFees
  );

  return (
    <>
      <Text.DisplayL.Loading width={80} />
      {enableTransactionFees && (
        <FeesDisclaimerDisplay.Loading style={{ marginTop: 16 }} />
      )}
      <Button.Loading style={{ marginTop: 16 }} />
    </>
  );
};

UneditablePaymentDisplay.Loading = Loading;

export { UneditablePaymentDisplay };
