import { LocalDate, RecurringDate } from "@ailo/date";
import { Colors, Text } from "@ailo/primitives";
import { capitalCase } from "capital-case";
import { Frequency, FrequencyEnum } from "local/domain/ledger";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import moment from "moment-timezone";
import React, { FC, useState } from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import styled from "styled-components/native";
import { DisplaySRed } from "../../liabilityPaymentPlans/components";

interface Props {
  frequency?: FrequencyEnum;
  anniversaryDaysOfMonth?: number[];
  isLastDayOfTheMonth?: boolean;
  onDaySelected: (
    startDate: Date | undefined,
    anniversaryDaysOfMonth: number[],
    isLastDayOfTheMonth: boolean
  ) => void;
}

const getStartDate = (
  selectedDays: number[],
  isLastDayOfTheMonth: boolean
): LocalDate => {
  const nextOccurrences = selectedDays.map((day) =>
    new RecurringDate({
      frequency: "monthly",
      anniversaryDay: day
    }).getNextOccurrence()
  );

  if (isLastDayOfTheMonth) {
    nextOccurrences.push(
      new RecurringDate({
        frequency: "monthly",
        anniversaryDay: moment().daysInMonth()
      }).getNextOccurrence()
    );
  }

  if (nextOccurrences.length === 0) {
    throw new Error(
      "Either `isLastDayOfTheMonth` needs to be `true` or `selectedDays` needs to be non-empty"
    );
  }

  return LocalDate.min(...(nextOccurrences as [LocalDate, ...LocalDate[]]));
};

const SelectMonthlyFrequencyDateScreen: FC<Props> = ({
  frequency,
  anniversaryDaysOfMonth,
  isLastDayOfTheMonth: isLastDayOfTheMonthProp,
  onDaySelected
}) => {
  const [selectedDays, setSelectedDays] = useState(
    anniversaryDaysOfMonth ?? []
  );
  const [isLastDayOfTheMonth, setIsLastDayOfTheMonth] = useState(
    isLastDayOfTheMonthProp ?? false
  );

  const header = (
    <Text.DisplayS>
      {`You selected `}
      {<DisplaySRed>{capitalCase(Frequency.value(frequency))}</DisplaySRed>}
      {`,\n`}
      {"what day(s) of the month do you want funds to be transferred?"}
    </Text.DisplayS>
  );

  let nextTransferAt = undefined;
  if (selectedDays.length || isLastDayOfTheMonth) {
    nextTransferAt = getStartDate(selectedDays, isLastDayOfTheMonth);
  }

  const onNextClick = (): void => {
    if (selectedDays) {
      const startDate = getStartDate(
        selectedDays,
        isLastDayOfTheMonth
      ).toDate();
      onDaySelected(startDate, selectedDays, isLastDayOfTheMonth);
    }
  };

  const toggleDay = (day: number): void => {
    setSelectedDays((previousSelectedDays) => {
      const wasSelected = previousSelectedDays.includes(day);
      if (wasSelected) {
        return previousSelectedDays.filter((d) => d !== day);
      } else {
        return [...previousSelectedDays, day];
      }
    });
  };

  const body = (
    <Body>
      <MonthlyDayPicker>
        {Array.from(Array(4), (e, row) => {
          return (
            <MonthlyRow key={row}>
              {Array.from(Array(7), (e, day) => {
                const monthDay = day + 1 + row * 7;
                const isSelected = selectedDays.includes(monthDay);
                return (
                  <TouchableWithoutFeedback
                    onPress={() => toggleDay(monthDay)}
                    key={monthDay}
                  >
                    <Cell>
                      <DayContainer selected={isSelected}>
                        <StyledText selected={isSelected}>
                          {monthDay}
                        </StyledText>
                      </DayContainer>
                    </Cell>
                  </TouchableWithoutFeedback>
                );
              })}
            </MonthlyRow>
          );
        })}
        <MonthlyRow>
          <TouchableWithoutFeedback
            onPress={() => setIsLastDayOfTheMonth((previous) => !previous)}
          >
            <LastDay selected={isLastDayOfTheMonth}>
              <StyledText selected={isLastDayOfTheMonth}>
                {"Last day of the month"}
              </StyledText>
            </LastDay>
          </TouchableWithoutFeedback>
        </MonthlyRow>
      </MonthlyDayPicker>
    </Body>
  );

  return (
    <ScreenWithHeaderAndAction
      header={header}
      subHeader={"You can select more than one day"}
      primaryAction={{
        disabled: !selectedDays,
        onClick: onNextClick,
        text: "Next"
      }}
      buttonInfo={
        nextTransferAt
          ? `Transfers begin ${nextTransferAt.format("D MMMM YYYY")}`
          : undefined
      }
    >
      {body}
    </ScreenWithHeaderAndAction>
  );
};

const Body = styled(View)`
  width: 100%;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
`;

const MonthlyDayPicker = styled(View)`
  flex: 1;
  margin-top: 4px;
  align-items: center;
  justify-content: center;
`;

const Cell = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const DayContainer = styled(View)<{ selected: boolean }>`
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background: ${({ selected }) => (selected ? Colors.OCEAN : Colors.WHITE)};
`;

const LastDay = styled(View)<{ selected: boolean }>`
  height: 36px;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 18px;
  background: ${({ selected }) => (selected ? Colors.OCEAN : Colors.WHITE)};
`;

const StyledText = styled(Text.BodyM)<{ selected: boolean }>`
  color: ${({ selected }) =>
    selected ? Colors.TEXT.LIGHT.PRIMARY : Colors.TEXT.DARK.PRIMARY};
`;

const MonthlyRow = styled(View)`
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 6.25px;
  margin-right: 6.25px;
`;

export { SelectMonthlyFrequencyDateScreen };
