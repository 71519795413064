import { Card, EmptyContent } from "@ailo/ui";
import { useCurrentLegalEntity, useOnFocus } from "local/common";

import React from "react";
import { WalletsList } from "./components";
import { useGetMyWalletsQuery } from "local/graphql";

export function MyWalletsCard(): React.ReactElement {
  const [legalEntity] = useCurrentLegalEntity();
  const { loading, data, refetch } = useGetMyWalletsQuery({
    variables: { legalEntityId: legalEntity.id.toString() }
  });

  useOnFocus(refetch);

  if (!data && loading) return <MyWalletsCard.Loading />;

  const wallets = data?.legalEntity?.wallets;

  if (!wallets || wallets.length === 0)
    return (
      <Card style={{ paddingVertical: 20, paddingHorizontal: 16 }}>
        <EmptyContent message={"Sorry, this content could not be loaded"} />
      </Card>
    );

  return <WalletsList wallets={wallets} />;
}

MyWalletsCard.Loading = WalletsList.Loading;
