import React, { FC } from "react";
import styled from "styled-components/native";
import { Text, Colors } from "@ailo/primitives";

const UpcomingBillsHeader: FC = () => {
  return (
    <Container color={Colors.TEXT.DARK.PRIMARY}>{"Outstanding"}</Container>
  );
};

const Container = styled(Text.BodyM)`
  padding: 20px 16px 16px 16px;
`;

export { UpcomingBillsHeader };
