import { FetchMoreOptions } from "@apollo/client";
import { GetPaidBillsQuery, GetPaidBillsQueryVariables } from "local/graphql";
import { unionBy } from "lodash";

const fetchMorePaidBills: FetchMoreOptions<
  GetPaidBillsQuery,
  GetPaidBillsQueryVariables
>["updateQuery"] = (previousResult, { fetchMoreResult }) => {
  const previousBills = previousResult.paidBills;
  if (!previousBills) throw new Error("Not enough data to display bills");

  const newBills = fetchMoreResult?.paidBills;
  if (!newBills) return previousResult;

  if (previousBills.pageInfo.nextCursor === newBills.pageInfo.nextCursor)
    return previousResult;

  const mergedBillItems = unionBy(
    previousBills.items,
    newBills.items,
    (bill) => bill?.ailoRN
  );

  return {
    ...previousResult,
    ...fetchMoreResult,
    paidBills: {
      ...previousBills,
      ...newBills,
      items: mergedBillItems
    }
  };
};

export { fetchMorePaidBills };
