import React, { ReactElement, useCallback } from "react";
import { ViewStyle } from "react-native";
import { isEmpty } from "lodash";
import { SFC, StyleProps } from "@ailo/primitives";
import { ErrorAlertScreen, ListItemBorderType } from "@ailo/ui";
import { didQueryFail, didQueryNotLoadYet, useOnFocus } from "@ailo/services";
import {
  RentHistoryList,
  useTenantRentHistory,
  RentLiabilityEntryFragment
} from "@ailo/domains";
import { Screens, useNavigation } from "local/common";
import { LiabilityEntrySortField } from "local/graphql";

interface Props {
  tenancyId: string;
  hasFooter?: boolean;
  emptyView?: SFC;
  length?: number;
  bounces?: boolean;
  lastPaymentListItemBorderType?: ListItemBorderType;
  listHeaderComponent?: ReactElement;
  style?: StyleProps<ViewStyle>;
}

function TenantRentHistoryList({
  tenancyId,
  hasFooter,
  emptyView: EmptyView = RentHistoryList.Empty,
  length,
  bounces,
  lastPaymentListItemBorderType,
  listHeaderComponent,
  style
}: Props): React.ReactElement {
  const navigation = useNavigation();
  const listItemOnPress = useCallback(
    (liabilityEntryId: string) => {
      navigation.navigate(Screens.TenantRentHistoryDetail, {
        tenancyId,
        liabilityEntryId
      });
    },
    [navigation, tenancyId]
  );

  const result = useTenantRentHistory({
    variables: {
      tenancyId,
      pageSize: 1000,
      sort: {
        field: LiabilityEntrySortField.EffectiveAt
      }
    }
  });

  useOnFocus(result.refetch);

  if (didQueryNotLoadYet(result)) {
    return <RentHistoryList.Loading style={style} />;
  }

  const { data } = result;

  if (didQueryFail(result) || !data || data.liability.paidToDate == null) {
    return (
      <ErrorAlertScreen
        variant={"medium"}
        title={"There was a problem loading\nRent History"}
        onRetry={result.refetch}
        style={{ paddingHorizontal: 32 }}
      />
    );
  }

  const { entries, liability } = data;

  if (isEmpty(entries)) {
    return <EmptyView style={style} />;
  }

  return (
    <RentHistoryList
      style={style}
      liabilityFirstPaymentDate={liability.firstPaymentDate}
      liabilityPaidToDate={liability.paidToDate}
      liabilityTerminationDate={liability.terminationDate}
      liabilityEntries={entries as unknown as RentLiabilityEntryFragment[]}
      numEntriesToDisplay={length}
      hasFooter={hasFooter}
      bounces={bounces}
      lastPaymentListItemBorderType={lastPaymentListItemBorderType}
      listHeaderComponent={listHeaderComponent}
      rentHistoryListType={"RENTER"}
      listItemOnPress={listItemOnPress}
    />
  );
}

TenantRentHistoryList.Loading = RentHistoryList.Loading;
TenantRentHistoryList.Empty = RentHistoryList.Empty;

export { TenantRentHistoryList };
