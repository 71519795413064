import React, { ReactElement } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Colors } from "@ailo/primitives";
import { ScreenComponent } from "@ailo/services";
import { NewChatParticipantsContent } from "./NewChatParticipantsContent";
import { useNavCloseButton } from "local/common";

export const NewChatParticipantsScreen = React.memo(
  function NewChatParticipantsScreen(): ReactElement {
    useNavCloseButton();
    const { bottom: bottomInset } = useSafeAreaInsets();

    return (
      <ScreenComponent
        statusBarStyle={"dark-content"}
        style={{ backgroundColor: Colors.WHITE, paddingBottom: bottomInset }}
      >
        <NewChatParticipantsContent />
      </ScreenComponent>
    );
  }
);
