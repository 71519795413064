import { Colors, opacify } from "@ailo/primitives";
import {
  ScreenComponent,
  useOnFocus,
  withScreenComponent
} from "@ailo/services";
import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import styled from "styled-components/native";
import {
  AiloSection,
  DeveloperToolsLinks,
  FooterImages,
  LogoutButton,
  MyAccountSection,
  Watermark
} from "./components";
import { UserProfileIntro } from "../common";
import { useNavigation, Screens, useCurrentLegalEntity } from "local/common";

import { useGetUserLegalEntities } from "local/domain/authz";
import { AccountSwitchBottomSheet } from "./AccountSwitchBottomSheet";

export const ProfileScreen = withScreenComponent(() => {
  const navigation = useNavigation();
  const [currentLegalEntity, setCurrentLegalEntity] = useCurrentLegalEntity();
  const legalEntitiesResult = useGetUserLegalEntities();
  const [accountSwitchBottomSheetOpen, setAccountSwitchBottomSheetOpen] =
    useState(false);

  useOnFocus(legalEntitiesResult.refetch);

  const showSwitchAccountButton =
    currentLegalEntity.__typename === "Company" ||
    (legalEntitiesResult.data && legalEntitiesResult.data.length > 1);
  // Throw the result error only if we're not showing the "Switch Account" button
  // Otherwise don't bother, as the error will be handled by the AccountSwitchBottomSheet .
  if (
    !showSwitchAccountButton &&
    legalEntitiesResult.error &&
    !legalEntitiesResult.data
  ) {
    throw legalEntitiesResult.error;
  }

  return (
    <ScreenComponent statusBarStyle={"dark-content"}>
      <StyledScrollView>
        <UserProfileIntro
          legalEntity={currentLegalEntity}
          viewProfileButton={
            currentLegalEntity.__typename === "Person"
              ? {
                  onPress: (): void => {
                    navigation.navigate(Screens.EditProfile);
                  }
                }
              : undefined
          }
          switchAccountButton={
            showSwitchAccountButton
              ? {
                  onPress(): void {
                    setAccountSwitchBottomSheetOpen(true);
                  }
                }
              : undefined
          }
        />
        <StyledDivider />
        <MyAccountSection />
        <AiloSection />
        <DeveloperToolsLinks />
        <LogoutButton style={{ marginTop: 40 }} />
        <Watermark />
        <FooterImages />
      </StyledScrollView>
      <AccountSwitchBottomSheet
        legalEntitiesResult={legalEntitiesResult}
        currentLegalEntity={currentLegalEntity}
        open={accountSwitchBottomSheetOpen}
        onSubmit={(le): void => {
          setCurrentLegalEntity(le);
          setAccountSwitchBottomSheetOpen(false);
        }}
        onClose={(): void => setAccountSwitchBottomSheetOpen(false)}
      />
    </ScreenComponent>
  );
});

const StyledScrollView = styled(ScrollView)`
  flex: 1;
  background-color: ${Colors.WHITE};
`;

const StyledDivider = styled(View)`
  margin-left: 16px;
  border-color: ${opacify(Colors.SPACE_BLACK, 0.1)};
  border-bottom-width: 1px;
  padding-bottom: 24px;
`;
