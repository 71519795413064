import { Alert } from "@ailo/ui";
import { BillAgencyStatus } from "local/graphql";
import React from "react";
import { ViewStyle } from "react-native";

type Props = {
  style?: ViewStyle;
  status: BillAgencyStatus;
};

export const BillStatusInfoBox: React.FC<Props> = ({ style, status }) => {
  switch (status) {
    case "Cancelled":
      return (
        <Alert
          style={style}
          type={"info"}
          message={"This bill has been cancelled."}
        />
      );
    case "RefundFailed":
      return (
        <Alert
          style={style}
          type={"error"}
          message={
            "The refund for this payment failed. Our team is looking into this and will be in touch soon."
          }
        />
      );
    case "Overdue":
      return (
        <Alert
          style={style}
          type={"error"}
          message={"This bill is overdue. Please pay now."}
        />
      );
    default:
      return null;
  }
};
