import React from "react";
import { Badge } from "@ailo/ui";
import { TenancyDepositStatus } from "local/graphql";

export function DepositStatusBadge(
  status: TenancyDepositStatus
): React.ReactElement | undefined {
  switch (status) {
    case TenancyDepositStatus.Pending:
      return <Badge type={"attention"}>{"Pending"}</Badge>;
    case TenancyDepositStatus.Failed:
      return <Badge type={"critical"}>{"Failed"}</Badge>;
    case TenancyDepositStatus.Paid:
      return <Badge type={"success"}>{"Paid"}</Badge>;
  }
  return undefined;
}
