import { Colors, SFC, Text } from "@ailo/primitives";
import { DateTimeWithTimeZone } from "@ailo/ui";
import Constants from "expo-constants";
import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";

export const Watermark: SFC = ({ style }) => {
  return (
    <Container style={style}>
      <WatermarkText>
        {"Version "}
        {Constants.manifest?.version}
      </WatermarkText>
      {(__DEV__ ||
        !["preprod", "prod"].includes(Constants.manifest?.extra?.env)) && (
        <>
          <WatermarkText>{Constants.manifest?.name}</WatermarkText>
          <WatermarkText>
            {`Build ${Constants.manifest?.extra?.buildVersion}` +
              (Constants.manifest?.extra?.buildTimestamp
                ? ` (${DateTimeWithTimeZone.fromTimestampString(
                    Constants.manifest.extra.buildTimestamp
                  ).friendlyFormat("datetime")})`
                : "")}
          </WatermarkText>
          <WatermarkText>
            {Constants.manifest?.extra?.codeVersion &&
              `Code ${Constants.manifest.extra.codeVersion.substr(0, 8)}`}
          </WatermarkText>
        </>
      )}
    </Container>
  );
};

const Container = styled(View)`
  padding: 16px 16px;
  align-items: center;
`;

const WatermarkText = styled(Text.BodyS)`
  color: ${Colors.TEXT.DARK.SECONDARY};
`;
