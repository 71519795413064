import React, { FC } from "react";
import { Button, Card, HouseViewWithDetails } from "@ailo/ui";
import { View } from "react-native";
import { Screens, useNavigation, FloatingClouds } from "local/common";

const RenterSettingUpMessageCard: FC = () => {
  return (
    <Card>
      <HouseViewWithDetails
        size={"medium"}
        heading={"We are getting your renters set up!"}
        subHeading={`We will let you know when\nyour rent arrives in Ailo`}
      >
        <View style={{ paddingBottom: 16 }} />
        <FloatingClouds
          style={{
            left: 0,
            right: 0,
            top: 40,
            position: "absolute",
            zIndex: -1
          }}
        />
      </HouseViewWithDetails>
    </Card>
  );
};

const RenterSetUpCard: FC<{ message: string }> = ({ message }) => {
  const navigation = useNavigation();

  return (
    <Card>
      <HouseViewWithDetails
        size={"small"}
        heading={"Congratulations!"}
        subHeading={message}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 12,
            marginBottom: 4
          }}
        >
          <Button.Primary
            onPress={(): void =>
              navigation.navigate(Screens.InvestorPropertyWalletTab)
            }
          >
            {"Go to Property Wallet"}
          </Button.Primary>
        </View>
        <FloatingClouds
          style={{
            left: 0,
            right: 0,
            top: 40,
            position: "absolute",
            zIndex: -1
          }}
        />
      </HouseViewWithDetails>
    </Card>
  );
};

const RenterSetUpNoAutoWithdrawMessageCard: FC = () => {
  return (
    <RenterSetUpCard
      message={`Your first rent payment in Ailo is available.\nTransfer your funds to your bank account.`}
    />
  );
};

const RenterSetUpWithAutoWithdrawMessageCard: FC = () => {
  return (
    <RenterSetUpCard
      message={`Your first rent payment in Ailo is here!\nYour auto transfer from the property wallet is on, you\ncan view or change the settings.`}
    />
  );
};

export {
  RenterSettingUpMessageCard,
  RenterSetUpNoAutoWithdrawMessageCard,
  RenterSetUpWithAutoWithdrawMessageCard
};
