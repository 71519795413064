import React, { FC } from "react";
import styled from "styled-components/native";
import { Card, Money } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";
import { View } from "react-native";
import { DepositStatusBadge } from "./DepositStatusBadge";
import { PayButton } from "./PayButton";
import { DepositData } from "./parseDepositData";
import { DateTimeWithTimeZone } from "@ailo/date";
import moment from "moment";

type Props = {
  deposit: DepositData;
};

const TenancyDepositCard: FC<Props> = ({ deposit }) => {
  const statusBadge = DepositStatusBadge(deposit.status);

  return (
    <CardContainer>
      <Text.BodyM weight={"medium"}>{"Initial Payment"}</Text.BodyM>
      <AmountDisplayContainer style={{ flex: 1, marginTop: 16 }}>
        <Text.DisplayM style={{ marginBottom: 8 }}>
          {Money.from(deposit.amount).format()}
        </Text.DisplayM>

        {!!statusBadge && statusBadge}
        {deposit.isDue && !statusBadge && (
          <Text.BodyM color={Colors.TEXT.DARK.SECONDARY}>
            {"Amount Due"}
          </Text.BodyM>
        )}
      </AmountDisplayContainer>
      {(deposit.isDue || deposit.isPending) && (
        <TextContainer style={{ marginTop: 16 }}>
          <Text.BodyS style={{ textAlign: "center" }}>
            {deposit.isDue
              ? "Your property manager has requested an initial payment before you move in"
              : getPendingPaymentDescription(deposit!)}
          </Text.BodyS>
        </TextContainer>
      )}

      {(deposit.isDue || deposit.failed) && (
        <PayButton
          failed={deposit.failed}
          amount={deposit.amount}
          liabilityId={deposit.liabilityId}
        />
      )}
    </CardContainer>
  );
};

const getPendingPaymentDescription = (data: DepositData): string => {
  if (!data.expectedClearedAt) {
    return (
      (data.paidAt
        ? `Paid on ${DateTimeWithTimeZone.from(data.paidAt).format(
            "D MMM YYYY"
          )}. `
        : "") + "Bank account payments take 1-2 business days to process."
    );
  }
  const expectedClearedAt = moment.tz(
    data.expectedClearedAt,
    "Australia/Sydney"
  );

  return `Estimated to arrive by ${expectedClearedAt.format(
    "h:mma, D MMM yyyy"
  )}`;
};

const CardContainer = styled(Card)`
  background: ${Colors.WHITE};
  padding: 20px 16px;
  border-radius: 8px;
`;

const AmountDisplayContainer = styled(View)`
  background: ${Colors.CLOUD};
  padding: 12px 16px;
  border-radius: 12px;
  align-items: center;
`;

const TextContainer = styled(View)`
  padding: 12px 16px;
  border-radius: 12px;
  text-align: center;
  border: 1px solid rgba(28, 30, 38, 0.1);
`;

export { TenancyDepositCard };
