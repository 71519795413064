import React from "react";
import { AiloWholeWordIcon, AiloHouses, SFC } from "@ailo/primitives";
import styled from "styled-components/native";
import { Image, View } from "react-native";

const FooterImages: SFC = ({ style }) => {
  return (
    <Container style={style}>
      <AiloWholeWordIcon width={49} style={{ marginBottom: 48 }} />
      <Image
        style={{
          width: "100%",
          height: undefined,
          aspectRatio: ailoHousesAspectRatio
        }}
        source={AiloHouses}
        resizeMode={"stretch"}
      />
    </Container>
  );
};

const ailoHousesAspectRatio = 327 / 106;

const Container = styled(View)`
  flex-direction: column;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
`;

export { FooterImages };
