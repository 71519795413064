import React from "react";
import { useGetTotalUnreadMessagesCountQuery } from "@ailo/domains";
import { useOnFocus } from "@ailo/services";
import { usePropertySelectorContext } from "local/common";
import { UnreadIndicatorIcon } from "@ailo/primitives";
import styled from "styled-components/native";

export function ChatUnreadIndicatorIcon(): React.ReactNode {
  const { currentProperty } = usePropertySelectorContext();
  const agencyAilorn = currentProperty?.agency.organisationAilorn;

  if (!agencyAilorn) {
    throw new Error("Invalid Agency Ailorn");
  }

  const { data, loading, refetch } = useGetTotalUnreadMessagesCountQuery({
    variables: {
      organisationAilorn: agencyAilorn.toString()
    }
  });

  useOnFocus(refetch);

  if (loading) return null;
  if (!data?.unreadMessagesCount) return null;
  if (data.unreadMessagesCount === 0) return null;

  return <StyledUnreadIndicatorIcon />;
}

const StyledUnreadIndicatorIcon = styled(UnreadIndicatorIcon)`
  height: 6px;
  width: 6px;
  border-radius: 3px;
`;
