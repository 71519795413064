import React, { ReactElement } from "react";
import { Text, Colors, EditIcon } from "@ailo/primitives";
import { ListItemProps, ListItem } from "@ailo/ui";

interface Props extends Omit<ListItemProps, "header" | "subHeader"> {
  label?: string;
  value?: string;
  placeholder?: string;
  editable?: boolean;
  onPress?(): void;
}

export function FormFieldListItem({
  label,
  value,
  placeholder,
  editable,
  ...props
}: Props): ReactElement {
  const rightComponent =
    props.rightComponent ??
    (editable ? <EditIcon width={20} height={20} /> : undefined);

  return (
    <ListItem
      {...{
        ...props,
        rightComponent,
        header: label && (
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>{label}</Text.BodyS>
        ),
        subHeader: (value || placeholder) && (
          <Text.BodyM
            weight={"medium"}
            color={
              value ? Colors.TEXT.DARK.PRIMARY : Colors.TEXT.DARK.PLACEHOLDER
            }
          >
            {value || placeholder}
          </Text.BodyM>
        )
      }}
    />
  );
}
