import React from "react";
import { SFC } from "@ailo/primitives";
import { alert, Button } from "@ailo/ui";
import { useCancelAutoPayRentMutation } from "local/graphql";
import { useNavigation } from "local/common";
import { useAutoPayAnalytics } from "local/domain/liabilityPaymentPlan";

interface Props {
  liabilityId: string;
  onClose: () => void;
  loading: boolean;
}

const TurnOffAutoPayRentButton: SFC<Props> = ({
  liabilityId,
  onClose,
  loading
}: Props) => {
  const [cancelAutoPayRent] = useCancelAutoPayRentMutation({
    variables: { liabilityId }
  });

  const navigation = useNavigation();
  const analytics = useAutoPayAnalytics();

  return (
    <Button.Secondary
      onPress={(): void => {
        alert(
          "Turn off auto rent payment?",
          "Are you sure you want to cancel your auto rent payment?",
          [
            { text: "No", style: "cancel" },
            {
              text: "Turn off",
              style: "destructive",
              onPress: (): void => {
                navigation.setParams({ showLoadingOverlay: true });
                cancelAutoPayRent()
                  .then(() => {
                    analytics.track(false, "When Rent Is Due", liabilityId);
                    onClose();
                  })
                  .catch(() => {
                    alert(
                      "Error",
                      "We were unable to turn off your automatic payment. Please try again and if the problem persists, contact Ailo support",
                      [{ text: "Dismiss" }]
                    );
                    navigation.setParams({ showLoadingOverlay: false });
                  });
              }
            }
          ]
        );
      }}
      disabled={loading}
    >
      {"Turn off Auto Rent Payment"}
    </Button.Secondary>
  );
};

export { TurnOffAutoPayRentButton };
