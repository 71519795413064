import React from "react";
import { Platform } from "react-native";
import { Screens, AppNavigatorStack } from "local/common";
import { WelcomeScreen } from "./WelcomeScreen";
import { AddPaymentMethodKeyActionScreen } from "./AddPaymentMethodKeyActionScreen";
import {
  SecureYourAccountScreen,
  YourAccountIsSecureScreen,
  CodeVerificationScreen
} from "./phoneVerification";
import { TermsOfServiceViewScreen } from "local/domain/termsOfService";
import { TermsOfServiceAcceptScreen } from "./TermsOfServiceAcceptScreen";
import { AllowNotificationsKeyActionScreen } from "./AllowNotificationsKeyActionScreen";
import { SetupAutoRentPaymentKeyActionScreen } from "./SetupAutoRentPaymentKeyActionScreen";
import { PayTenancyDepositKeyActionScreen } from "../payTenancyDeposit/PayTenancyDepositScreen/PayTenancyDepositKeyActionScreen";

export function getOnboardingScreens(
  Stack: AppNavigatorStack
): React.ReactElement {
  return (
    <>
      <Stack.Screen
        name={Screens.TermsOfServiceAccept}
        component={TermsOfServiceAcceptScreen}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name={Screens.AiloOverview}
        component={WelcomeScreen}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name={Screens.AddPaymentMethodKeyAction}
        component={AddPaymentMethodKeyActionScreen}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name={Screens.SetupAutoRentPaymentKeyAction}
        component={SetupAutoRentPaymentKeyActionScreen}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name={Screens.SecureYourAccount}
        component={SecureYourAccountScreen}
      />
      <Stack.Screen
        name={Screens.CodeVerification}
        component={CodeVerificationScreen}
      />
      <Stack.Screen
        name={Screens.YourAccountIsSecure}
        component={YourAccountIsSecureScreen}
      />
      <Stack.Screen
        name={Screens.TermsOfServiceView}
        component={TermsOfServiceViewScreen}
      />
      {Platform.OS !== "web" && (
        <Stack.Screen
          name={Screens.AllowNotificationsKeyAction}
          component={AllowNotificationsKeyActionScreen}
          options={{
            headerShown: false
          }}
        />
      )}
      <Stack.Screen
        name={Screens.TenancyDepositKeyAction}
        component={PayTenancyDepositKeyActionScreen}
        options={{
          headerShown: false
        }}
      />
    </>
  );
}
