import React, { FC, useCallback } from "react";
import { ListItem } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import { RightChevronIcon, Skeleton } from "@ailo/primitives";

interface Props {
  onSuccess: (paymentMethodId: string) => void;
}

interface Statics {
  Loading: FC;
}

const AddOnceOffBankAccountListItem: FC<Props> & Statics = ({ onSuccess }) => {
  const navigation = useNavigation();

  const addBankAccount = useCallback(() => {
    const addBankAccountParams = { onSuccess };
    navigation.navigate(Screens.AddOnceOffBankAccount, addBankAccountParams);
  }, [navigation, onSuccess]);

  return (
    <ListItem
      header={"One Off Transfer"}
      style={{ alignItems: "center" }}
      rightComponent={<RightChevronIcon width={20} height={20} />}
      accessibilityLabel={"one-off-transfer-list-item"}
      onPress={addBankAccount}
    />
  );
};

const Loading: FC = () => {
  return (
    <ListItem
      style={{ alignItems: "center" }}
      header={<Skeleton style={{ width: 160, height: 12 }} />}
    />
  );
};

AddOnceOffBankAccountListItem.Loading = Loading;

export { AddOnceOffBankAccountListItem };
