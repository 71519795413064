import React from "react";
import { withScreenComponent, Screens, useRoute, useTitle } from "local/common";
import { AddBankAccountScreenComponent } from "./AddBankAccountScreen";
import styled from "styled-components/native";
import { ListItemHeader } from "@ailo/ui";

const AddOnceOffBankAccountScreen = withScreenComponent(
  (): React.ReactElement => {
    const { params } = useRoute<Screens.AddOnceOffBankAccount>();
    const { onSuccess, options } = params;
    useTitle(options?.title || Screens.AddOnceOffBankAccount);

    return (
      <>
        {options?.header || <Header text={"Banking details"} />}
        <AddBankAccountScreenComponent
          buttonText={"Continue"}
          onceOff={true}
          onSuccess={(paymentMethod) => onSuccess(paymentMethod.id)}
          background={options?.background}
        />
      </>
    );
  }
);

const Header = styled(ListItemHeader)`
  padding: 12px 16px 0;
`;

export { AddOnceOffBankAccountScreen };
