import React from "react";
import { TextInputProps, View } from "react-native";
import { Controller, useFormContext } from "react-hook-form";
import { MoneyTextInput, TextInput } from "@ailo/ui";
import { formatMoneyInputWithoutDollar } from "@ailo/domains";
import { SFC } from "@ailo/primitives";

export interface FormData {
  paymentAmount: string;
}

interface Props {
  inputDisabled?: boolean;
  accessibilityLabel?: string;
}

interface Statics {
  Loading: SFC;
}

const PaymentAmountInputField: SFC<Props & TextInputProps> & Statics = ({
  style,
  accessibilityLabel,
  ...rest
}) => {
  const { control, errors } = useFormContext<FormData>();

  return (
    <View style={style} accessible={true}>
      <Controller
        control={control}
        name={"paymentAmount"}
        defaultValue={""}
        render={({ onChange, onBlur, value }): React.ReactElement => (
          <MoneyTextInput
            error={errors?.paymentAmount?.message}
            keyboardType={"decimal-pad"}
            returnKeyType={"done"}
            accessibilityLabel={accessibilityLabel}
            {...rest}
            value={value}
            onChangeText={(text): void =>
              onChange(formatMoneyInputWithoutDollar([text]))
            }
            onBlur={onBlur}
          />
        )}
        rules={{
          required: "Payment amount is required",
          max: {
            value: 100000.0,
            message: "Maximum Limit Reached"
          },
          pattern: {
            value: /^[0-9]+(\.[0-9]{2})?$/,
            message: "Payment amount must be valid"
          }
        }}
      />
    </View>
  );
};

const Loading: SFC = ({ style }) => {
  return (
    <View style={style}>
      <TextInput editable={false} />
    </View>
  );
};

PaymentAmountInputField.Loading = Loading;

export { PaymentAmountInputField };
