import React from "react";
import { AFC, Colors, opacify } from "@ailo/primitives";
import styled from "styled-components/native";
import { View } from "react-native";

interface Props {
  totalSlides: number;
  currentSlideIndex: number;
}

const SwiperDots: AFC<Props> = ({ totalSlides, currentSlideIndex }) => {
  const dots = [];

  if (currentSlideIndex === 0) {
    return (
      <Container>
        <View style={{ height: 12 }} />
      </Container>
    );
  }

  for (let i = 1; i < totalSlides; i++) {
    dots.push(
      <Dot
        key={i}
        style={{
          backgroundColor:
            i === currentSlideIndex
              ? Colors.AILO_RED
              : opacify(Colors.SPACE_BLACK, 0.1)
        }}
      />
    );
  }

  return <Container>{dots}</Container>;
};

const Container = styled(View)`
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: center;
`;

const Dot = styled(View)`
  margin-left: 4px;
  margin-right: 4px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
`;

export { SwiperDots };
