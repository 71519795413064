import { AiloRN } from "@ailo/ailorn";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError, QueryHookOptions } from "@apollo/client";
import { LegalEntity } from "local/common";
import { useGetUserLegalEntitiesQuery } from "local/graphql";

export type GetUserLegalEntitiesResult = MappedQueryResult<LegalEntity[], {}>;

export function useGetUserLegalEntities(
  opts?: QueryHookOptions<unknown, {}>
): GetUserLegalEntitiesResult {
  const queryResult = useGetUserLegalEntitiesQuery(opts);
  const result = useMappedQueryResult(queryResult, (data) => {
    if (!data.effectiveUser) {
      throw new ApolloError({
        errorMessage: `effectiveUser query returned null`
      });
    }

    const person = {
      ...data.effectiveUser!.person,
      id: AiloRN.fromString(data.effectiveUser!.person.ailoRN)
    };

    const companies = (data.companies || []).map((c) => ({
      ...c,
      id: AiloRN.fromString(c.ailoRN)
    }));

    return [person, ...companies];
  });

  return result;
}
