import React from "react";
import * as accounting from "accounting";
import styled from "styled-components/native";
import { View, ViewStyle } from "react-native";
import { Text, Colors } from "@ailo/primitives";
import { DateTimeWithTimeZone, isLedgerMaxDate } from "@ailo/ui";

interface Props {
  style?: ViewStyle;
  rentDueAmount: number;
  rentDueDate: string;
  autoPaymentEnabled: boolean;
}

const RentDueDisplayContainer = styled(View)`
  flex: 1;
  align-items: center;
  padding: 8px;
  background: ${Colors.CLOUD};
`;

function RentDueDisplay({
  style,
  rentDueAmount,
  rentDueDate,
  autoPaymentEnabled
}: Props): React.ReactElement {
  const displayAmount = accounting.formatMoney(rentDueAmount / 100);
  const formattedDueDate = !isLedgerMaxDate(rentDueDate)
    ? DateTimeWithTimeZone.fromDateSubtractingAMillisecond(
        rentDueDate
      ).friendlyFormat()
    : null;

  const rentDescription = autoPaymentEnabled
    ? `Payment scheduled for ${formattedDueDate}`
    : `Rent due ${formattedDueDate}`;

  return (
    <RentDueDisplayContainer style={style}>
      <Text.DisplayM style={{ marginTop: 4 }}>{displayAmount}</Text.DisplayM>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginVertical: 8, textAlign: "center" }}
      >
        {rentDescription}
      </Text.BodyM>
    </RentDueDisplayContainer>
  );
}

function Loading({ style }: { style?: ViewStyle }): React.ReactElement {
  return (
    <RentDueDisplayContainer style={style}>
      <Text.DisplayM.Loading width={80} />
      <Text.BodyM.Loading width={120} style={{ marginTop: 8 }} />
    </RentDueDisplayContainer>
  );
}

RentDueDisplay.Loading = Loading;

export { RentDueDisplay };
