import { formatLegalEntityName } from "@ailo/domain-helpers";
import { Colors } from "@ailo/primitives";
import { didQueryNotLoadYet, didQuerySucceed } from "@ailo/services";
import {
  LegacyBottomSheet,
  ErrorAlertScreen,
  LegalEntityAvatar,
  ListItem,
  RadioListItem,
  RadioListItemProps
} from "@ailo/ui";
import { LegalEntity, useAnalytics } from "local/common";
import { GetUserLegalEntitiesResult } from "local/domain/authz";
import React, { useEffect } from "react";

export function AccountSwitchBottomSheet({
  legalEntitiesResult,
  currentLegalEntity,
  open,
  onSubmit,
  onClose
}: {
  legalEntitiesResult: GetUserLegalEntitiesResult;
  currentLegalEntity: LegalEntity;
  open: boolean;
  onSubmit?(legalEntity: LegalEntity): void;
  onClose?(): void;
}): React.ReactElement {
  const analytics = useAnalytics();

  useEffect(() => {
    if (open) {
      analytics.trackScreenVisited("Profile Switcher");
    }
  }, [analytics, open]);

  function submit(le: LegalEntity): void {
    analytics.track("Profile Switched", {
      previousLegalEntityId: currentLegalEntity.id.toString(),
      nextLegalEntityId: le.id.toString()
    });
    onSubmit?.(le);
  }

  let content: React.ReactNode,
    scroll = false;
  if (didQueryNotLoadYet(legalEntitiesResult)) {
    content = (
      <>
        <LegalEntityListItem.Loading />
        <LegalEntityListItem.Loading />
      </>
    );
  } else if (!didQuerySucceed(legalEntitiesResult)) {
    content = <ErrorAlertScreen onRetry={legalEntitiesResult.refetch} />;
  } else {
    content = legalEntitiesResult.data.map((le, index) => (
      <LegalEntityListItem
        key={le.id.toString()}
        legalEntity={le}
        checked={currentLegalEntity.id.equals(le.id)}
        onPress={(): void => {
          submit(le);
        }}
        style={{
          borderType:
            index === legalEntitiesResult.data.length - 1 ? "none" : "under-all"
        }}
      />
    ));
    scroll = legalEntitiesResult.data.length > 5;
  }

  return (
    <LegacyBottomSheet open={open} scroll={scroll} onClose={onClose}>
      {content}
    </LegacyBottomSheet>
  );
}

function LegalEntityListItem({
  legalEntity,
  checked,
  onPress,
  style
}: {
  legalEntity: LegalEntity;
  checked: boolean;
  onPress?(): void;
  style: RadioListItemProps["style"];
}): React.ReactElement {
  return (
    <RadioListItem
      checked={checked}
      header={formatLegalEntityName(legalEntity)}
      style={{ ...style }}
      leftComponent={
        <LegalEntityAvatar
          legalEntity={legalEntity}
          size={40}
          initialsColor={Colors.TEXT.LIGHT.PRIMARY}
          style={{ marginRight: 12 }}
        />
      }
      onPress={onPress}
    />
  );
}
LegalEntityListItem.Loading =
  function LegalEntityListItemLoading(): React.ReactElement {
    return <ListItem.Loading leftComponent />;
  };
