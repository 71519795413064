import React, { FC, useCallback } from "react";
import { ListItem } from "@ailo/ui";
import { Screens, useRoute, useNavigation } from "local/common";
import { RightChevronIcon } from "@ailo/primitives";

const AddCreditDebitCardListItem: FC = () => {
  const { onSuccess } = useRoute<Screens.SetupPaymentMethod>().params;
  const navigation = useNavigation<Screens.SetupPaymentMethod>();

  const addCreditDebitCard = useCallback(() => {
    navigation.navigate(Screens.AddCreditDebitCard, {
      onSuccess
    });
  }, [navigation, onSuccess]);

  return (
    <ListItem
      header={"Credit or Debit Card"}
      testID={"credit-card-list-item"}
      onPress={addCreditDebitCard}
      style={{ alignItems: "center" }}
      rightComponent={<RightChevronIcon width={20} height={20} />}
    />
  );
};

export { AddCreditDebitCardListItem };
