import {
  VerifyEmailErrorCode,
  SendEmailVerificationErrorCode
} from "local/graphql";

export function getEmailErrorMessage(
  code: VerifyEmailErrorCode | SendEmailVerificationErrorCode
): string {
  switch (code) {
    case VerifyEmailErrorCode.EmailUsedByAnotherPerson:
    case SendEmailVerificationErrorCode.EmailUsedByAnotherPerson:
      return "This email is already used by another user.";
    case VerifyEmailErrorCode.VerificationCodeExpired:
      return 'This code is expired. Click "Resend email verification" to send a new one.';
    case VerifyEmailErrorCode.VerificationCodeNotFound:
      return 'Invalid code. Make sure you typed it correctly, or click "Resend email verification" to send a new one.';
    case SendEmailVerificationErrorCode.EmailAlreadyVerified:
      return "This email is already verified";
  }
}
