import { SFC } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { Screens, useNavigateToThisScreen, useNavigation } from "local/common";
import { useGetBankAccountPaymentMethodsForUser } from "local/domain/ledger/hooks";
import React, { useCallback } from "react";

export const useSetupAutoWalletTransferAction = (): {
  handleSetupAutoPayClick: () => void;
  loading: boolean;
} => {
  const navigation = useNavigation();
  const navigateToThisScreen = useNavigateToThisScreen();

  const bankPaymentMethodsResult = useGetBankAccountPaymentMethodsForUser();
  const bankPaymentMethods = bankPaymentMethodsResult.bankPaymentMethods;

  return {
    handleSetupAutoPayClick: useCallback(() => {
      navigation.navigate(Screens.AutoWithdrawPlanSelectWallet, {
        onClose: navigateToThisScreen,
        onSuccess: navigateToThisScreen,
        bankPaymentMethods
      });
    }, [bankPaymentMethods, navigateToThisScreen, navigation]),
    loading: bankPaymentMethodsResult.loading
  };
};

export const SetupAutoWalletTransferButton: SFC = ({ style }) => {
  const { handleSetupAutoPayClick, loading } =
    useSetupAutoWalletTransferAction();

  if (loading) {
    return <Button.Loading style={style} />;
  }

  return (
    <Button.Secondary style={style} onPress={handleSetupAutoPayClick}>
      {"Setup Auto Transfer"}
    </Button.Secondary>
  );
};
