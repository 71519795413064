import { Colors, Text } from "@ailo/primitives";
import { useLayout } from "@react-native-community/hooks";
import React, { useMemo } from "react";
import {
  StyleSheet,
  View,
  ViewStyle,
  TouchableOpacity,
  Animated
} from "react-native";
import { Route, TabBarItemProps } from "react-native-tab-view";

const ITEM_PADDING_HORIZONTAL = 6.5;

const ACTIVE_TEXT_COLOR_OPACITY = 1;
const INACTIVE_TEXT_OPACITY = 0.7;

function estimateLabelTextWidth(labelText: string): number {
  /**
   * What's the estimated width [in px] of 1 character in the label?
   */
  const LABEL_TEXT_ESTIMATED_CHAR_WIDTH = 7;

  return labelText.length * LABEL_TEXT_ESTIMATED_CHAR_WIDTH;
}

interface AiloTabBarItemProps<T extends Route> extends TabBarItemProps<T> {
  windowWidth: number;
}

export function AiloTabBarItem<T extends Route>(
  props: AiloTabBarItemProps<T>
): JSX.Element {
  const {
    route,
    position,
    navigationState,
    renderLabel,
    renderIcon,
    getLabelText,
    getTestID,
    getAccessibilityLabel,
    getAccessible,
    pressOpacity,
    style,
    windowWidth,
    onLayout,
    onPress,
    onLongPress
  } = props;
  const { routes } = navigationState;
  const tabIndex = routes.indexOf(route);
  const isFocused = navigationState.index === tabIndex;
  const isFirst = tabIndex === 0;
  const isLast = tabIndex + 1 === routes.length;

  const { onLayout: onLabelLayout, width: labelLayoutWidth } = useLayout();

  const labelText = renderLabel
    ? renderLabel({
        route,
        focused: true,
        color: "NOT-USED"
      })
    : undefined;

  const labelWidth =
    labelLayoutWidth === 0 && typeof labelText === "string"
      ? estimateLabelTextWidth(labelText)
      : labelLayoutWidth;

  const paddingLeft =
    isFirst && !isLast
      ? (windowWidth - labelWidth - 2 * ITEM_PADDING_HORIZONTAL) / 2
      : 0;
  const paddingRight =
    isLast && !isFirst
      ? (windowWidth - labelWidth - 2 * ITEM_PADDING_HORIZONTAL) / 2
      : 0;

  const { activeOpacity, inactiveOpacity, textOpacity } = useMemo(() => {
    if (routes.length > 1) {
      const inputRange = routes.map((_, i) => i);

      return {
        activeOpacity: position.interpolate({
          inputRange,
          outputRange: inputRange.map((i) => (i === tabIndex ? 1 : 0)),
          extrapolate: "clamp"
        }),
        inactiveOpacity: position.interpolate({
          inputRange,
          outputRange: inputRange.map((i) => (i === tabIndex ? 0 : 1)),
          extrapolate: "clamp"
        }),
        textOpacity: position.interpolate({
          inputRange,
          outputRange: inputRange.map((i) =>
            i === tabIndex ? ACTIVE_TEXT_COLOR_OPACITY : INACTIVE_TEXT_OPACITY
          ),
          extrapolate: "clamp"
        })
      };
    } else {
      return {
        activeOpacity: 1,
        inactiveOpacity: 0,
        textOpacity: ACTIVE_TEXT_COLOR_OPACITY
      };
    }
  }, [position, routes, tabIndex]);

  let icon: React.ReactNode | null = null;
  let label: React.ReactNode | null = null;

  if (renderIcon) {
    icon = renderIcon({
      route,
      focused: true,
      color: "NOT-USED"
    });
  }

  if (labelText) {
    label = (
      <View
        style={{
          paddingLeft,
          paddingRight
        }}
      >
        <View>
          {/* Red rounded box */}
          <Animated.View
            style={{
              // Note: On web platform there's an initial flicker
              // caused by the reanimated not applying the styles on component init
              // - see https://github.com/software-mansion/react-native-reanimated/issues/762
              opacity: activeOpacity,
              transform: [{ scale: activeOpacity }]
            }}
            onLayout={onLabelLayout}
          >
            <View style={styles.labelBox}>
              <Text
                size={16}
                lineHeight={32}
                color={"transparent"}
                style={styles.labelText}
              >
                {labelText}
              </Text>
            </View>
          </Animated.View>

          {/* Text */}
          <Text
            as={Animated.Text}
            size={16}
            lineHeight={32}
            style={[
              StyleSheet.absoluteFill,
              styles.labelText,
              {
                opacity: textOpacity,
                // Note: On web platform there's an initial flicker
                // caused by the reanimated not applying the styles on component init
                // - see https://github.com/software-mansion/react-native-reanimated/issues/762
                color: Colors.TEXT.LIGHT.PRIMARY
              }
            ]}
          >
            {labelText}
          </Text>

          {/* Icon (if exists) */}
          {icon && (
            <Animated.View style={[styles.icon, { opacity: inactiveOpacity }]}>
              {icon}
            </Animated.View>
          )}
        </View>
      </View>
    );
  }

  const tabStyle = StyleSheet.flatten(style);
  const isWidthSet = tabStyle?.width !== undefined;
  const tabContainerStyle: ViewStyle | null = isWidthSet ? null : { flex: 1 };

  const scene = { route };

  let accessibilityLabel = getAccessibilityLabel(scene);

  accessibilityLabel =
    typeof accessibilityLabel !== "undefined"
      ? accessibilityLabel
      : getLabelText(scene);

  if (routes.length === 1) {
    return (
      <View
        style={{
          width: "100%"
        }}
      >
        <View pointerEvents={"none"} style={styles.item}>
          {label}
        </View>
      </View>
    );
  }

  return (
    <TouchableOpacity
      testID={getTestID(scene)}
      accessible={getAccessible(scene)}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={"tab"}
      accessibilityState={isFocused ? { selected: true } : undefined}
      activeOpacity={pressOpacity}
      delayPressIn={0}
      onLayout={onLayout}
      onPress={onPress}
      onLongPress={onLongPress}
      style={tabContainerStyle}
    >
      <View pointerEvents={"none"} style={styles.item}>
        {label}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  item: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
    paddingHorizontal: ITEM_PADDING_HORIZONTAL,
    paddingVertical: 8,
    minHeight: 48
  },
  icon: {
    position: "absolute",
    top: 2,
    right: 6
  },
  labelBox: {
    borderRadius: 50,
    backgroundColor: Colors.AILO_RED
  },
  labelText: {
    paddingHorizontal: 10
  }
});
