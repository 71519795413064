import { Colors } from "@ailo/primitives";
import { StickyBottom } from "@ailo/ui";
import React, { ReactElement } from "react";
import {
  SafeAreaView,
  ScrollView,
  StyleProp,
  View,
  ViewStyle
} from "react-native";
import { KeyboardAvoidingScreenView } from "./KeyboardAvoidingScreenView";
import { ScreenPadding } from "./ScreenPadding";

interface Props {
  /**
   * If true, will wrap children in `KeyboardAvoidingView`.
   * @default true
   */
  keyboardAvoidingView?: boolean;
  /**
   * Additional children that will be put in a `StickyBottom` component,
   * so that it appears below the `ScrollView`,
   * but inside of the `SafeAreaView` and `KeyboardAvoidingView`.
   */
  stickyBottom?: React.ReactNode;
  /**
   * Makes the screen contents be wrapped in a `ScreenPadding` component.
   *
   * @default true
   */
  padding?: boolean;
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
}

/**
 * Basic container component that can be used as root element for your screen component.
 *
 * - adds white background,
 * - wraps children in `SafeAreaView`, so that the children always fit in the device screen's safe area,
 * - wraps children in `KeyboardAvoidingView`, so that the children slide up if the device's keyboard
 *   would hide the input on which the user is focusing,
 * - wraps children in `ScrollView`, so that user is always able to reach the screen children.
 */
export function ScreenContainer({
  keyboardAvoidingView = true,
  stickyBottom,
  padding = true,
  style,
  children
}: Props): ReactElement {
  const KeyboardAvoidingView = keyboardAvoidingView
    ? KeyboardAvoidingScreenView
    : React.Fragment;

  const screenContent = padding ? (
    <ScreenPadding>{children}</ScreenPadding>
  ) : (
    children
  );

  if (stickyBottom) {
    return (
      <SafeAreaView style={[{ flex: 1, backgroundColor: Colors.WHITE }, style]}>
        <KeyboardAvoidingView>
          <ScrollView style={{ flex: 1 }}>{screenContent}</ScrollView>
          <StickyBottom safeAreaView={false}>{stickyBottom}</StickyBottom>
        </KeyboardAvoidingView>
      </SafeAreaView>
    );
  }

  return (
    <View style={[{ flex: 1, backgroundColor: Colors.WHITE }, style]}>
      <KeyboardAvoidingView>
        <ScrollView style={{ flex: 1 }}>
          <SafeAreaView>{screenContent}</SafeAreaView>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
}
