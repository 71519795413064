import { SFC } from "@ailo/primitives";
import { Screens, useNavigateToThisScreen, useNavigation } from "local/common";
import React, { useCallback } from "react";
import { Button } from "@ailo/ui";

export const useSetupAutoPayBillsAction = (): (() => void) => {
  const navigation = useNavigation();
  const navigateToThisScreen = useNavigateToThisScreen();

  return useCallback(() => {
    navigation.navigate(Screens.SelectAutoPayBillsWallet, {
      onClose: navigateToThisScreen
    });
  }, [navigateToThisScreen, navigation]);
};

export const SetupAutoBillPayButton: SFC = ({ style }) => {
  const handleSetupAutoPayClick = useSetupAutoPayBillsAction();

  return (
    <Button.Secondary style={style} onPress={handleSetupAutoPayClick}>
      {"Setup Auto Bill Payment"}
    </Button.Secondary>
  );
};
