import { Colors, RightChevronIcon, Text } from "@ailo/primitives";
import React, { ReactElement } from "react";
import { StyleProp, TouchableOpacity, View, ViewStyle } from "react-native";
import styled from "styled-components/native";

interface SetupAutoTransferButtonProp {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
}

function SetupAutoTransferButton({
  onPress,
  style
}: SetupAutoTransferButtonProp): ReactElement {
  return (
    <StyledTouchableOpacity onPress={onPress} style={style}>
      <Text.BodyM weight={"medium"} color={Colors.AILO_RED}>
        {"Setup Auto Transfer"}
      </Text.BodyM>
      <RightChevronIcon color={Colors.AILO_RED} width={20} height={20} />
    </StyledTouchableOpacity>
  );
}

SetupAutoTransferButton.Loading = function Loading({
  style
}: {
  style: StyleProp<ViewStyle>;
}): ReactElement {
  return (
    <View style={style}>
      <Text.BodyM.Loading width={200} />
    </View>
  );
};

const StyledTouchableOpacity = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
`;

export { SetupAutoTransferButton };
