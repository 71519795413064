import { Colors, Text, Link } from "@ailo/primitives";
import { alert, Button } from "@ailo/ui";
import React, { useCallback } from "react";
import { View } from "react-native";
import { useNavigation, Screens } from "local/common";
import {
  PaymentMethodDomainType,
  PaymentMethodTypeDomainEnum
} from "@ailo/domains";
import { capitalize } from "lodash";
import { useDeletePaymentMethod } from "local/domain/ledger/hooks/useDeletePaymentMethod";

export function ConfirmBottomSheetContent({
  onClose,
  paymentMethodDetails,
  onRemoveFetchStart,
  onRemoveFetchSuccess,
  onRemoveFetchError,
  isLastPaymentMethod
}: {
  onClose?(): void;
  onRemoveFetchStart?(): void;
  onRemoveFetchSuccess?(): void;
  onRemoveFetchError?(): void;
  paymentMethodDetails: PaymentMethodDomainType;
  isLastPaymentMethod: boolean;
}): React.ReactElement {
  const deletePaymentMethod = useDeletePaymentMethod();

  const navigation = useNavigation<Screens.PaymentMethodDetails>();

  const showErrorAlert = useCallback((): void => {
    onRemoveFetchError && onRemoveFetchError();

    alert(
      "Error",
      "We were unable to delete your payment method. Please try again and if the problem persists, contact Ailo support",
      [{ text: "Dismiss" }]
    );
  }, [onRemoveFetchError]);

  const handleOnAddAnotherPaymentMethodPress = (): void => {
    onClose && onClose();

    navigation.navigate(Screens.SetupPaymentMethod, {
      onClose: navigation.goBack,
      onSuccess: () => navigation.navigate(Screens.PaymentMethods)
    });
  };

  const handleConfirmOnPress = useCallback(async () => {
    onClose && onClose();

    onRemoveFetchStart && onRemoveFetchStart();

    const success = await deletePaymentMethod(paymentMethodDetails);
    if (!success) {
      showErrorAlert();
    } else {
      onRemoveFetchSuccess && onRemoveFetchSuccess();
      navigation.navigate(Screens.RemovePaymentMethodSuccess, {
        paymentMethodDetails,
        onClose: () => navigation.navigate(Screens.PaymentMethods)
      });
    }
  }, [
    onClose,
    navigation,
    paymentMethodDetails,
    deletePaymentMethod,
    showErrorAlert,
    onRemoveFetchStart,
    onRemoveFetchSuccess
  ]);

  const lastPaymentMethodContent = isLastPaymentMethod ? (
    <Text.BodyM>
      {`\n`}
      {`This is your only payment method.\n`}
      <Link variant={"primary"} onPress={handleOnAddAnotherPaymentMethodPress}>
        {"Add another payment method"}
      </Link>
      {` before removing?`}
    </Text.BodyM>
  ) : undefined;

  return (
    <View
      style={{
        backgroundColor: Colors.WHITE,
        paddingHorizontal: 32,
        paddingTop: 24,
        paddingBottom: 24
      }}
    >
      <Text.DisplayS style={{ marginBottom: 16 }}>
        {isLastPaymentMethod ? "Are you sure?" : "Confirm"}
      </Text.DisplayS>
      <View style={{ minHeight: 176 }}>
        {paymentMethodDetails.__typename ===
          PaymentMethodTypeDomainEnum.BankAccount && (
          <Text.BodyM>
            {`The bank account ${
              paymentMethodDetails.accountName
            } ending in ${paymentMethodDetails.accountNumber!.slice(
              -4
            )} will be removed, any pending payments will still take effect.`}
          </Text.BodyM>
        )}

        {paymentMethodDetails.__typename ===
          PaymentMethodTypeDomainEnum.CreditCard && (
          <Text.BodyM>
            {`The ${
              paymentMethodDetails.type
                ? capitalize(paymentMethodDetails.type)
                : "Credit"
            } card ending in ${paymentMethodDetails.number!.slice(
              -4
            )} will be removed, any pending payments will still take effect.`}
          </Text.BodyM>
        )}

        {lastPaymentMethodContent}
      </View>
      <View style={{ flexDirection: "row-reverse" }}>
        <Button.Primary
          onPress={handleConfirmOnPress}
          style={{ marginLeft: 12 }}
        >
          {"Confirm"}
        </Button.Primary>
        <Button.Secondary onPress={onClose}>{"Cancel"}</Button.Secondary>
      </View>
    </View>
  );
}

ConfirmBottomSheetContent.HEIGHT = 300;
