import React from "react";
import { ViewStyle } from "react-native";
import { StyleProps } from "@ailo/primitives";
import { Card } from "@ailo/ui";
import { RentHistoryList } from "@ailo/domains";
import { RentHistoryHeader, ViewAllRentHistoryButton } from "./components";

interface Props {
  style?: StyleProps<ViewStyle>;
}

export function LoadingRentHistoryCard({ style }: Props): React.ReactElement {
  return (
    <Card style={style}>
      <RentHistoryHeader.Loading />
      <RentHistoryList.Loading />
      <ViewAllRentHistoryButton.Loading />
    </Card>
  );
}
