import React, { useCallback } from "react";
import { View } from "react-native";
import { Colors, EditIcon, Pressable, SFC, Text } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { Screens, useNavigateToThisScreen, useNavigation } from "local/common";

interface Props {
  autoWithdrawPlanId: string;
  textSize?: "small" | "medium";
  canModify?: boolean;
}

const ChangeAutoWithdrawPlanDisplay: SFC<Props> = ({
  style,
  autoWithdrawPlanId,
  textSize = "small",
  canModify = true
}) => {
  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();

  const onChange = useCallback(() => {
    navigation.navigate(Screens.AutoWithdrawPlanEdit, {
      id: autoWithdrawPlanId,
      onSuccess: returnToThisScreen
    });
  }, [navigation, autoWithdrawPlanId, returnToThisScreen]);

  const TextElement = textSize === "small" ? Text.BodyS : Text.BodyM;

  return (
    <Pressable onPress={onChange}>
      <Container style={style}>
        <TextElement color={Colors.TEXT.DARK.SECONDARY}>
          {"Auto transfer enabled"}
        </TextElement>
        {canModify ? (
          <Button.Text onPress={onChange} leftIcon={EditIcon} />
        ) : (
          <Button.Text onPress={onChange}>{"View"}</Button.Text>
        )}
      </Container>
    </Pressable>
  );
};

const Container: SFC = ({ style, children }) => {
  return (
    <View
      style={{
        paddingTop: 12,
        paddingRight: 16,
        paddingLeft: 16,
        paddingBottom: 16,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        ...style
      }}
    >
      {children}
    </View>
  );
};

export { ChangeAutoWithdrawPlanDisplay };
