import React from "react";
import styled from "styled-components/native";
import { View, ViewStyle } from "react-native";
import { Colors, opacify, Text } from "@ailo/primitives";
import { DateTimeWithTimeZone, isLedgerMaxDate, Money } from "@ailo/ui";

const RentOverdueDisplayContainer = styled(View)`
  flex: 1;
  align-items: center;
  padding: 8px;
  background: ${opacify(Colors.STATUS.ERROR, 0.25)};
`;

interface Props {
  style?: ViewStyle;
  overdueAmountInCents: number;
  nextDueDate: string;
  nextDueAmountInCents: number;
}

export function RentOverdueDisplay({
  style,
  overdueAmountInCents,
  nextDueDate,
  nextDueAmountInCents
}: Props): React.ReactElement {
  let amount = Money.fromCents(overdueAmountInCents);

  const nextDue = isLedgerMaxDate(nextDueDate)
    ? undefined
    : DateTimeWithTimeZone.fromDateSubtractingAMillisecond(nextDueDate);
  if (nextDue?.isToday()) {
    amount = Money.fromCents(overdueAmountInCents - nextDueAmountInCents);
  }

  return (
    <RentOverdueDisplayContainer style={style}>
      <Text.DisplayM
        style={{ marginTop: 4 }}
        testID={"rent-overdue-amount-text"}
      >
        {amount.format()}
      </Text.DisplayM>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginVertical: 8 }}
      >
        {"Rent overdue"}
      </Text.BodyM>
    </RentOverdueDisplayContainer>
  );
}
