import { AiloRN } from "@ailo/ailorn";
import { formatAddress } from "@ailo/domain-helpers";
import { Colors, PropertyIcon, SFC, WalletIcon } from "@ailo/primitives";
import { didQueryNotLoadYet, useEnvironment } from "@ailo/services";
import { Alert, BoxWithShadow, ListItem, ListItemHeader } from "@ailo/ui";
import {
  Screens,
  useCurrentLegalEntity,
  useNavigation,
  usePropertySelectorContext,
  useRoute,
  withScreenComponent
} from "local/common";
import { EnsureKycVerified } from "local/domain/authz";
import {
  useAutoPayLiabilityPossible,
  useAutoPayLiabilityStatuses
} from "local/domain/ledger";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import React from "react";
import { View } from "react-native";

const SelectAutoPayBillsWalletScreenContent = (): React.ReactElement => {
  const navigation = useNavigation<Screens.SelectAutoPayBillsWallet>();
  const params = useRoute<Screens.SelectAutoPayBillsWallet>().params;
  const [legalEntity] = useCurrentLegalEntity();
  const { allProperties } = usePropertySelectorContext();
  const autoPayStatusesResult = useAutoPayLiabilityStatuses();
  const { completelyOwnsAllProperties } = useAutoPayLiabilityPossible();
  const { ENABLE_AUTO_BILL_PAY_FROM_PERSONAL_WALLET } = useEnvironment();

  const title = `Which property wallet would you like to setup automatic bill payments from?`;

  if (didQueryNotLoadYet(autoPayStatusesResult)) {
    return (
      <ScreenWithHeaderAndAction backgroundColor={"white"} header={title}>
        <ListItemHeader text={"Property wallets"} />
        <ListItem.Loading />
        <ListItemHeader text={"Personal wallets"} style={{ paddingTop: 24 }} />
        <ListItem.Loading />
      </ScreenWithHeaderAndAction>
    );
  }

  const manageAutoBillPayForEntity = (payerId: AiloRN): void => {
    navigation.navigate(Screens.ManageAutoPayBills, {
      ...params,
      payerId
    });
  };

  const propertiesWithNonZeroSharesOwned = allProperties.filter(
    (p) =>
      p.__typename === "OwnedProperty" && p.ownership.sharesOwnedRatio !== 0
  );

  const isAutoPayBillSetupForPayer = (payerId: AiloRN): boolean => {
    return !!autoPayStatusesResult?.data?.find((s) => s.payerId.equals(payerId))
      ?.enabled;
  };

  const propertyItems = propertiesWithNonZeroSharesOwned
    .filter(
      (p) =>
        !isAutoPayBillSetupForPayer(p.management.id) &&
        !isAutoPayBillSetupForPayer(p.managementFolio.ailorn)
    )
    .map((p) => {
      // MET-250: This will always be true once ledger migrates management wallets to folio wallets
      const autoPayStatusesResultHasFolioAilorn = isAutoPayBillSetupForPayer(
        p.managementFolio.ailorn
      );
      return (
        <ListItem
          key={p.id.toString()}
          header={formatAddress(p?.address, { format: "street" })}
          subHeader={p?.address?.suburb}
          leftComponent={
            <BoxWithShadow style={{ marginRight: 16 }}>
              <PropertyIcon color={Colors.TEXT.DARK.PLACEHOLDER} />
            </BoxWithShadow>
          }
          onPress={() =>
            manageAutoBillPayForEntity(
              autoPayStatusesResultHasFolioAilorn
                ? p.managementFolio.ailorn
                : p.management.id
            )
          }
        />
      );
    });

  const personalWalletItem = (
    ENABLE_AUTO_BILL_PAY_FROM_PERSONAL_WALLET && completelyOwnsAllProperties
      ? [legalEntity.id]
      : []
  )
    .filter((le) => !isAutoPayBillSetupForPayer(le))
    .map((le) => {
      return (
        <ListItem
          key={le.toString()}
          header={"Your personal wallet"}
          leftComponent={
            <View style={{ marginRight: 18 }}>
              <WalletIcon color={Colors.BLACK} />
            </View>
          }
          onPress={() => manageAutoBillPayForEntity(le)}
        />
      );
    });

  return (
    <ScreenWithHeaderAndAction backgroundColor={"white"} header={title}>
      {!!propertyItems.length && <ListItemHeader text={"Property wallets"} />}
      {propertyItems}
      {!!personalWalletItem.length && (
        <ListItemHeader text={"Personal wallets"} style={{ paddingTop: 24 }} />
      )}
      {personalWalletItem}

      {!propertyItems.length && !personalWalletItem.length && (
        <AllSetupInfo style={{ marginHorizontal: 16 }} />
      )}
    </ScreenWithHeaderAndAction>
  );
};

const AllSetupInfo: SFC = ({ style }) => (
  <Alert type={"neutral"} style={style}>
    {"Bill payments already setup"}
  </Alert>
);

export const SelectAutoPayBillsWalletScreen = withScreenComponent(
  (): React.ReactElement => {
    return (
      <EnsureKycVerified>
        <SelectAutoPayBillsWalletScreenContent />
      </EnsureKycVerified>
    );
  }
);
