export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ActionInitiator: ["User", "System"],
    AiloFormField: [
      "AiloFormFieldDate",
      "AiloFormFieldEmpty",
      "AiloFormFieldNumber",
      "AiloFormFieldText",
      "AiloFormFieldYesNo"
    ],
    AiloFormResponse: [
      "AiloFormResponseDate",
      "AiloFormResponseNumber",
      "AiloFormResponseText",
      "AiloFormResponseYesNo"
    ],
    AiloFormTemplateField: [
      "AiloFormTemplateFieldDate",
      "AiloFormTemplateFieldEmpty",
      "AiloFormTemplateFieldNumber",
      "AiloFormTemplateFieldText",
      "AiloFormTemplateFieldYesNo"
    ],
    BillPayee: ["Supplier", "Management", "LegalEntityCompanion"],
    BillPayer: ["Tenancy", "Management", "LegalEntityCompanion"],
    ContactPropertyAddress: [
      "ManagementPropertyAddress",
      "TenancyPropertyAddress"
    ],
    FeeAppliedTo: ["Bill", "Tenancy"],
    Form: ["AiloForm", "ReiForm"],
    LegalEntity: ["Company", "Person"],
    LiabilityEntry: [
      "AdjustmentLiabilityEntry",
      "PaymentLiabilityEntry",
      "PlanBasedLiabilityEntry"
    ],
    LiabilityReferencedEntity: ["Bill", "Fee", "Tenancy"],
    MailDirectionDetails: [
      "InboundMailDirectionDetails",
      "OutboundMailDirectionDetails"
    ],
    OwingReferencedEntity: ["RecurringFee", "Tenancy"],
    PaymentMethod: ["BankAccount", "BPay", "CreditCard", "VirtualAccount"],
    ProblemInterface: ["EditableRentReviewProblem"],
    PurposeReference: ["Bill", "RecurringOwing", "TrustAccountWallet"],
    Role: [
      "LegalEntityPersonRole",
      "LegalEntityRole",
      "OrganisationPersonRole",
      "OrganisationRole"
    ],
    SendEmailVerificationResponse: [
      "SendEmailVerificationSuccessResponse",
      "SendEmailVerificationErrorResponse"
    ],
    StatementLineItem2: [
      "StatementAdjustmentLineItem",
      "StatementTransactionLineItem"
    ],
    StatementSubject: ["LegalEntityCompanion", "Management", "Tenancy"],
    ThreadRelatedEntity: ["Management"],
    TrustAccountWalletEntity: [
      "LegalEntityCompanion",
      "ManagementFolio",
      "Tenancy",
      "Supplier",
      "BondAuthority"
    ],
    VerifyEmailResponse: [
      "VerifyEmailSuccessResponse",
      "VerifyEmailErrorResponse"
    ]
  }
};
export default result;
