import React, { FC } from "react";
import { View } from "react-native";
import { Colors, SFC, Text } from "@ailo/primitives";
import { useEnvironment } from "@ailo/services";
import { useOpenAuthorizedHelpInBrowser } from "@ailo/domains";

interface Props {
  dollarAmount: string;
}

interface Statics {
  Loading: SFC;
}

const FeesDisclaimerDisplay: SFC<Props> & Statics = ({
  dollarAmount,
  style
}) => {
  return (
    <View style={style}>
      <View style={{ flexDirection: "row" }}>
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
          {`Payments incur a service fee. `}
        </Text.BodyS>
        <LearnMoreLink />
      </View>
      <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
        {`Total due: ${dollarAmount}`}
      </Text.BodyS>
    </View>
  );
};

const LearnMoreLink: FC = () => {
  const { FEES_HELP_URL } = useEnvironment();
  const openHelpInBrowser = useOpenAuthorizedHelpInBrowser(FEES_HELP_URL);

  return (
    <Text.BodyS
      onPress={openHelpInBrowser}
      color={Colors.AILO_RED}
      weight={"medium"}
      style={{ textDecorationLine: "underline" }}
    >
      {"Learn more"}
    </Text.BodyS>
  );
};

const Loading: SFC = (props) => {
  return <FeesDisclaimerDisplay {...props} dollarAmount={"$0.00"} />;
};

FeesDisclaimerDisplay.Loading = Loading;

export { FeesDisclaimerDisplay };
