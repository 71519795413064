import React from "react";
import { View } from "react-native";
import { Colors, HouseWithGarden, opacify, SFC, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { LinearGradient } from "expo-linear-gradient";

interface Props {
  header: React.ReactNode;
  summary: React.ReactNode;
}
const SuccessAlertMessage: SFC<Props> = ({ style, header, summary }) => {
  const colors = [opacify(Colors.WHITE, 0), opacify("rgb(110, 143, 203)", 0.2)];

  return (
    <View style={style}>
      <HouseContainer colors={colors}>
        <HouseWithGarden />
      </HouseContainer>
      <Details>
        <Text.DisplayS>{header}</Text.DisplayS>
        <Summary>
          <Text.BodyM style={{ textAlign: "center" }}>{summary}</Text.BodyM>
        </Summary>
      </Details>
    </View>
  );
};

const HouseContainer = styled(LinearGradient)`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

const Details = styled(View)`
  height: 280px;
  padding: 24px;
  align-items: center;
`;

const Summary = styled(View)`
  padding-top: 12px;
`;

export { SuccessAlertMessage };
