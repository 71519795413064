import { AiloSentry } from "@ailo/services";
import { useAnalytics, useCurrentLegalEntity } from "local/common";
import {
  Maybe,
  MoneyInput,
  PaymentMethodDestinationInput,
  QuartzPlanFrequency,
  GetAutoWithdrawPlanByIdDocument,
  useUpdateAutoWithdrawPlanMutation
} from "local/graphql";
import { useCallback } from "react";

export interface UpdateAutoWithdrawPlan {
  id: string;
  paymentMethodDestinations: PaymentMethodDestinationInput[];
  setAsideAmount?: Maybe<MoneyInput>;
  frequency: QuartzPlanFrequency;
}

export function useUpdateAutoWithdrawPlan(): (
  autoWithdrawPlanInput: UpdateAutoWithdrawPlan
) => Promise<boolean> {
  const [updateMutation] = useUpdateAutoWithdrawPlanMutation();
  const [legalEntity] = useCurrentLegalEntity();
  const analytics = useAnalytics();

  return useCallback(
    (autoWithdrawPlanInput: UpdateAutoWithdrawPlan) => {
      return updateMutation({
        variables: {
          updateAutoWithdrawInput: {
            autoWithdrawPlanId: autoWithdrawPlanInput.id,
            paymentMethodDestinations:
              autoWithdrawPlanInput.paymentMethodDestinations,
            setAsideAmount: autoWithdrawPlanInput.setAsideAmount
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GetAutoWithdrawPlanByIdDocument,
            variables: {
              autoWithdrawPlanId: autoWithdrawPlanInput.id,
              legalEntityId: legalEntity.id.toString()
            }
          }
        ]
      })
        .then(() => {
          analytics.trackAutoWithdrawPlanChanged({
            eventName: "Auto Withdraw Edited",
            frequency: autoWithdrawPlanInput.frequency,
            paymentMethodDestinations:
              autoWithdrawPlanInput.paymentMethodDestinations
          });
          return true;
        })
        .catch((error) => {
          AiloSentry.captureException(error);
          return false;
        });
    },
    [updateMutation, legalEntity.id, analytics]
  );
}
