import React from "react";
import { Button, ButtonProps } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";
import { useGetDefaultPaymentMethodIdsForUser } from "local/domain/ledger/hooks";
import { useOnFocus, useNavigateToThisScreen } from "@ailo/services";

interface Props extends ButtonProps {
  shortText?: boolean;
  showPrimaryStyle?: boolean;
  onSuccess?: () => void;
}

function SetupAutoPayRentButton({
  style,
  onSuccess,
  showPrimaryStyle = false,
  shortText = false,
  type,
  ...rest
}: Props): React.ReactElement {
  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();

  const { loading, data, refetch, defaultInPaymentMethodId } =
    useGetDefaultPaymentMethodIdsForUser();

  useOnFocus(refetch);
  if (!data && loading)
    return <SetupAutoPayRentButton.Loading type={type} style={style} />;

  const navigateToAutoPayTypeScreen = (paymentMethodId: string): void => {
    navigation.navigate(Screens.LiabilityPlanChooseType, {
      paymentMethodId,
      onSuccess: onSuccess || returnToThisScreen,
      onClose: returnToThisScreen
    });
  };

  const onAutoPayPress = (): void => {
    if (!defaultInPaymentMethodId) {
      navigation.navigate(Screens.SetupPaymentMethod, {
        onClose: returnToThisScreen,
        onSuccess: navigateToAutoPayTypeScreen
      });
    } else {
      navigateToAutoPayTypeScreen(defaultInPaymentMethodId);
    }
  };

  const ButtonComponent = showPrimaryStyle ? Button.Primary : Button.Secondary;

  return (
    <ButtonComponent
      type={type}
      style={style}
      onPress={onAutoPayPress}
      {...rest}
    >
      {shortText ? "Setup Autopay" : "Setup Auto Rent Payment"}
    </ButtonComponent>
  );
}

SetupAutoPayRentButton.Loading = Button.Loading;

export { SetupAutoPayRentButton };
