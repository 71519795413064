import React, { ReactElement, useCallback, useMemo } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { useLayout } from "@react-native-community/hooks";
import { AiloRN } from "@ailo/ailorn";
import { Colors, LeftChevronIcon } from "@ailo/primitives";
import {
  ChatDraft,
  ChatFileContext,
  ChatSearchBarMobile,
  CommsChatScreenContainer,
  ComposeBox,
  MAX_THREAD_SUBJECT_LENGTH,
  NEW_CHAT_DRAFT_PARENT,
  useChatContext,
  useCreateNewChatFromDraft,
  useThreadListContext,
  validateThread
} from "@ailo/domains";
import {
  ScreenComponent,
  Screens,
  useNavCloseButton,
  useRoute
} from "local/common";
import { useResetNavAndOpenChat } from "local/tabs";

function NewChatMessageScreen(): ReactElement {
  const { onLayout } = useLayout();

  const { organisationId, contacts } =
    useRoute<Screens.NewChatMessage>().params || {};

  const organisationAilorn = AiloRN.of("authz:organisation", organisationId);

  const { drafts } = useChatContext();

  const openChat = useResetNavAndOpenChat();

  const {
    headerState: { setSmall }
  } = useThreadListContext();

  const { createChatFromDraft } = useCreateNewChatFromDraft();

  useNavCloseButton({ icon: LeftChevronIcon });

  const participants = useMemo(
    () => contacts.map((c) => ({ contactAilorn: c.ailorn.toString() })),
    [contacts]
  );

  const createNewChat = useCallback(
    async (draft: ChatDraft, completeSending: () => void) => {
      const createdChatAilorn = await createChatFromDraft({
        draft,
        completeSending,
        participants,
        organisationAilorn
      });
      if (createdChatAilorn) {
        openChat(createdChatAilorn);
      }
    },
    [createChatFromDraft, organisationAilorn, openChat, participants]
  );

  return (
    <ScreenComponent style={{ backgroundColor: Colors.CLOUD }}>
      <CommsChatScreenContainer onLayout={onLayout}>
        <ChatFileContext drafts={drafts} messageParent={NEW_CHAT_DRAFT_PARENT}>
          <ChatSearchBarMobile externallySelectedContacts={contacts} readonly />
          <FillSpace />
          <ComposeBox
            hasSubject={true}
            messageParent={NEW_CHAT_DRAFT_PARENT}
            drafts={drafts}
            onSend={createNewChat}
            validate={validateThread}
            maxSubjectLength={MAX_THREAD_SUBJECT_LENGTH}
            onOpenClose={setSmall}
            alwaysOpen
          />
        </ChatFileContext>
      </CommsChatScreenContainer>
    </ScreenComponent>
  );
}

const FillSpace = styled(View)`
  flex: 1;
  background-color: ${Colors.CLOUD};
`;

export { NewChatMessageScreen };
