import React, { FC } from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors, PropertyIcon, Text } from "@ailo/primitives";
import { BoxWithShadow } from "@ailo/ui";

type Props = {
  address?: string;
};

export const PropertyInfoCard: FC<Props> = ({ address }) => {
  return (
    <InfoCard style={{ marginTop: 12 }}>
      <BoxWithShadow style={{ marginRight: 16, padding: 10, flex: 0.1 }}>
        <PropertyIcon color={Colors.TEXT.DARK.PLACEHOLDER} />
      </BoxWithShadow>
      <View style={{ flex: 0.9 }}>
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
          {"Property Address"}
        </Text.BodyS>
        <Text.BodyS>{address}</Text.BodyS>
      </View>
    </InfoCard>
  );
};

const InfoCard = styled(View)`
  border: 1px solid rgba(28, 30, 38, 0.1);
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: row;
`;
