import React, { ReactElement, useMemo } from "react";
import { SectionList } from "react-native";
import { BaseProperty } from "local/common";
import { UseNewChatParticipants } from "./useNewChatParticipants";
import {
  ParticipantListFooter,
  ParticipantListItem,
  ParticipantsListHeader
} from "./ParticipantList";

interface Props {
  currentAgency: BaseProperty["agency"];
  newChatParticipants: UseNewChatParticipants;
}

export const NewChatParticipantsList = React.memo(
  function NewChatParticipantsList({
    currentAgency,
    newChatParticipants: {
      participantListSections,
      isParticipantSelected,
      onParticipantSelect
    }
  }: Props): ReactElement {
    const lastSectionTitle = useMemo(
      () =>
        participantListSections.length > 0
          ? participantListSections[participantListSections.length - 1].title
          : "",
      [participantListSections]
    );

    return (
      <SectionList
        sections={participantListSections}
        keyExtractor={(item) => item.ailorn.toString()}
        renderSectionHeader={({ section }) => (
          <ParticipantsListHeader section={section} />
        )}
        renderItem={({ section, item, index }) => (
          <ParticipantListItem
            title={section.title}
            participantContact={item}
            currentPropertyAgencyName={currentAgency.organisationName}
            onParticipantSelect={onParticipantSelect}
            isParticipantSelected={isParticipantSelected}
            isLastItem={index === section.data.length - 1}
          />
        )}
        renderSectionFooter={({ section: { title } }) => (
          <ParticipantListFooter
            title={title}
            lastSectionTitle={lastSectionTitle}
          />
        )}
      />
    );
  }
);
