import { ErrorAlertScreen } from "@ailo/ui";
import React, { FC } from "react";

export const PaymentMethodDetailsError: FC<{ onRetry: () => void }> = ({
  onRetry
}) => {
  return (
    <ErrorAlertScreen
      variant={"medium"}
      title={`There was a problem loading \nPayment Methods Details`}
      onRetry={onRetry}
    />
  );
};
