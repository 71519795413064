import { MutationTuple } from "@apollo/client";
import { useCallback, useRef } from "react";
import {
  GetAutoPayLiabilityStatusDocument,
  GetAutoPayLiabilityStatusQuery,
  GetAutoPayLiabilityStatusQueryVariables,
  useCancelAutoPayLiabilityStatusMutation,
  useUpsertAutoPayLiabilityStatusMutation
} from "local/graphql";
import { MoneyInterface } from "@ailo/ui";
import { useCurrentLegalEntity } from "local/common";
import { AiloRN } from "@ailo/ailorn";

export type UpsertAutoPayBillsData =
  | { enabled: false }
  | {
      enabled: true;
      maximumPaymentAmount: MoneyInterface;
    };

export function useUpsertAutoPayLiabilityStatus(
  payerIdArg?: AiloRN
): MutationTuple<{}, UpsertAutoPayBillsData> {
  const [legalEntity] = useCurrentLegalEntity();
  const payerId = payerIdArg
    ? payerIdArg.toString()
    : legalEntity.id.toString();

  const [createMutation, createMutationResult] =
    useUpsertAutoPayLiabilityStatusMutation({
      update(cache, result) {
        cache.writeQuery<
          GetAutoPayLiabilityStatusQuery,
          GetAutoPayLiabilityStatusQueryVariables
        >({
          query: GetAutoPayLiabilityStatusDocument,
          variables: {
            payerLegalEntityId: legalEntity.id.toString(),
            payerId: payerId
          },
          data: {
            autoPayLiabilityStatus: {
              __typename: "AutoPayLiabilityStatus",
              enabled: true,
              maximumPaymentAmount: result.data?.upsertAutoPayLiabilityStatus
                ?.maximumPaymentAmount
                ? {
                    __typename: "Money",
                    ...result.data?.upsertAutoPayLiabilityStatus
                      ?.maximumPaymentAmount
                  }
                : null,
              paymentMethodId:
                result.data?.upsertAutoPayLiabilityStatus?.paymentMethodId,
              payerId: payerId
            }
          }
        });
      }
    });

  const [cancelMutation, cancelMutationResult] =
    useCancelAutoPayLiabilityStatusMutation({
      update(cache) {
        cache.writeQuery<
          GetAutoPayLiabilityStatusQuery,
          GetAutoPayLiabilityStatusQueryVariables
        >({
          query: GetAutoPayLiabilityStatusDocument,
          variables: {
            payerLegalEntityId: legalEntity.id.toString(),
            payerId: payerId
          },
          data: {
            autoPayLiabilityStatus: {
              __typename: "AutoPayLiabilityStatus",
              enabled: false,
              maximumPaymentAmount: null,
              paymentMethodId: null,
              payerId: payerId
            }
          }
        });
      }
    });

  const recentlyCalled = useRef<"create" | "cancel">();

  const toggleMutation = useCallback(
    (options?: { variables?: UpsertAutoPayBillsData }) => {
      if (options?.variables?.enabled === undefined) {
        throw new TypeError(`options.variables.enabled is missing`);
      }

      if (options.variables.enabled) {
        recentlyCalled.current = "create";
        return createMutation({
          variables: {
            payerLegalEntityId: legalEntity.id.toString(),
            payerId: payerId,
            maximumPaymentAmount: {
              cents: options.variables.maximumPaymentAmount.cents
            }
          }
        });
      } else {
        recentlyCalled.current = "cancel";
        return cancelMutation({
          variables: {
            payerLegalEntityId: legalEntity.id.toString(),
            payerId: payerId
          }
        });
      }
    },
    [cancelMutation, createMutation, legalEntity.id, payerId]
  );

  const toggleMutationResult =
    recentlyCalled.current === "cancel"
      ? cancelMutationResult
      : createMutationResult;

  return [toggleMutation, toggleMutationResult];
}
