import React, { useCallback } from "react";
import { Screens, useNavigation } from "local/common";
import { Colors, SFC, Text } from "@ailo/primitives";

const LinkToWalletScreen: SFC = () => {
  const navigation = useNavigation();
  const navigateToWalletScreen = useCallback(() => {
    navigation.navigate(Screens.WalletOverview);
  }, [navigation]);

  return (
    <Text.BodyM
      onPress={navigateToWalletScreen}
      color={Colors.AILO_RED}
      weight={"medium"}
    >
      {"Wallet"}
    </Text.BodyM>
  );
};

export { LinkToWalletScreen };
