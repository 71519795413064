import React from "react";
import styled from "styled-components/native";
import { View, ViewStyle } from "react-native";
import { Colors } from "@ailo/primitives";
import { RentDueDisplay, RentOverdueDisplay } from "./components";
import { DateTimeWithTimeZone, isLedgerMaxDate } from "@ailo/ui";
import { LiabilityData } from "../../parseLiabilityData";

interface Props {
  style?: ViewStyle;
  liabilityData: LiabilityData;
}

const RentDisplayContainer = styled(View)`
  flex-direction: row;
  justify-content: space-evenly;
  background: ${Colors.WHITE};
  border-radius: 12px;
  overflow: hidden;
`;

function RentStatusDisplay({
  style,
  liabilityData
}: Props): React.ReactElement {
  const isOverdue =
    !isLedgerMaxDate(liabilityData.dueDate) &&
    DateTimeWithTimeZone.fromDateSubtractingAMillisecond(
      liabilityData.dueDate
    ).isOverdue() &&
    liabilityData.overdueAmount.cents > 0;

  const isTerminatingWithNoNextRentDue =
    liabilityData.nextDueAmount.cents === 0 && !!liabilityData.terminationDate;

  return (
    <RentDisplayContainer style={style}>
      {isOverdue && (
        <RentOverdueDisplay
          overdueAmountInCents={liabilityData.overdueAmount.cents}
          nextDueDate={liabilityData.nextDueDate}
          nextDueAmountInCents={liabilityData.nextDueAmount.cents}
        />
      )}
      {!isTerminatingWithNoNextRentDue && (
        <RentDueDisplay
          rentDueAmount={liabilityData.nextDueAmount.cents}
          rentDueDate={liabilityData.nextDueDate}
          autoPaymentEnabled={liabilityData.onDueAutoPaymentEnabled}
        />
      )}
    </RentDisplayContainer>
  );
}

function Loading({ style }: { style?: ViewStyle }): React.ReactElement {
  return (
    <RentDisplayContainer style={style}>
      <RentDueDisplay.Loading />
    </RentDisplayContainer>
  );
}

RentStatusDisplay.Loading = Loading;

export { RentStatusDisplay };
