import { TypedStackNavigator } from "@react-navigation/stack";
import { AppNavigatorParamList, Screens } from "local/common";
import React from "react";
import { AboutScreen } from "./AboutScreen";
import { AddProfileDetailsScreen } from "./AddProfileDetailsScreen";
import { AutomaticPaymentsScreen } from "./AutomaticPaymentsScreen";
import { EditAddressScreen } from "./EditAddressScreen";
import { EditBirthDateScreen } from "./EditBirthDateScreen";
import { EditEmailAddressScreen } from "./EditEmailAddressScreen";
import { EditGivenNameScreen } from "./EditGivenNameScreen";
import { EditPreferredNameScreen } from "./EditPreferredNameScreen";
import { EditProfilePhotoScreen } from "./EditProfilePhotoScreen";
import { EditProfileScreen } from "./EditProfileScreen";
import { PaymentHistoryScreen } from "./PaymentHistoryScreen";
import { PaymentMethodDetailsScreen } from "./PaymentMethodDetailsScreen";
import { RemovePaymentMethodSuccessScreen } from "./RemovePaymentMethodSuccessScreen";
import { PaymentMethodsScreen } from "./PaymentMethodsScreen";
import { ProfileScreen } from "./ProfileScreen";
import {
  SelectAutoPayBillsWalletScreen,
  ManageAutoPayBillsScreen
} from "./SetupAutoPayBillsScreen";
import { VerifyEmailAddressScreen } from "./VerifyEmailAddressScreen";

export const getProfileScreens = (
  Stack: TypedStackNavigator<AppNavigatorParamList>
): React.ReactElement => {
  return (
    <>
      <Stack.Screen name={Screens.Profile} component={ProfileScreen} />
      <Stack.Screen name={Screens.EditProfile} component={EditProfileScreen} />
      <Stack.Screen
        name={Screens.EditProfilePhoto}
        component={EditProfilePhotoScreen}
      />
      <Stack.Screen name={Screens.EditAddress} component={EditAddressScreen} />
      <Stack.Screen
        name={Screens.EditBirthDate}
        component={EditBirthDateScreen}
      />
      <Stack.Screen
        name={Screens.EditEmailAddress}
        component={EditEmailAddressScreen}
      />
      <Stack.Screen
        name={Screens.VerifyEmailAddress}
        component={VerifyEmailAddressScreen}
      />
      <Stack.Screen
        name={Screens.EditGivenName}
        component={EditGivenNameScreen}
      />
      <Stack.Screen
        name={Screens.EditPreferredName}
        component={EditPreferredNameScreen}
      />
      <Stack.Screen
        name={Screens.AddProfileDetails}
        component={AddProfileDetailsScreen}
      />
      <Stack.Screen
        name={Screens.PaymentMethods}
        component={PaymentMethodsScreen}
      />
      <Stack.Screen
        name={Screens.PaymentHistory}
        component={PaymentHistoryScreen}
      />
      <Stack.Screen
        name={Screens.AutomaticPayments}
        component={AutomaticPaymentsScreen}
      />
      <Stack.Screen
        name={Screens.SelectAutoPayBillsWallet}
        component={SelectAutoPayBillsWalletScreen}
      />
      <Stack.Screen
        name={Screens.ManageAutoPayBills}
        component={ManageAutoPayBillsScreen}
      />
      <Stack.Screen
        name={Screens.PaymentMethodDetails}
        component={PaymentMethodDetailsScreen}
      />
      <Stack.Screen
        name={Screens.RemovePaymentMethodSuccess}
        component={RemovePaymentMethodSuccessScreen}
        options={{ gestureEnabled: false }}
      />
      <Stack.Screen name={Screens.About} component={AboutScreen} />
    </>
  );
};
