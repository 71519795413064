import { AiloRN } from "@ailo/ailorn";
import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { QueryHookOptions, ApolloError } from "@apollo/client";
import { LegalEntity } from "local/common";
import {
  GetSetupDataQuery,
  GetSetupDataQueryVariables,
  useGetSetupDataQuery
} from "local/graphql";

export type SetupData = Omit<GetSetupDataQuery, "companies"> & {
  effectiveUser: NonNullable<GetSetupDataQuery["effectiveUser"]> & {
    person: NonNullable<GetSetupDataQuery["effectiveUser"]>["person"];
  };
  tenancies: NonNullable<GetSetupDataQuery["tenancies"]>;
  managements: NonNullable<GetSetupDataQuery["managements"]>;
  person: LegalEntity;
  companies: LegalEntity[];
};

export function useGetSetupData(
  opts: QueryHookOptions<GetSetupDataQuery, GetSetupDataQueryVariables>
): MappedQueryResult<SetupData, GetSetupDataQueryVariables> {
  const result = useGetSetupDataQuery(opts);

  return useMappedQueryResult(result, (data) => {
    if (!data.effectiveUser?.person) {
      throw new ApolloError({
        errorMessage: `effectiveUser.person is null in GetSetupDataQuery`
      });
    }

    if (!data.companies) {
      throw new ApolloError({
        errorMessage: `companies is null in GetSetupDataQuery`
      });
    }

    if (!data.tenancies) {
      throw new ApolloError({
        errorMessage: `tenancies is null in GetSetupDataQuery`
      });
    }

    if (!data.managements) {
      throw new ApolloError({
        errorMessage: `managements is null in GetSetupDataQuery`
      });
    }

    const person: LegalEntity = {
      ...data.effectiveUser.person,
      id: AiloRN.fromString(data.effectiveUser.person.ailoRN)
    };

    const companies: LegalEntity[] = data.companies.map((c) => ({
      ...c,
      id: AiloRN.fromString(c.ailoRN)
    }));

    const nextData: SetupData = {
      ...data,
      effectiveUser: data.effectiveUser,
      tenancies: data.tenancies,
      managements: data.managements,
      person,
      companies
    };

    return nextData;
  });
}
