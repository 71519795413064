import { CardStyleInterpolators } from "@react-navigation/stack";
import { AppNavigatorStack, Screens } from "local/common";
import React from "react";
import {
  AddBankAccountScreen,
  AddOnceOffBankAccountScreen
} from "./AddBankAccountScreen";
import { AddCreditDebitCardScreen } from "./AddCreditDebitCardScreen";
import { SetupPaymentMethodScreen } from "./SetupPaymentMethodScreen";

const getAddPaymentMethodScreens = (
  Stack: AppNavigatorStack
): React.ReactElement => {
  return (
    <>
      <Stack.Screen
        name={Screens.SetupPaymentMethod}
        component={SetupPaymentMethodScreen}
      />
      <Stack.Screen
        name={Screens.AddCreditDebitCard}
        component={AddCreditDebitCardScreen}
      />
      <Stack.Screen
        name={Screens.AddBankAccount}
        component={AddBankAccountScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS
        }}
      />
      <Stack.Screen
        name={Screens.AddOnceOffBankAccount}
        component={AddOnceOffBankAccountScreen}
      />
    </>
  );
};

export { getAddPaymentMethodScreens };
