import {
  didQueryNotLoadYet,
  didQuerySucceed,
  logApolloResultFailed,
  useNavigateToThisScreen
} from "@ailo/services";
import { ErrorAlertScreen, SpinnerOverlay } from "@ailo/ui";
import {
  Screens,
  useNavigateToPreviousScreen,
  useNavigation
} from "local/common";
import React, { useEffect } from "react";
import { useKycStatus } from "../useKycStatus";

interface EnsureKycVerifiedProps {
  /**
   * Will be passed to the `AddProfileDetailsScreen` as `route.params.onClose`.
   *
   * @default useNavigateToPreviousScreen()
   */
  onKycCheckDismiss?(): void;
  children?: React.ReactNode;
}

/**
 * When mounted, ensures that current user is `kycVerified`,
 * and if not, will redirect them to `AddProfileDetailsScreen`.
 *
 * After the user finishes KYC, they will be returned back to the current screen.
 */
export function EnsureKycVerified({
  onKycCheckDismiss: onKycCheckDismissProp,
  children
}: EnsureKycVerifiedProps): React.ReactElement | null {
  const { result, missing: kycMissing, required: kycRequired } = useKycStatus();
  const returnToThisScreen = useNavigateToThisScreen({ replace: true });
  const navigateToPreviousScreen = useNavigateToPreviousScreen();
  const navigation = useNavigation();
  const onKycCheckDismiss = onKycCheckDismissProp ?? navigateToPreviousScreen;

  useEffect(() => {
    if (kycMissing) {
      navigation.replace(Screens.AddProfileDetails, {
        onClose: onKycCheckDismiss,
        onProceed: returnToThisScreen
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycMissing]);

  if (kycRequired && didQueryNotLoadYet(result)) {
    return <SpinnerOverlay />;
  }

  if (kycRequired && (!didQuerySucceed(result) || !result.data.actualUser)) {
    logApolloResultFailed(result, {
      operationId: "kycResult"
    });
    return <ErrorAlertScreen onRetry={result.refetch} />;
  }

  if (kycMissing) {
    return null;
  }

  return <>{children}</>;
}
