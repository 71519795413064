import React from "react";
import { ViewStyle } from "react-native";
import { StyleProps } from "@ailo/primitives";
import { useTenantRentHistory } from "@ailo/domains";
import {
  didQueryFail,
  didQueryNotLoadYet,
  useClearPreviousDataOnVariablesChange
} from "@ailo/services";
import { useOnFocus } from "local/common";
import { RentHistoryCard } from "../RentHistoryCard";

interface Props {
  tenancyId: string;
  style?: StyleProps<ViewStyle>;
}

function TenantRentHistoryCard({
  tenancyId,
  style
}: Props): React.ReactElement {
  const result = useClearPreviousDataOnVariablesChange(useTenantRentHistory, {
    variables: { tenancyId, pageSize: 1000 }
  });
  const { data, refetch } = result;
  useOnFocus(refetch);

  if (didQueryNotLoadYet(result)) {
    return <RentHistoryCard.Loading style={style} />;
  }

  if (didQueryFail(result) || !data || data.liability.paidToDate == null) {
    return <RentHistoryCard.Error refetch={refetch} style={style} />;
  }

  const { liability, pageInfo } = data;
  const totalItems = pageInfo?.total;

  if (!totalItems) {
    return <RentHistoryCard.Empty style={style} />;
  }

  return (
    <RentHistoryCard
      managementId={undefined}
      tenancyId={tenancyId}
      nextDueDate={liability.nextDueDate}
      overdueAmount={liability.overdueAmount}
      nextDueAmount={liability.nextDueAmount}
      style={style}
    />
  );
}

TenantRentHistoryCard.Loading = RentHistoryCard.Loading;
TenantRentHistoryCard.Empty = RentHistoryCard.Empty;

export { TenantRentHistoryCard };
