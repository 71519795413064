import Big from "big.js";
import { PaymentMethodDestinationInput } from "local/graphql";
import { BankAccountPaymentMethodOption } from "../../types";

const MAX_PRECISION = 4;

function getNumberOfDecimalPlaces(number: number): number {
  if (number % 1 === 0) return 0;
  return number.toString().split(".")[1].length;
}

export function createPaymentMethodDestinationInput(
  input: BankAccountPaymentMethodOption
): PaymentMethodDestinationInput {
  if (input.percentage === undefined)
    throw new Error("payment destination must have a defined percentage");

  if (getNumberOfDecimalPlaces(input.percentage) > MAX_PRECISION) {
    throw new Error(
      `payment destination percentage should have a maximum of ${MAX_PRECISION} decimal places`
    );
  }

  return {
    paymentMethodId: input.id,
    share: Big(input.percentage)
      .mul(10 ** MAX_PRECISION)
      .toNumber()
  };
}
