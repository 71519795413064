import { ErrorBoundary } from "@ailo/services";
import React, { ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { UpcomingBillsCardErrorComponent } from "./UpcomingBillsCardErrorComponent";

export function UpcomingBillsCardErrorBoundary({
  style,
  children
}: {
  style?: StyleProp<ViewStyle>;
  children?: ReactNode;
}): React.ReactElement {
  return (
    <ErrorBoundary
      fallbackComponent={(): React.ReactElement => (
        <UpcomingBillsCardErrorComponent style={style} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
}
