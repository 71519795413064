import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  withScreenComponent,
  useCurrentLegalEntity,
  Screens,
  useRoute,
  AddCreditDebitCardForm,
  useNavigateToThisScreen
} from "local/common";
import { useGatewayCapturePaymentDetailsQuery } from "local/graphql";

export const AddCreditDebitCardScreen = withScreenComponent(
  (): React.ReactElement => {
    const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
    const [legalEntity] = useCurrentLegalEntity();

    const { loading, data } = useGatewayCapturePaymentDetailsQuery({
      fetchPolicy: "no-cache",
      variables: {
        owner: legalEntity.id.toString()
      }
    });

    const uri = data?.gatewayCapturePaymentDetails?.url;
    const { onSuccess } = useRoute<Screens.AddCreditDebitCard>().params;
    const returnToThisScreen = useNavigateToThisScreen({ replace: true });

    if (!data && loading)
      return (
        <AddCreditDebitCardForm.Loading
          style={{ flex: 1, marginBottom: bottomSafeAreaInset }}
        />
      );

    if (!uri) throw new Error("No Credit Card Uri found");

    return (
      <AddCreditDebitCardForm
        style={{ flex: 1, marginBottom: bottomSafeAreaInset }}
        uri={uri}
        onSuccess={onSuccess}
        onError={returnToThisScreen}
      />
    );
  }
);
