import {
  Colors,
  IconProps,
  RightChevronIcon,
  PeopleIcon,
  Text,
  opacify,
  AiloWholeWordIcon
} from "@ailo/primitives";
import { PlatformFeatureId } from "local/graphql";
import { Screens, useNavigation } from "local/common";
import React from "react";
import { StyleProp, View, ViewStyle, TouchableOpacity } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useGlobal } from "reactn";
import styled from "styled-components/native";
import { PropertySelect } from "./PropertySelect";
import { Money } from "@ailo/ui";
import { useWalletAvailableBalance } from "./useWalletAvailableBalance";
import { useHasFeature } from "@ailo/domains";

const StyledContainer = styled(View)`
  background-color: ${Colors.CHARCOAL};
  border-bottom-width: 1px;
  border-color: ${opacify(Colors.WHITE, 0.1)};
`;

const StyledInnerContainer = styled(View)`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const StyledLeftSide = styled(View)`
  flex: 1;
  margin-right: auto;
  align-items: flex-start;
`;

const StyledCenter = styled(View)`
  flex: 1;
  align-items: center;
  margin-left: 42px;
`;

const StyledRightSide = styled(View)`
  height: 100%;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButton = styled(View)<{
  icon?: boolean;
}>`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 32px;
  ${({ icon }): string => (icon ? "width: 32px;" : "")}
  padding: 0 16px;
  margin-left: 8px;
  background-color: ${Colors.SPACE_BLACK};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: ${({ icon }): string => (icon ? "32px" : "16px")};
`;

interface HeaderBarButtonProps {
  label?: string;
  hasRightArrow?: boolean;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  icon?: React.ComponentType<IconProps>;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  onPress?(): void;
}
function HeaderBarButton({
  label,
  hasRightArrow,
  accessibilityLabel = label,
  accessibilityHint,
  icon: Icon,
  style,
  onPress,
  testID
}: HeaderBarButtonProps): React.ReactElement {
  return (
    <TouchableOpacity
      accessible
      accessibilityRole={"button"}
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      style={style}
      testID={testID}
      onPress={onPress}
    >
      <StyledButton icon={!!Icon}>
        {label && <Text.BodyM color={Colors.WHITE}>{label}</Text.BodyM>}
        {Icon && (
          <Icon
            color={Colors.WHITE}
            width={18}
            height={18}
            style={{ minWidth: 18 }}
          />
        )}
        {hasRightArrow && (
          <RightChevronIcon color={Colors.AILO_RED} width={20} height={20} />
        )}
      </StyledButton>
    </TouchableOpacity>
  );
}

export const HeaderBar = React.memo(function HeaderBar({
  onPropertySelectorOpen
}: {
  onPropertySelectorOpen?(): void;
}): React.ReactElement {
  const [availableFeatures] = useGlobal("availableFeatures");
  const hasWalletAccess = availableFeatures.includes(
    PlatformFeatureId.WalletAccess
  );
  const isInvestorOrTenant = [
    PlatformFeatureId.TenantAccess,
    PlatformFeatureId.InvestorAccess
  ].some((feature) => availableFeatures.includes(feature));

  const walletAmountResult = useWalletAvailableBalance({
    skip: !hasWalletAccess
  });
  const formattedWalletAmount = walletAmountResult.data
    ? Money.from(walletAmountResult.data).format()
    : undefined;

  const navigation = useNavigation();
  const { top: topSafeAreaInset } = useSafeAreaInsets();

  const propertyWalletEnabled = useHasFeature(
    PlatformFeatureId.InvestorPropertyWallet
  );

  return (
    <StyledContainer
      style={{ paddingTop: topSafeAreaInset }}
      testID={"HeaderBar"}
    >
      <StyledInnerContainer>
        {isInvestorOrTenant && (
          <StyledLeftSide>
            <PropertySelect onPress={onPropertySelectorOpen} />
          </StyledLeftSide>
        )}

        {!isInvestorOrTenant && (
          <StyledCenter>
            <AiloWholeWordIcon height={21} width={50} />
          </StyledCenter>
        )}

        <StyledRightSide>
          {isInvestorOrTenant &&
            formattedWalletAmount &&
            !propertyWalletEnabled && (
              <HeaderBarButton
                label={formattedWalletAmount}
                hasRightArrow
                accessibilityLabel={`Your current wallet amount: ${formattedWalletAmount}`}
                accessibilityHint={"Navigates to Wallet screen"}
                onPress={(): void => {
                  navigation.navigate(Screens.WalletOverview);
                }}
              />
            )}
          <HeaderBarButton
            icon={PeopleIcon}
            accessibilityLabel={"Go to Your Profile"}
            accessibilityHint={"Navigates to Your Profile screen"}
            testID={"profile-button"}
            onPress={(): void => {
              navigation.navigate(Screens.Profile);
            }}
          />
        </StyledRightSide>
      </StyledInnerContainer>
    </StyledContainer>
  );
});
