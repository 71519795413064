import {
  Screens,
  useCurrentLegalEntity,
  useNavigation,
  useRoute
} from "local/common";
import React, { FC, useCallback } from "react";
import { isEmpty } from "lodash";
import { isPresent } from "ts-is-present";
import { LoadingScreen } from "../common";
import { PaymentMethodListContainer } from "local/domain/ledger";
import { useGetPaymentMethodsQuery } from "local/graphql";
import { OtherPaymentMethodListItem } from "../../changePaymentMethod/ChangePaymentMethodScreen/components";

export const SelectPaymentMethodScreen: FC = () => {
  const [legalEntity] = useCurrentLegalEntity();

  const { loading, data } = useGetPaymentMethodsQuery({
    variables: {
      legalEntityId: legalEntity.id.toString()
    }
  });

  const { liabilityId, amount } =
    useRoute<Screens.TenancyDepositSelectPaymentMethod>().params;
  const navigation = useNavigation();

  const onPaymentMethodSelection = useCallback(
    (paymentMethodId: string): void => {
      navigation.navigate(Screens.TenancyDepositConfirmPayment, {
        liabilityId,
        amount,
        paymentMethodId
      });
    },
    [liabilityId, amount, navigation]
  );

  const onAddPaymentMethod = useCallback(() => {
    navigation.navigate(Screens.TenancyDepositAddPaymentMethod, {
      liabilityId,
      amount
    });
  }, [liabilityId, amount, navigation]);

  if (loading) {
    return <LoadingScreen />;
  }

  const paymentMethods = data?.legalEntity?.paymentMethods?.items;
  const hasPaymentMethods = !isEmpty(paymentMethods?.filter(isPresent));
  if (!hasPaymentMethods) {
    onAddPaymentMethod();
  }

  const otherPaymentMethodsSection = [
    {
      title: "Other",
      data: [
        <OtherPaymentMethodListItem
          key={"1"}
          onSuccess={onPaymentMethodSelection}
        />
      ]
    }
  ];

  return (
    <PaymentMethodListContainer
      onAddPaymentMethodPress={onAddPaymentMethod}
      onPaymentMethodSelection={onPaymentMethodSelection}
      moreSections={otherPaymentMethodsSection}
      buttonVariant={"secondary"}
    />
  );
};
