import React, { FC, useCallback } from "react";
import { ListItem } from "@ailo/ui";
import { useNavigation, useRoute, Screens } from "local/common";
import { RightChevronIcon } from "@ailo/primitives";

const AddOtherListItem: FC = () => {
  const { onSuccess } = useRoute<Screens.SetupPaymentMethod>().params;
  const navigation = useNavigation<Screens.SetupPaymentMethod>();

  const addOtherPaymentMethod = useCallback(() => {
    navigation.navigate(Screens.OtherPaymentMethodsScreen, { onSuccess });
  }, [navigation, onSuccess]);

  return (
    <ListItem
      header={"Other payment method"}
      testID={"other-list-item"}
      onPress={addOtherPaymentMethod}
      style={{ alignItems: "center" }}
      rightComponent={<RightChevronIcon width={20} height={20} />}
    />
  );
};

export { AddOtherListItem };
