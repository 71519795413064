import { TypedStackNavigator } from "@react-navigation/stack";
import { AppNavigatorParamList, Screens } from "local/common";
import React from "react";
import { TenantRentHistoryDetailScreen } from "./TenantRentHistoryDetailScreen";
import { TenantRentHistoryScreen } from "./TenantRentHistoryScreen";

export const getTenantRentTabModalScreens = (
  Stack: TypedStackNavigator<AppNavigatorParamList>
): React.ReactElement => {
  return (
    <>
      <Stack.Screen
        name={Screens.TenantRentHistory}
        component={TenantRentHistoryScreen}
        options={{
          title: "Rent History"
        }}
      />
      <Stack.Screen
        name={Screens.TenantRentHistoryDetail}
        component={TenantRentHistoryDetailScreen}
        options={{
          title: ""
        }}
      />
    </>
  );
};
