import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Button } from "@ailo/ui";

interface Props {
  onPress: () => void;
}

function ViewAllRentHistoryButton({ onPress }: Props): React.ReactElement {
  return (
    <StyledView>
      <Button variant={"secondary"} onPress={onPress}>
        {"View All Rent History"}
      </Button>
    </StyledView>
  );
}

function Loading(): React.ReactElement {
  return (
    <StyledView>
      <Button.Loading />
    </StyledView>
  );
}

const StyledView = styled(View)`
  padding: 16px;
`;

ViewAllRentHistoryButton.Loading = Loading;

export { ViewAllRentHistoryButton };
