import React, { useCallback } from "react";
import { ViewStyle } from "react-native";
import { isEmpty } from "lodash";
import { SFC, StyleProps } from "@ailo/primitives";
import { ErrorAlertScreen, ListItemBorderType } from "@ailo/ui";
import { didQueryFail, didQueryNotLoadYet, useOnFocus } from "@ailo/services";
import {
  RentHistoryList,
  useGetRentHistoryForTenancyQuery,
  useHasFeature,
  computeRentBalance,
  RentLiabilityEntryFragment
} from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { Screens, useNavigation } from "local/common";
import { RentBalanceHeader } from "../../../../tabs/investorRent/InvestorRentHistoryScreen/components";

interface Props {
  managementId: string;
  tenancyId: string;
  hasFooter?: boolean;
  emptyView?: SFC;
  length?: number;
  bounces?: boolean;
  lastPaymentListItemBorderType?: ListItemBorderType;
  showRentBalanceHeader?: boolean;
  style?: StyleProps<ViewStyle>;
}

function InvestorRentHistoryList({
  managementId,
  tenancyId,
  hasFooter,
  emptyView: EmptyView = RentHistoryList.Empty,
  length,
  bounces,
  lastPaymentListItemBorderType,
  showRentBalanceHeader,
  style
}: Props): React.ReactElement {
  const navigation = useNavigation();

  const listItemOnPress = useCallback(
    (liabilityEntryId: string) => {
      navigation.navigate(Screens.InvestorRentHistoryDetail, {
        managementId,
        liabilityEntryId
      });
    },
    [navigation, managementId]
  );

  const result = useGetRentHistoryForTenancyQuery({
    variables: { tenancyId, pageSize: 1000 }
  });
  useOnFocus(result.refetch);

  const hasLedgerRemakeFeature = useHasFeature(PlatformFeatureId.LedgerRemake);

  if (didQueryNotLoadYet(result)) {
    return <RentHistoryList.Loading style={style} />;
  }

  const { data } = result;
  const liability = data?.tenancy?.liability;
  const entries = liability?.entries?.items;
  const computedRentBalance = hasLedgerRemakeFeature
    ? computeRentBalance({
        nextDueDate: liability?.nextDueDate,
        overdueAmount: liability?.overdueAmount,
        nextDueAmount: liability?.nextDueAmount
      })
    : undefined;

  if (
    didQueryFail(result) ||
    entries == null ||
    liability?.paidToDate == null
  ) {
    return (
      <ErrorAlertScreen
        variant={"medium"}
        title={"There was a problem loading\nRent History"}
        onRetry={result.refetch}
        style={{ paddingHorizontal: 32 }}
      />
    );
  }

  if (isEmpty(entries)) {
    return <EmptyView style={style} />;
  }

  return (
    <RentHistoryList
      style={style}
      liabilityFirstPaymentDate={liability?.firstPaymentDate}
      liabilityPaidToDate={liability?.paidToDate}
      liabilityTerminationDate={liability?.terminationDate}
      liabilityEntries={entries as unknown as RentLiabilityEntryFragment[]}
      numEntriesToDisplay={length}
      hasFooter={hasFooter}
      bounces={bounces}
      lastPaymentListItemBorderType={lastPaymentListItemBorderType}
      listHeaderComponent={
        showRentBalanceHeader ? (
          <RentBalanceHeader {...computedRentBalance} />
        ) : undefined
      }
      rentHistoryListType={"INVESTOR"}
      listItemOnPress={listItemOnPress}
    />
  );
}

InvestorRentHistoryList.Loading = RentHistoryList.Loading;
InvestorRentHistoryList.Empty = RentHistoryList.Empty;

export { InvestorRentHistoryList };
