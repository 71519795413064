import React, { FC, useEffect, useMemo } from "react";
import { Cloud } from "@ailo/primitives";
import { Animated, Easing, View, ViewStyle } from "react-native";
import { useLayout } from "./useLayout";
import { Color } from "react-native-svg";

interface AnimationConfig {
  scale?: number;
  topOverlap?: number;
  delay: number;
  duration: number;
  opacity: {
    start: number;
    end: number;
  };
}

interface Props {
  style?: ViewStyle;
  config?: AnimationConfig[];
  color?: Color;
}

const FloatingClouds: FC<Props> = ({ style, config, color }) => {
  const [{ width }, onLayout] = useLayout();

  const clouds = useMemo(
    () =>
      (config || defaultConfig).map((c) => ({
        ...c,
        progress: new Animated.Value(0)
      })),
    [config]
  );

  useEffect(() => {
    Animated.parallel(
      clouds.map((c) =>
        Animated.loop(
          Animated.sequence([
            Animated.delay(c.delay),
            Animated.timing(c.progress, {
              toValue: 1,
              duration: c.duration,
              easing: Easing.linear,
              useNativeDriver: false
            })
          ])
        )
      )
    ).start();
  }, [clouds]);

  return (
    <View style={{ ...style }} onLayout={onLayout}>
      {clouds.map((c, i) => (
        <Animated.View
          key={i}
          style={{
            opacity: c.progress.interpolate({
              inputRange: [0, 1],
              outputRange: [c.opacity.start, c.opacity.end]
            }),
            transform: [
              {
                translateX: c.progress.interpolate({
                  inputRange: [0, 1],
                  outputRange: [-60, width]
                })
              }
            ],
            marginTop: c.topOverlap || 0
          }}
        >
          <Cloud
            opacity={1}
            style={{ transform: [{ scale: c.scale || 1 }] }}
            color={color}
          />
        </Animated.View>
      ))}
    </View>
  );
};

const defaultConfig: AnimationConfig[] = [
  { delay: 0, duration: 25000, opacity: { start: 0.1, end: 0.05 } },
  {
    delay: 4000,
    duration: 37000,
    scale: 0.8,
    opacity: { start: 0.05, end: 0.025 }
  }
];

export { FloatingClouds };
