import React, { ReactNode, FC, useCallback, useEffect } from "react";
import { Image, View, Dimensions } from "react-native";
import styled from "styled-components/native";
import { AccountSecure, Text, Colors } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { useNavigation } from "local/common";
import {
  ScreenContainer,
  Header
} from "local/domain/onboarding/phoneVerification";
import { useCompleteOnboardingTask } from "local/domain/onboarding";
import { OnboardingTaskId } from "local/graphql";

export const YourAccountIsSecureScreen: FC = () => {
  const navigation = useNavigation();
  const completeOnboardingTask = useCompleteOnboardingTask();

  const HeaderTitle = (): ReactNode => {
    return <Header />;
  };

  useEffect(() => {
    navigation.setOptions({
      title: "",
      headerRight: HeaderTitle
    });
  }, [navigation]);

  const onPressCallback = useCallback((): void => {
    completeOnboardingTask(OnboardingTaskId.VerifyPhoneNumber);
  }, [completeOnboardingTask]);

  return (
    <ScreenContainer>
      <ImageContainer>
        <StyledImage source={AccountSecure} />
      </ImageContainer>
      <Container>
        <Title weight={"medium"} numberOfLines={2}>
          {"Your account\nis secure"}
        </Title>
        <StyledButton onPress={onPressCallback} type={"large"}>
          <Text.BodyL weight={"medium"} color={Colors.TEXT.LIGHT.PRIMARY}>
            {"Okay"}
          </Text.BodyL>
        </StyledButton>
      </Container>
    </ScreenContainer>
  );
};

const imageWidth = 1.42 * Dimensions.get("window").width - 258;
const imageHeight = 1.02 * imageWidth;
const StyledImage = styled(Image)`
  width: ${imageWidth}px;
  height: ${imageHeight}px;
`;

const ImageContainer = styled(View)`
  flex: 1;
  margin-top: 20px;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Container = styled(View)`
  padding: 0 40px;
`;

const Title = styled(Text.DisplayS)`
  text-align: center;
`;

const StyledButton = styled(Button.Primary)`
  margin-top: 92px;
  margin-bottom: 56px;
  padding: 16px 0;
`;
