import { DEFAULT_THEME } from "react-native-country-picker-modal";
import { Colors, opacify } from "@ailo/primitives";

export const THEME = {
  ...DEFAULT_THEME,
  backgroundColor: Colors.WHITE,
  primaryColor: Colors.TEXT.DARK.PRIMARY,
  primaryColorVariant: opacify(Colors.SPACE_BLACK, 0.1),
  onBackgroundTextColor: Colors.TEXT.DARK.PRIMARY,
  fontFamily: "circular-medium",
  fontSize: 15,
  filterPlaceholderTextColor: Colors.TEXT.DARK.PLACEHOLDER,
  itemHeight: 52,
  flagSize: 24,
  flagSizeButton: 20,
  activeOpacity: 1
};
