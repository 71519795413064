import React, { FC, ReactElement } from "react";
import { SectionList, View } from "react-native";
import styled from "styled-components/native";
import { isEmpty } from "lodash";
import { Colors, RightChevronIcon } from "@ailo/primitives";
import { ListItemHeader } from "@ailo/ui";
import { PaymentMethod } from "local/domain/ledger/types";
import {
  PaymentMethodListItem,
  BankAccountTypeName,
  CreditCardTypeName,
  formatPaymentMethodName
} from "@ailo/domains";
import { EmptyPaymentMethodList, LoadingPaymentMethodList } from "./components";

interface Props {
  paymentMethods: Array<PaymentMethod>;
  moreSections?: Array<SectionListSection>;
  showAutoBadges?: boolean;
  onPaymentMethodSelection?: (
    paymentMethodId: string,
    header: string,
    showLoadingOverlay: boolean
  ) => void;
}

export interface SectionListSection {
  title: string;
  data: Array<ReactElement>;
}

interface Statics {
  Loading: FC;
}

const PaymentMethodList: FC<Props> & Statics = ({
  paymentMethods,
  moreSections,
  showAutoBadges,
  onPaymentMethodSelection
}) => {
  if (isEmpty(paymentMethods)) {
    return <EmptyPaymentMethodList />;
  }

  const renderPaymentMethod = (
    item: PaymentMethod,
    index: number,
    array: PaymentMethod[]
  ): ReactElement => {
    const paymentMethodName = formatPaymentMethodName(item);
    return (
      <PaymentMethodListItem
        key={item.id}
        paymentMethodId={item.id}
        showAutoBadges={showAutoBadges}
        onPress={(): void =>
          onPaymentMethodSelection?.(item.id, paymentMethodName, false)
        }
        rightComponent={<RightChevronIcon width={20} height={20} />}
        style={index === array.length - 1 ? { borderType: "none" } : undefined}
      />
    );
  };

  const creditCardMethods = paymentMethods.filter(
    (paymentMethod) =>
      paymentMethod.__typename === CreditCardTypeName &&
      paymentMethod.number != null
  );

  const bankAccountMethods = paymentMethods.filter(
    (paymentMethod) =>
      paymentMethod.__typename === BankAccountTypeName &&
      paymentMethod.accountNumber != null
  );

  const creditCardsSection = {
    title: "Credit Or Debit",
    data: creditCardMethods.map(renderPaymentMethod)
  };

  const bankAccountsSection = {
    title: "Bank Accounts",
    data: bankAccountMethods.map(renderPaymentMethod)
  };

  const listItems = [creditCardsSection, bankAccountsSection]
    .concat(moreSections || [])
    .filter((section) => !isEmpty(section.data));

  return (
    <SectionList
      sections={listItems}
      renderItem={({ item }): ReactElement => item}
      renderSectionHeader={({ section: { title } }): ReactElement => {
        return <Header text={title} />;
      }}
      ListFooterComponent={<BottomMargin />}
      stickySectionHeadersEnabled={true}
    />
  );
};

const Header = styled(ListItemHeader)`
  background-color: ${Colors.CLOUD};
`;

const BottomMargin = styled(View)`
  height: 32px;
`;

PaymentMethodList.Loading = LoadingPaymentMethodList;

export { PaymentMethodList };
