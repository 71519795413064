import { AiloRN } from "@ailo/ailorn";
import { Colors, opacify } from "@ailo/primitives";
import { logApolloResultFailed, useOnFocus } from "@ailo/services";
import { ListItem, ListItemHeader } from "@ailo/ui";
import { usePropertySelectorContext } from "local/common";
import {
  findPropertyByManagementOrFolioAilorn,
  useAutoPayLiabilityStatuses
} from "local/domain/ledger";
import { orderBy } from "lodash";
import React from "react";
import { View } from "react-native";
import { isNotOffboarded } from "../../isNotOffboarded";
import { AutoPayLiabilityStatusListItem } from "./components";
import { SetupAutoBillPayButton } from "./components/SetupAutoBillPayButton";

function Container({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <View>
      <ListItemHeader text={"Auto Pay Bills"} />
      {children}
    </View>
  );
}

export function BillsSection(): React.ReactElement | null {
  const autoPayStatusesResult = useAutoPayLiabilityStatuses();
  const { allProperties } = usePropertySelectorContext();
  useOnFocus(autoPayStatusesResult.refetch);

  if (autoPayStatusesResult.loading && !autoPayStatusesResult.data) {
    return (
      <Container>
        <ListItem.Loading />
      </Container>
    );
  }

  if (!autoPayStatusesResult.data || autoPayStatusesResult.error) {
    logApolloResultFailed(autoPayStatusesResult, {
      operationId: "BillsSection.autoPayStatusesResult"
    });
    return null;
  }

  const enabledItems = autoPayStatusesResult?.data
    ?.filter((status) => status.enabled)
    .filter((status) =>
      isNotOffboarded(allProperties, AiloRN.from(status?.payerId))
    )
    .map((s) => {
      const property = findPropertyByManagementOrFolioAilorn(
        allProperties,
        s?.payerId
      );
      return {
        status: s,
        sortKey: property?.address?.streetName || "ZZZ"
      };
    });

  const enabledItemsSorted = orderBy(enabledItems, ["sortKey"], ["asc"]).map(
    ({ status }) => {
      return (
        <AutoPayLiabilityStatusListItem
          key={status.payerId.toString()}
          status={status}
        />
      );
    }
  );

  const enableElement = (
    <View
      style={{
        backgroundColor: Colors.WHITE,
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: opacify(Colors.SPACE_BLACK, 0.1),
        borderTopWidth: 0,
        padding: 16
      }}
    >
      <SetupAutoBillPayButton style={{ paddingHorizontal: 50 }} />
    </View>
  );

  return (
    <>
      <Container>
        {enabledItemsSorted}
        {enableElement}
      </Container>
    </>
  );
}
