import { useIsMountedRef } from "@ailo/primitives";
import { alert } from "@ailo/ui";
import { useAnalytics } from "local/common";
import {
  useAutoPayLiabilityStatus,
  UpsertAutoPayBillsData,
  useUpsertAutoPayLiabilityStatus
} from "local/domain/ledger";
import { useCallback, useRef } from "react";
import { AiloRN } from "@ailo/ailorn";

export interface SubmitAutoPayLiabilityStatusFormOptions {
  data: UpsertAutoPayBillsData;
  skipConfirmAlert?: boolean;
}

export function useAutoPayLiabilityStatusForm(payerId?: AiloRN): {
  statusResult: ReturnType<typeof useAutoPayLiabilityStatus>;
  submitResult: ReturnType<typeof useUpsertAutoPayLiabilityStatus>[1];
  submitCalledWith?: UpsertAutoPayBillsData;
  submit(opts: SubmitAutoPayLiabilityStatusFormOptions): void;
} {
  const isMountedRef = useIsMountedRef();

  const analytics = useAnalytics();

  const statusResult = useAutoPayLiabilityStatus(payerId);

  const [upsertAutoPayLiabilityStatusMutation, submitResult] =
    useUpsertAutoPayLiabilityStatus(payerId);
  const upsertAutoPayLiabilityStatusCalledWithRef =
    useRef<UpsertAutoPayBillsData>();

  const upsertAutoPayStatus = useCallback(
    async (data: UpsertAutoPayBillsData): Promise<void> => {
      upsertAutoPayLiabilityStatusCalledWithRef.current = data;
      try {
        await upsertAutoPayLiabilityStatusMutation({
          variables: data
        });
        analytics.track(
          data.enabled ? "Auto Pay Bills Enabled" : "Auto Pay Bills Disabled"
        );
      } catch (error: any) {
        // Intentionally skip analytics fail-scenario tracking
        // on network-type errors
        if (error.networkError) {
          return;
        }
        analytics.track(
          data.enabled
            ? "Auto Pay Bills Enable Failed"
            : "Auto Pay Bills Disable Failed",
          {
            errorMessage: error.message
          }
        );
      }
    },
    [upsertAutoPayLiabilityStatusMutation, analytics]
  );

  const submit = useCallback(
    async ({ skipConfirmAlert = false, data }) => {
      if (!data.enabled && !skipConfirmAlert) {
        return new Promise<void>((resolve, reject) =>
          alert(
            "Turn off auto bill payment?",
            "Are your sure you want to turn off your auto bill payments?",
            [
              { text: "No", style: "cancel" },
              {
                text: "Turn off",
                style: "destructive",
                onPress: async (): Promise<void> => {
                  if (!isMountedRef.current) {
                    return;
                  }
                  return upsertAutoPayStatus(data).then(resolve).catch(reject);
                }
              }
            ]
          )
        );
      }

      return upsertAutoPayStatus(data);
    },
    [isMountedRef, upsertAutoPayStatus]
  );

  return {
    statusResult,
    submitResult,
    submitCalledWith: upsertAutoPayLiabilityStatusCalledWithRef.current,
    submit
  };
}
