import React, { FC } from "react";
import { View } from "react-native";
import { ListItem } from "@ailo/ui";
import { Text, Skeleton } from "@ailo/primitives";

const ListHeaderSkeleton: FC = () => (
  <Text.Subheading.Loading
    width={120}
    style={{
      marginTop: 12,
      marginLeft: 16,
      marginBottom: 8
    }}
  />
);

const ListItemSkeleton: FC = () => (
  <ListItem
    header={<Text.BodyM.Loading width={200} />}
    subHeader={<Text.BodyS.Loading width={120} />}
    leftComponent={
      <Skeleton style={{ width: 40, height: 40, marginRight: 16 }} />
    }
  />
);

const LoadingPaymentMethodList: FC = () => {
  return (
    <View>
      <ListHeaderSkeleton />
      <ListItemSkeleton />
      <ListItemSkeleton />
      <ListItemSkeleton />
    </View>
  );
};

export { LoadingPaymentMethodList };
