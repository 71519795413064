import React, { useCallback } from "react";
import { LoadingSynchroniser, useSynchronisedLoad } from "@ailo/primitives";
import {
  useNavigation,
  useRoute,
  Screens,
  withScreenComponent
} from "local/common";
import { Container, TransferFundsDisplay } from "./components";
import { TransferToDisplay, BankAccountTypeName } from "local/domain/ledger";
import { useGetPaymentMethodForTransferQuery } from "local/graphql";
import { SpinnerOverlay } from "@ailo/ui";
import { ScrollView } from "react-native";

export const TransferFundsScreen = withScreenComponent(
  (): React.ReactElement => {
    const navigation = useNavigation<Screens.TransferFunds>();
    const { params, name } = useRoute<Screens.TransferFunds>();
    const { paymentMethodId, showLoadingOverlay } = params;

    const { loading, data } = useGetPaymentMethodForTransferQuery({
      variables: { paymentMethodId }
    });
    const isLoading = useSynchronisedLoad(!data && loading);

    const onPaymentMethodChanged = useCallback(
      (paymentMethodId: string): void => {
        navigation.navigate(name, { ...params, paymentMethodId });
      },
      [name, navigation, params]
    );

    if (isLoading)
      return (
        <Container>
          <TransferFundsDisplay.Loading />
          <TransferToDisplay
            paymentMethodId={paymentMethodId}
            onPaymentMethodChanged={onPaymentMethodChanged}
          />
        </Container>
      );

    const bankAccountMethod = data?.paymentMethodById;

    if (
      !bankAccountMethod ||
      bankAccountMethod.__typename !== BankAccountTypeName
    ) {
      throw new Error("Transfer funds screen missing bankAccountMethod.");
    }

    return (
      <LoadingSynchroniser>
        <Container>
          <ScrollView keyboardShouldPersistTaps={"handled"}>
            <TransferFundsDisplay bankAccountMethod={bankAccountMethod} />
            <TransferToDisplay
              paymentMethodId={paymentMethodId}
              onPaymentMethodChanged={onPaymentMethodChanged}
            />
          </ScrollView>
          {showLoadingOverlay && <SpinnerOverlay />}
        </Container>
      </LoadingSynchroniser>
    );
  }
);
