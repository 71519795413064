import "react-native-gesture-handler";
import "array-flat-polyfill";
import { MaintenanceSetup } from "@ailo/domains";
import { Colors, CurrentAppContextProvider } from "@ailo/primitives";
import {
  ApolloSetup,
  AuthSetup,
  EnvironmentSetup,
  ErrorSetup,
  NavigationSetup,
  UpdaterSetup,
  useAuth,
  UserStorageContextProvider,
  withErrorBoundary,
  staticEnvironment,
  ActionEventContextProvider,
  AppVersionCheck
} from "@ailo/services";
import { LoadingSplashPage, UIProvider } from "@ailo/ui";
import * as Updates from "expo-updates";
import React, { useEffect } from "react";
import { StatusBar } from "react-native";
import possibleTypeData from "../graphql/fragmentTypes.generated";
import { AnalyticsSetup } from "./AnalyticsSetup";
import { AppDataSetup } from "./AppDataSetup";
import { LoginNavigator } from "./LoginNavigator";
import { useFonts } from "./useFonts";

const navigationTheme = {
  colors: { background: Colors.CLOUD }
};

function UserStorageProvider({
  children
}: {
  children: React.ReactElement;
}): JSX.Element {
  const { user } = useAuth();
  return (
    <UserStorageContextProvider userId={user?.effectiveId?.toString()}>
      {children}
    </UserStorageContextProvider>
  );
}

const { SENTRY_ENABLE, SENTRY_DSN, SENTRY_TRACES_SAMPLE_RATE, GATEWAY_TRACE } =
  staticEnvironment.releaseEnvironment;

const allowLoggingPii = !staticEnvironment.isProd;
const errorConfig = {
  sentryEnabled: !!(!__DEV__ || SENTRY_ENABLE),
  sentryDsn: SENTRY_DSN,
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE ?? __DEV__ ? 1.0 : 0.01,
  allowLoggingPii
};
const apolloConfig = {
  allowLoggingPii,
  forceTracing: !!GATEWAY_TRACE,
  possibleTypes: possibleTypeData.possibleTypes,
  clientName: "ailo-consumer-app",
  clientVersion: staticEnvironment.buildInfo.codeVersion
};

const App: React.FunctionComponent = () => {
  const fontsLoaded = useFonts();
  useEffect(() => {
    StatusBar.setBarStyle("light-content");
  }, []);

  const content = fontsLoaded ? <LoginNavigator /> : <LoadingSplashPage />;

  return (
    <CurrentAppContextProvider appPackageId={"consumer-app"}>
      <UIProvider>
        <ActionEventContextProvider>
          <ErrorSetup {...errorConfig}>
            <UpdaterSetup>
              <EnvironmentSetup>
                <AppVersionCheck
                  fallback={{
                    android:
                      "https://play.google.com/store/apps/details?id=app.ailo",
                    ios: "https://apps.apple.com/au/app/ailo/id1490980661"
                  }}
                >
                  <MaintenanceSetup>
                    <AuthSetup>
                      <UserStorageProvider>
                        <ApolloSetup {...apolloConfig}>
                          <AnalyticsSetup>
                            <NavigationSetup
                              theme={navigationTheme}
                              linking={{ prefixes: [] }}
                            >
                              <AppDataSetup>{content}</AppDataSetup>
                            </NavigationSetup>
                          </AnalyticsSetup>
                        </ApolloSetup>
                      </UserStorageProvider>
                    </AuthSetup>
                  </MaintenanceSetup>
                </AppVersionCheck>
              </EnvironmentSetup>
            </UpdaterSetup>
          </ErrorSetup>
        </ActionEventContextProvider>
      </UIProvider>
    </CurrentAppContextProvider>
  );
};

const AppWithErrorBoundary = withErrorBoundary(App, {
  retry: () => Updates.reloadAsync()
});

export { AppWithErrorBoundary as App };
