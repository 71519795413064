import React from "react";
import { AFC, useSynchronisedLoad } from "@ailo/primitives";
import { BillLineItemsCard, Money } from "@ailo/ui";
import { useOnFocus } from "@ailo/services";
import { useGetBillLineItemsQuery } from "local/graphql";

type Props = {
  billId: string;
};

export const BillLineItems: AFC<Props> = ({ billId, style }) => {
  const { data, refetch, loading } = useGetBillLineItemsQuery({
    variables: { billId }
  });
  useOnFocus(refetch);
  const isLoading = useSynchronisedLoad(loading && !data);

  if (isLoading)
    return (
      <BillLineItemsCard.Loading style={[style, { marginHorizontal: 12 }]} />
    );
  if (!data) return null;

  const lineItems = data.billById.lineItems?.items;
  if (!lineItems || lineItems.length === 0) {
    return null;
  }
  const nonTaxLineItems = lineItems?.filter((item) => !item.isTax);
  const includedTaxAmount = nonTaxLineItems
    .map((li) => li.taxAmount)
    .reduce((a, b) => a.add(b), Money.zero());
  const taxItems = lineItems?.filter((item) => item.isTax);

  return (
    <BillLineItemsCard
      style={[style, { marginHorizontal: 12 }]}
      lineItems={nonTaxLineItems.map((item) => ({
        key: item.ailoRN,
        name: item.description,
        taxInclusiveAmount: item.taxInclusiveAmount
      }))}
      taxItems={taxItems.map((item) => ({
        key: item.ailoRN,
        name: item.description,
        taxInclusiveAmount: item.taxInclusiveAmount
      }))}
      includedTaxAmount={includedTaxAmount}
      totalAmount={data.billById.amount}
    />
  );
};
