import { AiloRN } from "@ailo/ailorn";
import { didQueryNotLoadYet } from "@ailo/services";
import { QueryResult } from "@apollo/client";
import {
  GetManagementFolioQuery,
  GetManagementFolioQueryVariables,
  useGetManagementFolioQuery
} from "local/graphql";

export const useGetManagementFolioManagements = ({
  managementFolioAilorn
}: {
  managementFolioAilorn?: AiloRN;
}): {
  skipped: boolean;
  loading: boolean;
  error: boolean;
  refetch: QueryResult<
    GetManagementFolioQuery,
    GetManagementFolioQueryVariables
  >["refetch"];
  managementAilorns: AiloRN[];
} => {
  const result = useGetManagementFolioQuery({
    variables: {
      id: managementFolioAilorn?.internalId ?? ""
    },
    skip: !managementFolioAilorn
  });

  const managementAilorns =
    result.data?.managementFolio?.managements.map((management) =>
      AiloRN.from(management.ailorn)
    ) ?? [];

  const loading = didQueryNotLoadYet(result);

  return {
    skipped: !managementFolioAilorn,
    loading,
    error: !!result.error,
    refetch: result.refetch,
    managementAilorns
  };
};
