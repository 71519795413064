import React, { useState } from "react";
import { AFC } from "@ailo/primitives";
import styled from "styled-components/native";
import { Button } from "@ailo/ui";
import { OnboardingTaskId } from "local/graphql";
import { useCompleteOnboardingTask } from "local/domain/onboarding";

interface Props {
  scrollForwardFunction(): void;
  totalSlides: number;
  currentSlideIndex: number;
}

const NextButton: AFC<Props> = ({
  scrollForwardFunction,
  totalSlides,
  currentSlideIndex
}) => {
  const completeOnboardingTask = useCompleteOnboardingTask();
  const [disabled, setDisabled] = useState(false);

  const buttonText = currentSlideIndex === 0 ? "Take a look" : "Next";

  const handleButtonPress = (): void => {
    if (currentSlideIndex === totalSlides - 1) {
      handleCompleteWelcomeOnboarding();
    }
    scrollForwardFunction();
  };

  const handleCompleteWelcomeOnboarding = async (): Promise<void> => {
    setDisabled(true);
    completeOnboardingTask(OnboardingTaskId.CompleteAiloOverview);
  };

  return (
    <StyledButton
      type={"large"}
      onPress={handleButtonPress}
      disabled={disabled}
    >
      {buttonText}
    </StyledButton>
  );
};

const StyledButton = styled(Button.Primary)`
  margin-bottom: 24px;
  margin-left: 40px;
  margin-right: 40px;
`;

export { NextButton };
