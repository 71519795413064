import { LegalEntity, Property } from "local/common";

export function groupPropertiesByLegalEntity({
  properties,
  legalEntities
}: {
  properties: Property[];
  legalEntities: LegalEntity[];
}): Array<{
  legalEntity: LegalEntity;
  properties: Property[];
}> {
  const acc: { [key: string]: Property[] | undefined } = {};

  properties.forEach((property) => {
    const legalEntity = legalEntities.find((entity) =>
      entity.id.equals(property.userLegalEntity.id)
    );
    const key = legalEntity?.id.toString() ?? "undefined";
    acc[key] = acc[key] ?? [];
    acc[key]!.push(property);
  });

  return legalEntities
    .map((legalEntity) => ({
      legalEntity,
      properties: acc[legalEntity.id.toString()] ?? []
    }))
    .filter(({ properties }) => properties.length > 0);
}
