import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Colors } from "@ailo/primitives";
import { RentBalance } from "@ailo/domains";
import { Screens, useRoute } from "local/common";

export function RentBalanceHeader(): React.ReactElement {
  const { rentBalanceLabel, rentBalanceColor, rentBalance } =
    useRoute<Screens.TenantRentHistory>().params;

  return (
    <>
      {rentBalanceLabel && rentBalanceColor && rentBalance && (
        <RentBalanceContainer>
          <RentBalance
            rentBalanceLabel={rentBalanceLabel}
            rentBalanceColor={rentBalanceColor}
            rentBalance={rentBalance}
          />
        </RentBalanceContainer>
      )}
    </>
  );
}

const RentBalanceContainer = styled(View)`
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: ${Colors.WHITE};
  padding: 24px 16px 16px 16px;
`;
