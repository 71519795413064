import { Colors, Text } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import React, { useCallback } from "react";
import { View } from "react-native";
import { useNavigation, Screens } from "local/common";

export function AutoPayBottomSheetContent({
  onClose
}: {
  onClose?(): void;
}): React.ReactElement {
  const navigation = useNavigation();
  const handleGoToAutoPayOnPress = useCallback(() => {
    onClose && onClose();
    navigation.navigate(Screens.AutomaticPayments);
  }, [onClose, navigation]);

  return (
    <View
      style={{
        backgroundColor: Colors.WHITE,
        paddingHorizontal: 32,
        paddingTop: 24,
        paddingBottom: 24
      }}
    >
      <Text.DisplayS style={{ marginBottom: 16 }}>
        {"You have AutoPay set up"}
      </Text.DisplayS>
      <View style={{ minHeight: 176 }}>
        <Text.BodyM>
          {
            "You have Auto Pay set up with this payment method, please change the payment method for your Auto Pay before removing it."
          }
        </Text.BodyM>
      </View>
      <View style={{ flexDirection: "row-reverse" }}>
        <Button.Primary
          onPress={handleGoToAutoPayOnPress}
          style={{ marginLeft: 12 }}
        >
          {"Go to AutoPay"}
        </Button.Primary>
        <Button.Secondary onPress={onClose}>{"Cancel"}</Button.Secondary>
      </View>
    </View>
  );
}
