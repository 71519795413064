import React from "react";
import { Colors, SFC, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { Screens, useNavigation } from "local/common";
import { useEnvironment } from "@ailo/services";
import * as WebBrowser from "expo-web-browser";
import { View } from "react-native";

const DisclaimerText: SFC = ({ style }) => {
  const navigation = useNavigation();
  const { PDS_URL } = useEnvironment();

  const navigateToEula = (): void => {
    navigation.navigate(Screens.TermsOfServiceView);
  };

  const navigateToPds = (): void => {
    WebBrowser.openBrowserAsync(PDS_URL);
  };

  return (
    <View style={style}>
      <StyledText>
        <StyledText>
          {
            "By clicking I Accept, you are indicating that you have read and acknowledge the "
          }
        </StyledText>
        <StyledText color={Colors.AILO_RED} onPress={navigateToEula}>
          {"Terms of Service"}
        </StyledText>
      </StyledText>
      <StyledText style={{ marginTop: 12 }}>
        <StyledText>
          {
            "To review our Product Disclosure Statement & Financial Service Guide, please tap "
          }
        </StyledText>
        <StyledText color={Colors.AILO_RED} onPress={navigateToPds}>
          {"here"}
        </StyledText>
      </StyledText>
    </View>
  );
};

const StyledText = styled(Text.BodyS)`
  text-align: center;
`;

export { DisclaimerText };
