import React from "react";
import { Screens, AppNavigatorStack } from "local/common";
import { PayBillLiabilityScreen } from "./PayBillLiabilityScreen";
import { RentPaymentSuccessScreen } from "./RentPaymentSuccessScreen";
import { BillPaymentSuccessScreen } from "./BillPaymentSuccessScreen";
import { SetupAutoPayRentScreen } from "./SetupAutoPayRentScreen";
import { AutoPayRentSuccessScreen } from "./AutoPayRentSuccessScreen";
import { PayRentLiabilityScreen } from "./PayRentLiabilityScreen";

const getPayLiabilityScreens = (
  Stack: AppNavigatorStack
): React.ReactElement => {
  return (
    <>
      <Stack.Screen
        name={Screens.PayBillLiability}
        component={PayBillLiabilityScreen}
      />
      <Stack.Screen
        name={Screens.PayRentLiability}
        component={PayRentLiabilityScreen}
      />
      <Stack.Screen
        name={Screens.RentPaymentSuccess}
        component={RentPaymentSuccessScreen}
        options={{
          gestureEnabled: false
        }}
      />
      <Stack.Screen
        name={Screens.BillPaymentSuccess}
        component={BillPaymentSuccessScreen}
        options={{
          gestureEnabled: false
        }}
      />
      <Stack.Screen
        name={Screens.SetupAutoPayRent}
        component={SetupAutoPayRentScreen}
        options={{
          title: "Setup Auto Rent Payments"
        }}
      />
      <Stack.Screen
        name={Screens.EditAutoPayRent}
        component={SetupAutoPayRentScreen}
        options={{
          title: "Edit Auto Rent Payments"
        }}
      />
      <Stack.Screen
        name={Screens.SetupAutoPayRentSuccess}
        component={AutoPayRentSuccessScreen}
        options={{
          title: "Setup Auto Rent Payments",
          gestureEnabled: false
        }}
      />
      <Stack.Screen
        name={Screens.EditAutoPayRentSuccess}
        component={AutoPayRentSuccessScreen}
        options={{
          title: "Edit Auto Rent Payments",
          gestureEnabled: false
        }}
      />
    </>
  );
};

export { getPayLiabilityScreens };
