import { useHasFeature } from "@ailo/domains";
import { useAuth } from "@ailo/services";
import { LoadingSplashPage } from "@ailo/ui";
import { createStackNavigator } from "@react-navigation/stack";
import { Screens } from "local/common";
import React from "react";
import { useGlobal } from "reactn";
import { AppNavigator } from "../AppNavigator";
import { LoginScreen } from "./LoginScreen";
import { RecommendAgencyAppScreen } from "./RecommendAgencyAppScreen";
import { RecommendAiloInsightScreen } from "./RecommendAiloInsightScreen";
import { PlatformFeatureId } from "local/graphql";

const Stack = createStackNavigator();

export const LoginNavigator: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [authenticatedUserSetupDataSet] = useGlobal(
    "authenticatedUserSetupDataSet"
  );

  const hasConsumerAccess = useHasFeature(PlatformFeatureId.ConsumerAccess);
  const hasAgencyAccess = useHasFeature(PlatformFeatureId.AgencyAccess);

  if (!isAuthenticated) {
    return (
      <Stack.Navigator initialRouteName={Screens.Login}>
        <Stack.Screen
          name={Screens.Login}
          component={LoginScreen}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );
  }

  if (!authenticatedUserSetupDataSet) {
    return <LoadingSplashPage />;
  }

  const onlyHasAgencyAccess = hasAgencyAccess && !hasConsumerAccess;

  if (onlyHasAgencyAccess) {
    return (
      // We put the screen into navigator because ScreenComponent requires a navigator.
      // If/when we build a new ScreenComponent that does not use useNavigaiton, consider removing the navigator
      <Stack.Navigator initialRouteName={Screens.RecommendAgencyApp}>
        <Stack.Screen
          name={Screens.RecommendAgencyApp}
          component={RecommendAgencyAppScreen}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );
  }

  if (!hasConsumerAccess)
    return (
      // We put the screen into navigator because ScreenComponent requires a navigator.
      // If/when we build a new ScreenComponent that does not use useNavigaiton, consider removing the navigator
      <Stack.Navigator initialRouteName={Screens.RecommendAiloInsight}>
        <Stack.Screen
          name={Screens.RecommendAiloInsight}
          component={RecommendAiloInsightScreen}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );

  return <AppNavigator />;
};
