import { useCallback, useState } from "react";
import { useUserStorage } from "@ailo/services";
import usePromise from "react-promise";

const useClosableAction = (
  key: string
): { onClosePress: () => Promise<void>; dismissed: boolean } => {
  const storage = useUserStorage();
  const [dismissed, setDismissed] = useState(false);

  const onClosePress = useCallback(async (): Promise<void> => {
    setDismissed(true);
    await storage.setItem(key, true);
  }, [key, storage, setDismissed]);

  usePromise(
    useCallback(async () => {
      const isDismissed = (await storage.getItem(key)) == true;
      setDismissed(isDismissed);
      return isDismissed;
    }, [key, storage, setDismissed])
  );

  return { onClosePress, dismissed };
};

export { useClosableAction };
