export enum Screens {
  Home = "Home",
  Login = "Login",
  TabNavigator = "Root Tab Navigator",
  Profile = "Profile",
  EditProfile = "Edit Profile",
  EditProfilePhoto = "Update Profile Picture",
  EditAddress = "Update Residential Address",
  EditBirthDate = "Update Date of Birth",
  EditGivenName = "Update Given Name",
  EditPreferredName = "Update Preferred Name",
  EditEmailAddress = "Update Email Address",
  VerifyEmailAddress = "Verify Email Address",
  AddProfileDetails = "More details required",
  About = "About",
  PaymentMethods = "Payment Methods",
  PaymentMethodDetails = "Payment Method Details",
  RemovePaymentMethodSuccess = "Remove Payment Method Success",
  TermsOfServiceView = "Terms of Service",
  InvestorTransactionsTab = "Investor Overview",
  InvestorPropertyWalletTab = "Property Wallet",
  InvestorRentTab = "Investor Rent Overview",
  TenantRentTab = "Tenant Overview",
  WalletOverview = "Wallet",
  AddPaymentMethodModal = "Add Payment Method",
  SetupPaymentMethod = "Setup Payment Method",
  AddCreditDebitCard = "Add Credit or Debit Card",
  AddBankAccount = "Add Bank Account",
  AddOnceOffBankAccount = "Setup One Off Transfer",
  PayRentLiability = "Pay Rent Liability",
  PayBillLiability = "Pay Liability",
  SetupAutoPayRent = "Setup Auto Pay Rent",
  SelectAutoPayBillsWallet = "Setup Auto Bill Payments",
  ManageAutoPayBills = "Auto Bill Payments",
  EditAutoPayRent = "Edit Auto Pay Rent",
  SetupAutoPayRentSuccess = "Setup Auto Pay Rent Success",
  EditAutoPayRentSuccess = "Edit Auto Pay Rent Success",
  RentPaymentSuccess = "Rent Payment Success",
  BillPaymentSuccess = "Bill Payment Success",
  ChangePaymentMethod = "Change Payment Method",
  ChangeBankAccount = "Change Bank Account",
  TransferFundsSelectWallet = "Select Wallet",
  TransferFunds = "Transfer Funds",
  FundsTransferred = "Funds Transferred",
  AllInvestorTransactions = "All Investor Transactions",
  TenantRentHistory = "Tenant Rent History",
  TenantRentHistoryDetail = "Tenant Rent History Details",
  InvestorRentHistory = "Investor Rent History",
  InvestorRentHistoryDetail = "Investor Rent History Details",
  AutomaticPayments = "Automatic Payments",
  EditWalletAutomaticTransfer = "Edit Wallet Automatic Transfer",
  SetupRecurringTransfer = "Setup Recurring Transfer",
  OtherPaymentMethodsScreen = "Other Payment Method",
  ChatTab = "Chat List",
  Chat = "Chat",
  Threads = "Threads",
  Messages = "Messages",
  NewChatMessage = "New Chat Message",
  NewChatParticipants = "New Chat Participants",
  BillsTab = "Bills Overview",
  UpcomingBills = "Upcoming Bills",
  PaidBills = "Paid Bills",
  Bill = "Bill",
  AiloOverview = "Onboarding Overview",
  TermsOfServiceAccept = "Terms of Service Accept",
  AddPaymentMethodKeyAction = "Add Payment Method Key Action",
  SetupAutoRentPaymentKeyAction = "Setup Auto Rent Payment Key Action",
  SecureYourAccount = "Secure Your Account",
  YourAccountIsSecure = "Your Account Is Secure",
  CodeVerification = "Code Verification",
  AllowNotificationsKeyAction = "Allow Notifications",
  ChatImageCarousel = "Chat Image Carousel",
  ChatPDFView = "Chat PDF View",
  RecommendAiloInsight = "Recommend Ailo Insight",
  RecommendAgencyApp = "Recommend Agency App",
  PaymentHistory = "Payment History",
  GetToKnowAilo = "Get To Know Ailo",

  LiabilityPlanChooseType = "Setup Auto Rent Payments",
  LiabilityPlanSelectProperty = "Liability Payment Plan Select Property",
  LiabilityPlanAutoPaySelectProperty = "Liability Payment Plan Select Auto Pay Property",
  LiabilityPlanSelectAmount = "Liability Payment Plan Select Amount",
  LiabilityPlanSelectFrequency = "Liability Payment Plan Select Frequency",
  LiabilityPlanSelectStartDate = "Liability Payment Plan Select Start Date",
  LiabilityPlanSelectEndDate = "Liability Payment Plan Select End Date",
  LiabilityPlanConfirmDetails = "Liability Payment Plan Confirm Details",
  LiabilityPlanSave = "Liability Payment Plan Save",
  LiabilityPlanEdit = "Liability Payment Plan Edit",

  AutoWithdrawPlanSelectWallet = "Auto Withdraw Plan Select Wallet",
  AutoWithdrawPlanSelectPaymentDestinations = "Auto Withdraw Wallet Select Payment Destinations",
  AutoWithdrawPlanSelectPaymentDestinationPercentages = "Auto Withdraw Wallet Select Payment Destination Percentages",
  AutoWithdrawPlanSelectFrequency = "Auto Withdraw Plan Select Frequency",
  AutoWithdrawPlanSelectStartDate = "Auto Withdraw Plan Select StartDate",
  AutoWithdrawPlanConfirmAutoPayBills = "Auto Withdraw Plan Confirm Auto Pay Bills",
  AutoWithdrawPlanConfirmDetails = "Auto Withdraw Plan Confirm Details",
  AutoWithdrawPlanSave = "Auto Withdraw Plan Save",
  AutoWithdrawPlanEdit = "Auto Withdraw Plan Edit",

  InvestorAddBankAccountSuccess = "Investor Add Bank Account Success",

  TenancyDepositKeyAction = "Make an initial payment",
  TenancyDepositAddPaymentMethod = "Add a Payment Method",
  TenancyDepositSelectPaymentMethod = "Select a Payment Method",
  TenancyDepositEnterCreditDebitCard = "Enter Credit or Debit Card",
  TenancyDepositConfirmPayment = "Confirm Initial Payment",
  TenancyDepositPaymentSuccess = "Initial Payment Confirmation"
}
