import { AiloRN } from "@ailo/ailorn";
import { formatAddress } from "@ailo/domain-helpers";
import { Property, usePropertySelectorContext } from "local/common";
import { AutoPayLiabilityStatus } from "local/domain/ledger";

const ManagementFormatter = (
  entity: Property
): { address: string | undefined } => {
  return {
    address: formatAddress(entity?.address, { format: "street" })
  };
};

const findPropertyByManagementOrFolioAilorn = (
  allProperties: Property[],
  ailorn?: AiloRN
): Property | undefined => {
  return ailorn?.nsEntity === "propertymanagement:management"
    ? allProperties.find((p) => p?.management?.id?.equals(ailorn))
    : ailorn?.nsEntity === "propertymanagement:managementfolio"
    ? allProperties.find((p) => p?.managementFolio?.ailorn?.equals(ailorn))
    : undefined;
};

const hasNonZeroOwnership = (
  allProperties: Property[],
  ailorn?: AiloRN
): boolean => {
  const property = findPropertyByManagementOrFolioAilorn(allProperties, ailorn);
  return (
    property?.__typename === "OwnedProperty" &&
    (property?.ownership?.sharesOwnedRatio || 0) !== 0
  );
};

const useCanModifyAutoPayLiability = (
  walletOwnerReference?: AiloRN
): {
  canModifyAutoPayLiability: boolean;
  isLegalEntity: boolean;
  isManagementOrManagementFolio: boolean;
} => {
  const { allProperties } = usePropertySelectorContext();

  if (!walletOwnerReference) {
    return {
      isManagementOrManagementFolio: false,
      isLegalEntity: false,
      canModifyAutoPayLiability: false
    };
  }

  const isManagement = walletOwnerReference.entity === "management";
  const isManagementFolio =
    walletOwnerReference?.nsEntity === "propertymanagement:managementfolio";
  const isManagementOrManagementFolio = isManagement || isManagementFolio;
  const isLegalEntity = walletOwnerReference.entity === "legalentity";

  const canModifyAutoPayLiability =
    isManagement || isManagementFolio
      ? hasNonZeroOwnership(allProperties, walletOwnerReference)
      : isLegalEntity;

  return {
    isManagementOrManagementFolio,
    isLegalEntity,
    canModifyAutoPayLiability
  };
};

const useAutoPayLiabilityStatusDetails = (
  status: AutoPayLiabilityStatus | undefined
): {
  fromPaymentMethod: boolean;
  isManagementOrManagementFolio: boolean;
  isLegalEntity: boolean;
  managementAddress: string;
} => {
  const { allProperties } = usePropertySelectorContext();

  const fromPaymentMethod = !!status?.paymentMethodId;
  const isManagement = status?.payerId.entity === "management";
  const isManagementFolio =
    status?.payerId.nsEntity === "propertymanagement:managementfolio";
  const isManagementOrManagementFolio = isManagement || isManagementFolio;
  const isLegalEntity = status?.payerId.entity === "legalentity";

  const findManagementAddressByAilorn = (ailorn?: AiloRN): string => {
    const property = findPropertyByManagementOrFolioAilorn(
      allProperties,
      ailorn
    );
    return property
      ? ManagementFormatter(property).address || "Unknown"
      : "Unknown";
  };

  const managementAddress = findManagementAddressByAilorn(status?.payerId);

  return {
    fromPaymentMethod,
    isManagementOrManagementFolio,
    isLegalEntity,
    managementAddress
  };
};

export {
  useAutoPayLiabilityStatusDetails,
  useCanModifyAutoPayLiability,
  ManagementFormatter,
  findPropertyByManagementOrFolioAilorn
};
