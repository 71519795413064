import { BankAccountPaymentMethodOption } from "../../types";

export function unselectZeroPercentagePaymentMethods(
  bankPaymentMethods: BankAccountPaymentMethodOption[]
): BankAccountPaymentMethodOption[] {
  return bankPaymentMethods.map((paymentMethod) => {
    return paymentMethod.percentage === 0
      ? { ...paymentMethod, selected: false }
      : paymentMethod;
  });
}
