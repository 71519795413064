import { RecurringDate } from "@ailo/date";
import { Text } from "@ailo/primitives";
import { formatMonthlyFrequency } from "@ailo/ui";
import { Screens, useNavNoButton, useRoute } from "local/common";
import { FrequencyEnum } from "local/domain/ledger";
import {
  ScreenWithHeaderAndAction,
  SuccessAlertMessage
} from "local/domain/liabilityPaymentPlan";
import { lowerFirst } from "lodash";
import moment from "moment";
import React, { FC } from "react";

const SaveScreen: FC = () => {
  const params = useRoute<Screens.AutoWithdrawPlanSave>().params;
  useNavNoButton();

  const {
    frequency,
    startDate,
    bankPaymentMethods,
    anniversary,
    anniversaryDaysOfMonth,
    isLastDayOfTheMonth,
    onSuccess
  } = params;

  const allAnniversaryRelatedFieldsEmpty =
    anniversary === null &&
    !(anniversaryDaysOfMonth ?? []).length &&
    !isLastDayOfTheMonth;

  if (
    !frequency ||
    !startDate ||
    !bankPaymentMethods ||
    allAnniversaryRelatedFieldsEmpty
  ) {
    throw new Error("Provide all fields");
  }

  const formattedRecurringDate =
    frequency === FrequencyEnum.monthly
      ? lowerFirst(
          formatMonthlyFrequency(
            anniversaryDaysOfMonth ?? [],
            isLastDayOfTheMonth ?? false
          )
        )
      : new RecurringDate({
          frequency,
          startDate: moment(startDate).format("YYYY-MM-DD")
        }).format({ type: "F-ly on D" });

  return (
    <ScreenWithHeaderAndAction
      primaryAction={{
        text: "Done",
        onClick: onSuccess
      }}
      scrollable={false}
    >
      <SuccessAlertMessage
        style={{ flex: 1 }}
        header={"Auto Transfers Activated"}
        summary={
          <Text.BodyM style={{ textAlign: "center" }}>
            {`Available funds will be transferred to your bank account ${formattedRecurringDate}`}
          </Text.BodyM>
        }
      />
    </ScreenWithHeaderAndAction>
  );
};

export { SaveScreen };
