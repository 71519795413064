import React, { FC } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { SFC, Colors, opacify } from "@ailo/primitives";
import { Button, ButtonVariant } from "@ailo/ui";

interface Statics {
  Loading: FC;
}

interface Props {
  onPress: () => void;
  variant?: ButtonVariant;
  text?: string;
}

const AddPaymentMethodButton: SFC<Props> & Statics = ({
  style,
  onPress,
  text,
  variant
}) => {
  return (
    <Container style={style}>
      <Button
        variant={variant ?? "primary"}
        onPress={onPress}
        testID={"add-payment-method-button"}
      >
        {text ?? "Add Payment Method"}
      </Button>
    </Container>
  );
};

const Loading: SFC = ({ style }) => {
  return (
    <Container style={style}>
      <Button.Loading />
    </Container>
  );
};

AddPaymentMethodButton.Loading = Loading;

const Container = styled(View)`
  background: ${Colors.WHITE};
  box-shadow: 0px -1px 0px ${opacify(Colors.SPACE_BLACK, 0.1)};
  padding: 8px 16px;
`;

export { AddPaymentMethodButton };
