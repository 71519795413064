import moment from "moment-timezone";

/**
 * @deprecated Try extending and using `DateTimeWithTimeZone.fromDateSubtractingAMillisecond` from UI package instead.
 */
class DueDate {
  private dueDate: moment.Moment;

  constructor(dueDate: string, private timeZone = "Australia/Sydney") {
    this.dueDate = moment.tz(dueDate, timeZone).subtract(1, "millisecond");
  }

  private now(): moment.Moment {
    return moment().tz(this.timeZone);
  }

  public friendlyFormat(): string {
    return this.dueDate.calendar(this.now(), calendarDateFormat);
  }

  public simpleFormat(): string {
    return this.dueDate.format("DD MMMM");
  }

  public isDueToday(): boolean {
    return this.dueDate.isSame(this.now(), "day");
  }

  public isOverdue(): boolean {
    return this.now().isAfter(this.dueDate);
  }

  public overdueDays(): number {
    return this.now().diff(this.dueDate, "days");
  }
}

const calendarDateFormat = {
  sameDay: "[today]",
  nextDay: "[tomorrow]",
  nextWeek: "dddd",
  lastDay: "[yesterday]",
  lastWeek: "ddd, DD MMM",
  sameElse: "ddd, DD MMM"
};

export { DueDate };
