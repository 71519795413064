import React from "react";
import { useGlobal } from "reactn";
import { PlatformFeatureId } from "local/graphql";
import { InvestorWelcomeScreen } from "./InvestorWelcomeScreen";
import { TenantWelcomeScreen } from "./TenantWelcomeScreen";
import { withScreenComponent } from "local/common";

export const WelcomeScreen = withScreenComponent((): React.ReactElement => {
  const [availableFeatures] = useGlobal("availableFeatures");
  const isInvestor = availableFeatures.includes(
    PlatformFeatureId.InvestorAccess
  );
  return isInvestor ? <InvestorWelcomeScreen /> : <TenantWelcomeScreen />;
});
