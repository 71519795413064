import { useHasFeature } from "@ailo/domains";
import { usePropertySelectorContext } from "local/common";
import { PlatformFeatureId } from "local/graphql";

/**
 * Returns true if "Auto Pay Bills" can be enabled for personal wallet.
 *
 * That is:
 * - they have `AutoPayBillsAsInvestor` feature flag, and
 * - they are an investor in at least one property, and
 * - for all the properties in which they are an investor, they own it in 100%
 */
export function useAutoPayLiabilityPossible(): {
  featureEnabled: boolean;
  completelyOwnsAllProperties: boolean;
} {
  const featureEnabled = useHasFeature(
    PlatformFeatureId.AutoPayBillsAsInvestor
  );
  const { allProperties, hasOwnedProperties } = usePropertySelectorContext();

  if (!featureEnabled) {
    return { featureEnabled: false, completelyOwnsAllProperties: false };
  }

  if (!hasOwnedProperties) {
    return { featureEnabled: false, completelyOwnsAllProperties: false };
  }

  const completelyOwnsAllInvestedProperties = !allProperties.find(
    (p) =>
      p.__typename === "OwnedProperty" && p.ownership.sharesOwnedRatio !== 1
  );

  if (!completelyOwnsAllInvestedProperties) {
    return { featureEnabled: true, completelyOwnsAllProperties: false };
  }

  return { featureEnabled: true, completelyOwnsAllProperties: true };
}
