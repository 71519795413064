import { useOnboardingContext } from "../OnboardingContext";
import { OnboardingTask } from "../types";
import { useOnboardingFlag } from "./useOnboardingFlag";
import { Screens } from "local/common";
import { useLastCommsNotificationResponse } from "@ailo/domains";

export function useInitialRoute(): Screens | undefined {
  const onboardingEnabled = useOnboardingFlag();
  const onboardingTask = useNextOnboardingTask();
  const lastCommsNotificationResponse = useLastCommsNotificationResponse();

  if (onboardingEnabled && onboardingTask) return onboardingTask.screen;

  if (lastCommsNotificationResponse === undefined) return undefined;

  if (lastCommsNotificationResponse?.type === "chat") {
    return Screens.Threads;
  }

  if (lastCommsNotificationResponse?.type === "thread") {
    return Screens.Messages;
  }

  return Screens.TabNavigator;
}

function useNextOnboardingTask(): OnboardingTask | undefined {
  const { incompleteOnboardingTasks } = useOnboardingContext();
  return incompleteOnboardingTasks[0];
}
