import React from "react";
import {
  useRoute,
  Screens,
  withScreenComponent,
  useNavigateToThisScreen,
  useNavigation
} from "local/common";
import { PaidBillsList } from "local/domain/bill";

const PaidBillsScreen = withScreenComponent(
  { testID: "BillsScreen" },
  (): React.ReactElement => {
    const { reference, address } = useRoute<Screens.PaidBills>().params;

    const navigation = useNavigation();
    const returnToThisScreen = useNavigateToThisScreen();
    const onBillPress = (billId: string): void => {
      navigation.navigate(Screens.Bill, {
        billId,
        onClose: returnToThisScreen
      });
    };

    return (
      <PaidBillsList
        reference={reference}
        address={address}
        onBillPress={onBillPress}
        lazyLoad
      />
    );
  }
);

export { PaidBillsScreen };
