import React, { FC } from "react";
import { AddPaymentMethodInfo } from "../../components";
import { Screens, useNavigation, useRoute } from "local/common";
import { SelectAmountComponent } from "./components";
import { Amounts } from "../../../payLiability/PayBillLiabilityScreen/components/PaymentDisplay/components/PaymentAmountInput";

const SelectAmountScreen: FC = () => {
  const navigation = useNavigation<Screens.LiabilityPlanSelectAmount>();
  const params = useRoute<Screens.LiabilityPlanSelectAmount>().params;
  const { paymentMethodId, amount } = params;

  const onPaymentMethodChanged = (paymentMethodId: string): void => {
    navigation.navigate(Screens.LiabilityPlanSelectAmount, {
      ...params,
      paymentMethodId
    });
  };

  const onNextClick = (amounts: Amounts): void => {
    navigation.navigate(Screens.LiabilityPlanSelectFrequency, {
      ...params,
      paymentMethodId,
      amount: amounts.amountWithoutFees
    });
  };

  if (!paymentMethodId)
    return (
      <AddPaymentMethodInfo onPaymentMethodAdded={onPaymentMethodChanged} />
    );

  return (
    <SelectAmountComponent
      defaultAmount={amount?.cents || 0}
      paymentMethodId={paymentMethodId}
      onNextClick={onNextClick}
      onPaymentMethodChanged={onPaymentMethodChanged}
    />
  );
};

export { SelectAmountScreen };
