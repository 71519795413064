import React from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { Text } from "@ailo/primitives";
import { Money } from "@ailo/ui";
import { RentBalance } from "@ailo/domains";

interface Props {
  rentBalanceLabel: string | undefined;
  rentBalanceColor: string | undefined;
  rentBalance: Money | undefined;
}

export function RentHistoryHeader({
  rentBalanceLabel,
  rentBalanceColor,
  rentBalance
}: Props): React.ReactElement {
  return (
    <Container>
      <Text.BodyM weight={"medium"}>{"Rent History"}</Text.BodyM>
      {rentBalanceLabel && rentBalanceColor && rentBalance && (
        <RentBalance
          rentBalanceLabel={rentBalanceLabel}
          rentBalanceColor={rentBalanceColor}
          rentBalance={rentBalance}
        />
      )}
    </Container>
  );
}

function Loading(): React.ReactElement {
  return (
    <Container>
      <Text.BodyM weight={"medium"}>{"Rent History"}</Text.BodyM>
    </Container>
  );
}

RentHistoryHeader.Loading = Loading;

const Container = styled(View)`
  padding: 20px 16px 16px 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
