import { AppNavigatorStack, Screens } from "local/common";
import React, { ReactElement } from "react";
import { ConfirmAutoPayBillsScreen } from "./screens/ConfirmAutoPayBillsScreen";
import { ConfirmDetailsScreen } from "./screens/ConfirmDetailsScreen";
import { EditDetailsScreen } from "./screens/EditDetailsScreen";
import { SaveScreen } from "./screens/SaveScreen";
import { SelectFrequencyScreen } from "./screens/SelectFrequencyScreen";
import { SelectPaymentDestinationPercentagesScreen } from "./screens/SelectPaymentDestinationPercentagesScreen";
import { SelectPaymentDestinationsScreen } from "./screens/SelectPaymentDestinationsScreen";
import { SelectStartDateScreen } from "./screens/SelectStartDateScreen";
import { SelectWalletScreen } from "./screens/SelectWalletScreen";

const getAutoWithdrawPlanScreens = (Stack: AppNavigatorStack): ReactElement => {
  const defaultTitle = "Setup Auto Transfers";

  const screens = [
    {
      name: Screens.AutoWithdrawPlanSelectPaymentDestinations,
      component: SelectPaymentDestinationsScreen
    },
    {
      name: Screens.AutoWithdrawPlanSelectPaymentDestinationPercentages,
      component: SelectPaymentDestinationPercentagesScreen
    },
    {
      name: Screens.AutoWithdrawPlanSelectFrequency,
      component: SelectFrequencyScreen
    },
    {
      name: Screens.AutoWithdrawPlanSelectStartDate,
      component: SelectStartDateScreen
    },
    {
      name: Screens.AutoWithdrawPlanConfirmAutoPayBills,
      component: ConfirmAutoPayBillsScreen
    },
    {
      name: Screens.AutoWithdrawPlanConfirmDetails,
      component: ConfirmDetailsScreen
    },
    {
      name: Screens.AutoWithdrawPlanSave,
      component: SaveScreen,
      options: {
        gestureEnabled: false
      }
    },
    {
      name: Screens.AutoWithdrawPlanEdit,
      component: EditDetailsScreen,
      options: { title: "Auto Transfer" }
    },
    {
      name: Screens.AutoWithdrawPlanSelectWallet,
      component: SelectWalletScreen
    }
  ];

  return (
    <>
      {screens.map(({ name, component, options }) => {
        return (
          <Stack.Screen
            key={name}
            name={name}
            component={component}
            options={{ ...options, title: options?.title ?? defaultTitle }}
          />
        );
      })}
    </>
  );
};

export { getAutoWithdrawPlanScreens };
