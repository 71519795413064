import React, { ReactElement } from "react";
import { ListItemHeader } from "@ailo/ui";
import { Colors } from "@ailo/primitives";
import { NewChatParticipantsListSection } from "../useParticipantListSections";

interface Props {
  section: NewChatParticipantsListSection;
}

export const ParticipantsListHeader = React.memo(
  function ParticipantsListHeader({ section: { title } }: Props): ReactElement {
    return (
      <ListItemHeader
        style={{
          backgroundColor: Colors.CLOUD,
          paddingHorizontal: 16,
          paddingVertical: 4
        }}
        text={title}
      />
    );
  }
);
