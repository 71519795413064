import React, { FC } from "react";
import { Image, View } from "react-native";
import { AllowNotifications } from "@ailo/primitives";
import styled from "styled-components/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export const AllowNotificationsImage: FC = () => {
  const { top } = useSafeAreaInsets();

  return (
    <Container style={{ marginTop: top + 64 }}>
      <StyledImage resizeMode={"contain"} source={AllowNotifications} />
    </Container>
  );
};

const Container = styled(View)`
  flex: 1;
  margin: 0 60px;
  justify-content: flex-start;
  align-content: flex-start;
`;

const StyledImage = styled(Image)`
  flex: 1;
  width: undefined;
  height: undefined;
`;
