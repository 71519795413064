import { AiloRN } from "@ailo/ailorn";
import { Property } from "local/common";
import { findPropertyByManagementOrFolioAilorn } from "local/domain/ledger";

export function isNotOffboarded(
  allProperties: Property[],
  folioAilorn: AiloRN
): boolean {
  return !!findPropertyByManagementOrFolioAilorn(allProperties, folioAilorn);
}
