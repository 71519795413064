import React, { useCallback } from "react";
import {
  useRoute,
  useNavCloseButton,
  useNavigation,
  useTitle,
  Screens,
  withScreenComponent
} from "local/common";
import { AlertScreen } from "@ailo/ui";
import { formatPaymentMethodName } from "@ailo/domains";

const RemovePaymentMethodSuccessScreen = withScreenComponent(
  (): React.ReactElement => {
    const { params } = useRoute<Screens.RemovePaymentMethodSuccess>();
    const { paymentMethodDetails } = params;

    const navigation = useNavigation();
    useNavCloseButton();

    const title = formatPaymentMethodName(paymentMethodDetails);

    useTitle(title);

    const handleClose = useCallback(() => {
      navigation.navigate(Screens.PaymentMethods);
    }, [navigation]);

    return (
      <AlertScreen
        title={`The payment method\nhas been removed`}
        type={"wallet"}
        bottomButtons={[
          {
            label: "Done",
            onPress: handleClose
          }
        ]}
      />
    );
  }
);

export { RemovePaymentMethodSuccessScreen };
