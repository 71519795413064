import { ExpenseStatusBadge, getSupplierName } from "@ailo/domains";
import { AFC, Colors, Text, useSynchronisedLoad } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import { Block, Money } from "@ailo/ui";
import React from "react";
import { BillStatusInfoBox } from "./components";
import { useGetMainBillDetailsQuery } from "local/graphql";

type Props = {
  billId: string;
};

export const BillPrimaryInfo: AFC<Props> = ({ billId }) => {
  const { data, refetch, loading } = useGetMainBillDetailsQuery({
    variables: { billId }
  });
  useOnFocus(refetch);
  const isLoading = useSynchronisedLoad(loading && !data);

  if (isLoading)
    return (
      <Block style={{ paddingTop: 20 }}>
        <Text.BodyM.Loading width={80} />
        <Text.DisplayL.Loading width={120} />
        <ExpenseStatusBadge.Loading style={{ marginTop: 12 }} />
      </Block>
    );

  if (!data || !data.billById.supplier)
    throw new Error(`Failed to load key details for bill with id ${billId}`);

  const supplierName = getSupplierName(data?.billById?.supplier);
  const amount = data.billById.amount;
  const status = data.billById.agencyStatus;

  if (!supplierName || !amount) {
    throw new Error("Missing data in bill with id " + billId);
  }

  const formattedAmount = Money.fromCents(amount.cents).format();

  return (
    <Block style={{ paddingTop: 20 }}>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        numberOfLines={1}
        testID={"BillSupplier"}
      >
        {supplierName}
      </Text.BodyM>
      <Text.DisplayL testID={"BillAmount"}>{formattedAmount}</Text.DisplayL>
      <ExpenseStatusBadge
        status={status}
        style={{ marginTop: 12, alignSelf: "flex-start" }}
      />
      <BillStatusInfoBox status={status} style={{ marginTop: 20 }} />
    </Block>
  );
};
