import { AiloRN } from "@ailo/ailorn";
import { useHasFeature } from "@ailo/domains";
import { Colors, SFC } from "@ailo/primitives";
import { Money } from "@ailo/ui";
import {
  Screens,
  useOnFocus,
  usePropertySelectorContext,
  useRoute
} from "local/common";
import { BankAccountMethod } from "local/domain/ledger";
import { useGetManagementFolioManagements } from "local/domain/managementFolio/useGetManagementFolioManagements";
import { PlatformFeatureId, useGetWalletForTransferQuery } from "local/graphql";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { View } from "react-native";
import styled from "styled-components/native";
import {
  ConfirmTransferButton,
  FormData,
  FundsInput,
  LiabilitiesDueAlert,
  MaxTransferAmount
} from "./components";
import { useGetBillAmountDue } from "./useGetBillAmountDue";

interface Props {
  bankAccountMethod: BankAccountMethod;
}

interface Statics {
  Loading: SFC;
}

const amountInput = "amount";

const TransferFundsDisplay: SFC<Props> & Statics = ({
  bankAccountMethod,
  style
}) => {
  const hasTransferBillsDueWarningFeature = useHasFeature(
    PlatformFeatureId.TransferBillsDueWarning
  );

  const { walletId } = useRoute<Screens.TransferFunds>().params;
  const {
    loading: walletLoading,
    data: walletData,
    refetch: walletRefetch
  } = useGetWalletForTransferQuery({
    variables: { walletId }
  });

  const { currentProperty } = usePropertySelectorContext();

  if (!currentProperty) {
    throw new Error("Could not find currentProperty");
  }
  const wallet = walletData?.walletById;
  const walletOwnerReference = wallet?.owner.reference
    ? AiloRN.from(wallet?.owner.reference)
    : undefined;

  const isManagementFolio = walletOwnerReference?.isA(
    "propertymanagement:managementfolio"
  );

  const managementFolioResult = useGetManagementFolioManagements({
    managementFolioAilorn: isManagementFolio ? walletOwnerReference : undefined
  });

  const billPayers = walletOwnerReference
    ? isManagementFolio
      ? [walletOwnerReference, ...managementFolioResult.managementAilorns]
      : [walletOwnerReference]
    : undefined;

  const billResult = useGetBillAmountDue({
    payers: billPayers,
    timezone: "Australia/Sydney" // TODO: find what timezone wallet owner is in
  });

  useOnFocus(walletRefetch);

  const formMethods = useForm<FormData>();

  if (
    (!walletData && walletLoading) ||
    billResult.skipped ||
    billResult.loading ||
    (isManagementFolio && managementFolioResult.loading)
  ) {
    return <Loading style={style} />;
  }

  const watchAmount = formMethods.watch(amountInput);

  if (!wallet) {
    throw new Error("Transfer funds screen missing wallet.");
  }

  const { idempotencyKey, availableBalance } = wallet;
  const outstandingFeeAmount = Money.fromCents(
    Math.max(wallet.owner?.dueFeeAmount.cents ?? 0, 0)
  );

  const outstandingBillAmount = hasTransferBillsDueWarningFeature
    ? billResult.amountDue
    : Money.zero();

  const maxTransferAmount = Money.fromCents(
    Math.max(availableBalance.cents - outstandingFeeAmount.cents, 0)
  );

  return (
    <Container style={style}>
      <FormProvider {...formMethods}>
        <LiabilitiesDueAlert
          feeAmount={outstandingFeeAmount}
          billAmount={outstandingBillAmount}
          maxTransferAmount={maxTransferAmount}
        />
        <FundsInput inputName={amountInput} style={{ marginTop: 24 }} />
        <MaxTransferAmount
          amount={maxTransferAmount}
          style={{ marginTop: 8 }}
        />
        <ConfirmTransferButton
          style={{ marginTop: 20 }}
          watchAmount={watchAmount}
          billAmount={outstandingBillAmount}
          maxTransferAmount={maxTransferAmount}
          bankAccountMethod={bankAccountMethod}
          idempotencyKey={idempotencyKey}
        />
      </FormProvider>
    </Container>
  );
};

const Loading: SFC = ({ style }) => {
  return (
    <Container style={style}>
      <FundsInput.Loading />
      <MaxTransferAmount.Loading style={{ marginTop: 8 }} />
      <ConfirmTransferButton.Loading style={{ marginTop: 20 }} />
    </Container>
  );
};
TransferFundsDisplay.Loading = Loading;

const Container = styled(View)`
  padding: 20px 16px;
  background: ${Colors.WHITE};
`;

export { TransferFundsDisplay };
