import React, { FC } from "react";
import { ScrollView, View } from "react-native";
import { Colors, opacify, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { LoadingScreen, PrimaryButton } from "../common";
import {
  PropertyAddress,
  ScreenComponent,
  Screens,
  useNavCloseButton,
  usePropertySelectorContext,
  useRoute
} from "local/common";
import { formatAddress } from "@ailo/domain-helpers";
import { Separator } from "@ailo/ui";
import {
  useGetOnlyPaymentMethodByIdQuery,
  OnboardingTaskId
} from "local/graphql";
import { PropertyInfoCard } from "./PropertyInfoCard";
import { formatPaymentMethodName } from "@ailo/domains";
import moment from "moment";
import { useCompleteOnboardingTask } from "local/domain/onboarding";
import { useRemovePreviousScreensFromNavigationStack } from "./useRemovePreviousScreensFromNavigationStack";

const PaymentSuccessScreen: FC = () => {
  const { paymentMethodId, paymentDetails } =
    useRoute<Screens.TenancyDepositPaymentSuccess>().params;
  useRemovePreviousScreensFromNavigationStack();
  useNavCloseButton();

  const completeOnboardingTask = useCompleteOnboardingTask();

  const { currentProperty } = usePropertySelectorContext();
  const address = getAddress(currentProperty?.address);

  const formattedCreatedAt = moment
    .tz(paymentDetails.createdAt, "Australia/Sydney")
    .format("h:mma, D MMMM yyyy");

  const { data: paymentMethodData, loading: loadingPaymentMethodData } =
    useGetOnlyPaymentMethodByIdQuery({ variables: { paymentMethodId } });

  if (loadingPaymentMethodData) {
    return <LoadingScreen />;
  }

  const paymentMethod = paymentMethodData?.paymentMethodById!;

  const notifiedMessage = `We’ve notified your property manager that your payment has been made, and they should receive it within ${
    paymentMethod.__typename === "CreditCard"
      ? "1-2 hours."
      : "1-2 business days."
  }`;

  return (
    <Container>
      <ScrollView style={{ padding: 16 }} contentContainerStyle={{ flex: 1 }}>
        <Text.DisplayS style={{ textAlign: "center" }}>
          {"Thanks for making your initial payment!"}
        </Text.DisplayS>
        <PropertyInfoCard address={address} />
        <ShadowBox style={{ marginTop: 12, padding: 16 }}>
          <ListRow style={{ marginBottom: 8 }}>
            <LabelText>{"Payment Method"}</LabelText>
            <ValueText>
              {formatPaymentMethodName(
                paymentMethodData?.paymentMethodById!,
                true
              )}
            </ValueText>
          </ListRow>
          <ListRow>
            <LabelText>{"Payment Time"}</LabelText>
            <ValueText>{formattedCreatedAt}</ValueText>
          </ListRow>
          <ContentSeparator />
          <ListRow style={{ marginBottom: 8 }}>
            <LabelText>{"Amount"}</LabelText>
            <ValueText>{paymentDetails.amount?.format()}</ValueText>
          </ListRow>
          <ListRow>
            <LabelText>{"Service Fee"}</LabelText>
            <ValueText>{paymentDetails.fee?.format()}</ValueText>
          </ListRow>
          <ContentSeparator />
          <ListRow>
            <Text.DisplayS>{"Total Paid"}</Text.DisplayS>
            <Text.DisplayS>{paymentDetails.total?.format()}</Text.DisplayS>
          </ListRow>
        </ShadowBox>
        <Text.BodyM
          style={{ textAlign: "center", marginTop: 16 }}
          color={Colors.TEXT.DARK.SECONDARY}
        >
          {notifiedMessage}
        </Text.BodyM>
      </ScrollView>

      <View style={{ marginTop: "auto" }}>
        <PrimaryButton
          onPress={() => {
            completeOnboardingTask(OnboardingTaskId.PayTenancyDeposit);
          }}
        >
          {"Done"}
        </PrimaryButton>
      </View>
    </Container>
  );
};

function getAddress(address?: PropertyAddress): string | undefined {
  if (!address) {
    return undefined;
  }
  return `${formatAddress(address, {
    format: "street"
  })}, ${formatAddress(address, { format: "zone" })}`;
}

const Container = styled(ScreenComponent)`
  height: 100%
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  background: ${Colors.WHITE};
`;

const ShadowBox = styled(View)`
  background-color: ${Colors.WHITE};
  box-shadow: 0 1px 4px ${opacify(Colors.SPACE_BLACK, 0.2)};
  border-radius: 4px;
`;

const ListRow = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LabelText = styled(Text.BodyS)`
  color: ${Colors.TEXT.DARK.SECONDARY};
`;

const ValueText = styled(Text.BodyM)`
  color: ${Colors.TEXT.DARK.PRIMARY};
`;

const ContentSeparator = styled(Separator)`
  margin: 20px -16px 20px 0;
`;

export { PaymentSuccessScreen };
