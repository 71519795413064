import {
  Property,
  useAnalytics,
  usePropertySelectorContext
} from "local/common";
import React, { useCallback, useEffect } from "react";
import { Sheet } from "./Sheet";

export function PropertySelectorBottomSheet({
  open: openProp,
  onClose
}: {
  open: boolean;
  onClose(): void;
}): React.ReactElement | null {
  const { allProperties, setCurrentProperty } = usePropertySelectorContext();
  const shouldRender = allProperties.length > 1;
  const open = shouldRender && openProp;
  const analytics = useAnalytics();
  useEffect(() => {
    if (open) {
      analytics.trackScreenVisited("Property Switcher");
    }
  }, [analytics, open]);

  const changeProperty = useCallback(
    (nextProperty: Property, prevProperty: Property): void => {
      setCurrentProperty(nextProperty);
      analytics.trackPropertyChanged({
        previousManagementAiloRN: prevProperty?.management.id.toString(),
        nextManagementAiloRN: nextProperty?.management.id.toString()
      });
      onClose?.();
    },
    [setCurrentProperty, analytics, onClose]
  );

  if (!open) {
    return null;
  }

  return (
    <Sheet open={openProp} onDismiss={onClose} onSubmit={changeProperty} />
  );
}
