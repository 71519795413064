import { useEffect } from "react";
import { Screens } from "../Screens";
import { useNavigation } from "./useNavigation";

/**
 * Replaces default title text with given title
 **/
export function useTitle(title: string): void {
  const navigation = useNavigation<Screens>();
  useEffect(() => {
    navigation.setOptions({
      title
    });
  }, [navigation, title]);
}
