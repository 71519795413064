import React, { FC } from "react";
import { ListItem } from "@ailo/ui";
import { View } from "react-native";
import { Frequency, FrequencyEnum } from "local/domain/ledger";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import { capitalCase } from "capital-case";

interface Props {
  heading: string;
  onFrequencySelected: (frequency: FrequencyEnum) => void;
}

const SelectFrequencyScreen: FC<Props> = ({ heading, onFrequencySelected }) => {
  const frequencies = Frequency.values();

  return (
    <ScreenWithHeaderAndAction header={heading}>
      {frequencies.map((frequency) => {
        return (
          <ListItem
            key={frequency}
            header={capitalCase(Frequency.value(frequency))}
            style={{ paddingHorizontal: 0 }}
            leftComponent={<View style={{ width: 16 }} />}
            onPress={(): void => onFrequencySelected(frequency)}
          />
        );
      })}
    </ScreenWithHeaderAndAction>
  );
};

export { SelectFrequencyScreen };
