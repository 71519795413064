import React from "react";
import {
  SFC,
  Colors,
  opacify,
  BillsIcon,
  PropertyIcon,
  ChatIcon
} from "@ailo/primitives";
import { CircleAvatarContainer } from "@ailo/ui";

interface Statics {
  Chat: typeof Chat;
  Rent: typeof Rent;
  Bill: typeof Bill;
}

const NotificationIcon: SFC & Statics = ({ style, children }) => {
  return (
    <CircleAvatarContainer
      style={style}
      size={40}
      colors={[Colors.CLOUD, Colors.CLOUD]}
    >
      {children}
    </CircleAvatarContainer>
  );
};

const Chat: SFC = ({ style }) => {
  return (
    <NotificationIcon style={style}>
      <ChatIcon width={iconSize} height={iconSize} color={iconColour} />
    </NotificationIcon>
  );
};

const Rent: SFC = ({ style }) => {
  return (
    <NotificationIcon style={style}>
      <PropertyIcon width={iconSize} height={iconSize} color={iconColour} />
    </NotificationIcon>
  );
};

const Bill: SFC = ({ style }) => {
  return (
    <NotificationIcon style={style}>
      <BillsIcon width={iconSize} height={iconSize} color={iconColour} />
    </NotificationIcon>
  );
};

const iconColour = opacify(Colors.SPACE_BLACK, 0.5);
const iconSize = 20;

NotificationIcon.Chat = Chat;
NotificationIcon.Rent = Rent;
NotificationIcon.Bill = Bill;

export { NotificationIcon };
