import {
  Screens,
  useNavCloseButton,
  useNavigation,
  useRoute,
  useCurrentLegalEntity
} from "local/common";
import React, { FC, useCallback } from "react";
import { DetailsEditComponent } from "../../components";
import {
  CreateLiabilityPaymentPlanInput,
  useCancelLiabilityPaymentPlanMutation,
  useGetLiabilityPaymentPlanByIdQuery,
  useUpdateLiabilityPaymentPlanMutation
} from "local/graphql";
import { LiabilityPaymentPlanSetupData } from "../../types";
import { FrequencyEnum } from "local/domain/ledger";
import moment from "moment";
import { alert, SpinnerOverlay } from "@ailo/ui";
import { useAutoPayAnalytics } from "local/domain/liabilityPaymentPlan";
import { capitalCase } from "capital-case";

export interface EditDetailsScreenProps {
  id: string;
  onSuccess: () => void;
  paymentMethodId?: string | undefined;
}

const EditDetailsScreen: FC = () => {
  useNavCloseButton();
  const navigation = useNavigation<Screens.LiabilityPlanEdit>();
  const params = useRoute<Screens.LiabilityPlanEdit>().params;
  const { id, paymentMethodId, onSuccess, showLoadingOverlay } = params;

  const [cancelMutation] = useCancelLiabilityPaymentPlanMutation();
  const [updateMutation] = useUpdateLiabilityPaymentPlanMutation();

  const [legalEntity] = useCurrentLegalEntity();

  const analytics = useAutoPayAnalytics();

  const { data, loading } = useGetLiabilityPaymentPlanByIdQuery({
    variables: {
      liabilityPaymentPlanId: id,
      payerLegalEntityId: legalEntity.id.toString()
    }
  });
  const details = data?.liabilityPaymentPlanById?.details;

  const editDetails: LiabilityPaymentPlanSetupData = {
    frequency: details?.frequency as any as FrequencyEnum,
    startDate: moment(details?.startDate).toDate(),
    endDate: details?.endDate ? moment(details?.endDate).toDate() : undefined,
    tenancy: { liabilityId: data?.liabilityPaymentPlanById?.liabilityId },
    amount: details?.amount,
    anniversary: details?.anniversary,
    paymentMethodId: paymentMethodId || details?.paymentMethodId
  };

  const onPressPaymentMethodChange = useCallback(
    (paymentMethodId: string) => {
      navigation.navigate(Screens.LiabilityPlanEdit, {
        ...params,
        paymentMethodId
      });
    },
    [navigation, params]
  );

  const showCancelErrorAlert = useCallback((): void => {
    navigation.setParams({ showLoadingOverlay: false });

    alert(
      "Error",
      "We were unable to turn off your automatic payment. Please try again and if the problem persists, contact Ailo support",
      [{ text: "Dismiss" }]
    );
  }, [navigation]);

  const showEditErrorAlert = useCallback((): void => {
    navigation.setParams({ showLoadingOverlay: false });

    alert(
      "Error",
      "We were unable to edit your automatic payment. Please try again and if the problem persists, contact Ailo support",
      [{ text: "Dismiss" }]
    );
  }, [navigation]);

  const onSaveClick = useCallback(
    (
      liabilityPaymentPlanInput: CreateLiabilityPaymentPlanInput
    ): Promise<any> => {
      navigation.setParams({ showLoadingOverlay: true });

      return updateMutation({
        variables: {
          updateLiabilityPaymentPlanInput: {
            liabilityPaymentPlanId: id,
            paymentMethodId: liabilityPaymentPlanInput.paymentMethodId,
            payerLegalEntityId: liabilityPaymentPlanInput.payerLegalEntityId
          }
        }
      })
        .then(onSuccess)
        .catch(showEditErrorAlert);
    },
    [showEditErrorAlert, id, navigation, updateMutation, onSuccess]
  );

  const cancelAutoPaymentClick = useCallback((): void => {
    alert(
      "Turn off auto rent payment?",
      "Are you sure you want to turn off your auto rent payment?",
      [
        { text: "No", style: "cancel" },
        {
          text: "Turn off",
          style: "destructive",
          onPress: (): void => {
            navigation.setParams({ showLoadingOverlay: true });

            cancelMutation({
              variables: {
                cancelLiabilityPaymentPlanInput: {
                  payerLegalEntityId: legalEntity.id.toString(),
                  liabilityPaymentPlanId: id
                }
              }
            })
              .then(({ data }) => {
                const plan = data?.cancelLiabilityPaymentPlan;

                if (plan) {
                  analytics.track(
                    false,
                    capitalCase(plan.details.frequency),
                    plan.id
                  );
                  onSuccess();
                } else {
                  showCancelErrorAlert();
                }
              })
              .catch(showCancelErrorAlert);
          }
        }
      ]
    );
  }, [
    analytics,
    cancelMutation,
    id,
    navigation,
    onSuccess,
    legalEntity.id,
    showCancelErrorAlert
  ]);

  if (!data && loading) return <SpinnerOverlay />;

  return (
    <DetailsEditComponent
      {...editDetails}
      showTitle={false}
      editAllDetails={false}
      onPaymentMethodChange={onPressPaymentMethodChange}
      onSaveClick={onSaveClick}
      primaryAction={{
        text: "Save Changes"
      }}
      secondaryAction={{
        text: "Turn off Auto Rent Payment",
        onClick: cancelAutoPaymentClick
      }}
      showLoadingOverlay={showLoadingOverlay}
    />
  );
};

export { EditDetailsScreen };
