import React, { FC } from "react";
import { View, TouchableWithoutFeedback } from "react-native";
import styled from "styled-components/native";
import { Text, DownCaretIcon, Colors } from "@ailo/primitives";

interface Props {
  onPress: () => void;
  callingCode: string;
}

export const CallingCode: FC<Props> = ({ onPress, callingCode }) => {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <StyledContainer>
        <DownCaretIcon color={Colors.TEXT.DARK.PLACEHOLDER} />
        <StyledText weight={"book"}>
          {"+"}
          {callingCode}
        </StyledText>
      </StyledContainer>
    </TouchableWithoutFeedback>
  );
};

const StyledText = styled(Text.BodyL)`
  margin-left: 2px;
`;

const StyledContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`;
