import { alert } from "@ailo/ui";
import { PaymentMethodTypeDomainEnum } from "@ailo/domains";
import { PaymentStatus } from "local/domain/ledger";

export const handleUnsuccessfulPaymentStatus = (
  paymentMethodType: string | undefined,
  paymentStatus: string | undefined,
  goBack: () => void
): void => {
  switch (paymentStatus) {
    case PaymentStatus.InsufficientFunds:
      alert(
        "Insufficient funds",
        "This amount exceeds your current balance. Please check your balance and try again.",
        [
          {
            text: "Dismiss",
            onPress: goBack
          }
        ]
      );
      break;
    case PaymentStatus.Unauthorized:
      alert(
        "Unauthorised",
        "You don’t have permission to process this transaction. Please check your permissions and try again.",
        [
          {
            text: "Dismiss",
            onPress: goBack
          }
        ]
      );
      break;
    case PaymentStatus.TransactionLimitReached:
      alert(
        "Error",
        "You have hit your transaction limit. Please try another amount or try again tomorrow. If the problem persists contact Ailo support.",
        [
          {
            text: "Dismiss",
            onPress: goBack
          }
        ]
      );
      break;
    case PaymentStatus.DuplicateFinancialTransaction:
      alert(
        "Error",
        "We were unable to process your payment. Please try again or contact Ailo support.",
        [
          {
            text: "Dismiss",
            onPress: goBack
          }
        ]
      );
      break;
    case PaymentStatus.FailedTransaction:
      alert(
        "Error",
        paymentMethodType === PaymentMethodTypeDomainEnum.CreditCard
          ? "Please contact your card issuer."
          : "We were unable to process your payment. Please check the details and try again or contact Ailo support.",
        [
          {
            text: "Dismiss",
            onPress: goBack
          }
        ]
      );
      break;
    case PaymentStatus.Fail:
      alert(
        "Error",
        "We were unable to process your payment. Please check the details and try again or contact Ailo support.",
        [
          {
            text: "Dismiss",
            onPress: goBack
          }
        ]
      );
      break;
    case PaymentStatus.LiabilityArchived:
      alert("Error", "No payment available.", [
        {
          text: "Go back",
          onPress: goBack
        }
      ]);
      break;
    default:
      alert(
        "Error",
        "We were unable to process your payment. Please check the details and try again or contact Ailo support.",
        [
          {
            text: "Dismiss",
            onPress: goBack
          }
        ]
      );
      throw new Error(`Payment returned an unknown status: ${paymentStatus}`);
  }
};
