import { PlatformFeatureId } from "local/graphql";
import { useGlobal } from "reactn";
import { useMemo } from "react";

export function useOnboardingFlag(): boolean | undefined {
  const [features] = useGlobal("availableFeatures");
  const [authenticatedUserSetupDataSet] = useGlobal(
    "authenticatedUserSetupDataSet"
  );

  const onboardingEnabled = useMemo(() => {
    if (!authenticatedUserSetupDataSet) return undefined;

    return features.includes(PlatformFeatureId.Onboarding);
  }, [authenticatedUserSetupDataSet, features]);

  return onboardingEnabled;
}
