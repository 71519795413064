import { formatAddress } from "@ailo/domain-helpers";
import { WithdrawStatus } from "@ailo/domains";
import { alert, Button, Money } from "@ailo/ui";
import {
  Property,
  Screens,
  useAnalytics,
  useNavigation,
  usePropertySelectorContext,
  useRoute
} from "local/common";
import { BankAccountMethod, toCents } from "local/domain/ledger";
import { useWithdrawFromWalletMutation } from "local/graphql";
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Keyboard, ViewStyle } from "react-native";
import { FormData } from "./types";

interface Props {
  style?: ViewStyle;
  watchAmount: string | undefined;
  billAmount: Money;
  maxTransferAmount: Money;
  bankAccountMethod: BankAccountMethod;
  idempotencyKey: string;
}

interface LoadingProps {
  style?: ViewStyle;
}

interface Statics {
  Loading: FC<LoadingProps>;
}

const withdrawStatusErrors = {
  [WithdrawStatus.InsufficientFunds]: {
    title: "Insufficient funds",
    message:
      "This amount exceeds your current balance. Please check your balance and try again."
  },
  [WithdrawStatus.Unauthorized]: {
    title: "Unauthorised",
    message:
      "You don't have permission to process this transaction. Please check your permissions and try again."
  },
  [WithdrawStatus.TransactionLimitReached]: {
    title: "Error",
    message:
      "You have hit your transaction limit. Please try another amount or try again tomorrow. If the problem persists contact Ailo support."
  },
  [WithdrawStatus.DuplicateFinancialTransaction]: {
    title: "Error",
    message:
      "We were unable to process your transfer. Please try again or contact Ailo support."
  }
};

const ConfirmTransferButton: FC<Props> & Statics = ({
  style,
  watchAmount = "$0",
  billAmount,
  maxTransferAmount,
  bankAccountMethod,
  idempotencyKey
}) => {
  const { handleSubmit } = useFormContext<FormData>();
  const [withdrawFromWalletMutation] = useWithdrawFromWalletMutation();
  const navigation = useNavigation();
  const { walletId, onClose, onSuccess, fromPropertyWallet } =
    useRoute<Screens.TransferFunds>().params;
  const analytics = useAnalytics();
  const { currentProperty } = usePropertySelectorContext();
  const propertyAddress = fromPropertyWallet
    ? getPropertyAddress(currentProperty)
    : undefined;
  const { id: toPaymentMethodId, accountNumber: bankAccountNumber } =
    bankAccountMethod;
  if (!bankAccountNumber) {
    throw new Error(
      "Bank account number is required but does not exist in transfer funds screen."
    );
  }

  const onSubmit = handleSubmit(async ({ amount }: { amount: string }) => {
    navigation.setParams({ showLoadingOverlay: true });
    Keyboard.dismiss();
    const cents = toCents(amount);
    const withdrawInput = {
      amount: { cents },
      fromWalletId: walletId,
      idempotencyKey,
      toPaymentMethodId,
      userFacingDescription: propertyAddress
    };
    const { data } = await withdrawFromWalletMutation({
      variables: { withdrawInput }
    });

    const channel =
      data?.withdrawFromWallet?.businessTransaction?.paymentChannel ||
      undefined;

    const status = data?.withdrawFromWallet?.status;
    switch (status) {
      case WithdrawStatus.Pending:
      case WithdrawStatus.Success:
        analytics.trackWithdraw({ withdrawType: "manual" }, false);
        navigation.navigate(Screens.FundsTransferred, {
          amount,
          bankAccountNumber,
          channel,
          onClose: onSuccess
        });
        break;
      case WithdrawStatus.InsufficientFunds:
      case WithdrawStatus.Unauthorized:
      case WithdrawStatus.TransactionLimitReached:
      case WithdrawStatus.DuplicateFinancialTransaction:
        analytics.trackWithdraw(
          {
            withdrawType: "manual",
            errorCode: status,
            errorMessage: withdrawStatusErrors[status].message
          },
          true
        );
        alert(
          withdrawStatusErrors[status].title,
          withdrawStatusErrors[status].message,
          [{ text: "Dismiss", onPress: onClose }]
        );
        break;
      default:
        analytics.trackWithdraw(
          {
            withdrawType: "manual",
            errorCode: status,
            errorMessage: "Transfer funds status does not exist."
          },
          true
        );
        alert(
          "Error",
          "We were unable to process your transfer. Please check the details and try again",
          [{ text: "Dismiss", onPress: onClose }]
        );
    }
    navigation.setParams({ showLoadingOverlay: false });
  });

  const cents = toCents(watchAmount);
  return (
    <Button.Primary
      disabled={cents == 0}
      onPress={(): void => {
        const remainingBalance = Money.fromCents(
          maxTransferAmount.cents - cents
        );
        const canStillPayBills = remainingBalance.cents >= billAmount.cents;

        if (canStillPayBills) {
          onSubmit();
        } else {
          alert(
            "Remaining balance insufficient to pay bills",
            `${billAmount.format()} in bills are due within 30 days.`,
            [
              { text: "Cancel", style: "cancel" },
              {
                text: "Continue transfer",
                onPress: (): void => {
                  onSubmit();
                }
              }
            ]
          );
          analytics.track(
            "Insufficient Funds to Pay Bills",
            undefined,
            "SCREEN"
          );
        }
      }}
      style={style}
      accessibilityLabel={"transfer-button"}
    >
      {"Transfer " + watchAmount}
    </Button.Primary>
  );
};

function getPropertyAddress(currentProperty?: Property): string | undefined {
  return currentProperty?.address
    ? formatAddress(currentProperty?.address, {
        format: "street, suburb, state, postcode"
      })
    : undefined;
}

const Loading: FC<LoadingProps> = ({ style }) => (
  <Button.Loading style={style} />
);

ConfirmTransferButton.Loading = Loading;

export { ConfirmTransferButton };
