import React, { FC } from "react";
import { View } from "react-native";
import { EditIcon } from "@ailo/primitives";
import { ListItemHeader, ListItemAction } from "@ailo/ui";
import { PaymentMethodListItem } from "@ailo/domains";
import { useNavigation, Screens, useNavigateToThisScreen } from "local/common";

interface Props {
  paymentMethodId: string;
  onPaymentMethodChanged: (paymentMethodId: string) => void;
}

const TransferToDisplay: FC<Props> = ({
  paymentMethodId,
  onPaymentMethodChanged
}) => {
  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();

  const onPressChange = (): void => {
    navigation.navigate(Screens.ChangeBankAccount, {
      onClose: returnToThisScreen,
      onSuccess: onPaymentMethodChanged
    });
  };

  return (
    <View>
      <ListItemHeader text={"Transfer To"} />
      <PaymentMethodListItem
        paymentMethodId={paymentMethodId}
        rightComponent={
          <ListItemAction
            label={"Change"}
            icon={EditIcon}
            onPress={onPressChange}
            accessibilityLabel={"change-transfer-to-button"}
          />
        }
      />
    </View>
  );
};

export { TransferToDisplay };
