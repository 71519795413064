import React from "react";
import { Text, Colors, SFC } from "@ailo/primitives";

interface Props {
  cooldown: number;
}

export const CooldownMessage: SFC<Props> = ({ style, cooldown }) => {
  return (
    <Text.BodyM style={style} color={Colors.TEXT.DARK.SECONDARY}>
      {"Resend code in 0:"}
      {String(cooldown).padStart(2, "0")}
    </Text.BodyM>
  );
};
