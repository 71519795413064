import { MappedQueryResult, useMappedQueryResult } from "@ailo/services";
import { ApolloError } from "@apollo/client";
import { useCurrentLegalEntity } from "local/common";
import {
  GetAutoPayLiabilityStatusesQueryVariables,
  useGetAutoPayLiabilityStatusesQuery
} from "local/graphql";
import { AiloRN } from "@ailo/ailorn";
import { AutoPayLiabilityStatus } from "local/domain/ledger";

export function useAutoPayLiabilityStatuses(): MappedQueryResult<
  AutoPayLiabilityStatus[] | undefined,
  GetAutoPayLiabilityStatusesQueryVariables
> {
  const [legalEntity] = useCurrentLegalEntity();
  const result = useGetAutoPayLiabilityStatusesQuery({
    variables: {
      payerLegalEntityId: legalEntity.id.toString()
    }
  });

  return useMappedQueryResult(result, (data) => {
    if (!data.autoPayLiabilityStatuses) {
      throw new ApolloError({
        errorMessage: "autoPayLiabilityStatuses query returned null"
      });
    }

    return data.autoPayLiabilityStatuses?.map((status) => {
      return {
        enabled: status?.enabled || false,
        payerId: AiloRN.fromString(status?.payerId || ""),
        maximumPaymentAmount: status?.maximumPaymentAmount || { cents: 50000 },
        paymentMethodId: status?.paymentMethodId || undefined
      };
    });
  });
}
