import {
  AddPaymentMethodIcon,
  Colors,
  RightChevronIcon,
  Text,
  UpdateProfileIcon
} from "@ailo/primitives";
import { Card, ListItem } from "@ailo/ui";
import {
  Screens,
  useNavigateToThisScreen,
  useNavigation,
  useOnFocus,
  usePropertySelectorContext
} from "local/common";
import { useKycStatus } from "local/domain/authz";
import { useGetDefaultPaymentMethodIdsForUser } from "local/domain/ledger/hooks";
import React, { FC, ReactNode, useCallback } from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { useInvestorOverviewAnalytics } from "../useInvestorOverviewAnalytics";

interface Props {
  style?: ViewStyle;
}

const PendingTasksCard: FC<Props> = ({ style }) => {
  const navigation = useNavigation();
  const analytics = useInvestorOverviewAnalytics();
  const returnToThisScreen = useNavigateToThisScreen();
  const { missing: kycMissing, result: kycResult } = useKycStatus();
  const { defaultInPaymentMethodId, refetch: refetchDefaultPaymentMethod } =
    useGetDefaultPaymentMethodIdsForUser();

  useOnFocus(kycResult.refetch);
  useOnFocus(refetchDefaultPaymentMethod);

  const { currentProperty } = usePropertySelectorContext();
  const hasSharesOwned =
    currentProperty &&
    currentProperty.__typename === "OwnedProperty" &&
    !!currentProperty.ownership.sharesOwnedRatio;

  const addBankAccountItem = {
    header: "Add Bank Account",
    subHeader: "To transfer your rent once it arrives",
    icon: <AddPaymentMethodIcon />,
    onPress: (): void => {
      analytics.trackPendingTask("Add Bank Account Click");
      navigation.navigate(Screens.AddBankAccount, {
        onSuccess: returnToThisScreen
      });
    }
  };
  const updateProfileItem = {
    header: "Update Your Profile",
    subHeader: "So we can verify your account",
    icon: <UpdateProfileIcon />,
    onPress: useCallback(async () => {
      analytics.trackPendingTask("Update Profile Click");
      navigation.navigate(Screens.AddProfileDetails, {
        onClose: returnToThisScreen,
        onProceed: returnToThisScreen,
        title: "Complete your profile",
        message: `Please complete your profile, so we can verify your identity and protect your information.`
      });
    }, [analytics, navigation, returnToThisScreen])
  };

  const tasks = [
    !defaultInPaymentMethodId ? addBankAccountItem : undefined,
    kycMissing ? updateProfileItem : undefined
  ].filter(Boolean);

  if (tasks.length && hasSharesOwned) {
    return (
      <CardContainer style={style}>
        <Text.BodyM
          color={Colors.TEXT.DARK.PRIMARY}
          style={{ paddingBottom: 16, paddingLeft: 16 }}
          weight={"medium"}
        >
          {"Pending Tasks"}
        </Text.BodyM>

        {tasks.map((task, i) => {
          return (
            <PendingListItem
              key={i}
              header={task?.header}
              subHeader={task?.subHeader}
              icon={task?.icon}
              onPress={task?.onPress}
              hideBottomBorder={i === tasks.length - 1}
            />
          );
        })}
      </CardContainer>
    );
  }

  return null;
};

interface ListProps {
  header: ReactNode;
  subHeader: ReactNode;
  icon?: ReactNode;
  onPress?: () => void;
  hideBottomBorder?: boolean;
}

const PendingListItem: FC<ListProps> = ({
  icon,
  header,
  subHeader,
  onPress,
  hideBottomBorder
}) => {
  return (
    <ListItem
      onPress={onPress}
      leftComponent={
        <View style={{ marginRight: 18, paddingLeft: 16 }}>{icon}</View>
      }
      header={
        <View style={{ justifyContent: "center", flex: 1 }}>
          <Text.BodyM weight={"medium"}>{header}</Text.BodyM>
          <Text.BodyS color={Colors.TEXT.DARK.SECONDARY}>
            {subHeader}
          </Text.BodyS>
        </View>
      }
      rightComponent={<RightChevronIcon width={20} height={20} />}
      style={{
        alignItems: "flex-start",
        paddingLeft: 0,
        borderType: hideBottomBorder ? "none" : "under-center-and-right"
      }}
    />
  );
};

const CardContainer = styled(Card)`
  padding: 20px 0 0;
  background-color: ${Colors.WHITE};
`;

export { PendingTasksCard };
