import React, { useCallback } from "react";
import { ViewStyle } from "react-native";
import { StyleProps } from "@ailo/primitives";
import { Card, MoneyInterface } from "@ailo/ui";
import { computeRentBalance, useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import { InvestorRentHistoryList } from "../InvestorRentHistoryList";
import { TenantRentHistoryList } from "../TenantRentHistoryList";
import { Screens, useNavigation } from "local/common";
import { RentHistoryHeader, ViewAllRentHistoryButton } from "./components";
import { LoadingRentHistoryCard } from "./LoadingRentHistoryCard";
import { EmptyRentHistoryCard } from "./EmptyRentHistoryCard";
import { ErrorRentHistoryCard } from "./ErrorRentHistoryCard";

interface Props {
  managementId?: string;
  tenancyId?: string;
  nextDueDate: string | null | undefined;
  overdueAmount: MoneyInterface | undefined;
  nextDueAmount: MoneyInterface | undefined | null;
  style?: StyleProps<ViewStyle>;
}

function RentHistoryCard({
  managementId,
  tenancyId,
  nextDueDate,
  overdueAmount,
  nextDueAmount,
  style
}: Props): React.ReactElement {
  if (!tenancyId) {
    throw new TypeError("tenancyId is not defined");
  }
  const isManagementCard = !!managementId;
  const isTenancyCard = !managementId;

  const hasLedgerRemakeFeature = useHasFeature(PlatformFeatureId.LedgerRemake);

  const computedRentBalance = !hasLedgerRemakeFeature
    ? undefined
    : computeRentBalance({ nextDueDate, overdueAmount, nextDueAmount });

  const navigation = useNavigation();

  const navigateToInvestorRentHistory = useCallback(() => {
    navigation.navigate(Screens.InvestorRentHistory, {
      managementId: managementId!,
      tenancyId: tenancyId!
    });
  }, [navigation, managementId, tenancyId]);

  const navigateToTenantRentHistory = useCallback(() => {
    navigation.navigate(Screens.TenantRentHistory, {
      tenancyId: tenancyId!,
      rentBalanceLabel: computedRentBalance?.label,
      rentBalanceColor: computedRentBalance?.color,
      rentBalance: computedRentBalance?.amount
    });
  }, [navigation, tenancyId, computedRentBalance]);

  return (
    <Card style={style}>
      <RentHistoryHeader
        rentBalanceLabel={computedRentBalance?.label}
        rentBalanceColor={computedRentBalance?.color}
        rentBalance={computedRentBalance?.amount}
      />
      {isManagementCard && (
        <>
          <InvestorRentHistoryList
            managementId={managementId!}
            tenancyId={tenancyId!}
            length={8}
            bounces={false}
            lastPaymentListItemBorderType={"under-center-and-right"}
          />
          <ViewAllRentHistoryButton onPress={navigateToInvestorRentHistory} />
        </>
      )}
      {isTenancyCard && (
        <>
          <TenantRentHistoryList
            tenancyId={tenancyId!}
            length={8}
            bounces={false}
            lastPaymentListItemBorderType={"under-center-and-right"}
          />
          <ViewAllRentHistoryButton onPress={navigateToTenantRentHistory} />
        </>
      )}
    </Card>
  );
}

RentHistoryCard.Loading = LoadingRentHistoryCard;
RentHistoryCard.Empty = EmptyRentHistoryCard;
RentHistoryCard.Error = ErrorRentHistoryCard;

export { RentHistoryCard };
