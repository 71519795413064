import React, { FC } from "react";
import { ListItem } from "@ailo/ui";
import { Text } from "@ailo/primitives";
import { formatMoney } from "accounting";

interface Wallet {
  name: string;
  availableBalance: {
    cents: number;
  };
}

interface Props {
  wallet: Wallet;
  onPress: () => void;
}

interface Statics {
  Loading: FC;
}

const WalletListItem: FC<Props> & Statics = ({ wallet, onPress }) => {
  const { availableBalance, name } = wallet;
  const formattedAvailableBalance = formatMoney(availableBalance.cents / 100);

  const balances = `Available: ${formattedAvailableBalance}`;

  return (
    <ListItem
      header={name}
      subHeader={balances}
      onPress={onPress}
      accessibilityLabel={"wallet-list-item"}
    />
  );
};

const Loading: FC = () => (
  <ListItem
    header={<Text.BodyM.Loading width={160} />}
    subHeader={<Text.BodyS.Loading width={120} />}
  />
);
WalletListItem.Loading = Loading;

export { WalletListItem };
