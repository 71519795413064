import React, { FC } from "react";
import { ViewStyle } from "react-native";
import { Button } from "@ailo/ui";
import { Screens, useNavigation, useNavigateToThisScreen } from "local/common";

interface Props {
  style?: ViewStyle;
  liabilityId: string;
  tenancyId: string;
  address?: Address | null;
  defaultPaymentAmount: number;
  paymentMethodId?: string;
  disabled: boolean;
}

interface Address {
  unitStreetNumber: string;
  streetName: string;
}

const PayRentButton: FC<Props> = ({
  style,
  liabilityId,
  tenancyId,
  address,
  defaultPaymentAmount,
  paymentMethodId,
  disabled
}) => {
  const navigation = useNavigation();

  const returnToThisScreen = useNavigateToThisScreen();

  const navigateToPayRentScreen = (paymentMethodId?: string): void => {
    navigation.navigate(Screens.PayRentLiability, {
      liabilityId,
      tenancyId,
      description: stringifyAddress(address),
      defaultPaymentAmount,
      paymentMethodId,
      reference: tenancyId,
      onClose: returnToThisScreen,
      showOtherPaymentOptions: true
    });
  };

  const navigateToSetupPaymentMethod = (
    onSuccess: (paymentMethodId: string) => void,
    showOtherPaymentOptions: boolean
  ): void => {
    navigation.navigate(Screens.SetupPaymentMethod, {
      showOtherPaymentOptions,
      onClose: returnToThisScreen,
      onSuccess
    });
  };

  const startPayNowFlow = (): void => {
    if (!paymentMethodId) {
      navigateToSetupPaymentMethod(navigateToPayRentScreen, true);
    } else {
      navigateToPayRentScreen();
    }
  };

  return (
    <Button.Primary
      style={style}
      onPress={startPayNowFlow}
      accessibilityLabel={"pay-rent-button"}
      testID={"pay-rent-button"}
      disabled={disabled}
    >
      {"Pay Rent"}
    </Button.Primary>
  );
};

function stringifyAddress(address?: Address | null): string {
  const unitStreetNumber = address?.unitStreetNumber;
  const streetName = address?.streetName;

  return [unitStreetNumber, streetName].filter(Boolean).join(" ");
}

export { PayRentButton };
