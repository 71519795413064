import React from "react";
import styled from "styled-components/native";
import { View } from "react-native";
import { Colors, EditIcon, opacify, SFC, Text } from "@ailo/primitives";
import { ListItemAction } from "@ailo/ui";
import { Screens, useNavigation } from "local/common";

interface Statics {
  Loading: SFC;
}

const StatusBoxContainer = styled(View)`
  padding: 16px;
  background: ${Colors.WHITE};
  border: 1px solid ${opacify(Colors.SPACE_BLACK, 0.1)};
  border-radius: 12px;
  overflow: hidden;
  flex-direction: row;
`;

const AutoPaymentStatusBox: SFC & Statics = ({ style }) => {
  const navigation = useNavigation();

  const onPressEdit = (): void =>
    navigation.navigate(Screens.AutomaticPayments);

  return (
    <StatusBoxContainer style={style}>
      <Text>{"Auto payments "}</Text>
      <Text weight={"medium"}>{"turned on"}</Text>
      <View style={{ flex: 1, flexDirection: "row-reverse" }}>
        <ListItemAction label={"Edit"} icon={EditIcon} onPress={onPressEdit} />
      </View>
    </StatusBoxContainer>
  );
};

const Loading: SFC = ({ style }) => (
  <StatusBoxContainer style={style}>
    <View style={{ padding: 2 }}>
      <Text.BodyS.Loading width={160} />
    </View>
  </StatusBoxContainer>
);
AutoPaymentStatusBox.Loading = Loading;

export { AutoPaymentStatusBox };
