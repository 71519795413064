import { isPresent } from "ts-is-present";
import { flatMap } from "lodash";
import {
  OnboardingTaskId,
  PlatformFeatureId,
  GetUserOnboardingStateQuery
} from "local/graphql";

interface OnboardingData {
  isTenant: boolean;
  isInvestor: boolean;
  isImpersonating: boolean;
  notificationPrimingEnabled: boolean;
  hasBankAccount: boolean;
  hasCreditCard: boolean;
  phoneNoVerified: boolean;
  completedOnboardingTasks: OnboardingTaskId[];
}

export function getOnboardingData(
  data: GetUserOnboardingStateQuery
): OnboardingData {
  const currentUser = data.effectiveUser;
  if (!currentUser) {
    throw new Error("Could not load user data");
  }

  const currentPerson = currentUser.person;
  if (!currentPerson) {
    throw new Error("Could not load person data");
  }

  const availableFeatures = flatMap(currentUser.organisations, (org) =>
    org.availableFeatures.map((feature) => feature.id)
  );
  const isTenant = availableFeatures.includes(PlatformFeatureId.TenantAccess);
  const isInvestor = availableFeatures.includes(
    PlatformFeatureId.InvestorAccess
  );

  const notificationPrimingEnabled = availableFeatures.includes(
    PlatformFeatureId.NotificationPriming
  );

  const completedOnboardingTasks = currentUser.onboardingTasks
    .filter(isPresent)
    .map((onboardingTask) => onboardingTask.onboardingTaskId);

  const hasBankAccount = !!currentPerson.bankAccounts?.pageInfo?.total;
  const hasCreditCard = !!currentPerson.creditCards?.pageInfo?.total;
  const phoneNoVerified = !!currentPerson.phoneNoVerified;

  const actualUser = data.actualUser;
  const isImpersonating = !!actualUser && actualUser.id !== currentUser.id;

  return {
    isImpersonating,
    isTenant,
    isInvestor,
    notificationPrimingEnabled,
    hasBankAccount,
    hasCreditCard,
    phoneNoVerified,
    completedOnboardingTasks
  };
}
