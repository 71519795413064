import React from "react";
import { Platform, View } from "react-native";
import { Colors, opacify, SFC, Text } from "@ailo/primitives";
import styled from "styled-components/native";

interface Props {
  address: string;
}

interface Statics {
  Loading: SFC;
}

const Loading: SFC = ({ style }) => {
  return (
    <OuterContainer>
      <Container style={style}>
        <Text.BodyL.Loading width={160} />
      </Container>
    </OuterContainer>
  );
};

const AddressHeader: SFC<Props> & Statics = ({ address, style }) => {
  return (
    <OuterContainer>
      <Container style={style}>
        <Text.BodyL weight={"medium"} style={{ justifyContent: "center" }}>
          {address}
        </Text.BodyL>
      </Container>
    </OuterContainer>
  );
};

AddressHeader.Loading = Loading;

const OuterContainer = styled(View)`
  overflow: hidden;
  ${Platform.select({
    ios: `
      height: 48.25px;
      padding-bottom: 0.25px;
      margin-bottom: -0.5px;
    `,
    android: `
      height: 52px;
      padding-bottom: 4px;
      margin-bottom: -4.25px;
    `
  })}
`;

const Container = styled(View)`
  padding: 14px 0px;
  align-items: center;
  background-color: ${Colors.WHITE};
  ${Platform.select({
    ios: `
      box-shadow: 0px 0.25px 0px ${opacify(Colors.SPACE_BLACK, 0.2)};
    `,
    android: `
      box-shadow: 0px 4px 0px ${opacify(Colors.SPACE_BLACK, 0.1)};
      elevation: 4;
    `
  })}
`;

export { AddressHeader };
