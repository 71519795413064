import React, { ReactElement } from "react";
import { StyleProp, ViewStyle, View } from "react-native";
import { Text, Colors } from "@ailo/primitives";

export interface ScreenHeaderProps {
  title: string;
  description?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export function ScreenHeader({
  title,
  description,
  style
}: ScreenHeaderProps): ReactElement {
  return (
    <View style={style}>
      <Text.DisplayS color={Colors.TEXT.DARK.PRIMARY} weight={"medium"}>
        {title}
      </Text.DisplayS>

      {description && (
        <Text.BodyM
          color={Colors.TEXT.DARK.SECONDARY}
          style={{ marginTop: 12 }}
        >
          {description}
        </Text.BodyM>
      )}
    </View>
  );
}
