import {
  uploadFileToFileService,
  useCurrentUser,
  ValidateFileFailedError
} from "@ailo/domains";
import {
  ensureMutationSucceeds,
  withScreenComponent,
  useAnalytics,
  useApolloClient,
  AiloSentry
} from "@ailo/services";
import {
  Button,
  ImageAvatar,
  ImagePicker,
  ImagePickerRef,
  PickFileAbortedError,
  PickFileError,
  SpinnerOverlay,
  useToastContext
} from "@ailo/ui";
import {
  ScreenContainer,
  Screens,
  useNavigation,
  useRoute
} from "local/common";
import { FileKind, useUpdatePersonProfileDetailsMutation } from "local/graphql";
import React from "react";
import { useForm } from "react-hook-form";
import { TouchableOpacity, View } from "react-native";
import { useLayout } from "@react-native-community/hooks";

export const EditProfilePhotoScreen = withScreenComponent(
  {
    statusBarStyle: "dark-content",
    viewTitle: null
  },
  (): React.ReactElement => {
    const currentUser = useCurrentUser();
    const toasts = useToastContext();
    const apolloClient = useApolloClient();
    const navigation = useNavigation();
    const analytics = useAnalytics();
    const { handleSubmit, formState } = useForm<{}>();
    const route = useRoute<Screens.EditProfilePhoto>();
    const { file } = route.params;
    const { onLayout, width: avatarWidth } = useLayout();

    const [updateMutation] = useUpdatePersonProfileDetailsMutation();

    const imagePickerRef = React.useRef<ImagePickerRef>(null);
    const editPhoto = async (): Promise<void> => {
      try {
        const file = await imagePickerRef.current?.pickImage();
        if (file) {
          navigation.navigate(Screens.EditProfilePhoto, { file });
        }
      } catch (error) {
        if (error instanceof PickFileAbortedError) {
          return;
        }
        if (error instanceof PickFileError) {
          toasts.show({
            type: "error",
            message:
              "Error happened while picking the picture file. Try again, choose a different picture, or contact Ailo support if the problem persists."
          });
          return;
        }
        throw error;
      }
    };

    const goBack = (): void => {
      navigation.navigate(Screens.EditProfile);
    };

    const submit = handleSubmit(async () => {
      try {
        const uploadedFile = await uploadFileToFileService({
          apolloClient,
          file: {
            fileName: file.name,
            fileUri: file.dataUri,
            kind: FileKind.AuthZPersonPhoto
          }
        });

        await ensureMutationSucceeds(
          updateMutation({
            variables: {
              input: {
                id: currentUser.person.ailoRN,
                photoFileId: uploadedFile.id
              }
            }
          }),
          {
            dataKey: "updatePersonProfileDetails"
          }
        );
      } catch (error) {
        if (error instanceof ValidateFileFailedError) {
          toasts.show({
            type: "error",
            message:
              "A problem occured while parsing the image file. Are you sure it's a proper image? Formats we support include: jpg, png, gif, heic."
          });
          return;
        }
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      toasts.show({
        type: "success",
        message: "Profile photo updated"
      });
      navigation.navigate(Screens.EditProfile);
      analytics.track("Profile Updated", {
        fields: ["Profile Photo"]
      });
    });

    return (
      <>
        <ScreenContainer
          stickyBottom={
            <>
              <Button.Secondary
                disabled={formState.isSubmitting}
                style={{ marginBottom: 8 }}
                onPress={goBack}
              >
                {"Cancel"}
              </Button.Secondary>
              <Button.Primary
                disabled={formState.isSubmitting}
                onPress={submit}
              >
                {"Confirm Profile Photo"}
              </Button.Primary>
            </>
          }
        >
          <ImagePicker
            ref={imagePickerRef}
            nativeOptions={{
              imagePickerOptions: { aspect: [1, 1], allowsEditing: true }
            }}
          />
          <View onLayout={onLayout}>
            <TouchableOpacity activeOpacity={0.9} onPress={editPhoto}>
              <ImageAvatar
                size={avatarWidth ?? 112}
                source={{ uri: file.dataUri }}
              />
            </TouchableOpacity>
          </View>
        </ScreenContainer>
        {formState.isSubmitting && <SpinnerOverlay />}
      </>
    );
  }
);
