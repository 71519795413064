import React from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { useClearPreviousDataOnVariablesChange } from "@ailo/services";
import {
  Card,
  DateTimeWithTimeZone,
  EmptyContent,
  isLedgerMaxDate
} from "@ailo/ui";
import { useOnFocus, useCurrentLegalEntity } from "local/common";
import { SetupAutoPayRentButton } from "local/domain/liabilityPaymentPlan";
import {
  AutoPaymentStatusBox,
  CardHeader,
  PayMyRentButton,
  RentStatusBox,
  RentStatusDisplay,
  TenancyEndDisplay
} from "./components";
import { OffAppRentPaymentAlert } from "./components/OffAppRentPaymentAlert";
import { useGetTenancyLiabilityQuery } from "local/graphql";
import { DepositData } from "../TenancyDepositCard/parseDepositData";
import { parseLiability } from "./parseLiabilityData";

const CardContainer = styled(Card)`
  padding: 20px 16px 16px 16px;
  border-radius: 8px;
`;

const ViewContainer = styled(View)`
  padding: 0px 16px;
`;

interface Props {
  tenancyId: string;
  address?: Address | null;
  deposit?: DepositData;
  style?: ViewStyle;
}

interface Address {
  unitStreetNumber: string;
  streetName: string;
}

function Loading({ style }: { style?: ViewStyle }): React.ReactElement {
  return (
    <CardContainer style={style}>
      <CardHeader />
      <RentStatusDisplay.Loading style={{ marginTop: 16 }} />
      <RentStatusBox.Loading style={{ marginTop: 16 }} />
      <PayMyRentButton.Loading style={{ marginTop: 16 }} />
    </CardContainer>
  );
}

function RentCard({
  tenancyId,
  address,
  style,
  deposit
}: Props): React.ReactElement {
  const [legalEntity] = useCurrentLegalEntity();

  const { loading, data, refetch } = useClearPreviousDataOnVariablesChange(
    useGetTenancyLiabilityQuery,
    {
      variables: { tenancyId, payerLegalEntityId: legalEntity.id.toString() }
    }
  );

  useOnFocus(refetch);

  if (!data && loading) return <Loading style={style} />;

  const liabilityData = parseLiability(data);

  if (!liabilityData) {
    return (
      <CardContainer style={style}>
        <CardHeader />
        <EmptyContent
          message={"No rent information currently available"}
          style={{ marginTop: 16 }}
        />
      </CardContainer>
    );
  }

  const showPayRentButton =
    !liabilityData.terminationAmountDue ||
    liabilityData.terminationAmountDue.cents > 0;

  const amountToPay = calculatePayment(
    liabilityData.nextDueAmount.cents,
    liabilityData.overdueAmount.cents,
    deposit
  );

  const isTerminatingWithNoNextRentDue =
    liabilityData.nextDueAmount.cents === 0 && !!liabilityData.terminationDate;

  const isOverdue =
    !isLedgerMaxDate(liabilityData.dueDate) &&
    DateTimeWithTimeZone.fromDateSubtractingAMillisecond(
      liabilityData.dueDate
    ).isOverdue() &&
    liabilityData.overdueAmount.cents > 0;
  const hasNoMoreRentDue = !isOverdue && isTerminatingWithNoNextRentDue;

  return (
    <Card style={[{ borderRadius: 8 }, style]}>
      <ViewContainer>
        {!hasNoMoreRentDue && (
          <>
            <CardHeader style={{ marginTop: 20 }} />
            <RentStatusDisplay
              style={{ marginTop: 16 }}
              liabilityData={liabilityData}
            />
            <RentStatusBox
              style={{ marginTop: 16 }}
              dueDate={liabilityData.dueDate}
              paidToDate={liabilityData.paidToDate}
              terminationDate={liabilityData.terminationDate}
              autoPaymentEnabled={liabilityData.anyAutoPaymentEnabled}
              deposit={deposit}
            />
          </>
        )}
        {liabilityData.anyAutoPaymentEnabled && (
          <AutoPaymentStatusBox style={{ marginTop: 16 }} />
        )}
        {showPayRentButton && (
          <View style={{ flexDirection: "row", marginVertical: 16 }}>
            <PayMyRentButton
              style={{ flex: 1 }}
              liabilityId={liabilityData.liabilityId}
              tenancyId={tenancyId}
              address={address}
              defaultPaymentAmount={amountToPay}
            />
            {!liabilityData.anyAutoPaymentEnabled && (
              <SetupAutoPayRentButton
                shortText={true}
                style={{ flex: 1, marginLeft: 8 }}
              />
            )}
          </View>
        )}
      </ViewContainer>
      {liabilityData.terminationDate && (
        <TenancyEndDisplay
          variant={hasNoMoreRentDue ? "medium" : "small"}
          terminationDate={liabilityData.terminationDate}
        />
      )}
      <OffAppRentPaymentAlert />
    </Card>
  );
}

function calculatePayment(
  nextDueAmount: number,
  overdueAmount: number,
  deposit?: DepositData
): number {
  if (overdueAmount > 0) return overdueAmount;
  const depositAmountPaid = deposit?.isPaidOrPending ? deposit.amount.cents : 0;
  return nextDueAmount - depositAmountPaid;
}

RentCard.Loading = Loading;

export { RentCard };
