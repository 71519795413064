import React, { FunctionComponent, useCallback, useEffect } from "react";
import {
  PaymentMethodType,
  PaymentMethodListContainer
} from "local/domain/ledger";
import {
  useRoute,
  useNavigation,
  Screens,
  useNavigateToThisScreen
} from "local/common";
import { Button } from "@ailo/ui";
import { LeftChevronIcon } from "@ailo/primitives";

const ChangeBankAccountScreen: FunctionComponent = () => {
  const { onClose, onSuccess } = useRoute<Screens.ChangePaymentMethod>().params;
  const navigation = useNavigation<Screens.ChangePaymentMethod>();

  const HeaderLeft = useCallback(
    () => (
      <Button.Text
        onPress={onClose}
        leftIcon={LeftChevronIcon}
        style={{ marginLeft: 16 }}
      />
    ),
    [onClose]
  );
  useEffect(() => {
    navigation.setOptions({
      headerLeft: HeaderLeft
    });
  }, [HeaderLeft, navigation]);

  const type = PaymentMethodType.BankAccount;

  const returnToThisScreen = useNavigateToThisScreen();

  const openAddBankAccountModal = useCallback(() => {
    navigation.navigate(Screens.AddBankAccount, {
      onSuccess: returnToThisScreen
    });
  }, [navigation, returnToThisScreen]);

  return (
    <PaymentMethodListContainer
      onPaymentMethodSelection={onSuccess}
      paymentMethodFilter={type}
      onAddPaymentMethodPress={openAddBankAccountModal}
      addPaymentMethodButtonText={"Add Bank Account"}
    />
  );
};

export { ChangeBankAccountScreen };
