import React, { FC } from "react";
import { Button } from "@ailo/ui";

interface Props {
  onPress: () => void;
}

interface Statics {
  Loading: FC;
}

const style = { margin: 16 };

const ViewAllButton: FC<Props> & Statics = ({ onPress }) => {
  return (
    <Button.Secondary onPress={onPress} style={style}>
      {"View All"}
    </Button.Secondary>
  );
};

const Loading: FC = () => {
  return <Button.Loading style={style} />;
};

ViewAllButton.Loading = Loading;

export { ViewAllButton };
