import React, { useCallback, useEffect, useState } from "react";
import { View, Image, Switch } from "react-native";
import {
  useAuth,
  useEnvironment,
  ScreenComponent,
  AuthLoginFailedError,
  AiloSentry
} from "@ailo/services";
import {
  Colors,
  AiloHouses,
  AiloWholeWordIcon,
  Text,
  useIsMountedRef
} from "@ailo/primitives";
import styled from "styled-components/native";
import { Button, Alert } from "@ailo/ui";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import * as WebBrowser from "expo-web-browser";
import { useGlobal } from "reactn";

export const LoginScreen: React.FC = () => {
  const { HELP_URL, SHOW_PASSWORD_LOGIN_TOGGLE } = useEnvironment();
  const { login, authEventObservable } = useAuth();
  const { top: topSafeAreaInset } = useSafeAreaInsets();
  const [usePasswordLogin, setUsePasswordLogin] = useGlobal("usePasswordLogin");
  const [errorMessage, setErrorMessage] = useState<string>();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    return authEventObservable.subscribeObserver("loginFailed", (error) => {
      if (!isMountedRef.current) return;
      if (error instanceof AuthLoginFailedError) {
        if (error.code === "login-declined") return;
        setErrorMessage(error.message);
      } else {
        AiloSentry.captureException(new Error("Unknown login failure"), {
          extras: {
            error
          }
        });
        setErrorMessage("A login error occurred");
      }
    });
  }, [authEventObservable, isMountedRef]);

  const loginPress = useCallback(() => {
    AiloSentry.addBreadcrumb({
      category: AiloSentry.Category.Auth.LoginInProgress,
      message: "User attempting to log in",
      level: AiloSentry.Severity.Info
    });
    login();
    setErrorMessage(undefined);
  }, [login]);

  const togglePasswordLogin = useCallback(
    (newUsePasswordLogin) => {
      setUsePasswordLogin(newUsePasswordLogin);
    },
    [setUsePasswordLogin]
  );

  const helpPress = useCallback(() => {
    WebBrowser.openBrowserAsync(HELP_URL);
  }, [HELP_URL]);

  return (
    <ScreenContainer
      statusBarStyle={"light-content"}
      topSafeAreaInset={topSafeAreaInset}
    >
      <FormContainer>
        <AiloWholeWordIcon width={97.39} height={40} color={Colors.WHITE} />
        <Button
          style={{ marginTop: 80, width: "100%" }}
          onPress={loginPress}
          backgroundColor={{
            default: Colors.CHARCOAL,
            pressed: Colors.INTERACTION.CHARCOAL.PRESSED,
            hovered: Colors.INTERACTION.CHARCOAL.HOVER
          }}
          contentColor={Colors.WHITE}
          testID={"login-button"}
          type={"large"}
        >
          {"Get Started"}
        </Button>
        {!!errorMessage && (
          <Alert
            style={{ marginTop: 20, width: "100%" }}
            type={"neutral"}
            iconType={"warning"}
            message={errorMessage}
          ></Alert>
        )}
        {SHOW_PASSWORD_LOGIN_TOGGLE ? (
          <Row style={{ marginTop: 10, alignItems: "center" }}>
            <Text.BodyM color={Colors.WHITE} weight={"medium"}>
              {"Password Login"}
            </Text.BodyM>
            <Switch
              onValueChange={togglePasswordLogin}
              value={usePasswordLogin}
              style={{ transform: [{ scaleX: 0.7 }, { scaleY: 0.7 }] }}
            />
          </Row>
        ) : null}
        <Row style={{ marginTop: 20 }}>
          <Button.Text onPress={helpPress}>
            <Text.BodyM color={Colors.WHITE} weight={"medium"}>
              {"Get help"}
            </Text.BodyM>
          </Button.Text>
        </Row>
      </FormContainer>
      <Image
        style={{
          width: 401,
          height: 130
        }}
        source={AiloHouses}
      />
    </ScreenContainer>
  );
};

const ScreenContainer = styled(ScreenComponent)`
  flex: 1;
  background-color: ${Colors.AILO_RED};
  align-items: center;
  justify-content: space-between;
  padding-top: ${(props: { topSafeAreaInset: number }): number =>
    props.topSafeAreaInset + 120}px;
`;

const FormContainer = styled(View)`
  flex: 1;
  width: 100%;
  align-items: center;
  margin-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;
`;

const Row = styled(View)`
  flex-direction: row;
`;
