import { useRoute as useReactRoute } from "@react-navigation/native";
import { AppNavigatorParamList } from "../screenParams";
import { Screens } from "../Screens";

const useRoute = <T extends Screens>(): {
  key: string;
  name: T;
  params: AppNavigatorParamList[T];
} => {
  return useReactRoute();
};

export { useRoute };
