import { Link } from "@ailo/primitives";
import {
  AiloSentry,
  ensureMutationSucceeds,
  useAnalytics,
  useEnvironment,
  withScreenComponent
} from "@ailo/services";
import { Button, FormField, SpinnerOverlay, useToastContext } from "@ailo/ui";
import {
  ScreenContainer,
  ScreenHeader,
  CodeInput,
  Screens,
  useNavigation,
  useRoute
} from "local/common";

import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { Keyboard, View } from "react-native";
import { getEmailErrorMessage } from "../common";
import {
  useVerifyEmailMutation,
  useSendEmailVerificationCodeMutation
} from "local/graphql";

interface FormData {
  verificationCode: string | null;
}

export const VerifyEmailAddressScreen = withScreenComponent(
  {
    statusBarStyle: "dark-content",
    viewTitle: null
  },
  (): React.ReactElement => {
    const { USER_EMAIL_VERIFICATION_CODE_LENGTH = 6 } = useEnvironment();
    const toasts = useToastContext();
    const navigation = useNavigation();
    const analytics = useAnalytics();
    const { emailAddress } = useRoute<Screens.VerifyEmailAddress>().params;

    const [sendEmailMutation, sendEmailVerificationMutationResult] =
      useSendEmailVerificationCodeMutation();
    const [verifyEmailMutation, verifyEmailMutationResult] =
      useVerifyEmailMutation();

    const resendVerificationCode = async (): Promise<void> => {
      Keyboard.dismiss();
      try {
        const result = await ensureMutationSucceeds(
          sendEmailMutation({
            variables: {
              emailAddress
            }
          }),
          {
            dataKey: "sendEmailVerificationCode"
          }
        );
        if (
          result.data?.sendEmailVerificationCode?.__typename ===
          "SendEmailVerificationErrorResponse"
        ) {
          toasts.show({
            type: "error",
            message: getEmailErrorMessage(
              result.data.sendEmailVerificationCode.errorCode
            )
          });
          return;
        }
      } catch (error) {
        AiloSentry.captureException(error);
        toasts.showFormSubmitError();
        return;
      }

      toasts.show({
        type: "success",
        message: "New verification code has been sent."
      });
    };

    const { control, handleSubmit, formState } = useForm<FormData>({
      mode: "onChange"
    });

    const submit = useMemo(
      () =>
        handleSubmit(async (data: FormData) => {
          Keyboard.dismiss();
          try {
            const result = await ensureMutationSucceeds(
              verifyEmailMutation({
                variables: {
                  emailAddress,
                  verificationCode: data.verificationCode!
                }
              }),
              {
                dataKey: "verifyEmail"
              }
            );
            if (
              result.data?.verifyEmail?.__typename ===
              "VerifyEmailErrorResponse"
            ) {
              toasts.show({
                type: "error",
                message: getEmailErrorMessage(result.data.verifyEmail.errorCode)
              });
              return;
            }
          } catch (error) {
            AiloSentry.captureException(error);
            toasts.showFormSubmitError();
            return;
          }

          toasts.show({
            type: "success",
            message: "Email address verified"
          });
          navigation.navigate(Screens.EditProfile);
          analytics.track("Email Change Verified");
        }),
      [
        analytics,
        emailAddress,
        handleSubmit,
        navigation,
        toasts,
        verifyEmailMutation
      ]
    );

    const isFormReadyToSubmit = formState.isDirty && formState.isValid;
    useEffect(() => {
      if (isFormReadyToSubmit) {
        submit();
      }
    }, [isFormReadyToSubmit, submit]);

    return (
      <>
        <ScreenContainer
          stickyBottom={
            <Button.Primary
              disabled={
                formState.isSubmitting ||
                !formState.isValid ||
                !formState.isDirty ||
                sendEmailVerificationMutationResult.loading
              }
              onPress={submit}
            >
              {"Verify Email Address"}
            </Button.Primary>
          }
        >
          <ScreenHeader
            title={"Please verify your email"}
            description={
              <>
                {"We have sent a verification code to your email address "}
                <Link variant={"primary"}>{emailAddress}</Link>
                {"."}
              </>
            }
          />

          <FormField style={{ marginTop: 24 }}>
            <Controller
              control={control}
              name={"verificationCode"}
              defaultValue={null}
              rules={{
                required: true,
                minLength: USER_EMAIL_VERIFICATION_CODE_LENGTH,
                maxLength: USER_EMAIL_VERIFICATION_CODE_LENGTH
              }}
              render={({ onChange, onBlur, value }): React.ReactElement => (
                <CodeInput
                  codeLength={USER_EMAIL_VERIFICATION_CODE_LENGTH}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
          </FormField>

          <View style={{ paddingTop: 24 }}>
            <Link variant={"primary"} onPress={resendVerificationCode}>
              {"Resend email verification"}
            </Link>
          </View>
        </ScreenContainer>
        {(verifyEmailMutationResult.loading ||
          sendEmailVerificationMutationResult.loading) && <SpinnerOverlay />}
      </>
    );
  }
);
