import {
  CalendarIcon,
  Colors,
  Text,
  PaymentMethodIcon,
  PaymentHistoryIcon
} from "@ailo/primitives";
import { PlatformFeatureId } from "local/graphql";
import { Screens, useNavigation } from "local/common";
import React, { FC, useCallback } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components/native";
import { ProfileListItem } from "../ProfileListItem";
import { WalletListItem } from "./components";
import { useAutoPayRentPossible } from "local/domain/ledger/autoPayRent";

const MyAccountSection: FC = () => {
  const navigation = useNavigation();
  const [availableFeatures] = useGlobal("availableFeatures");

  const navigateToPaymentMethodsScreen = useCallback(() => {
    navigation.navigate(Screens.PaymentMethods);
  }, [navigation]);

  const navigateToPaymentHistoryScreen = useCallback(() => {
    navigation.navigate(Screens.PaymentHistory);
  }, [navigation]);

  const navigateToAutomaticPaymentsScreen = useCallback(() => {
    navigation.navigate(Screens.AutomaticPayments);
  }, [navigation]);

  const autoPayRentEnabled = useAutoPayRentPossible();

  if (availableFeatures.includes(PlatformFeatureId.WalletAccess)) {
    return (
      <>
        <ListHeader>{"My Account"}</ListHeader>
        <WalletListItem />
        <ProfileListItem
          icon={<PaymentMethodIcon />}
          header={"Payment Methods"}
          testID={"payment-methods-list-item"}
          onPress={navigateToPaymentMethodsScreen}
        />
        {availableFeatures.includes(PlatformFeatureId.TransactionFees) && (
          <ProfileListItem
            icon={<PaymentHistoryIcon />}
            header={"Payment History"}
            testID={"payment-history-list-item"}
            onPress={navigateToPaymentHistoryScreen}
          />
        )}
        {(availableFeatures.includes(PlatformFeatureId.AutoWalletWithdraw) ||
          autoPayRentEnabled) && (
          <ProfileListItem
            icon={<CalendarIcon />}
            header={"Automatic Payments"}
            testID={"automatic-payments-list-item"}
            onPress={navigateToAutomaticPaymentsScreen}
          />
        )}
      </>
    );
  } else {
    return null;
  }
};

const ListHeader = styled(Text.BodyM)`
  padding-left: 16px;
  padding-top: 24px;
  padding-bottom: 8px;
  color: ${Colors.TEXT.DARK.SECONDARY};
`;

export { MyAccountSection };
