import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  withScreenComponent,
  useCurrentLegalEntity,
  useRoute,
  Screens,
  AddCreditDebitCardForm,
  useNavigateToThisScreen
} from "local/common";
import { useGatewayCapturePaymentDetailsQuery } from "local/graphql";

import { Text } from "@ailo/primitives";

export const EnterCreditDebitCardScreen = withScreenComponent(
  (): React.ReactElement => {
    const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();
    const [legalEntity] = useCurrentLegalEntity();

    const { loading, data } = useGatewayCapturePaymentDetailsQuery({
      fetchPolicy: "no-cache",
      variables: {
        owner: legalEntity.id.toString()
      }
    });

    const returnToThisScreen = useNavigateToThisScreen({ replace: true });

    const uri = data?.gatewayCapturePaymentDetails?.url;

    const { onSuccess } =
      useRoute<Screens.TenancyDepositEnterCreditDebitCard>().params;
    if (!data && loading)
      return (
        <AddCreditDebitCardForm.Loading
          style={{ flex: 1, marginBottom: bottomSafeAreaInset }}
        />
      );

    if (!uri) throw new Error("No Credit Card Uri found");

    return (
      <AddCreditDebitCardForm
        style={{ flex: 1, marginBottom: bottomSafeAreaInset }}
        uri={uri}
        onSuccess={onSuccess}
        onError={returnToThisScreen}
        header={
          <Text.BodyM style={{ textAlign: "center" }}>
            {
              "A service fee of 1.50% applies to credit card transactions and 0.95% to debit cards."
            }
          </Text.BodyM>
        }
      />
    );
  }
);
