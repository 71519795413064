const formatMoneyInput = ([text]: string[]): string => {
  const numbers = text.replace(/[^0-9^.]/g, "");
  const decimalIndex = numbers.indexOf(".");
  if (decimalIndex >= 0) {
    const integers = numbers.slice(0, decimalIndex) || "0";
    const decimals = numbers
      .slice(decimalIndex + 1)
      .replace(/[^0-9]/g, "")
      .substr(0, 2);
    return "$" + integers + "." + decimals;
  }
  return "$" + numbers;
};

export { formatMoneyInput };
