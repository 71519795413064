import React from "react";
import styled from "styled-components/native";
import {
  RentHistoryPaymentDetail,
  useGetRentHistoryListItemByIdQuery,
  useGetTenancyAddressQuery
} from "@ailo/domains";
import { didQueryNotLoadYet, didQuerySucceed } from "@ailo/services";
import {
  ScreenComponent,
  Screens,
  useNavCloseButton,
  useOnFocus,
  useRoute
} from "local/common";
import { PaymentLiabilityEntry } from "local/graphql";

export function TenantRentHistoryDetailScreen(): React.ReactElement | null {
  useNavCloseButton();
  const { tenancyId, liabilityEntryId } =
    useRoute<Screens.TenantRentHistoryDetail>().params;

  const liabilityEntryResult = useGetRentHistoryListItemByIdQuery({
    variables: { liabilityEntryId }
  });

  const propertyResult = useGetTenancyAddressQuery({
    variables: { tenancyId }
  });

  useOnFocus(liabilityEntryResult.refetch);
  useOnFocus(propertyResult.refetch);

  if (
    didQueryNotLoadYet(liabilityEntryResult) ||
    didQueryNotLoadYet(propertyResult)
  ) {
    return (
      <Container>
        <RentHistoryPaymentDetail.Loading />
      </Container>
    );
  }

  if (
    !didQuerySucceed(liabilityEntryResult) ||
    !didQuerySucceed(propertyResult)
  ) {
    const refetch = (): void => {
      liabilityEntryResult.refetch();
      propertyResult.refetch();
    };

    return (
      <Container>
        <RentHistoryPaymentDetail.Error onRetry={refetch} />
      </Container>
    );
  }

  if (
    liabilityEntryResult.data.liabilityEntry?.__typename ===
    "PaymentLiabilityEntry"
  ) {
    const paymentLiabilityEntry = liabilityEntryResult.data
      .liabilityEntry as PaymentLiabilityEntry;
    const unitStreetNumber =
      propertyResult.data?.tenancy?.property?.address?.unitStreetNumber || "";
    const streetName =
      propertyResult.data?.tenancy?.property?.address?.streetName || "";

    return (
      <Container>
        <RentHistoryPaymentDetail
          paymentLiabilityEntry={paymentLiabilityEntry}
          unitStreetNumber={unitStreetNumber}
          streetName={streetName}
          rentHistoryListType={"TENANT"}
        />
      </Container>
    );
  }

  return null;
}

const Container = styled(ScreenComponent)`
  flex: 1;
`;
