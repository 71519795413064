import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AiloRN: string;
  /** Base64-encoded binary file */
  Binary: any;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  GeneratedKey: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: Record<string, any>;
  /** Date string. (e.g. "2007-12-03") */
  LocalDate: string;
  Long: number;
  /** Markdown syntax string */
  Markdown: any;
  /** IANA Time Zone name. (e.g. "America/Los_Angeles") */
  TimeZone: string;
  /** Url syntax string */
  Url: any;
  URL: any;
  ZoneId: any;
};

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["Boolean"]>;
  abrCompanyLookup?: Maybe<AbrCompanyLookupResult>;
  accountOwner?: Maybe<AccountOwner>;
  accountTagBalances?: Maybe<PaginatedAccountTagBalances>;
  accounts?: Maybe<PaginatedAccounts>;
  actualUser?: Maybe<User>;
  agencyProperties: PaginatedAgencyProperties;
  agencyProperty?: Maybe<AgencyProperty>;
  agencyPropertyByOrgAndProperty?: Maybe<AgencyProperty>;
  ailoForm: AiloForm;
  ailoFormTemplate: AiloFormTemplate;
  ailoFormTemplateByType: AiloFormTemplate;
  ailoFormTemplates: Array<AiloFormTemplate>;
  ailoForms: Array<AiloForm>;
  /** Needs admin permissions to query. */
  allTenancies: BidirectionalPaginatedTenancies;
  /**
   * Amount due to be paid up til the 00am of the given timestamp minus all payment made against this liability up til now.
   * e.g. if liability is $100/wk rent. Tenant paid nothing, and input time is Wed 10am, this will return 2days * 100/7
   */
  amountDueToDate?: Maybe<Money>;
  atpEmptyStarterSchemaNoop?: Maybe<Scalars["Boolean"]>;
  autoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  autoPayLiabilityStatuses?: Maybe<Array<Maybe<AutoPayLiabilityStatus>>>;
  autoWithdrawPlanById?: Maybe<AutoWithdrawPlan>;
  autoWithdrawPlans?: Maybe<PaginatedAutoWithdrawPlans>;
  bankReconciliationDetailReport?: Maybe<PaginatedReconciliationDetail>;
  bankReconciliationReport?: Maybe<BankReconciliationReport>;
  billById: Bill;
  bills?: Maybe<PaginatedBills>;
  chatV2: ChatV2;
  checkSagaStatus?: Maybe<SagaStatusResponse>;
  claimedFilesCount?: Maybe<ClaimedFilesCount>;
  /** Lists companies in which the current person has "legal_entity:data:view" permission. */
  companies?: Maybe<Array<Company>>;
  contacts: PaginatedContacts;
  contactsByEmails: Array<Contact>;
  contactsByIds: Array<Contact>;
  contactsByManagement: ContactsByManagement;
  contactsByTenancy: ContactsByTenancy;
  contactsByUserAilorns: Array<Contact>;
  /** @deprecated Use 'effectiveUser' instead */
  currentUser?: Maybe<User>;
  effectiveUser?: Maybe<User>;
  emptyAutopayment?: Maybe<Scalars["Boolean"]>;
  entries?: Maybe<PaginatedEntries>;
  fee?: Maybe<Fee>;
  feeBlueprint?: Maybe<FeeBlueprint>;
  feeBlueprints?: Maybe<PaginatedFeeBlueprints>;
  feeEvents?: Maybe<Array<FeeEvent>>;
  feeTaxCategories?: Maybe<Array<FeeTaxCategory>>;
  fees?: Maybe<PaginatedFees>;
  file?: Maybe<File>;
  findChatsV2: PaginatedChatsV2;
  firstAndLastEntries?: Maybe<FirstAndLastEntries>;
  form: Form;
  gatewayCapturePaymentDetails?: Maybe<GatewayCapturePaymentDetails>;
  getCrn?: Maybe<GetCrnOutput>;
  getLegalEntityByCrn?: Maybe<GetLegalEntityByCrnOutput>;
  getTrustAccountLock?: Maybe<TrustAccountLock>;
  ignoreMe?: Maybe<IgnoreMe>;
  incomeAndExpenseByTaxCategory?: Maybe<IncomeAndExpenseByTaxCategoryData>;
  inspection: Inspection;
  inspectionAppointment: InspectionAppointment;
  inspectionAppointmentConnection: InspectionAppointmentConnection;
  inspectionArea: InspectionArea;
  inspectionAreas: Array<InspectionArea>;
  inspectionConnection: InspectionConnection;
  inspectionFeature: InspectionFeature;
  inspectionFeatures: Array<InspectionFeature>;
  inspections: Array<Inspection>;
  key?: Maybe<Key>;
  ledgerEvent?: Maybe<LedgerEvent>;
  legalEntity?: Maybe<LegalEntity>;
  liabilityById?: Maybe<Liability>;
  liabilityCalculation?: Maybe<LiabilityCalculation>;
  liabilityEntry?: Maybe<LiabilityEntry>;
  liabilityPaymentPlanById?: Maybe<LiabilityPaymentPlan>;
  liabilityPaymentPlans?: Maybe<PaginatedLiabilityPaymentPlans>;
  liabilityPlanChangePreview?: Maybe<LiabilityChangePreview>;
  liabilityReportIgnoreMe?: Maybe<LiabilityReport>;
  listBankAccountBlacklistByOwner?: Maybe<Array<BankAccountBlacklist>>;
  listPaymentMethodAiloFeesWaiverByOwner?: Maybe<
    Array<Maybe<PaymentMethodAiloFees>>
  >;
  mailByIds: Array<Mail>;
  management?: Maybe<Management>;
  managementEndReasons: Array<ManagementEndReason>;
  managementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  managementFeeBlueprints?: Maybe<PaginatedManagementFeeBlueprints>;
  managementFeeSchedule: ManagementFeeSchedule;
  managementFolio?: Maybe<ManagementFolio>;
  managementFolioIgnoreMe?: Maybe<ManagementFolio>;
  managementFolios?: Maybe<PaginatedManagementFolios>;
  managements?: Maybe<PaginatedManagements>;
  messageV2: MessageV2;
  organisation?: Maybe<Organisation>;
  organisations?: Maybe<PaginatedOrganisations>;
  /** @deprecated Use `bills` instead */
  paidBills: DeprecatedPaginatedBills;
  paidToDatePreview: PaidTo;
  payablePurposes?: Maybe<Array<Purpose>>;
  paymentMethodById?: Maybe<PaymentMethod>;
  paymentMethods?: Maybe<PaginatedPaymentMethods>;
  /** @deprecated Use `paymentMethods` instead. */
  paymentMethodsByOwner?: Maybe<PaginatedPaymentMethods>;
  paymentReferences?: Maybe<PaginatedPaymentReferences>;
  personEmailInUse: Scalars["Boolean"];
  previewMultipleRentReviews?: Maybe<Array<Maybe<RentReviewPlan>>>;
  properties?: Maybe<PaginatedProperties>;
  property?: Maybe<Property>;
  reconciliationReportPeriod?: Maybe<ReconciliationReportPeriod>;
  reconciliationReportPeriodByDate?: Maybe<ReconciliationReportPeriod>;
  reconciliationReportPeriods: ReconciliationReportPeriodConnection;
  recurringFee?: Maybe<RecurringFee>;
  /** @deprecated Use `feeBlueprint` instead. */
  recurringFeeBlueprint?: Maybe<RecurringFeeBlueprint>;
  /** @deprecated Use `feeBlueprints` instead. */
  recurringFeeBlueprints?: Maybe<Array<RecurringFeeBlueprint>>;
  /** @deprecated Use `feeBlueprints` instead. */
  recurringFeeBlueprintsV2?: Maybe<PaginatedRecurringFeeBlueprints>;
  recurringFees?: Maybe<Array<RecurringFee>>;
  recurringOwing?: Maybe<RecurringOwing>;
  recurringOwingByReference?: Maybe<RecurringOwing>;
  recurringOwingPreviewSummary: Array<OwingSummary>;
  reiForm: ReiForm;
  reiToken: ReiToken;
  reiTokens: Array<ReiToken>;
  rent?: Maybe<Rent>;
  rentReviewPreview?: Maybe<Array<Maybe<RentReviewPlan>>>;
  roles?: Maybe<Array<Role>>;
  /** @deprecated Use `LegalEntity.legalEntityMembershipsAsLegalEntity` instead. */
  rolesAtLegalEntity?: Maybe<Array<LegalEntityPersonRole>>;
  searchAgencyProperties?: Maybe<PaginatedAgencyPropertySearchItems>;
  searchContacts?: Maybe<PaginatedContactSearchItems>;
  searchTenancies?: Maybe<PaginatedTenancySearchResultItems>;
  searchTrustAccountWallets?: Maybe<PaginatedTrustAccountWalletSearchItems>;
  statement?: Maybe<Statement>;
  /** @deprecated The return type is deprecated. This is only being used for integration tests and will be removed eventually. */
  statementByAccount?: Maybe<DeprecatedStatementData>;
  statementProgress?: Maybe<StatementProgress>;
  statementProgresses?: Maybe<PaginatedStatementProgresses>;
  statements?: Maybe<PaginatedStatements>;
  supplier?: Maybe<Supplier>;
  /** @deprecated Use `supplier` instead */
  supplierById?: Maybe<Supplier>;
  suppliers: PaginatedSuppliers;
  taxCategories: Array<TaxCategory>;
  taxCategoryById?: Maybe<TaxCategory>;
  taxSummaryStatementData: TaxSummaryStatementData;
  tenancies?: Maybe<PaginatedTenancies>;
  tenancy?: Maybe<Tenancy>;
  tenancyAgreement?: Maybe<TenancyAgreement>;
  /**
   * Query to only get the tenancy ledger line items that are related to initial payment. Intended as a stop gap until the dedicated
   * deposit service exists
   */
  tenancyLedgerInitialPaymentLineItems: Array<TenancyLedgerLineItem>;
  tenancyLedgerLineItems: Array<TenancyLedgerLineItem>;
  threadV2: ThreadV2;
  totalClearedRentReceivedInWallets: TotalClearedRentReceived;
  transactionDetailsById?: Maybe<TransactionDetails>;
  transactionReport?: Maybe<PaginatedTransactionReport>;
  transactions?: Maybe<PaginatedTransactions>;
  transferSummaryStatementData?: Maybe<WalletStatementData>;
  /**
   * Query to get the range of a transfer summary statement.
   * The input businessTx and matching toDateTime corresponds to the transfer from management wallet to an investor's bank account.
   * This query returns the timestamp of that transfer (toDateTime), and the timestamp of the previous non-failed transfer (fromDateTime).
   * TransferSummaryStatementRange is to be applied as part of TransferSummaryStatementInput for the transferSummaryStatementData endpoint.
   */
  transferSummaryStatementRange?: Maybe<TransferSummaryStatementRange>;
  trustAccount?: Maybe<TrustAccount>;
  trustAccountReconciliationAdjustments: TrustAccountReconciliationAdjustmentConnection;
  trustAccountWallet?: Maybe<TrustAccountWallet>;
  trustAccountWallets: TrustAccountWalletConnection;
  trustAccounts: TrustAccountConnection;
  unreadMessagesCount: Scalars["Int"];
  /** @deprecated Use `bills` instead */
  upcomingBills: DeprecatedPaginatedBills;
  user?: Maybe<User>;
  users: Array<User>;
  walletBalanceReport?: Maybe<WalletBalanceReport>;
  walletById?: Maybe<Wallet>;
  walletByWalletOwnerReference?: Maybe<Wallet>;
  /** @deprecated Use `walletEntriesByOwnerRef` instead */
  walletEntriesWithStatusByOwnerRef?: Maybe<PaginatedWalletEntriesWithBtStatus>;
  walletOwnerLiabilityReport?: Maybe<WalletOwnerLiabilityReport>;
  walletStatement?: Maybe<WalletStatementData>;
};

export type QueryAbrCompanyLookupArgs = {
  abnOrAcn: Scalars["String"];
};

export type QueryAccountOwnerArgs = {
  reference: Scalars["AiloRN"];
};

export type QueryAccountTagBalancesArgs = {
  filter?: Maybe<ListAccountTagBalancesFilters>;
  paginationParams?: PaginationParams;
};

export type QueryAccountsArgs = {
  filters: ListAccountsFilters;
  pagination?: PaginationParams;
  sorts?: Array<ListAccountsSortParams>;
};

export type QueryAgencyPropertiesArgs = {
  input: AgencyPropertiesQueryInput;
};

export type QueryAgencyPropertyArgs = {
  id: Scalars["ID"];
};

export type QueryAgencyPropertyByOrgAndPropertyArgs = {
  organisationAilorn: Scalars["AiloRN"];
  propertyId: Scalars["ID"];
};

export type QueryAiloFormArgs = {
  id: Scalars["ID"];
};

export type QueryAiloFormTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryAiloFormTemplateByTypeArgs = {
  version?: Maybe<Scalars["Int"]>;
  state: AustralianState;
  type: AiloFormType;
};

export type QueryAllTenanciesArgs = {
  input: AllTenanciesQueryInput;
};

export type QueryAmountDueToDateArgs = {
  amountDueToDateInput: AmountDueToDateInput;
};

export type QueryAutoPayLiabilityStatusArgs = {
  payerId?: Maybe<Scalars["AiloRN"]>;
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryAutoPayLiabilityStatusesArgs = {
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryAutoWithdrawPlanByIdArgs = {
  autoWithdrawPlanId: Scalars["ID"];
  payerLegalEntityId?: Maybe<Scalars["AiloRN"]>;
};

export type QueryAutoWithdrawPlansArgs = {
  disableOwnerFilter?: Maybe<Scalars["Boolean"]>;
  enabled: Scalars["Boolean"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryBankReconciliationDetailReportArgs = {
  cursor?: Maybe<BidirectionalPageCursor>;
  filter?: Maybe<Filter>;
};

export type QueryBankReconciliationReportArgs = {
  filter?: Maybe<DateRangeFilter>;
};

export type QueryBillByIdArgs = {
  billId: Scalars["ID"];
};

export type QueryBillsArgs = {
  id?: Maybe<Array<Scalars["AiloRN"]>>;
  organisationId?: Maybe<Array<Scalars["AiloRN"]>>;
  payerId?: Maybe<Array<Scalars["AiloRN"]>>;
  payerIdNotIn?: Maybe<Array<Scalars["AiloRN"]>>;
  payerType?: Maybe<Array<BillPayerType>>;
  payeeId?: Maybe<Array<Scalars["AiloRN"]>>;
  taxCategoryId?: Maybe<Array<Scalars["String"]>>;
  taxCategoryIdNotIn?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Array<BillStatus>>;
  paymentStatus?: Maybe<Array<BillPaymentStatus>>;
  management?: Maybe<Array<Scalars["AiloRN"]>>;
  team?: Maybe<Array<Scalars["AiloRN"]>>;
  paid?: Maybe<Scalars["Boolean"]>;
  dueDate?: Maybe<LocalDateRangeCondition>;
  payByDate?: Maybe<LocalDateRangeCondition>;
  paymentDate?: Maybe<LocalDateRangeCondition>;
  invoiceNumber?: Maybe<Array<Scalars["String"]>>;
  agencyStatus?: Maybe<Array<BillAgencyStatus>>;
  sort?: Maybe<BillSortParams>;
  cursor?: Maybe<PageCursorWithoutSort>;
  excludeBillsWithEmptyPaymentStatus?: Maybe<Scalars["Boolean"]>;
  includeUnassigned?: Maybe<Scalars["Boolean"]>;
};

export type QueryChatV2Args = {
  chatAilorn: Scalars["AiloRN"];
};

export type QueryCheckSagaStatusArgs = {
  sagaId?: Maybe<Scalars["ID"]>;
};

export type QueryClaimedFilesCountArgs = {
  claimerAilorn: Scalars["AiloRN"];
  fileKind?: Maybe<FileKind>;
};

export type QueryContactsArgs = {
  input: ContactsQueryInput;
};

export type QueryContactsByEmailsArgs = {
  organisationAilorn: Scalars["AiloRN"];
  emails: Array<Scalars["String"]>;
};

export type QueryContactsByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QueryContactsByManagementArgs = {
  managementAilorn: Scalars["AiloRN"];
  filters?: Maybe<ContactsFilters>;
};

export type QueryContactsByTenancyArgs = {
  tenancyAilorn: Scalars["AiloRN"];
  filters?: Maybe<ContactsFilters>;
};

export type QueryContactsByUserAilornsArgs = {
  organisationAilorn: Scalars["AiloRN"];
  userAilorns: Array<Scalars["AiloRN"]>;
};

export type QueryEntriesArgs = {
  filters: ListEntriesFilters;
  pagination?: PaginationParams;
  sorts?: Array<ListEntriesSortParams>;
};

export type QueryFeeArgs = {
  id: Scalars["ID"];
};

export type QueryFeeBlueprintArgs = {
  id: Scalars["ID"];
};

export type QueryFeeBlueprintsArgs = {
  conditions?: Maybe<FeeBlueprintsQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
};

export type QueryFeesArgs = {
  conditions?: Maybe<FeesQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
  sort?: Maybe<Array<FeeSort>>;
};

export type QueryFileArgs = {
  id: Scalars["AiloRN"];
};

export type QueryFindChatsV2Args = {
  input: PaginatedChatsInputV2;
};

export type QueryFirstAndLastEntriesArgs = {
  filters: ListEntriesFilters;
};

export type QueryFormArgs = {
  id: Scalars["ID"];
};

export type QueryGatewayCapturePaymentDetailsArgs = {
  owner: Scalars["AiloRN"];
};

export type QueryGetCrnArgs = {
  input: GetCrnInput;
};

export type QueryGetLegalEntityByCrnArgs = {
  input: GetLegalEntityByCrnInput;
};

export type QueryGetTrustAccountLockArgs = {
  trustAccountAilorn: Scalars["AiloRN"];
};

export type QueryIncomeAndExpenseByTaxCategoryArgs = {
  input?: Maybe<IncomeAndExpenseByTaxCategoryInput>;
};

export type QueryInspectionArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionAppointmentArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionAppointmentConnectionArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<InspectionAppointmentFilter>;
  sorting?: Maybe<Array<InspectionAppointmentSort>>;
};

export type QueryInspectionAreaArgs = {
  id: Scalars["ID"];
};

export type QueryInspectionConnectionArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<InspectionFilter>;
  sorting?: Maybe<Array<InspectionSort>>;
};

export type QueryInspectionFeatureArgs = {
  id: Scalars["ID"];
};

export type QueryKeyArgs = {
  id: Scalars["ID"];
};

export type QueryLedgerEventArgs = {
  id: Scalars["ID"];
};

export type QueryLegalEntityArgs = {
  id: Scalars["AiloRN"];
};

export type QueryLiabilityByIdArgs = {
  liabilityId: Scalars["ID"];
};

export type QueryLiabilityCalculationArgs = {
  liabilityCalculationInput: LiabilityCalculationInput;
};

export type QueryLiabilityEntryArgs = {
  liabilityEntryId: Scalars["ID"];
};

export type QueryLiabilityPaymentPlanByIdArgs = {
  liabilityPaymentPlanId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryLiabilityPaymentPlansArgs = {
  enabled: Scalars["Boolean"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type QueryLiabilityPlanChangePreviewArgs = {
  planToPreview: LiabilityPlanInput;
};

export type QueryListBankAccountBlacklistByOwnerArgs = {
  owner: Scalars["AiloRN"];
};

export type QueryListPaymentMethodAiloFeesWaiverByOwnerArgs = {
  owner: Scalars["AiloRN"];
};

export type QueryMailByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type QueryManagementArgs = {
  managementId: Scalars["ID"];
};

export type QueryManagementFeeBlueprintArgs = {
  id: Scalars["ID"];
};

export type QueryManagementFeeBlueprintsArgs = {
  conditions?: Maybe<ManagementFeeBlueprintsQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
};

export type QueryManagementFeeScheduleArgs = {
  id: Scalars["ID"];
};

export type QueryManagementFolioArgs = {
  id: Scalars["ID"];
};

export type QueryManagementFoliosArgs = {
  id?: Maybe<Array<Scalars["ID"]>>;
  ownerId?: Maybe<Array<Scalars["ID"]>>;
  managingEntityId?: Maybe<Array<Scalars["ID"]>>;
  paginationParams?: PaginationParams;
};

export type QueryMessageV2Args = {
  messageAilorn: Scalars["AiloRN"];
};

export type QueryOrganisationArgs = {
  id: Scalars["ID"];
};

export type QueryOrganisationsArgs = {
  pageCursor?: Maybe<PageCursor>;
  orgType?: Maybe<OrganisationType>;
  search?: Maybe<Scalars["String"]>;
};

export type QueryPaidBillsArgs = {
  toBePaidBy: Scalars["AiloRN"];
  cursor?: Maybe<BillPageCursor>;
};

export type QueryPaidToDatePreviewArgs = {
  owing: RecurringOwingCalculationInput;
  totalAmountPaid?: Maybe<MoneyInput>;
};

export type QueryPayablePurposesArgs = {
  filters: ListPurposesFilters;
};

export type QueryPaymentMethodByIdArgs = {
  paymentMethodId: Scalars["ID"];
};

export type QueryPaymentMethodsArgs = {
  conditions?: Maybe<PaymentMethodSearchOptions>;
};

export type QueryPaymentMethodsByOwnerArgs = {
  options?: Maybe<PaymentMethodSearchOptions>;
  owner: Scalars["AiloRN"];
};

export type QueryPaymentReferencesArgs = {
  cursor?: Maybe<BillDefaultPageCursor>;
  conditions?: Maybe<PaymentReferencesQueryConditions>;
};

export type QueryPersonEmailInUseArgs = {
  email: Scalars["String"];
};

export type QueryPreviewMultipleRentReviewsArgs = {
  proposedPlan: PreviewMultipleRentReviewsInput;
};

export type QueryPropertiesArgs = {
  pageCursor: PmPageCursor;
  search?: Maybe<Scalars["String"]>;
};

export type QueryPropertyArgs = {
  propertyId: Scalars["ID"];
};

export type QueryReconciliationReportPeriodArgs = {
  id: Scalars["ID"];
};

export type QueryReconciliationReportPeriodByDateArgs = {
  input: ReportPeriodByDateInput;
};

export type QueryReconciliationReportPeriodsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<ReconciliationReportPeriodFilter>;
  sorting?: Maybe<Array<ReconciliationReportPeriodSort>>;
};

export type QueryRecurringFeeArgs = {
  id: Scalars["AiloRN"];
};

export type QueryRecurringFeeBlueprintArgs = {
  id: Scalars["AiloRN"];
};

export type QueryRecurringFeeBlueprintsArgs = {
  conditions: RecurringFeeBlueprintsQueryInput;
};

export type QueryRecurringFeeBlueprintsV2Args = {
  conditions?: Maybe<RecurringFeeBlueprintsQueryConditions>;
  cursor?: Maybe<PageCursorWithoutSort>;
};

export type QueryRecurringFeesArgs = {
  conditions: RecurringFeesQueryInput;
};

export type QueryRecurringOwingArgs = {
  id: Scalars["ID"];
};

export type QueryRecurringOwingByReferenceArgs = {
  ref: Scalars["AiloRN"];
};

export type QueryRecurringOwingPreviewSummaryArgs = {
  owing: RecurringOwingCalculationInput;
};

export type QueryReiFormArgs = {
  id: Scalars["ID"];
};

export type QueryReiTokenArgs = {
  id: Scalars["ID"];
};

export type QueryRentArgs = {
  id: Scalars["AiloRN"];
};

export type QueryRentReviewPreviewArgs = {
  proposedPlan: RentReviewProposedPlanInput;
};

export type QueryRolesArgs = {
  conditions?: Maybe<RolesQueryConditions>;
};

export type QueryRolesAtLegalEntityArgs = {
  legalEntity: Scalars["AiloRN"];
};

export type QuerySearchAgencyPropertiesArgs = {
  input: SearchAgencyPropertiesQueryInput;
};

export type QuerySearchContactsArgs = {
  organisationAilornIn?: Maybe<Array<Scalars["AiloRN"]>>;
  typeOf?: Maybe<Array<Scalars["String"]>>;
  legalEntityType?: Maybe<Array<Scalars["String"]>>;
  query?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<ContactSortParams>>;
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export type QuerySearchTenanciesArgs = {
  input: SearchTenanciesQueryInput;
};

export type QuerySearchTrustAccountWalletsArgs = {
  input: SearchTrustAccountWalletsQueryInput;
};

export type QueryStatementArgs = {
  id: Scalars["ID"];
};

export type QueryStatementByAccountArgs = {
  statementByAccountInput: StatementByAccountInput;
};

export type QueryStatementProgressArgs = {
  id: Scalars["ID"];
};

export type QueryStatementProgressesArgs = {
  filter: StatementProgressFilter;
  cursor: StatementProgressCursor;
};

export type QueryStatementsArgs = {
  filter?: Maybe<GeneralStatementFilter>;
  paginationParams?: Maybe<PaginationParams>;
  sort?: Maybe<Array<StatementSort>>;
};

export type QuerySupplierArgs = {
  id: Scalars["ID"];
};

export type QuerySupplierByIdArgs = {
  supplierId: Scalars["ID"];
};

export type QuerySuppliersArgs = {
  cursor: PageCursor;
  conditions?: Maybe<SuppliersQueryInput>;
};

export type QueryTaxCategoryByIdArgs = {
  taxCategoryId: Scalars["String"];
};

export type QueryTaxSummaryStatementDataArgs = {
  statementRequest: TaxSummaryStatementInput;
};

export type QueryTenanciesArgs = {
  pageSize?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["String"]>;
};

export type QueryTenancyArgs = {
  tenancyId: Scalars["ID"];
};

export type QueryTenancyAgreementArgs = {
  id: Scalars["ID"];
};

export type QueryTenancyLedgerInitialPaymentLineItemsArgs = {
  input?: Maybe<TenancyLedgerLineItemsInput>;
};

export type QueryTenancyLedgerLineItemsArgs = {
  input?: Maybe<TenancyLedgerLineItemsInput>;
};

export type QueryThreadV2Args = {
  threadAilorn: Scalars["AiloRN"];
};

export type QueryTotalClearedRentReceivedInWalletsArgs = {
  ownerRef: Scalars["AiloRN"];
};

export type QueryTransactionDetailsByIdArgs = {
  businessTransactionId: Scalars["AiloRN"];
};

export type QueryTransactionReportArgs = {
  cursor: LedgerPageCursor;
  filter?: Maybe<Filter>;
};

export type QueryTransactionsArgs = {
  filter: ListTransactionsFilters;
  paginationParams?: PaginationParams;
  sort?: Array<ListTransactionsSortParams>;
};

export type QueryTransferSummaryStatementDataArgs = {
  input?: Maybe<TransferSummaryStatementInput>;
};

export type QueryTransferSummaryStatementRangeArgs = {
  input?: Maybe<TransferSummaryStatementRangeInput>;
};

export type QueryTrustAccountArgs = {
  id: Scalars["ID"];
};

export type QueryTrustAccountReconciliationAdjustmentsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountReconciliationAdjustmentFilter>;
  sorting?: Maybe<Array<TrustAccountReconciliationAdjustmentSort>>;
};

export type QueryTrustAccountWalletArgs = {
  id: Scalars["ID"];
};

export type QueryTrustAccountWalletsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountWalletFilter>;
  sorting?: Maybe<Array<TrustAccountWalletSort>>;
};

export type QueryTrustAccountsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountFilter>;
  sorting?: Maybe<Array<TrustAccountSort>>;
};

export type QueryUnreadMessagesCountArgs = {
  input: UnreadMessagesCountInput;
};

export type QueryUpcomingBillsArgs = {
  toBePaidBy: Scalars["AiloRN"];
  cursor?: Maybe<BillPageCursor>;
};

export type QueryUserArgs = {
  id: Scalars["Int"];
};

export type QueryUsersArgs = {
  ids: Array<Scalars["Int"]>;
};

export type QueryWalletBalanceReportArgs = {
  filter?: Maybe<Filter>;
};

export type QueryWalletByIdArgs = {
  walletId: Scalars["ID"];
};

export type QueryWalletByWalletOwnerReferenceArgs = {
  walletOwnerReference: Scalars["AiloRN"];
};

export type QueryWalletEntriesWithStatusByOwnerRefArgs = {
  aggregated?: Maybe<Scalars["Boolean"]>;
  cursor?: Maybe<LedgerPageCursor>;
  ownerRef: Scalars["AiloRN"];
  relatingToManagementAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type QueryWalletOwnerLiabilityReportArgs = {
  filter?: Maybe<DateRangeFilter>;
};

export type QueryWalletStatementArgs = {
  input: WalletStatementInput;
};

export type Mutation = {
  __typename?: "Mutation";
  unlockReportPeriod: ReportPeriodLockChangeResult;
  createOneTrustAccountReconciliationAdjustment: TrustAccountReconciliationAdjustment;
  removeOneTrustAccountReconciliationAdjustment: TrustAccountReconciliationAdjustment;
  createOneTrustAccountStatementBalance: TrustAccountStatementBalance;
  createOneTrustAccount: TrustAccount;
  updateOneTrustAccount: TrustAccount;
  createStatements: CreateStatementsResponse;
  createStatementsWithSubject: CreateStatementsResponse;
  createStatementsForAllManagements: Scalars["String"];
  retryCreateStatement?: Maybe<Statement>;
  registerPushToken?: Maybe<PushToken>;
  deregisterPushToken?: Maybe<PushToken>;
  createTenancy?: Maybe<Tenancy>;
  updateEndOfTenancy?: Maybe<Tenancy>;
  updateIngoingTenancy?: Maybe<Tenancy>;
  reviewLease?: Maybe<Tenancy>;
  voidTenancy: VoidTenancyPayload;
  alterTenancyStartDate?: Maybe<Tenancy>;
  createRents?: Maybe<CreateRentsPayload>;
  deleteRent?: Maybe<DeleteRentPayload>;
  /** @deprecated Use `createFeeBlueprint` instead. */
  createRecurringFeeBlueprint?: Maybe<RecurringFeeBlueprint>;
  createFeeBlueprint?: Maybe<FeeBlueprint>;
  findOrCreateFeeBlueprint?: Maybe<FeeBlueprint>;
  /** @deprecated Use `updateFeeBlueprint` instead. */
  updateRecurringFeeBlueprint?: Maybe<RecurringFeeBlueprint>;
  updateFeeBlueprint?: Maybe<FeeBlueprint>;
  clearManagementEnd?: Maybe<ClearManagementEndPayload>;
  updateManagementAgreement: UpdateManagementAgreementPayload;
  updateManagementFee: ManagementFeeSchedule;
  createFee?: Maybe<Fee>;
  updateFee?: Maybe<Fee>;
  createRecurringFee?: Maybe<RecurringFee>;
  createRecurringFees?: Maybe<Array<RecurringFee>>;
  /**
   * Cancels the recurring fee by setting its end date, to stop charging the fee.
   * Any outstanding balance still needs to be paid off.
   */
  cancelRecurringFee?: Maybe<RecurringFee>;
  updateRecurringFee?: Maybe<RecurringFee>;
  setupManagementAndTenancy?: Maybe<SetupResult>;
  createProperty?: Maybe<Property>;
  updateProperty?: Maybe<Property>;
  createRentCredit?: Maybe<RentCreditDetailsPayload>;
  createTenancyAgreement?: Maybe<CreateTenancyAgreementPayload>;
  updateTenancyAgreement?: Maybe<UpdateTenancyAgreementPayload>;
  /** @deprecated Use `createRentReview` instead. */
  reviewRent?: Maybe<Tenancy>;
  createRentReview?: Maybe<CreateRentReviewPayload>;
  editRentReview?: Maybe<EditRentReviewPayload>;
  attachFilesToTenancy?: Maybe<Tenancy>;
  attachFilesToManagement?: Maybe<Management>;
  deleteTenancyFile?: Maybe<DeleteTenancyFilePayload>;
  deleteManagementFile?: Maybe<DeleteManagementFilePayload>;
  findOrCreateManagementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  createManagementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  updateManagementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  createOrUpdateManagementNote?: Maybe<CreateOrUpdateManagementNotePayload>;
  endManagementV2: EndManagementV2Payload;
  lockTrustAccount?: Maybe<TrustAccountLock>;
  payFromWallet?: Maybe<SagaStatusResponse>;
  receiptFunds?: Maybe<SagaStatusResponse>;
  walletTransfer?: Maybe<SagaStatusResponse>;
  createRecurringOwing?: Maybe<RecurringOwing>;
  updateRecurringOwing?: Maybe<RecurringOwing>;
  revokeMailboxAccess: Scalars["Boolean"];
  sendMail: Mail;
  /** @deprecated Use `createPaymentMethod` instead */
  addBPay?: Maybe<BPay>;
  /** @deprecated Use `createPaymentMethod` instead */
  addBankAccount?: Maybe<BankAccount>;
  /** @deprecated Use `createPaymentMethod` instead */
  addCreditCard?: Maybe<CreditCard>;
  adjustLiabilityEntry?: Maybe<AdjustmentLiabilityEntry>;
  archiveLiability: LiabilityStatus;
  cancelAutoPay?: Maybe<ResponseStatus>;
  cancelAutoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  cancelAutoWithdrawPlan?: Maybe<AutoWithdrawPlan>;
  cancelLiabilityPaymentPlan?: Maybe<LiabilityPaymentPlan>;
  cleanOrphanedBusinessTransaction: BusinessTransactionStatus;
  cleanUnclearedBusinessTransaction: BusinessTransactionStatus;
  /**
   * Use for BILL HOP1 late reversal and HOP2 has already been SUCCESS and cleared. Details: http://ailo.atlassian.net/wiki/spaces/TS/pages/1622245700/How+to+do+all+Reversal+Refund+like+things
   * No real world payment transaction will be created, purely Ailo adjustment internally.
   */
  createAdjustmentForPayer: BusinessTransaction;
  createAutoWithdrawPlanV2?: Maybe<AutoWithdrawPlan>;
  createBankAccountBlacklist: BankAccountBlacklist;
  createLiabilityPaymentPlan?: Maybe<LiabilityPaymentPlan>;
  createOrUpdatePaymentMethodAiloFeeWaiver: Array<Maybe<PaymentMethodAiloFees>>;
  createOverdraftAllowance: OverdraftAllowance;
  createPaymentMethod?: Maybe<PaymentMethod>;
  createUpdateAutoPay?: Maybe<ResponseStatus>;
  creditFromAiloBank?: Maybe<ResponseStatus>;
  deleteBankAccountBlacklist: BankAccountBlacklist;
  /**
   * Graphql best practice require mutation return something.
   * This should always return true.
   * Exceptions may be thrown in error.
   */
  deletePaymentMethod: Scalars["Boolean"];
  emitLiabilityStateChangedEvent?: Maybe<Liability>;
  generateLiabilityEntries: GenerateLiabilityEntriesPayload;
  /**
   * This should only be used by admin or service-to-service communication.
   * This is used to prevent creating duplicate bank account.
   * This never creates once-off bank account.
   */
  getOrCreateWalletOwnerAndBankAccount?: Maybe<BankAccount>;
  partialRefundLiabilityPayment: Array<RefundOutput>;
  payLiability?: Maybe<ResponseStatus>;
  payLiabilityFromWallet?: Maybe<ResponseStatus>;
  /**
   * Use for RENT/FEE/BILL HOP1 REDUND. Details: https://ailo.atlassian.net/wiki/spaces/TS/pages/1622245700/How+to+do+all+Reversal+Refund+like+things
   * If the payment is coming from a payment method, it will credit the money back to the payment method, so real world payment transaction will be initiated
   * If the payment is coming from a wallet, it will credit the money back to the wallet.
   */
  refundLiabilityPayment: Array<RefundOutput>;
  reverseAdjustment?: Maybe<AdjustmentLiabilityEntry>;
  /**
   * Use for RENT late revsersal, make sure the management fee has been refund first before late reversal of RENT. Details: https://ailo.atlassian.net/wiki/spaces/TS/pages/1622245700/How+to+do+all+Reversal+Refund+like+things
   * Use for BILL Hop2 late reversal, make sure the param are bill HOP2 BT id, no overdraft allowance will be created against Ailo bank wallet.
   * Use for BILL Hop1 late reversal and HOP2 VOIDed or FAILed.  make sure the param are bill HOP1 BT id, no overdraft allowance will be created against supplier wallet
   * Use for wallet withdraw late reversal, no overdraft allowance will be created.
   * No real world payment transaction will be created, purely Ailo adjustment internally.
   */
  reverseBusinessTx: BusinessTransaction;
  transferToWallet?: Maybe<ResponseStatus>;
  updateAutoWithdrawPlanV2?: Maybe<AutoWithdrawPlan>;
  updateLiabilityPaymentPlan?: Maybe<LiabilityPaymentPlan>;
  upsertAutoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  withdrawFromWallet?: Maybe<ResponseStatus>;
  writeOff?: Maybe<ResponseStatus>;
  createOneKey: Key;
  updateOneKey: Key;
  deleteOneKey: KeyDeleteResponse;
  createInspection: CreateInspectionResult;
  updateInspection: UpdateInspectionResult;
  removeInspection: RemoveInspectionResult;
  createInspectionArea: CreateInspectionAreaResult;
  updateInspectionArea: UpdateInspectionAreaResult;
  removeInspectionArea: RemoveInspectionAreaResult;
  createInspectionAreaFile: CreateInspectionAreaFileResult;
  removeInspectionAreaFile: RemoveInspectionAreaFileResult;
  createInspectionFeature: CreateInspectionFeatureResult;
  updateInspectionFeature: UpdateInspectionFeatureResult;
  removeInspectionFeature: RemoveInspectionFeatureResult;
  createInspectionFeatureFile: CreateInspectionFeatureFileResult;
  removeInspectionFeatureFile: RemoveInspectionFeatureFileResult;
  createInspectionAppointments: CreateInspectionAppointmentsResult;
  updateInspectionAppointment: UpdateInspectionAppointmentResult;
  cancelInspectionAppointment: CancelInspectionAppointmentResult;
  setRoutineInspectionDueDate: SetRoutineInspectionDueDateResult;
  generateInspectionReport: GenerateInspectionReportResult;
  createBanana?: Maybe<Banana>;
  createReiToken: ReiToken;
  updateReiToken: ReiToken;
  removeReiToken: ReiToken;
  createReiFormFromUserTemplate: ReiForm;
  createAiloForm: CreateAiloFormResult;
  updateAiloForm: UpdateAiloFormResult;
  /**
   * Creates a new file entity attached to the current user,
   * and returns it together with an AWS S3 Upload URL to which the user can upload a file.
   */
  startFileUpload: FileWithUploadUrl;
  /**
   * Validate if the file has been uploaded.
   *
   * File needs to belong to the current user,
   * or if it's a m2m call, requires "file:claim" scope.
   */
  validateFile: ValidateFileResponse;
  createOtherContact: Contact;
  findOrCreateOtherContacts: Array<Contact>;
  syncLegalEntity: Array<Contact>;
  createChatV2?: Maybe<ChatV2>;
  createThreadV2: ThreadV2;
  sendMessageV2: MessageV2;
  acknowledgeThread?: Maybe<ThreadV2>;
  unacknowledgeThreadFromMessage?: Maybe<ThreadV2>;
  createBill?: Maybe<Bill>;
  archiveBill?: Maybe<Bill>;
  addBillAttachments?: Maybe<Bill>;
  createPaymentReference?: Maybe<PaymentReference>;
  findOrCreatePaymentReference?: Maybe<PaymentReference>;
  createSupplier?: Maybe<Supplier>;
  findOrCreateInternalSupplier?: Maybe<Supplier>;
  archiveSupplier?: Maybe<Supplier>;
  updateSupplier?: Maybe<Supplier>;
  setBillLiabilityState?: Maybe<BillLiabilityState>;
  noopAutopayment?: Maybe<Scalars["Boolean"]>;
  upsertCrn?: Maybe<UpsertCrnOutput>;
  deleteCrn?: Maybe<Scalars["Boolean"]>;
  sendEmailVerificationCode?: Maybe<SendEmailVerificationResponse>;
  verifyEmail?: Maybe<VerifyEmailResponse>;
  cancelEmailVerifications?: Maybe<Scalars["Boolean"]>;
  acceptTermsOfServices?: Maybe<Person>;
  updatePersonProfileDetails?: Maybe<Person>;
  updateCompanyProfileDetails?: Maybe<Company>;
  addMemberToOrganisation?: Maybe<OrganisationMembership>;
  removeMemberFromOrganisation?: Maybe<Scalars["Boolean"]>;
  addMemberToLegalEntity?: Maybe<LegalEntityMembership>;
  removeMemberFromLegalEntity?: Maybe<Scalars["Boolean"]>;
  completeOnboardingTask?: Maybe<UserOnboardingTask>;
  sendPhoneVerificationCode?: Maybe<SendPhoneVerificationCodeResponse>;
  verifyPhoneVerificationCode?: Maybe<VerifyPhoneVerificationCodeResponse>;
  atpEmptyStarterSchemaNoop?: Maybe<Scalars["Boolean"]>;
  _empty?: Maybe<Scalars["Boolean"]>;
};

export type MutationUnlockReportPeriodArgs = {
  input: UnlockReportPeriodInput;
};

export type MutationCreateOneTrustAccountReconciliationAdjustmentArgs = {
  input: CreateOneTrustAccountReconciliationAdjustmentInput;
};

export type MutationRemoveOneTrustAccountReconciliationAdjustmentArgs = {
  input: RemoveTrustAccountReconciliationAdjustmentInput;
};

export type MutationCreateOneTrustAccountStatementBalanceArgs = {
  input: CreateOneTrustAccountStatementBalanceInput;
};

export type MutationCreateOneTrustAccountArgs = {
  input: CreateOneTrustAccountInput;
};

export type MutationUpdateOneTrustAccountArgs = {
  input: UpdateOneTrustAccountInput;
};

export type MutationCreateStatementsArgs = {
  input: CreateStatementInput;
};

export type MutationCreateStatementsWithSubjectArgs = {
  input: CreateStatementWithSubjectInput;
};

export type MutationCreateStatementsForAllManagementsArgs = {
  input: CreateStatementsForAllManagementsInput;
};

export type MutationRetryCreateStatementArgs = {
  progressAiloRN: Scalars["AiloRN"];
};

export type MutationRegisterPushTokenArgs = {
  token: Scalars["String"];
  projectId?: Maybe<Scalars["String"]>;
};

export type MutationDeregisterPushTokenArgs = {
  token: Scalars["String"];
};

export type MutationCreateTenancyArgs = {
  tenancyDetails: TenancySetupInput;
  managementId: Scalars["ID"];
  autoPublish?: Maybe<Scalars["Boolean"]>;
  doNotNotify?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateEndOfTenancyArgs = {
  tenancyId: Scalars["ID"];
  endDate?: Maybe<Scalars["Date"]>;
  reason?: Maybe<VacatingReason>;
  notes?: Maybe<Scalars["String"]>;
};

export type MutationUpdateIngoingTenancyArgs = {
  tenancyDetails: IngoingTenancyUpdateInput;
};

export type MutationReviewLeaseArgs = {
  tenancyId: Scalars["ID"];
  agreementDetails?: Maybe<LeaseRenewalDetails>;
  allowCurrentTenancyAgreementToLapse?: Maybe<Scalars["Boolean"]>;
};

export type MutationVoidTenancyArgs = {
  tenancyId: Scalars["ID"];
};

export type MutationAlterTenancyStartDateArgs = {
  tenancyId: Scalars["ID"];
  startDate: Scalars["String"];
};

export type MutationCreateRentsArgs = {
  input: CreateRentsInput;
};

export type MutationDeleteRentArgs = {
  input: DeleteRentInput;
};

export type MutationCreateRecurringFeeBlueprintArgs = {
  input: RecurringFeeBlueprintInput;
};

export type MutationCreateFeeBlueprintArgs = {
  input: CreateFeeBlueprintInput;
};

export type MutationFindOrCreateFeeBlueprintArgs = {
  input: FindOrCreateBlueprintInput;
};

export type MutationUpdateRecurringFeeBlueprintArgs = {
  input: UpdateRecurringFeeBlueprintInput;
};

export type MutationUpdateFeeBlueprintArgs = {
  input: UpdateFeeBlueprintInput;
};

export type MutationClearManagementEndArgs = {
  managementId: Scalars["ID"];
};

export type MutationUpdateManagementAgreementArgs = {
  input: UpdateManagementAgreementInput;
};

export type MutationUpdateManagementFeeArgs = {
  input: UpdateManagementFeeInput;
};

export type MutationCreateFeeArgs = {
  input: CreateFeeInput;
};

export type MutationUpdateFeeArgs = {
  input: UpdateFeeInput;
};

export type MutationCreateRecurringFeeArgs = {
  input: RecurringFeeInput;
};

export type MutationCreateRecurringFeesArgs = {
  input: Array<RecurringFeeInput>;
};

export type MutationCancelRecurringFeeArgs = {
  input: CancelRecurringFeeInput;
};

export type MutationUpdateRecurringFeeArgs = {
  input: UpdateRecurringFeeInput;
};

export type MutationSetupManagementAndTenancyArgs = {
  managementAndTenancyDetails: ManagementAndTenancyInput;
  autoPublish?: Maybe<Scalars["Boolean"]>;
  doNotNotify?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreatePropertyArgs = {
  propertyDetails: PropertyInput;
};

export type MutationUpdatePropertyArgs = {
  updatePropertyInput: UpdatePropertyInput;
};

export type MutationCreateRentCreditArgs = {
  input: RentCreditDetailsInput;
};

export type MutationCreateTenancyAgreementArgs = {
  tenancyId: Scalars["ID"];
  agreementDetails: TenancyAgreementDetails;
};

export type MutationUpdateTenancyAgreementArgs = {
  input: UpdateTenancyAgreementInput;
};

export type MutationReviewRentArgs = {
  tenancyId: Scalars["ID"];
  rentDetails: RentReviewDetails;
};

export type MutationCreateRentReviewArgs = {
  tenancyId: Scalars["ID"];
  rentDetails: RentReviewDetails;
};

export type MutationEditRentReviewArgs = {
  input: EditRentReviewInput;
};

export type MutationAttachFilesToTenancyArgs = {
  tenancyId: Scalars["ID"];
  fileIds: Array<Scalars["AiloRN"]>;
};

export type MutationAttachFilesToManagementArgs = {
  managementId: Scalars["ID"];
  fileIds: Array<Scalars["AiloRN"]>;
  fileOwnerAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type MutationDeleteTenancyFileArgs = {
  input: DeleteTenancyFileInput;
};

export type MutationDeleteManagementFileArgs = {
  input: DeleteManagementFileInput;
};

export type MutationFindOrCreateManagementFeeBlueprintArgs = {
  input: FindOrCreateManagementFeeBlueprintInput;
};

export type MutationCreateManagementFeeBlueprintArgs = {
  input: CreateManagementFeeBlueprintInput;
};

export type MutationUpdateManagementFeeBlueprintArgs = {
  input: UpdateManagementFeeBlueprintInput;
};

export type MutationCreateOrUpdateManagementNoteArgs = {
  input: CreateOrUpdateManagementNoteInput;
};

export type MutationEndManagementV2Args = {
  input: EndManagementV2Input;
};

export type MutationLockTrustAccountArgs = {
  input: LockTrustAccountInput;
};

export type MutationPayFromWalletArgs = {
  payFromWalletInput: PayFromWalletInput;
};

export type MutationReceiptFundsArgs = {
  receiptFundsInput: ReceiptFundsInput;
};

export type MutationWalletTransferArgs = {
  walletTransferInput: WalletTransferInput;
};

export type MutationCreateRecurringOwingArgs = {
  owing: RecurringOwingInput;
};

export type MutationUpdateRecurringOwingArgs = {
  owing: RecurringOwingCalculationInput;
  owingId: Scalars["ID"];
  reason: Scalars["String"];
};

export type MutationRevokeMailboxAccessArgs = {
  input: RevokeMailboxAccessInput;
};

export type MutationSendMailArgs = {
  input: SendMailInput;
};

export type MutationAddBPayArgs = {
  details: BPayInput;
  owner: Scalars["AiloRN"];
};

export type MutationAddBankAccountArgs = {
  details: BankAccountInput;
  owner: Scalars["AiloRN"];
};

export type MutationAddCreditCardArgs = {
  details: CreditCardInput;
  owner: Scalars["AiloRN"];
};

export type MutationAdjustLiabilityEntryArgs = {
  adjustmentType: Scalars["String"];
  amount: MoneyInput;
  description: Scalars["String"];
  descriptionIsCustom?: Maybe<Scalars["Boolean"]>;
  effectiveAt: Scalars["DateTime"];
  idempotencyKey: Scalars["String"];
  liabilityId: Scalars["ID"];
};

export type MutationArchiveLiabilityArgs = {
  input: ArchiveLiabilityInput;
};

export type MutationCancelAutoPayArgs = {
  cancelAutoPayInput: CancelAutoPayInput;
};

export type MutationCancelAutoPayLiabilityStatusArgs = {
  cancelAutoPayLiabilityStatusInput: CancelAutoPayLiabilityStatusInput;
};

export type MutationCancelAutoWithdrawPlanArgs = {
  cancelAutoWithdrawInput: CancelAutoWithdrawPlanInput;
};

export type MutationCancelLiabilityPaymentPlanArgs = {
  cancelLiabilityPaymentPlanInput: CancelLiabilityPaymentPlanInput;
};

export type MutationCleanOrphanedBusinessTransactionArgs = {
  businessTransactionId: Scalars["ID"];
};

export type MutationCleanUnclearedBusinessTransactionArgs = {
  businessTransactionId: Scalars["ID"];
  description: Scalars["String"];
};

export type MutationCreateAdjustmentForPayerArgs = {
  businessTxId: Scalars["ID"];
  payerReference: Scalars["AiloRN"];
};

export type MutationCreateAutoWithdrawPlanV2Args = {
  createAutoWithdrawPlanInput: CreateAutoWithdrawPlanInputV2;
};

export type MutationCreateBankAccountBlacklistArgs = {
  blackListItem: BankAccountBlacklistInput;
};

export type MutationCreateLiabilityPaymentPlanArgs = {
  createLiabilityPaymentPlanInput: CreateLiabilityPaymentPlanInput;
};

export type MutationCreateOrUpdatePaymentMethodAiloFeeWaiverArgs = {
  feeWaiverInput: PaymentMethodAiloFeeWaiverInput;
};

export type MutationCreateOverdraftAllowanceArgs = {
  input: OverdraftAllowanceInput;
};

export type MutationCreatePaymentMethodArgs = {
  input?: Maybe<CreatePaymentMethodInput>;
};

export type MutationCreateUpdateAutoPayArgs = {
  createUpdateAutoPayInput: CreateUpdateAutoPayInput;
};

export type MutationCreditFromAiloBankArgs = {
  walletCreditInput: AdjustWalletBalanceInput;
};

export type MutationDeleteBankAccountBlacklistArgs = {
  id: Scalars["ID"];
};

export type MutationDeletePaymentMethodArgs = {
  disableAutoPlans?: Maybe<Scalars["Boolean"]>;
  paymentMethodId: Scalars["ID"];
};

export type MutationEmitLiabilityStateChangedEventArgs = {
  liabilityId: Scalars["ID"];
};

export type MutationGenerateLiabilityEntriesArgs = {
  input: GenerateLiabilityEntriesInput;
};

export type MutationGetOrCreateWalletOwnerAndBankAccountArgs = {
  input: GetOrCreateWalletOwnerAndBankAccountInput;
};

export type MutationPartialRefundLiabilityPaymentArgs = {
  amount: MoneyInput;
  businessTxId: Scalars["AiloRN"];
  cascade?: Maybe<Scalars["Boolean"]>;
  description: Scalars["String"];
  destinationWalletId?: Maybe<Scalars["String"]>;
};

export type MutationPayLiabilityArgs = {
  amount: MoneyInput;
  idempotentKey: Scalars["GeneratedKey"];
  liabilityId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

export type MutationPayLiabilityFromWalletArgs = {
  amount: MoneyInput;
  idempotentKey: Scalars["GeneratedKey"];
  liability: Scalars["AiloRN"];
  wallet: Scalars["AiloRN"];
};

export type MutationRefundLiabilityPaymentArgs = {
  businessTxId: Scalars["AiloRN"];
  cascade?: Maybe<Scalars["Boolean"]>;
  description: Scalars["String"];
};

export type MutationReverseAdjustmentArgs = {
  input: ReverseAdjustmentInput;
};

export type MutationReverseBusinessTxArgs = {
  businessTxId: Scalars["ID"];
};

export type MutationTransferToWalletArgs = {
  transferToWalletInput: TransferToWalletInput;
};

export type MutationUpdateAutoWithdrawPlanV2Args = {
  updateAutoWithdrawInput: UpdateAutoWithdrawPlanInputV2;
};

export type MutationUpdateLiabilityPaymentPlanArgs = {
  updateLiabilityPaymentPlanInput: UpdateLiabilityPaymentPlanInput;
};

export type MutationUpsertAutoPayLiabilityStatusArgs = {
  upsertAutoPayLiabilityStatusInput: UpsertAutoPayLiabilityStatusInput;
};

export type MutationWithdrawFromWalletArgs = {
  withdrawInput: WithdrawInput;
};

export type MutationWriteOffArgs = {
  walletCreditInput: AdjustWalletBalanceInput;
};

export type MutationCreateOneKeyArgs = {
  input: CreateOneKeyInput;
};

export type MutationUpdateOneKeyArgs = {
  input: UpdateOneKeyInput;
};

export type MutationDeleteOneKeyArgs = {
  input: DeleteOneKeyInput;
};

export type MutationCreateInspectionArgs = {
  createInspectionInput: CreateInspectionInput;
};

export type MutationUpdateInspectionArgs = {
  updateInspectionInput: UpdateInspectionInput;
};

export type MutationRemoveInspectionArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionAreaArgs = {
  createInspectionAreaInput: CreateInspectionAreaInput;
};

export type MutationUpdateInspectionAreaArgs = {
  updateInspectionAreaInput: UpdateInspectionAreaInput;
};

export type MutationRemoveInspectionAreaArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionAreaFileArgs = {
  createInspectionAreaFileInput: CreateInspectionAreaFileInput;
};

export type MutationRemoveInspectionAreaFileArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionFeatureArgs = {
  createInspectionFeatureInput: CreateInspectionFeatureInput;
};

export type MutationUpdateInspectionFeatureArgs = {
  updateInspectionFeatureInput: UpdateInspectionFeatureInput;
};

export type MutationRemoveInspectionFeatureArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionFeatureFileArgs = {
  createInspectionFeatureFileInput: CreateInspectionFeatureFileInput;
};

export type MutationRemoveInspectionFeatureFileArgs = {
  id: Scalars["ID"];
};

export type MutationCreateInspectionAppointmentsArgs = {
  input: Array<CreateInspectionAppointmentInput>;
  agencyAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type MutationUpdateInspectionAppointmentArgs = {
  input: UpdateInspectionAppointmentInput;
};

export type MutationCancelInspectionAppointmentArgs = {
  id: Scalars["ID"];
};

export type MutationSetRoutineInspectionDueDateArgs = {
  input: SetRoutineInspectionDueDateInput;
};

export type MutationGenerateInspectionReportArgs = {
  generateInspectionReportInput: GenerateInspectionReportInput;
};

export type MutationCreateBananaArgs = {
  input: CreateBananaInput;
};

export type MutationCreateReiTokenArgs = {
  createReiTokenInput: CreateReiTokenInput;
};

export type MutationUpdateReiTokenArgs = {
  updateReiTokenInput: UpdateReiTokenInput;
};

export type MutationRemoveReiTokenArgs = {
  removeReiTokenInput: RemoveReiTokenInput;
};

export type MutationCreateReiFormFromUserTemplateArgs = {
  createReiFormFromUserTemplateInput: CreateReiFormFromUserTemplateInput;
};

export type MutationCreateAiloFormArgs = {
  createAiloFormInput: CreateAiloFormInput;
};

export type MutationUpdateAiloFormArgs = {
  updateAiloFormInput: UpdateAiloFormInput;
};

export type MutationStartFileUploadArgs = {
  fileName: Scalars["String"];
  fileKind?: Maybe<FileKind>;
  ownerId?: Maybe<Scalars["AiloRN"]>;
};

export type MutationValidateFileArgs = {
  fileId: Scalars["AiloRN"];
  fileKind?: Maybe<FileKind>;
};

export type MutationCreateOtherContactArgs = {
  input: CreateOtherContactInput;
};

export type MutationFindOrCreateOtherContactsArgs = {
  input: FindOrCreateOtherContactsInput;
};

export type MutationSyncLegalEntityArgs = {
  input: SyncLegalEntityInput;
};

export type MutationCreateChatV2Args = {
  input: CreateChatInputV2;
};

export type MutationCreateThreadV2Args = {
  input: CreateThreadInputV2;
};

export type MutationSendMessageV2Args = {
  input: SendMessageInputV2;
};

export type MutationAcknowledgeThreadArgs = {
  input: AcknowledgeThreadInput;
};

export type MutationUnacknowledgeThreadFromMessageArgs = {
  input: UnacknowledgeThreadFromMessageInput;
};

export type MutationCreateBillArgs = {
  billDetails: BillInput;
};

export type MutationArchiveBillArgs = {
  billId: Scalars["ID"];
  reason?: Maybe<Scalars["String"]>;
};

export type MutationAddBillAttachmentsArgs = {
  billId: Scalars["AiloRN"];
  attachmentsFileIds: Array<Scalars["AiloRN"]>;
  payersLegalEntitiesIds?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type MutationCreatePaymentReferenceArgs = {
  paymentReferenceDetails: PaymentReferenceInput;
};

export type MutationFindOrCreatePaymentReferenceArgs = {
  paymentReferenceDetails: PaymentReferenceInput;
};

export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};

export type MutationFindOrCreateInternalSupplierArgs = {
  supplierDetails: InternalSupplierInput;
};

export type MutationArchiveSupplierArgs = {
  supplierId: Scalars["ID"];
};

export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};

export type MutationSetBillLiabilityStateArgs = {
  billPaymentDetails: BillLiabilityStateInput;
};

export type MutationUpsertCrnArgs = {
  input: UpsertCrnInput;
};

export type MutationDeleteCrnArgs = {
  input: DeleteCrnInput;
};

export type MutationSendEmailVerificationCodeArgs = {
  emailAddress: Scalars["String"];
};

export type MutationVerifyEmailArgs = {
  emailAddress: Scalars["String"];
  verificationCode: Scalars["String"];
};

export type MutationUpdatePersonProfileDetailsArgs = {
  input: UpdatePersonProfileDetailsInput;
};

export type MutationUpdateCompanyProfileDetailsArgs = {
  input: UpdateCompanyProfileDetailsInput;
};

export type MutationAddMemberToOrganisationArgs = {
  input: AddMemberToOrganisationInput;
};

export type MutationRemoveMemberFromOrganisationArgs = {
  input: RemoveMemberFromOrganisationInput;
};

export type MutationAddMemberToLegalEntityArgs = {
  input: AddMemberToLegalEntityInput;
};

export type MutationRemoveMemberFromLegalEntityArgs = {
  input: RemoveMemberFromLegalEntityInput;
};

export type MutationCompleteOnboardingTaskArgs = {
  onboardingTaskId: OnboardingTaskId;
};

export type MutationSendPhoneVerificationCodeArgs = {
  phoneNumber: Scalars["String"];
};

export type MutationVerifyPhoneVerificationCodeArgs = {
  phoneNumber: Scalars["String"];
  code: Scalars["String"];
};

export type AbrCompanyLookupResult = {
  __typename?: "AbrCompanyLookupResult";
  registeredEntityName: Scalars["String"];
  addressPostcode: Scalars["String"];
  addressState: Scalars["String"];
  abn: Scalars["String"];
  acn?: Maybe<Scalars["String"]>;
};

export type Account = {
  __typename?: "Account";
  accountOwner: AccountOwner;
  balance: Money;
  bill?: Maybe<Bill>;
  book: Book;
  id: Scalars["ID"];
  reference: Scalars["AiloRN"];
  subtype: AccountSubtype;
};

export type AccountOwner = {
  __typename?: "AccountOwner";
  availableBalance: Money;
  managingEntity?: Maybe<LegalEntityCompanion>;
  reference: Scalars["AiloRN"];
  totalBalance: Money;
};

export type AccountOwnerAvailableBalanceArgs = {
  book?: Book;
};

export type AccountOwnerTotalBalanceArgs = {
  book?: Book;
};

export enum AccountSortField {
  Book = "BOOK",
  Reference = "REFERENCE",
  Subtype = "SUBTYPE",
  Type = "TYPE"
}

export enum AccountSubtype {
  CashCleared = "CASH_CLEARED",
  CashClearing = "CASH_CLEARING",
  Contribution = "CONTRIBUTION",
  Disbursement = "DISBURSEMENT",
  Expense = "EXPENSE",
  Income = "INCOME",
  Payable = "PAYABLE",
  PayableCleared = "PAYABLE_CLEARED",
  PayableClearing = "PAYABLE_CLEARING",
  Receivable = "RECEIVABLE"
}

export type AccountTag = {
  __typename?: "AccountTag";
  accountOwnerReference: Scalars["AiloRN"];
  accountSubtype: AccountSubtype;
  book: Book;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  value: Scalars["String"];
};

export type AccountTagBalance = {
  __typename?: "AccountTagBalance";
  accountTag: AccountTag;
  balance?: Maybe<Money>;
  balanceCreatedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  isLatestEntry: Scalars["Boolean"];
  snowflake: Scalars["Long"];
};

export type AccountTagBalanceIsLatestEntryArgs = {
  asOf?: Maybe<Scalars["DateTime"]>;
};

export enum AccountType {
  Asset = "ASSET",
  Equity = "EQUITY",
  Liability = "LIABILITY"
}

export type AcknowledgeThreadInput = {
  threadAilorn: Scalars["AiloRN"];
  lastAcknowledgedAt: Scalars["DateTime"];
  lastAcknowledgedMessageAilorn: Scalars["AiloRN"];
};

export type ActionInitiator = User | System;

export type AddMemberToLegalEntityInput = {
  memberPersonId: Scalars["String"];
  legalEntityId: Scalars["String"];
  roleId: Scalars["String"];
};

export type AddMemberToOrganisationInput = {
  personId: Scalars["String"];
  organisationId: Scalars["String"];
  roleId: Scalars["String"];
};

export type Address = {
  __typename?: "Address";
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  postcode?: Maybe<Scalars["String"]>;
};

export type AdjustmentLiabilityEntry = LiabilityEntry & {
  __typename?: "AdjustmentLiabilityEntry";
  amount: Money;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  description?: Maybe<Scalars["String"]>;
  descriptionIsCustom?: Maybe<Scalars["Boolean"]>;
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  reversedByAdjustment?: Maybe<AdjustmentLiabilityEntry>;
  reversesAdjustment?: Maybe<AdjustmentLiabilityEntry>;
  sourceId?: Maybe<Scalars["ID"]>;
  sourceType?: Maybe<SourceType>;
  /** @deprecated Irrelevant to adjustment liability entry */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type AdjustWalletBalanceInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  userFacingDescription?: Maybe<Scalars["String"]>;
  walletId: Scalars["ID"];
};

export type AgencyPropertiesQueryInput = {
  paginationParams: PaginationParams;
};

export type AgencyProperty = {
  __typename?: "AgencyProperty";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  property: Property;
  agencyOrganisation: Organisation;
  /**
   * returns the first to exist of active, next or previous management
   * in that order.
   */
  mostRelevantManagement?: Maybe<Management>;
  managements: Array<Management>;
  address: Address;
};

export type AiloForm = Form & {
  __typename?: "AiloForm";
  id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  organisation: Organisation;
  name: Scalars["String"];
  template: AiloFormTemplate;
  /** Number of answers associated with this page */
  respondedFields: Scalars["Int"];
  /** Number of fields associated with this page (Excludes the 'EMPTY' field type) */
  totalFields: Scalars["Int"];
  pages: Array<AiloFormPage>;
  ailorn: Scalars["AiloRN"];
};

export type AiloFormField = {
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
};

export type AiloFormFieldDate = AiloFormField & {
  __typename?: "AiloFormFieldDate";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  placeholder?: Maybe<Scalars["String"]>;
  dateResponse?: Maybe<AiloFormResponseDate>;
};

export type AiloFormFieldEmpty = AiloFormField & {
  __typename?: "AiloFormFieldEmpty";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
};

export type AiloFormFieldInfo = {
  __typename?: "AiloFormFieldInfo";
  description: Scalars["Markdown"];
  icon?: Maybe<Scalars["Url"]>;
};

export type AiloFormFieldNumber = AiloFormField & {
  __typename?: "AiloFormFieldNumber";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  unit?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
  numberResponse?: Maybe<AiloFormResponseNumber>;
};

export type AiloFormFieldText = AiloFormField & {
  __typename?: "AiloFormFieldText";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  placeholder?: Maybe<Scalars["String"]>;
  textResponse?: Maybe<AiloFormResponseText>;
};

export type AiloFormFieldYesNo = AiloFormField & {
  __typename?: "AiloFormFieldYesNo";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  response?: Maybe<AiloFormResponse>;
  children: Array<AiloFormField>;
  includeNA: Scalars["Boolean"];
  yesNoResponse?: Maybe<AiloFormResponseYesNo>;
};

export type AiloFormPage = {
  __typename?: "AiloFormPage";
  id: Scalars["ID"];
  index: Scalars["Int"];
  icon?: Maybe<Scalars["Url"]>;
  name: Scalars["String"];
  parentTemplate: AiloFormTemplate;
  parentForm: AiloForm;
  /** List of all fields flattened including children fields */
  allFields: Array<AiloFormField>;
  /** List of all top level fields, query children to get next depth of fields */
  topLevelFields: Array<AiloFormField>;
};

export type AiloFormResponse = {
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
};

export type AiloFormResponseDate = AiloFormResponse & {
  __typename?: "AiloFormResponseDate";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  date: Scalars["DateTime"];
};

export type AiloFormResponseNumber = AiloFormResponse & {
  __typename?: "AiloFormResponseNumber";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  number: Scalars["Float"];
};

export type AiloFormResponsePayloadInput = {
  DATE?: Maybe<Scalars["DateTime"]>;
  NUMBER?: Maybe<Scalars["Float"]>;
  TEXT?: Maybe<Scalars["String"]>;
  YES_NO?: Maybe<Scalars["String"]>;
};

export type AiloFormResponseText = AiloFormResponse & {
  __typename?: "AiloFormResponseText";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  text: Scalars["String"];
};

export type AiloFormResponseYesNo = AiloFormResponse & {
  __typename?: "AiloFormResponseYesNo";
  id: Scalars["ID"];
  parentForm: AiloForm;
  formattedResponse: Scalars["String"];
  field: AiloFormField;
  yesNoNa: AiloFormYesNoResponse;
};

export type AiloFormTemplate = {
  __typename?: "AiloFormTemplate";
  id: Scalars["ID"];
  type: AiloFormType;
  supportedState: AustralianState;
  version: Scalars["Int"];
  name: Scalars["String"];
  pages: Array<AiloFormTemplatePage>;
};

export type AiloFormTemplateField = {
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
};

export type AiloFormTemplateFieldDate = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldDate";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  placeholder?: Maybe<Scalars["String"]>;
};

export type AiloFormTemplateFieldEmpty = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldEmpty";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
};

export type AiloFormTemplateFieldNumber = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldNumber";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  unit?: Maybe<Scalars["String"]>;
  placeholder?: Maybe<Scalars["String"]>;
};

export type AiloFormTemplateFieldText = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldText";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  placeholder?: Maybe<Scalars["String"]>;
};

export type AiloFormTemplateFieldYesNo = AiloFormTemplateField & {
  __typename?: "AiloFormTemplateFieldYesNo";
  id: Scalars["ID"];
  index: Scalars["Int"];
  bullet?: Maybe<Scalars["String"]>;
  children: Array<AiloFormTemplateField>;
  description: Scalars["Markdown"];
  aboveInfo?: Maybe<AiloFormFieldInfo>;
  belowInfo?: Maybe<AiloFormFieldInfo>;
  parentPage: AiloFormTemplatePage;
  includeNA: Scalars["Boolean"];
};

export type AiloFormTemplatePage = {
  __typename?: "AiloFormTemplatePage";
  id: Scalars["ID"];
  index: Scalars["Int"];
  icon?: Maybe<Scalars["Url"]>;
  name: Scalars["String"];
  parentTemplate: AiloFormTemplate;
  /** List of all fields flattened including children fields */
  allFields: Array<AiloFormTemplateField>;
  /** List of all top level fields, query children to get next depth of fields */
  topLevelFields: Array<AiloFormTemplateField>;
};

export enum AiloFormType {
  IngoingInspection = "INGOING_INSPECTION"
}

export enum AiloFormYesNoResponse {
  Yes = "YES",
  No = "NO",
  Na = "NA"
}

export type AiloRnFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["AiloRN"]>;
  neq?: Maybe<Scalars["AiloRN"]>;
  gt?: Maybe<Scalars["AiloRN"]>;
  gte?: Maybe<Scalars["AiloRN"]>;
  lt?: Maybe<Scalars["AiloRN"]>;
  lte?: Maybe<Scalars["AiloRN"]>;
  like?: Maybe<Scalars["AiloRN"]>;
  notLike?: Maybe<Scalars["AiloRN"]>;
  iLike?: Maybe<Scalars["AiloRN"]>;
  notILike?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export enum AllowedOperations {
  None = "None",
  Create = "Create",
  Edit = "Edit"
}

export type AllTenanciesQueryFilterInput = {
  includeOffboarded?: Maybe<Scalars["Boolean"]>;
  includeVoided?: Maybe<Scalars["Boolean"]>;
  includeDrafts?: Maybe<Scalars["Boolean"]>;
};

export type AllTenanciesQueryInput = {
  paginationParams: PaginationParams;
  filters?: Maybe<AllTenanciesQueryFilterInput>;
};

export type AmountDueToDateInput = {
  dateTime?: Maybe<Scalars["DateTime"]>;
  liabilityId: Scalars["ID"];
};

export type ArchiveLiabilityInput = {
  archivedAt?: Maybe<Scalars["DateTime"]>;
  liabilityId: Scalars["ID"];
};

export type Arrears = {
  __typename?: "Arrears";
  /** @deprecated Use `amountInArrears` instead */
  amount: TaxableAmount;
  /** Amount in arrears, value can be negative when overpaid  */
  amountInArrears: TaxableAmount;
  calculatedFromDate?: Maybe<Scalars["Date"]>;
  calculatedToDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `calculatedFromDate` instead */
  date: Scalars["Date"];
  /** Number of days in arrears, value can be negative when overpaid  */
  daysInArrears: Scalars["Int"];
};

export enum AustralianState {
  Nsw = "NSW",
  Wa = "WA",
  Sa = "SA",
  Nt = "NT",
  Tas = "TAS",
  Act = "ACT",
  Qld = "QLD",
  Vic = "VIC"
}

export type AutoPayLiabilityStatus = {
  __typename?: "AutoPayLiabilityStatus";
  enabled: Scalars["Boolean"];
  maximumPaymentAmount?: Maybe<Money>;
  /**
   * If null, it means it'll be paid using Ailo Wallet.
   * TODO: Return `WalletPaymentMethod` instead.
   */
  payerId: Scalars["AiloRN"];
  paymentMethod?: Maybe<PaymentMethod>;
  /** @deprecated Use `AutoPayLiabilityStatus.paymentMethod.id` instead */
  paymentMethodId?: Maybe<Scalars["ID"]>;
};

export type AutoPaymentDetails = {
  __typename?: "AutoPaymentDetails";
  paymentMethod?: Maybe<PaymentMethod>;
};

export type AutoWithdrawPlan = {
  __typename?: "AutoWithdrawPlan";
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  details: AutoWithdrawPlanDetails;
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  owner: Scalars["AiloRN"];
  wallet: Wallet;
  walletId: Scalars["ID"];
};

export type AutoWithdrawPlanDetails = {
  __typename?: "AutoWithdrawPlanDetails";
  /** If `frequency` = Monthly, use `anniversaryDaysOfMonth` instead. */
  anniversary?: Maybe<Scalars["Int"]>;
  /**
   * Only used when `frequency` = Monthly. Allowed values: 1-28.
   * e.g. ['1', '15'] means a withdrawal occurs on the 1st and 15th of every month.
   */
  anniversaryDaysOfMonth?: Maybe<Array<Scalars["Int"]>>;
  autoWithdrawPlanId: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  createdByV2?: Maybe<ActionInitiator>;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  id: Scalars["ID"];
  /**
   * Only used when `frequency` = Monthly.
   * If `true`, withdrawal will happen on the last day of the month.
   * e.g. 30th June, 31st July, 28th Feb or 29th Feb depending on leap year.
   */
  isLastDayOfTheMonth?: Maybe<Scalars["Boolean"]>;
  nextFireTime: Scalars["DateTime"];
  /** @deprecated Use `paymentMethodDestinations` instead */
  paymentMethod: PaymentMethod;
  paymentMethodDestinations: Array<PaymentMethodDestination>;
  /** @deprecated Use `paymentMethodDestinations` instead */
  paymentMethodId: Scalars["ID"];
  setAsideAmount?: Maybe<Money>;
  startDate: Scalars["Date"];
  timezoneId: Scalars["String"];
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type Banana = {
  __typename?: "Banana";
  id: Scalars["ID"];
  weight: Weight;
};

export type BankAccount = PaymentMethod & {
  __typename?: "BankAccount";
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  ailoRN: Scalars["AiloRN"];
  bsb?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  topUpFee?: Maybe<TransactionFee>;
  wallet: Wallet;
};

export type BankAccountBlacklist = {
  __typename?: "BankAccountBlacklist";
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  ownerLegalEntity: Scalars["AiloRN"];
};

export type BankAccountBlacklistInput = {
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  description: Scalars["String"];
  ownerLegalEntity: Scalars["AiloRN"];
};

export type BankAccountInput = {
  accountName: Scalars["String"];
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  hidden?: Maybe<Scalars["Boolean"]>;
  onceOff?: Maybe<Scalars["Boolean"]>;
};

export type BankReconciliationReport = {
  __typename?: "BankReconciliationReport";
  items?: Maybe<Array<Maybe<ReconciliationReportItem>>>;
};

export type BelongToTeamsArgInput = {
  teamIds?: Array<Scalars["AiloRN"]>;
  includeUnassigned?: Scalars["Boolean"];
};

/** @deprecated Use `PaginationParams` instead when possible. */
export type BidirectionalPageCursor = {
  /** If true, will return items before the cursor, rather than after it. */
  before?: Maybe<Scalars["Boolean"]>;
  cursor?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type BidirectionalPageInfo = {
  __typename?: "BidirectionalPageInfo";
  total: Scalars["Int"];
  hasNext: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
  hasPrevious: Scalars["Boolean"];
  previousCursor?: Maybe<Scalars["String"]>;
};

export type BidirectionalPaginatedTenancies = {
  __typename?: "BidirectionalPaginatedTenancies";
  pageInfo: BidirectionalPageInfo;
  items: Array<Tenancy>;
};

export type Bill = {
  __typename?: "Bill";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  /** Never null (nullable only because of federation reasons). */
  organisation?: Maybe<Organisation>;
  paymentReference?: Maybe<PaymentReference>;
  /** @deprecated Use `Bill.payer.ailoRN` instead. */
  toBePaidBy: Scalars["AiloRN"];
  /** Never null (nullable only because of federation reasons). */
  payer?: Maybe<BillPayer>;
  /**
   * Never null (nullable only because of federation reasons).
   * @deprecated Use `Bill.payee` instead.
   */
  supplier?: Maybe<Supplier>;
  /** Never null (nullable only because of federation reasons). */
  payee?: Maybe<BillPayee>;
  description?: Maybe<Scalars["String"]>;
  /** @deprecated Use `Bill.dueDateV2` instead */
  dueDate: Scalars["Date"];
  dueDateV2: Scalars["LocalDate"];
  /** @deprecated Use `Bill.issueDateV2` instead */
  issueDate: Scalars["Date"];
  issueDateV2: Scalars["LocalDate"];
  timeZone: Scalars["TimeZone"];
  liabilityState?: Maybe<BillLiabilityState>;
  invoiceNumber?: Maybe<Scalars["String"]>;
  status: BillStatus;
  agencyStatus: BillAgencyStatus;
  taxCategory: TaxCategory;
  /**
   * When bill was created, was the tax automatically calculated by the UI?
   * (If null, it means it was created before the automatic tax calculation was available.)
   */
  taxAutoCalculated?: Maybe<Scalars["Boolean"]>;
  amount: Money;
  lineItems?: Maybe<PaginatedLineItems>;
  relatingToManagement: Scalars["AiloRN"];
  relatedToTeam?: Maybe<Scalars["AiloRN"]>;
  createdAt: Scalars["DateTime"];
  /** @deprecated Use `Bill.createdByV2.ailoRN` instead. */
  createdBy: Scalars["AiloRN"];
  /** If null it means the bill has been created by Ailo system. */
  createdByUser?: Maybe<User>;
  modifiedBy: Scalars["AiloRN"];
  liability?: Maybe<Liability>;
  organisationArchiveReason?: Maybe<Scalars["String"]>;
  /**
   * It represents the agency legal entity.
   * Never nullable (nullable only because of federation error policy).
   */
  managingEntityAilorn?: Maybe<Scalars["AiloRN"]>;
  /** It is only used to populate bill liability payment decription when debiting investor bank account, not consumer facing */
  propertyAddress?: Maybe<Scalars["String"]>;
  applyManagementFee: Scalars["Boolean"];
  /** If true the funds will not be automatically withdrawn by the supplier */
  internal: Scalars["Boolean"];
  attachments: Array<BillAttachment>;
};

export enum BillAgencyStatus {
  Cancelled = "Cancelled",
  Refunded = "Refunded",
  RefundFailed = "RefundFailed",
  AwaitingPayment = "AwaitingPayment",
  Overdue = "Overdue",
  Due = "Due",
  PaymentSent = "PaymentSent",
  Paid = "Paid"
}

export type BillAttachment = {
  __typename?: "BillAttachment";
  id: Scalars["ID"];
  /**
   * This should never be null, but because it comes from external service,
   * in a case of unexpected server error, it will be returned as null.
   * In such case, client should treat it as internal server error,
   * show an error indicator and report it to Sentry.
   */
  file?: Maybe<File>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
};

export type BillDefaultPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<Scalars["String"]>;
};

export type BillInput = {
  paymentReferenceId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  /** Tenancy / Management / Agency Legal Entity ID */
  toBePaidBy: Scalars["AiloRN"];
  /** Tenancy Tenants / Management Owners / Agency Legal Entity IDs */
  toBePaidByLegalEntities?: Maybe<Array<Scalars["AiloRN"]>>;
  /** @deprecated Use `payeeAiloRN` instead. */
  supplierId?: Maybe<Scalars["ID"]>;
  payeeAiloRN?: Maybe<Scalars["AiloRN"]>;
  /** @deprecated Use `dueDateV2` instead. */
  dueDate?: Maybe<Scalars["Date"]>;
  dueDateV2?: Maybe<Scalars["LocalDate"]>;
  invoiceNumber?: Maybe<Scalars["String"]>;
  status: BillStatus;
  taxCategoryId: Scalars["String"];
  /** @deprecated Use `issueDateV2` instead. */
  issueDate?: Maybe<Scalars["Date"]>;
  issueDateV2?: Maybe<Scalars["LocalDate"]>;
  timeZone?: Maybe<Scalars["TimeZone"]>;
  taxAutoCalculated?: Maybe<Scalars["Boolean"]>;
  lineItems: Array<LineItemInput>;
  attachmentsFileIds?: Maybe<Array<Scalars["AiloRN"]>>;
  relatingToManagement: Scalars["AiloRN"];
  relatingToOrganisation: Scalars["AiloRN"];
  managingEntityAilorn?: Maybe<Scalars["AiloRN"]>;
  propertyAddress?: Maybe<Scalars["String"]>;
  applyManagementFee?: Maybe<Scalars["Boolean"]>;
  internal?: Maybe<Scalars["Boolean"]>;
};

export type BillLiabilityState = {
  __typename?: "BillLiabilityState";
  /** @deprecated Use `BillLiabilityState.payByDateV2` instead */
  payByDate?: Maybe<Scalars["Date"]>;
  payByDateV2?: Maybe<Scalars["LocalDate"]>;
  paymentStatus?: Maybe<Scalars["String"]>;
  /** @deprecated Use `BillLiabilityState.paidAt` instead */
  paymentDate?: Maybe<Scalars["Date"]>;
  paidAt?: Maybe<Scalars["DateTime"]>;
  dueAmount?: Maybe<Money>;
};

export type BillLiabilityStateInput = {
  billId: Scalars["AiloRN"];
  /** @deprecated Use `paidAt` instead. */
  paymentDate?: Maybe<Scalars["Date"]>;
  paidAt?: Maybe<Scalars["DateTime"]>;
  paidAmount: MoneyInput;
};

export type BillPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<BillSortParams>;
};

export type BillPayee = Supplier | Management | LegalEntityCompanion;

export type BillPayer = Tenancy | Management | LegalEntityCompanion;

export enum BillPayerType {
  Tenancy = "Tenancy",
  Management = "Management",
  LegalEntity = "LegalEntity"
}

export enum BillPaymentStatus {
  Due = "DUE",
  Paid = "PAID",
  Pending = "PENDING",
  Processing = "PROCESSING",
  Failed = "FAILED"
}

export enum BillSortField {
  CreatedAt = "CreatedAt",
  DueDate = "DueDate",
  PayByDate = "PayByDate",
  PaymentDate = "PaymentDate"
}

export type BillSortParams = {
  field: BillSortField;
  direction: SortDirection;
};

export enum BillStatus {
  Void = "VOID",
  Approved = "APPROVED",
  Refunded = "REFUNDED"
}

export type BondAuthority = {
  __typename?: "BondAuthority";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  agencyLegalEntityAilorn: Scalars["AiloRN"];
  userFacingName: Scalars["String"];
};

export type BondAuthorityAggregateGroupBy = {
  __typename?: "BondAuthorityAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
};

export type BondAuthorityCountAggregate = {
  __typename?: "BondAuthorityCountAggregate";
  id?: Maybe<Scalars["Int"]>;
};

export type BondAuthorityEdge = {
  __typename?: "BondAuthorityEdge";
  /** The node containing the BondAuthority */
  node: BondAuthority;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type BondAuthorityMaxAggregate = {
  __typename?: "BondAuthorityMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export type BondAuthorityMinAggregate = {
  __typename?: "BondAuthorityMinAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export enum BondDisbursalFailureReason {
  ReceivedLessFundsThanClaimed = "ReceivedLessFundsThanClaimed",
  ReceivedMoreFundsThanClaimed = "ReceivedMoreFundsThanClaimed",
  PaymentError = "PaymentError"
}

export enum Book {
  Accrual = "ACCRUAL",
  Cash = "CASH"
}

export type BooleanFieldComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
};

export type BPay = PaymentMethod & {
  __typename?: "BPay";
  ailoRN: Scalars["AiloRN"];
  billerCode: Scalars["String"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  topUpFee?: Maybe<TransactionFee>;
  wallet: Wallet;
};

export type BPayInput = {
  billerCode: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type BusinessTransaction = {
  __typename?: "BusinessTransaction";
  ailoRN: Scalars["AiloRN"];
  amount: Money;
  children?: Maybe<Array<Maybe<BusinessTransaction>>>;
  /** Returns date & time of clearedAt or null if it is not cleared yet. */
  clearedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars["String"]>;
  /**
   * Returns date of clearedAt if the business transaction is cleared, else it returns an estimate based on created date and today's date.
   * If the status of the business transaction is FAIL or CLEANED, null is returned.
   * Currently does not support business transactions without a liability due to lack of Zone data in Business Transaction.
   */
  estimatedClearDate?: Maybe<Scalars["Date"]>;
  /** Returns date & time of clearedAt (Can be before cleared) */
  expectedClearedAt?: Maybe<Scalars["DateTime"]>;
  /** Returns date of when latest business transaction status is created if it's status is FAIL or CLEANED and null otherwise. */
  failedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  idempotentKey: Scalars["String"];
  isReversal: Scalars["Boolean"];
  parent?: Maybe<BusinessTransaction>;
  payer?: Maybe<Scalars["AiloRN"]>;
  payerLegalEntity?: Maybe<LegalEntityCompanion>;
  paymentChannel?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentReferenceId?: Maybe<Scalars["String"]>;
  status?: Maybe<BusinessTxStatusEnum>;
  type: BusinessTxType;
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type BusinessTransactionStatus = {
  __typename?: "BusinessTransactionStatus";
  status: BusinessTxStatusEnum;
};

export enum BusinessTxStatusEnum {
  Cleaned = "CLEANED",
  Fail = "FAIL",
  Pending = "PENDING",
  Success = "SUCCESS"
}

export enum BusinessTxType {
  PaymentAuto = "PAYMENT_AUTO",
  PaymentManual = "PAYMENT_MANUAL",
  Refund = "REFUND",
  Reverse = "REVERSE",
  TopUpManual = "TOP_UP_MANUAL",
  VirtualAccountDebit = "VIRTUAL_ACCOUNT_DEBIT",
  WithdrawAuto = "WITHDRAW_AUTO",
  WithdrawManual = "WITHDRAW_MANUAL"
}

export type CancelAutoPayInput = {
  liabilityId: Scalars["ID"];
};

export type CancelAutoPayLiabilityStatusInput = {
  /**
   * Legal Entity ARN (Person/Company) if it is meant to pay liabilities using their "personal" wallet,
   * or Management folio ARN, if it's meant to pay liabilities using management folio wallet.
   */
  payerId?: Maybe<Scalars["AiloRN"]>;
  /** Current user legal entity ID. Will get deprecated soon, once we are able to get user person ARN from new authz token. */
  payerLegalEntity: Scalars["AiloRN"];
};

export type CancelAutoWithdrawPlanInput = {
  autoWithdrawPlanId: Scalars["ID"];
};

export type CancelInspectionAppointmentResult = {
  __typename?: "CancelInspectionAppointmentResult";
  inspectionAppointment: InspectionAppointment;
};

export type CancelLiabilityPaymentPlanInput = {
  liabilityPaymentPlanId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type CancelRecurringFeeInput = {
  id: Scalars["AiloRN"];
  /**
   * If not provided, fee will end with the current cycle. (Future cycles will not happen.)
   * In case the fee hasn't started yet, it'll end immediately. (It's end date will be set to the date before the fee start date.)
   *
   * Optionally, pass in the date on which the fee should end.
   * Such date needs to be an end date of a fee cycle.
   */
  endDate?: Maybe<Scalars["String"]>;
};

export type CancelVacateAbility = {
  __typename?: "CancelVacateAbility";
  ableToCancel: Scalars["Boolean"];
  notAbleToCancelReason?: Maybe<NotAbleToCancelReason>;
};

export type Charge = {
  __typename?: "Charge";
  amount: TaxableAmount;
  date: Scalars["Date"];
};

export type ChargeCycle = {
  __typename?: "ChargeCycle";
  amount: Money;
  endDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  startDate: Scalars["Date"];
  taxAmount: Money;
};

export type ChargeSchedule = {
  __typename?: "ChargeSchedule";
  firstChargeDate: Scalars["Date"];
};

export type ChargeScheduleInput = {
  firstChargeDate: Scalars["Date"];
};

export type ChatV2 = {
  __typename?: "ChatV2";
  ailorn: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  participants: Array<Contact>;
  threads?: Maybe<PaginatedThreadsV2>;
  mostRecentMessage: MessageSummaryV2;
  readonly: Scalars["Boolean"];
  unreadMessagesCount: Scalars["Int"];
};

export type ChatV2ThreadsArgs = {
  pagination?: Maybe<PaginationInputV2>;
};

export type ClaimedFilesCount = {
  __typename?: "ClaimedFilesCount";
  totalFiles: Scalars["Int"];
  validFiles: Scalars["Int"];
};

export type ClearManagementEndPayload = {
  __typename?: "ClearManagementEndPayload";
  management?: Maybe<Management>;
};

export type Company = LegalEntity & {
  __typename?: "Company";
  id: Scalars["ID"];
  ailoRN: Scalars["String"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisationId: Scalars["ID"];
  organisation: Organisation;
  timezone: Scalars["TimeZone"];
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  registeredEntityId?: Maybe<Scalars["String"]>;
  registeredEntityName: Scalars["String"];
  eulaSignedBy?: Maybe<Person>;
  currentUserPermissions: Array<Scalars["String"]>;
  accountsEmail: Array<Scalars["String"]>;
  /** Legal Entity Memberships in which this legal entity is the group of which the other people are members. */
  legalEntityMembershipsAsLegalEntity: Array<LegalEntityMembership>;
  trustAccounts: CompanyTrustAccountsConnection;
  /** Statements with subject = Legal Entity Ailorn */
  statements?: Maybe<PaginatedStatements>;
  tenancies?: Maybe<PaginatedTenancies>;
  managements?: Maybe<PaginatedManagements>;
  tenancyBondVirtualAccounts: Array<TenancyBondAccount>;
  /** this can go when payment gateway splits */
  allWalletsAvailableBalance: Money;
  allWalletsTotalBalance: Money;
  kycVerifiedAccordingToLedgerService?: Maybe<Scalars["Boolean"]>;
  paymentMethods?: Maybe<PaginatedPaymentMethods>;
  wallets: Array<Wallet>;
  contact?: Maybe<Contact>;
};

export type CompanyTrustAccountsArgs = {
  paging?: Maybe<CursorPaging>;
  filter?: Maybe<TrustAccountFilter>;
  sorting?: Maybe<Array<TrustAccountSort>>;
};

export type CompanyStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type CompanyTenanciesArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type CompanyManagementsArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type CompanyPaymentMethodsArgs = {
  methodType?: Maybe<PaymentMethodType>;
};

export type CompanyContactArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type CompanyTrustAccountsConnection = {
  __typename?: "CompanyTrustAccountsConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountEdge>;
};

export type ConsumerInvite = {
  __typename?: "ConsumerInvite";
  id: Scalars["ID"];
  metadata: Scalars["String"];
};

export type ConsumerSetupInput = {
  email?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  migratingCustomer?: Maybe<Scalars["Boolean"]>;
  legalMiddleName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
};

export type Contact = {
  __typename?: "Contact";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  types: Array<ContactType>;
  /**
   * The contact's preferred name (or first name if they haven't indicated a preferred name), followed by their last name
   * (if any), in a format ready for display. Does not include middle names.
   */
  displayName: Scalars["String"];
  /** The contact's preferred name, or their first name if they haven't indicated a preferred name */
  displayFirstName: Scalars["String"];
  status: ContactStatus;
  legalEntity?: Maybe<LegalEntity>;
  legalEntityType?: Maybe<ContactLegalEntityType>;
  user?: Maybe<User>;
  primaryEmail?: Maybe<Scalars["String"]>;
  photo?: Maybe<File>;
  /** The owner of this contact, e.g. a Person contact who owns a Company contact */
  owner?: Maybe<Contact>;
  /** Contacts that are owned by this contact. i.e. Company contacts that are owned by a Person contact */
  contactsOwned: Array<Contact>;
  /**
   * This is the confidential part of the contact that is accessible only for entities that have a read permission against the
   * requested contact's organisation. Other entities would only be able to see the "non-confidential" fields.
   * Null if a non-authorised user tries to query for this
   */
  confidential?: Maybe<ContactConfidential>;
  allAddresses: Array<ContactPropertyAddress>;
  investorAddresses: Array<ManagementPropertyAddress>;
  tenantAddresses: Array<TenancyPropertyAddress>;
  mailboxAccesses: Array<MailboxAccess>;
};

export type ContactConfidential = {
  __typename?: "ContactConfidential";
  organisation?: Maybe<Organisation>;
  primaryPhone?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  onAppAt?: Maybe<Scalars["DateTime"]>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  contactRole?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  middleNames?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  modifiedAt: Scalars["DateTime"];
  modifiedBy: ActionInitiator;
};

export enum ContactLegalEntityType {
  Person = "Person",
  Company = "Company"
}

export type ContactOrEmail = {
  contactAilorn?: Maybe<Scalars["AiloRN"]>;
  email?: Maybe<Scalars["String"]>;
};

export type ContactPropertyAddress = {
  managementAilorn: Scalars["AiloRN"];
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  /** <unitStreetNumber> <streetName> */
  shortAddress: Scalars["String"];
};

export type ContactsByManagement = {
  __typename?: "ContactsByManagement";
  agencyContacts: Array<Contact>;
  investorContacts: Array<Contact>;
};

export type ContactsByTenancy = {
  __typename?: "ContactsByTenancy";
  agencyContacts: Array<Contact>;
  tenantContacts: Array<Contact>;
};

export type ContactsFilters = {
  legalEntityTypes?: Maybe<Array<ContactLegalEntityType>>;
  excludeContactAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type ContactSortParams = {
  field: Scalars["String"];
  direction: SortDirection;
};

export type ContactsQueryInput = {
  pagination: PaginationParams;
};

export enum ContactStatus {
  NotOnApp = "NotOnApp",
  OnApp = "OnApp",
  Archived = "Archived"
}

export enum ContactType {
  Investor = "Investor",
  Tenant = "Tenant",
  Supplier = "Supplier",
  Agent = "Agent",
  Other = "Other"
}

export type CreateAiloFormCreateAiloFormResponseInput = {
  payload: AiloFormResponsePayloadInput;
  fieldId: Scalars["ID"];
};

export type CreateAiloFormInput = {
  templateId: Scalars["ID"];
  organisationAilorn: Scalars["AiloRN"];
  name: Scalars["String"];
  responses?: Maybe<Array<CreateAiloFormCreateAiloFormResponseInput>>;
};

export type CreateAiloFormResult = {
  __typename?: "CreateAiloFormResult";
  form: AiloForm;
};

export type CreateAutoWithdrawPlanInputV2 = {
  anniversary?: Maybe<Scalars["Int"]>;
  /**
   * Only used when `frequency` = Monthly. Allowed values: 1-28.
   * e.g. ['1', '15'] means a withdrawal occurs on the 1st and 15th of every month.
   */
  anniversaryDaysOfMonth?: Maybe<Array<Scalars["Int"]>>;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  /**
   * Only used when `frequency` = Monthly.
   * If `true`, withdrawal will happen on the last day of the month.
   * e.g. 30th June, 31st July, 28th Feb or 29th Feb depending on leap year.
   */
  lastDayOfTheMonth?: Maybe<Scalars["Boolean"]>;
  payerLegalEntityId: Scalars["AiloRN"];
  paymentMethodDestinations: Array<PaymentMethodDestinationInput>;
  setAsideAmount?: Maybe<MoneyInput>;
  startDate: Scalars["Date"];
  userFacingDescription?: Maybe<Scalars["String"]>;
  walletId: Scalars["ID"];
};

export type CreateBananaInput = {
  bananaId: Scalars["ID"];
};

export type CreateBondInput = {
  tenancyAilorn: Scalars["AiloRN"];
  reference?: Maybe<Scalars["String"]>;
  amount: MoneyInput;
  bondAccountAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CreateChatInputV2 = {
  participants: Array<ContactOrEmail>;
  initialThread: ThreadInputV2;
  organisationAilorn: Scalars["AiloRN"];
};

export type CreateFeeBlueprintInput = {
  name: Scalars["String"];
  type: FeeBlueprintType;
  taxCategoryId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  frequency?: Maybe<FeeFrequency>;
  event?: Maybe<FeeEventType>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  organisationId: Scalars["AiloRN"];
};

export type CreateFeeInput = {
  type: FeeType;
  feeBlueprintId?: Maybe<Scalars["String"]>;
  managementFeeBlueprintId?: Maybe<Scalars["String"]>;
  managementId: Scalars["String"];
  amount: MoneyInput;
  taxAmount?: Maybe<MoneyInput>;
  percentage?: Maybe<Scalars["Float"]>;
  baseAmount?: Maybe<MoneyInput>;
  baseAmountType?: Maybe<FeeBaseAmountType>;
  taxCategoryId: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type CreateInspectionAppointmentInput = {
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn: Scalars["AiloRN"];
  type: InspectionType;
  inspectingAgentAilorn: Scalars["AiloRN"];
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type CreateInspectionAppointmentsResult = {
  __typename?: "CreateInspectionAppointmentsResult";
  inspectionAppointments: Array<InspectionAppointment>;
};

export type CreateInspectionAreaFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionAreaId: Scalars["ID"];
};

export type CreateInspectionAreaFileResult = {
  __typename?: "CreateInspectionAreaFileResult";
  inspectionAreaFile: InspectionAreaFile;
};

export type CreateInspectionAreaInput = {
  index: Scalars["Int"];
  name: Scalars["String"];
  inspected: Scalars["Boolean"];
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  inspectionId: Scalars["ID"];
  files?: Maybe<Array<CreateInspectionAreaInputFileInput>>;
  features?: Maybe<Array<CreateInspectionAreaInputFeatureInput>>;
};

export type CreateInspectionAreaInputFeatureInput = {
  name: Scalars["String"];
  index: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  files?: Maybe<Array<CreateInspectionFeatureInputFileInput>>;
};

export type CreateInspectionAreaInputFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
};

export type CreateInspectionAreaResult = {
  __typename?: "CreateInspectionAreaResult";
  inspectionArea: InspectionArea;
};

export type CreateInspectionFeatureFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionFeatureId: Scalars["ID"];
};

export type CreateInspectionFeatureFileResult = {
  __typename?: "CreateInspectionFeatureFileResult";
  inspectionFeatureFile: InspectionFeatureFile;
};

export type CreateInspectionFeatureInput = {
  name: Scalars["String"];
  index: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  inspectionAreaId: Scalars["ID"];
  files?: Maybe<Array<CreateInspectionFeatureInputFileInput>>;
};

export type CreateInspectionFeatureInputFileInput = {
  fileAilorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
};

export type CreateInspectionFeatureResult = {
  __typename?: "CreateInspectionFeatureResult";
  inspectionFeature: InspectionFeature;
};

export type CreateInspectionInput = {
  inspectionAppointmentId: Scalars["String"];
  agencyAilorn?: Maybe<Scalars["AiloRN"]>;
  type: InspectionType;
  inspectingAgentAilorn: Scalars["AiloRN"];
  startedAt: Scalars["DateTime"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  areas?: Maybe<Array<CreateInspectionInputAreaInput>>;
  ailoFormAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CreateInspectionInputAreaInput = {
  index: Scalars["Int"];
  name: Scalars["String"];
  inspected: Scalars["Boolean"];
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  files?: Maybe<Array<CreateInspectionAreaInputFileInput>>;
  features?: Maybe<Array<CreateInspectionAreaInputFeatureInput>>;
};

export type CreateInspectionResult = {
  __typename?: "CreateInspectionResult";
  inspection: Inspection;
};

export type CreateLiabilityPaymentPlanInput = {
  amount: MoneyInput;
  anniversary: Scalars["Int"];
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  liabilityId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
  paymentMethodId: Scalars["ID"];
  startDate: Scalars["Date"];
};

export type CreateManagementFeeBlueprintInput = {
  managementId: Scalars["ID"];
  feeBlueprintId: Scalars["ID"];
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  description?: Maybe<Scalars["String"]>;
};

export type CreateOneKeyInput = {
  /** The record to create */
  key: KeyInput;
};

export type CreateOneTrustAccountInput = {
  /** The record to create */
  trustAccount: CreateTrustAccountInput;
};

export type CreateOneTrustAccountReconciliationAdjustmentInput = {
  /** The record to create */
  trustAccountReconciliationAdjustment: CreateTrustAccountReconciliationAdjustmentInput;
};

export type CreateOneTrustAccountStatementBalanceInput = {
  /** The record to create */
  trustAccountStatementBalance: CreateTrustAccountStatementBalanceInput;
};

export type CreateOrUpdateManagementNoteInput = {
  id?: Maybe<Scalars["ID"]>;
  managementId: Scalars["ID"];
  note: Scalars["String"];
};

export type CreateOrUpdateManagementNotePayload = {
  __typename?: "CreateOrUpdateManagementNotePayload";
  managementNote: ManagementNote;
};

export type CreateOtherContactInput = {
  organisationAilorn: Scalars["AiloRN"];
  primaryEmail: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
};

export type CreatePaymentMethodInput = {
  bankAccountInput?: Maybe<BankAccountInput>;
  bpayInput?: Maybe<BPayInput>;
  /** If true, create a `walletOwner` if it doesn't already exist before adding the provided payment method to it */
  createWalletOwner?: Maybe<Scalars["Boolean"]>;
  creditCardInput?: Maybe<CreditCardInputV2>;
  deleteExistingPaymentMethods?: Maybe<Scalars["Boolean"]>;
  /** ID reference from external system. Not used by ledger. */
  externalId?: Maybe<Scalars["String"]>;
  /** Organisation (e.g. agency) that is allowed to manage the provided wallet owner. Must be provided when the walletOwnerAiloRN is a supplier AiloRN. */
  managingOrganisationAiloRN?: Maybe<Scalars["AiloRN"]>;
  walletOwnerAiloRN: Scalars["AiloRN"];
};

export type CreateReiFormFromUserTemplateInput = {
  /** template id of form to generate */
  reiUserTemplateId: Scalars["Int"];
  /** Form name for future reference */
  name: Scalars["String"];
  /** AiloRN of agency organisation */
  organisationAilorn: Scalars["AiloRN"];
  /** Tenancy Ailorn of the property */
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type CreateReiTokenInput = {
  /** REI Token */
  token: Scalars["String"];
  /** State the REI account was linked to */
  supportedAustralianState?: Maybe<AustralianState>;
  /** AiloRN of person the token is associated with */
  personAilorn: Scalars["AiloRN"];
  /** AiloRN of agency organisation */
  organisationAilorn: Scalars["AiloRN"];
};

export type CreateRentInput = {
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  category: RentCategory;
  setsChargeDate: Scalars["Boolean"];
};

export type CreateRentReviewPayload = {
  __typename?: "CreateRentReviewPayload";
  rent?: Maybe<Rent>;
  tenancy?: Maybe<Tenancy>;
  query: Query;
};

export type CreateRentsInput = {
  tenancyId: Scalars["ID"];
  rents: Array<CreateRentInput>;
};

export type CreateRentsPayload = {
  __typename?: "CreateRentsPayload";
  rents: Array<Rent>;
  query: Query;
};

export type CreateStatementInput = {
  statementOwnerAiloRNs: Array<Scalars["AiloRN"]>;
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  type: StatementType;
  /** Statement created for is default to manual request */
  createdFor?: Maybe<StatementCreatedFor>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
  sendEmail?: Maybe<Scalars["Boolean"]>;
  dryRunEmail?: Maybe<Scalars["String"]>;
  /** overrideStatementValues should be null unless overriding real data. To override the real data, provide a string containing a JSON of the exact statement values wanted for the statement */
  overrideStatementValues?: Maybe<Scalars["String"]>;
};

export type CreateStatementsForAllManagementsInput = {
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  sendEmail?: Maybe<Scalars["Boolean"]>;
  type: StatementType;
  dryRunEmail?: Maybe<Scalars["String"]>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
};

export type CreateStatementsResponse = {
  __typename?: "CreateStatementsResponse";
  statementProgresses: Array<StatementProgress>;
  numberOfStatements: Scalars["Int"];
};

export type CreateStatementWithSubjectInput = {
  statementSubjectAiloRNs: Array<Scalars["AiloRN"]>;
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  type: StatementType;
  /** Statement created for is default to manual request */
  createdFor?: Maybe<StatementCreatedFor>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
  sendEmail?: Maybe<Scalars["Boolean"]>;
  dryRunEmail?: Maybe<Scalars["String"]>;
  /** overrideStatementValues should be null unless overriding real data. To override the real data, provide a string containing a JSON of the exact statement values wanted for the statement */
  overrideStatementValues?: Maybe<Scalars["String"]>;
};

export type CreateSupplierInput = {
  organisationAiloRN: Scalars["AiloRN"];
  abn?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  address?: Maybe<SupplierAddressInput>;
  emailAddress?: Maybe<Scalars["String"]>;
};

export type CreateTenancyAgreementPayload = {
  __typename?: "CreateTenancyAgreementPayload";
  tenancyAgreement?: Maybe<TenancyAgreement>;
};

export type CreateTenancyBondClaim = {
  amount: MoneyInput;
  bondAiloRN: Scalars["AiloRN"];
  liabilityAiloRN: Scalars["AiloRN"];
  claimOrder: Scalars["Int"];
};

export type CreateTenantshipInput = {
  tenancyId: Scalars["ID"];
  tenantId: Scalars["ID"];
  startDate: Scalars["Date"];
  endDate?: Maybe<Scalars["Date"]>;
};

export type CreateThreadInputV2 = {
  thread: ThreadInputV2;
  chatAilorn: Scalars["AiloRN"];
};

export type CreateTrustAccountInput = {
  ownerLegalEntityAilorn: Scalars["AiloRN"];
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bsb?: Maybe<Scalars["String"]>;
};

export type CreateTrustAccountReconciliationAdjustmentInput = {
  amountInCents: Scalars["Long"];
  applyOn: Scalars["LocalDate"];
  details: Scalars["String"];
  reportPeriodId: Scalars["ID"];
};

export type CreateTrustAccountStatementBalanceInput = {
  amountInCents: Scalars["Long"];
  date: Scalars["LocalDate"];
  reportPeriodId: Scalars["ID"];
};

export type CreateUpdateAutoPayInput = {
  liabilityId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

export type CreditCard = PaymentMethod & {
  __typename?: "CreditCard";
  ailoRN: Scalars["AiloRN"];
  category?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  expiry?: Maybe<Scalars["Date"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  /** Masked credit card number, e.g. "512345XXXXXX2346". */
  number?: Maybe<Scalars["String"]>;
  topUpFee?: Maybe<TransactionFee>;
  /** E.g. "visa", "mastercard", "amex". */
  type?: Maybe<Scalars["String"]>;
  wallet: Wallet;
};

export type CreditCardInput = {
  description?: Maybe<Scalars["String"]>;
  rawCreditCardPayload: Scalars["String"];
};

export type CreditCardInputV2 = {
  category: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  expiry: Scalars["String"];
  number: Scalars["String"];
  token: Scalars["String"];
  type: Scalars["String"];
};

export type CumulativeCharge = {
  __typename?: "CumulativeCharge";
  amount: TaxableAmount;
  date: Scalars["Date"];
};

export type CursorPaging = {
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars["ConnectionCursor"]>;
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars["ConnectionCursor"]>;
  /** Paginate first */
  first?: Maybe<Scalars["Int"]>;
  /** Paginate last */
  last?: Maybe<Scalars["Int"]>;
};

export type DateFieldComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["DateTime"]>;
  neq?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
  between?: Maybe<DateFieldComparisonBetween>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars["DateTime"];
  upper: Scalars["DateTime"];
};

export type DateRange = {
  __typename?: "DateRange";
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
};

export enum DateRangeCurrentness {
  Past = "past",
  Current = "current",
  Future = "future"
}

export type DateRangeFilter = {
  afterDate?: Maybe<Scalars["Date"]>;
  beforeDate?: Maybe<Scalars["Date"]>;
};

export type DeleteCrnInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
};

export type DeleteManagementFileInput = {
  fileAilorn: Scalars["AiloRN"];
  managementId: Scalars["ID"];
};

export type DeleteManagementFilePayload = {
  __typename?: "DeleteManagementFilePayload";
  file: File;
  query: Query;
};

export type DeleteOneKeyInput = {
  /** The id of the record to delete. */
  id: Scalars["ID"];
};

export type DeleteRentInput = {
  rentId: Scalars["ID"];
};

export type DeleteRentPayload = {
  __typename?: "DeleteRentPayload";
  rentId: Scalars["ID"];
  query: Query;
};

export type DeleteTenancyFileInput = {
  fileAilorn: Scalars["AiloRN"];
  tenancyId: Scalars["ID"];
};

export type DeleteTenancyFilePayload = {
  __typename?: "DeleteTenancyFilePayload";
  file: File;
  query: Query;
};

export type DeprecatedPaginatedBills = {
  __typename?: "DeprecatedPaginatedBills";
  pageInfo: PageInfo;
  items: Array<Maybe<Bill>>;
};

export type DeprecatedStatementData = {
  __typename?: "DeprecatedStatementData";
  lineItems?: Maybe<Array<StatementLineItem>>;
  netEarnings: Money;
  totalExpenses: Money;
  totalExpensesGST: Money;
  totalIncome: Money;
};

export type EditableRentReviewPayload = {
  __typename?: "EditableRentReviewPayload";
  rent?: Maybe<Rent>;
  problem?: Maybe<EditableRentReviewProblem>;
};

export type EditableRentReviewProblem = ProblemInterface & {
  __typename?: "EditableRentReviewProblem";
  message: Scalars["String"];
};

export type EditRentReviewInput = {
  rentId: Scalars["ID"];
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  setsChargeDate: Scalars["Boolean"];
  scheduledRentReviewDate: Scalars["Date"];
};

export type EditRentReviewPayload = {
  __typename?: "EditRentReviewPayload";
  rent?: Maybe<Rent>;
  tenancy?: Maybe<Tenancy>;
  query: Query;
};

export type EndManagementV2Input = {
  managementId: Scalars["ID"];
  endDate: Scalars["Date"];
  endReasonCode: ManagementEndReasonCode;
  endCauseCodes?: Maybe<Array<ManagementEndCauseCode>>;
  endNote: Scalars["String"];
};

export type EndManagementV2Payload = {
  __typename?: "EndManagementV2Payload";
  management: Management;
};

export type Entry = {
  __typename?: "Entry";
  account: Account;
  accountBalance?: Maybe<Money>;
  amount: Money;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  ledgerEvent: LedgerEvent;
  snowflake: Scalars["Long"];
  userFacingDescription: Scalars["String"];
};

export enum EntrySortField {
  CreatedAt = "CREATED_AT",
  Snowflake = "SNOWFLAKE"
}

export enum ExternalMailboxStatus {
  Active = "Active",
  Inactive = "Inactive"
}

export type ExternalMetadataInput = {
  externalSourceId: Scalars["Int"];
  externalFeeTemplateId: Scalars["ID"];
  triggerId?: Maybe<Scalars["ID"]>;
  triggerType?: Maybe<PMeFeeTriggerType>;
  name?: Maybe<Scalars["String"]>;
  chartAccountName?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Float"]>;
  valueType?: Maybe<PMeFeeValueType>;
};

export type Fee = {
  __typename?: "Fee";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  type: FeeType;
  management: Management;
  blueprint?: Maybe<FeeBlueprint>;
  managementFeeBlueprint?: Maybe<ManagementFeeBlueprint>;
  /** Never null if type = BillManagementFee */
  appliedTo?: Maybe<Bill>;
  /**
   * Bill if type = BillManagementFee
   * Tenancy if type = EventBasedFee & blueprint.eventType = firstRentSettled
   */
  appliedToV2?: Maybe<FeeAppliedTo>;
  amount: Money;
  percentage?: Maybe<Scalars["Float"]>;
  baseAmount?: Maybe<Money>;
  baseAmountType?: Maybe<FeeBaseAmountType>;
  taxCategory: FeeTaxCategory;
  description?: Maybe<Scalars["String"]>;
  /** TODO META-381: Make this never null after backfilling status */
  status?: Maybe<FeeStatus>;
  createdAt: Scalars["DateTime"];
  /** Never null. */
  createdBy?: Maybe<ActionInitiator>;
  /** Never null. */
  modifiedBy?: Maybe<ActionInitiator>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<ActionInitiator>;
  archiveReason?: Maybe<Scalars["String"]>;
  taxAmount?: Maybe<Money>;
  liability?: Maybe<Liability>;
};

export type FeeAppliedTo = Bill | Tenancy;

export enum FeeBaseAmountType {
  Bill = "Bill",
  OneWeekRent = "OneWeekRent"
}

export type FeeBlueprint = {
  __typename?: "FeeBlueprint";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  type: FeeBlueprintType;
  name: Scalars["String"];
  taxCategory: FeeTaxCategory;
  event?: Maybe<FeeEvent>;
  /** @deprecated Use `description` on `Fee` or `RecurringFee` instead. */
  description?: Maybe<Scalars["String"]>;
  /** Present if `chargeType == FixedAmount`. Null otherwise. */
  fixedAmount?: Maybe<Money>;
  taxTreatment: TaxTreatment;
  /** Present if `chargeType == OneWeekRentPercentage`. Null otherwise. */
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  chargeType: FeeBlueprintChargeType;
  /** Present if `type == RecurringFee`. Null otherwise. */
  frequency?: Maybe<FeeFrequency>;
  /** Present if `type == RecurringFee`. Null otherwise. */
  anniversaryDay?: Maybe<Scalars["Int"]>;
  /** Present if `type == RecurringFee && frequency == "annually"`. Null otherwise. */
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  /** Always present. (Nullable only because it's a federated field.) */
  organisation?: Maybe<Organisation>;
  createdAt: Scalars["DateTime"];
  createdBy: ActionInitiator;
  modifiedBy: ActionInitiator;
  archived: Scalars["Boolean"];
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<ActionInitiator>;
  archiveReason?: Maybe<Scalars["String"]>;
  supported?: Maybe<Scalars["Boolean"]>;
};

export enum FeeBlueprintChargeType {
  FixedAmount = "FixedAmount",
  OneWeekRentPercentage = "OneWeekRentPercentage"
}

export type FeeBlueprintsQueryConditions = {
  organisationId?: Maybe<Scalars["ID"]>;
  type?: Maybe<FeeBlueprintType>;
  /** Exclude agency fee blueprints that are linked to non-archived management fee blueprints on the given management */
  notAppliedToManagementId?: Maybe<Scalars["ID"]>;
  archived?: Maybe<Scalars["Boolean"]>;
};

export enum FeeBlueprintType {
  RecurringFee = "RecurringFee",
  OneOffFee = "OneOffFee",
  EventBasedFee = "EventBasedFee"
}

export type FeeEvent = {
  __typename?: "FeeEvent";
  type: FeeEventType;
  shortDescription: Scalars["String"];
  longDescription: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export enum FeeEventType {
  FirstRentSettled = "firstRentSettled"
}

export enum FeeFrequency {
  Weekly = "weekly",
  Monthly = "monthly",
  Annually = "annually"
}

export type FeeOccurrence = {
  __typename?: "FeeOccurrence";
  date: Scalars["Date"];
  taxAmount: Money;
  taxInclusiveAmount: Money;
};

export enum FeeSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  /** Sorted in this order: Due, PaymentPending, Paid, RefundPending, Refunded, Cancelled */
  StatusProgressAsc = "STATUS_PROGRESS_ASC"
}

export type FeesQueryConditions = {
  type?: Maybe<Array<FeeType>>;
  managementId?: Maybe<Array<Scalars["ID"]>>;
  appliedToAiloRN?: Maybe<Array<Scalars["AiloRN"]>>;
  status?: Maybe<Array<FeeStatus>>;
};

export enum FeeStatus {
  Due = "Due",
  PaymentPending = "PaymentPending",
  Paid = "Paid",
  Cancelled = "Cancelled",
  RefundPending = "RefundPending",
  Refunded = "Refunded"
}

export type FeeTaxCategory = {
  __typename?: "FeeTaxCategory";
  id: Scalars["String"];
  name: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export enum FeeType {
  BillManagementFee = "BillManagementFee",
  OneOffFee = "OneOffFee",
  EventBasedFee = "EventBasedFee"
}

export type File = {
  __typename?: "File";
  id: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  fileName: Scalars["String"];
  fileSize?: Maybe<Scalars["Int"]>;
  kind: FileKind;
  contentType?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  thumbnailUrl?: Maybe<Scalars["String"]>;
  thumbnailStatus: ThumbnailStatus;
};

export enum FileKind {
  AgencyTransferStatement = "AgencyTransferStatement",
  AuthZPersonPhoto = "AuthZ_PersonPhoto",
  BillBillAttachment = "Bill_BillAttachment",
  ChatMessageFilePart = "Chat_MessageFilePart",
  InspectionReport = "InspectionReport",
  StatementInvestorStatement = "Statement_InvestorStatement",
  ProjectFile = "ProjectFile",
  TenancyFile = "TenancyFile",
  ManagementFile = "ManagementFile",
  InspectionFile = "InspectionFile",
  Receipt = "Receipt",
  CentrepayReport = "CentrepayReport",
  Unknown = "unknown"
}

export type FileWithUploadUrl = {
  __typename?: "FileWithUploadUrl";
  file: File;
  uploadUrl: Scalars["String"];
};

export type Filter = {
  dateRange?: Maybe<DateRangeFilter>;
  search?: Maybe<Array<Maybe<SearchFilter>>>;
};

export type FindOrCreateBlueprintInput = {
  name: Scalars["String"];
  type: FeeBlueprintType;
  taxCategoryId: Scalars["ID"];
  taxTreatment: TaxTreatment;
  event?: Maybe<FeeEventType>;
  organisationAilorn: Scalars["AiloRN"];
  externalMetadata: ExternalMetadataInput;
  fixedAmount?: Maybe<MoneyInput>;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  frequency?: Maybe<FeeFrequency>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
};

export type FindOrCreateManagementFeeBlueprintInput = {
  managementId: Scalars["ID"];
  feeBlueprintId: Scalars["ID"];
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  pmeData?: Maybe<ExternalMetadataInput>;
  description?: Maybe<Scalars["String"]>;
};

export type FindOrCreateOtherContactsInput = {
  organisationAilorn: Scalars["AiloRN"];
  emails: Array<Scalars["String"]>;
};

export type FirstAndLastEntries = {
  __typename?: "FirstAndLastEntries";
  firstEntry?: Maybe<Entry>;
  lastEntry?: Maybe<Entry>;
};

export type Form = {
  id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  organisation: Organisation;
};

export type FundReceived = {
  __typename?: "FundReceived";
  amount: Money;
  receivedAt: Scalars["DateTime"];
};

export type GatewayCapturePaymentDetails = {
  __typename?: "GatewayCapturePaymentDetails";
  url?: Maybe<Scalars["URL"]>;
};

export type GeneralLedgerInteraction = {
  __typename?: "GeneralLedgerInteraction";
  reference: Scalars["AiloRN"];
};

export type GeneralStatementFilter = {
  subject?: Maybe<Scalars["AiloRN"]>;
  types?: Maybe<Array<StatementType>>;
  createdFor?: Maybe<Array<StatementCreatedFor>>;
};

export type GenerateInspectionReportInput = {
  inspectionId: Scalars["String"];
};

export type GenerateInspectionReportResult = {
  __typename?: "GenerateInspectionReportResult";
  inspection: Inspection;
};

export type GenerateLiabilityEntriesInput = {
  liabilityIds: Array<Scalars["ID"]>;
};

export type GenerateLiabilityEntriesPayload = {
  __typename?: "GenerateLiabilityEntriesPayload";
  liabilityIds: Array<Scalars["ID"]>;
};

export type GetCrnInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
};

export type GetCrnOutput = {
  __typename?: "GetCrnOutput";
  crn: Scalars["String"];
};

export type GetLegalEntityByCrnInput = {
  managingEntity: Scalars["AiloRN"];
  crn: Scalars["String"];
};

export type GetLegalEntityByCrnOutput = {
  __typename?: "GetLegalEntityByCrnOutput";
  legalEntity: Scalars["AiloRN"];
};

export type GetOrCreateWalletOwnerAndBankAccountInput = {
  accountName: Scalars["String"];
  accountNumber: Scalars["String"];
  bsb: Scalars["String"];
  description: Scalars["String"];
  walletOwnerAiloRN: Scalars["AiloRN"];
};

export type GraphQlAilornFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["AiloRN"]>;
  neq?: Maybe<Scalars["AiloRN"]>;
  gt?: Maybe<Scalars["AiloRN"]>;
  gte?: Maybe<Scalars["AiloRN"]>;
  lt?: Maybe<Scalars["AiloRN"]>;
  lte?: Maybe<Scalars["AiloRN"]>;
  like?: Maybe<Scalars["AiloRN"]>;
  notLike?: Maybe<Scalars["AiloRN"]>;
  iLike?: Maybe<Scalars["AiloRN"]>;
  notILike?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type IdFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["ID"]>;
  neq?: Maybe<Scalars["ID"]>;
  gt?: Maybe<Scalars["ID"]>;
  gte?: Maybe<Scalars["ID"]>;
  lt?: Maybe<Scalars["ID"]>;
  lte?: Maybe<Scalars["ID"]>;
  like?: Maybe<Scalars["ID"]>;
  notLike?: Maybe<Scalars["ID"]>;
  iLike?: Maybe<Scalars["ID"]>;
  notILike?: Maybe<Scalars["ID"]>;
  in?: Maybe<Array<Scalars["ID"]>>;
  notIn?: Maybe<Array<Scalars["ID"]>>;
};

export type IgnoreMe = {
  __typename?: "IgnoreMe";
  company?: Maybe<Company>;
  paymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
  person?: Maybe<Person>;
};

export type InboundMailDirectionDetails = {
  __typename?: "InboundMailDirectionDetails";
  direction: MailDirection;
};

export type IncomeAndExpenseByTaxCategoryData = {
  __typename?: "IncomeAndExpenseByTaxCategoryData";
  expenses: Array<IncomeAndExpenseByTaxCategoryDataEntry>;
  income: Array<IncomeAndExpenseByTaxCategoryDataEntry>;
};

export type IncomeAndExpenseByTaxCategoryDataEntry = {
  __typename?: "IncomeAndExpenseByTaxCategoryDataEntry";
  taxCategory: Scalars["String"];
  totalAmount: Money;
  totalTaxAmount: Money;
};

export type IncomeAndExpenseByTaxCategoryInput = {
  accountOwnerReference: Scalars["AiloRN"];
  /** Filter to include only entries which have created_at < createdBefore */
  createdBefore: Scalars["DateTime"];
  /** Filter to include only entries which have created_at >= createdFrom */
  createdFrom: Scalars["DateTime"];
};

export type IngoingTenancyUpdateInput = {
  tenancyId: Scalars["ID"];
  scheduledRentReviewDate?: Maybe<Scalars["Date"]>;
  tenancyAgreement: TenancyAgreementSetupInput;
  rents: Array<RentSetupInput>;
};

export type Inspection = {
  __typename?: "Inspection";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  type: InspectionType;
  startedAt: Scalars["DateTime"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  areas: Array<InspectionArea>;
  inspectionAppointment: InspectionAppointment;
  property: Property;
  agency: Organisation;
  inspectingAgent: Person;
  /** @deprecated Use areaFilesForThumbnail and featureFieldsForThumbnail instead */
  files: Array<InspectionAreaFile>;
  areaFilesForThumbnail: Array<InspectionAreaFile>;
  featureFilesForThumbnail: Array<InspectionFeatureFile>;
  ailoForm?: Maybe<AiloForm>;
  tenancy: Tenancy;
  reportGenerationState: ReportGenerationState;
  latestReportProgress?: Maybe<InspectionReportProgress>;
};

export type InspectionAreaFilesForThumbnailArgs = {
  limit?: Maybe<Scalars["Int"]>;
};

export type InspectionFeatureFilesForThumbnailArgs = {
  limit?: Maybe<Scalars["Int"]>;
};

export type InspectionAggregateGroupBy = {
  __typename?: "InspectionAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointment = {
  __typename?: "InspectionAppointment";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  type: InspectionType;
  startTime?: Maybe<Scalars["DateTime"]>;
  inspection?: Maybe<Inspection>;
  property: Property;
  tenancy: Tenancy;
  agency: Organisation;
  inspectingAgent: Person;
  previousInspection?: Maybe<Inspection>;
};

export type InspectionAppointmentAggregateGroupBy = {
  __typename?: "InspectionAppointmentAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointmentConnection = {
  __typename?: "InspectionAppointmentConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<InspectionAppointmentEdge>;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type InspectionAppointmentCountAggregate = {
  __typename?: "InspectionAppointmentCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  propertyAilorn?: Maybe<Scalars["Int"]>;
  tenancyAilorn?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["Int"]>;
  inspectingAgentAilorn?: Maybe<Scalars["Int"]>;
  startTime?: Maybe<Scalars["Int"]>;
};

export type InspectionAppointmentEdge = {
  __typename?: "InspectionAppointmentEdge";
  /** The node containing the InspectionAppointment */
  node: InspectionAppointment;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type InspectionAppointmentFilter = {
  id?: Maybe<IdFilterComparison>;
  propertyAilorn?: Maybe<InspectionAppointmentPropertyAilornFilterComparison>;
  tenancyAilorn?: Maybe<InspectionAppointmentTenancyAilornFilterComparison>;
  type?: Maybe<InspectionAppointmentTypeFilterComparison>;
  inspectingAgentAilorn?: Maybe<InspectionAppointmentInspectingAgentAilornFilterComparison>;
  startTime?: Maybe<DateFieldComparison>;
  inspection?: Maybe<InspectionAppointmentFilterInspectionFilter>;
};

export type InspectionAppointmentFilterInspectionFilter = {
  id?: Maybe<IdFilterComparison>;
  type?: Maybe<InspectionTypeFilterComparison>;
  inspectingAgentAilorn?: Maybe<InspectionInspectingAgentAilornFilterComparison>;
  startedAt?: Maybe<DateFieldComparison>;
  completedAt?: Maybe<DateFieldComparison>;
};

export type InspectionAppointmentInspectingAgentAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionAppointmentMaxAggregate = {
  __typename?: "InspectionAppointmentMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointmentMinAggregate = {
  __typename?: "InspectionAppointmentMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  propertyAilorn?: Maybe<Scalars["AiloRN"]>;
  tenancyAilorn?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
};

export type InspectionAppointmentPropertyAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionAppointmentSort = {
  field: InspectionAppointmentSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum InspectionAppointmentSortFields {
  Id = "id",
  PropertyAilorn = "propertyAilorn",
  TenancyAilorn = "tenancyAilorn",
  Type = "type",
  InspectingAgentAilorn = "inspectingAgentAilorn",
  StartTime = "startTime"
}

export type InspectionAppointmentTenancyAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionAppointmentTypeFilterComparison = {
  eq?: Maybe<InspectionType>;
  in?: Maybe<Array<InspectionType>>;
  notIn?: Maybe<Array<InspectionType>>;
};

export type InspectionArea = {
  __typename?: "InspectionArea";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  index: Scalars["Int"];
  name: Scalars["String"];
  inspected: Scalars["Boolean"];
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  inspection: Inspection;
  files: Array<InspectionAreaFile>;
  features: Array<InspectionFeature>;
};

export enum InspectionAreaCondition {
  Excellent = "Excellent",
  Good = "Good",
  Fair = "Fair",
  Poor = "Poor"
}

export type InspectionAreaFile = {
  __typename?: "InspectionAreaFile";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionArea: InspectionArea;
  file: File;
};

export type InspectionConnection = {
  __typename?: "InspectionConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<InspectionEdge>;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type InspectionCountAggregate = {
  __typename?: "InspectionCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["Int"]>;
  inspectingAgentAilorn?: Maybe<Scalars["Int"]>;
  startedAt?: Maybe<Scalars["Int"]>;
  completedAt?: Maybe<Scalars["Int"]>;
};

export type InspectionEdge = {
  __typename?: "InspectionEdge";
  /** The node containing the Inspection */
  node: Inspection;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type InspectionFeature = {
  __typename?: "InspectionFeature";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  name: Scalars["String"];
  index: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  inspectionArea: InspectionArea;
  files: Array<InspectionFeatureFile>;
};

export type InspectionFeatureFile = {
  __typename?: "InspectionFeatureFile";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  timestamp: Scalars["DateTime"];
  inspectionFeature: InspectionFeature;
  file: File;
};

export type InspectionFilter = {
  id?: Maybe<IdFilterComparison>;
  type?: Maybe<InspectionTypeFilterComparison>;
  inspectingAgentAilorn?: Maybe<InspectionInspectingAgentAilornFilterComparison>;
  startedAt?: Maybe<DateFieldComparison>;
  completedAt?: Maybe<DateFieldComparison>;
};

export type InspectionInspectingAgentAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
  in?: Maybe<Array<Scalars["AiloRN"]>>;
  notIn?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type InspectionMaxAggregate = {
  __typename?: "InspectionMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
};

export type InspectionMinAggregate = {
  __typename?: "InspectionMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
};

export type InspectionReportProgress = {
  __typename?: "InspectionReportProgress";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  fileAilorn?: Maybe<Scalars["AiloRN"]>;
  /** Report generation completion time */
  completedAt?: Maybe<Scalars["DateTime"]>;
  /** Report generation failure time */
  failedAt?: Maybe<Scalars["DateTime"]>;
  inspection: Inspection;
};

export type InspectionSort = {
  field: InspectionSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum InspectionSortFields {
  Id = "id",
  Type = "type",
  InspectingAgentAilorn = "inspectingAgentAilorn",
  StartedAt = "startedAt",
  CompletedAt = "completedAt"
}

export enum InspectionType {
  Ingoing = "Ingoing",
  Outgoing = "Outgoing",
  Routine = "Routine"
}

export type InspectionTypeFilterComparison = {
  eq?: Maybe<InspectionType>;
  in?: Maybe<Array<InspectionType>>;
  notIn?: Maybe<Array<InspectionType>>;
};

export type InternalSupplierInput = {
  name?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  organisationReference: Scalars["AiloRN"];
  internalReference: Scalars["AiloRN"];
};

export type IntFieldComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["Int"]>;
  neq?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  between?: Maybe<IntFieldComparisonBetween>;
  notBetween?: Maybe<IntFieldComparisonBetween>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars["Int"];
  upper: Scalars["Int"];
};

export type Key = {
  __typename?: "Key";
  id: Scalars["ID"];
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  property: Property;
  organisation: Organisation;
};

export type KeyAggregateGroupBy = {
  __typename?: "KeyAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type KeyCountAggregate = {
  __typename?: "KeyCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["Int"]>;
  organisationAilorn?: Maybe<Scalars["Int"]>;
};

export type KeyDeleteResponse = {
  __typename?: "KeyDeleteResponse";
  id?: Maybe<Scalars["ID"]>;
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  property?: Maybe<Property>;
  organisation?: Maybe<Organisation>;
};

export type KeyEdge = {
  __typename?: "KeyEdge";
  /** The node containing the Key */
  node: Key;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type KeyFilter = {
  id?: Maybe<KeyIdFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
  organisationAilorn?: Maybe<KeyOrganisationAilornFilterComparison>;
};

export type KeyIdFilterComparison = {
  eq?: Maybe<Scalars["ID"]>;
};

export type KeyInput = {
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  propertyAilorn: Scalars["AiloRN"];
  organisationAilorn: Scalars["AiloRN"];
};

export type KeyMaxAggregate = {
  __typename?: "KeyMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type KeyMinAggregate = {
  __typename?: "KeyMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type KeyOrganisationAilornFilterComparison = {
  eq?: Maybe<Scalars["AiloRN"]>;
};

export type KeySort = {
  field: KeySortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum KeySortFields {
  Id = "id",
  CreatedAt = "createdAt",
  UpdatedAt = "updatedAt",
  OrganisationAilorn = "organisationAilorn"
}

export type LeaseRenewalDetails = {
  id?: Maybe<Scalars["ID"]>;
  startDate: Scalars["Date"];
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
};

/** @deprecated Use `BidirectionalPageInfo` instead when possible. */
export type LedgerBidirectionalPageInfo = {
  __typename?: "LedgerBidirectionalPageInfo";
  /** @deprecated Use `hasNext` instead. */
  hasMore: Scalars["Boolean"];
  hasNext: Scalars["Boolean"];
  hasPrevious: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
  previousCursor?: Maybe<Scalars["String"]>;
  total: Scalars["Int"];
};

export type LedgerEvent = {
  __typename?: "LedgerEvent";
  ailoRN: Scalars["AiloRN"];
  businessTransaction?: Maybe<BusinessTransaction>;
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  domainEventKey: Scalars["String"];
  entries?: Maybe<PaginatedEntries>;
  id: Scalars["ID"];
  interaction: GeneralLedgerInteraction;
  occurredAt: Scalars["DateTime"];
  owing?: Maybe<RecurringOwing>;
  paymentMethod?: Maybe<PaymentMethodCompanion>;
  reference: Scalars["AiloRN"];
  reversalDepth?: Maybe<Scalars["Int"]>;
  type: LedgerEventType;
};

export type LedgerEventEntriesArgs = {
  pagination?: PaginationParams;
};

export enum LedgerEventType {
  Cleared = "CLEARED",
  Failed = "FAILED",
  Initiated = "INITIATED",
  InitiatedAndCleared = "INITIATED_AND_CLEARED"
}

/** @deprecated Use `BidirectionalPageCursor` instead when possible. */
export type LedgerPageCursor = {
  cursor?: Maybe<Scalars["String"]>;
  pageSize: Scalars["Int"];
  paginateBackward?: Maybe<Scalars["Boolean"]>;
  /** sort is not supported yet. */
  sort?: Maybe<Scalars["String"]>;
};

export type LegalEntity = {
  id: Scalars["ID"];
  ailoRN: Scalars["String"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisationId: Scalars["ID"];
  currentUserPermissions: Array<Scalars["String"]>;
  timezone: Scalars["TimeZone"];
  emailAddress?: Maybe<Scalars["String"]>;
  /** Statements with subject = Legal Entity Ailorn */
  statements?: Maybe<PaginatedStatements>;
  tenancies?: Maybe<PaginatedTenancies>;
  managements?: Maybe<PaginatedManagements>;
  contact?: Maybe<Contact>;
};

export type LegalEntityStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type LegalEntityTenanciesArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type LegalEntityManagementsArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type LegalEntityContactArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type LegalEntityCompanion = {
  __typename?: "LegalEntityCompanion";
  id: Scalars["ID"];
  /** @deprecated Use `LegalEntityCompanion.ailoRNV2` instead. */
  ailoRN: Scalars["String"];
  ailoRNV2: Scalars["AiloRN"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisation: Organisation;
  legalEntity: LegalEntity;
};

export type LegalEntityMembership = {
  __typename?: "LegalEntityMembership";
  member: Person;
  legalEntity: LegalEntity;
  role: LegalEntityRole;
};

export type LegalEntityPersonRole = Role & {
  __typename?: "LegalEntityPersonRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
  legalEntity: LegalEntity;
  person: Person;
};

export type LegalEntityRole = Role & {
  __typename?: "LegalEntityRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
};

export type Liability = {
  __typename?: "Liability";
  /**
   * Amount computed base on how many full cycle, how many Days into a partial cycle and how much are paid up to now for this liability.
   * e.g. Tenant occupied a $100/wk property for 2 days so far and paid $30.
   * The DailyRate of this property is $100/7 = $14.29 per day.
   * This field should be 2days * $14.29 - $30 = -$1.42 (i.e. Tenant overpaid $1.42 so far and not in arrears)
   */
  amountInArrears: Money;
  autoPaymentDetails?: Maybe<AutoPaymentDetails>;
  category: Scalars["String"];
  /**
   * A poorly named and designed timestamp representing a date compute base on whether payment for full cycles of liability has been made.
   * The time should always be 00am of a Date in liability's zone. This timestamp also require minus 1 sec treatment to be displayed as a Date that's accurate for the domain.
   * e.g. Tenant paid 1 full week Rent for this Mon-Sun, this field would be Monday 00am in `zone`. The actual Paid To Date should be Sunday.
   * @deprecated Use `paidToDate` instead
   */
  classicPaidTo?: Maybe<Scalars["DateTime"]>;
  consumerReference?: Maybe<Scalars["AiloRN"]>;
  /** The daily rent rate for the latest plan. */
  dailyRate: Money;
  /**
   * The number of days a tenant has stayed in a property and not paid anything for.
   * Eg. Tenant moves in 1/1/2021 and hasn't paid. Tenant is 1 day in arrears because they didn't pay for 1/1/2021.
   * If this tenant makes a payment for any amount, they will be 0 days in arrears, even if it doesn't cover a full day.
   */
  daysInArrears: Scalars["Int"];
  description?: Maybe<Scalars["String"]>;
  /** the amount of whichever the not-fully-paid cycle (past, today or future). if no partial-paid period, this will be full amount of whichever cycle due-to-be-paid next. */
  dueAmount?: Maybe<Money>;
  /** the due date of whichever not-fully-paid cycle (past, today or future). if no partial-paid period, this will be full amount of whichever cycle due-to-be-paid next. */
  dueDate?: Maybe<Scalars["DateTime"]>;
  /**
   * Date compute base on how much is paid against this liability up til now.
   * Does not require minus 1 day or 1 sec treatment. The Date is in this Liability's `zone`.
   * e.g. Tenant paid $30 into this Mon-Sun (rent = $100/week). It is 2 days and a bit worth of Rent. This field should be this Tuesday (i.e paid for Monday and Tuesday inclusive)
   * e.g. Tenant paid nothing into this Mon-Sun, this field should be last Sunday.
   */
  effectivePaidToDate?: Maybe<Scalars["Date"]>;
  entries?: Maybe<PaginatedLiabilityEntries>;
  firstPaymentDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  lastPaymentDate?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `category` instead */
  liabilityType?: Maybe<Scalars["String"]>;
  linkedChildBusinessTransactions?: Maybe<Array<BusinessTransaction>>;
  management?: Maybe<Management>;
  /** the full amount of the next (as of today) rent cycle, regardless any rent payment */
  nextChargeAmount?: Maybe<Money>;
  /** the start date of the next (as of today) rent cycle, regardless any rent payment */
  nextChargeCreateDate?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `nextChargeDueDate`. they are the same, this one is poorly named. */
  nextChargeDate?: Maybe<Scalars["DateTime"]>;
  /** the due date of the next rent cycle, regardless any rent payment */
  nextChargeDueDate?: Maybe<Scalars["DateTime"]>;
  /** the due amount of the next (as of today) not-fully-paid rent cycle */
  nextDueAmount?: Maybe<Money>;
  /** the due date of the next (as of today) not-fully-paid rent cycle */
  nextDueDate?: Maybe<Scalars["DateTime"]>;
  /**
   * Poorly named field representing Amount computed base on how many full liability cycles owed and how much are paid up to now.
   * Produce wrong result for any Liability with Overlapping LiabilityPlans, as this field uses Liaiblity Entry sum.
   * @deprecated Use `overdueAmount` instead
   */
  outstandingBalance: Money;
  /**
   * Amount computed base on how many full liability cycles owed and how much are paid up to now.
   * e.g. Tenant occupied a $100/wk property for 2 days so far and paid $30. This field should be $100 - $30
   */
  overdueAmount: Money;
  /**
   * A poorly named and designed timestamp representing Effective Paid To Date, a Date compute base on how much is paid against this liability up til now.
   * The time should always be 00am of a Date in liability's zone. This timestamp also require minus 1 sec treatment to be displayed as a Date that's accurate for the domain.
   * e.g. Tenant paid $30 into this Mon-Sun (rent = $100/week). It is 2 days and a bit worth of Rent. This field will be 00am of Wed. The actual Effective Paid To Date should be Tuesday.
   * @deprecated Use `effectivePaidToDate` instead
   */
  paidTo?: Maybe<Scalars["DateTime"]>;
  /**
   * Date compute base on whether payment for full cycles of liability has been made.
   * Does not require minus 1 day or 1 sec treatment. The Date is in this Liability's `zone`.
   * e.g. Tenant paid 1 full week Rent for this Mon-Sun, this field should be this Sunday.
   * e.g. Tenant paid nothing for this Mon-Sun, this field should be last Sunday.
   */
  paidToDate?: Maybe<Scalars["Date"]>;
  paymentKey: Scalars["GeneratedKey"];
  paymentPlans?: Maybe<PaginatedLiabilityPaymentPlans>;
  providerReference?: Maybe<Scalars["AiloRN"]>;
  reference: Scalars["String"];
  referencedEntity?: Maybe<LiabilityReferencedEntity>;
  relatingToManagement?: Maybe<Scalars["AiloRN"]>;
  status?: Maybe<LiabilityStatus>;
  taxCategory: Scalars["String"];
  /**
   * If this liability has a defined termination date, what is the Amount owed from now to that termination day (incl. the termination day itself)
   * Null when there's no termination date known for the liability.
   * e.g. as of today, a Tenant owes nothing and even over paid $30. The property will be terminated in 1 month and 12 days.
   * This field should calculate how much will be owed between now and 1mth12days base on rent schedule, and minus $30 overpayment.
   */
  terminationAmountDue?: Maybe<Money>;
  /** The inclusive date of the liability's termination. The value is null if liability has not been terminated. */
  terminationDate?: Maybe<Scalars["Date"]>;
  timeZoneName?: Maybe<Scalars["ZoneId"]>;
  totalAmountPaid: Money;
  recurringFee?: Maybe<RecurringFee>;
  owing?: Maybe<RecurringOwing>;
};

export type LiabilityEntriesArgs = {
  cursor?: Maybe<BidirectionalPageCursor>;
  filterTypes?: Maybe<Array<SourceType>>;
  sort?: Maybe<LiabilityEntrySort>;
};

export type LiabilityPaymentPlansArgs = {
  enabled: Scalars["Boolean"];
  payerLegalEntityId: Scalars["AiloRN"];
};

export type LiabilityCalculation = {
  __typename?: "LiabilityCalculation";
  paidToDate: Scalars["Date"];
  timeZoneName: Scalars["ZoneId"];
};

export type LiabilityCalculationInput = {
  asOf: Scalars["Date"];
  liabilityId: Scalars["ID"];
};

export type LiabilityChangePreview = {
  __typename?: "LiabilityChangePreview";
  earliestStartDateTime: Scalars["DateTime"];
  effectivePaidToDate?: Maybe<Scalars["Date"]>;
  effectivePaidToDateWithProposedPlan?: Maybe<Scalars["Date"]>;
  mergedPlans: Array<Maybe<Plan>>;
  /** @deprecated Use `effectivePaidToDate` instead */
  paidToDate?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `effectivePaidToDateWithProposedPlan` instead */
  paidToDateWithProposedPlan?: Maybe<Scalars["DateTime"]>;
  requiredAdjustment: Money;
};

export type LiabilityEntry = {
  amount: Money;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  /** @deprecated Use status in PaymentLiabilityEntry businessTransaction */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type LiabilityEntrySort = {
  field: LiabilityEntrySortField;
};

export enum LiabilityEntrySortField {
  DisplayDate = "DISPLAY_DATE",
  EffectiveAt = "EFFECTIVE_AT"
}

export type LiabilityPaymentPlan = {
  __typename?: "LiabilityPaymentPlan";
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  details: LiabilityPaymentPlanDetails;
  enabled: Scalars["Boolean"];
  id: Scalars["ID"];
  liabilityId: Scalars["ID"];
  owner: Scalars["AiloRN"];
};

export type LiabilityPaymentPlanDetails = {
  __typename?: "LiabilityPaymentPlanDetails";
  amount: Money;
  anniversary: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  endDate?: Maybe<Scalars["Date"]>;
  frequency: QuartzPlanFrequency;
  id: Scalars["ID"];
  liabilityPaymentPlanId: Scalars["ID"];
  paymentMethod: PaymentMethod;
  paymentMethodId: Scalars["ID"];
  startDate: Scalars["Date"];
  timezoneId: Scalars["String"];
};

export enum LiabilityPlanFrequency {
  Daily = "DAILY",
  Fortnightly = "FORTNIGHTLY",
  Monthly = "MONTHLY",
  OnceOff = "ONCE_OFF",
  Weekly = "WEEKLY"
}

export type LiabilityPlanInput = {
  asOfDate?: Maybe<Scalars["DateTime"]>;
  liabilityId: Scalars["ID"];
  proposedPlans: Array<Maybe<ProposedPlan>>;
};

/**
 * There should be `RecurringFee` here as well,
 * but we temporarily do not support it.
 * (Should be done eventually with steps listed at https://github.com/ailohq/property-management-service/pull/241 )
 */
export type LiabilityReferencedEntity = Bill | Fee | Tenancy;

export type LiabilityReport = {
  __typename?: "LiabilityReport";
  reference: Scalars["String"];
  reportItems?: Maybe<Array<Maybe<LiabilityReportItem>>>;
};

export type LiabilityReportItem = {
  __typename?: "LiabilityReportItem";
  amount: Money;
  /** @deprecated Use `daysInArrearsV2` */
  daysInArrears: Scalars["Int"];
  daysInArrearsV2: Scalars["Int"];
  nextDueDate: Scalars["DateTime"];
  outstandingBalance: Money;
  /** @deprecated Use `paidToDateV2` */
  paidToDate: Scalars["DateTime"];
  paidToDateV2: Scalars["DateTime"];
  paymentDate: Scalars["DateTime"];
  /** @deprecated Use `rentArrearsV2` */
  rentArrears: Money;
  rentArrearsV2: Money;
};

export type LiabilityStatus = {
  __typename?: "LiabilityStatus";
  archivedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  id: Scalars["ID"];
  isArchived: Scalars["Boolean"];
  liabilityId: Scalars["ID"];
  payerKycCheckRequired: Scalars["Boolean"];
};

export type LineItem = {
  __typename?: "LineItem";
  id: Scalars["ID"];
  /** @deprecated Use `LineItem.id` instead */
  ailoRN: Scalars["AiloRN"];
  /** @deprecated Use `LineItem.taxInclusiveAmount` instead. */
  amount: Money;
  taxInclusiveAmount: Money;
  taxAmount: Money;
  description: Scalars["String"];
  isTax: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
};

export type LineItemInput = {
  /** Tax inclusive amount. */
  amount: MoneyInput;
  taxAmount?: Maybe<MoneyInput>;
  description: Scalars["String"];
  isTax: Scalars["Boolean"];
};

export type ListAccountsFilters = {
  accountOwnerReference: Scalars["AiloRN"];
  accountReferences?: Maybe<Array<Scalars["AiloRN"]>>;
  accountSubtypes?: Maybe<Array<AccountSubtype>>;
  accountTypes?: Maybe<Array<AccountType>>;
  books?: Maybe<Array<Book>>;
  /** Default: includeAccountsWithNonPositiveBalances = true */
  includeAccountsWithNonPositiveBalances?: Maybe<Scalars["Boolean"]>;
};

export type ListAccountsSortParams = {
  direction: SortDirection;
  field: AccountSortField;
};

export type ListAccountTagBalancesFilters = {
  accountOwnerReference: Scalars["AiloRN"];
  accountSubtypes: Array<AccountSubtype>;
  asOf?: Maybe<Scalars["DateTime"]>;
  book?: Maybe<Book>;
  tagValue?: Maybe<Scalars["String"]>;
};

export type ListEntriesFilters = {
  accountOwnerReference: Scalars["AiloRN"];
  /**
   * Filter to include only entries which have account's reference match NS Entities.
   * NS Entities don't have "ailo:" prefix. Usage example: [ "authz:legalentity", "bill:bill" ]
   */
  accountReferenceNSEntities?: Maybe<Array<Scalars["String"]>>;
  accountReferences?: Maybe<Array<Scalars["AiloRN"]>>;
  accountSubtypes?: Maybe<Array<AccountSubtype>>;
  accountTypes?: Maybe<Array<AccountType>>;
  books?: Maybe<Array<Book>>;
  /** Filter to include only entries which have created_at < createdBefore */
  createdBefore?: Maybe<Scalars["DateTime"]>;
  /** Filter to include only entries which have created_at >= createdFrom */
  createdFrom?: Maybe<Scalars["DateTime"]>;
  includeEmptyAccountBalance?: Maybe<Scalars["Boolean"]>;
};

export type ListEntriesSortParams = {
  direction: SortDirection;
  field: EntrySortField;
};

export type ListPurposesFilters = {
  payableBy?: Maybe<Scalars["AiloRN"]>;
  payableTo?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<Scalars["String"]>;
};

export type ListTransactionsFilters = {
  createdAfter?: Maybe<Scalars["DateTime"]>;
  createdBefore?: Maybe<Scalars["DateTime"]>;
  management?: Maybe<Scalars["AiloRN"]>;
  moneyHolder: Scalars["AiloRN"];
  type?: Maybe<Scalars["String"]>;
};

export type ListTransactionsSortParams = {
  direction: SortDirection;
  field: TransactionsSortField;
};

export type LocalDateFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["LocalDate"]>;
  neq?: Maybe<Scalars["LocalDate"]>;
  gt?: Maybe<Scalars["LocalDate"]>;
  gte?: Maybe<Scalars["LocalDate"]>;
  lt?: Maybe<Scalars["LocalDate"]>;
  lte?: Maybe<Scalars["LocalDate"]>;
  like?: Maybe<Scalars["LocalDate"]>;
  notLike?: Maybe<Scalars["LocalDate"]>;
  iLike?: Maybe<Scalars["LocalDate"]>;
  notILike?: Maybe<Scalars["LocalDate"]>;
  in?: Maybe<Array<Scalars["LocalDate"]>>;
  notIn?: Maybe<Array<Scalars["LocalDate"]>>;
};

export type LocalDateRangeCondition = {
  gt?: Maybe<Scalars["LocalDate"]>;
  gte?: Maybe<Scalars["LocalDate"]>;
  lt?: Maybe<Scalars["LocalDate"]>;
  lte?: Maybe<Scalars["LocalDate"]>;
};

export type LockTrustAccountInput = {
  lockedUpTo: Scalars["DateTime"];
  trustAccountAilorn: Scalars["AiloRN"];
};

export type LongFilterComparison = {
  is?: Maybe<Scalars["Boolean"]>;
  isNot?: Maybe<Scalars["Boolean"]>;
  eq?: Maybe<Scalars["Long"]>;
  neq?: Maybe<Scalars["Long"]>;
  gt?: Maybe<Scalars["Long"]>;
  gte?: Maybe<Scalars["Long"]>;
  lt?: Maybe<Scalars["Long"]>;
  lte?: Maybe<Scalars["Long"]>;
  like?: Maybe<Scalars["Long"]>;
  notLike?: Maybe<Scalars["Long"]>;
  iLike?: Maybe<Scalars["Long"]>;
  notILike?: Maybe<Scalars["Long"]>;
  in?: Maybe<Array<Scalars["Long"]>>;
  notIn?: Maybe<Array<Scalars["Long"]>>;
};

export type Mail = {
  __typename?: "Mail";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  mailThreadAilorn: Scalars["AiloRN"];
  fromContact?: Maybe<Contact>;
  toContacts: Array<Maybe<Contact>>;
  attachmentFiles: Array<Maybe<File>>;
  externalId: Scalars["String"];
  externalSentAt: Scalars["DateTime"];
  fromEmailAddress: Scalars["String"];
  toEmailAddresses: Array<Scalars["String"]>;
  subject: Scalars["String"];
  htmlBody: Scalars["String"];
  plaintextBody: Scalars["String"];
  provider: MailboxProvider;
  directionDetails: MailDirectionDetails;
};

export type Mailbox = {
  __typename?: "Mailbox";
  emailAddress: Scalars["String"];
  externalStatus: ExternalMailboxStatus;
  externalStatusLastCheckedAt: Scalars["DateTime"];
  provider: MailboxProvider;
};

export type MailboxAccess = {
  __typename?: "MailboxAccess";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  contactWithAccess?: Maybe<Contact>;
  grantedAt: Scalars["DateTime"];
  mailbox: Mailbox;
};

export enum MailboxProvider {
  Gmail = "Gmail"
}

export enum MailDirection {
  Inbound = "Inbound",
  Outbound = "Outbound"
}

export type MailDirectionDetails =
  | InboundMailDirectionDetails
  | OutboundMailDirectionDetails;

export type Management = {
  __typename?: "Management";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  isDraft?: Maybe<Scalars["Boolean"]>;
  managingEntity?: Maybe<Company>;
  firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  owners?: Maybe<Array<Maybe<Ownership>>>;
  endDate?: Maybe<Scalars["Date"]>;
  createdAt: Scalars["DateTime"];
  migratedAt?: Maybe<Scalars["DateTime"]>;
  terminationReason?: Maybe<ManagementTerminationReason>;
  terminationNotes?: Maybe<Scalars["String"]>;
  endAbility: ManagementEndAbility;
  endReason?: Maybe<ManagementEndReason>;
  endNote?: Maybe<Scalars["String"]>;
  managementFolioAilorn: Scalars["AiloRN"];
  managementFolio: ManagementFolio;
  agencyProperty: AgencyProperty;
  /**
   * Tenancies with an end date before today's date,
   * Ordered by date range desc (the ones that have ended recently first).
   */
  formerTenancies?: Maybe<BidirectionalPaginatedTenancies>;
  /** Ordered by date range desc (the ones that have been voided recently first). */
  voidedTenancies?: Maybe<BidirectionalPaginatedTenancies>;
  /** Tenancy with a start date later than today */
  nextTenancy?: Maybe<Tenancy>;
  /** Tenancy with start date before or equal to today and closest to today's date */
  mostRecentTenancy?: Maybe<Tenancy>;
  /**
   * returns the first to exist of active, next or previous tenancy
   * in that order.
   */
  mostRelevantTenancy?: Maybe<Tenancy>;
  currentManagementAgreement?: Maybe<ManagementAgreement>;
  currentOrNextManagementAgreement?: Maybe<ManagementAgreement>;
  allManagementAgreements: Array<ManagementAgreement>;
  managementAgreements?: Maybe<PaginatedManagementAgreements>;
  currentOrNextManagementFeeSchedule?: Maybe<ManagementFeeSchedule>;
  fees?: Maybe<PaginatedFees>;
  recurringFees: Array<RecurringFee>;
  property: Property;
  files?: Maybe<PaginatedFiles>;
  note?: Maybe<ManagementNote>;
  ownerships: Array<Ownership>;
  tenancies?: Maybe<PaginatedTenancies>;
  /** Statements with subject = Mangement Ailorn */
  statements?: Maybe<PaginatedStatements>;
  ownerContacts: Array<Contact>;
};

export type ManagementFormerTenanciesArgs = {
  pageCursor?: Maybe<TenancyCursor>;
};

export type ManagementVoidedTenanciesArgs = {
  pageCursor?: Maybe<TenancyCursor>;
};

export type ManagementFeesArgs = {
  filter?: Maybe<ManagementFeeFilter>;
  cursor?: Maybe<PageCursorWithoutSort>;
  sort?: Maybe<Array<FeeSort>>;
};

export type ManagementFilesArgs = {
  pageCursor?: PaginationParams;
};

export type ManagementTenanciesArgs = {
  pageSize?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["String"]>;
};

export type ManagementStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type ManagementAgreement = {
  __typename?: "ManagementAgreement";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  startDate?: Maybe<Scalars["String"]>;
  fixedTermEndDate?: Maybe<Scalars["String"]>;
  /** In ISO-8601 duration format */
  inspectionFrequency?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["Int"];
  modifiedBy: Scalars["Int"];
  managementFeeSchedules?: Maybe<PaginatedManagementFeeSchedules>;
};

export type ManagementAndTenancyInput = {
  propertyId?: Maybe<Scalars["ID"]>;
  property?: Maybe<PropertyInput>;
  managingEntityId: Scalars["ID"];
  management: ManagementSetupInput;
  tenancy?: Maybe<TenancySetupInput>;
};

export enum ManagementCannotBeEndedProblem {
  TenancyEndDateMissing = "TenancyEndDateMissing"
}

export type ManagementEndAbility = {
  __typename?: "ManagementEndAbility";
  canBeEnded: Scalars["Boolean"];
  problem?: Maybe<ManagementCannotBeEndedProblem>;
};

export type ManagementEndCause = {
  __typename?: "ManagementEndCause";
  code: ManagementEndCauseCode;
  label: Scalars["String"];
};

export enum ManagementEndCauseCode {
  Fees = "Fees",
  LevelOfService = "LevelOfService",
  Communication = "Communication",
  ChangingTeamMembers = "ChangingTeamMembers",
  Other = "Other"
}

export type ManagementEndReason = {
  __typename?: "ManagementEndReason";
  code: ManagementEndReasonCode;
  label: Scalars["String"];
  causes: Array<ManagementEndCause>;
};

export enum ManagementEndReasonCode {
  LostToCompetitor = "LostToCompetitor",
  LostToOwnerSelfManaging = "LostToOwnerSelfManaging",
  OwnerOrFamilyMovedIn = "OwnerOrFamilyMovedIn",
  ManagementTerminatedByOurAgency = "ManagementTerminatedByOurAgency",
  ManagementSoldToAnotherAgency = "ManagementSoldToAnotherAgency",
  PropertyRenovations = "PropertyRenovations",
  PropertySaleByOurAgency = "PropertySaleByOurAgency",
  PropertySaleByAnotherAgency = "PropertySaleByAnotherAgency",
  PropertySaleByOwner = "PropertySaleByOwner",
  Other = "Other",
  Offboarded = "Offboarded"
}

export type ManagementFeeBlueprint = {
  __typename?: "ManagementFeeBlueprint";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  feeBlueprint: FeeBlueprint;
  management: Management;
  fixedAmount?: Maybe<Money>;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  chargeType: FeeBlueprintChargeType;
  createdAt: Scalars["DateTime"];
  createdBy: ActionInitiator;
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  modifiedBy?: Maybe<ActionInitiator>;
  archived: Scalars["Boolean"];
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<ActionInitiator>;
  description?: Maybe<Scalars["String"]>;
};

export type ManagementFeeBlueprintsQueryConditions = {
  managementId?: Maybe<Scalars["ID"]>;
  feeBlueprintId?: Maybe<Scalars["ID"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<FeeBlueprintType>;
};

export type ManagementFeeFilter = {
  type?: Maybe<Array<FeeType>>;
  appliedToAiloRN?: Maybe<Array<Scalars["AiloRN"]>>;
  status?: Maybe<Array<FeeStatus>>;
};

export type ManagementFeeSchedule = {
  __typename?: "ManagementFeeSchedule";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  /**
   * Percentage of rent that will deduce the fee amount.
   * In range 0 - 1.
   */
  percent: Scalars["Float"];
  startDate: Scalars["String"];
  management: Management;
  managementAgreement: ManagementAgreement;
};

export type ManagementFilterAddressSimilarityInput = {
  search: Scalars["String"];
  /**
   * Threshold from 0 to 1 for how similar returned results must be
   * 0: All results
   * 1: Extremely similar
   */
  threshold?: Scalars["Float"];
};

export type ManagementFilterParams = {
  name: ManagementFilterTypes;
  args?: Maybe<MgmtFilterArgInput>;
};

export enum ManagementFilterTypes {
  HasActiveTenancyInArrears = "HasActiveTenancyInArrears",
  HasActiveTenancyInArrearsFromOwing = "HasActiveTenancyInArrearsFromOwing",
  HasOverdueTenancy = "HasOverdueTenancy",
  HasOverdueTenancyFromOwing = "HasOverdueTenancyFromOwing",
  HasActiveTenancy = "HasActiveTenancy",
  HasTenancyUpForRentReviewOrLeaseRenewal = "HasTenancyUpForRentReviewOrLeaseRenewal",
  /**
   * enum BelongToTeams will eventually be deprecated,
   * use BelongToTeamsAndUnassigned instead
   */
  BelongToTeams = "BelongToTeams",
  BelongToTeamsAndUnassigned = "BelongToTeamsAndUnassigned",
  Active = "Active",
  DefaultPropertyList = "DefaultPropertyList",
  Terminated = "Terminated",
  HasOccupancyStatus = "HasOccupancyStatus",
  AddressSimilarity = "AddressSimilarity",
  ExcludeLost = "ExcludeLost"
}

export type ManagementFolio = {
  __typename?: "ManagementFolio";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  managingEntity?: Maybe<Company>;
  owners: Array<ManagementFolioOwnership>;
  /**
   * All managements including current and former ones that were previously in this folio before they were moved to a different folio.
   * The same management can appear multiple times in this list if it was moved out, back in and out again.
   */
  allManagements: PaginatedManagementFolioToManagements;
  /**
   * Managements that were previously in this folio before they were moved to a different folio.
   * The same management can appear multiple times in this list if it was moved out, back in and out again.
   * @deprecated Use allManagements instead
   */
  formerManagements: PaginatedManagementFolioToManagements;
  /**
   * Managements that are currently in this Management Folio.
   * @deprecated Use ManagementFolio.allManagements from common instead
   */
  managements: Array<Management>;
  wallet?: Maybe<Wallet>;
};

export type ManagementFolioAllManagementsArgs = {
  paginationParams?: PaginationParams;
  filter?: Maybe<ManagementFolioManagementFilter>;
};

export type ManagementFolioFormerManagementsArgs = {
  paginationParams?: PaginationParams;
};

export type ManagementFolioManagementFilter = {
  isCurrent?: Maybe<Scalars["Boolean"]>;
};

export type ManagementFolioOwnership = {
  __typename?: "ManagementFolioOwnership";
  managementFolio: ManagementFolio;
  owner?: Maybe<LegalEntity>;
  startDate: Scalars["LocalDate"];
  isPrimary: Scalars["Boolean"];
  consumerInvite?: Maybe<ConsumerInvite>;
};

export type ManagementFolioToManagement = {
  __typename?: "ManagementFolioToManagement";
  id: Scalars["ID"];
  management: Management;
  managementFolio: ManagementFolio;
  startDatetime: Scalars["DateTime"];
  endDatetime?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  modifiedAt: Scalars["DateTime"];
};

export type ManagementNote = {
  __typename?: "ManagementNote";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  management: Management;
  note: Scalars["String"];
};

export type ManagementPropertyAddress = ContactPropertyAddress & {
  __typename?: "ManagementPropertyAddress";
  managementAilorn: Scalars["AiloRN"];
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  /** <unitStreetNumber> <streetName> */
  shortAddress: Scalars["String"];
  firstPublishedAt?: Maybe<Scalars["DateTime"]>;
  endDate?: Maybe<Scalars["Date"]>;
};

export type ManagementSetupInput = {
  startDate: Scalars["String"];
  fixedTermEndDate?: Maybe<Scalars["String"]>;
  /** In ISO-8601 duration format */
  inspectionFrequency?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  percent: Scalars["Float"];
  owners: Array<OwnershipSetupInput>;
};

export enum ManagementTerminationReason {
  Lost = "Lost",
  OffBoarded = "OffBoarded"
}

export type MessageInputV2 = {
  text?: Maybe<Scalars["String"]>;
  fileAilorns: Array<Scalars["AiloRN"]>;
  sendEmailFromMailboxAccessAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type MessageSummaryV2 = {
  __typename?: "MessageSummaryV2";
  /**
   * The first portion of the message, truncated at 64 characters.
   * If the message contains only attachments, this will contain
   * a text summary, e.g. "sent 2 files".
   */
  snippet: Scalars["String"];
  attachmentCount: Scalars["Int"];
  sentAt: Scalars["DateTime"];
  sender: Contact;
};

export type MessageV2 = {
  __typename?: "MessageV2";
  ailorn: Scalars["AiloRN"];
  sender: Contact;
  sentAt: Scalars["DateTime"];
  text?: Maybe<Scalars["String"]>;
  files: Array<File>;
  mail?: Maybe<Mail>;
};

export type MgmtFilterArgInput = {
  BelongToTeams?: Maybe<Array<Scalars["AiloRN"]>>;
  BelongToTeamsAndUnassigned?: Maybe<BelongToTeamsArgInput>;
  HasOccupancyStatus?: Maybe<Array<OccupancyStatus>>;
  AddressSimilarity?: Maybe<ManagementFilterAddressSimilarityInput>;
};

export type Money = {
  __typename?: "Money";
  cents: Scalars["Long"];
};

export type MoneyInput = {
  cents: Scalars["Long"];
};

export enum NotAbleToCancelReason {
  TenancyIsNotVacating = "TenancyIsNotVacating",
  TenancyEndDateIsInThePast = "TenancyEndDateIsInThePast",
  ManagementHasIngoingTenancy = "ManagementHasIngoingTenancy",
  AlreadyChargedForPeriodWithEndDate = "AlreadyChargedForPeriodWithEndDate",
  ManagementIsEnding = "ManagementIsEnding"
}

export enum OccupancyStatus {
  Vacant = "Vacant",
  Occupied = "Occupied",
  Vacating = "Vacating"
}

export type OffsetPageInfo = {
  __typename?: "OffsetPageInfo";
  total: Scalars["Int"];
  hasMore: Scalars["Boolean"];
  nextOffset?: Maybe<Scalars["Int"]>;
};

export type OffsetPaginationInputV2 = {
  pageSize: Scalars["Int"];
  offset?: Maybe<Scalars["Int"]>;
};

export type OkResult = {
  __typename?: "OkResult";
  _ok: Scalars["Boolean"];
};

export type OnboardingTask = {
  __typename?: "OnboardingTask";
  id: OnboardingTaskId;
  description: Scalars["String"];
};

export enum OnboardingTaskId {
  CompleteAiloOverview = "CompleteAiloOverview",
  AddPaymentMethod = "AddPaymentMethod",
  VerifyPhoneNumber = "VerifyPhoneNumber",
  AcceptTermsOfService = "AcceptTermsOfService",
  AllowPushNotifications = "AllowPushNotifications",
  SetupAutoRentPayment = "SetupAutoRentPayment",
  GetToKnowAilo = "GetToKnowAilo",
  AcceptAgencyTermsOfService = "AcceptAgencyTermsOfService",
  PayTenancyDeposit = "PayTenancyDeposit"
}

export type Organisation = {
  __typename?: "Organisation";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  name: Scalars["String"];
  orgType: OrganisationType;
  createdAt: Scalars["DateTime"];
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  members?: Maybe<PaginatedPeople>;
  legalEntities?: Maybe<Array<Maybe<LegalEntity>>>;
  memberships: Array<OrganisationMembership>;
  availableFeatures: Array<PlatformFeature>;
  effectiveUserContact?: Maybe<Contact>;
  /** Returns only the external suppliers. */
  suppliers: PaginatedSuppliers;
  /** @deprecated Use `Organisation.suppliers` instead */
  externalSuppliers: PaginatedSuppliers;
};

export type OrganisationMembersArgs = {
  pageCursor?: Maybe<PageCursor>;
};

export type OrganisationSuppliersArgs = {
  cursor: BillDefaultPageCursor;
};

export type OrganisationExternalSuppliersArgs = {
  cursor: BillDefaultPageCursor;
};

export type OrganisationMembership = {
  __typename?: "OrganisationMembership";
  member: Person;
  organisation: Organisation;
  /** @deprecated Use `OrganisationMembership.member` instead. */
  person: Person;
  role: OrganisationRole;
};

export type OrganisationPersonRole = Role & {
  __typename?: "OrganisationPersonRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
  organisation: Organisation;
};

export type OrganisationRole = Role & {
  __typename?: "OrganisationRole";
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
};

export type OrganisationsFilter = {
  orgType?: Maybe<OrganisationType>;
};

export enum OrganisationType {
  Agency = "Agency",
  Generic = "Generic"
}

export enum OutboundMailDeliveryError {
  InvalidToAddress = "InvalidToAddress",
  MailboxFull = "MailboxFull",
  Unknown = "Unknown"
}

export enum OutboundMailDeliveryStatus {
  Sending = "Sending",
  Sent = "Sent",
  Error = "Error"
}

export type OutboundMailDirectionDetails = {
  __typename?: "OutboundMailDirectionDetails";
  direction: MailDirection;
  outboundDeliveryStatus: OutboundMailDeliveryStatus;
  outboundDeliveryStatusUpdatedAt: Scalars["DateTime"];
  outboundDeliveryError?: Maybe<OutboundMailDeliveryError>;
};

export type OverdraftAllowance = {
  __typename?: "OverdraftAllowance";
  amount: Money;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  endAt: Scalars["DateTime"];
  id: Scalars["ID"];
  startAt: Scalars["DateTime"];
  type: Scalars["String"];
  walletId: Scalars["ID"];
};

export type OverdraftAllowanceInput = {
  amount: MoneyInput;
  endAt: Scalars["DateTime"];
  startAt: Scalars["DateTime"];
  walletId: Scalars["ID"];
};

export type OwingProgress = {
  __typename?: "OwingProgress";
  /** arrears representing all not fully paid days up to and including today  */
  arrears: Arrears;
  /** the earliest not fully paid charge  */
  due?: Maybe<Charge>;
  /** cumulative charge representing total remaining amount to be paid  */
  endOfOwing?: Maybe<CumulativeCharge>;
  firstPaidAt?: Maybe<Scalars["DateTime"]>;
  lastPaidAt?: Maybe<Scalars["DateTime"]>;
  /** next (from today) charge regardless of whether it is paid or not  */
  nextCharge?: Maybe<Charge>;
  /** the next (from today) not fully paid charge  */
  nextDue?: Maybe<Charge>;
  /** cumulative charge representing all not fully paid cycles that are due up to today  */
  overdue: CumulativeCharge;
  paidTo: PaidTo;
  /** The total amount of money paid towards this owing. This does not include adjustments */
  totalPaid: Money;
};

export type OwingReferencedEntity = RecurringFee | Tenancy;

export type OwingSummary = {
  __typename?: "OwingSummary";
  chargeCycle: UnpersistedChargeCycle;
  chargeRates?: Maybe<Array<Maybe<Scalars["String"]>>>;
  firstChargeDate: Scalars["Date"];
  frequency: RecurringFrequency;
};

export type Ownership = {
  __typename?: "Ownership";
  managementId: Scalars["ID"];
  owner?: Maybe<LegalEntity>;
  startDate: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  sharesOwned: Scalars["Int"];
  sharesRatio: Scalars["Float"];
  /**
   * Total shares owned by all ownerships of the management
   * @deprecated Use `Management.sharesRatio` or `Management.sharesOwned` instead.
   */
  totalShares: Scalars["Int"];
  ownerId: Scalars["AiloRN"];
};

export type OwnershipSetupInput = {
  ownerId?: Maybe<Scalars["ID"]>;
  sharesOwned: Scalars["Int"];
  consumer?: Maybe<ConsumerSetupInput>;
};

export type PageCursor = {
  pageSize?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["String"]>;
};

export type PageCursorWithoutSort = {
  pageSize?: Maybe<Scalars["Int"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  total: Scalars["Int"];
  hasMore: Scalars["Boolean"];
  nextCursor?: Maybe<Scalars["String"]>;
};

export type PaginatedAccounts = {
  __typename?: "PaginatedAccounts";
  items: Array<Maybe<Account>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedAccountTagBalances = {
  __typename?: "PaginatedAccountTagBalances";
  items: Array<Maybe<AccountTagBalance>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedAgencyProperties = {
  __typename?: "PaginatedAgencyProperties";
  pageInfo: BidirectionalPageInfo;
  items: Array<AgencyProperty>;
};

export type PaginatedAgencyPropertySearchItems = {
  __typename?: "PaginatedAgencyPropertySearchItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<AgencyProperty>;
};

export type PaginatedAutoWithdrawPlans = {
  __typename?: "PaginatedAutoWithdrawPlans";
  items?: Maybe<Array<AutoWithdrawPlan>>;
  pageInfo: PageInfo;
};

export type PaginatedBills = {
  __typename?: "PaginatedBills";
  pageInfo: BidirectionalPageInfo;
  items: Array<Bill>;
};

export type PaginatedChargeCycles = {
  __typename?: "PaginatedChargeCycles";
  items: Array<Maybe<ChargeCycle>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedChatsInputV2 = {
  organisationAilorn: Scalars["AiloRN"];
  pagination: OffsetPaginationInputV2;
  /**
   * All chats returned must have at least one of the following
   * contacts as a participant.
   */
  mustIncludeOneOf?: Maybe<Array<ContactOrEmail>>;
  /**
   * All chats returned must have all of the following contacts
   * as participants.
   */
  mustIncludeAllOf?: Maybe<Array<ContactOrEmail>>;
  /**
   * All chats returned must have all of, and only, the following contacts
   * as participants.
   */
  mustIncludeExactly?: Maybe<Array<ContactOrEmail>>;
};

export type PaginatedChatsV2 = {
  __typename?: "PaginatedChatsV2";
  offsetPageInfo: OffsetPageInfo;
  items: Array<ChatV2>;
};

export type PaginatedContacts = {
  __typename?: "PaginatedContacts";
  pageInfo: BidirectionalPageInfo;
  items: Array<Contact>;
};

export type PaginatedContactSearchItems = {
  __typename?: "PaginatedContactSearchItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<Contact>;
};

export type PaginatedEntries = {
  __typename?: "PaginatedEntries";
  items: Array<Maybe<Entry>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedFeeBlueprints = {
  __typename?: "PaginatedFeeBlueprints";
  pageInfo: PageInfo;
  items: Array<FeeBlueprint>;
};

export type PaginatedFees = {
  __typename?: "PaginatedFees";
  pageInfo: PageInfo;
  items: Array<Fee>;
};

export type PaginatedFiles = {
  __typename?: "PaginatedFiles";
  pageInfo: BidirectionalPageInfo;
  items: Array<Maybe<File>>;
};

export type PaginatedLiabilityEntries = {
  __typename?: "PaginatedLiabilityEntries";
  items: Array<LiabilityEntry>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginatedLiabilityPaymentPlans = {
  __typename?: "PaginatedLiabilityPaymentPlans";
  items?: Maybe<Array<LiabilityPaymentPlan>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedLineItems = {
  __typename?: "PaginatedLineItems";
  pageInfo: PageInfo;
  items: Array<LineItem>;
};

export type PaginatedManagementAgreements = {
  __typename?: "PaginatedManagementAgreements";
  pageInfo: PageInfo;
  items: Array<ManagementAgreement>;
};

export type PaginatedManagementFeeBlueprints = {
  __typename?: "PaginatedManagementFeeBlueprints";
  pageInfo: PageInfo;
  items: Array<ManagementFeeBlueprint>;
};

export type PaginatedManagementFeeSchedules = {
  __typename?: "PaginatedManagementFeeSchedules";
  pageInfo: PageInfo;
  items: Array<Maybe<ManagementFeeSchedule>>;
};

export type PaginatedManagementFolioOwnerships = {
  __typename?: "PaginatedManagementFolioOwnerships";
  pageInfo: PageInfo;
  items: Array<ManagementFolioOwnership>;
};

export type PaginatedManagementFolios = {
  __typename?: "PaginatedManagementFolios";
  pageInfo: PageInfo;
  items: Array<ManagementFolio>;
};

export type PaginatedManagementFolioToManagements = {
  __typename?: "PaginatedManagementFolioToManagements";
  pageInfo: PageInfo;
  items: Array<ManagementFolioToManagement>;
};

export type PaginatedManagements = {
  __typename?: "PaginatedManagements";
  pageInfo: PageInfo;
  items: Array<Management>;
};

export type PaginatedMessagesV2 = {
  __typename?: "PaginatedMessagesV2";
  pageInfo: PageInfo;
  items: Array<Maybe<MessageV2>>;
};

export type PaginatedOrganisations = {
  __typename?: "PaginatedOrganisations";
  pageInfo: PageInfo;
  items: Array<Maybe<Organisation>>;
};

export type PaginatedOwnerships = {
  __typename?: "PaginatedOwnerships";
  pageInfo: PageInfo;
  items: Array<Ownership>;
};

export type PaginatedPaymentMethods = {
  __typename?: "PaginatedPaymentMethods";
  items: Array<Maybe<PaymentMethod>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaginatedPaymentReferences = {
  __typename?: "PaginatedPaymentReferences";
  pageInfo: PageInfo;
  items: Array<Maybe<PaymentReference>>;
};

export type PaginatedPeople = {
  __typename?: "PaginatedPeople";
  pageInfo: PageInfo;
  items: Array<Person>;
};

export type PaginatedProperties = {
  __typename?: "PaginatedProperties";
  pageInfo: PageInfo;
  items: Array<Property>;
};

export type PaginatedReconciliationDetail = {
  __typename?: "PaginatedReconciliationDetail";
  items: Array<ReconciliationDetailReportItem>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginatedRecurringFeeBlueprints = {
  __typename?: "PaginatedRecurringFeeBlueprints";
  pageInfo: PageInfo;
  items: Array<RecurringFeeBlueprint>;
};

export type PaginatedRecurringOwingEvents = {
  __typename?: "PaginatedRecurringOwingEvents";
  items: Array<Maybe<RecurringOwingEvent>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedRents = {
  __typename?: "PaginatedRents";
  pageInfo: PageInfo;
  items: Array<Rent>;
};

export type PaginatedStatementProgresses = {
  __typename?: "PaginatedStatementProgresses";
  pageInfo: BidirectionalPageInfo;
  items: Array<Maybe<StatementProgress>>;
};

export type PaginatedStatements = {
  __typename?: "PaginatedStatements";
  pageInfo: BidirectionalPageInfo;
  items: Array<Statement>;
};

export type PaginatedStatementTransactionLineItem = {
  __typename?: "PaginatedStatementTransactionLineItem";
  items: Array<StatementTransactionLineItem>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginatedSuppliers = {
  __typename?: "PaginatedSuppliers";
  pageInfo: PageInfo;
  items: Array<Supplier>;
};

export type PaginatedTenancies = {
  __typename?: "PaginatedTenancies";
  pageInfo: PageInfo;
  items: Array<Tenancy>;
};

export type PaginatedTenancyAgreements = {
  __typename?: "PaginatedTenancyAgreements";
  pageInfo: PageInfo;
  items: Array<Maybe<TenancyAgreement>>;
};

export type PaginatedTenancySearchResultItems = {
  __typename?: "PaginatedTenancySearchResultItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<TenancySearchResult>;
};

export type PaginatedTenantships = {
  __typename?: "PaginatedTenantships";
  pageInfo: PageInfo;
  items: Array<Tenantship>;
};

export type PaginatedThreadsV2 = {
  __typename?: "PaginatedThreadsV2";
  pageInfo: PageInfo;
  items: Array<Maybe<ThreadV2>>;
};

export type PaginatedTransactionReport = {
  __typename?: "PaginatedTransactionReport";
  items?: Maybe<Array<Maybe<TransactionReportItem>>>;
  pageInfo?: Maybe<LedgerBidirectionalPageInfo>;
};

export type PaginatedTransactions = {
  __typename?: "PaginatedTransactions";
  items: Array<Maybe<Transaction>>;
  pageInfo: BidirectionalPageInfo;
};

export type PaginatedTrustAccountWalletSearchItems = {
  __typename?: "PaginatedTrustAccountWalletSearchItems";
  pageInfo: BidirectionalPageInfo;
  items: Array<TrustAccountWallet>;
};

export type PaginatedUsers = {
  __typename?: "PaginatedUsers";
  pageInfo: PageInfo;
  items: Array<Maybe<User>>;
};

export type PaginatedWalletEntriesWithBtStatus = {
  __typename?: "PaginatedWalletEntriesWithBtStatus";
  items: Array<WalletEntry>;
  pageInfo: LedgerBidirectionalPageInfo;
};

export type PaginationInputV2 = {
  pageSize: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
};

export type PaginationParams = {
  cursor?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  /** If true, will return items before the cursor, rather than after it. */
  before?: Maybe<Scalars["Boolean"]>;
};

export type PaginationParamsWithPageNumber = {
  cursor?: Maybe<Scalars["String"]>;
  /** Current page number (positive value) */
  page?: Maybe<Scalars["Int"]>;
  /** Number of items per page */
  pageSize?: Maybe<Scalars["Int"]>;
  /** If true, will return items before the cursor, rather than after it. */
  before?: Maybe<Scalars["Boolean"]>;
};

export type PaidTo = {
  __typename?: "PaidTo";
  classic: PaidToDate;
  effective: PaidToDate;
};

export type PaidToDate = {
  __typename?: "PaidToDate";
  date: Scalars["Date"];
  partPayment: Money;
};

export type PaidToDateDetails = {
  __typename?: "PaidToDateDetails";
  amountInArrearsBeforeEntry: Money;
  daysInArrearsBeforeEntry: Scalars["Int"];
  effectivePaidToDate: Scalars["Date"];
  effectivePaidToDateBeforeEntry: Scalars["Date"];
  effectivePaidToDateCredit: Money;
  paidToDate: Scalars["Date"];
  paidToDateBeforeEntry: Scalars["Date"];
  paidToDateCredit: Money;
};

export enum PaidUsingType {
  AiloWallet = "AILO_WALLET",
  EftIntoTrustAccount = "EFT_INTO_TRUST_ACCOUNT",
  PaymentMethod = "PAYMENT_METHOD"
}

export type PayFromWalletInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  idempotencyKey: Scalars["String"];
  purposeId: Scalars["ID"];
  walletOwner: Scalars["AiloRN"];
};

export type PaymentAllocationInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  purposeId: Scalars["ID"];
};

export type PaymentLiabilityEntry = LiabilityEntry & {
  __typename?: "PaymentLiabilityEntry";
  amount: Money;
  businessTransaction: BusinessTransaction;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  /** @deprecated Use status in PaymentLiabilityEntry businessTransaction */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type PaymentMethod = {
  ailoRN: Scalars["AiloRN"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  topUpFee?: Maybe<TransactionFee>;
  wallet: Wallet;
};

export type PaymentMethodAiloFeeFilterEntryInput = {
  active: Scalars["Boolean"];
  endDate?: Maybe<Scalars["Date"]>;
  paymentMethodType: Scalars["String"];
  startDate?: Maybe<Scalars["Date"]>;
};

export type PaymentMethodAiloFees = {
  __typename?: "PaymentMethodAiloFees";
  appliesToId?: Maybe<Scalars["String"]>;
  feeBasisPoints?: Maybe<Scalars["Int"]>;
  feeFlatCents?: Maybe<Scalars["Int"]>;
  feeName?: Maybe<Scalars["String"]>;
  filterEntryEffectiveFromInclusive?: Maybe<Scalars["Date"]>;
  filterEntryEffectiveToInclusive?: Maybe<Scalars["Date"]>;
  filterName?: Maybe<Scalars["String"]>;
  filterNote?: Maybe<Scalars["String"]>;
  filterType?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  methodCategory?: Maybe<Scalars["String"]>;
  methodType: Scalars["String"];
};

export type PaymentMethodAiloFeeWaiverInput = {
  filterEntries?: Maybe<Array<Maybe<PaymentMethodAiloFeeFilterEntryInput>>>;
  notes?: Maybe<Scalars["String"]>;
  ownerLegalEntity: Scalars["AiloRN"];
};

export type PaymentMethodCompanion = {
  __typename?: "PaymentMethodCompanion";
  id: Scalars["AiloRN"];
  paymentMethod: PaymentMethod;
};

export type PaymentMethodDestination = {
  __typename?: "PaymentMethodDestination";
  paymentMethod: PaymentMethod;
  paymentMethodId: Scalars["ID"];
  share: Scalars["Int"];
};

export type PaymentMethodDestinationInput = {
  paymentMethodId: Scalars["ID"];
  share: Scalars["Int"];
};

export type PaymentMethodSearchOptions = {
  externalId?: Maybe<Scalars["String"]>;
  includeDeleted?: Maybe<Scalars["Boolean"]>;
  includeHidden?: Maybe<Scalars["Boolean"]>;
  includeOnceOff?: Maybe<Scalars["Boolean"]>;
  ownerAiloRN?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<PaymentMethodType>;
};

export enum PaymentMethodType {
  BPay = "BPay",
  BankAccount = "BankAccount",
  CreditCard = "CreditCard",
  VirtualAccount = "VirtualAccount"
}

export type PaymentReference = {
  __typename?: "PaymentReference";
  id: Scalars["ID"];
  supplier: Supplier;
  /**
   * Always present if supplier is external, otherwise empty.
   * @deprecated Use `PaymentReference.supplierPaymentMethodCompanion.ailoRN` instead.
   */
  supplierPaymentMethodReference?: Maybe<Scalars["AiloRN"]>;
  /**
   * Only present if it's an external supplier.
   * If it's an internal supplier, the bill will be paid to supplier's Ailo wallet.
   */
  supplierPaymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
  crn?: Maybe<Scalars["String"]>;
  paymentDescription?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
};

export type PaymentReferenceInput = {
  supplierId: Scalars["ID"];
  /** Required if supplier is external. */
  supplierPaymentMethodReference?: Maybe<Scalars["AiloRN"]>;
  crn?: Maybe<Scalars["String"]>;
  paymentDescription?: Maybe<Scalars["String"]>;
};

export type PaymentReferencesQueryConditions = {
  /** @deprecated Use `PaymentReferencesQueryConditions.payeeId` instead */
  supplierId?: Maybe<Scalars["ID"]>;
  payeeId?: Maybe<Scalars["AiloRN"]>;
  billPayerId?: Maybe<Scalars["AiloRN"]>;
};

export type Permission = {
  __typename?: "Permission";
  id: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type Person = LegalEntity & {
  __typename?: "Person";
  id: Scalars["ID"];
  ailoRN: Scalars["String"];
  eulaSignedAt?: Maybe<Scalars["DateTime"]>;
  organisationId: Scalars["ID"];
  organisation: Organisation;
  timezone: Scalars["TimeZone"];
  emailAddress?: Maybe<Scalars["String"]>;
  /**
   * Future email address that is currently in pending verification status.
   * Will be set to null once verification succeeds or expires.
   */
  pendingEmailAddress?: Maybe<Scalars["String"]>;
  phoneNo?: Maybe<Scalars["String"]>;
  /** Equal to `preferredName` if given, otherwise `legalFirstName`. */
  firstName: Scalars["String"];
  legalFirstName: Scalars["String"];
  legalMiddleName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  photo?: Maybe<File>;
  /**
   * Has KYC (Know Your Customer) verification check has been done for this person?
   * It is required for them to be eligible to transfer money out of Ailo platform.
   */
  kycVerified: Scalars["Boolean"];
  user?: Maybe<User>;
  occupation?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  taxFileNumber?: Maybe<Scalars["String"]>;
  signUpCompleted?: Maybe<Scalars["Boolean"]>;
  currentUserPermissions: Array<Scalars["String"]>;
  organisationMemberships: Array<OrganisationMembership>;
  /** Legal Entity Memberships in which this person is a member. */
  legalEntityMembershipsAsMember: Array<LegalEntityMembership>;
  /** @deprecated Use `Person.organisationMemberships`. */
  organisationRoles: Array<OrganisationPersonRole>;
  /** @deprecated Use `Person.legalEntityMembershipsAsMember`. */
  legalEntityRoles: Array<LegalEntityPersonRole>;
  /** Legal Entity Memberships in which this legal entity is the group of which the other people are members. */
  legalEntityMembershipsAsLegalEntity: Array<LegalEntityMembership>;
  phoneNoVerified: Scalars["Boolean"];
  /** Statements with subject = Legal Entity Ailorn */
  statements?: Maybe<PaginatedStatements>;
  tenancies?: Maybe<PaginatedTenancies>;
  managements?: Maybe<PaginatedManagements>;
  /** this can go when payment gateway splits */
  allWalletsAvailableBalance: Money;
  allWalletsTotalBalance: Money;
  kycVerifiedAccordingToLedgerService?: Maybe<Scalars["Boolean"]>;
  paymentMethods?: Maybe<PaginatedPaymentMethods>;
  wallets: Array<Wallet>;
  reiTokensForOrg: Array<ReiToken>;
  reiTemplatesForOrg: Array<ReiTemplate>;
  contact?: Maybe<Contact>;
};

export type PersonStatementsArgs = {
  filter: StatementFilter;
  cursor?: Maybe<StatementCursor>;
  sort?: Maybe<Array<StatementSort>>;
};

export type PersonTenanciesArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type PersonManagementsArgs = {
  managingOrganisationId?: Maybe<Scalars["AiloRN"]>;
  pageCursor: PmPageCursor;
};

export type PersonPaymentMethodsArgs = {
  methodType?: Maybe<PaymentMethodType>;
};

export type PersonReiTokensForOrgArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PersonReiTemplatesForOrgArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PersonContactArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type Plan = {
  __typename?: "Plan";
  amount: Money;
  /** @deprecated No longer needed. PM service also got rid of it. */
  endDate?: Maybe<Scalars["DateTime"]>;
  frequency: QuartzPlanFrequency;
  isExisting?: Maybe<Scalars["Boolean"]>;
  startDate: Scalars["DateTime"];
};

export type PlanBasedLiabilityEntry = LiabilityEntry & {
  __typename?: "PlanBasedLiabilityEntry";
  amount: Money;
  /** @deprecated Use `paidToDate` instead */
  classicPaidTo: Scalars["Date"];
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<ActionInitiator>;
  cycleEndDate: Scalars["Date"];
  cycleStartDate: Scalars["Date"];
  /** The date of the entry to be shown to consumers. This is the latest of createdAt and effectiveAt. */
  displayDate: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  entryType: SourceType;
  frequency?: Maybe<LiabilityPlanFrequency>;
  id: Scalars["ID"];
  liability: Liability;
  /** @deprecated Use `liability` */
  liabilityId: Scalars["ID"];
  /** The date the liability has been paid to after this entry took place. */
  paidToDate: Scalars["Date"];
  /** @deprecated Irrelevant to plan based liability entry */
  status?: Maybe<BusinessTxStatusEnum>;
};

export type PlatformFeature = {
  __typename?: "PlatformFeature";
  id: PlatformFeatureId;
  description?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
};

export enum PlatformFeatureId {
  AgencyAccess = "AgencyAccess",
  AgencyAppPropertyBills = "AgencyAppPropertyBills",
  AgencyAppPropertyFees = "AgencyAppPropertyFees",
  AgencyAppSupplierCreate = "AgencyAppSupplierCreate",
  AgencyAppViewConsumersMoneySettings = "AgencyAppViewConsumersMoneySettings",
  AgencyIncomeTotals = "AgencyIncomeTotals",
  AgencyInternalSuppliers = "AgencyInternalSuppliers",
  AgencyTransferStatement = "AgencyTransferStatement",
  AgencyWalletManualWithdrawal = "AgencyWalletManualWithdrawal",
  AllowFeatureOverride = "AllowFeatureOverride",
  AnalyticsDashboard = "AnalyticsDashboard",
  ApplyNewFee = "ApplyNewFee",
  ArchiveBillAsAgent = "ArchiveBillAsAgent",
  ArrearsListFromOwing = "ArrearsListFromOwing",
  AuthorizedHelpUrl = "AuthorizedHelpUrl",
  AutoPayBillsAsInvestor = "AutoPayBillsAsInvestor",
  AutoPayRent = "AutoPayRent",
  AutoWalletWithdraw = "AutoWalletWithdraw",
  AutoWithdrawPlan = "AutoWithdrawPlan",
  BillAttachments = "BillAttachments",
  BillManagementFee = "BillManagementFee",
  Bills = "Bills",
  BondsReport = "BondsReport",
  BypassRentLimit = "BypassRentLimit",
  CancelRecurringFee = "CancelRecurringFee",
  Centrepay = "Centrepay",
  Chat = "Chat",
  ChatDetailsWeb = "ChatDetailsWeb",
  ConsumerAccess = "ConsumerAccess",
  ConsumerAppKycCheck = "ConsumerAppKycCheck",
  ConsumerAppUserEmailChange = "ConsumerAppUserEmailChange",
  ConsumerProfileProperties = "ConsumerProfileProperties",
  ContactsTab = "ContactsTab",
  ContactsTabCustomers = "ContactsTabCustomers",
  ContactsTabOtherContacts = "ContactsTabOtherContacts",
  CreateAndViewPropertyKeys = "CreateAndViewPropertyKeys",
  CreateGeneralProject = "CreateGeneralProject",
  CreateLeaseRenewalProject = "CreateLeaseRenewalProject",
  CreateNewManagementProject = "CreateNewManagementProject",
  CreateNewTenancyProject = "CreateNewTenancyProject",
  CreateRoutineInspectionProject = "CreateRoutineInspectionProject",
  CreateVacatingTenancyProject = "CreateVacatingTenancyProject",
  CubejsReporting = "CubejsReporting",
  Customers = "Customers",
  DeletePropertyKeys = "DeletePropertyKeys",
  Deposits = "Deposits",
  Disbursements = "Disbursements",
  DisplayFormsTab = "DisplayFormsTab",
  EditApplyNewFee = "EditApplyNewFee",
  EditManagementEnd = "EditManagementEnd",
  EditManagementFee = "EditManagementFee",
  EditPropertyKeys = "EditPropertyKeys",
  EditRecurringFee = "EditRecurringFee",
  EditTenancyBondClaim = "EditTenancyBondClaim",
  EnableBillAutoPayLimit = "EnableBillAutoPayLimit",
  EndManagement = "EndManagement",
  EventBasedFee = "EventBasedFee",
  ExpensesCard = "ExpensesCard",
  FeeBlueprints = "FeeBlueprints",
  FeeFreeRent = "FeeFreeRent",
  FileUpload = "FileUpload",
  FilterBillByTeam = "FilterBillByTeam",
  ForceFileDownloadFlag = "ForceFileDownloadFlag",
  InAppReview = "InAppReview",
  InactiveChatEnhancements = "InactiveChatEnhancements",
  InvestorAccess = "InvestorAccess",
  InvestorFormerTenanciesCard = "InvestorFormerTenanciesCard",
  InvestorInternalSuppliers = "InvestorInternalSuppliers",
  InvestorPropertyWallet = "InvestorPropertyWallet",
  InvestorRentTab = "InvestorRentTab",
  LedgerRemake = "LedgerRemake",
  LiabilityPaymentPlan = "LiabilityPaymentPlan",
  LpmaAccess = "LpmaAccess",
  MailboxLinking = "MailboxLinking",
  ManageExpenseForm = "ManageExpenseForm",
  ManagementFeeBlueprint = "ManagementFeeBlueprint",
  ManagementPortfolios = "ManagementPortfolios",
  MetabaseReporting = "MetabaseReporting",
  MobileTeamChatsFilter = "MobileTeamChatsFilter",
  MoveManagementToFolio = "MoveManagementToFolio",
  NewConsumerAppNav = "NewConsumerAppNav",
  NewLostManagementsList = "NewLostManagementsList",
  NotificationPriming = "NotificationPriming",
  Onboarding = "Onboarding",
  OnboardingTabInPmApp = "OnboardingTabInPMApp",
  OnboardingTabPublishProperty = "OnboardingTabPublishProperty",
  OnboardingTabRefresh = "OnboardingTabRefresh",
  OneOffFeeBlueprints = "OneOffFeeBlueprints",
  OverdueListFromOwing = "OverdueListFromOwing",
  OwnershipStatementBillAndFeeDescription = "OwnershipStatementBillAndFeeDescription",
  OwnershipStatementInclBeforeGst = "OwnershipStatementInclBeforeGst",
  OwnershipStatementShowNextRentScheduleForNewProperties = "OwnershipStatementShowNextRentScheduleForNewProperties",
  PmJoinThread = "PmJoinThread",
  Projects = "Projects",
  Properties = "Properties",
  PropertyIssuesMissingFees = "PropertyIssuesMissingFees",
  PropertySearchFromSearchService = "PropertySearchFromSearchService",
  Receipting = "Receipting",
  RemoveManagementEnd = "RemoveManagementEnd",
  ReportingModalFilter = "ReportingModalFilter",
  ReportingTeamFilter = "ReportingTeamFilter",
  RevenueReport = "RevenueReport",
  SplitJointOwnershipStatement = "SplitJointOwnershipStatement",
  StatementsAddedToManagementFiles = "StatementsAddedToManagementFiles",
  TenancyBondClaim = "TenancyBondClaim",
  TenancyBonds = "TenancyBonds",
  TenancyDetailsDragAndDrop = "TenancyDetailsDragAndDrop",
  TenancyLedgerArrearsInHeader = "TenancyLedgerArrearsInHeader",
  TenancyLedgerAvoidOverlappingRentPeriod = "TenancyLedgerAvoidOverlappingRentPeriod",
  TenancyLedgerHeadersUseLedger = "TenancyLedgerHeadersUseLedger",
  TenancyLedgerLineItemsUseLedger = "TenancyLedgerLineItemsUseLedger",
  TenancyLedgerStatementDownload = "TenancyLedgerStatementDownload",
  TenantAccess = "TenantAccess",
  TransactionFees = "TransactionFees",
  TransferBillsDueWarning = "TransferBillsDueWarning",
  TransferDeposits = "TransferDeposits",
  TransferSummaryStatement = "TransferSummaryStatement",
  TruncateProjectList = "TruncateProjectList",
  TrustAccounting = "TrustAccounting",
  TrustReconciliation = "TrustReconciliation",
  ViewAgencyWallet = "ViewAgencyWallet",
  ViewAgencyWalletTransactions = "ViewAgencyWalletTransactions",
  ViewBillAsAgent = "ViewBillAsAgent",
  VoidIngoingTenancy = "VoidIngoingTenancy",
  WalletAccess = "WalletAccess",
  WalletStatementUserFacingDescription = "WalletStatementUserFacingDescription",
  WelcomeMessageParticipants = "WelcomeMessageParticipants",
  ZendeskWidget = "ZendeskWidget",
  ZendeskWidgetRestrictedForms = "ZendeskWidgetRestrictedForms"
}

export type PlatformPlan = {
  __typename?: "PlatformPlan";
  id: Scalars["String"];
  price: Money;
  product: PlatformProduct;
  features: Array<PlatformFeature>;
};

export type PlatformProduct = {
  __typename?: "PlatformProduct";
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type PlatformSubscription = {
  __typename?: "PlatformSubscription";
  id: Scalars["ID"];
  plan: PlatformPlan;
  startsOn: Scalars["Date"];
  endsOn?: Maybe<Scalars["Date"]>;
  customer?: Maybe<Organisation>;
};

export enum PMeFeeTriggerType {
  FirstRentReceipt = "FirstRentReceipt",
  Manual = "Manual",
  FirstReceiptPerStatement = "FirstReceiptPerStatement",
  InvoiceReceipt = "InvoiceReceipt",
  InspectionOnRoutine = "InspectionOnRoutine",
  InspectionOnEntry = "InspectionOnEntry",
  InspectionOnExit = "InspectionOnExit",
  BillCreated = "BillCreated"
}

export enum PMeFeeValueType {
  Fixed = "Fixed",
  Percent = "Percent"
}

export type PmPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
  sort?: Maybe<Scalars["String"]>;
};

export type PreviewedRentSchedule = {
  __typename?: "PreviewedRentSchedule";
  amountInCents: Scalars["String"];
  period: RentFrequency;
  startDate: Scalars["Date"];
  endDate: Scalars["Date"];
  proRata: Scalars["Boolean"];
};

export type PreviewMultipleRentReviewsInput = {
  rentFrequency: QuartzPlanFrequency;
  rentReviewDetails: Array<RentReviewInput>;
};

export type ProblemInterface = {
  message: Scalars["String"];
};

export type Property = {
  __typename?: "Property";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  address: Address;
  /**
   * Returns all agency properties associated with this property.
   * Throws if requested without admin read permissions.
   */
  agencyProperties: Array<AgencyProperty>;
  keys: Array<Key>;
  inspections: Array<Inspection>;
  inspectionAppointments: Array<InspectionAppointment>;
};

export type PropertyKeysArgs = {
  filter?: Maybe<KeyFilter>;
  sorting?: Maybe<Array<KeySort>>;
};

export type PropertyInspectionsArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PropertyInspectionAppointmentsArgs = {
  organisationAilorn: Scalars["AiloRN"];
};

export type PropertyInput = {
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  postcode?: Maybe<Scalars["String"]>;
};

export type ProposedPlan = {
  amount: MoneyInput;
  desiredStartDate: Scalars["DateTime"];
  frequency: QuartzPlanFrequency;
};

export type ProposedRent = {
  amountInCents: Scalars["String"];
  period: RentFrequency;
  effectiveDate: Scalars["Date"];
  setsChargeDate: Scalars["Boolean"];
};

export type Purpose = {
  __typename?: "Purpose";
  id: Scalars["ID"];
  payable?: Maybe<PurposeReference>;
  payableBy: Scalars["AiloRN"];
  payableTo: Scalars["AiloRN"];
  reference: Scalars["AiloRN"];
  type: PurposeType;
};

export type PurposeReference = Bill | RecurringOwing | TrustAccountWallet;

export enum PurposeType {
  AccountTransfer = "ACCOUNT_TRANSFER",
  Bill = "BILL",
  Deposit = "DEPOSIT",
  Disbursement = "DISBURSEMENT",
  Fee = "FEE",
  NegativeWalletBalance = "NEGATIVE_WALLET_BALANCE",
  Rent = "RENT"
}

export type PushToken = {
  __typename?: "PushToken";
  id: Scalars["Int"];
  token: Scalars["String"];
  userArn: Scalars["AiloRN"];
  registeredAt: Scalars["DateTime"];
  projectId?: Maybe<Scalars["String"]>;
};

export enum QuartzPlanFrequency {
  Daily = "daily",
  Fortnightly = "fortnightly",
  Monthly = "monthly",
  Weekly = "weekly"
}

export type ReceiptFundsInput = {
  allocations: Array<PaymentAllocationInput>;
  idempotencyKey: Scalars["String"];
  paidByReference?: Maybe<Scalars["String"]>;
  paidUsing?: Maybe<PaidUsingType>;
  trustAccount: Scalars["AiloRN"];
};

export type ReconciliationDetailReportItem = {
  __typename?: "ReconciliationDetailReportItem";
  businessTransactionId: Scalars["AiloRN"];
  categoryId: Scalars["Int"];
  fees?: Maybe<Money>;
  id: Scalars["Int"];
  paymentTransactionId?: Maybe<Scalars["AiloRN"]>;
  paymentType?: Maybe<Scalars["String"]>;
  requested?: Maybe<Money>;
  total: Money;
  transactionType?: Maybe<Scalars["String"]>;
};

export type ReconciliationReportItem = {
  __typename?: "ReconciliationReportItem";
  count: Scalars["Long"];
  fees?: Maybe<Money>;
  id?: Maybe<Scalars["Int"]>;
  paymentType?: Maybe<Scalars["String"]>;
  requested?: Maybe<Money>;
  total: Money;
  transactionType?: Maybe<Scalars["String"]>;
};

export type ReconciliationReportPeriod = {
  __typename?: "ReconciliationReportPeriod";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  month: Scalars["Int"];
  year: Scalars["Int"];
  locked: Scalars["Boolean"];
  trustAccountAilorn: Scalars["AiloRN"];
  trustAccount: TrustAccount;
  legalEntity?: Maybe<Company>;
  lastAddedStatementBalance?: Maybe<TrustAccountStatementBalance>;
  canAlterLock: Scalars["Boolean"];
  adjustmentSumInCents: Scalars["Long"];
  isReconciling: Scalars["Boolean"];
};

export type ReconciliationReportPeriodConnection = {
  __typename?: "ReconciliationReportPeriodConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<ReconciliationReportPeriodEdge>;
};

export type ReconciliationReportPeriodEdge = {
  __typename?: "ReconciliationReportPeriodEdge";
  /** The node containing the ReconciliationReportPeriod */
  node: ReconciliationReportPeriod;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type ReconciliationReportPeriodFilter = {
  and?: Maybe<Array<ReconciliationReportPeriodFilter>>;
  or?: Maybe<Array<ReconciliationReportPeriodFilter>>;
  id?: Maybe<IdFilterComparison>;
  month?: Maybe<IntFieldComparison>;
  year?: Maybe<IntFieldComparison>;
  locked?: Maybe<BooleanFieldComparison>;
  trustAccountAilorn?: Maybe<GraphQlAilornFilterComparison>;
};

export type ReconciliationReportPeriodSort = {
  field: ReconciliationReportPeriodSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum ReconciliationReportPeriodSortFields {
  Id = "id",
  Month = "month",
  Year = "year",
  Locked = "locked",
  TrustAccountAilorn = "trustAccountAilorn"
}

export type RecurringFee = {
  __typename?: "RecurringFee";
  /** @deprecated Use `idV2` or `ailoRN` instead. */
  id: Scalars["AiloRN"];
  idV2: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  name: Scalars["String"];
  /** Null only if the current user isn't authorized to access the management. (e.g. Tenant.) */
  managementAgreement?: Maybe<ManagementAgreement>;
  /** Null only if the current user isn't authorized to access the management. (e.g. Tenant.) */
  management?: Maybe<Management>;
  taxCategory: FeeTaxCategory;
  taxTreatment: TaxTreatment;
  frequency: FeeFrequency;
  startDate: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
  /** @deprecated Use `blueprintV2` instead. */
  blueprint: RecurringFeeBlueprint;
  blueprintV2: FeeBlueprint;
  schedules: Array<RecurringFeeSchedule>;
  /**
   * The most recent schedule that has already started (even if it has already ended due to fee being cancelled or management ending).
   * If none exist, return the schedule that will start next.
   */
  currentOrNextSchedule?: Maybe<RecurringFeeSchedule>;
  liability?: Maybe<Liability>;
  /** Whether local date is currently on or past the fee's final charge cycle */
  nextOccurrence?: Maybe<FeeOccurrence>;
  cancelled: Scalars["Boolean"];
  /**
   * True if the fee has been cancelled while it was still in the future,
   * or archived before it has been paid.
   *
   * An archived fee is archived forever;
   * and its' liabilities will also get archived.
   */
  archived: Scalars["Boolean"];
  currentness: DateRangeCurrentness;
  owing?: Maybe<RecurringOwing>;
};

export type RecurringFeeBlueprint = {
  __typename?: "RecurringFeeBlueprint";
  id: Scalars["AiloRN"];
  type: Scalars["String"];
  name: Scalars["String"];
  taxCategory: FeeTaxCategory;
  description?: Maybe<Scalars["String"]>;
  fixedAmount: Money;
  taxTreatment: TaxTreatment;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  frequency: FeeFrequency;
  frequencyFormatted: Scalars["String"];
  anniversaryDay: Scalars["Int"];
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  /** @deprecated Use `organisation.id` instead. */
  createdByOrganisation: Scalars["AiloRN"];
  /** @deprecated Use `organisation.id` instead. */
  organisationId: Scalars["AiloRN"];
  /** Always present. (Nullable only because it's a federated field.) */
  organisation?: Maybe<Organisation>;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
  archived: Scalars["Boolean"];
  archivedAt?: Maybe<Scalars["DateTime"]>;
  archivedBy?: Maybe<Scalars["AiloRN"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type RecurringFeeBlueprintInput = {
  name: Scalars["String"];
  taxCategoryId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  fixedAmount: MoneyInput;
  taxTreatment: TaxTreatment;
  frequency: FeeFrequency;
  anniversaryDay: Scalars["Int"];
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  organisationId: Scalars["AiloRN"];
};

export type RecurringFeeBlueprintsQueryConditions = {
  organisationId?: Maybe<Scalars["AiloRN"]>;
  archived?: Maybe<Scalars["Boolean"]>;
};

export type RecurringFeeBlueprintsQueryInput = {
  createdByOrganisation: Scalars["AiloRN"];
  archived?: Maybe<Scalars["Boolean"]>;
};

export enum RecurringFeeCurrentness {
  Past = "past",
  Current = "current",
  Future = "future"
}

export type RecurringFeeInput = {
  blueprintId: Scalars["AiloRN"];
  managementAgreementId: Scalars["AiloRN"];
  endDate?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  schedules: Array<RecurringFeeScheduleInput>;
};

export enum RecurringFeePaymentStatus {
  Overdue = "overdue",
  /**
   * Currently not overdue but may become overdue in the future
   * once the next occurrence starts
   */
  PaidForNow = "paidForNow",
  /**
   * Currently not overdue and fee has no more occurrences so it should
   * never become overdue again
   */
  PaidCompletely = "paidCompletely"
}

export type RecurringFeeSchedule = {
  __typename?: "RecurringFeeSchedule";
  id: Scalars["AiloRN"];
  feeId: Scalars["AiloRN"];
  taxAmount: Money;
  taxInclusiveAmount: Money;
  startDate: Scalars["String"];
  createdAt: Scalars["String"];
  createdBy: Scalars["AiloRN"];
};

export type RecurringFeeScheduleInput = {
  taxAmount: MoneyInput;
  taxInclusiveAmount: MoneyInput;
  startDate: Scalars["String"];
};

export type RecurringFeesQueryInput = {
  managementId?: Maybe<Scalars["AiloRN"]>;
  managementAgreementId?: Maybe<Scalars["AiloRN"]>;
  createdByOrganisation?: Maybe<Scalars["AiloRN"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  currentness?: Maybe<Array<RecurringFeeCurrentness>>;
  paymentStatus?: Maybe<Array<RecurringFeePaymentStatus>>;
};

export enum RecurringFrequency {
  Annually = "ANNUALLY",
  Fortnightly = "FORTNIGHTLY",
  Monthly = "MONTHLY",
  Onceoff = "ONCEOFF",
  Weekly = "WEEKLY"
}

export type RecurringOwing = {
  __typename?: "RecurringOwing";
  ailoRN: Scalars["AiloRN"];
  chargeCycles?: Maybe<PaginatedChargeCycles>;
  chargeSchedules: Array<ChargeSchedule>;
  id: Scalars["ID"];
  liability?: Maybe<Liability>;
  owedBy: Scalars["AiloRN"];
  owedTo: Scalars["AiloRN"];
  owingType: RecurringOwingType;
  progress: OwingProgress;
  ratePeriods: Array<RecurringOwingRatePeriod>;
  recurringOwingEvents?: Maybe<PaginatedRecurringOwingEvents>;
  reference: Scalars["AiloRN"];
  referencedEntity?: Maybe<OwingReferencedEntity>;
  taxCategory: Scalars["String"];
};

export type RecurringOwingChargeCyclesArgs = {
  pagination?: PaginationParams;
};

export type RecurringOwingRecurringOwingEventsArgs = {
  pagination?: PaginationParams;
};

export type RecurringOwingCalculationInput = {
  chargeSchedules: Array<ChargeScheduleInput>;
  ratePeriods: Array<RecurringOwingRatePeriodInput>;
};

export type RecurringOwingEvent = {
  __typename?: "RecurringOwingEvent";
  arrearsBeforeEntry?: Maybe<Arrears>;
  businessTransaction?: Maybe<BusinessTransaction>;
  causeReference: Scalars["AiloRN"];
  changeInOwedAmount: Money;
  changeInOwedTaxAmount: Money;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  effectiveAt: Scalars["DateTime"];
  emittedAt?: Maybe<Scalars["DateTime"]>;
  eventType: RecurringOwingEventType;
  id: Scalars["ID"];
  internalDescription: Scalars["String"];
  modifiedAt: Scalars["DateTime"];
  paidTo?: Maybe<PaidTo>;
  paidToBeforeEntry?: Maybe<PaidTo>;
  recurringOwing?: Maybe<RecurringOwing>;
  reversalOf?: Maybe<Scalars["AiloRN"]>;
};

export enum RecurringOwingEventType {
  Adjustment = "ADJUSTMENT",
  AdjustmentReversal = "ADJUSTMENT_REVERSAL",
  OwingChanged = "OWING_CHANGED",
  OwingDue = "OWING_DUE",
  Payment = "PAYMENT",
  PaymentFailure = "PAYMENT_FAILURE",
  PaymentReversal = "PAYMENT_REVERSAL",
  Refund = "REFUND",
  RefundFailure = "REFUND_FAILURE",
  RefundReversal = "REFUND_REVERSAL"
}

export type RecurringOwingInput = {
  chargeSchedules: Array<ChargeScheduleInput>;
  liabilityId?: Maybe<Scalars["AiloRN"]>;
  managingEntities?: Maybe<Array<Scalars["AiloRN"]>>;
  owedBy: Scalars["AiloRN"];
  owedTo: Scalars["AiloRN"];
  owingType: RecurringOwingType;
  ratePeriods: Array<RecurringOwingRatePeriodInput>;
  reference: Scalars["AiloRN"];
  relatedToManagement?: Maybe<Scalars["AiloRN"]>;
  taxCategory: Scalars["String"];
  timezone: Scalars["String"];
};

export type RecurringOwingRatePeriod = {
  __typename?: "RecurringOwingRatePeriod";
  amount: Money;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: RecurringFrequency;
  startDate: Scalars["Date"];
  taxAmount: Money;
};

export type RecurringOwingRatePeriodInput = {
  amount: MoneyInput;
  endDate?: Maybe<Scalars["Date"]>;
  frequency: RecurringFrequency;
  startDate: Scalars["Date"];
  taxAmount?: Maybe<MoneyInput>;
};

export enum RecurringOwingType {
  Fee = "FEE",
  Rent = "RENT"
}

export type RefundOutput = {
  __typename?: "RefundOutput";
  businessTransaction: BusinessTransaction;
  businessTransactionStatus: BusinessTransactionStatus;
};

export type ReiForm = Form & {
  __typename?: "ReiForm";
  id: Scalars["ID"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  organisation: Organisation;
  supportedState: AustralianState;
  reiFormInfo: ReiFormInfo;
  viewUrl: Scalars["String"];
  downloadUrl: Scalars["String"];
  remoteSigningPDF: Scalars["Binary"];
  ailorn: Scalars["AiloRN"];
  remoteSigningStatus: ReiFormRemoteSigningStatus;
};

export enum ReiFormErrorCode {
  /** The form is not found in REI system, perhaps it has been deleted */
  ReiFormNotFound = "REI_FORM_NOT_FOUND",
  /** REI doesn't allow to get the form with that ID */
  ReiUnauthorised = "REI_UNAUTHORISED",
  /** The linked account and the form state don't match */
  ReiTokenFormStatesMismatch = "REI_TOKEN_FORM_STATES_MISMATCH"
}

export type ReiFormInfo = {
  __typename?: "ReiFormInfo";
  name: Scalars["String"];
  finalised: Scalars["Boolean"];
};

export type ReiFormRemoteSigner = {
  __typename?: "ReiFormRemoteSigner";
  name: Scalars["String"];
  /** Sign status */
  status: ReiFormRemoteSignerStatus;
  /** Date if signed */
  signed?: Maybe<Scalars["DateTime"]>;
};

export enum ReiFormRemoteSignerStatus {
  /** The form is waiting for the signer to sign */
  Pending = "PENDING",
  /** The signer has opened the form in Docusign */
  Delivered = "DELIVERED",
  /** The signer has declined to sign a form */
  Declined = "DECLINED",
  /** The signer has completed signing a form */
  Completed = "COMPLETED"
}

export type ReiFormRemoteSigningStatus = {
  __typename?: "ReiFormRemoteSigningStatus";
  status: SigningStatus;
  /** Date if sent */
  sent?: Maybe<Scalars["DateTime"]>;
  /** List of signers with names and signed dates if any */
  recipients: Array<ReiFormRemoteSigner>;
};

export type ReiTemplate = {
  __typename?: "ReiTemplate";
  id: Scalars["Int"];
  name: Scalars["String"];
  code: Scalars["String"];
};

export type ReiToken = {
  __typename?: "ReiToken";
  id: Scalars["ID"];
  token: Scalars["String"];
  email: Scalars["String"];
  supportedState: AustralianState;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  person: Person;
  organisation: Organisation;
};

export type RelayPageInfo = {
  __typename?: "RelayPageInfo";
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars["Boolean"]>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars["Boolean"]>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars["ConnectionCursor"]>;
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars["ConnectionCursor"]>;
};

/** Sort Directions */
export enum RelaySortDirection {
  Asc = "ASC",
  Desc = "DESC"
}

export type RemoveInspectionAreaFileResult = {
  __typename?: "RemoveInspectionAreaFileResult";
  inspectionAreaFile: InspectionAreaFile;
};

export type RemoveInspectionAreaResult = {
  __typename?: "RemoveInspectionAreaResult";
  inspectionArea: InspectionArea;
};

export type RemoveInspectionFeatureFileResult = {
  __typename?: "RemoveInspectionFeatureFileResult";
  inspectionFeatureFile: InspectionFeatureFile;
};

export type RemoveInspectionFeatureResult = {
  __typename?: "RemoveInspectionFeatureResult";
  inspectionFeature: InspectionFeature;
};

export type RemoveInspectionResult = {
  __typename?: "RemoveInspectionResult";
  inspection: Inspection;
};

export type RemoveMemberFromLegalEntityInput = {
  memberPersonId: Scalars["String"];
  legalEntityId: Scalars["String"];
  roleId: Scalars["String"];
};

export type RemoveMemberFromOrganisationInput = {
  personId: Scalars["String"];
  organisationId: Scalars["String"];
  roleId: Scalars["String"];
};

export type RemoveReiTokenInput = {
  /** ID of the REI Token to be deleted */
  id: Scalars["String"];
  /** AiloRN of person the token is associated with */
  personAilorn: Scalars["AiloRN"];
  /** AiloRN of agency organisation */
  organisationAilorn: Scalars["AiloRN"];
};

export type RemoveTenantshipInput = {
  tenancyId: Scalars["ID"];
  tenantId: Scalars["ID"];
};

export type RemoveTrustAccountReconciliationAdjustmentInput = {
  id: Scalars["ID"];
  removedReason: Scalars["String"];
  removeFromReportPeriodId: Scalars["ID"];
};

export type Rent = {
  __typename?: "Rent";
  /** @deprecated Use rent.ailorn instead. This field will eventually be changed to type ID */
  id: Scalars["AiloRN"];
  ailorn: Scalars["AiloRN"];
  amountInCents: Scalars["String"];
  /** In cents with double-precision */
  dailyRate: Scalars["Float"];
  period: RentFrequency;
  effectiveDate: Scalars["Date"];
  rentIncreaseInCents: Scalars["String"];
  setsChargeDate: Scalars["Boolean"];
  category: RentCategory;
  previousRent?: Maybe<Rent>;
  tenancy?: Maybe<Tenancy>;
  rentSchedules: Array<RentSchedule>;
  proRataSchedulesForRentUpdate: Array<PreviewedRentSchedule>;
};

export type RentProRataSchedulesForRentUpdateArgs = {
  proposedRent: ProposedRent;
};

export enum RentCategory {
  Legacy = "Legacy",
  Support = "Support",
  Inherited = "Inherited",
  RentReview = "RentReview",
  LeaseRenewal = "LeaseRenewal",
  NewTenancy = "NewTenancy"
}

export type RentCreditDetailsInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  tenancyId: Scalars["ID"];
  effectiveDate: Scalars["Date"];
  idempotencyKey: Scalars["String"];
  notifyTenants?: Scalars["Boolean"];
  notifyInvestors?: Scalars["Boolean"];
};

export type RentCreditDetailsPayload = {
  __typename?: "RentCreditDetailsPayload";
  rentCredit?: Maybe<AdjustmentLiabilityEntry>;
  tenancy?: Maybe<Tenancy>;
  query: Query;
};

export enum RentFrequency {
  Daily = "daily",
  Weekly = "weekly",
  Fortnightly = "fortnightly",
  Monthly = "monthly"
}

export type RentPageCursor = {
  pageSize?: Scalars["Int"];
  cursor?: Maybe<Scalars["String"]>;
  paginateBackward?: Scalars["Boolean"];
};

export type RentReviewDetails = {
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  scheduledRentReviewDate: Scalars["Date"];
  period: RentFrequency;
};

export type RentReviewInput = {
  amount: MoneyInput;
  effectiveDate: Scalars["Date"];
};

export type RentReviewPlan = {
  __typename?: "RentReviewPlan";
  amount: Money;
  description?: Maybe<Scalars["String"]>;
  effectiveFrom: Scalars["Date"];
  proRata: Scalars["Boolean"];
};

export type RentReviewProposedPlanInput = {
  amount: MoneyInput;
  desiredStartFrom: Scalars["Date"];
  liabilityRef: Scalars["AiloRN"];
};

export type RentSchedule = {
  __typename?: "RentSchedule";
  id: Scalars["AiloRN"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  startDate: Scalars["Date"];
  proRata: Scalars["Boolean"];
};

export type RentSetupInput = {
  effectiveDate: Scalars["Date"];
  amountInCents: Scalars["String"];
  period: RentFrequency;
  category: RentCategory;
};

export enum RentSort {
  EffectiveDateAsc = "EFFECTIVE_DATE_ASC",
  EffectiveDateDesc = "EFFECTIVE_DATE_DESC"
}

export type ReportGenerationState = {
  __typename?: "ReportGenerationState";
  /** Returns true if an ongoing report progress does not exist */
  canGenerateReport: Scalars["Boolean"];
};

export type ReportPeriodByDateInput = {
  month: Scalars["Int"];
  year: Scalars["Int"];
  trustAccountAilorn: Scalars["AiloRN"];
};

export type ReportPeriodLockChangeResult = {
  __typename?: "ReportPeriodLockChangeResult";
  status: ReportPeriodLockChangeResultStatus;
  failureReason?: Maybe<Scalars["String"]>;
  report?: Maybe<ReconciliationReportPeriod>;
};

export enum ReportPeriodLockChangeResultStatus {
  Success = "SUCCESS",
  Failure = "FAILURE"
}

export type ResponseStatus = {
  __typename?: "ResponseStatus";
  businessTransaction?: Maybe<BusinessTransaction>;
  businessTransactionId?: Maybe<Scalars["AiloRN"]>;
  error?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
};

export type ReverseAdjustmentInput = {
  adjustmentId: Scalars["ID"];
  description: Scalars["String"];
};

export type RevokeMailboxAccessInput = {
  mailboxAccessAilorn: Scalars["AiloRN"];
};

export type Role = {
  id: Scalars["String"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  domain: Scalars["String"];
  permissions: Array<Permission>;
};

export type RolesQueryConditions = {
  type?: Maybe<RoleType>;
};

export enum RoleType {
  OrganisationRole = "OrganisationRole",
  LegalEntityRole = "LegalEntityRole"
}

export type SagaStatusResponse = {
  __typename?: "SagaStatusResponse";
  error?: Maybe<Scalars["String"]>;
  idempotencyKey: Scalars["String"];
  sagaId?: Maybe<Scalars["ID"]>;
  status: Scalars["String"];
};

export type SearchAgencyPropertiesQueryInput = {
  managingEntityOrgAilorn?: Maybe<Scalars["AiloRN"]>;
  query: Scalars["String"];
  /**
   * Cursor-based pagination in Elasticsearch can only support forward way based on "scroll_id" or "search_after", details: https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#scroll-search-results
   * Current cursor-based implementation is based on "scroll_id".
   * If page param (must be positive, i.e., >=1) is provided, then it will process query by using "from" (similar like offset based query in postgres), details:  https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#paginate-search-results
   * Note: By default, you cannot use from and size to page through more than 10,000 hits. This limit is a safeguard set by the index.max_result_window index setting. If you need to page through more than 10,000 hits, use the search_after parameter instead.
   */
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export type SearchFilter = {
  key?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type SearchTenanciesQueryInput = {
  filters?: Maybe<TenanciesQueryFilterInput>;
  sort?: Maybe<Array<TenanciesSortParams>>;
  /**
   * Cursor-based pagination in Elasticsearch can only support forward way based on "scroll_id" or "search_after", details: https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#scroll-search-results
   * Current cursor-based implementation is based on "scroll_id".
   * If page param (must be positive, i.e., >=1) is provided, then it will process query by using "from" (similar like offset based query in postgres), details:  https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#paginate-search-results
   * Note: By default, you cannot use from and size to page through more than 10,000 hits. This limit is a safeguard set by the index.max_result_window index setting. If you need to page through more than 10,000 hits, use the search_after parameter instead.
   */
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export type SearchTrustAccountWalletsQueryInput = {
  trustAccountOwnerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
  query?: Maybe<Scalars["String"]>;
  isTenancyWallet?: Maybe<Scalars["Boolean"]>;
  /**
   * Cursor-based pagination in Elasticsearch can only support forward way based on "scroll_id" or "search_after", details: https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#scroll-search-results
   * Current cursor-based implementation is based on "scroll_id".
   * If page param (must be positive, i.e., >=1) is provided, then it will process query by using "from" (similar like offset based query in postgres), details:  https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html#paginate-search-results
   * Note: By default, you cannot use from and size to page through more than 10,000 hits. This limit is a safeguard set by the index.max_result_window index setting. If you need to page through more than 10,000 hits, use the search_after parameter instead.
   */
  paginationParams?: Maybe<PaginationParamsWithPageNumber>;
};

export enum SendEmailVerificationErrorCode {
  EmailAlreadyVerified = "EMAIL_ALREADY_VERIFIED",
  EmailUsedByAnotherPerson = "EMAIL_USED_BY_ANOTHER_PERSON"
}

export type SendEmailVerificationErrorResponse = {
  __typename?: "SendEmailVerificationErrorResponse";
  errorCode: SendEmailVerificationErrorCode;
};

export type SendEmailVerificationResponse =
  | SendEmailVerificationSuccessResponse
  | SendEmailVerificationErrorResponse;

export type SendEmailVerificationSuccessResponse = {
  __typename?: "SendEmailVerificationSuccessResponse";
  ok: Scalars["Boolean"];
};

export type SendMailInput = {
  sendFromMailboxAccessAilorn: Scalars["AiloRN"];
  toContactAilorns: Array<Scalars["AiloRN"]>;
  replyToMailThreadAilorn?: Maybe<Scalars["AiloRN"]>;
  subject: Scalars["String"];
  /** Plain text email body, will be formatted with generic styling before sending. */
  plaintextBody: Scalars["String"];
  attachmentFileAilorns: Array<Scalars["AiloRN"]>;
};

export type SendMessageInputV2 = {
  message: MessageInputV2;
  threadAilorn: Scalars["AiloRN"];
};

export type SendPhoneVerificationCodeResponse = {
  __typename?: "SendPhoneVerificationCodeResponse";
  ok: Scalars["Boolean"];
  error?: Maybe<SendPhoneVerificationCodeResponseError>;
};

export type SendPhoneVerificationCodeResponseError = {
  __typename?: "SendPhoneVerificationCodeResponseError";
  /**
   * Possible values:
   * - "internal-server-error"
   * - "phone-number-already-verified"
   * - "invalid-phone-number"
   * - "max-verification-check-attempts-reached"
   * - "max-send-attempts-reached"
   * - "sms-is-not-supported-by-landline-phone-number"
   * - "too-many-concurrent-requests-for-phone-number"
   */
  code: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
};

export type SetRoutineInspectionDueDateInput = {
  tenancyAilorn: Scalars["AiloRN"];
  dueDate: Scalars["Date"];
};

export type SetRoutineInspectionDueDateResult = {
  __typename?: "SetRoutineInspectionDueDateResult";
  tenancy: Tenancy;
};

export type SetupResult = {
  __typename?: "SetupResult";
  management?: Maybe<Management>;
  tenancy?: Maybe<Tenancy>;
};

export enum SigningStatus {
  /** The REI account is not linked with DocuSign yet */
  NotAvailable = "NOT_AVAILABLE",
  /** The form is waiting to be sent */
  Pending = "PENDING",
  /** The form has been sent to its signers */
  Sent = "SENT",
  /** All the signers have signed the form */
  Completed = "COMPLETED",
  /** One of the form signers has declined to sign */
  Declined = "DECLINED",
  /** REI returned an unknown status */
  Unknown = "UNKNOWN"
}

export enum SortDirection {
  Asc = "Asc",
  Desc = "Desc"
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = "NULLS_FIRST",
  NullsLast = "NULLS_LAST"
}

export enum SourceType {
  Adjustment = "ADJUSTMENT",
  Payment = "PAYMENT",
  Planbased = "PLANBASED"
}

export type Statement = {
  __typename?: "Statement";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  file?: Maybe<File>;
  billsZipFile?: Maybe<File>;
  statementSubject: Scalars["AiloRN"];
  subject?: Maybe<StatementSubject>;
  type: StatementType;
  rangeStartDate: Scalars["Date"];
  rangeEndDate: Scalars["Date"];
  createdFor: StatementCreatedFor;
  statementValues?: Maybe<Scalars["String"]>;
  statementProgress?: Maybe<StatementProgress>;
};

export type StatementAdjustmentLineItem = StatementLineItem2 & {
  __typename?: "StatementAdjustmentLineItem";
  adjustmentAmount: Money;
  clearedDate?: Maybe<Scalars["Date"]>;
  description?: Maybe<Scalars["String"]>;
  effectivePaidTo?: Maybe<Scalars["Date"]>;
  knowledgedDate: Scalars["Date"];
  liability?: Maybe<Liability>;
  liabilityCategory?: Maybe<Scalars["String"]>;
  management?: Maybe<Management>;
  sourceId: Scalars["ID"];
  sourceType: StatementLineItemSourceType;
  statementTransactionDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `statementTransactionDate` */
  statementTransactionDateTime?: Maybe<Scalars["DateTime"]>;
  walletId: Scalars["ID"];
};

export type StatementByAccountInput = {
  account: Scalars["AiloRN"];
  from: Scalars["Date"];
  to: Scalars["Date"];
  zoneId: Scalars["ZoneId"];
};

export enum StatementCreatedFor {
  ScheduledJob = "ScheduledJob",
  ManualRequest = "ManualRequest",
  Disbursement = "Disbursement"
}

export type StatementCursor = {
  pageSize?: Maybe<Scalars["Int"]>;
  paginateBackward?: Maybe<Scalars["Boolean"]>;
  cursor?: Maybe<Scalars["String"]>;
};

export type StatementFilter = {
  statementTypes?: Maybe<Array<StatementType>>;
  createdFor?: Maybe<Array<StatementCreatedFor>>;
};

export type StatementLineItem = {
  __typename?: "StatementLineItem";
  account: Scalars["AiloRN"];
  amount: Money;
  businessTransactionType?: Maybe<Scalars["String"]>;
  category: StatementLineItemCategoryType;
  description?: Maybe<Scalars["String"]>;
  effectivePaidTo?: Maybe<Scalars["Date"]>;
  gst: Money;
  knowledgedDate: Scalars["Date"];
  liability: Liability;
  sourceType: StatementLineItemSourceType;
};

export type StatementLineItem2 = {
  clearedDate?: Maybe<Scalars["Date"]>;
  knowledgedDate: Scalars["Date"];
  liability?: Maybe<Liability>;
  liabilityCategory?: Maybe<Scalars["String"]>;
  management?: Maybe<Management>;
  sourceId: Scalars["ID"];
  sourceType: StatementLineItemSourceType;
  statementTransactionDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `statementTransactionDate` */
  statementTransactionDateTime?: Maybe<Scalars["DateTime"]>;
  walletId: Scalars["ID"];
};

export enum StatementLineItemCategoryType {
  Expense = "EXPENSE",
  Income = "INCOME"
}

export enum StatementLineItemSourceType {
  AdjustmentEntry = "AdjustmentEntry",
  StatementEntry = "StatementEntry"
}

export type StatementProgress = {
  __typename?: "StatementProgress";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  statement?: Maybe<Statement>;
  statementSubject?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<StatementType>;
  rangeStartDate?: Maybe<Scalars["Date"]>;
  rangeEndDate?: Maybe<Scalars["Date"]>;
  createdFor?: Maybe<StatementCreatedFor>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  isSuccessful?: Maybe<Scalars["Boolean"]>;
  skipValidation?: Maybe<Scalars["Boolean"]>;
  skipManagementHasPaymentsCheck?: Maybe<Scalars["Boolean"]>;
  allowUnrecognisedTransactionLineItems?: Maybe<Scalars["Boolean"]>;
  linkFileToSubject?: Maybe<Scalars["Boolean"]>;
  dryRunEmail?: Maybe<Scalars["String"]>;
  sendEmail?: Maybe<Scalars["Boolean"]>;
  overrideStatementValues?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  attemptNo?: Maybe<Scalars["Int"]>;
  rangeStartBusinessTxId?: Maybe<Scalars["String"]>;
  rangeEndBusinessTxId?: Maybe<Scalars["String"]>;
  subject?: Maybe<StatementSubject>;
};

export type StatementProgressCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
};

export type StatementProgressFilter = {
  sendEmail?: Maybe<Scalars["Boolean"]>;
  statementSubject?: Maybe<Scalars["AiloRN"]>;
  type?: Maybe<StatementType>;
  types?: Maybe<Array<StatementType>>;
};

export enum StatementSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  RangeStartDateAsc = "RANGE_START_DATE_ASC",
  RangeStartDateDesc = "RANGE_START_DATE_DESC",
  RangeEndDateAsc = "RANGE_END_DATE_ASC",
  RangeEndDateDesc = "RANGE_END_DATE_DESC"
}

export type StatementSubject = LegalEntityCompanion | Management | Tenancy;

export type StatementTransactionLineItem = StatementLineItem2 & {
  __typename?: "StatementTransactionLineItem";
  btAmount: Money;
  btCreatedAt: Scalars["DateTime"];
  btStatusCreatedAt: Scalars["DateTime"];
  businessTransactionId: Scalars["ID"];
  businessTransactionStatus: Scalars["String"];
  businessTransactionType?: Maybe<Scalars["String"]>;
  clearedDate?: Maybe<Scalars["Date"]>;
  effectivePaidTo?: Maybe<Scalars["Date"]>;
  gst: Money;
  knowledgedDate: Scalars["Date"];
  liability?: Maybe<Liability>;
  liabilityCategory?: Maybe<Scalars["String"]>;
  management?: Maybe<Management>;
  parentBusinessTransactionId?: Maybe<Scalars["ID"]>;
  reversalBusinessTransactionType?: Maybe<Scalars["String"]>;
  reversalOfBusinessTransactionId?: Maybe<Scalars["ID"]>;
  serviceFeeAmount: Money;
  sourceId: Scalars["ID"];
  sourcePaymentMethodMaskedDetails?: Maybe<Scalars["String"]>;
  sourcePaymentMethodType?: Maybe<Scalars["String"]>;
  sourceType: StatementLineItemSourceType;
  sourceWalletId?: Maybe<Scalars["ID"]>;
  sourceWalletOwner?: Maybe<LegalEntityCompanion>;
  sourceWalletOwnerReference?: Maybe<Scalars["AiloRN"]>;
  sourceWalletType?: Maybe<StatementWalletType>;
  statementTransactionDate?: Maybe<Scalars["Date"]>;
  /** @deprecated Use `statementTransactionDate` */
  statementTransactionDateTime?: Maybe<Scalars["DateTime"]>;
  targetPaymentMethodMaskedDetails?: Maybe<Scalars["String"]>;
  targetPaymentMethodType?: Maybe<Scalars["String"]>;
  targetWalletId?: Maybe<Scalars["ID"]>;
  targetWalletOwner?: Maybe<LegalEntityCompanion>;
  targetWalletOwnerReference?: Maybe<Scalars["AiloRN"]>;
  targetWalletType?: Maybe<StatementWalletType>;
  taxCategory?: Maybe<Scalars["String"]>;
  userFacingDescription?: Maybe<Scalars["String"]>;
  walletAvailableBalance: Money;
  walletChangeAmount: Money;
  walletId: Scalars["ID"];
  walletOverdraftAllowance: Money;
  walletStatementRunningBalance?: Maybe<Money>;
  walletTotalBalance: Money;
};

export enum StatementType {
  AgencyTransfer = "AgencyTransfer",
  Management = "Management",
  TaxSummary = "TaxSummary",
  TenancyLedger = "TenancyLedger",
  TransferSummary = "TransferSummary"
}

export enum StatementWalletType {
  AiloBank = "AILO_BANK",
  AiloTrading = "AILO_TRADING",
  AiloWriteOff = "AILO_WRITE_OFF",
  ManagementFolio = "MANAGEMENT_FOLIO",
  PaymentMethod = "PAYMENT_METHOD",
  Personal = "PERSONAL",
  Tenancy = "TENANCY",
  Unknown = "UNKNOWN"
}

export type Supplier = {
  __typename?: "Supplier";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  registeredEntityName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  abn?: Maybe<Scalars["String"]>;
  /**
   * String containing entire address. Will be removed in the future after migrating
   * this field to the new address fields.
   */
  businessAddress?: Maybe<Scalars["String"]>;
  address?: Maybe<SupplierAddress>;
  emailAddress?: Maybe<Scalars["String"]>;
  organisationReference: Scalars["AiloRN"];
  organisation?: Maybe<Organisation>;
  createdAt: Scalars["DateTime"];
  /**
   * If null it means the bill has been created by Ailo system.
   * @deprecated Use `Supplier.createdByV2` instead
   */
  createdBy?: Maybe<User>;
  createdByV2?: Maybe<ActionInitiator>;
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  modifiedBy?: Maybe<ActionInitiator>;
  archivedAt?: Maybe<Scalars["DateTime"]>;
  /** @deprecated Use `Supplier.archivedByV2` instead */
  archivedBy?: Maybe<Scalars["AiloRN"]>;
  archivedByV2?: Maybe<ActionInitiator>;
  /** @deprecated Use `Bill.payee` instead */
  internalReference?: Maybe<Scalars["AiloRN"]>;
  /** @deprecated Use `Bill.payee` instead */
  internalLegalEntity?: Maybe<LegalEntityCompanion>;
  paymentMethods: Array<Maybe<PaymentMethodCompanion>>;
  kycDataComplete: Scalars["Boolean"];
};

export type SupplierAddress = {
  __typename?: "SupplierAddress";
  streetName?: Maybe<Scalars["String"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type SupplierAddressInput = {
  streetName?: Maybe<Scalars["String"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type SupplierBankAccountInput = {
  description?: Maybe<Scalars["String"]>;
  bsb: Scalars["String"];
  accountNumber: Scalars["String"];
  accountName: Scalars["String"];
  onceOff?: Maybe<Scalars["Boolean"]>;
};

export type SupplierBPayInput = {
  description?: Maybe<Scalars["String"]>;
  billerCode: Scalars["String"];
};

export type SupplierCreditCardInputV2 = {
  description?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
  type: Scalars["String"];
  category: Scalars["String"];
  number: Scalars["String"];
  expiry: Scalars["String"];
};

export type SupplierPaymentMethodInput = {
  creditCardInput?: Maybe<SupplierCreditCardInputV2>;
  bankAccountInput?: Maybe<SupplierBankAccountInput>;
  bpayInput?: Maybe<SupplierBPayInput>;
};

export type SuppliersQueryInput = {
  registeredEntityName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  registeredEntityNameLike?: Maybe<Scalars["String"]>;
  nameLike?: Maybe<Scalars["String"]>;
  abnLike?: Maybe<Scalars["String"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  kycDataComplete?: Maybe<Scalars["Boolean"]>;
  organisationReference?: Maybe<Scalars["AiloRN"]>;
};

export type SyncLegalEntityInput = {
  legalEntityAilorn: Scalars["AiloRN"];
};

export type System = {
  __typename?: "System";
  ailoRN: Scalars["AiloRN"];
};

export type TaxableAmount = {
  __typename?: "TaxableAmount";
  tax: Money;
  total: Money;
};

export type TaxCategory = {
  __typename?: "TaxCategory";
  id: Scalars["String"];
  name: Scalars["String"];
  createdAt: Scalars["DateTime"];
};

export type TaxSummaryEntry = {
  __typename?: "TaxSummaryEntry";
  taxCategory: Scalars["String"];
  totalAiloFees: Money;
  totalAmount: Money;
  totalGST: Money;
};

export type TaxSummaryStatementData = {
  __typename?: "TaxSummaryStatementData";
  expenseTransactions: Array<TaxSummaryTransactionEntry>;
  expenses: Array<TaxSummaryEntry>;
  income: Array<TaxSummaryEntry>;
  incomeTransactions: Array<TaxSummaryTransactionEntry>;
};

export type TaxSummaryStatementInput = {
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
  walletOwnerReference: Scalars["AiloRN"];
  zoneId: Scalars["ZoneId"];
};

export type TaxSummaryTransactionEntry = {
  __typename?: "TaxSummaryTransactionEntry";
  amount: Money;
  bill?: Maybe<Bill>;
  feeAmount: Money;
  gstAmount: Money;
  liabilityReference?: Maybe<Scalars["AiloRN"]>;
  taxCategory: Scalars["String"];
  transactionClearedAt?: Maybe<Scalars["DateTime"]>;
  transactionCreatedAt: Scalars["DateTime"];
};

export enum TaxTreatment {
  Inclusive = "inclusive",
  Exclusive = "exclusive",
  NoTax = "noTax"
}

export type TeamFilterInput = {
  ailornIn?: Maybe<Array<Scalars["AiloRN"]>>;
  includeUnassigned?: Maybe<Scalars["Boolean"]>;
};

export type TenanciesQueryFilterInput = {
  query?: Maybe<Scalars["String"]>;
  team?: Maybe<TeamFilterInput>;
  managingEntityOrgAilorn?: Maybe<Scalars["AiloRN"]>;
  excludeVoided?: Maybe<Scalars["Boolean"]>;
  excludeVacated?: Maybe<Scalars["Boolean"]>;
  excludeWithPendingRoutineAppointment?: Maybe<Scalars["Boolean"]>;
  excludeOffboarded?: Maybe<Scalars["Boolean"]>;
};

export enum TenanciesSortField {
  RoutineInspectionDueDate = "RoutineInspectionDueDate"
}

export type TenanciesSortParams = {
  field: TenanciesSortField;
  direction: SortDirection;
};

export type Tenancy = {
  __typename?: "Tenancy";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
  voidedAt?: Maybe<Scalars["Date"]>;
  vacatingReason?: Maybe<VacatingReason>;
  vacatingNotes?: Maybe<Scalars["String"]>;
  canCancelVacate?: Maybe<CancelVacateAbility>;
  isVoidable: VoidTenancyAbility;
  /** Date which the another rent review should be performed */
  scheduledRentReviewDate?: Maybe<Scalars["Date"]>;
  validVacateEndDateRange?: Maybe<DateRange>;
  /** @deprecated Use `leaseReviewAllowedOperation` instead */
  leaseReviewAllowed?: Maybe<Scalars["Boolean"]>;
  leaseReviewAllowedOperation?: Maybe<AllowedOperations>;
  agencyProperty: AgencyProperty;
  /**
   * returns the first to exist of currentRent, nextRent, mostRecentRent
   * in that order. Represents the rent users are most likely
   * interested in.
   */
  displayRent?: Maybe<Rent>;
  currentRent?: Maybe<Rent>;
  nextRent?: Maybe<Rent>;
  nextRentReview?: Maybe<Rent>;
  mostRecentRentReview?: Maybe<Rent>;
  rents: PaginatedRents;
  property: Property;
  currentRentSchedule?: Maybe<RentSchedule>;
  nextRentSchedule?: Maybe<RentSchedule>;
  proRataSchedulesForNewRent: Array<PreviewedRentSchedule>;
  tenancyAgreements?: Maybe<PaginatedTenancyAgreements>;
  mostRecentTenancyAgreement?: Maybe<TenancyAgreement>;
  nextTenancyAgreement?: Maybe<TenancyAgreement>;
  latestTenancyAgreement?: Maybe<TenancyAgreement>;
  rentReviewAllowed?: Maybe<Scalars["Boolean"]>;
  editableRentReview?: Maybe<EditableRentReviewPayload>;
  bond?: Maybe<TenancyBond>;
  files?: Maybe<PaginatedFiles>;
  management: Management;
  liability?: Maybe<Liability>;
  managementAiloRN: Scalars["AiloRN"];
  managementFolioAilorn: Scalars["AiloRN"];
  managingEntity?: Maybe<Company>;
  tenantships: PaginatedTenantships;
  deposit?: Maybe<TenancyDeposit>;
  rent?: Maybe<RecurringOwing>;
  inspections: Array<Inspection>;
  inspectionAppointments: Array<InspectionAppointment>;
  lastCompletedRoutineInspection?: Maybe<Inspection>;
  routineInspectionDueDate?: Maybe<Scalars["Date"]>;
  tenantContacts: Array<Contact>;
  billsAsPayer?: Maybe<PaginatedBills>;
};

export type TenancyRentsArgs = {
  cursor?: RentPageCursor;
  sort?: Maybe<Array<RentSort>>;
};

export type TenancyProRataSchedulesForNewRentArgs = {
  proposedRent: ProposedRent;
};

export type TenancyFilesArgs = {
  pageCursor?: PaginationParams;
};

export type TenancyTenantshipsArgs = {
  pageCursor?: PmPageCursor;
};

export type TenancyBillsAsPayerArgs = {
  id?: Maybe<Array<Scalars["AiloRN"]>>;
  organisationId?: Maybe<Array<Scalars["AiloRN"]>>;
  payeeId?: Maybe<Array<Scalars["AiloRN"]>>;
  taxCategoryId?: Maybe<Array<Scalars["String"]>>;
  taxCategoryIdNotIn?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Array<BillStatus>>;
  paid?: Maybe<Scalars["Boolean"]>;
  payByDate?: Maybe<LocalDateRangeCondition>;
  sort?: Maybe<BillSortParams>;
  cursor?: Maybe<PageCursorWithoutSort>;
  excludeBillsWithEmptyPaymentStatus?: Maybe<Scalars["Boolean"]>;
};

export type TenancyAgreement = {
  __typename?: "TenancyAgreement";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  startDate?: Maybe<Scalars["Date"]>;
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
  /**
   * Represents the date when the user made the decision to allow the current lease to lapse.
   * This is NOT the date which the tenancy is allowed to go into periodic from.
   */
  allowedToLapseAt?: Maybe<Scalars["DateTime"]>;
};

export type TenancyAgreementDetails = {
  startDate: Scalars["Date"];
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
};

export type TenancyAgreementSetupInput = {
  startDate: Scalars["Date"];
  fixedTermEndDate?: Maybe<Scalars["Date"]>;
};

export type TenancyBond = {
  __typename?: "TenancyBond";
  id: Scalars["ID"];
  amount: Money;
  /** @deprecated use 'amount' */
  amountInCents?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["String"]>;
  status: TenancyBondStatus;
  failureReason?: Maybe<BondDisbursalFailureReason>;
  fundsReceived?: Maybe<Array<FundReceived>>;
  claims?: Maybe<Array<TenancyBondClaim>>;
  createdAt: Scalars["DateTime"];
  modifiedAt?: Maybe<Scalars["DateTime"]>;
};

export type TenancyBondAccount = {
  __typename?: "TenancyBondAccount";
  id: Scalars["ID"];
  paymentMethodCompanion?: Maybe<PaymentMethodCompanion>;
};

export type TenancyBondClaim = {
  __typename?: "TenancyBondClaim";
  id: Scalars["ID"];
  amount: Money;
  liabilityAiloRN: Scalars["AiloRN"];
  claimOrder: Scalars["Int"];
  claimStatus: TenancyBondClaimStatus;
};

export enum TenancyBondClaimStatus {
  Unpaid = "Unpaid",
  Pending = "Pending",
  Paid = "Paid"
}

export type TenancyBondSetupInput = {
  amountInCents: Scalars["String"];
  reference: Scalars["String"];
};

export enum TenancyBondStatus {
  Unclaimed = "Unclaimed",
  AwaitingFunds = "AwaitingFunds",
  FundsReceived = "FundsReceived",
  Disbursing = "Disbursing",
  ClaimSuccessful = "ClaimSuccessful",
  DisbursementFailed = "DisbursementFailed",
  BondResolved = "BondResolved"
}

export type TenancyCursor = {
  pageSize?: Scalars["Int"];
  paginateBackward?: Scalars["Boolean"];
  cursor?: Maybe<Scalars["String"]>;
};

export type TenancyDeposit = {
  __typename?: "TenancyDeposit";
  id: Scalars["ID"];
  ailoRN: Scalars["AiloRN"];
  amount: Money;
  releasedAt?: Maybe<Scalars["DateTime"]>;
  releasedAmount: Money;
  releasedBy?: Maybe<Scalars["AiloRN"]>;
  paidAt?: Maybe<Scalars["DateTime"]>;
  status: TenancyDepositStatus;
  createdAt: Scalars["DateTime"];
  createdBy: Scalars["AiloRN"];
  modifiedBy: Scalars["AiloRN"];
  liability?: Maybe<Liability>;
};

export type TenancyDepositSetupInput = {
  amount: MoneyInput;
};

export enum TenancyDepositStatus {
  Void = "VOID",
  New = "NEW",
  Due = "DUE",
  Pending = "PENDING",
  Paid = "PAID",
  Released = "RELEASED",
  Failed = "FAILED"
}

export type TenancyInput = {
  managementId: Scalars["ID"];
};

export type TenancyLedgerLineItem = {
  __typename?: "TenancyLedgerLineItem";
  amount: Money;
  businessTransaction?: Maybe<BusinessTransaction>;
  displayDateTime: Scalars["DateTime"];
  isFailOrVoid: Scalars["Boolean"];
  liability: Liability;
  liabilityEntryDateTime: Scalars["DateTime"];
  paidToDateDetails?: Maybe<PaidToDateDetails>;
  type: TenancyLedgerLineItemType;
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type TenancyLedgerLineItemsInput = {
  tenancy: Scalars["AiloRN"];
};

export enum TenancyLedgerLineItemType {
  Adjustment = "ADJUSTMENT",
  Bill = "BILL",
  Rent = "RENT"
}

export type TenancyPropertyAddress = ContactPropertyAddress & {
  __typename?: "TenancyPropertyAddress";
  managementAilorn: Scalars["AiloRN"];
  managementEndDate?: Maybe<Scalars["Date"]>;
  tenancyAilorn: Scalars["AiloRN"];
  unitStreetNumber: Scalars["String"];
  streetName: Scalars["String"];
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  /** <unitStreetNumber> <streetName> */
  shortAddress: Scalars["String"];
  startDate?: Maybe<Scalars["Date"]>;
  endDate?: Maybe<Scalars["Date"]>;
  voidedAt?: Maybe<Scalars["Date"]>;
};

export type TenancySearchResult = {
  __typename?: "TenancySearchResult";
  id: Scalars["ID"];
  tenancy: Tenancy;
};

export type TenancySetupInput = {
  startDate: Scalars["Date"];
  endDate?: Maybe<Scalars["Date"]>;
  scheduledRentReviewDate?: Maybe<Scalars["Date"]>;
  tenants: Array<TenantshipSetupInput>;
  tenancyAgreement: TenancyAgreementSetupInput;
  rents?: Maybe<Array<RentSetupInput>>;
  bond?: Maybe<TenancyBondSetupInput>;
  deposit?: Maybe<TenancyDepositSetupInput>;
  fileAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type Tenantship = {
  __typename?: "Tenantship";
  tenancyId: Scalars["AiloRN"];
  tenantId: Scalars["AiloRN"];
  tenant?: Maybe<LegalEntity>;
};

export type TenantshipSetupInput = {
  tenantId?: Maybe<Scalars["ID"]>;
  consumer?: Maybe<ConsumerSetupInput>;
};

export enum ThreadDeliveryMechanism {
  Chat = "Chat",
  Email = "Email"
}

export type ThreadInputV2 = {
  message: MessageInputV2;
  subject: Scalars["String"];
  /**
   * If "Email" is selected as one of the delivery mechanisms, then
   * all messages sent in this thread (including the initial message)
   * must also have a "sendEmailFromMailboxAccessAilorn" set in MessageInputV2
   */
  deliveryMechanisms?: Maybe<Array<ThreadDeliveryMechanism>>;
  /**
   * Entities in Ailo that this thread is related to. Currently supported relations are:
   * - propertymanagement:management
   */
  relatedToAilorns?: Maybe<Array<Scalars["AiloRN"]>>;
};

export type ThreadParticipant = {
  __typename?: "ThreadParticipant";
  contact: Contact;
  joinedAt: Scalars["DateTime"];
  isAdditionalParticipant: Scalars["Boolean"];
};

export type ThreadRelatedEntity = Management;

export type ThreadV2 = {
  __typename?: "ThreadV2";
  ailorn: Scalars["AiloRN"];
  subject: Scalars["String"];
  participants: Array<Contact>;
  threadParticipants: Array<ThreadParticipant>;
  deliveryMechanisms: Array<ThreadDeliveryMechanism>;
  firstMessage: MessageV2;
  mostRecentMessage: MessageV2;
  oldestUnreadMessage?: Maybe<MessageV2>;
  /** This does not include the first message in the thread. */
  replies: PaginatedMessagesV2;
  /** This does not count the first message in the thread. */
  numberOfReplies: Scalars["Int"];
  chat: ChatV2;
  relatedTo: Array<Maybe<ThreadRelatedEntity>>;
  unreadMessagesCount: Scalars["Int"];
};

export type ThreadV2RepliesArgs = {
  pagination: PaginationInputV2;
};

export enum ThumbnailStatus {
  Generating = "Generating",
  Generated = "Generated",
  None = "None"
}

export type TotalClearedRentReceived = {
  __typename?: "TotalClearedRentReceived";
  count: Scalars["Int"];
};

export type Transaction = {
  __typename?: "Transaction";
  allocations: Array<Maybe<TransactionAllocation>>;
  auditNumber?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["AiloRN"]>;
  management?: Maybe<Management>;
  referenceNumber?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

export type TransactionAllocation = {
  __typename?: "TransactionAllocation";
  amount?: Maybe<Money>;
  description?: Maybe<Scalars["String"]>;
};

export type TransactionDetails = {
  __typename?: "TransactionDetails";
  createdAt: Scalars["DateTime"];
  feeAmount: Money;
  idempotentKey?: Maybe<Scalars["String"]>;
  liability?: Maybe<Liability>;
  message?: Maybe<Scalars["String"]>;
  payer?: Maybe<Scalars["AiloRN"]>;
  paymentChannel?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentReferenceId?: Maybe<Scalars["String"]>;
  paymentRequestDescription?: Maybe<Scalars["String"]>;
  requestedAmount: Money;
  transactionAmount: Money;
  transactionRef: Scalars["AiloRN"];
  transactionStatus: TransactionStatus;
  type?: Maybe<Scalars["String"]>;
};

export type TransactionFee = {
  __typename?: "TransactionFee";
  feeBps: Scalars["Int"];
  feeFlatCents: Scalars["Int"];
  waived?: Maybe<Scalars["Boolean"]>;
  waivedTo?: Maybe<Scalars["Date"]>;
};

export type TransactionReportItem = {
  __typename?: "TransactionReportItem";
  ailoFeeAmount?: Maybe<Money>;
  bankSettlementDate?: Maybe<Scalars["DateTime"]>;
  bankingChannel?: Maybe<Scalars["String"]>;
  billType?: Maybe<Scalars["String"]>;
  businessTransactionId: Scalars["AiloRN"];
  channel?: Maybe<Scalars["String"]>;
  clearedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  gatewayPaymentId?: Maybe<Scalars["String"]>;
  /** @deprecated Use `businessTransactionId` or `paymentTransactionId` instead */
  id?: Maybe<Scalars["AiloRN"]>;
  methodType?: Maybe<Scalars["String"]>;
  originalPaymentAmount?: Maybe<Money>;
  payeeId?: Maybe<Scalars["AiloRN"]>;
  payerId?: Maybe<Scalars["AiloRN"]>;
  paymentTransactionId?: Maybe<Scalars["AiloRN"]>;
  paymentTransactionType?: Maybe<Scalars["String"]>;
  paymentType?: Maybe<Scalars["String"]>;
  processOrSettlementDate?: Maybe<Scalars["DateTime"]>;
  product?: Maybe<Scalars["String"]>;
  responseCode?: Maybe<Scalars["String"]>;
  reversalType?: Maybe<Scalars["String"]>;
  reversedBusinessTransactionId?: Maybe<Scalars["AiloRN"]>;
  reversedPaymentTransaction?: Maybe<Scalars["AiloRN"]>;
  status?: Maybe<Scalars["String"]>;
  taxCategory?: Maybe<Scalars["String"]>;
  totalPaymentAmount?: Maybe<Money>;
  transactionType?: Maybe<Scalars["String"]>;
};

export enum TransactionsSortField {
  AuditNumber = "AUDIT_NUMBER"
}

export type TransactionStatus = {
  __typename?: "transactionStatus";
  clearedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  status: BusinessTxStatusEnum;
};

export type TransferSummaryStatementInput = {
  /** Transfer business tx id the statement data starts from exclusive */
  from?: Maybe<Scalars["ID"]>;
  /** Transfer business tx id the statement data is up to inclusive */
  to: Scalars["ID"];
  walletOwnerReference: Scalars["AiloRN"];
};

export type TransferSummaryStatementRange = {
  __typename?: "TransferSummaryStatementRange";
  fromBusinessTxId?: Maybe<Scalars["ID"]>;
  fromDateTime: Scalars["DateTime"];
  toBusinessTxId: Scalars["ID"];
  toDateTime: Scalars["DateTime"];
};

export type TransferSummaryStatementRangeInput = {
  /** Business transaction id of the disbursement. */
  businessTxId: Scalars["ID"];
  /** Wallet owner of wallet being disbursed from. */
  walletOwnerReference: Scalars["AiloRN"];
};

export type TransferToWalletInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  fromWalletId: Scalars["ID"];
  toWalletId: Scalars["ID"];
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type TrustAccount = {
  __typename?: "TrustAccount";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  ownerLegalEntityAilorn: Scalars["AiloRN"];
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bsb?: Maybe<Scalars["String"]>;
  ownerLegalEntity: Company;
};

export type TrustAccountAggregateGroupBy = {
  __typename?: "TrustAccountAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountConnection = {
  __typename?: "TrustAccountConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountEdge>;
};

export type TrustAccountCountAggregate = {
  __typename?: "TrustAccountCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["Int"]>;
};

export type TrustAccountEdge = {
  __typename?: "TrustAccountEdge";
  /** The node containing the TrustAccount */
  node: TrustAccount;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustAccountFilter = {
  and?: Maybe<Array<TrustAccountFilter>>;
  or?: Maybe<Array<TrustAccountFilter>>;
  id?: Maybe<IdFilterComparison>;
  ownerLegalEntityAilorn?: Maybe<AiloRnFilterComparison>;
};

export type TrustAccountLock = {
  __typename?: "TrustAccountLock";
  lockedUpTo: Scalars["DateTime"];
  status: TrustAccountLockStatus;
  trustAccountAilorn: Scalars["AiloRN"];
};

export enum TrustAccountLockStatus {
  Confirmed = "CONFIRMED",
  Unconfirmed = "UNCONFIRMED"
}

export type TrustAccountMaxAggregate = {
  __typename?: "TrustAccountMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountMinAggregate = {
  __typename?: "TrustAccountMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  ownerLegalEntityAilorn?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountReconciliationAdjustment = {
  __typename?: "TrustAccountReconciliationAdjustment";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  amountInCents: Scalars["Long"];
  applyOn: Scalars["LocalDate"];
  details: Scalars["String"];
  reportPeriodId: Scalars["ID"];
  removedReason?: Maybe<Scalars["String"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
  /** @deprecated Use removedFrom */
  removedAt?: Maybe<Scalars["DateTime"]>;
  reportPeriod: ReconciliationReportPeriod;
};

export type TrustAccountReconciliationAdjustmentAggregateGroupBy = {
  __typename?: "TrustAccountReconciliationAdjustmentAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  amountInCents?: Maybe<Scalars["Long"]>;
  applyOn?: Maybe<Scalars["LocalDate"]>;
  reportPeriodId?: Maybe<Scalars["ID"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
};

export type TrustAccountReconciliationAdjustmentAvgAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentAvgAggregate";
  amountInCents?: Maybe<Scalars["Float"]>;
};

export type TrustAccountReconciliationAdjustmentConnection = {
  __typename?: "TrustAccountReconciliationAdjustmentConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountReconciliationAdjustmentEdge>;
  /** Fetch total count of records */
  totalCount: Scalars["Int"];
};

export type TrustAccountReconciliationAdjustmentCountAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  amountInCents?: Maybe<Scalars["Int"]>;
  applyOn?: Maybe<Scalars["Int"]>;
  reportPeriodId?: Maybe<Scalars["Int"]>;
  removedFrom?: Maybe<Scalars["Int"]>;
};

export type TrustAccountReconciliationAdjustmentEdge = {
  __typename?: "TrustAccountReconciliationAdjustmentEdge";
  /** The node containing the TrustAccountReconciliationAdjustment */
  node: TrustAccountReconciliationAdjustment;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustAccountReconciliationAdjustmentFilter = {
  and?: Maybe<Array<TrustAccountReconciliationAdjustmentFilter>>;
  or?: Maybe<Array<TrustAccountReconciliationAdjustmentFilter>>;
  id?: Maybe<IdFilterComparison>;
  amountInCents?: Maybe<LongFilterComparison>;
  applyOn?: Maybe<LocalDateFilterComparison>;
  reportPeriodId?: Maybe<IdFilterComparison>;
  removedFrom?: Maybe<LocalDateFilterComparison>;
  reportPeriod?: Maybe<TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter>;
};

export type TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter =
  {
    and?: Maybe<
      Array<TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter>
    >;
    or?: Maybe<
      Array<TrustAccountReconciliationAdjustmentFilterReconciliationReportPeriodFilter>
    >;
    id?: Maybe<IdFilterComparison>;
    month?: Maybe<IntFieldComparison>;
    year?: Maybe<IntFieldComparison>;
    locked?: Maybe<BooleanFieldComparison>;
    trustAccountAilorn?: Maybe<GraphQlAilornFilterComparison>;
  };

export type TrustAccountReconciliationAdjustmentMaxAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  amountInCents?: Maybe<Scalars["Long"]>;
  applyOn?: Maybe<Scalars["LocalDate"]>;
  reportPeriodId?: Maybe<Scalars["ID"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
};

export type TrustAccountReconciliationAdjustmentMinAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  amountInCents?: Maybe<Scalars["Long"]>;
  applyOn?: Maybe<Scalars["LocalDate"]>;
  reportPeriodId?: Maybe<Scalars["ID"]>;
  removedFrom?: Maybe<Scalars["LocalDate"]>;
};

export type TrustAccountReconciliationAdjustmentSort = {
  field: TrustAccountReconciliationAdjustmentSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TrustAccountReconciliationAdjustmentSortFields {
  Id = "id",
  AmountInCents = "amountInCents",
  ApplyOn = "applyOn",
  ReportPeriodId = "reportPeriodId",
  RemovedFrom = "removedFrom"
}

export type TrustAccountReconciliationAdjustmentSumAggregate = {
  __typename?: "TrustAccountReconciliationAdjustmentSumAggregate";
  amountInCents?: Maybe<Scalars["Float"]>;
};

export type TrustAccountSort = {
  field: TrustAccountSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TrustAccountSortFields {
  Id = "id",
  OwnerLegalEntityAilorn = "ownerLegalEntityAilorn"
}

export type TrustAccountStatementBalance = {
  __typename?: "TrustAccountStatementBalance";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  amountInCents: Scalars["Long"];
  date: Scalars["LocalDate"];
  trustAccountAilorn: Scalars["ID"];
  relatedReportPeriods: Array<ReconciliationReportPeriod>;
};

export type TrustAccountStatementBalanceRelatedReportPeriodsArgs = {
  filter?: Maybe<ReconciliationReportPeriodFilter>;
  sorting?: Maybe<Array<ReconciliationReportPeriodSort>>;
};

export type TrustAccountWallet = {
  __typename?: "TrustAccountWallet";
  id: Scalars["ID"];
  ailorn: Scalars["AiloRN"];
  trustAccount: TrustAccount;
  trustAccountId: Scalars["ID"];
  entityReference: Scalars["AiloRN"];
  type: TrustAccountWalletType;
  code: Scalars["String"];
  entity: TrustAccountWalletEntity;
};

export type TrustAccountWalletAggregateGroupBy = {
  __typename?: "TrustAccountWalletAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
  trustAccountId?: Maybe<Scalars["ID"]>;
  entityReference?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountWalletConnection = {
  __typename?: "TrustAccountWalletConnection";
  /** Paging information */
  pageInfo: RelayPageInfo;
  /** Array of edges. */
  edges: Array<TrustAccountWalletEdge>;
};

export type TrustAccountWalletCountAggregate = {
  __typename?: "TrustAccountWalletCountAggregate";
  id?: Maybe<Scalars["Int"]>;
  trustAccountId?: Maybe<Scalars["Int"]>;
  entityReference?: Maybe<Scalars["Int"]>;
};

export type TrustAccountWalletEdge = {
  __typename?: "TrustAccountWalletEdge";
  /** The node containing the TrustAccountWallet */
  node: TrustAccountWallet;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustAccountWalletEntity =
  | LegalEntityCompanion
  | ManagementFolio
  | Tenancy
  | Supplier
  | BondAuthority;

export type TrustAccountWalletFilter = {
  and?: Maybe<Array<TrustAccountWalletFilter>>;
  or?: Maybe<Array<TrustAccountWalletFilter>>;
  id?: Maybe<IdFilterComparison>;
  trustAccountId?: Maybe<IdFilterComparison>;
  entityReference?: Maybe<AiloRnFilterComparison>;
};

export type TrustAccountWalletMaxAggregate = {
  __typename?: "TrustAccountWalletMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
  trustAccountId?: Maybe<Scalars["ID"]>;
  entityReference?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountWalletMinAggregate = {
  __typename?: "TrustAccountWalletMinAggregate";
  id?: Maybe<Scalars["ID"]>;
  trustAccountId?: Maybe<Scalars["ID"]>;
  entityReference?: Maybe<Scalars["AiloRN"]>;
};

export type TrustAccountWalletSort = {
  field: TrustAccountWalletSortFields;
  direction: RelaySortDirection;
  nulls?: Maybe<SortNulls>;
};

export enum TrustAccountWalletSortFields {
  Id = "id",
  TrustAccountId = "trustAccountId",
  EntityReference = "entityReference"
}

export enum TrustAccountWalletType {
  ManagementFolio = "MANAGEMENT_FOLIO",
  Tenancy = "TENANCY",
  Agency = "AGENCY",
  BondAuthority = "BOND_AUTHORITY",
  Supplier = "SUPPLIER",
  Investor = "INVESTOR",
  Renter = "RENTER"
}

export type TrustReceipt = {
  __typename?: "TrustReceipt";
  id: Scalars["ID"];
};

export type TrustReceiptAggregateGroupBy = {
  __typename?: "TrustReceiptAggregateGroupBy";
  id?: Maybe<Scalars["ID"]>;
};

export type TrustReceiptCountAggregate = {
  __typename?: "TrustReceiptCountAggregate";
  id?: Maybe<Scalars["Int"]>;
};

export type TrustReceiptEdge = {
  __typename?: "TrustReceiptEdge";
  /** The node containing the TrustReceipt */
  node: TrustReceipt;
  /** Cursor for this node. */
  cursor: Scalars["ConnectionCursor"];
};

export type TrustReceiptMaxAggregate = {
  __typename?: "TrustReceiptMaxAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export type TrustReceiptMinAggregate = {
  __typename?: "TrustReceiptMinAggregate";
  id?: Maybe<Scalars["ID"]>;
};

export type UnacknowledgeThreadFromMessageInput = {
  messageAilorn: Scalars["AiloRN"];
};

export type UnlockReportPeriodInput = {
  month: Scalars["Int"];
  year: Scalars["Int"];
  trustAccountAilorn: Scalars["AiloRN"];
};

export type UnpersistedChargeCycle = {
  __typename?: "UnpersistedChargeCycle";
  amount: Money;
  endDate?: Maybe<Scalars["Date"]>;
  startDate: Scalars["Date"];
  taxAmount: Money;
};

export type UnreadMessagesCountInput = {
  organisationAilorn: Scalars["AiloRN"];
};

export enum UnvoidableTenancyReason {
  TenancyIsNotIngoing = "TenancyIsNotIngoing",
  DepositHasBeenPaid = "DepositHasBeenPaid"
}

export type UpdateAiloFormInput = {
  name?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  updateResponses?: Maybe<Array<UpdateAiloFormResponseInput>>;
  /** creates or overwrites responses for specific fields */
  createResponses?: Maybe<Array<CreateAiloFormCreateAiloFormResponseInput>>;
  deleteResponseForFields?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateAiloFormResponseInput = {
  payload: AiloFormResponsePayloadInput;
  id: Scalars["ID"];
};

export type UpdateAiloFormResult = {
  __typename?: "UpdateAiloFormResult";
  form: AiloForm;
};

export type UpdateAutoWithdrawPlanInputV2 = {
  autoWithdrawPlanId: Scalars["ID"];
  paymentMethodDestinations: Array<PaymentMethodDestinationInput>;
  setAsideAmount?: Maybe<MoneyInput>;
};

export type UpdateBondInput = {
  tenancyAilorn: Scalars["AiloRN"];
  reference?: Maybe<Scalars["String"]>;
  amount?: Maybe<MoneyInput>;
  bondAccountAilorn?: Maybe<Scalars["AiloRN"]>;
  claimedOn?: Maybe<Scalars["Date"]>;
};

export type UpdateCompanyProfileDetailsInput = {
  id: Scalars["AiloRN"];
  registeredEntityId?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["TimeZone"]>;
  accountsEmail?: Maybe<Array<Scalars["String"]>>;
};

export type UpdateFeeBlueprintInput = {
  id: Scalars["ID"];
  archived?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  taxCategoryId?: Maybe<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  event?: Maybe<FeeEventType>;
  fixedAmount?: Maybe<MoneyInput>;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  taxTreatment?: Maybe<TaxTreatment>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type UpdateFeeInput = {
  id: Scalars["String"];
  amount?: Maybe<MoneyInput>;
  taxAmount?: Maybe<MoneyInput>;
  percentage?: Maybe<Scalars["Float"]>;
  baseAmount?: Maybe<MoneyInput>;
  description?: Maybe<Scalars["String"]>;
  /** Must be either `true` or `null` */
  archived?: Maybe<Scalars["Boolean"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type UpdateInspectionAppointmentInput = {
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startTime?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
};

export type UpdateInspectionAppointmentResult = {
  __typename?: "UpdateInspectionAppointmentResult";
  inspectionAppointment: InspectionAppointment;
};

export type UpdateInspectionAreaInput = {
  index?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  inspected?: Maybe<Scalars["Boolean"]>;
  notes?: Maybe<Scalars["String"]>;
  condition?: Maybe<InspectionAreaCondition>;
  id: Scalars["ID"];
  createFiles?: Maybe<Array<CreateInspectionAreaInputFileInput>>;
  removeFiles?: Maybe<Array<Scalars["ID"]>>;
  updateFeatures?: Maybe<Array<UpdateInspectionFeatureInput>>;
  createFeatures?: Maybe<Array<CreateInspectionAreaInputFeatureInput>>;
  removeFeatures?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateInspectionAreaResult = {
  __typename?: "UpdateInspectionAreaResult";
  inspectionArea: InspectionArea;
};

export type UpdateInspectionFeatureInput = {
  name?: Maybe<Scalars["String"]>;
  index?: Maybe<Scalars["Int"]>;
  description?: Maybe<Scalars["String"]>;
  isClean?: Maybe<Scalars["Boolean"]>;
  isUndamaged?: Maybe<Scalars["Boolean"]>;
  isWorking?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  createFiles?: Maybe<Array<CreateInspectionFeatureInputFileInput>>;
  removeFiles?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateInspectionFeatureResult = {
  __typename?: "UpdateInspectionFeatureResult";
  inspectionFeature: InspectionFeature;
};

export type UpdateInspectionInput = {
  type?: Maybe<InspectionType>;
  inspectingAgentAilorn?: Maybe<Scalars["AiloRN"]>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  ailoFormAilorn?: Maybe<Scalars["AiloRN"]>;
  id: Scalars["ID"];
  updateAreas?: Maybe<Array<UpdateInspectionAreaInput>>;
  createAreas?: Maybe<Array<CreateInspectionInputAreaInput>>;
  removeAreas?: Maybe<Array<Scalars["ID"]>>;
};

export type UpdateInspectionResult = {
  __typename?: "UpdateInspectionResult";
  inspection: Inspection;
};

export type UpdateKeyInput = {
  code: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type UpdateLiabilityPaymentPlanInput = {
  liabilityPaymentPlanId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
  paymentMethodId: Scalars["ID"];
};

export type UpdateManagementAgreementInput = {
  id: Scalars["ID"];
  startDate?: Maybe<Scalars["String"]>;
  fixedTermEndDate?: Maybe<Scalars["String"]>;
  /** In ISO-8601 duration format */
  inspectionFrequency?: Maybe<Scalars["String"]>;
};

export type UpdateManagementAgreementPayload = {
  __typename?: "UpdateManagementAgreementPayload";
  managementAgreement?: Maybe<ManagementAgreement>;
};

export type UpdateManagementFeeBlueprintInput = {
  id: Scalars["ID"];
  archived?: Maybe<Scalars["Boolean"]>;
  fixedAmount?: Maybe<MoneyInput>;
  oneWeekRentPercentage?: Maybe<Scalars["Float"]>;
  taxTreatment?: Maybe<TaxTreatment>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateManagementFeeInput = {
  managementAgreementAiloRN: Scalars["AiloRN"];
  percent: Scalars["Float"];
};

export type UpdateOneKeyInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateKeyInput;
};

export type UpdateOneTrustAccountInput = {
  /** The id of the record to update */
  id: Scalars["ID"];
  /** The update to apply. */
  update: UpdateTrustAccountInput;
};

export type UpdatePersonProfileDetailsInput = {
  id: Scalars["AiloRN"];
  legalFirstName?: Maybe<Scalars["String"]>;
  legalMiddleName?: Maybe<Scalars["String"]>;
  preferredName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  photoFileId?: Maybe<Scalars["AiloRN"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  unitStreetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  suburb?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
};

export type UpdatePropertyInput = {
  id: Scalars["ID"];
  address: PropertyInput;
};

export type UpdateRecurringFeeBlueprintInput = {
  id: Scalars["AiloRN"];
  archived?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  taxCategoryId?: Maybe<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  fixedAmount?: Maybe<MoneyInput>;
  taxTreatment?: Maybe<TaxTreatment>;
  anniversaryDay?: Maybe<Scalars["Int"]>;
  anniversaryMonth?: Maybe<Scalars["Int"]>;
  archiveReason?: Maybe<Scalars["String"]>;
};

export type UpdateRecurringFeeInput = {
  id: Scalars["AiloRN"];
  /**
   * If provided, must contain at least one schedule.
   * Replaces any existing future schedules with the provided list.
   */
  futureSchedules?: Maybe<Array<RecurringFeeScheduleInput>>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateReiTokenInput = {
  /** REI Token */
  token?: Maybe<Scalars["String"]>;
  /** State the REI account was linked to */
  supportedAustralianState?: Maybe<AustralianState>;
  /** AiloRN of person the token is associated with */
  personAilorn?: Maybe<Scalars["AiloRN"]>;
  /** AiloRN of agency organisation */
  organisationAilorn?: Maybe<Scalars["AiloRN"]>;
  id: Scalars["ID"];
};

export type UpdateSupplierInput = {
  id: Scalars["ID"];
  abn?: Maybe<Scalars["String"]>;
  registeredEntityName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  address?: Maybe<SupplierAddressInput>;
  emailAddress?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<SupplierPaymentMethodInput>;
  replaceExistingDueBillsPaymentMethod?: Maybe<Scalars["Boolean"]>;
};

export type UpdateTenancyAgreementInput = {
  id: Scalars["ID"];
  startDate: Scalars["LocalDate"];
  /** If not provided, the mutation will remove the existing `fixedTermEndDate` */
  fixedTermEndDate?: Maybe<Scalars["LocalDate"]>;
};

export type UpdateTenancyAgreementPayload = {
  __typename?: "UpdateTenancyAgreementPayload";
  tenancyAgreement?: Maybe<TenancyAgreement>;
};

export type UpdateTrustAccountInput = {
  accountName?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  bsb?: Maybe<Scalars["String"]>;
};

export type UpsertAutoPayLiabilityStatusInput = {
  /** Maximum payable amount that auto pay will cover, default to be 40k and configurable via env var: ledger.auto-pay-liability.maximum-payment-amount */
  maximumPaymentAmount?: Maybe<MoneyInput>;
  /**
   * Legal Entity ARN (Person/Company) if it is meant to pay liabilities using their "personal" wallet,
   * or Management folio ARN, if it's meant to pay liabilities using management folio wallet.
   */
  payerId?: Maybe<Scalars["AiloRN"]>;
  /** Current user legal entity ID. Will get deprecated soon, once we are able to get user person ARN from new authz token. */
  payerLegalEntity: Scalars["AiloRN"];
  paymentMethodId?: Maybe<Scalars["ID"]>;
};

export type UpsertCrnInput = {
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  crn: Scalars["String"];
};

export type UpsertCrnOutput = {
  __typename?: "UpsertCrnOutput";
  legalEntity: Scalars["AiloRN"];
  managingEntity: Scalars["AiloRN"];
  crn: Scalars["String"];
};

export type UpsertTenancyBondClaim = {
  id?: Maybe<Scalars["ID"]>;
  amount: MoneyInput;
  liabilityAiloRN: Scalars["AiloRN"];
  claimOrder: Scalars["Int"];
  claimStatus?: Maybe<TenancyBondClaimStatus>;
};

export type UpsertTenancyBondClaims = {
  bondId: Scalars["ID"];
  claims: Array<UpsertTenancyBondClaim>;
};

export type User = {
  __typename?: "User";
  id: Scalars["Int"];
  ailoRN: Scalars["AiloRN"];
  auth0Id?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  modifiedAt?: Maybe<Scalars["DateTime"]>;
  onboardedAt?: Maybe<Scalars["DateTime"]>;
  onboardingCompletedAt?: Maybe<Scalars["DateTime"]>;
  person: Person;
  organisations: Array<Organisation>;
  onboardingTasks: Array<UserOnboardingTask>;
  /** Returns true if the user has a role (via person) in any org with type "Agency". */
  isAgent?: Maybe<Scalars["Boolean"]>;
};

export type UserOrganisationsArgs = {
  filter?: Maybe<OrganisationsFilter>;
};

export type UserOnboardingTask = {
  __typename?: "UserOnboardingTask";
  /** @deprecated Use `onboardingTask.id` */
  onboardingTaskId: OnboardingTaskId;
  onboardingTask: OnboardingTask;
  completedAt: Scalars["DateTime"];
};

export enum VacatingReason {
  WithinTermsOfAgreement = "WithinTermsOfAgreement",
  LeaseBreak = "LeaseBreak",
  Termination = "Termination",
  LostManagement = "LostManagement",
  OffBoarded = "OffBoarded"
}

export enum ValidateFileErrorCode {
  FileNotFound = "FileNotFound",
  FileNotUploaded = "FileNotUploaded",
  InvalidFileType = "InvalidFileType"
}

export type ValidateFileResponse = {
  __typename?: "ValidateFileResponse";
  ok: Scalars["Boolean"];
  /** Present if `ok === true`. */
  file?: Maybe<File>;
  /** Present if `ok === false`. */
  errorCode?: Maybe<ValidateFileErrorCode>;
};

export enum VerifyEmailErrorCode {
  VerificationCodeNotFound = "VERIFICATION_CODE_NOT_FOUND",
  VerificationCodeExpired = "VERIFICATION_CODE_EXPIRED",
  EmailUsedByAnotherPerson = "EMAIL_USED_BY_ANOTHER_PERSON"
}

export type VerifyEmailErrorResponse = {
  __typename?: "VerifyEmailErrorResponse";
  errorCode: VerifyEmailErrorCode;
};

export type VerifyEmailResponse =
  | VerifyEmailSuccessResponse
  | VerifyEmailErrorResponse;

export type VerifyEmailSuccessResponse = {
  __typename?: "VerifyEmailSuccessResponse";
  user: User;
};

export type VerifyPhoneVerificationCodeResponse = {
  __typename?: "VerifyPhoneVerificationCodeResponse";
  ok: Scalars["Boolean"];
  error?: Maybe<VerifyPhoneVerificationCodeResponseError>;
};

export type VerifyPhoneVerificationCodeResponseError = {
  __typename?: "VerifyPhoneVerificationCodeResponseError";
  /**
   * Possible values:
   * - "internal-server-error"
   * - "invalid-verification-code"
   * - "verification-not-found"
   * - "invalid-phone-number"
   * - "max-verification-check-attempts-reached"
   * - "max-send-attempts-reached"
   * - "sms-is-not-supported-by-landline-phone-number"
   * - "too-many-concurrent-requests-for-phone-number"
   */
  code: Scalars["String"];
  message?: Maybe<Scalars["String"]>;
};

export type VirtualAccount = PaymentMethod & {
  __typename?: "VirtualAccount";
  accountNumber?: Maybe<Scalars["String"]>;
  ailoRN: Scalars["AiloRN"];
  bsb?: Maybe<Scalars["String"]>;
  deletedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isAutoPay: Scalars["Boolean"];
  isAutoWithdraw: Scalars["Boolean"];
  isDefaultIn: Scalars["Boolean"];
  isDefaultOut: Scalars["Boolean"];
  isHidden: Scalars["Boolean"];
  isOnceOff?: Maybe<Scalars["Boolean"]>;
  reference?: Maybe<Scalars["String"]>;
  topUpFee?: Maybe<TransactionFee>;
  virtualAccountId?: Maybe<Scalars["String"]>;
  wallet: Wallet;
};

export type VoidTenancyAbility = {
  __typename?: "VoidTenancyAbility";
  voidable: Scalars["Boolean"];
  unvoidableReason?: Maybe<UnvoidableTenancyReason>;
};

export type VoidTenancyPayload = {
  __typename?: "VoidTenancyPayload";
  tenancy: Tenancy;
};

export type Wallet = {
  __typename?: "Wallet";
  autoPayLiabilityStatus?: Maybe<AutoPayLiabilityStatus>;
  autoWithdrawPlans?: Maybe<PaginatedAutoWithdrawPlans>;
  availableBalance: Money;
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  idempotencyKey: Scalars["GeneratedKey"];
  name: Scalars["String"];
  owner: WalletOwner;
  totalBalance: Money;
};

export type WalletAutoWithdrawPlansArgs = {
  disableOwnerFilter?: Maybe<Scalars["Boolean"]>;
  enabled: Scalars["Boolean"];
  payerLegalEntityId?: Maybe<Scalars["AiloRN"]>;
};

export type WalletBalance = {
  __typename?: "WalletBalance";
  availableBalance: Money;
  clearedBalance: Money;
  overdraftAllowance: Money;
  totalBalance: Money;
};

export type WalletBalanceItem = {
  __typename?: "WalletBalanceItem";
  balance: Money;
  id: Scalars["AiloRN"];
  name: Scalars["String"];
  reference: Scalars["AiloRN"];
};

export type WalletBalanceReport = {
  __typename?: "WalletBalanceReport";
  items: Array<Maybe<WalletBalanceItem>>;
};

export type WalletEntry = {
  __typename?: "WalletEntry";
  amount: Money;
  businessTransaction: Scalars["AiloRN"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  isReversal: Scalars["Boolean"];
  isTopUpFee: Scalars["Boolean"];
  liability?: Maybe<Liability>;
  paymentMethod?: Maybe<PaymentMethod>;
  status: BusinessTxStatusEnum;
};

export type WalletOwner = {
  __typename?: "WalletOwner";
  /** How much money the wallet owner currently owes due to fees */
  dueFeeAmount: Money;
  id: Scalars["ID"];
  reference: Scalars["AiloRN"];
};

export type WalletOwnerLiabilityReport = {
  __typename?: "WalletOwnerLiabilityReport";
  items: Array<Maybe<WalletOwnerLiabilityReportItem>>;
};

export type WalletOwnerLiabilityReportItem = {
  __typename?: "WalletOwnerLiabilityReportItem";
  reference: Scalars["AiloRN"];
  totalBalance: Money;
};

export type WalletStatementData = {
  __typename?: "WalletStatementData";
  closingBalance: WalletBalance;
  lineItemsByStatementTransactionDate: Array<StatementLineItem2>;
  openingBalance: WalletBalance;
  taxSummary: TaxSummaryStatementData;
  transactionLineItemsByStatementTransactionDate?: Maybe<PaginatedStatementTransactionLineItem>;
};

export type WalletStatementDataTransactionLineItemsByStatementTransactionDateArgs =
  {
    paginationParams?: Maybe<PaginationParams>;
  };

export type WalletStatementInput = {
  from: Scalars["Date"];
  to: Scalars["Date"];
  walletOwnerReference: Scalars["AiloRN"];
  zoneId: Scalars["ZoneId"];
};

export type WalletTransferInput = {
  amount: MoneyInput;
  description: Scalars["String"];
  destinationWalletOwner: Scalars["AiloRN"];
  idempotencyKey: Scalars["String"];
  sourceWalletOwner: Scalars["AiloRN"];
};

export type Weight = {
  __typename?: "Weight";
  kgs: Scalars["Long"];
};

export type WithdrawInput = {
  amount: MoneyInput;
  fromWalletId: Scalars["ID"];
  idempotencyKey: Scalars["GeneratedKey"];
  toPaymentMethodId: Scalars["ID"];
  userFacingDescription?: Maybe<Scalars["String"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  messagePostedV2?: Maybe<SubscriptionMessageV2>;
};

export type SubscriptionMessageV2 = {
  __typename?: "SubscriptionMessageV2";
  messageAilorn: Scalars["AiloRN"];
  threadAilorn: Scalars["AiloRN"];
  chatAilorn: Scalars["AiloRN"];
};

export type LegalEntityCompanionCacheKeysFragmentFragment = {
  __typename?: "LegalEntityCompanion";
  id: string;
  ailoRNV2: string;
};

export type LegalEntityCompanionOrganisationFragmentFragment = {
  __typename?: "LegalEntityCompanion";
  organisation: {
    __typename?: "Organisation";
    id: string;
    ailoRN: string;
    name: string;
  };
} & LegalEntityCompanionCacheKeysFragmentFragment;

export type GetUserLegalEntitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserLegalEntitiesQuery = {
  __typename?: "Query";
  companies?: Maybe<
    Array<{
      __typename: "Company";
      ailoRN: string;
      registeredEntityName: string;
      registeredEntityId?: Maybe<string>;
    }>
  >;
  effectiveUser?: Maybe<{
    __typename?: "User";
    id: number;
    person: {
      __typename: "Person";
      id: string;
      ailoRN: string;
      firstName: string;
      lastName?: Maybe<string>;
      emailAddress?: Maybe<string>;
      photo?: Maybe<{
        __typename?: "File";
        id: string;
        url?: Maybe<string>;
        thumbnailUrl?: Maybe<string>;
      }>;
    };
  }>;
};

export type GetKycVerificationStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetKycVerificationStatusQuery = {
  __typename?: "Query";
  actualUser?: Maybe<{
    __typename?: "User";
    id: number;
    person: { __typename?: "Person"; id: string; kycVerified: boolean };
  }>;
};

export type GetPaidBillsQueryVariables = Exact<{
  reference: Scalars["AiloRN"];
  pageCursor: BillPageCursor;
}>;

export type GetPaidBillsQuery = {
  __typename?: "Query";
  paidBills: {
    __typename?: "DeprecatedPaginatedBills";
    pageInfo: {
      __typename?: "PageInfo";
      nextCursor?: Maybe<string>;
      hasMore: boolean;
      total: number;
    };
    items: Array<
      Maybe<{
        __typename?: "Bill";
        ailoRN: string;
        status: BillStatus;
        dueDate: string;
        amount: { __typename?: "Money"; cents: number };
        supplier?: Maybe<{
          __typename?: "Supplier";
          ailoRN: string;
          name?: Maybe<string>;
          internalLegalEntity?: Maybe<
            {
              __typename?: "LegalEntityCompanion";
            } & LegalEntityCompanionOrganisationFragmentFragment
          >;
        }>;
        liabilityState?: Maybe<{
          __typename?: "BillLiabilityState";
          paymentStatus?: Maybe<string>;
          paidAt?: Maybe<string>;
        }>;
      }>
    >;
  };
};

export type GetUpcomingBillsQueryVariables = Exact<{
  reference: Scalars["AiloRN"];
  pageCursor: BillPageCursor;
}>;

export type GetUpcomingBillsQuery = {
  __typename?: "Query";
  upcomingBills: {
    __typename?: "DeprecatedPaginatedBills";
    pageInfo: {
      __typename?: "PageInfo";
      nextCursor?: Maybe<string>;
      hasMore: boolean;
      total: number;
    };
    items: Array<
      Maybe<{
        __typename?: "Bill";
        ailoRN: string;
        status: BillStatus;
        dueDate: string;
        amount: { __typename?: "Money"; cents: number };
        supplier?: Maybe<{
          __typename?: "Supplier";
          ailoRN: string;
          name?: Maybe<string>;
          internalLegalEntity?: Maybe<
            {
              __typename?: "LegalEntityCompanion";
            } & LegalEntityCompanionOrganisationFragmentFragment
          >;
        }>;
        liabilityState?: Maybe<{
          __typename?: "BillLiabilityState";
          paymentStatus?: Maybe<string>;
          paidAt?: Maybe<string>;
        }>;
      }>
    >;
  };
};

export type CancelAutoPayLiabilityStatusMutationVariables = Exact<{
  payerLegalEntityId: Scalars["AiloRN"];
  payerId: Scalars["AiloRN"];
}>;

export type CancelAutoPayLiabilityStatusMutation = {
  __typename?: "Mutation";
  cancelAutoPayLiabilityStatus?: Maybe<{
    __typename?: "AutoPayLiabilityStatus";
    enabled: boolean;
    payerId: string;
    paymentMethodId?: Maybe<string>;
    maximumPaymentAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
};

export type GetAutoPayLiabilityStatusQueryVariables = Exact<{
  payerLegalEntityId: Scalars["AiloRN"];
  payerId: Scalars["AiloRN"];
}>;

export type GetAutoPayLiabilityStatusQuery = {
  __typename?: "Query";
  autoPayLiabilityStatus?: Maybe<{
    __typename?: "AutoPayLiabilityStatus";
    enabled: boolean;
    payerId: string;
    paymentMethodId?: Maybe<string>;
    maximumPaymentAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
};

export type GetAutoPayLiabilityStatusesQueryVariables = Exact<{
  payerLegalEntityId: Scalars["AiloRN"];
}>;

export type GetAutoPayLiabilityStatusesQuery = {
  __typename?: "Query";
  autoPayLiabilityStatuses?: Maybe<
    Array<
      Maybe<{
        __typename?: "AutoPayLiabilityStatus";
        enabled: boolean;
        payerId: string;
        paymentMethodId?: Maybe<string>;
        maximumPaymentAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
      }>
    >
  >;
};

export type UpsertAutoPayLiabilityStatusMutationVariables = Exact<{
  payerLegalEntityId: Scalars["AiloRN"];
  maximumPaymentAmount: MoneyInput;
  payerId: Scalars["AiloRN"];
}>;

export type UpsertAutoPayLiabilityStatusMutation = {
  __typename?: "Mutation";
  upsertAutoPayLiabilityStatus?: Maybe<{
    __typename?: "AutoPayLiabilityStatus";
    enabled: boolean;
    payerId: string;
    paymentMethodId?: Maybe<string>;
    maximumPaymentAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
};

export type GetAutoWithdrawPlanByIdQueryVariables = Exact<{
  autoWithdrawPlanId: Scalars["ID"];
  legalEntityId: Scalars["AiloRN"];
}>;

export type GetAutoWithdrawPlanByIdQuery = {
  __typename?: "Query";
  autoWithdrawPlanById?: Maybe<{
    __typename?: "AutoWithdrawPlan";
    id: string;
    owner: string;
    walletId: string;
    enabled: boolean;
    createdBy: string;
    createdAt: string;
    wallet: {
      __typename?: "Wallet";
      owner: { __typename?: "WalletOwner"; id: string; reference: string };
    };
    details: {
      __typename?: "AutoWithdrawPlanDetails";
      id: string;
      autoWithdrawPlanId: string;
      timezoneId: string;
      startDate: string;
      endDate?: Maybe<string>;
      frequency: QuartzPlanFrequency;
      anniversary?: Maybe<number>;
      anniversaryDaysOfMonth?: Maybe<Array<number>>;
      createdAt: string;
      isLastDayOfTheMonth?: Maybe<boolean>;
      nextFireTime: string;
      setAsideAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
      paymentMethodDestinations: Array<{
        __typename?: "PaymentMethodDestination";
        paymentMethodId: string;
        share: number;
        paymentMethod:
          | ({
              __typename?: "BankAccount";
              accountName?: Maybe<string>;
              accountNumber?: Maybe<string>;
            } & PaymentMethodDetails_BankAccount_Fragment)
          | ({ __typename?: "BPay" } & PaymentMethodDetails_BPay_Fragment)
          | ({
              __typename?: "CreditCard";
            } & PaymentMethodDetails_CreditCard_Fragment)
          | ({
              __typename?: "VirtualAccount";
            } & PaymentMethodDetails_VirtualAccount_Fragment);
      }>;
    };
  }>;
};

type PaymentMethodDetails_BankAccount_Fragment = {
  __typename: "BankAccount";
  id: string;
};

type PaymentMethodDetails_BPay_Fragment = { __typename: "BPay"; id: string };

type PaymentMethodDetails_CreditCard_Fragment = {
  __typename: "CreditCard";
  id: string;
};

type PaymentMethodDetails_VirtualAccount_Fragment = {
  __typename: "VirtualAccount";
  id: string;
};

export type PaymentMethodDetailsFragment =
  | PaymentMethodDetails_BankAccount_Fragment
  | PaymentMethodDetails_BPay_Fragment
  | PaymentMethodDetails_CreditCard_Fragment
  | PaymentMethodDetails_VirtualAccount_Fragment;

export type UpdateAutoWithdrawPlanMutationVariables = Exact<{
  updateAutoWithdrawInput: UpdateAutoWithdrawPlanInputV2;
}>;

export type UpdateAutoWithdrawPlanMutation = {
  __typename?: "Mutation";
  updateAutoWithdrawPlan?: Maybe<{
    __typename?: "AutoWithdrawPlan";
    enabled: boolean;
  }>;
};

export type GetManagementFormerTenanciesQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementFormerTenanciesQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    formerTenancies?: Maybe<{
      __typename?: "BidirectionalPaginatedTenancies";
      items: Array<{
        __typename?: "Tenancy";
        id: string;
        startDate?: Maybe<string>;
        endDate?: Maybe<string>;
      }>;
    }>;
  }>;
};

export type GetPaymentMethodsQueryVariables = Exact<{
  methodType?: Maybe<PaymentMethodType>;
  legalEntityId: Scalars["AiloRN"];
}>;

export type GetPaymentMethodsQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | {
        __typename: "Company";
        id: string;
        ailoRN: string;
        paymentMethods?: Maybe<{
          __typename?: "PaginatedPaymentMethods";
          pageInfo?: Maybe<{ __typename?: "PageInfo"; total: number }>;
          items: Array<
            Maybe<
              | {
                  __typename: "BankAccount";
                  bsb?: Maybe<string>;
                  accountName?: Maybe<string>;
                  accountNumber?: Maybe<string>;
                  id: string;
                  isOnceOff?: Maybe<boolean>;
                  isAutoPay: boolean;
                  isAutoWithdraw: boolean;
                }
              | {
                  __typename: "BPay";
                  id: string;
                  isOnceOff?: Maybe<boolean>;
                  isAutoPay: boolean;
                  isAutoWithdraw: boolean;
                }
              | {
                  __typename: "CreditCard";
                  expiry?: Maybe<string>;
                  number?: Maybe<string>;
                  type?: Maybe<string>;
                  id: string;
                  isOnceOff?: Maybe<boolean>;
                  isAutoPay: boolean;
                  isAutoWithdraw: boolean;
                }
              | {
                  __typename: "VirtualAccount";
                  id: string;
                  isOnceOff?: Maybe<boolean>;
                  isAutoPay: boolean;
                  isAutoWithdraw: boolean;
                }
            >
          >;
        }>;
      }
    | {
        __typename: "Person";
        id: string;
        ailoRN: string;
        paymentMethods?: Maybe<{
          __typename?: "PaginatedPaymentMethods";
          pageInfo?: Maybe<{ __typename?: "PageInfo"; total: number }>;
          items: Array<
            Maybe<
              | {
                  __typename: "BankAccount";
                  bsb?: Maybe<string>;
                  accountName?: Maybe<string>;
                  accountNumber?: Maybe<string>;
                  id: string;
                  isOnceOff?: Maybe<boolean>;
                  isAutoPay: boolean;
                  isAutoWithdraw: boolean;
                }
              | {
                  __typename: "BPay";
                  id: string;
                  isOnceOff?: Maybe<boolean>;
                  isAutoPay: boolean;
                  isAutoWithdraw: boolean;
                }
              | {
                  __typename: "CreditCard";
                  expiry?: Maybe<string>;
                  number?: Maybe<string>;
                  type?: Maybe<string>;
                  id: string;
                  isOnceOff?: Maybe<boolean>;
                  isAutoPay: boolean;
                  isAutoWithdraw: boolean;
                }
              | {
                  __typename: "VirtualAccount";
                  id: string;
                  isOnceOff?: Maybe<boolean>;
                  isAutoPay: boolean;
                  isAutoWithdraw: boolean;
                }
            >
          >;
        }>;
      }
  >;
};

export type GetPaymentMethodIdsForUserQueryVariables = Exact<{
  legalEntityId: Scalars["AiloRN"];
}>;

export type GetPaymentMethodIdsForUserQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | {
        __typename: "Company";
        id: string;
        ailorn: string;
        paymentMethods?: Maybe<{
          __typename?: "PaginatedPaymentMethods";
          items: Array<
            Maybe<
              | {
                  __typename: "BankAccount";
                  accountName?: Maybe<string>;
                  accountNumber?: Maybe<string>;
                  id: string;
                  isDefaultIn: boolean;
                  isDefaultOut: boolean;
                }
              | {
                  __typename: "BPay";
                  id: string;
                  isDefaultIn: boolean;
                  isDefaultOut: boolean;
                }
              | {
                  __typename: "CreditCard";
                  id: string;
                  isDefaultIn: boolean;
                  isDefaultOut: boolean;
                }
              | {
                  __typename: "VirtualAccount";
                  id: string;
                  isDefaultIn: boolean;
                  isDefaultOut: boolean;
                }
            >
          >;
        }>;
      }
    | {
        __typename: "Person";
        id: string;
        ailorn: string;
        paymentMethods?: Maybe<{
          __typename?: "PaginatedPaymentMethods";
          items: Array<
            Maybe<
              | {
                  __typename: "BankAccount";
                  accountName?: Maybe<string>;
                  accountNumber?: Maybe<string>;
                  id: string;
                  isDefaultIn: boolean;
                  isDefaultOut: boolean;
                }
              | {
                  __typename: "BPay";
                  id: string;
                  isDefaultIn: boolean;
                  isDefaultOut: boolean;
                }
              | {
                  __typename: "CreditCard";
                  id: string;
                  isDefaultIn: boolean;
                  isDefaultOut: boolean;
                }
              | {
                  __typename: "VirtualAccount";
                  id: string;
                  isDefaultIn: boolean;
                  isDefaultOut: boolean;
                }
            >
          >;
        }>;
      }
  >;
};

export type GetTenancyAutoPaymentDetailsQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetTenancyAutoPaymentDetailsQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    liability?: Maybe<{
      __typename?: "Liability";
      id: string;
      reference: string;
      autoPaymentDetails?: Maybe<{
        __typename?: "AutoPaymentDetails";
        paymentMethod?: Maybe<
          | { __typename?: "BankAccount"; id: string }
          | { __typename?: "BPay"; id: string }
          | { __typename?: "CreditCard"; id: string }
          | { __typename?: "VirtualAccount"; id: string }
        >;
      }>;
    }>;
  }>;
};

export type DeletePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars["ID"];
}>;

export type DeletePaymentMethodMutation = {
  __typename?: "Mutation";
  deletePaymentMethod: boolean;
};

export type GetPaymentMethodWithFeesQueryVariables = Exact<{
  paymentMethodId: Scalars["ID"];
}>;

export type GetPaymentMethodWithFeesQuery = {
  __typename?: "Query";
  paymentMethodById?: Maybe<
    | {
        __typename: "BankAccount";
        id: string;
        topUpFee?: Maybe<{
          __typename?: "TransactionFee";
          feeBps: number;
          feeFlatCents: number;
        }>;
      }
    | { __typename: "BPay"; id: string }
    | {
        __typename: "CreditCard";
        id: string;
        topUpFee?: Maybe<{
          __typename?: "TransactionFee";
          feeBps: number;
          feeFlatCents: number;
        }>;
      }
    | { __typename: "VirtualAccount"; id: string }
  >;
};

export type PayLiabilityMutationVariables = Exact<{
  amount: MoneyInput;
  idempotentKey: Scalars["GeneratedKey"];
  liabilityId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
}>;

export type PayLiabilityMutation = {
  __typename?: "Mutation";
  payLiability?: Maybe<{
    __typename?: "ResponseStatus";
    status: string;
    businessTransaction?: Maybe<{
      __typename?: "BusinessTransaction";
      id: string;
      createdAt: string;
    }>;
  }>;
};

export type GetManagementFolioQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetManagementFolioQuery = {
  __typename?: "Query";
  managementFolio?: Maybe<{
    __typename?: "ManagementFolio";
    id: string;
    ailorn: string;
    managements: Array<{
      __typename?: "Management";
      id: string;
      ailorn: string;
    }>;
  }>;
};

export type CompleteOnboardingTaskMutationVariables = Exact<{
  onboardingTaskId: OnboardingTaskId;
}>;

export type CompleteOnboardingTaskMutation = {
  __typename?: "Mutation";
  completeOnboardingTask?: Maybe<{
    __typename?: "UserOnboardingTask";
    onboardingTaskId: OnboardingTaskId;
  }>;
};

export type GetPersonDataQueryVariables = Exact<{
  id: Scalars["AiloRN"];
}>;

export type GetPersonDataQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | { __typename: "Company"; id: string }
    | {
        __typename: "Person";
        id: string;
        user?: Maybe<{
          __typename?: "User";
          onboardingCompletedAt?: Maybe<string>;
        }>;
      }
  >;
};

export type SendPhoneVerificationCodeMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
}>;

export type SendPhoneVerificationCodeMutation = {
  __typename?: "Mutation";
  sendPhoneVerificationCode?: Maybe<{
    __typename?: "SendPhoneVerificationCodeResponse";
    ok: boolean;
    error?: Maybe<{
      __typename?: "SendPhoneVerificationCodeResponseError";
      code: string;
      message?: Maybe<string>;
    }>;
  }>;
};

export type VerifyVerificationCodeMutationVariables = Exact<{
  phoneNumber: Scalars["String"];
  code: Scalars["String"];
}>;

export type VerifyVerificationCodeMutation = {
  __typename?: "Mutation";
  verifyPhoneVerificationCode?: Maybe<{
    __typename?: "VerifyPhoneVerificationCodeResponse";
    ok: boolean;
    error?: Maybe<{
      __typename?: "VerifyPhoneVerificationCodeResponseError";
      code: string;
      message?: Maybe<string>;
    }>;
  }>;
};

export type GetAnalyticsDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetAnalyticsDataQuery = {
  __typename?: "Query";
  effectiveUser?: Maybe<{
    __typename?: "User";
    id: number;
    person: {
      __typename?: "Person";
      id: string;
      firstName: string;
      lastName?: Maybe<string>;
      emailAddress?: Maybe<string>;
    };
    organisations: Array<{ __typename?: "Organisation"; id: string }>;
  }>;
  tenancies?: Maybe<{
    __typename?: "PaginatedTenancies";
    items: Array<{
      __typename?: "Tenancy";
      id: string;
      managingEntity?: Maybe<{
        __typename?: "Company";
        id: string;
        organisation: { __typename?: "Organisation"; id: string };
      }>;
    }>;
  }>;
  managements?: Maybe<{
    __typename?: "PaginatedManagements";
    items: Array<{
      __typename?: "Management";
      id: string;
      managingEntity?: Maybe<{
        __typename?: "Company";
        id: string;
        organisation: { __typename?: "Organisation"; id: string };
      }>;
    }>;
  }>;
};

export type GetTenancyDepositQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetTenancyDepositQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    deposit?: Maybe<{
      __typename?: "TenancyDeposit";
      id: string;
      status: TenancyDepositStatus;
    }>;
  }>;
};

export type GetUserOnboardingStateQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserOnboardingStateQuery = {
  __typename?: "Query";
  effectiveUser?: Maybe<{
    __typename?: "User";
    id: number;
    organisations: Array<{
      __typename?: "Organisation";
      id: string;
      availableFeatures: Array<{
        __typename?: "PlatformFeature";
        id: PlatformFeatureId;
      }>;
    }>;
    person: {
      __typename?: "Person";
      id: string;
      ailoRN: string;
      phoneNoVerified: boolean;
      bankAccounts?: Maybe<{
        __typename?: "PaginatedPaymentMethods";
        pageInfo?: Maybe<{ __typename?: "PageInfo"; total: number }>;
      }>;
      creditCards?: Maybe<{
        __typename?: "PaginatedPaymentMethods";
        pageInfo?: Maybe<{ __typename?: "PageInfo"; total: number }>;
      }>;
    };
    onboardingTasks: Array<{
      __typename?: "UserOnboardingTask";
      onboardingTaskId: OnboardingTaskId;
    }>;
  }>;
  actualUser?: Maybe<{ __typename?: "User"; id: number }>;
};

export type GetSetupDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetSetupDataQuery = {
  __typename?: "Query";
  companies?: Maybe<
    Array<{
      __typename: "Company";
      ailoRN: string;
      registeredEntityName: string;
      registeredEntityId?: Maybe<string>;
    }>
  >;
  tenancies?: Maybe<{
    __typename?: "PaginatedTenancies";
    items: Array<
      {
        __typename?: "Tenancy";
        id: string;
        ailoRN: string;
        managementAiloRN: string;
        managementFolioAilorn: string;
        startDate?: Maybe<string>;
        endDate?: Maybe<string>;
        deposit?: Maybe<{
          __typename?: "TenancyDeposit";
          id: string;
          status: TenancyDepositStatus;
          amount: { __typename?: "Money"; cents: number };
          liability?: Maybe<{ __typename?: "Liability"; id: string }>;
        }>;
        managingEntity?: Maybe<{
          __typename?: "Company";
          id: string;
          timezone: string;
          registeredEntityName: string;
          organisationId: string;
          organisation: {
            __typename?: "Organisation";
            name: string;
            effectiveUserContact?: Maybe<{
              __typename?: "Contact";
              ailorn: string;
            }>;
          };
        }>;
        tenantships: {
          __typename?: "PaginatedTenantships";
          items: Array<{ __typename?: "Tenantship"; tenantId: string }>;
        };
      } & TenancyPropertyFragment
    >;
  }>;
  managements?: Maybe<{
    __typename?: "PaginatedManagements";
    items: Array<
      {
        __typename?: "Management";
        id: string;
        ailoRN: string;
        endDate?: Maybe<string>;
        managementAgreements?: Maybe<{
          __typename?: "PaginatedManagementAgreements";
          items: Array<{
            __typename?: "ManagementAgreement";
            startDate?: Maybe<string>;
          }>;
        }>;
        managementFolio: {
          __typename?: "ManagementFolio";
          id: string;
          ailorn: string;
        };
        ownerships: Array<{
          __typename?: "Ownership";
          ownerId: string;
          sharesOwned: number;
        }>;
        managingEntity?: Maybe<{
          __typename?: "Company";
          organisationId: string;
          timezone: string;
          organisation: {
            __typename?: "Organisation";
            name: string;
            effectiveUserContact?: Maybe<{
              __typename?: "Contact";
              ailorn: string;
            }>;
          };
        }>;
      } & ManagementPropertyFragment
    >;
  }>;
  effectiveUser?: Maybe<{
    __typename?: "User";
    id: number;
    organisations: Array<{
      __typename?: "Organisation";
      id: string;
      availableFeatures: Array<{
        __typename?: "PlatformFeature";
        id: PlatformFeatureId;
      }>;
    }>;
    person: {
      __typename: "Person";
      id: string;
      ailoRN: string;
      firstName: string;
      lastName?: Maybe<string>;
      emailAddress?: Maybe<string>;
      photo?: Maybe<{
        __typename?: "File";
        id: string;
        url?: Maybe<string>;
        thumbnailUrl?: Maybe<string>;
      }>;
    };
  }>;
};

export type GetWalletAvailableBalanceQueryVariables = Exact<{
  legalEntityId: Scalars["AiloRN"];
}>;

export type GetWalletAvailableBalanceQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | {
        __typename: "Company";
        id: string;
        ailoRN: string;
        allWalletsAvailableBalance: { __typename?: "Money"; cents: number };
      }
    | {
        __typename: "Person";
        id: string;
        ailoRN: string;
        allWalletsAvailableBalance: { __typename?: "Money"; cents: number };
      }
  >;
};

export type AddBankAccountMutationVariables = Exact<{
  owner: Scalars["AiloRN"];
  details: BankAccountInput;
}>;

export type AddBankAccountMutation = {
  __typename?: "Mutation";
  addBankAccount?: Maybe<{
    __typename?: "BankAccount";
    id: string;
    accountName?: Maybe<string>;
    accountNumber?: Maybe<string>;
  }>;
};

export type AddCreditCardMutationVariables = Exact<{
  owner: Scalars["AiloRN"];
  details: CreditCardInput;
}>;

export type AddCreditCardMutation = {
  __typename?: "Mutation";
  addCreditCard?: Maybe<{
    __typename?: "CreditCard";
    id: string;
    category?: Maybe<string>;
    type?: Maybe<string>;
  }>;
};

export type GatewayCapturePaymentDetailsQueryVariables = Exact<{
  owner: Scalars["AiloRN"];
}>;

export type GatewayCapturePaymentDetailsQuery = {
  __typename?: "Query";
  gatewayCapturePaymentDetails?: Maybe<{
    __typename?: "GatewayCapturePaymentDetails";
    url?: Maybe<any>;
  }>;
};

export type CreateAutoWithdrawPlanMutationVariables = Exact<{
  createAutoWithdrawPlanInput: CreateAutoWithdrawPlanInputV2;
}>;

export type CreateAutoWithdrawPlanMutation = {
  __typename?: "Mutation";
  createAutoWithdrawPlan?: Maybe<{
    __typename?: "AutoWithdrawPlan";
    id: string;
    owner: string;
    walletId: string;
    enabled: boolean;
    createdBy: string;
    createdAt: string;
    details: {
      __typename?: "AutoWithdrawPlanDetails";
      id: string;
      autoWithdrawPlanId: string;
      timezoneId: string;
      startDate: string;
      endDate?: Maybe<string>;
      frequency: QuartzPlanFrequency;
      anniversary?: Maybe<number>;
      createdBy: string;
      createdAt: string;
      isLastDayOfTheMonth?: Maybe<boolean>;
      userFacingDescription?: Maybe<string>;
      setAsideAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
      paymentMethodDestinations: Array<{
        __typename?: "PaymentMethodDestination";
        paymentMethodId: string;
        share: number;
      }>;
    };
  }>;
};

export type CancelAutoWithdrawPlanMutationVariables = Exact<{
  cancelAutoWithdrawInput: CancelAutoWithdrawPlanInput;
}>;

export type CancelAutoWithdrawPlanMutation = {
  __typename?: "Mutation";
  cancelAutoWithdrawPlan?: Maybe<{
    __typename?: "AutoWithdrawPlan";
    id: string;
    enabled: boolean;
    details: {
      __typename?: "AutoWithdrawPlanDetails";
      id: string;
      frequency: QuartzPlanFrequency;
      paymentMethodDestinations: Array<{
        __typename?: "PaymentMethodDestination";
        paymentMethodId: string;
        share: number;
      }>;
    };
  }>;
};

export type GetOwnerForWalletQueryVariables = Exact<{
  walletId: Scalars["ID"];
}>;

export type GetOwnerForWalletQuery = {
  __typename?: "Query";
  walletById?: Maybe<{
    __typename?: "Wallet";
    id: string;
    owner: { __typename?: "WalletOwner"; id: string; reference: string };
  }>;
};

export type GetDefaultWalletWithAutoWithdrawInfoQueryVariables = Exact<{
  walletOwnerReference: Scalars["AiloRN"];
  currentUserLegalEntity: Scalars["AiloRN"];
}>;

export type GetDefaultWalletWithAutoWithdrawInfoQuery = {
  __typename?: "Query";
  walletByWalletOwnerReference?: Maybe<{
    __typename?: "Wallet";
    id: string;
    name: string;
    autoWithdrawPlans?: Maybe<{
      __typename?: "PaginatedAutoWithdrawPlans";
      items?: Maybe<
        Array<{ __typename?: "AutoWithdrawPlan"; id: string; enabled: boolean }>
      >;
    }>;
  }>;
};

export type GetBillAttachmentsQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type GetBillAttachmentsQuery = {
  __typename?: "Query";
  billById: {
    __typename?: "Bill";
    ailoRN: string;
    attachments: Array<{
      __typename?: "BillAttachment";
      id: string;
      file?: Maybe<{
        __typename?: "File";
        id: string;
        fileName: string;
        fileSize?: Maybe<number>;
        contentType?: Maybe<string>;
        url?: Maybe<string>;
      }>;
    }>;
  };
};

export type GetSecondaryBillDetailsQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type GetSecondaryBillDetailsQuery = {
  __typename?: "Query";
  billById: {
    __typename?: "Bill";
    ailoRN: string;
    status: BillStatus;
    agencyStatus: BillAgencyStatus;
    description?: Maybe<string>;
    invoiceNumber?: Maybe<string>;
    createdAt: string;
    dueDate: string;
    issueDate: string;
    paymentReference?: Maybe<{
      __typename?: "PaymentReference";
      id: string;
      crn?: Maybe<string>;
      supplierPaymentMethodCompanion?: Maybe<{
        __typename?: "PaymentMethodCompanion";
        paymentMethod:
          | { __typename: "BankAccount" }
          | { __typename: "BPay"; billerCode: string }
          | { __typename: "CreditCard" }
          | { __typename: "VirtualAccount" };
      }>;
    }>;
    taxCategory: { __typename?: "TaxCategory"; name: string };
    liabilityState?: Maybe<{
      __typename?: "BillLiabilityState";
      paymentStatus?: Maybe<string>;
      paidAt?: Maybe<string>;
    }>;
    liability?: Maybe<{
      __typename?: "Liability";
      id: string;
      lastPaymentDate?: Maybe<string>;
    }>;
  };
};

export type GetBillLineItemsQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type GetBillLineItemsQuery = {
  __typename?: "Query";
  billById: {
    __typename?: "Bill";
    ailoRN: string;
    amount: { __typename?: "Money"; cents: number };
    lineItems?: Maybe<{
      __typename?: "PaginatedLineItems";
      items: Array<{
        __typename?: "LineItem";
        ailoRN: string;
        description: string;
        isTax: boolean;
        taxInclusiveAmount: { __typename?: "Money"; cents: number };
        taxAmount: { __typename?: "Money"; cents: number };
      }>;
    }>;
  };
};

export type GetMainBillDetailsQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type GetMainBillDetailsQuery = {
  __typename?: "Query";
  billById: {
    __typename?: "Bill";
    ailoRN: string;
    agencyStatus: BillAgencyStatus;
    status: BillStatus;
    supplier?: Maybe<{
      __typename?: "Supplier";
      ailoRN: string;
      name?: Maybe<string>;
      internalReference?: Maybe<string>;
      internalLegalEntity?: Maybe<
        {
          __typename?: "LegalEntityCompanion";
        } & LegalEntityCompanionOrganisationFragmentFragment
      >;
    }>;
    amount: { __typename?: "Money"; cents: number };
    liabilityState?: Maybe<{
      __typename?: "BillLiabilityState";
      paymentStatus?: Maybe<string>;
    }>;
  };
};

export type GetPayBillDetailsQueryVariables = Exact<{
  billId: Scalars["ID"];
}>;

export type GetPayBillDetailsQuery = {
  __typename?: "Query";
  billById: {
    __typename?: "Bill";
    ailoRN: string;
    amount: { __typename?: "Money"; cents: number };
    liability?: Maybe<{
      __typename?: "Liability";
      id: string;
      reference: string;
      dueAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
    }>;
    supplier?: Maybe<{
      __typename?: "Supplier";
      ailoRN: string;
      name?: Maybe<string>;
      internalReference?: Maybe<string>;
      internalLegalEntity?: Maybe<
        {
          __typename?: "LegalEntityCompanion";
        } & LegalEntityCompanionOrganisationFragmentFragment
      >;
    }>;
  };
};

export type CreateLiabilityPaymentPlanMutationVariables = Exact<{
  createLiabilityPaymentPlanInput: CreateLiabilityPaymentPlanInput;
}>;

export type CreateLiabilityPaymentPlanMutation = {
  __typename?: "Mutation";
  createLiabilityPaymentPlan?: Maybe<{
    __typename?: "LiabilityPaymentPlan";
    id: string;
    owner: string;
    liabilityId: string;
    enabled: boolean;
    createdBy: string;
    createdAt: string;
    details: {
      __typename?: "LiabilityPaymentPlanDetails";
      id: string;
      liabilityPaymentPlanId: string;
      paymentMethodId: string;
      timezoneId: string;
      startDate: string;
      endDate?: Maybe<string>;
      frequency: QuartzPlanFrequency;
      anniversary: number;
      createdBy: string;
      createdAt: string;
      amount: { __typename?: "Money"; cents: number };
    };
  }>;
};

export type CancelLiabilityPaymentPlanMutationVariables = Exact<{
  cancelLiabilityPaymentPlanInput: CancelLiabilityPaymentPlanInput;
}>;

export type CancelLiabilityPaymentPlanMutation = {
  __typename?: "Mutation";
  cancelLiabilityPaymentPlan?: Maybe<{
    __typename?: "LiabilityPaymentPlan";
    id: string;
    enabled: boolean;
    details: {
      __typename?: "LiabilityPaymentPlanDetails";
      id: string;
      frequency: QuartzPlanFrequency;
    };
  }>;
};

export type GetLiabilityPaymentPlanByIdQueryVariables = Exact<{
  liabilityPaymentPlanId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
}>;

export type GetLiabilityPaymentPlanByIdQuery = {
  __typename?: "Query";
  liabilityPaymentPlanById?: Maybe<{
    __typename?: "LiabilityPaymentPlan";
    id: string;
    owner: string;
    liabilityId: string;
    enabled: boolean;
    createdBy: string;
    createdAt: string;
    details: {
      __typename?: "LiabilityPaymentPlanDetails";
      id: string;
      liabilityPaymentPlanId: string;
      paymentMethodId: string;
      timezoneId: string;
      startDate: string;
      endDate?: Maybe<string>;
      frequency: QuartzPlanFrequency;
      anniversary: number;
      createdBy: string;
      createdAt: string;
      amount: { __typename?: "Money"; cents: number };
    };
  }>;
};

export type UpdateLiabilityPaymentPlanMutationVariables = Exact<{
  updateLiabilityPaymentPlanInput: UpdateLiabilityPaymentPlanInput;
}>;

export type UpdateLiabilityPaymentPlanMutation = {
  __typename?: "Mutation";
  updateLiabilityPaymentPlan?: Maybe<{
    __typename?: "LiabilityPaymentPlan";
    enabled: boolean;
  }>;
};

export type GetTenanciesWithLiabilitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetTenanciesWithLiabilitiesQuery = {
  __typename?: "Query";
  tenancies?: Maybe<{
    __typename?: "PaginatedTenancies";
    items: Array<
      {
        __typename?: "Tenancy";
        id: string;
        liability?: Maybe<{
          __typename?: "Liability";
          id: string;
          reference: string;
          autoPaymentDetails?: Maybe<{
            __typename?: "AutoPaymentDetails";
            paymentMethod?: Maybe<
              | { __typename?: "BankAccount"; id: string }
              | { __typename?: "BPay"; id: string }
              | { __typename?: "CreditCard"; id: string }
              | { __typename?: "VirtualAccount"; id: string }
            >;
          }>;
        }>;
      } & TenancyPropertyFragment
    >;
  }>;
};

export type SetBillLiabilityStateMutationVariables = Exact<{
  billPaymentDetails: BillLiabilityStateInput;
}>;

export type SetBillLiabilityStateMutation = {
  __typename?: "Mutation";
  setBillLiabilityState?: Maybe<{
    __typename?: "BillLiabilityState";
    paidAt?: Maybe<string>;
    dueAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
};

export type GetPaymentKeyAndAutoPaymentQueryVariables = Exact<{
  liabilityId: Scalars["ID"];
}>;

export type GetPaymentKeyAndAutoPaymentQuery = {
  __typename?: "Query";
  liabilityById?: Maybe<{
    __typename?: "Liability";
    id: string;
    reference: string;
    paymentKey: string;
    autoPaymentDetails?: Maybe<{
      __typename?: "AutoPaymentDetails";
      paymentMethod?: Maybe<
        | { __typename?: "BankAccount"; id: string }
        | { __typename?: "BPay"; id: string }
        | { __typename?: "CreditCard"; id: string }
        | { __typename?: "VirtualAccount"; id: string }
      >;
    }>;
  }>;
};

export type GetRentPaymentSuccessDetailsQueryVariables = Exact<{
  liabilityId: Scalars["ID"];
  tenancyId: Scalars["ID"];
}>;

export type GetRentPaymentSuccessDetailsQuery = {
  __typename?: "Query";
  liabilityById?: Maybe<{
    __typename?: "Liability";
    id: string;
    reference: string;
    nextDueDate?: Maybe<string>;
    autoPaymentDetails?: Maybe<{
      __typename?: "AutoPaymentDetails";
      paymentMethod?: Maybe<
        | { __typename?: "BankAccount"; id: string }
        | { __typename?: "BPay"; id: string }
        | { __typename?: "CreditCard"; id: string }
        | { __typename?: "VirtualAccount"; id: string }
      >;
    }>;
    terminationAmountDue?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    endDate?: Maybe<string>;
  }>;
};

export type GetPayRentLiabilityDetailsQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
  liabilityId: Scalars["ID"];
}>;

export type GetPayRentLiabilityDetailsQuery = {
  __typename?: "Query";
  liabilityById?: Maybe<{
    __typename?: "Liability";
    id: string;
    reference: string;
    paymentKey: string;
    autoPaymentDetails?: Maybe<{
      __typename?: "AutoPaymentDetails";
      paymentMethod?: Maybe<
        | { __typename?: "BankAccount"; id: string }
        | { __typename?: "BPay"; id: string }
        | { __typename?: "CreditCard"; id: string }
        | { __typename?: "VirtualAccount"; id: string }
      >;
    }>;
    terminationAmountDue?: Maybe<{ __typename?: "Money"; cents: number }>;
  }>;
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    endDate?: Maybe<string>;
  }>;
};

export type GetPaymentMethodPaidWithQueryVariables = Exact<{
  paymentMethodId: Scalars["ID"];
}>;

export type GetPaymentMethodPaidWithQuery = {
  __typename?: "Query";
  paymentMethodById?: Maybe<
    | { __typename: "BankAccount"; id: string; isOnceOff?: Maybe<boolean> }
    | { __typename: "BPay"; id: string; isOnceOff?: Maybe<boolean> }
    | { __typename: "CreditCard"; id: string; isOnceOff?: Maybe<boolean> }
    | { __typename: "VirtualAccount"; id: string; isOnceOff?: Maybe<boolean> }
  >;
};

export type SetupAutoPayRentMutationVariables = Exact<{
  liabilityId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
}>;

export type SetupAutoPayRentMutation = {
  __typename?: "Mutation";
  createUpdateAutoPay?: Maybe<{
    __typename?: "ResponseStatus";
    status: string;
  }>;
};

export type CancelAutoPayRentMutationVariables = Exact<{
  liabilityId: Scalars["ID"];
}>;

export type CancelAutoPayRentMutation = {
  __typename?: "Mutation";
  cancelAutoPay?: Maybe<{ __typename?: "ResponseStatus"; status: string }>;
};

export type GetAutoPayTenancyDetailsQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetAutoPayTenancyDetailsQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      liability?: Maybe<{
        __typename?: "Liability";
        id: string;
        reference: string;
        nextChargeAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
      }>;
    } & TenancyPropertyFragment
  >;
};

export type GetPaymentKeyQueryVariables = Exact<{
  liabilityId: Scalars["ID"];
}>;

export type GetPaymentKeyQuery = {
  __typename?: "Query";
  liabilityById?: Maybe<{
    __typename?: "Liability";
    id: string;
    paymentKey: string;
  }>;
};

export type GetPayDepositDetailsQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetPayDepositDetailsQuery = {
  __typename?: "Query";
  tenancy?: Maybe<
    {
      __typename?: "Tenancy";
      id: string;
      deposit?: Maybe<{
        __typename?: "TenancyDeposit";
        id: string;
        status: TenancyDepositStatus;
        amount: { __typename?: "Money"; cents: number };
        liability?: Maybe<{ __typename?: "Liability"; id: string }>;
      }>;
      managingEntity?: Maybe<{
        __typename?: "Company";
        id: string;
        registeredEntityName: string;
      }>;
    } & TenancyPropertyFragment
  >;
};

export type GetOnlyPaymentMethodByIdQueryVariables = Exact<{
  paymentMethodId: Scalars["ID"];
}>;

export type GetOnlyPaymentMethodByIdQuery = {
  __typename?: "Query";
  paymentMethodById?: Maybe<
    | {
        __typename: "BankAccount";
        accountName?: Maybe<string>;
        accountNumber?: Maybe<string>;
        id: string;
      }
    | { __typename: "BPay"; id: string }
    | {
        __typename: "CreditCard";
        number?: Maybe<string>;
        type?: Maybe<string>;
        id: string;
      }
    | { __typename: "VirtualAccount"; id: string }
  >;
};

export type GetWalletKycVerificationStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetWalletKycVerificationStatusQuery = {
  __typename?: "Query";
  effectiveUser?: Maybe<{
    __typename?: "User";
    id: number;
    person: {
      __typename?: "Person";
      id: string;
      kycVerifiedAccordingToLedgerService?: Maybe<boolean>;
    };
  }>;
};

export type GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQueryVariables =
  Exact<{
    payerLegalEntityId: Scalars["AiloRN"];
  }>;

export type GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery = {
  __typename?: "Query";
  tenancies?: Maybe<{
    __typename?: "PaginatedTenancies";
    items: Array<
      {
        __typename?: "Tenancy";
        id: string;
        liability?: Maybe<{
          __typename?: "Liability";
          id: string;
          reference: string;
          autoPaymentDetails?: Maybe<{
            __typename?: "AutoPaymentDetails";
            paymentMethod?: Maybe<
              | { __typename?: "BankAccount"; id: string }
              | { __typename?: "BPay"; id: string }
              | { __typename?: "CreditCard"; id: string }
              | { __typename?: "VirtualAccount"; id: string }
            >;
          }>;
          paymentPlans?: Maybe<{
            __typename?: "PaginatedLiabilityPaymentPlans";
            items?: Maybe<
              Array<{
                __typename?: "LiabilityPaymentPlan";
                id: string;
                details: {
                  __typename?: "LiabilityPaymentPlanDetails";
                  id: string;
                  frequency: QuartzPlanFrequency;
                };
              }>
            >;
          }>;
        }>;
      } & TenancyPropertyFragment
    >;
  }>;
};

export type GetAutoWithdrawPlansWithWalletQueryVariables = Exact<{
  legalEntityId: Scalars["AiloRN"];
}>;

export type GetAutoWithdrawPlansWithWalletQuery = {
  __typename?: "Query";
  autoWithdrawPlans?: Maybe<{
    __typename?: "PaginatedAutoWithdrawPlans";
    items?: Maybe<
      Array<{
        __typename?: "AutoWithdrawPlan";
        id: string;
        enabled: boolean;
        wallet: {
          __typename?: "Wallet";
          id: string;
          name: string;
          owner: { __typename?: "WalletOwner"; id: string; reference: string };
        };
        details: {
          __typename?: "AutoWithdrawPlanDetails";
          id: string;
          frequency: QuartzPlanFrequency;
          startDate: string;
          anniversaryDaysOfMonth?: Maybe<Array<number>>;
          isLastDayOfTheMonth?: Maybe<boolean>;
        };
      }>
    >;
  }>;
};

export type CancelEmailVerificationsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CancelEmailVerificationsMutation = {
  __typename?: "Mutation";
  cancelEmailVerifications?: Maybe<boolean>;
};

export type GetPaymentMethodByIdQueryVariables = Exact<{
  paymentMethodId: Scalars["ID"];
  legalEntityId: Scalars["AiloRN"];
}>;

export type GetPaymentMethodByIdQuery = {
  __typename?: "Query";
  paymentMethodById?: Maybe<
    | {
        __typename: "BankAccount";
        bsb?: Maybe<string>;
        accountName?: Maybe<string>;
        accountNumber?: Maybe<string>;
        id: string;
        isOnceOff?: Maybe<boolean>;
        isAutoPay: boolean;
        isAutoWithdraw: boolean;
      }
    | {
        __typename: "BPay";
        id: string;
        isOnceOff?: Maybe<boolean>;
        isAutoPay: boolean;
        isAutoWithdraw: boolean;
      }
    | {
        __typename: "CreditCard";
        expiry?: Maybe<string>;
        number?: Maybe<string>;
        type?: Maybe<string>;
        id: string;
        isOnceOff?: Maybe<boolean>;
        isAutoPay: boolean;
        isAutoWithdraw: boolean;
      }
    | {
        __typename: "VirtualAccount";
        id: string;
        isOnceOff?: Maybe<boolean>;
        isAutoPay: boolean;
        isAutoWithdraw: boolean;
      }
  >;
  legalEntity?: Maybe<
    | {
        __typename: "Company";
        id: string;
        ailoRN: string;
        paymentMethods?: Maybe<{
          __typename?: "PaginatedPaymentMethods";
          pageInfo?: Maybe<{ __typename?: "PageInfo"; total: number }>;
        }>;
      }
    | {
        __typename: "Person";
        id: string;
        ailoRN: string;
        paymentMethods?: Maybe<{
          __typename?: "PaginatedPaymentMethods";
          pageInfo?: Maybe<{ __typename?: "PageInfo"; total: number }>;
        }>;
      }
  >;
};

export type GetWalletTotalBalanceQueryVariables = Exact<{
  legalEntityId: Scalars["AiloRN"];
}>;

export type GetWalletTotalBalanceQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | {
        __typename: "Company";
        id: string;
        ailoRN: string;
        allWalletsTotalBalance: { __typename?: "Money"; cents: number };
      }
    | {
        __typename: "Person";
        id: string;
        ailoRN: string;
        allWalletsTotalBalance: { __typename?: "Money"; cents: number };
      }
  >;
};

export type VerifyEmailMutationVariables = Exact<{
  emailAddress: Scalars["String"];
  verificationCode: Scalars["String"];
}>;

export type VerifyEmailMutation = {
  __typename?: "Mutation";
  verifyEmail?: Maybe<
    | {
        __typename: "VerifyEmailSuccessResponse";
        user: {
          __typename?: "User";
          id: number;
          person: {
            __typename?: "Person";
            id: string;
            emailAddress?: Maybe<string>;
            pendingEmailAddress?: Maybe<string>;
          };
        };
      }
    | {
        __typename: "VerifyEmailErrorResponse";
        errorCode: VerifyEmailErrorCode;
      }
  >;
};

export type GetCurrentPersonProfileDetailsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentPersonProfileDetailsQuery = {
  __typename?: "Query";
  effectiveUser?: Maybe<{
    __typename?: "User";
    id: number;
    person: {
      __typename: "Person";
      id: string;
      ailoRN: string;
      kycVerified: boolean;
      firstName: string;
      legalFirstName: string;
      legalMiddleName?: Maybe<string>;
      preferredName?: Maybe<string>;
      lastName?: Maybe<string>;
      emailAddress?: Maybe<string>;
      pendingEmailAddress?: Maybe<string>;
      phoneNo?: Maybe<string>;
      unitStreetNumber?: Maybe<string>;
      streetName?: Maybe<string>;
      suburb?: Maybe<string>;
      state?: Maybe<string>;
      postcode?: Maybe<string>;
      country?: Maybe<string>;
      birthDate?: Maybe<string>;
      photo?: Maybe<{
        __typename?: "File";
        id: string;
        url?: Maybe<string>;
        thumbnailUrl?: Maybe<string>;
      }>;
    };
  }>;
};

export type SendEmailVerificationCodeMutationVariables = Exact<{
  emailAddress: Scalars["String"];
}>;

export type SendEmailVerificationCodeMutation = {
  __typename?: "Mutation";
  sendEmailVerificationCode?: Maybe<
    | { __typename: "SendEmailVerificationSuccessResponse"; ok: boolean }
    | {
        __typename: "SendEmailVerificationErrorResponse";
        errorCode: SendEmailVerificationErrorCode;
      }
  >;
};

export type UpdatePersonProfileDetailsMutationVariables = Exact<{
  input: UpdatePersonProfileDetailsInput;
}>;

export type UpdatePersonProfileDetailsMutation = {
  __typename?: "Mutation";
  updatePersonProfileDetails?: Maybe<{
    __typename?: "Person";
    id: string;
    ailoRN: string;
    kycVerified: boolean;
    firstName: string;
    legalFirstName: string;
    legalMiddleName?: Maybe<string>;
    preferredName?: Maybe<string>;
    lastName?: Maybe<string>;
    emailAddress?: Maybe<string>;
    phoneNo?: Maybe<string>;
    unitStreetNumber?: Maybe<string>;
    streetName?: Maybe<string>;
    suburb?: Maybe<string>;
    state?: Maybe<string>;
    postcode?: Maybe<string>;
    country?: Maybe<string>;
    birthDate?: Maybe<string>;
    photo?: Maybe<{
      __typename?: "File";
      id: string;
      url?: Maybe<string>;
      thumbnailUrl?: Maybe<string>;
    }>;
  }>;
};

export type WithdrawFromWalletMutationVariables = Exact<{
  withdrawInput: WithdrawInput;
}>;

export type WithdrawFromWalletMutation = {
  __typename?: "Mutation";
  withdrawFromWallet?: Maybe<{
    __typename?: "ResponseStatus";
    status: string;
    businessTransaction?: Maybe<{
      __typename?: "BusinessTransaction";
      paymentChannel?: Maybe<string>;
    }>;
  }>;
};

export type GetBillsDueSoonQueryVariables = Exact<{
  payerAilorns: Array<Scalars["AiloRN"]>;
  dueDate?: Maybe<LocalDateRangeCondition>;
}>;

export type GetBillsDueSoonQuery = {
  __typename?: "Query";
  bills?: Maybe<{
    __typename?: "PaginatedBills";
    pageInfo: {
      __typename?: "BidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
    };
    items: Array<{
      __typename?: "Bill";
      id: string;
      ailorn: string;
      amount: { __typename?: "Money"; cents: number };
    }>;
  }>;
};

export type GetWalletForTransferQueryVariables = Exact<{
  walletId: Scalars["ID"];
}>;

export type GetWalletForTransferQuery = {
  __typename?: "Query";
  walletById?: Maybe<{
    __typename?: "Wallet";
    id: string;
    idempotencyKey: string;
    availableBalance: { __typename?: "Money"; cents: number };
    owner: {
      __typename?: "WalletOwner";
      id: string;
      reference: string;
      dueFeeAmount: { __typename?: "Money"; cents: number };
    };
  }>;
};

export type GetPaymentMethodForTransferQueryVariables = Exact<{
  paymentMethodId: Scalars["ID"];
}>;

export type GetPaymentMethodForTransferQuery = {
  __typename?: "Query";
  paymentMethodById?: Maybe<
    | {
        __typename: "BankAccount";
        id: string;
        accountName?: Maybe<string>;
        accountNumber?: Maybe<string>;
      }
    | { __typename: "BPay" }
    | { __typename: "CreditCard" }
    | { __typename: "VirtualAccount" }
  >;
};

export type GetWalletsForTransferFundsQueryVariables = Exact<{
  legalEntityId: Scalars["AiloRN"];
}>;

export type GetWalletsForTransferFundsQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | {
        __typename: "Company";
        id: string;
        ailoRN: string;
        wallets: Array<{
          __typename?: "Wallet";
          id: string;
          name: string;
          availableBalance: { __typename?: "Money"; cents: number };
          autoWithdrawPlans?: Maybe<{
            __typename?: "PaginatedAutoWithdrawPlans";
            items?: Maybe<
              Array<{ __typename?: "AutoWithdrawPlan"; id: string }>
            >;
          }>;
        }>;
      }
    | {
        __typename: "Person";
        id: string;
        ailoRN: string;
        wallets: Array<{
          __typename?: "Wallet";
          id: string;
          name: string;
          availableBalance: { __typename?: "Money"; cents: number };
          autoWithdrawPlans?: Maybe<{
            __typename?: "PaginatedAutoWithdrawPlans";
            items?: Maybe<
              Array<{ __typename?: "AutoWithdrawPlan"; id: string }>
            >;
          }>;
        }>;
      }
  >;
};

export type GetMyWalletsQueryVariables = Exact<{
  legalEntityId: Scalars["AiloRN"];
}>;

export type GetMyWalletsQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | {
        __typename: "Company";
        id: string;
        ailoRN: string;
        wallets: Array<{
          __typename?: "Wallet";
          id: string;
          name: string;
          availableBalance: { __typename?: "Money"; cents: number };
          totalBalance: { __typename?: "Money"; cents: number };
          autoWithdrawPlans?: Maybe<{
            __typename?: "PaginatedAutoWithdrawPlans";
            items?: Maybe<
              Array<{ __typename?: "AutoWithdrawPlan"; id: string }>
            >;
          }>;
        }>;
      }
    | {
        __typename: "Person";
        id: string;
        ailoRN: string;
        wallets: Array<{
          __typename?: "Wallet";
          id: string;
          name: string;
          availableBalance: { __typename?: "Money"; cents: number };
          totalBalance: { __typename?: "Money"; cents: number };
          autoWithdrawPlans?: Maybe<{
            __typename?: "PaginatedAutoWithdrawPlans";
            items?: Maybe<
              Array<{ __typename?: "AutoWithdrawPlan"; id: string }>
            >;
          }>;
        }>;
      }
  >;
};

export type GetAllWalletsBalancesQueryVariables = Exact<{
  legalEntityId: Scalars["AiloRN"];
}>;

export type GetAllWalletsBalancesQuery = {
  __typename?: "Query";
  legalEntity?: Maybe<
    | {
        __typename: "Company";
        id: string;
        ailoRN: string;
        allWalletsTotalBalance: { __typename?: "Money"; cents: number };
        allWalletsAvailableBalance: { __typename?: "Money"; cents: number };
      }
    | {
        __typename: "Person";
        id: string;
        ailoRN: string;
        allWalletsTotalBalance: { __typename?: "Money"; cents: number };
        allWalletsAvailableBalance: { __typename?: "Money"; cents: number };
      }
  >;
};

export type GetTotalClearedRentCountForUserQueryVariables = Exact<{
  ownerRef: Scalars["AiloRN"];
}>;

export type GetTotalClearedRentCountForUserQuery = {
  __typename?: "Query";
  totalClearedRentReceivedInWallets: {
    __typename?: "TotalClearedRentReceived";
    count: number;
  };
};

export type GetManagementLatestTenancyDetailsQueryVariables = Exact<{
  managementId: Scalars["ID"];
}>;

export type GetManagementLatestTenancyDetailsQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      liability?: Maybe<{
        __typename?: "Liability";
        id: string;
        reference: string;
        effectivePaidToDate?: Maybe<string>;
        lastPaymentDate?: Maybe<string>;
      }>;
      currentRent?: Maybe<{
        __typename?: "Rent";
        id: string;
        amountInCents: string;
        period: RentFrequency;
      }>;
    }>;
  }>;
};

export type GetManagementLatestTenancyDetailsWithPaymentDatesQueryVariables =
  Exact<{
    managementId: Scalars["ID"];
  }>;

export type GetManagementLatestTenancyDetailsWithPaymentDatesQuery = {
  __typename?: "Query";
  management?: Maybe<{
    __typename?: "Management";
    id: string;
    mostRecentTenancy?: Maybe<{
      __typename?: "Tenancy";
      id: string;
      liability?: Maybe<{
        __typename?: "Liability";
        id: string;
        reference: string;
        effectivePaidToDate?: Maybe<string>;
        firstPaymentDate?: Maybe<string>;
        lastPaymentDate?: Maybe<string>;
      }>;
      currentRent?: Maybe<{
        __typename?: "Rent";
        id: string;
        amountInCents: string;
        period: RentFrequency;
      }>;
    }>;
  }>;
};

export type GetAutoWithdrawPlansForOwnerQueryVariables = Exact<{
  walletOwnerReference: Scalars["AiloRN"];
  currentUserLegalEntity: Scalars["AiloRN"];
}>;

export type GetAutoWithdrawPlansForOwnerQuery = {
  __typename?: "Query";
  walletByWalletOwnerReference?: Maybe<{
    __typename?: "Wallet";
    id: string;
    autoWithdrawPlans?: Maybe<{
      __typename?: "PaginatedAutoWithdrawPlans";
      items?: Maybe<
        Array<{ __typename?: "AutoWithdrawPlan"; id: string; enabled: boolean }>
      >;
    }>;
  }>;
};

export type GetNumTransactionsQueryVariables = Exact<{
  ownerRef: Scalars["AiloRN"];
  pageSize: Scalars["Int"];
}>;

export type GetNumTransactionsQuery = {
  __typename?: "Query";
  walletEntriesWithStatusByOwnerRef?: Maybe<{
    __typename?: "PaginatedWalletEntriesWithBtStatus";
    pageInfo: { __typename?: "LedgerBidirectionalPageInfo"; total: number };
  }>;
};

export type GetPropertyWalletWithAutoWithdrawInfoQueryVariables = Exact<{
  walletOwnerReference: Scalars["AiloRN"];
  currentUserLegalEntity: Scalars["AiloRN"];
}>;

export type GetPropertyWalletWithAutoWithdrawInfoQuery = {
  __typename?: "Query";
  walletByWalletOwnerReference?: Maybe<{
    __typename?: "Wallet";
    id: string;
    name: string;
    availableBalance: { __typename?: "Money"; cents: number };
    totalBalance: { __typename?: "Money"; cents: number };
    owner: {
      __typename?: "WalletOwner";
      id: string;
      dueFeeAmount: { __typename?: "Money"; cents: number };
    };
    autoWithdrawPlans?: Maybe<{
      __typename?: "PaginatedAutoWithdrawPlans";
      items?: Maybe<
        Array<{
          __typename?: "AutoWithdrawPlan";
          id: string;
          enabled: boolean;
          owner: string;
        }>
      >;
    }>;
  }>;
};

export type GetTenancyLiabilityQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
  payerLegalEntityId: Scalars["AiloRN"];
}>;

export type GetTenancyLiabilityQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    endDate?: Maybe<string>;
    liability?: Maybe<{
      __typename?: "Liability";
      id: string;
      reference: string;
      paidTo?: Maybe<string>;
      dueDate?: Maybe<string>;
      nextDueDate?: Maybe<string>;
      terminationDate?: Maybe<string>;
      overdueAmount: { __typename?: "Money"; cents: number };
      nextDueAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
      autoPaymentDetails?: Maybe<{
        __typename?: "AutoPaymentDetails";
        paymentMethod?: Maybe<
          | { __typename?: "BankAccount"; id: string }
          | { __typename?: "BPay"; id: string }
          | { __typename?: "CreditCard"; id: string }
          | { __typename?: "VirtualAccount"; id: string }
        >;
      }>;
      terminationAmountDue?: Maybe<{ __typename?: "Money"; cents: number }>;
      paymentPlans?: Maybe<{
        __typename?: "PaginatedLiabilityPaymentPlans";
        pageInfo?: Maybe<{ __typename?: "PageInfo"; total: number }>;
      }>;
    }>;
  }>;
};

export type GetTenancyDepositDetailsQueryVariables = Exact<{
  tenancyId: Scalars["ID"];
}>;

export type GetTenancyDepositDetailsQuery = {
  __typename?: "Query";
  tenancy?: Maybe<{
    __typename?: "Tenancy";
    id: string;
    deposit?: Maybe<{
      __typename?: "TenancyDeposit";
      id: string;
      status: TenancyDepositStatus;
      paidAt?: Maybe<string>;
      amount: { __typename?: "Money"; cents: number };
      liability?: Maybe<{
        __typename?: "Liability";
        id: string;
        entries?: Maybe<{
          __typename?: "PaginatedLiabilityEntries";
          items: Array<
            | { __typename?: "AdjustmentLiabilityEntry" }
            | {
                __typename?: "PaymentLiabilityEntry";
                businessTransaction: {
                  __typename?: "BusinessTransaction";
                  expectedClearedAt?: Maybe<string>;
                };
              }
            | { __typename?: "PlanBasedLiabilityEntry" }
          >;
        }>;
      }>;
    }>;
  }>;
};

export type RentLiabilityFragment = {
  __typename?: "Liability";
  id: string;
  category: string;
  firstPaymentDate?: Maybe<string>;
  nextDueDate?: Maybe<string>;
  paidToDate?: Maybe<string>;
  reference: string;
  taxCategory: string;
  terminationDate?: Maybe<string>;
  nextDueAmount?: Maybe<{ __typename?: "Money"; cents: number }>;
  overdueAmount: { __typename?: "Money"; cents: number };
  entries?: Maybe<{
    __typename?: "PaginatedLiabilityEntries";
    pageInfo: {
      __typename?: "LedgerBidirectionalPageInfo";
      total: number;
      hasNext: boolean;
      nextCursor?: Maybe<string>;
    };
    items: Array<
      | ({
          __typename?: "AdjustmentLiabilityEntry";
        } & RentLiabilityEntry_AdjustmentLiabilityEntry_Fragment)
      | ({
          __typename?: "PaymentLiabilityEntry";
        } & RentLiabilityEntry_PaymentLiabilityEntry_Fragment)
      | ({
          __typename?: "PlanBasedLiabilityEntry";
        } & RentLiabilityEntry_PlanBasedLiabilityEntry_Fragment)
    >;
  }>;
};

type RentLiabilityEntry_AdjustmentLiabilityEntry_Fragment = {
  __typename: "AdjustmentLiabilityEntry";
  descriptionIsCustom?: Maybe<boolean>;
  description?: Maybe<string>;
  id: string;
  createdAt: string;
  effectiveAt: string;
  entryType: SourceType;
  amount: { __typename?: "Money"; cents: number };
};

type RentLiabilityEntry_PaymentLiabilityEntry_Fragment = {
  __typename: "PaymentLiabilityEntry";
  id: string;
  createdAt: string;
  effectiveAt: string;
  entryType: SourceType;
  businessTransaction: {
    __typename?: "BusinessTransaction";
    id: string;
    type: BusinessTxType;
    payer?: Maybe<string>;
    status?: Maybe<BusinessTxStatusEnum>;
    estimatedClearDate?: Maybe<string>;
    clearedAt?: Maybe<string>;
    failedAt?: Maybe<string>;
    createdBy?: Maybe<{
      __typename?: "User";
      id: number;
      person: { __typename?: "Person"; id: string; firstName: string };
    }>;
    parent?: Maybe<{
      __typename?: "BusinessTransaction";
      id: string;
      createdAt: string;
    }>;
    children?: Maybe<
      Array<
        Maybe<{
          __typename?: "BusinessTransaction";
          id: string;
          type: BusinessTxType;
          status?: Maybe<BusinessTxStatusEnum>;
        }>
      >
    >;
  };
  amount: { __typename?: "Money"; cents: number };
};

type RentLiabilityEntry_PlanBasedLiabilityEntry_Fragment = {
  __typename: "PlanBasedLiabilityEntry";
  cycleStartDate: string;
  cycleEndDate: string;
  paidToDate: string;
  frequency?: Maybe<LiabilityPlanFrequency>;
  id: string;
  createdAt: string;
  effectiveAt: string;
  entryType: SourceType;
  amount: { __typename?: "Money"; cents: number };
};

export type RentLiabilityEntryFragment =
  | RentLiabilityEntry_AdjustmentLiabilityEntry_Fragment
  | RentLiabilityEntry_PaymentLiabilityEntry_Fragment
  | RentLiabilityEntry_PlanBasedLiabilityEntry_Fragment;

export type FileDetailsFragment = {
  __typename?: "File";
  id: string;
  fileName: string;
  fileSize?: Maybe<number>;
  contentType?: Maybe<string>;
  url?: Maybe<string>;
};

export type AddressFragment = {
  __typename?: "Address";
  unitStreetNumber: string;
  streetName: string;
  suburb?: Maybe<string>;
  state?: Maybe<string>;
  postcode?: Maybe<string>;
  country: string;
};

export type ManagementPropertyFragment = {
  __typename?: "Management";
  id: string;
  property: { __typename?: "Property" } & PropertyIdAndAddressFragment;
};

export type PropertyIdAndAddressFragment = {
  __typename?: "Property";
  id: string;
  address: { __typename?: "Address" } & AddressFragment;
};

export type TenancyPropertyFragment = {
  __typename?: "Tenancy";
  property: { __typename?: "Property" } & PropertyIdAndAddressFragment;
};

export const LegalEntityCompanionCacheKeysFragmentFragmentDoc = gql`
  fragment legalEntityCompanionCacheKeysFragment on LegalEntityCompanion {
    id
    ailoRNV2
  }
`;
export const LegalEntityCompanionOrganisationFragmentFragmentDoc = gql`
  fragment legalEntityCompanionOrganisationFragment on LegalEntityCompanion {
    ...legalEntityCompanionCacheKeysFragment
    organisation {
      id
      ailoRN
      name
    }
  }
  ${LegalEntityCompanionCacheKeysFragmentFragmentDoc}
`;
export const PaymentMethodDetailsFragmentDoc = gql`
  fragment PaymentMethodDetails on PaymentMethod {
    __typename
    id
  }
`;
export const RentLiabilityEntryFragmentDoc = gql`
  fragment RentLiabilityEntry on LiabilityEntry {
    id
    __typename
    amount {
      cents
    }
    createdAt
    effectiveAt
    entryType
    ... on PlanBasedLiabilityEntry {
      cycleStartDate
      cycleEndDate
      paidToDate
      frequency
    }
    ... on AdjustmentLiabilityEntry {
      descriptionIsCustom
      description
    }
    ... on PaymentLiabilityEntry {
      businessTransaction {
        id
        createdBy {
          id
          person {
            id
            firstName
          }
        }
        type
        payer
        status
        parent {
          id
          createdAt
        }
        children {
          id
          type
          status
        }
        estimatedClearDate
        clearedAt
        failedAt
      }
    }
  }
`;
export const RentLiabilityFragmentDoc = gql`
  fragment RentLiability on Liability {
    id
    category
    firstPaymentDate
    nextDueAmount {
      cents
    }
    nextDueDate
    overdueAmount {
      cents
    }
    paidToDate
    reference
    taxCategory
    terminationDate
    entries(
      filterTypes: $filterTypes
      cursor: { cursor: $cursor, pageSize: $pageSize }
      sort: $sort
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
      }
      items {
        ...RentLiabilityEntry
      }
    }
  }
  ${RentLiabilityEntryFragmentDoc}
`;
export const FileDetailsFragmentDoc = gql`
  fragment FileDetails on File {
    id
    fileName
    fileSize
    contentType
    url
  }
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    unitStreetNumber
    streetName
    suburb
    state
    postcode
    country
  }
`;
export const PropertyIdAndAddressFragmentDoc = gql`
  fragment PropertyIdAndAddress on Property {
    id
    address {
      ...Address
    }
  }
  ${AddressFragmentDoc}
`;
export const ManagementPropertyFragmentDoc = gql`
  fragment ManagementProperty on Management {
    id
    property {
      ...PropertyIdAndAddress
    }
  }
  ${PropertyIdAndAddressFragmentDoc}
`;
export const TenancyPropertyFragmentDoc = gql`
  fragment TenancyProperty on Tenancy {
    property {
      ...PropertyIdAndAddress
    }
  }
  ${PropertyIdAndAddressFragmentDoc}
`;
export const GetUserLegalEntitiesDocument = gql`
  query getUserLegalEntities {
    companies {
      __typename
      ailoRN
      registeredEntityName
      registeredEntityId
    }
    effectiveUser {
      id
      person {
        __typename
        id
        ailoRN
        firstName
        lastName
        emailAddress
        photo {
          id
          url
          thumbnailUrl
        }
      }
    }
  }
`;

/**
 * __useGetUserLegalEntitiesQuery__
 *
 * To run a query within a React component, call `useGetUserLegalEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLegalEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLegalEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserLegalEntitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserLegalEntitiesQuery,
    GetUserLegalEntitiesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetUserLegalEntitiesQuery,
    GetUserLegalEntitiesQueryVariables
  >(GetUserLegalEntitiesDocument, baseOptions);
}
export function useGetUserLegalEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserLegalEntitiesQuery,
    GetUserLegalEntitiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetUserLegalEntitiesQuery,
    GetUserLegalEntitiesQueryVariables
  >(GetUserLegalEntitiesDocument, baseOptions);
}
export type GetUserLegalEntitiesQueryHookResult = ReturnType<
  typeof useGetUserLegalEntitiesQuery
>;
export type GetUserLegalEntitiesLazyQueryHookResult = ReturnType<
  typeof useGetUserLegalEntitiesLazyQuery
>;
export type GetUserLegalEntitiesQueryResult = Apollo.QueryResult<
  GetUserLegalEntitiesQuery,
  GetUserLegalEntitiesQueryVariables
>;
export const GetKycVerificationStatusDocument = gql`
  query getKycVerificationStatus {
    actualUser {
      id
      person {
        id
        kycVerified
      }
    }
  }
`;

/**
 * __useGetKycVerificationStatusQuery__
 *
 * To run a query within a React component, call `useGetKycVerificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKycVerificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKycVerificationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKycVerificationStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetKycVerificationStatusQuery,
    GetKycVerificationStatusQueryVariables
  >
) {
  return Apollo.useQuery<
    GetKycVerificationStatusQuery,
    GetKycVerificationStatusQueryVariables
  >(GetKycVerificationStatusDocument, baseOptions);
}
export function useGetKycVerificationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetKycVerificationStatusQuery,
    GetKycVerificationStatusQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetKycVerificationStatusQuery,
    GetKycVerificationStatusQueryVariables
  >(GetKycVerificationStatusDocument, baseOptions);
}
export type GetKycVerificationStatusQueryHookResult = ReturnType<
  typeof useGetKycVerificationStatusQuery
>;
export type GetKycVerificationStatusLazyQueryHookResult = ReturnType<
  typeof useGetKycVerificationStatusLazyQuery
>;
export type GetKycVerificationStatusQueryResult = Apollo.QueryResult<
  GetKycVerificationStatusQuery,
  GetKycVerificationStatusQueryVariables
>;
export const GetPaidBillsDocument = gql`
  query getPaidBills($reference: AiloRN!, $pageCursor: BillPageCursor!) {
    paidBills(toBePaidBy: $reference, cursor: $pageCursor) {
      pageInfo {
        nextCursor
        hasMore
        total
      }
      items {
        ailoRN
        amount {
          cents
        }
        status
        supplier {
          ailoRN
          name
          internalLegalEntity {
            ...legalEntityCompanionOrganisationFragment
          }
        }
        dueDate: dueDateV2
        liabilityState {
          paymentStatus
          paidAt
        }
      }
    }
  }
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
`;

/**
 * __useGetPaidBillsQuery__
 *
 * To run a query within a React component, call `useGetPaidBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaidBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaidBillsQuery({
 *   variables: {
 *      reference: // value for 'reference'
 *      pageCursor: // value for 'pageCursor'
 *   },
 * });
 */
export function useGetPaidBillsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaidBillsQuery,
    GetPaidBillsQueryVariables
  >
) {
  return Apollo.useQuery<GetPaidBillsQuery, GetPaidBillsQueryVariables>(
    GetPaidBillsDocument,
    baseOptions
  );
}
export function useGetPaidBillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaidBillsQuery,
    GetPaidBillsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPaidBillsQuery, GetPaidBillsQueryVariables>(
    GetPaidBillsDocument,
    baseOptions
  );
}
export type GetPaidBillsQueryHookResult = ReturnType<
  typeof useGetPaidBillsQuery
>;
export type GetPaidBillsLazyQueryHookResult = ReturnType<
  typeof useGetPaidBillsLazyQuery
>;
export type GetPaidBillsQueryResult = Apollo.QueryResult<
  GetPaidBillsQuery,
  GetPaidBillsQueryVariables
>;
export const GetUpcomingBillsDocument = gql`
  query getUpcomingBills($reference: AiloRN!, $pageCursor: BillPageCursor!) {
    upcomingBills(toBePaidBy: $reference, cursor: $pageCursor) {
      pageInfo {
        nextCursor
        hasMore
        total
      }
      items {
        ailoRN
        amount {
          cents
        }
        status
        supplier {
          ailoRN
          name
          internalLegalEntity {
            ...legalEntityCompanionOrganisationFragment
          }
        }
        dueDate: dueDateV2
        liabilityState {
          paymentStatus
          paidAt
        }
      }
    }
  }
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
`;

/**
 * __useGetUpcomingBillsQuery__
 *
 * To run a query within a React component, call `useGetUpcomingBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpcomingBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpcomingBillsQuery({
 *   variables: {
 *      reference: // value for 'reference'
 *      pageCursor: // value for 'pageCursor'
 *   },
 * });
 */
export function useGetUpcomingBillsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUpcomingBillsQuery,
    GetUpcomingBillsQueryVariables
  >
) {
  return Apollo.useQuery<GetUpcomingBillsQuery, GetUpcomingBillsQueryVariables>(
    GetUpcomingBillsDocument,
    baseOptions
  );
}
export function useGetUpcomingBillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpcomingBillsQuery,
    GetUpcomingBillsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetUpcomingBillsQuery,
    GetUpcomingBillsQueryVariables
  >(GetUpcomingBillsDocument, baseOptions);
}
export type GetUpcomingBillsQueryHookResult = ReturnType<
  typeof useGetUpcomingBillsQuery
>;
export type GetUpcomingBillsLazyQueryHookResult = ReturnType<
  typeof useGetUpcomingBillsLazyQuery
>;
export type GetUpcomingBillsQueryResult = Apollo.QueryResult<
  GetUpcomingBillsQuery,
  GetUpcomingBillsQueryVariables
>;
export const CancelAutoPayLiabilityStatusDocument = gql`
  mutation cancelAutoPayLiabilityStatus(
    $payerLegalEntityId: AiloRN!
    $payerId: AiloRN!
  ) {
    cancelAutoPayLiabilityStatus(
      cancelAutoPayLiabilityStatusInput: {
        payerLegalEntity: $payerLegalEntityId
        payerId: $payerId
      }
    ) {
      enabled
      maximumPaymentAmount {
        cents
      }
      payerId
      paymentMethodId
    }
  }
`;
export type CancelAutoPayLiabilityStatusMutationFn = Apollo.MutationFunction<
  CancelAutoPayLiabilityStatusMutation,
  CancelAutoPayLiabilityStatusMutationVariables
>;

/**
 * __useCancelAutoPayLiabilityStatusMutation__
 *
 * To run a mutation, you first call `useCancelAutoPayLiabilityStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAutoPayLiabilityStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAutoPayLiabilityStatusMutation, { data, loading, error }] = useCancelAutoPayLiabilityStatusMutation({
 *   variables: {
 *      payerLegalEntityId: // value for 'payerLegalEntityId'
 *      payerId: // value for 'payerId'
 *   },
 * });
 */
export function useCancelAutoPayLiabilityStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAutoPayLiabilityStatusMutation,
    CancelAutoPayLiabilityStatusMutationVariables
  >
) {
  return Apollo.useMutation<
    CancelAutoPayLiabilityStatusMutation,
    CancelAutoPayLiabilityStatusMutationVariables
  >(CancelAutoPayLiabilityStatusDocument, baseOptions);
}
export type CancelAutoPayLiabilityStatusMutationHookResult = ReturnType<
  typeof useCancelAutoPayLiabilityStatusMutation
>;
export type CancelAutoPayLiabilityStatusMutationResult =
  Apollo.MutationResult<CancelAutoPayLiabilityStatusMutation>;
export type CancelAutoPayLiabilityStatusMutationOptions =
  Apollo.BaseMutationOptions<
    CancelAutoPayLiabilityStatusMutation,
    CancelAutoPayLiabilityStatusMutationVariables
  >;
export const GetAutoPayLiabilityStatusDocument = gql`
  query getAutoPayLiabilityStatus(
    $payerLegalEntityId: AiloRN!
    $payerId: AiloRN!
  ) {
    autoPayLiabilityStatus(
      payerLegalEntityId: $payerLegalEntityId
      payerId: $payerId
    ) {
      enabled
      maximumPaymentAmount {
        cents
      }
      payerId
      paymentMethodId
    }
  }
`;

/**
 * __useGetAutoPayLiabilityStatusQuery__
 *
 * To run a query within a React component, call `useGetAutoPayLiabilityStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoPayLiabilityStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoPayLiabilityStatusQuery({
 *   variables: {
 *      payerLegalEntityId: // value for 'payerLegalEntityId'
 *      payerId: // value for 'payerId'
 *   },
 * });
 */
export function useGetAutoPayLiabilityStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAutoPayLiabilityStatusQuery,
    GetAutoPayLiabilityStatusQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAutoPayLiabilityStatusQuery,
    GetAutoPayLiabilityStatusQueryVariables
  >(GetAutoPayLiabilityStatusDocument, baseOptions);
}
export function useGetAutoPayLiabilityStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutoPayLiabilityStatusQuery,
    GetAutoPayLiabilityStatusQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAutoPayLiabilityStatusQuery,
    GetAutoPayLiabilityStatusQueryVariables
  >(GetAutoPayLiabilityStatusDocument, baseOptions);
}
export type GetAutoPayLiabilityStatusQueryHookResult = ReturnType<
  typeof useGetAutoPayLiabilityStatusQuery
>;
export type GetAutoPayLiabilityStatusLazyQueryHookResult = ReturnType<
  typeof useGetAutoPayLiabilityStatusLazyQuery
>;
export type GetAutoPayLiabilityStatusQueryResult = Apollo.QueryResult<
  GetAutoPayLiabilityStatusQuery,
  GetAutoPayLiabilityStatusQueryVariables
>;
export const GetAutoPayLiabilityStatusesDocument = gql`
  query getAutoPayLiabilityStatuses($payerLegalEntityId: AiloRN!) {
    autoPayLiabilityStatuses(payerLegalEntityId: $payerLegalEntityId) {
      enabled
      maximumPaymentAmount {
        cents
      }
      payerId
      paymentMethodId
    }
  }
`;

/**
 * __useGetAutoPayLiabilityStatusesQuery__
 *
 * To run a query within a React component, call `useGetAutoPayLiabilityStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoPayLiabilityStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoPayLiabilityStatusesQuery({
 *   variables: {
 *      payerLegalEntityId: // value for 'payerLegalEntityId'
 *   },
 * });
 */
export function useGetAutoPayLiabilityStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAutoPayLiabilityStatusesQuery,
    GetAutoPayLiabilityStatusesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAutoPayLiabilityStatusesQuery,
    GetAutoPayLiabilityStatusesQueryVariables
  >(GetAutoPayLiabilityStatusesDocument, baseOptions);
}
export function useGetAutoPayLiabilityStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutoPayLiabilityStatusesQuery,
    GetAutoPayLiabilityStatusesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAutoPayLiabilityStatusesQuery,
    GetAutoPayLiabilityStatusesQueryVariables
  >(GetAutoPayLiabilityStatusesDocument, baseOptions);
}
export type GetAutoPayLiabilityStatusesQueryHookResult = ReturnType<
  typeof useGetAutoPayLiabilityStatusesQuery
>;
export type GetAutoPayLiabilityStatusesLazyQueryHookResult = ReturnType<
  typeof useGetAutoPayLiabilityStatusesLazyQuery
>;
export type GetAutoPayLiabilityStatusesQueryResult = Apollo.QueryResult<
  GetAutoPayLiabilityStatusesQuery,
  GetAutoPayLiabilityStatusesQueryVariables
>;
export const UpsertAutoPayLiabilityStatusDocument = gql`
  mutation upsertAutoPayLiabilityStatus(
    $payerLegalEntityId: AiloRN!
    $maximumPaymentAmount: MoneyInput!
    $payerId: AiloRN!
  ) {
    upsertAutoPayLiabilityStatus(
      upsertAutoPayLiabilityStatusInput: {
        payerLegalEntity: $payerLegalEntityId
        payerId: $payerId
        maximumPaymentAmount: $maximumPaymentAmount
      }
    ) {
      enabled
      maximumPaymentAmount {
        cents
      }
      payerId
      paymentMethodId
    }
  }
`;
export type UpsertAutoPayLiabilityStatusMutationFn = Apollo.MutationFunction<
  UpsertAutoPayLiabilityStatusMutation,
  UpsertAutoPayLiabilityStatusMutationVariables
>;

/**
 * __useUpsertAutoPayLiabilityStatusMutation__
 *
 * To run a mutation, you first call `useUpsertAutoPayLiabilityStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAutoPayLiabilityStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAutoPayLiabilityStatusMutation, { data, loading, error }] = useUpsertAutoPayLiabilityStatusMutation({
 *   variables: {
 *      payerLegalEntityId: // value for 'payerLegalEntityId'
 *      maximumPaymentAmount: // value for 'maximumPaymentAmount'
 *      payerId: // value for 'payerId'
 *   },
 * });
 */
export function useUpsertAutoPayLiabilityStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertAutoPayLiabilityStatusMutation,
    UpsertAutoPayLiabilityStatusMutationVariables
  >
) {
  return Apollo.useMutation<
    UpsertAutoPayLiabilityStatusMutation,
    UpsertAutoPayLiabilityStatusMutationVariables
  >(UpsertAutoPayLiabilityStatusDocument, baseOptions);
}
export type UpsertAutoPayLiabilityStatusMutationHookResult = ReturnType<
  typeof useUpsertAutoPayLiabilityStatusMutation
>;
export type UpsertAutoPayLiabilityStatusMutationResult =
  Apollo.MutationResult<UpsertAutoPayLiabilityStatusMutation>;
export type UpsertAutoPayLiabilityStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertAutoPayLiabilityStatusMutation,
    UpsertAutoPayLiabilityStatusMutationVariables
  >;
export const GetAutoWithdrawPlanByIdDocument = gql`
  query getAutoWithdrawPlanById(
    $autoWithdrawPlanId: ID!
    $legalEntityId: AiloRN!
  ) {
    autoWithdrawPlanById(
      autoWithdrawPlanId: $autoWithdrawPlanId
      payerLegalEntityId: $legalEntityId
    ) {
      id
      owner
      walletId
      wallet {
        owner {
          id
          reference
        }
      }
      enabled
      createdBy
      createdAt
      details {
        id
        setAsideAmount {
          cents
        }
        autoWithdrawPlanId
        paymentMethodDestinations {
          paymentMethodId
          share
          paymentMethod {
            ...PaymentMethodDetails
            ... on BankAccount {
              accountName
              accountNumber
            }
          }
        }
        timezoneId
        startDate
        endDate
        frequency
        anniversary
        anniversaryDaysOfMonth
        createdAt
        isLastDayOfTheMonth
        nextFireTime
      }
    }
  }
  ${PaymentMethodDetailsFragmentDoc}
`;

/**
 * __useGetAutoWithdrawPlanByIdQuery__
 *
 * To run a query within a React component, call `useGetAutoWithdrawPlanByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoWithdrawPlanByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoWithdrawPlanByIdQuery({
 *   variables: {
 *      autoWithdrawPlanId: // value for 'autoWithdrawPlanId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetAutoWithdrawPlanByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAutoWithdrawPlanByIdQuery,
    GetAutoWithdrawPlanByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAutoWithdrawPlanByIdQuery,
    GetAutoWithdrawPlanByIdQueryVariables
  >(GetAutoWithdrawPlanByIdDocument, baseOptions);
}
export function useGetAutoWithdrawPlanByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutoWithdrawPlanByIdQuery,
    GetAutoWithdrawPlanByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAutoWithdrawPlanByIdQuery,
    GetAutoWithdrawPlanByIdQueryVariables
  >(GetAutoWithdrawPlanByIdDocument, baseOptions);
}
export type GetAutoWithdrawPlanByIdQueryHookResult = ReturnType<
  typeof useGetAutoWithdrawPlanByIdQuery
>;
export type GetAutoWithdrawPlanByIdLazyQueryHookResult = ReturnType<
  typeof useGetAutoWithdrawPlanByIdLazyQuery
>;
export type GetAutoWithdrawPlanByIdQueryResult = Apollo.QueryResult<
  GetAutoWithdrawPlanByIdQuery,
  GetAutoWithdrawPlanByIdQueryVariables
>;
export const UpdateAutoWithdrawPlanDocument = gql`
  mutation updateAutoWithdrawPlan(
    $updateAutoWithdrawInput: UpdateAutoWithdrawPlanInputV2!
  ) {
    updateAutoWithdrawPlan: updateAutoWithdrawPlanV2(
      updateAutoWithdrawInput: $updateAutoWithdrawInput
    ) {
      enabled
    }
  }
`;
export type UpdateAutoWithdrawPlanMutationFn = Apollo.MutationFunction<
  UpdateAutoWithdrawPlanMutation,
  UpdateAutoWithdrawPlanMutationVariables
>;

/**
 * __useUpdateAutoWithdrawPlanMutation__
 *
 * To run a mutation, you first call `useUpdateAutoWithdrawPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoWithdrawPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoWithdrawPlanMutation, { data, loading, error }] = useUpdateAutoWithdrawPlanMutation({
 *   variables: {
 *      updateAutoWithdrawInput: // value for 'updateAutoWithdrawInput'
 *   },
 * });
 */
export function useUpdateAutoWithdrawPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAutoWithdrawPlanMutation,
    UpdateAutoWithdrawPlanMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateAutoWithdrawPlanMutation,
    UpdateAutoWithdrawPlanMutationVariables
  >(UpdateAutoWithdrawPlanDocument, baseOptions);
}
export type UpdateAutoWithdrawPlanMutationHookResult = ReturnType<
  typeof useUpdateAutoWithdrawPlanMutation
>;
export type UpdateAutoWithdrawPlanMutationResult =
  Apollo.MutationResult<UpdateAutoWithdrawPlanMutation>;
export type UpdateAutoWithdrawPlanMutationOptions = Apollo.BaseMutationOptions<
  UpdateAutoWithdrawPlanMutation,
  UpdateAutoWithdrawPlanMutationVariables
>;
export const GetManagementFormerTenanciesDocument = gql`
  query getManagementFormerTenancies($managementId: ID!) {
    management(managementId: $managementId) {
      id
      formerTenancies {
        items {
          id
          startDate
          endDate
        }
      }
    }
  }
`;

/**
 * __useGetManagementFormerTenanciesQuery__
 *
 * To run a query within a React component, call `useGetManagementFormerTenanciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementFormerTenanciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementFormerTenanciesQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementFormerTenanciesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementFormerTenanciesQuery,
    GetManagementFormerTenanciesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementFormerTenanciesQuery,
    GetManagementFormerTenanciesQueryVariables
  >(GetManagementFormerTenanciesDocument, baseOptions);
}
export function useGetManagementFormerTenanciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementFormerTenanciesQuery,
    GetManagementFormerTenanciesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementFormerTenanciesQuery,
    GetManagementFormerTenanciesQueryVariables
  >(GetManagementFormerTenanciesDocument, baseOptions);
}
export type GetManagementFormerTenanciesQueryHookResult = ReturnType<
  typeof useGetManagementFormerTenanciesQuery
>;
export type GetManagementFormerTenanciesLazyQueryHookResult = ReturnType<
  typeof useGetManagementFormerTenanciesLazyQuery
>;
export type GetManagementFormerTenanciesQueryResult = Apollo.QueryResult<
  GetManagementFormerTenanciesQuery,
  GetManagementFormerTenanciesQueryVariables
>;
export const GetPaymentMethodsDocument = gql`
  query getPaymentMethods(
    $methodType: PaymentMethodType
    $legalEntityId: AiloRN!
  ) {
    legalEntity(id: $legalEntityId) {
      __typename
      id
      ailoRN
      ... on Person {
        paymentMethods(methodType: $methodType) {
          pageInfo {
            total
          }
          items {
            __typename
            id
            isOnceOff
            isAutoPay
            isAutoWithdraw
            ... on BankAccount {
              bsb
              accountName
              accountNumber
            }
            ... on CreditCard {
              expiry
              number
              type
            }
          }
        }
      }
      ... on Company {
        paymentMethods(methodType: $methodType) {
          pageInfo {
            total
          }
          items {
            __typename
            id
            isOnceOff
            isAutoPay
            isAutoWithdraw
            ... on BankAccount {
              bsb
              accountName
              accountNumber
            }
            ... on CreditCard {
              expiry
              number
              type
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *      methodType: // value for 'methodType'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables
  >(GetPaymentMethodsDocument, baseOptions);
}
export function useGetPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaymentMethodsQuery,
    GetPaymentMethodsQueryVariables
  >(GetPaymentMethodsDocument, baseOptions);
}
export type GetPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetPaymentMethodsQuery
>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodsLazyQuery
>;
export type GetPaymentMethodsQueryResult = Apollo.QueryResult<
  GetPaymentMethodsQuery,
  GetPaymentMethodsQueryVariables
>;
export const GetPaymentMethodIdsForUserDocument = gql`
  query getPaymentMethodIdsForUser($legalEntityId: AiloRN!) {
    legalEntity(id: $legalEntityId) {
      __typename
      id
      ailorn: ailoRN
      ... on Person {
        paymentMethods {
          items {
            __typename
            id
            isDefaultIn
            isDefaultOut
            ... on BankAccount {
              accountName
              accountNumber
            }
          }
        }
      }
      ... on Company {
        paymentMethods {
          items {
            __typename
            id
            isDefaultIn
            isDefaultOut
            ... on BankAccount {
              accountName
              accountNumber
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetPaymentMethodIdsForUserQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodIdsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodIdsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodIdsForUserQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetPaymentMethodIdsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentMethodIdsForUserQuery,
    GetPaymentMethodIdsForUserQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaymentMethodIdsForUserQuery,
    GetPaymentMethodIdsForUserQueryVariables
  >(GetPaymentMethodIdsForUserDocument, baseOptions);
}
export function useGetPaymentMethodIdsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodIdsForUserQuery,
    GetPaymentMethodIdsForUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaymentMethodIdsForUserQuery,
    GetPaymentMethodIdsForUserQueryVariables
  >(GetPaymentMethodIdsForUserDocument, baseOptions);
}
export type GetPaymentMethodIdsForUserQueryHookResult = ReturnType<
  typeof useGetPaymentMethodIdsForUserQuery
>;
export type GetPaymentMethodIdsForUserLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodIdsForUserLazyQuery
>;
export type GetPaymentMethodIdsForUserQueryResult = Apollo.QueryResult<
  GetPaymentMethodIdsForUserQuery,
  GetPaymentMethodIdsForUserQueryVariables
>;
export const GetTenancyAutoPaymentDetailsDocument = gql`
  query getTenancyAutoPaymentDetails($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      liability {
        id
        reference
        autoPaymentDetails {
          paymentMethod {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetTenancyAutoPaymentDetailsQuery__
 *
 * To run a query within a React component, call `useGetTenancyAutoPaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyAutoPaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyAutoPaymentDetailsQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetTenancyAutoPaymentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyAutoPaymentDetailsQuery,
    GetTenancyAutoPaymentDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyAutoPaymentDetailsQuery,
    GetTenancyAutoPaymentDetailsQueryVariables
  >(GetTenancyAutoPaymentDetailsDocument, baseOptions);
}
export function useGetTenancyAutoPaymentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyAutoPaymentDetailsQuery,
    GetTenancyAutoPaymentDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyAutoPaymentDetailsQuery,
    GetTenancyAutoPaymentDetailsQueryVariables
  >(GetTenancyAutoPaymentDetailsDocument, baseOptions);
}
export type GetTenancyAutoPaymentDetailsQueryHookResult = ReturnType<
  typeof useGetTenancyAutoPaymentDetailsQuery
>;
export type GetTenancyAutoPaymentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetTenancyAutoPaymentDetailsLazyQuery
>;
export type GetTenancyAutoPaymentDetailsQueryResult = Apollo.QueryResult<
  GetTenancyAutoPaymentDetailsQuery,
  GetTenancyAutoPaymentDetailsQueryVariables
>;
export const DeletePaymentMethodDocument = gql`
  mutation deletePaymentMethod($paymentMethodId: ID!) {
    deletePaymentMethod(paymentMethodId: $paymentMethodId)
  }
`;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >(DeletePaymentMethodDocument, baseOptions);
}
export type DeletePaymentMethodMutationHookResult = ReturnType<
  typeof useDeletePaymentMethodMutation
>;
export type DeletePaymentMethodMutationResult =
  Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;
export const GetPaymentMethodWithFeesDocument = gql`
  query getPaymentMethodWithFees($paymentMethodId: ID!) {
    paymentMethodById(paymentMethodId: $paymentMethodId) {
      id
      __typename
      ... on BankAccount {
        topUpFee {
          feeBps
          feeFlatCents
        }
      }
      ... on CreditCard {
        topUpFee {
          feeBps
          feeFlatCents
        }
      }
    }
  }
`;

/**
 * __useGetPaymentMethodWithFeesQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodWithFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodWithFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodWithFeesQuery({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useGetPaymentMethodWithFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentMethodWithFeesQuery,
    GetPaymentMethodWithFeesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaymentMethodWithFeesQuery,
    GetPaymentMethodWithFeesQueryVariables
  >(GetPaymentMethodWithFeesDocument, baseOptions);
}
export function useGetPaymentMethodWithFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodWithFeesQuery,
    GetPaymentMethodWithFeesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaymentMethodWithFeesQuery,
    GetPaymentMethodWithFeesQueryVariables
  >(GetPaymentMethodWithFeesDocument, baseOptions);
}
export type GetPaymentMethodWithFeesQueryHookResult = ReturnType<
  typeof useGetPaymentMethodWithFeesQuery
>;
export type GetPaymentMethodWithFeesLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodWithFeesLazyQuery
>;
export type GetPaymentMethodWithFeesQueryResult = Apollo.QueryResult<
  GetPaymentMethodWithFeesQuery,
  GetPaymentMethodWithFeesQueryVariables
>;
export const PayLiabilityDocument = gql`
  mutation payLiability(
    $amount: MoneyInput!
    $idempotentKey: GeneratedKey!
    $liabilityId: ID!
    $paymentMethodId: ID!
  ) {
    payLiability(
      amount: $amount
      idempotentKey: $idempotentKey
      liabilityId: $liabilityId
      paymentMethodId: $paymentMethodId
    ) {
      status
      businessTransaction {
        id
        createdAt
      }
    }
  }
`;
export type PayLiabilityMutationFn = Apollo.MutationFunction<
  PayLiabilityMutation,
  PayLiabilityMutationVariables
>;

/**
 * __usePayLiabilityMutation__
 *
 * To run a mutation, you first call `usePayLiabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayLiabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payLiabilityMutation, { data, loading, error }] = usePayLiabilityMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      idempotentKey: // value for 'idempotentKey'
 *      liabilityId: // value for 'liabilityId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function usePayLiabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayLiabilityMutation,
    PayLiabilityMutationVariables
  >
) {
  return Apollo.useMutation<
    PayLiabilityMutation,
    PayLiabilityMutationVariables
  >(PayLiabilityDocument, baseOptions);
}
export type PayLiabilityMutationHookResult = ReturnType<
  typeof usePayLiabilityMutation
>;
export type PayLiabilityMutationResult =
  Apollo.MutationResult<PayLiabilityMutation>;
export type PayLiabilityMutationOptions = Apollo.BaseMutationOptions<
  PayLiabilityMutation,
  PayLiabilityMutationVariables
>;
export const GetManagementFolioDocument = gql`
  query getManagementFolio($id: ID!) {
    managementFolio(id: $id) {
      id
      ailorn
      managements {
        id
        ailorn: ailoRN
      }
    }
  }
`;

/**
 * __useGetManagementFolioQuery__
 *
 * To run a query within a React component, call `useGetManagementFolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementFolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementFolioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagementFolioQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementFolioQuery,
    GetManagementFolioQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementFolioQuery,
    GetManagementFolioQueryVariables
  >(GetManagementFolioDocument, baseOptions);
}
export function useGetManagementFolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementFolioQuery,
    GetManagementFolioQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementFolioQuery,
    GetManagementFolioQueryVariables
  >(GetManagementFolioDocument, baseOptions);
}
export type GetManagementFolioQueryHookResult = ReturnType<
  typeof useGetManagementFolioQuery
>;
export type GetManagementFolioLazyQueryHookResult = ReturnType<
  typeof useGetManagementFolioLazyQuery
>;
export type GetManagementFolioQueryResult = Apollo.QueryResult<
  GetManagementFolioQuery,
  GetManagementFolioQueryVariables
>;
export const CompleteOnboardingTaskDocument = gql`
  mutation completeOnboardingTask($onboardingTaskId: OnboardingTaskId!) {
    completeOnboardingTask(onboardingTaskId: $onboardingTaskId) {
      onboardingTaskId
    }
  }
`;
export type CompleteOnboardingTaskMutationFn = Apollo.MutationFunction<
  CompleteOnboardingTaskMutation,
  CompleteOnboardingTaskMutationVariables
>;

/**
 * __useCompleteOnboardingTaskMutation__
 *
 * To run a mutation, you first call `useCompleteOnboardingTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnboardingTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnboardingTaskMutation, { data, loading, error }] = useCompleteOnboardingTaskMutation({
 *   variables: {
 *      onboardingTaskId: // value for 'onboardingTaskId'
 *   },
 * });
 */
export function useCompleteOnboardingTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteOnboardingTaskMutation,
    CompleteOnboardingTaskMutationVariables
  >
) {
  return Apollo.useMutation<
    CompleteOnboardingTaskMutation,
    CompleteOnboardingTaskMutationVariables
  >(CompleteOnboardingTaskDocument, baseOptions);
}
export type CompleteOnboardingTaskMutationHookResult = ReturnType<
  typeof useCompleteOnboardingTaskMutation
>;
export type CompleteOnboardingTaskMutationResult =
  Apollo.MutationResult<CompleteOnboardingTaskMutation>;
export type CompleteOnboardingTaskMutationOptions = Apollo.BaseMutationOptions<
  CompleteOnboardingTaskMutation,
  CompleteOnboardingTaskMutationVariables
>;
export const GetPersonDataDocument = gql`
  query getPersonData($id: AiloRN!) {
    legalEntity(id: $id) {
      __typename
      id
      ... on Person {
        user {
          onboardingCompletedAt
        }
      }
    }
  }
`;

/**
 * __useGetPersonDataQuery__
 *
 * To run a query within a React component, call `useGetPersonDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonDataQuery,
    GetPersonDataQueryVariables
  >
) {
  return Apollo.useQuery<GetPersonDataQuery, GetPersonDataQueryVariables>(
    GetPersonDataDocument,
    baseOptions
  );
}
export function useGetPersonDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonDataQuery,
    GetPersonDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPersonDataQuery, GetPersonDataQueryVariables>(
    GetPersonDataDocument,
    baseOptions
  );
}
export type GetPersonDataQueryHookResult = ReturnType<
  typeof useGetPersonDataQuery
>;
export type GetPersonDataLazyQueryHookResult = ReturnType<
  typeof useGetPersonDataLazyQuery
>;
export type GetPersonDataQueryResult = Apollo.QueryResult<
  GetPersonDataQuery,
  GetPersonDataQueryVariables
>;
export const SendPhoneVerificationCodeDocument = gql`
  mutation sendPhoneVerificationCode($phoneNumber: String!) {
    sendPhoneVerificationCode(phoneNumber: $phoneNumber) {
      ok
      error {
        code
        message
      }
    }
  }
`;
export type SendPhoneVerificationCodeMutationFn = Apollo.MutationFunction<
  SendPhoneVerificationCodeMutation,
  SendPhoneVerificationCodeMutationVariables
>;

/**
 * __useSendPhoneVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendPhoneVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPhoneVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPhoneVerificationCodeMutation, { data, loading, error }] = useSendPhoneVerificationCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useSendPhoneVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPhoneVerificationCodeMutation,
    SendPhoneVerificationCodeMutationVariables
  >
) {
  return Apollo.useMutation<
    SendPhoneVerificationCodeMutation,
    SendPhoneVerificationCodeMutationVariables
  >(SendPhoneVerificationCodeDocument, baseOptions);
}
export type SendPhoneVerificationCodeMutationHookResult = ReturnType<
  typeof useSendPhoneVerificationCodeMutation
>;
export type SendPhoneVerificationCodeMutationResult =
  Apollo.MutationResult<SendPhoneVerificationCodeMutation>;
export type SendPhoneVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    SendPhoneVerificationCodeMutation,
    SendPhoneVerificationCodeMutationVariables
  >;
export const VerifyVerificationCodeDocument = gql`
  mutation verifyVerificationCode($phoneNumber: String!, $code: String!) {
    verifyPhoneVerificationCode(phoneNumber: $phoneNumber, code: $code) {
      ok
      error {
        code
        message
      }
    }
  }
`;
export type VerifyVerificationCodeMutationFn = Apollo.MutationFunction<
  VerifyVerificationCodeMutation,
  VerifyVerificationCodeMutationVariables
>;

/**
 * __useVerifyVerificationCodeMutation__
 *
 * To run a mutation, you first call `useVerifyVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyVerificationCodeMutation, { data, loading, error }] = useVerifyVerificationCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyVerificationCodeMutation,
    VerifyVerificationCodeMutationVariables
  >
) {
  return Apollo.useMutation<
    VerifyVerificationCodeMutation,
    VerifyVerificationCodeMutationVariables
  >(VerifyVerificationCodeDocument, baseOptions);
}
export type VerifyVerificationCodeMutationHookResult = ReturnType<
  typeof useVerifyVerificationCodeMutation
>;
export type VerifyVerificationCodeMutationResult =
  Apollo.MutationResult<VerifyVerificationCodeMutation>;
export type VerifyVerificationCodeMutationOptions = Apollo.BaseMutationOptions<
  VerifyVerificationCodeMutation,
  VerifyVerificationCodeMutationVariables
>;
export const GetAnalyticsDataDocument = gql`
  query getAnalyticsData {
    effectiveUser {
      id
      person {
        id
        firstName
        lastName
        emailAddress
      }
      organisations {
        id
      }
    }
    tenancies {
      items {
        id
        managingEntity {
          id
          organisation {
            id
          }
        }
      }
    }
    managements {
      items {
        id
        managingEntity {
          id
          organisation {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetAnalyticsDataQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnalyticsDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAnalyticsDataQuery,
    GetAnalyticsDataQueryVariables
  >
) {
  return Apollo.useQuery<GetAnalyticsDataQuery, GetAnalyticsDataQueryVariables>(
    GetAnalyticsDataDocument,
    baseOptions
  );
}
export function useGetAnalyticsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnalyticsDataQuery,
    GetAnalyticsDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAnalyticsDataQuery,
    GetAnalyticsDataQueryVariables
  >(GetAnalyticsDataDocument, baseOptions);
}
export type GetAnalyticsDataQueryHookResult = ReturnType<
  typeof useGetAnalyticsDataQuery
>;
export type GetAnalyticsDataLazyQueryHookResult = ReturnType<
  typeof useGetAnalyticsDataLazyQuery
>;
export type GetAnalyticsDataQueryResult = Apollo.QueryResult<
  GetAnalyticsDataQuery,
  GetAnalyticsDataQueryVariables
>;
export const GetTenancyDepositDocument = gql`
  query getTenancyDeposit($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      deposit {
        id
        status
      }
    }
  }
`;

/**
 * __useGetTenancyDepositQuery__
 *
 * To run a query within a React component, call `useGetTenancyDepositQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyDepositQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyDepositQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetTenancyDepositQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyDepositQuery,
    GetTenancyDepositQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyDepositQuery,
    GetTenancyDepositQueryVariables
  >(GetTenancyDepositDocument, baseOptions);
}
export function useGetTenancyDepositLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyDepositQuery,
    GetTenancyDepositQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyDepositQuery,
    GetTenancyDepositQueryVariables
  >(GetTenancyDepositDocument, baseOptions);
}
export type GetTenancyDepositQueryHookResult = ReturnType<
  typeof useGetTenancyDepositQuery
>;
export type GetTenancyDepositLazyQueryHookResult = ReturnType<
  typeof useGetTenancyDepositLazyQuery
>;
export type GetTenancyDepositQueryResult = Apollo.QueryResult<
  GetTenancyDepositQuery,
  GetTenancyDepositQueryVariables
>;
export const GetUserOnboardingStateDocument = gql`
  query getUserOnboardingState {
    effectiveUser {
      id
      organisations {
        id
        availableFeatures {
          id
        }
      }
      person {
        id
        ailoRN
        bankAccounts: paymentMethods(methodType: BankAccount) {
          pageInfo {
            total
          }
        }
        creditCards: paymentMethods(methodType: CreditCard) {
          pageInfo {
            total
          }
        }
        phoneNoVerified
      }
      onboardingTasks {
        onboardingTaskId
      }
    }
    actualUser {
      id
    }
  }
`;

/**
 * __useGetUserOnboardingStateQuery__
 *
 * To run a query within a React component, call `useGetUserOnboardingStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOnboardingStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOnboardingStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserOnboardingStateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserOnboardingStateQuery,
    GetUserOnboardingStateQueryVariables
  >
) {
  return Apollo.useQuery<
    GetUserOnboardingStateQuery,
    GetUserOnboardingStateQueryVariables
  >(GetUserOnboardingStateDocument, baseOptions);
}
export function useGetUserOnboardingStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserOnboardingStateQuery,
    GetUserOnboardingStateQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetUserOnboardingStateQuery,
    GetUserOnboardingStateQueryVariables
  >(GetUserOnboardingStateDocument, baseOptions);
}
export type GetUserOnboardingStateQueryHookResult = ReturnType<
  typeof useGetUserOnboardingStateQuery
>;
export type GetUserOnboardingStateLazyQueryHookResult = ReturnType<
  typeof useGetUserOnboardingStateLazyQuery
>;
export type GetUserOnboardingStateQueryResult = Apollo.QueryResult<
  GetUserOnboardingStateQuery,
  GetUserOnboardingStateQueryVariables
>;
export const GetSetupDataDocument = gql`
  query getSetupData {
    companies {
      __typename
      ailoRN
      registeredEntityName
      registeredEntityId
    }
    tenancies {
      items {
        id
        deposit {
          id
          status
          amount {
            cents
          }
          liability {
            id
          }
        }
        ailoRN
        managementAiloRN
        managementFolioAilorn
        startDate
        endDate
        managingEntity {
          id
          timezone
          registeredEntityName
          organisationId
          organisation {
            name
            effectiveUserContact {
              ailorn
            }
          }
        }
        tenantships(pageCursor: { pageSize: 10000 }) {
          items {
            tenantId
          }
        }
        ...TenancyProperty
      }
    }
    managements {
      items {
        id
        ailoRN
        endDate
        managementAgreements {
          items {
            startDate
          }
        }
        managementFolio {
          id
          ailorn
        }
        ownerships {
          ownerId
          sharesOwned
        }
        managingEntity {
          organisationId
          timezone
          organisation {
            name
            effectiveUserContact {
              ailorn
            }
          }
        }
        ...ManagementProperty
      }
    }
    effectiveUser {
      id
      organisations {
        id
        availableFeatures {
          id
        }
      }
      person {
        __typename
        id
        ailoRN
        firstName
        lastName
        emailAddress
        photo {
          id
          url
          thumbnailUrl
        }
      }
    }
  }
  ${TenancyPropertyFragmentDoc}
  ${ManagementPropertyFragmentDoc}
`;

/**
 * __useGetSetupDataQuery__
 *
 * To run a query within a React component, call `useGetSetupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSetupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSetupDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSetupDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSetupDataQuery,
    GetSetupDataQueryVariables
  >
) {
  return Apollo.useQuery<GetSetupDataQuery, GetSetupDataQueryVariables>(
    GetSetupDataDocument,
    baseOptions
  );
}
export function useGetSetupDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSetupDataQuery,
    GetSetupDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetSetupDataQuery, GetSetupDataQueryVariables>(
    GetSetupDataDocument,
    baseOptions
  );
}
export type GetSetupDataQueryHookResult = ReturnType<
  typeof useGetSetupDataQuery
>;
export type GetSetupDataLazyQueryHookResult = ReturnType<
  typeof useGetSetupDataLazyQuery
>;
export type GetSetupDataQueryResult = Apollo.QueryResult<
  GetSetupDataQuery,
  GetSetupDataQueryVariables
>;
export const GetWalletAvailableBalanceDocument = gql`
  query getWalletAvailableBalance($legalEntityId: AiloRN!) {
    legalEntity(id: $legalEntityId) {
      __typename
      id
      ailoRN
      ... on Person {
        allWalletsAvailableBalance {
          cents
        }
      }
      ... on Company {
        allWalletsAvailableBalance {
          cents
        }
      }
    }
  }
`;

/**
 * __useGetWalletAvailableBalanceQuery__
 *
 * To run a query within a React component, call `useGetWalletAvailableBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletAvailableBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletAvailableBalanceQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetWalletAvailableBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWalletAvailableBalanceQuery,
    GetWalletAvailableBalanceQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWalletAvailableBalanceQuery,
    GetWalletAvailableBalanceQueryVariables
  >(GetWalletAvailableBalanceDocument, baseOptions);
}
export function useGetWalletAvailableBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletAvailableBalanceQuery,
    GetWalletAvailableBalanceQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWalletAvailableBalanceQuery,
    GetWalletAvailableBalanceQueryVariables
  >(GetWalletAvailableBalanceDocument, baseOptions);
}
export type GetWalletAvailableBalanceQueryHookResult = ReturnType<
  typeof useGetWalletAvailableBalanceQuery
>;
export type GetWalletAvailableBalanceLazyQueryHookResult = ReturnType<
  typeof useGetWalletAvailableBalanceLazyQuery
>;
export type GetWalletAvailableBalanceQueryResult = Apollo.QueryResult<
  GetWalletAvailableBalanceQuery,
  GetWalletAvailableBalanceQueryVariables
>;
export const AddBankAccountDocument = gql`
  mutation addBankAccount($owner: AiloRN!, $details: BankAccountInput!) {
    addBankAccount(owner: $owner, details: $details) {
      id
      accountName
      accountNumber
    }
  }
`;
export type AddBankAccountMutationFn = Apollo.MutationFunction<
  AddBankAccountMutation,
  AddBankAccountMutationVariables
>;

/**
 * __useAddBankAccountMutation__
 *
 * To run a mutation, you first call `useAddBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBankAccountMutation, { data, loading, error }] = useAddBankAccountMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useAddBankAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBankAccountMutation,
    AddBankAccountMutationVariables
  >
) {
  return Apollo.useMutation<
    AddBankAccountMutation,
    AddBankAccountMutationVariables
  >(AddBankAccountDocument, baseOptions);
}
export type AddBankAccountMutationHookResult = ReturnType<
  typeof useAddBankAccountMutation
>;
export type AddBankAccountMutationResult =
  Apollo.MutationResult<AddBankAccountMutation>;
export type AddBankAccountMutationOptions = Apollo.BaseMutationOptions<
  AddBankAccountMutation,
  AddBankAccountMutationVariables
>;
export const AddCreditCardDocument = gql`
  mutation addCreditCard($owner: AiloRN!, $details: CreditCardInput!) {
    addCreditCard(owner: $owner, details: $details) {
      id
      category
      type
    }
  }
`;
export type AddCreditCardMutationFn = Apollo.MutationFunction<
  AddCreditCardMutation,
  AddCreditCardMutationVariables
>;

/**
 * __useAddCreditCardMutation__
 *
 * To run a mutation, you first call `useAddCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCreditCardMutation, { data, loading, error }] = useAddCreditCardMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useAddCreditCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCreditCardMutation,
    AddCreditCardMutationVariables
  >
) {
  return Apollo.useMutation<
    AddCreditCardMutation,
    AddCreditCardMutationVariables
  >(AddCreditCardDocument, baseOptions);
}
export type AddCreditCardMutationHookResult = ReturnType<
  typeof useAddCreditCardMutation
>;
export type AddCreditCardMutationResult =
  Apollo.MutationResult<AddCreditCardMutation>;
export type AddCreditCardMutationOptions = Apollo.BaseMutationOptions<
  AddCreditCardMutation,
  AddCreditCardMutationVariables
>;
export const GatewayCapturePaymentDetailsDocument = gql`
  query gatewayCapturePaymentDetails($owner: AiloRN!) {
    gatewayCapturePaymentDetails(owner: $owner) {
      url
    }
  }
`;

/**
 * __useGatewayCapturePaymentDetailsQuery__
 *
 * To run a query within a React component, call `useGatewayCapturePaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayCapturePaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayCapturePaymentDetailsQuery({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useGatewayCapturePaymentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GatewayCapturePaymentDetailsQuery,
    GatewayCapturePaymentDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GatewayCapturePaymentDetailsQuery,
    GatewayCapturePaymentDetailsQueryVariables
  >(GatewayCapturePaymentDetailsDocument, baseOptions);
}
export function useGatewayCapturePaymentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GatewayCapturePaymentDetailsQuery,
    GatewayCapturePaymentDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GatewayCapturePaymentDetailsQuery,
    GatewayCapturePaymentDetailsQueryVariables
  >(GatewayCapturePaymentDetailsDocument, baseOptions);
}
export type GatewayCapturePaymentDetailsQueryHookResult = ReturnType<
  typeof useGatewayCapturePaymentDetailsQuery
>;
export type GatewayCapturePaymentDetailsLazyQueryHookResult = ReturnType<
  typeof useGatewayCapturePaymentDetailsLazyQuery
>;
export type GatewayCapturePaymentDetailsQueryResult = Apollo.QueryResult<
  GatewayCapturePaymentDetailsQuery,
  GatewayCapturePaymentDetailsQueryVariables
>;
export const CreateAutoWithdrawPlanDocument = gql`
  mutation createAutoWithdrawPlan(
    $createAutoWithdrawPlanInput: CreateAutoWithdrawPlanInputV2!
  ) {
    createAutoWithdrawPlan: createAutoWithdrawPlanV2(
      createAutoWithdrawPlanInput: $createAutoWithdrawPlanInput
    ) {
      id
      owner
      walletId
      enabled
      createdBy
      createdAt
      details {
        id
        setAsideAmount {
          cents
        }
        autoWithdrawPlanId
        paymentMethodDestinations {
          paymentMethodId
          share
        }
        timezoneId
        startDate
        endDate
        frequency
        anniversary
        createdBy
        createdAt
        isLastDayOfTheMonth
        userFacingDescription
      }
    }
  }
`;
export type CreateAutoWithdrawPlanMutationFn = Apollo.MutationFunction<
  CreateAutoWithdrawPlanMutation,
  CreateAutoWithdrawPlanMutationVariables
>;

/**
 * __useCreateAutoWithdrawPlanMutation__
 *
 * To run a mutation, you first call `useCreateAutoWithdrawPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAutoWithdrawPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAutoWithdrawPlanMutation, { data, loading, error }] = useCreateAutoWithdrawPlanMutation({
 *   variables: {
 *      createAutoWithdrawPlanInput: // value for 'createAutoWithdrawPlanInput'
 *   },
 * });
 */
export function useCreateAutoWithdrawPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAutoWithdrawPlanMutation,
    CreateAutoWithdrawPlanMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateAutoWithdrawPlanMutation,
    CreateAutoWithdrawPlanMutationVariables
  >(CreateAutoWithdrawPlanDocument, baseOptions);
}
export type CreateAutoWithdrawPlanMutationHookResult = ReturnType<
  typeof useCreateAutoWithdrawPlanMutation
>;
export type CreateAutoWithdrawPlanMutationResult =
  Apollo.MutationResult<CreateAutoWithdrawPlanMutation>;
export type CreateAutoWithdrawPlanMutationOptions = Apollo.BaseMutationOptions<
  CreateAutoWithdrawPlanMutation,
  CreateAutoWithdrawPlanMutationVariables
>;
export const CancelAutoWithdrawPlanDocument = gql`
  mutation cancelAutoWithdrawPlan(
    $cancelAutoWithdrawInput: CancelAutoWithdrawPlanInput!
  ) {
    cancelAutoWithdrawPlan(cancelAutoWithdrawInput: $cancelAutoWithdrawInput) {
      id
      enabled
      details {
        id
        frequency
        paymentMethodDestinations {
          paymentMethodId
          share
        }
      }
    }
  }
`;
export type CancelAutoWithdrawPlanMutationFn = Apollo.MutationFunction<
  CancelAutoWithdrawPlanMutation,
  CancelAutoWithdrawPlanMutationVariables
>;

/**
 * __useCancelAutoWithdrawPlanMutation__
 *
 * To run a mutation, you first call `useCancelAutoWithdrawPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAutoWithdrawPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAutoWithdrawPlanMutation, { data, loading, error }] = useCancelAutoWithdrawPlanMutation({
 *   variables: {
 *      cancelAutoWithdrawInput: // value for 'cancelAutoWithdrawInput'
 *   },
 * });
 */
export function useCancelAutoWithdrawPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAutoWithdrawPlanMutation,
    CancelAutoWithdrawPlanMutationVariables
  >
) {
  return Apollo.useMutation<
    CancelAutoWithdrawPlanMutation,
    CancelAutoWithdrawPlanMutationVariables
  >(CancelAutoWithdrawPlanDocument, baseOptions);
}
export type CancelAutoWithdrawPlanMutationHookResult = ReturnType<
  typeof useCancelAutoWithdrawPlanMutation
>;
export type CancelAutoWithdrawPlanMutationResult =
  Apollo.MutationResult<CancelAutoWithdrawPlanMutation>;
export type CancelAutoWithdrawPlanMutationOptions = Apollo.BaseMutationOptions<
  CancelAutoWithdrawPlanMutation,
  CancelAutoWithdrawPlanMutationVariables
>;
export const GetOwnerForWalletDocument = gql`
  query getOwnerForWallet($walletId: ID!) {
    walletById(walletId: $walletId) {
      id
      owner {
        id
        reference
      }
    }
  }
`;

/**
 * __useGetOwnerForWalletQuery__
 *
 * To run a query within a React component, call `useGetOwnerForWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnerForWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnerForWalletQuery({
 *   variables: {
 *      walletId: // value for 'walletId'
 *   },
 * });
 */
export function useGetOwnerForWalletQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOwnerForWalletQuery,
    GetOwnerForWalletQueryVariables
  >
) {
  return Apollo.useQuery<
    GetOwnerForWalletQuery,
    GetOwnerForWalletQueryVariables
  >(GetOwnerForWalletDocument, baseOptions);
}
export function useGetOwnerForWalletLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOwnerForWalletQuery,
    GetOwnerForWalletQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOwnerForWalletQuery,
    GetOwnerForWalletQueryVariables
  >(GetOwnerForWalletDocument, baseOptions);
}
export type GetOwnerForWalletQueryHookResult = ReturnType<
  typeof useGetOwnerForWalletQuery
>;
export type GetOwnerForWalletLazyQueryHookResult = ReturnType<
  typeof useGetOwnerForWalletLazyQuery
>;
export type GetOwnerForWalletQueryResult = Apollo.QueryResult<
  GetOwnerForWalletQuery,
  GetOwnerForWalletQueryVariables
>;
export const GetDefaultWalletWithAutoWithdrawInfoDocument = gql`
  query getDefaultWalletWithAutoWithdrawInfo(
    $walletOwnerReference: AiloRN!
    $currentUserLegalEntity: AiloRN!
  ) {
    walletByWalletOwnerReference(walletOwnerReference: $walletOwnerReference) {
      id
      name
      autoWithdrawPlans(
        payerLegalEntityId: $currentUserLegalEntity
        enabled: true
        disableOwnerFilter: true
      ) {
        items {
          id
          enabled
        }
      }
    }
  }
`;

/**
 * __useGetDefaultWalletWithAutoWithdrawInfoQuery__
 *
 * To run a query within a React component, call `useGetDefaultWalletWithAutoWithdrawInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultWalletWithAutoWithdrawInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultWalletWithAutoWithdrawInfoQuery({
 *   variables: {
 *      walletOwnerReference: // value for 'walletOwnerReference'
 *      currentUserLegalEntity: // value for 'currentUserLegalEntity'
 *   },
 * });
 */
export function useGetDefaultWalletWithAutoWithdrawInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDefaultWalletWithAutoWithdrawInfoQuery,
    GetDefaultWalletWithAutoWithdrawInfoQueryVariables
  >
) {
  return Apollo.useQuery<
    GetDefaultWalletWithAutoWithdrawInfoQuery,
    GetDefaultWalletWithAutoWithdrawInfoQueryVariables
  >(GetDefaultWalletWithAutoWithdrawInfoDocument, baseOptions);
}
export function useGetDefaultWalletWithAutoWithdrawInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDefaultWalletWithAutoWithdrawInfoQuery,
    GetDefaultWalletWithAutoWithdrawInfoQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetDefaultWalletWithAutoWithdrawInfoQuery,
    GetDefaultWalletWithAutoWithdrawInfoQueryVariables
  >(GetDefaultWalletWithAutoWithdrawInfoDocument, baseOptions);
}
export type GetDefaultWalletWithAutoWithdrawInfoQueryHookResult = ReturnType<
  typeof useGetDefaultWalletWithAutoWithdrawInfoQuery
>;
export type GetDefaultWalletWithAutoWithdrawInfoLazyQueryHookResult =
  ReturnType<typeof useGetDefaultWalletWithAutoWithdrawInfoLazyQuery>;
export type GetDefaultWalletWithAutoWithdrawInfoQueryResult =
  Apollo.QueryResult<
    GetDefaultWalletWithAutoWithdrawInfoQuery,
    GetDefaultWalletWithAutoWithdrawInfoQueryVariables
  >;
export const GetBillAttachmentsDocument = gql`
  query getBillAttachments($billId: ID!) {
    billById(billId: $billId) {
      ailoRN
      attachments {
        id
        file {
          id
          fileName
          fileSize
          contentType
          url
        }
      }
    }
  }
`;

/**
 * __useGetBillAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetBillAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillAttachmentsQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useGetBillAttachmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillAttachmentsQuery,
    GetBillAttachmentsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetBillAttachmentsQuery,
    GetBillAttachmentsQueryVariables
  >(GetBillAttachmentsDocument, baseOptions);
}
export function useGetBillAttachmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillAttachmentsQuery,
    GetBillAttachmentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetBillAttachmentsQuery,
    GetBillAttachmentsQueryVariables
  >(GetBillAttachmentsDocument, baseOptions);
}
export type GetBillAttachmentsQueryHookResult = ReturnType<
  typeof useGetBillAttachmentsQuery
>;
export type GetBillAttachmentsLazyQueryHookResult = ReturnType<
  typeof useGetBillAttachmentsLazyQuery
>;
export type GetBillAttachmentsQueryResult = Apollo.QueryResult<
  GetBillAttachmentsQuery,
  GetBillAttachmentsQueryVariables
>;
export const GetSecondaryBillDetailsDocument = gql`
  query getSecondaryBillDetails($billId: ID!) {
    billById(billId: $billId) {
      ailoRN
      status
      agencyStatus
      paymentReference {
        id
        crn
        supplierPaymentMethodCompanion {
          paymentMethod {
            __typename
            ... on BPay {
              billerCode
            }
          }
        }
      }
      description
      invoiceNumber
      createdAt
      dueDate: dueDateV2
      issueDate: issueDateV2
      taxCategory {
        name
      }
      liabilityState {
        paymentStatus
        paidAt
      }
      liability {
        id
        lastPaymentDate
      }
    }
  }
`;

/**
 * __useGetSecondaryBillDetailsQuery__
 *
 * To run a query within a React component, call `useGetSecondaryBillDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecondaryBillDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecondaryBillDetailsQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useGetSecondaryBillDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSecondaryBillDetailsQuery,
    GetSecondaryBillDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetSecondaryBillDetailsQuery,
    GetSecondaryBillDetailsQueryVariables
  >(GetSecondaryBillDetailsDocument, baseOptions);
}
export function useGetSecondaryBillDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSecondaryBillDetailsQuery,
    GetSecondaryBillDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetSecondaryBillDetailsQuery,
    GetSecondaryBillDetailsQueryVariables
  >(GetSecondaryBillDetailsDocument, baseOptions);
}
export type GetSecondaryBillDetailsQueryHookResult = ReturnType<
  typeof useGetSecondaryBillDetailsQuery
>;
export type GetSecondaryBillDetailsLazyQueryHookResult = ReturnType<
  typeof useGetSecondaryBillDetailsLazyQuery
>;
export type GetSecondaryBillDetailsQueryResult = Apollo.QueryResult<
  GetSecondaryBillDetailsQuery,
  GetSecondaryBillDetailsQueryVariables
>;
export const GetBillLineItemsDocument = gql`
  query getBillLineItems($billId: ID!) {
    billById(billId: $billId) {
      ailoRN
      amount {
        cents
      }
      lineItems {
        items {
          ailoRN
          taxInclusiveAmount {
            cents
          }
          taxAmount {
            cents
          }
          description
          isTax
        }
      }
    }
  }
`;

/**
 * __useGetBillLineItemsQuery__
 *
 * To run a query within a React component, call `useGetBillLineItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillLineItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillLineItemsQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useGetBillLineItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillLineItemsQuery,
    GetBillLineItemsQueryVariables
  >
) {
  return Apollo.useQuery<GetBillLineItemsQuery, GetBillLineItemsQueryVariables>(
    GetBillLineItemsDocument,
    baseOptions
  );
}
export function useGetBillLineItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillLineItemsQuery,
    GetBillLineItemsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetBillLineItemsQuery,
    GetBillLineItemsQueryVariables
  >(GetBillLineItemsDocument, baseOptions);
}
export type GetBillLineItemsQueryHookResult = ReturnType<
  typeof useGetBillLineItemsQuery
>;
export type GetBillLineItemsLazyQueryHookResult = ReturnType<
  typeof useGetBillLineItemsLazyQuery
>;
export type GetBillLineItemsQueryResult = Apollo.QueryResult<
  GetBillLineItemsQuery,
  GetBillLineItemsQueryVariables
>;
export const GetMainBillDetailsDocument = gql`
  query getMainBillDetails($billId: ID!) {
    billById(billId: $billId) {
      ailoRN
      agencyStatus
      supplier {
        ailoRN
        name
        internalReference
        internalLegalEntity {
          ...legalEntityCompanionOrganisationFragment
        }
      }
      amount {
        cents
      }
      liabilityState {
        paymentStatus
      }
      status
    }
  }
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
`;

/**
 * __useGetMainBillDetailsQuery__
 *
 * To run a query within a React component, call `useGetMainBillDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMainBillDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMainBillDetailsQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useGetMainBillDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMainBillDetailsQuery,
    GetMainBillDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetMainBillDetailsQuery,
    GetMainBillDetailsQueryVariables
  >(GetMainBillDetailsDocument, baseOptions);
}
export function useGetMainBillDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMainBillDetailsQuery,
    GetMainBillDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetMainBillDetailsQuery,
    GetMainBillDetailsQueryVariables
  >(GetMainBillDetailsDocument, baseOptions);
}
export type GetMainBillDetailsQueryHookResult = ReturnType<
  typeof useGetMainBillDetailsQuery
>;
export type GetMainBillDetailsLazyQueryHookResult = ReturnType<
  typeof useGetMainBillDetailsLazyQuery
>;
export type GetMainBillDetailsQueryResult = Apollo.QueryResult<
  GetMainBillDetailsQuery,
  GetMainBillDetailsQueryVariables
>;
export const GetPayBillDetailsDocument = gql`
  query getPayBillDetails($billId: ID!) {
    billById(billId: $billId) {
      ailoRN
      amount {
        cents
      }
      liability {
        id
        reference
        dueAmount {
          cents
        }
      }
      supplier {
        ailoRN
        name
        internalReference
        internalLegalEntity {
          ...legalEntityCompanionOrganisationFragment
        }
      }
    }
  }
  ${LegalEntityCompanionOrganisationFragmentFragmentDoc}
`;

/**
 * __useGetPayBillDetailsQuery__
 *
 * To run a query within a React component, call `useGetPayBillDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayBillDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayBillDetailsQuery({
 *   variables: {
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useGetPayBillDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayBillDetailsQuery,
    GetPayBillDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPayBillDetailsQuery,
    GetPayBillDetailsQueryVariables
  >(GetPayBillDetailsDocument, baseOptions);
}
export function useGetPayBillDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayBillDetailsQuery,
    GetPayBillDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPayBillDetailsQuery,
    GetPayBillDetailsQueryVariables
  >(GetPayBillDetailsDocument, baseOptions);
}
export type GetPayBillDetailsQueryHookResult = ReturnType<
  typeof useGetPayBillDetailsQuery
>;
export type GetPayBillDetailsLazyQueryHookResult = ReturnType<
  typeof useGetPayBillDetailsLazyQuery
>;
export type GetPayBillDetailsQueryResult = Apollo.QueryResult<
  GetPayBillDetailsQuery,
  GetPayBillDetailsQueryVariables
>;
export const CreateLiabilityPaymentPlanDocument = gql`
  mutation createLiabilityPaymentPlan(
    $createLiabilityPaymentPlanInput: CreateLiabilityPaymentPlanInput!
  ) {
    createLiabilityPaymentPlan(
      createLiabilityPaymentPlanInput: $createLiabilityPaymentPlanInput
    ) {
      id
      owner
      liabilityId
      enabled
      createdBy
      createdAt
      details {
        id
        amount {
          cents
        }
        liabilityPaymentPlanId
        paymentMethodId
        timezoneId
        startDate
        endDate
        frequency
        anniversary
        createdBy
        createdAt
      }
    }
  }
`;
export type CreateLiabilityPaymentPlanMutationFn = Apollo.MutationFunction<
  CreateLiabilityPaymentPlanMutation,
  CreateLiabilityPaymentPlanMutationVariables
>;

/**
 * __useCreateLiabilityPaymentPlanMutation__
 *
 * To run a mutation, you first call `useCreateLiabilityPaymentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLiabilityPaymentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLiabilityPaymentPlanMutation, { data, loading, error }] = useCreateLiabilityPaymentPlanMutation({
 *   variables: {
 *      createLiabilityPaymentPlanInput: // value for 'createLiabilityPaymentPlanInput'
 *   },
 * });
 */
export function useCreateLiabilityPaymentPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLiabilityPaymentPlanMutation,
    CreateLiabilityPaymentPlanMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateLiabilityPaymentPlanMutation,
    CreateLiabilityPaymentPlanMutationVariables
  >(CreateLiabilityPaymentPlanDocument, baseOptions);
}
export type CreateLiabilityPaymentPlanMutationHookResult = ReturnType<
  typeof useCreateLiabilityPaymentPlanMutation
>;
export type CreateLiabilityPaymentPlanMutationResult =
  Apollo.MutationResult<CreateLiabilityPaymentPlanMutation>;
export type CreateLiabilityPaymentPlanMutationOptions =
  Apollo.BaseMutationOptions<
    CreateLiabilityPaymentPlanMutation,
    CreateLiabilityPaymentPlanMutationVariables
  >;
export const CancelLiabilityPaymentPlanDocument = gql`
  mutation cancelLiabilityPaymentPlan(
    $cancelLiabilityPaymentPlanInput: CancelLiabilityPaymentPlanInput!
  ) {
    cancelLiabilityPaymentPlan(
      cancelLiabilityPaymentPlanInput: $cancelLiabilityPaymentPlanInput
    ) {
      id
      enabled
      details {
        id
        frequency
      }
    }
  }
`;
export type CancelLiabilityPaymentPlanMutationFn = Apollo.MutationFunction<
  CancelLiabilityPaymentPlanMutation,
  CancelLiabilityPaymentPlanMutationVariables
>;

/**
 * __useCancelLiabilityPaymentPlanMutation__
 *
 * To run a mutation, you first call `useCancelLiabilityPaymentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelLiabilityPaymentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelLiabilityPaymentPlanMutation, { data, loading, error }] = useCancelLiabilityPaymentPlanMutation({
 *   variables: {
 *      cancelLiabilityPaymentPlanInput: // value for 'cancelLiabilityPaymentPlanInput'
 *   },
 * });
 */
export function useCancelLiabilityPaymentPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelLiabilityPaymentPlanMutation,
    CancelLiabilityPaymentPlanMutationVariables
  >
) {
  return Apollo.useMutation<
    CancelLiabilityPaymentPlanMutation,
    CancelLiabilityPaymentPlanMutationVariables
  >(CancelLiabilityPaymentPlanDocument, baseOptions);
}
export type CancelLiabilityPaymentPlanMutationHookResult = ReturnType<
  typeof useCancelLiabilityPaymentPlanMutation
>;
export type CancelLiabilityPaymentPlanMutationResult =
  Apollo.MutationResult<CancelLiabilityPaymentPlanMutation>;
export type CancelLiabilityPaymentPlanMutationOptions =
  Apollo.BaseMutationOptions<
    CancelLiabilityPaymentPlanMutation,
    CancelLiabilityPaymentPlanMutationVariables
  >;
export const GetLiabilityPaymentPlanByIdDocument = gql`
  query getLiabilityPaymentPlanById(
    $liabilityPaymentPlanId: ID!
    $payerLegalEntityId: AiloRN!
  ) {
    liabilityPaymentPlanById(
      payerLegalEntityId: $payerLegalEntityId
      liabilityPaymentPlanId: $liabilityPaymentPlanId
    ) {
      id
      owner
      liabilityId
      enabled
      createdBy
      createdAt
      details {
        id
        amount {
          cents
        }
        liabilityPaymentPlanId
        paymentMethodId
        timezoneId
        startDate
        endDate
        frequency
        anniversary
        createdBy
        createdAt
      }
    }
  }
`;

/**
 * __useGetLiabilityPaymentPlanByIdQuery__
 *
 * To run a query within a React component, call `useGetLiabilityPaymentPlanByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiabilityPaymentPlanByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiabilityPaymentPlanByIdQuery({
 *   variables: {
 *      liabilityPaymentPlanId: // value for 'liabilityPaymentPlanId'
 *      payerLegalEntityId: // value for 'payerLegalEntityId'
 *   },
 * });
 */
export function useGetLiabilityPaymentPlanByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLiabilityPaymentPlanByIdQuery,
    GetLiabilityPaymentPlanByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetLiabilityPaymentPlanByIdQuery,
    GetLiabilityPaymentPlanByIdQueryVariables
  >(GetLiabilityPaymentPlanByIdDocument, baseOptions);
}
export function useGetLiabilityPaymentPlanByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLiabilityPaymentPlanByIdQuery,
    GetLiabilityPaymentPlanByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetLiabilityPaymentPlanByIdQuery,
    GetLiabilityPaymentPlanByIdQueryVariables
  >(GetLiabilityPaymentPlanByIdDocument, baseOptions);
}
export type GetLiabilityPaymentPlanByIdQueryHookResult = ReturnType<
  typeof useGetLiabilityPaymentPlanByIdQuery
>;
export type GetLiabilityPaymentPlanByIdLazyQueryHookResult = ReturnType<
  typeof useGetLiabilityPaymentPlanByIdLazyQuery
>;
export type GetLiabilityPaymentPlanByIdQueryResult = Apollo.QueryResult<
  GetLiabilityPaymentPlanByIdQuery,
  GetLiabilityPaymentPlanByIdQueryVariables
>;
export const UpdateLiabilityPaymentPlanDocument = gql`
  mutation updateLiabilityPaymentPlan(
    $updateLiabilityPaymentPlanInput: UpdateLiabilityPaymentPlanInput!
  ) {
    updateLiabilityPaymentPlan(
      updateLiabilityPaymentPlanInput: $updateLiabilityPaymentPlanInput
    ) {
      enabled
    }
  }
`;
export type UpdateLiabilityPaymentPlanMutationFn = Apollo.MutationFunction<
  UpdateLiabilityPaymentPlanMutation,
  UpdateLiabilityPaymentPlanMutationVariables
>;

/**
 * __useUpdateLiabilityPaymentPlanMutation__
 *
 * To run a mutation, you first call `useUpdateLiabilityPaymentPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLiabilityPaymentPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLiabilityPaymentPlanMutation, { data, loading, error }] = useUpdateLiabilityPaymentPlanMutation({
 *   variables: {
 *      updateLiabilityPaymentPlanInput: // value for 'updateLiabilityPaymentPlanInput'
 *   },
 * });
 */
export function useUpdateLiabilityPaymentPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLiabilityPaymentPlanMutation,
    UpdateLiabilityPaymentPlanMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateLiabilityPaymentPlanMutation,
    UpdateLiabilityPaymentPlanMutationVariables
  >(UpdateLiabilityPaymentPlanDocument, baseOptions);
}
export type UpdateLiabilityPaymentPlanMutationHookResult = ReturnType<
  typeof useUpdateLiabilityPaymentPlanMutation
>;
export type UpdateLiabilityPaymentPlanMutationResult =
  Apollo.MutationResult<UpdateLiabilityPaymentPlanMutation>;
export type UpdateLiabilityPaymentPlanMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLiabilityPaymentPlanMutation,
    UpdateLiabilityPaymentPlanMutationVariables
  >;
export const GetTenanciesWithLiabilitiesDocument = gql`
  query getTenanciesWithLiabilities {
    tenancies {
      items {
        id
        liability {
          id
          reference
          autoPaymentDetails {
            paymentMethod {
              id
            }
          }
        }
        ...TenancyProperty
      }
    }
  }
  ${TenancyPropertyFragmentDoc}
`;

/**
 * __useGetTenanciesWithLiabilitiesQuery__
 *
 * To run a query within a React component, call `useGetTenanciesWithLiabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenanciesWithLiabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenanciesWithLiabilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenanciesWithLiabilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTenanciesWithLiabilitiesQuery,
    GetTenanciesWithLiabilitiesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenanciesWithLiabilitiesQuery,
    GetTenanciesWithLiabilitiesQueryVariables
  >(GetTenanciesWithLiabilitiesDocument, baseOptions);
}
export function useGetTenanciesWithLiabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenanciesWithLiabilitiesQuery,
    GetTenanciesWithLiabilitiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenanciesWithLiabilitiesQuery,
    GetTenanciesWithLiabilitiesQueryVariables
  >(GetTenanciesWithLiabilitiesDocument, baseOptions);
}
export type GetTenanciesWithLiabilitiesQueryHookResult = ReturnType<
  typeof useGetTenanciesWithLiabilitiesQuery
>;
export type GetTenanciesWithLiabilitiesLazyQueryHookResult = ReturnType<
  typeof useGetTenanciesWithLiabilitiesLazyQuery
>;
export type GetTenanciesWithLiabilitiesQueryResult = Apollo.QueryResult<
  GetTenanciesWithLiabilitiesQuery,
  GetTenanciesWithLiabilitiesQueryVariables
>;
export const SetBillLiabilityStateDocument = gql`
  mutation setBillLiabilityState(
    $billPaymentDetails: BillLiabilityStateInput!
  ) {
    setBillLiabilityState(billPaymentDetails: $billPaymentDetails) {
      paidAt
      dueAmount {
        cents
      }
    }
  }
`;
export type SetBillLiabilityStateMutationFn = Apollo.MutationFunction<
  SetBillLiabilityStateMutation,
  SetBillLiabilityStateMutationVariables
>;

/**
 * __useSetBillLiabilityStateMutation__
 *
 * To run a mutation, you first call `useSetBillLiabilityStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBillLiabilityStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBillLiabilityStateMutation, { data, loading, error }] = useSetBillLiabilityStateMutation({
 *   variables: {
 *      billPaymentDetails: // value for 'billPaymentDetails'
 *   },
 * });
 */
export function useSetBillLiabilityStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetBillLiabilityStateMutation,
    SetBillLiabilityStateMutationVariables
  >
) {
  return Apollo.useMutation<
    SetBillLiabilityStateMutation,
    SetBillLiabilityStateMutationVariables
  >(SetBillLiabilityStateDocument, baseOptions);
}
export type SetBillLiabilityStateMutationHookResult = ReturnType<
  typeof useSetBillLiabilityStateMutation
>;
export type SetBillLiabilityStateMutationResult =
  Apollo.MutationResult<SetBillLiabilityStateMutation>;
export type SetBillLiabilityStateMutationOptions = Apollo.BaseMutationOptions<
  SetBillLiabilityStateMutation,
  SetBillLiabilityStateMutationVariables
>;
export const GetPaymentKeyAndAutoPaymentDocument = gql`
  query getPaymentKeyAndAutoPayment($liabilityId: ID!) {
    liabilityById(liabilityId: $liabilityId) {
      id
      reference
      paymentKey
      autoPaymentDetails {
        paymentMethod {
          id
        }
      }
    }
  }
`;

/**
 * __useGetPaymentKeyAndAutoPaymentQuery__
 *
 * To run a query within a React component, call `useGetPaymentKeyAndAutoPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentKeyAndAutoPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentKeyAndAutoPaymentQuery({
 *   variables: {
 *      liabilityId: // value for 'liabilityId'
 *   },
 * });
 */
export function useGetPaymentKeyAndAutoPaymentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentKeyAndAutoPaymentQuery,
    GetPaymentKeyAndAutoPaymentQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaymentKeyAndAutoPaymentQuery,
    GetPaymentKeyAndAutoPaymentQueryVariables
  >(GetPaymentKeyAndAutoPaymentDocument, baseOptions);
}
export function useGetPaymentKeyAndAutoPaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentKeyAndAutoPaymentQuery,
    GetPaymentKeyAndAutoPaymentQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaymentKeyAndAutoPaymentQuery,
    GetPaymentKeyAndAutoPaymentQueryVariables
  >(GetPaymentKeyAndAutoPaymentDocument, baseOptions);
}
export type GetPaymentKeyAndAutoPaymentQueryHookResult = ReturnType<
  typeof useGetPaymentKeyAndAutoPaymentQuery
>;
export type GetPaymentKeyAndAutoPaymentLazyQueryHookResult = ReturnType<
  typeof useGetPaymentKeyAndAutoPaymentLazyQuery
>;
export type GetPaymentKeyAndAutoPaymentQueryResult = Apollo.QueryResult<
  GetPaymentKeyAndAutoPaymentQuery,
  GetPaymentKeyAndAutoPaymentQueryVariables
>;
export const GetRentPaymentSuccessDetailsDocument = gql`
  query getRentPaymentSuccessDetails($liabilityId: ID!, $tenancyId: ID!) {
    liabilityById(liabilityId: $liabilityId) {
      id
      reference
      nextDueDate
      autoPaymentDetails {
        paymentMethod {
          id
        }
      }
      terminationAmountDue {
        cents
      }
    }
    tenancy(tenancyId: $tenancyId) {
      id
      endDate
    }
  }
`;

/**
 * __useGetRentPaymentSuccessDetailsQuery__
 *
 * To run a query within a React component, call `useGetRentPaymentSuccessDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRentPaymentSuccessDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRentPaymentSuccessDetailsQuery({
 *   variables: {
 *      liabilityId: // value for 'liabilityId'
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetRentPaymentSuccessDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRentPaymentSuccessDetailsQuery,
    GetRentPaymentSuccessDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetRentPaymentSuccessDetailsQuery,
    GetRentPaymentSuccessDetailsQueryVariables
  >(GetRentPaymentSuccessDetailsDocument, baseOptions);
}
export function useGetRentPaymentSuccessDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRentPaymentSuccessDetailsQuery,
    GetRentPaymentSuccessDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRentPaymentSuccessDetailsQuery,
    GetRentPaymentSuccessDetailsQueryVariables
  >(GetRentPaymentSuccessDetailsDocument, baseOptions);
}
export type GetRentPaymentSuccessDetailsQueryHookResult = ReturnType<
  typeof useGetRentPaymentSuccessDetailsQuery
>;
export type GetRentPaymentSuccessDetailsLazyQueryHookResult = ReturnType<
  typeof useGetRentPaymentSuccessDetailsLazyQuery
>;
export type GetRentPaymentSuccessDetailsQueryResult = Apollo.QueryResult<
  GetRentPaymentSuccessDetailsQuery,
  GetRentPaymentSuccessDetailsQueryVariables
>;
export const GetPayRentLiabilityDetailsDocument = gql`
  query getPayRentLiabilityDetails($tenancyId: ID!, $liabilityId: ID!) {
    liabilityById(liabilityId: $liabilityId) {
      id
      reference
      paymentKey
      autoPaymentDetails {
        paymentMethod {
          id
        }
      }
      terminationAmountDue {
        cents
      }
    }
    tenancy(tenancyId: $tenancyId) {
      id
      endDate
    }
  }
`;

/**
 * __useGetPayRentLiabilityDetailsQuery__
 *
 * To run a query within a React component, call `useGetPayRentLiabilityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayRentLiabilityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayRentLiabilityDetailsQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *      liabilityId: // value for 'liabilityId'
 *   },
 * });
 */
export function useGetPayRentLiabilityDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayRentLiabilityDetailsQuery,
    GetPayRentLiabilityDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPayRentLiabilityDetailsQuery,
    GetPayRentLiabilityDetailsQueryVariables
  >(GetPayRentLiabilityDetailsDocument, baseOptions);
}
export function useGetPayRentLiabilityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayRentLiabilityDetailsQuery,
    GetPayRentLiabilityDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPayRentLiabilityDetailsQuery,
    GetPayRentLiabilityDetailsQueryVariables
  >(GetPayRentLiabilityDetailsDocument, baseOptions);
}
export type GetPayRentLiabilityDetailsQueryHookResult = ReturnType<
  typeof useGetPayRentLiabilityDetailsQuery
>;
export type GetPayRentLiabilityDetailsLazyQueryHookResult = ReturnType<
  typeof useGetPayRentLiabilityDetailsLazyQuery
>;
export type GetPayRentLiabilityDetailsQueryResult = Apollo.QueryResult<
  GetPayRentLiabilityDetailsQuery,
  GetPayRentLiabilityDetailsQueryVariables
>;
export const GetPaymentMethodPaidWithDocument = gql`
  query getPaymentMethodPaidWith($paymentMethodId: ID!) {
    paymentMethodById(paymentMethodId: $paymentMethodId) {
      id
      __typename
      isOnceOff
    }
  }
`;

/**
 * __useGetPaymentMethodPaidWithQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodPaidWithQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodPaidWithQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodPaidWithQuery({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useGetPaymentMethodPaidWithQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentMethodPaidWithQuery,
    GetPaymentMethodPaidWithQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaymentMethodPaidWithQuery,
    GetPaymentMethodPaidWithQueryVariables
  >(GetPaymentMethodPaidWithDocument, baseOptions);
}
export function useGetPaymentMethodPaidWithLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodPaidWithQuery,
    GetPaymentMethodPaidWithQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaymentMethodPaidWithQuery,
    GetPaymentMethodPaidWithQueryVariables
  >(GetPaymentMethodPaidWithDocument, baseOptions);
}
export type GetPaymentMethodPaidWithQueryHookResult = ReturnType<
  typeof useGetPaymentMethodPaidWithQuery
>;
export type GetPaymentMethodPaidWithLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodPaidWithLazyQuery
>;
export type GetPaymentMethodPaidWithQueryResult = Apollo.QueryResult<
  GetPaymentMethodPaidWithQuery,
  GetPaymentMethodPaidWithQueryVariables
>;
export const SetupAutoPayRentDocument = gql`
  mutation setupAutoPayRent($liabilityId: ID!, $paymentMethodId: ID!) {
    createUpdateAutoPay(
      createUpdateAutoPayInput: {
        liabilityId: $liabilityId
        paymentMethodId: $paymentMethodId
      }
    ) {
      status
    }
  }
`;
export type SetupAutoPayRentMutationFn = Apollo.MutationFunction<
  SetupAutoPayRentMutation,
  SetupAutoPayRentMutationVariables
>;

/**
 * __useSetupAutoPayRentMutation__
 *
 * To run a mutation, you first call `useSetupAutoPayRentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupAutoPayRentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupAutoPayRentMutation, { data, loading, error }] = useSetupAutoPayRentMutation({
 *   variables: {
 *      liabilityId: // value for 'liabilityId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useSetupAutoPayRentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupAutoPayRentMutation,
    SetupAutoPayRentMutationVariables
  >
) {
  return Apollo.useMutation<
    SetupAutoPayRentMutation,
    SetupAutoPayRentMutationVariables
  >(SetupAutoPayRentDocument, baseOptions);
}
export type SetupAutoPayRentMutationHookResult = ReturnType<
  typeof useSetupAutoPayRentMutation
>;
export type SetupAutoPayRentMutationResult =
  Apollo.MutationResult<SetupAutoPayRentMutation>;
export type SetupAutoPayRentMutationOptions = Apollo.BaseMutationOptions<
  SetupAutoPayRentMutation,
  SetupAutoPayRentMutationVariables
>;
export const CancelAutoPayRentDocument = gql`
  mutation cancelAutoPayRent($liabilityId: ID!) {
    cancelAutoPay(cancelAutoPayInput: { liabilityId: $liabilityId }) {
      status
    }
  }
`;
export type CancelAutoPayRentMutationFn = Apollo.MutationFunction<
  CancelAutoPayRentMutation,
  CancelAutoPayRentMutationVariables
>;

/**
 * __useCancelAutoPayRentMutation__
 *
 * To run a mutation, you first call `useCancelAutoPayRentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAutoPayRentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAutoPayRentMutation, { data, loading, error }] = useCancelAutoPayRentMutation({
 *   variables: {
 *      liabilityId: // value for 'liabilityId'
 *   },
 * });
 */
export function useCancelAutoPayRentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAutoPayRentMutation,
    CancelAutoPayRentMutationVariables
  >
) {
  return Apollo.useMutation<
    CancelAutoPayRentMutation,
    CancelAutoPayRentMutationVariables
  >(CancelAutoPayRentDocument, baseOptions);
}
export type CancelAutoPayRentMutationHookResult = ReturnType<
  typeof useCancelAutoPayRentMutation
>;
export type CancelAutoPayRentMutationResult =
  Apollo.MutationResult<CancelAutoPayRentMutation>;
export type CancelAutoPayRentMutationOptions = Apollo.BaseMutationOptions<
  CancelAutoPayRentMutation,
  CancelAutoPayRentMutationVariables
>;
export const GetAutoPayTenancyDetailsDocument = gql`
  query getAutoPayTenancyDetails($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      liability {
        id
        reference
        nextChargeAmount {
          cents
        }
      }
      ...TenancyProperty
    }
  }
  ${TenancyPropertyFragmentDoc}
`;

/**
 * __useGetAutoPayTenancyDetailsQuery__
 *
 * To run a query within a React component, call `useGetAutoPayTenancyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoPayTenancyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoPayTenancyDetailsQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetAutoPayTenancyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAutoPayTenancyDetailsQuery,
    GetAutoPayTenancyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAutoPayTenancyDetailsQuery,
    GetAutoPayTenancyDetailsQueryVariables
  >(GetAutoPayTenancyDetailsDocument, baseOptions);
}
export function useGetAutoPayTenancyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutoPayTenancyDetailsQuery,
    GetAutoPayTenancyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAutoPayTenancyDetailsQuery,
    GetAutoPayTenancyDetailsQueryVariables
  >(GetAutoPayTenancyDetailsDocument, baseOptions);
}
export type GetAutoPayTenancyDetailsQueryHookResult = ReturnType<
  typeof useGetAutoPayTenancyDetailsQuery
>;
export type GetAutoPayTenancyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAutoPayTenancyDetailsLazyQuery
>;
export type GetAutoPayTenancyDetailsQueryResult = Apollo.QueryResult<
  GetAutoPayTenancyDetailsQuery,
  GetAutoPayTenancyDetailsQueryVariables
>;
export const GetPaymentKeyDocument = gql`
  query getPaymentKey($liabilityId: ID!) {
    liabilityById(liabilityId: $liabilityId) {
      id
      paymentKey
    }
  }
`;

/**
 * __useGetPaymentKeyQuery__
 *
 * To run a query within a React component, call `useGetPaymentKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentKeyQuery({
 *   variables: {
 *      liabilityId: // value for 'liabilityId'
 *   },
 * });
 */
export function useGetPaymentKeyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentKeyQuery,
    GetPaymentKeyQueryVariables
  >
) {
  return Apollo.useQuery<GetPaymentKeyQuery, GetPaymentKeyQueryVariables>(
    GetPaymentKeyDocument,
    baseOptions
  );
}
export function useGetPaymentKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentKeyQuery,
    GetPaymentKeyQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPaymentKeyQuery, GetPaymentKeyQueryVariables>(
    GetPaymentKeyDocument,
    baseOptions
  );
}
export type GetPaymentKeyQueryHookResult = ReturnType<
  typeof useGetPaymentKeyQuery
>;
export type GetPaymentKeyLazyQueryHookResult = ReturnType<
  typeof useGetPaymentKeyLazyQuery
>;
export type GetPaymentKeyQueryResult = Apollo.QueryResult<
  GetPaymentKeyQuery,
  GetPaymentKeyQueryVariables
>;
export const GetPayDepositDetailsDocument = gql`
  query getPayDepositDetails($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      deposit {
        id
        status
        amount {
          cents
        }
        liability {
          id
        }
      }
      managingEntity {
        id
        registeredEntityName
      }
      ...TenancyProperty
    }
  }
  ${TenancyPropertyFragmentDoc}
`;

/**
 * __useGetPayDepositDetailsQuery__
 *
 * To run a query within a React component, call `useGetPayDepositDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayDepositDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayDepositDetailsQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetPayDepositDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPayDepositDetailsQuery,
    GetPayDepositDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPayDepositDetailsQuery,
    GetPayDepositDetailsQueryVariables
  >(GetPayDepositDetailsDocument, baseOptions);
}
export function useGetPayDepositDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPayDepositDetailsQuery,
    GetPayDepositDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPayDepositDetailsQuery,
    GetPayDepositDetailsQueryVariables
  >(GetPayDepositDetailsDocument, baseOptions);
}
export type GetPayDepositDetailsQueryHookResult = ReturnType<
  typeof useGetPayDepositDetailsQuery
>;
export type GetPayDepositDetailsLazyQueryHookResult = ReturnType<
  typeof useGetPayDepositDetailsLazyQuery
>;
export type GetPayDepositDetailsQueryResult = Apollo.QueryResult<
  GetPayDepositDetailsQuery,
  GetPayDepositDetailsQueryVariables
>;
export const GetOnlyPaymentMethodByIdDocument = gql`
  query getOnlyPaymentMethodById($paymentMethodId: ID!) {
    paymentMethodById(paymentMethodId: $paymentMethodId) {
      __typename
      id
      ... on BankAccount {
        accountName
        accountNumber
      }
      ... on CreditCard {
        number
        type
      }
    }
  }
`;

/**
 * __useGetOnlyPaymentMethodByIdQuery__
 *
 * To run a query within a React component, call `useGetOnlyPaymentMethodByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnlyPaymentMethodByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnlyPaymentMethodByIdQuery({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useGetOnlyPaymentMethodByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnlyPaymentMethodByIdQuery,
    GetOnlyPaymentMethodByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetOnlyPaymentMethodByIdQuery,
    GetOnlyPaymentMethodByIdQueryVariables
  >(GetOnlyPaymentMethodByIdDocument, baseOptions);
}
export function useGetOnlyPaymentMethodByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnlyPaymentMethodByIdQuery,
    GetOnlyPaymentMethodByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOnlyPaymentMethodByIdQuery,
    GetOnlyPaymentMethodByIdQueryVariables
  >(GetOnlyPaymentMethodByIdDocument, baseOptions);
}
export type GetOnlyPaymentMethodByIdQueryHookResult = ReturnType<
  typeof useGetOnlyPaymentMethodByIdQuery
>;
export type GetOnlyPaymentMethodByIdLazyQueryHookResult = ReturnType<
  typeof useGetOnlyPaymentMethodByIdLazyQuery
>;
export type GetOnlyPaymentMethodByIdQueryResult = Apollo.QueryResult<
  GetOnlyPaymentMethodByIdQuery,
  GetOnlyPaymentMethodByIdQueryVariables
>;
export const GetWalletKycVerificationStatusDocument = gql`
  query getWalletKycVerificationStatus {
    effectiveUser {
      id
      person {
        id
        kycVerifiedAccordingToLedgerService
      }
    }
  }
`;

/**
 * __useGetWalletKycVerificationStatusQuery__
 *
 * To run a query within a React component, call `useGetWalletKycVerificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletKycVerificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletKycVerificationStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWalletKycVerificationStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWalletKycVerificationStatusQuery,
    GetWalletKycVerificationStatusQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWalletKycVerificationStatusQuery,
    GetWalletKycVerificationStatusQueryVariables
  >(GetWalletKycVerificationStatusDocument, baseOptions);
}
export function useGetWalletKycVerificationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletKycVerificationStatusQuery,
    GetWalletKycVerificationStatusQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWalletKycVerificationStatusQuery,
    GetWalletKycVerificationStatusQueryVariables
  >(GetWalletKycVerificationStatusDocument, baseOptions);
}
export type GetWalletKycVerificationStatusQueryHookResult = ReturnType<
  typeof useGetWalletKycVerificationStatusQuery
>;
export type GetWalletKycVerificationStatusLazyQueryHookResult = ReturnType<
  typeof useGetWalletKycVerificationStatusLazyQuery
>;
export type GetWalletKycVerificationStatusQueryResult = Apollo.QueryResult<
  GetWalletKycVerificationStatusQuery,
  GetWalletKycVerificationStatusQueryVariables
>;
export const GetRentLiabilitiesWithAutoPaymentDetailsAndAddressDocument = gql`
  query getRentLiabilitiesWithAutoPaymentDetailsAndAddress(
    $payerLegalEntityId: AiloRN!
  ) {
    tenancies {
      items {
        id
        liability {
          id
          reference
          autoPaymentDetails {
            paymentMethod {
              id
            }
          }
          paymentPlans(payerLegalEntityId: $payerLegalEntityId, enabled: true) {
            items {
              id
              details {
                id
                frequency
              }
            }
          }
        }
        ...TenancyProperty
      }
    }
  }
  ${TenancyPropertyFragmentDoc}
`;

/**
 * __useGetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery__
 *
 * To run a query within a React component, call `useGetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery({
 *   variables: {
 *      payerLegalEntityId: // value for 'payerLegalEntityId'
 *   },
 * });
 */
export function useGetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery,
    GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQueryVariables
  >
) {
  return Apollo.useQuery<
    GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery,
    GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQueryVariables
  >(GetRentLiabilitiesWithAutoPaymentDetailsAndAddressDocument, baseOptions);
}
export function useGetRentLiabilitiesWithAutoPaymentDetailsAndAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery,
    GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery,
    GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQueryVariables
  >(GetRentLiabilitiesWithAutoPaymentDetailsAndAddressDocument, baseOptions);
}
export type GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQueryHookResult =
  ReturnType<typeof useGetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery>;
export type GetRentLiabilitiesWithAutoPaymentDetailsAndAddressLazyQueryHookResult =
  ReturnType<
    typeof useGetRentLiabilitiesWithAutoPaymentDetailsAndAddressLazyQuery
  >;
export type GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQueryResult =
  Apollo.QueryResult<
    GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQuery,
    GetRentLiabilitiesWithAutoPaymentDetailsAndAddressQueryVariables
  >;
export const GetAutoWithdrawPlansWithWalletDocument = gql`
  query getAutoWithdrawPlansWithWallet($legalEntityId: AiloRN!) {
    autoWithdrawPlans(
      payerLegalEntityId: $legalEntityId
      enabled: true
      disableOwnerFilter: true
    ) {
      items {
        id
        enabled
        wallet {
          id
          name
          owner {
            id
            reference
          }
        }
        details {
          id
          frequency
          startDate
          anniversaryDaysOfMonth
          isLastDayOfTheMonth
        }
      }
    }
  }
`;

/**
 * __useGetAutoWithdrawPlansWithWalletQuery__
 *
 * To run a query within a React component, call `useGetAutoWithdrawPlansWithWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoWithdrawPlansWithWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoWithdrawPlansWithWalletQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetAutoWithdrawPlansWithWalletQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAutoWithdrawPlansWithWalletQuery,
    GetAutoWithdrawPlansWithWalletQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAutoWithdrawPlansWithWalletQuery,
    GetAutoWithdrawPlansWithWalletQueryVariables
  >(GetAutoWithdrawPlansWithWalletDocument, baseOptions);
}
export function useGetAutoWithdrawPlansWithWalletLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutoWithdrawPlansWithWalletQuery,
    GetAutoWithdrawPlansWithWalletQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAutoWithdrawPlansWithWalletQuery,
    GetAutoWithdrawPlansWithWalletQueryVariables
  >(GetAutoWithdrawPlansWithWalletDocument, baseOptions);
}
export type GetAutoWithdrawPlansWithWalletQueryHookResult = ReturnType<
  typeof useGetAutoWithdrawPlansWithWalletQuery
>;
export type GetAutoWithdrawPlansWithWalletLazyQueryHookResult = ReturnType<
  typeof useGetAutoWithdrawPlansWithWalletLazyQuery
>;
export type GetAutoWithdrawPlansWithWalletQueryResult = Apollo.QueryResult<
  GetAutoWithdrawPlansWithWalletQuery,
  GetAutoWithdrawPlansWithWalletQueryVariables
>;
export const CancelEmailVerificationsDocument = gql`
  mutation cancelEmailVerifications {
    cancelEmailVerifications
  }
`;
export type CancelEmailVerificationsMutationFn = Apollo.MutationFunction<
  CancelEmailVerificationsMutation,
  CancelEmailVerificationsMutationVariables
>;

/**
 * __useCancelEmailVerificationsMutation__
 *
 * To run a mutation, you first call `useCancelEmailVerificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEmailVerificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEmailVerificationsMutation, { data, loading, error }] = useCancelEmailVerificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelEmailVerificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelEmailVerificationsMutation,
    CancelEmailVerificationsMutationVariables
  >
) {
  return Apollo.useMutation<
    CancelEmailVerificationsMutation,
    CancelEmailVerificationsMutationVariables
  >(CancelEmailVerificationsDocument, baseOptions);
}
export type CancelEmailVerificationsMutationHookResult = ReturnType<
  typeof useCancelEmailVerificationsMutation
>;
export type CancelEmailVerificationsMutationResult =
  Apollo.MutationResult<CancelEmailVerificationsMutation>;
export type CancelEmailVerificationsMutationOptions =
  Apollo.BaseMutationOptions<
    CancelEmailVerificationsMutation,
    CancelEmailVerificationsMutationVariables
  >;
export const GetPaymentMethodByIdDocument = gql`
  query getPaymentMethodById($paymentMethodId: ID!, $legalEntityId: AiloRN!) {
    paymentMethodById(paymentMethodId: $paymentMethodId) {
      __typename
      id
      isOnceOff
      isAutoPay
      isAutoWithdraw
      ... on BankAccount {
        bsb
        accountName
        accountNumber
      }
      ... on CreditCard {
        expiry
        number
        type
      }
    }
    legalEntity(id: $legalEntityId) {
      __typename
      id
      ailoRN
      ... on Person {
        paymentMethods {
          pageInfo {
            total
          }
        }
      }
      ... on Company {
        paymentMethods {
          pageInfo {
            total
          }
        }
      }
    }
  }
`;

/**
 * __useGetPaymentMethodByIdQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodByIdQuery({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetPaymentMethodByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentMethodByIdQuery,
    GetPaymentMethodByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaymentMethodByIdQuery,
    GetPaymentMethodByIdQueryVariables
  >(GetPaymentMethodByIdDocument, baseOptions);
}
export function useGetPaymentMethodByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodByIdQuery,
    GetPaymentMethodByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaymentMethodByIdQuery,
    GetPaymentMethodByIdQueryVariables
  >(GetPaymentMethodByIdDocument, baseOptions);
}
export type GetPaymentMethodByIdQueryHookResult = ReturnType<
  typeof useGetPaymentMethodByIdQuery
>;
export type GetPaymentMethodByIdLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodByIdLazyQuery
>;
export type GetPaymentMethodByIdQueryResult = Apollo.QueryResult<
  GetPaymentMethodByIdQuery,
  GetPaymentMethodByIdQueryVariables
>;
export const GetWalletTotalBalanceDocument = gql`
  query getWalletTotalBalance($legalEntityId: AiloRN!) {
    legalEntity(id: $legalEntityId) {
      __typename
      id
      ailoRN
      ... on Person {
        allWalletsTotalBalance {
          cents
        }
      }
      ... on Company {
        allWalletsTotalBalance {
          cents
        }
      }
    }
  }
`;

/**
 * __useGetWalletTotalBalanceQuery__
 *
 * To run a query within a React component, call `useGetWalletTotalBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletTotalBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletTotalBalanceQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetWalletTotalBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWalletTotalBalanceQuery,
    GetWalletTotalBalanceQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWalletTotalBalanceQuery,
    GetWalletTotalBalanceQueryVariables
  >(GetWalletTotalBalanceDocument, baseOptions);
}
export function useGetWalletTotalBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletTotalBalanceQuery,
    GetWalletTotalBalanceQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWalletTotalBalanceQuery,
    GetWalletTotalBalanceQueryVariables
  >(GetWalletTotalBalanceDocument, baseOptions);
}
export type GetWalletTotalBalanceQueryHookResult = ReturnType<
  typeof useGetWalletTotalBalanceQuery
>;
export type GetWalletTotalBalanceLazyQueryHookResult = ReturnType<
  typeof useGetWalletTotalBalanceLazyQuery
>;
export type GetWalletTotalBalanceQueryResult = Apollo.QueryResult<
  GetWalletTotalBalanceQuery,
  GetWalletTotalBalanceQueryVariables
>;
export const VerifyEmailDocument = gql`
  mutation verifyEmail($emailAddress: String!, $verificationCode: String!) {
    verifyEmail(
      emailAddress: $emailAddress
      verificationCode: $verificationCode
    ) {
      __typename
      ... on VerifyEmailSuccessResponse {
        user {
          id
          person {
            id
            emailAddress
            pendingEmailAddress
          }
        }
      }
      ... on VerifyEmailErrorResponse {
        errorCode
      }
    }
  }
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >
) {
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    VerifyEmailDocument,
    baseOptions
  );
}
export type VerifyEmailMutationHookResult = ReturnType<
  typeof useVerifyEmailMutation
>;
export type VerifyEmailMutationResult =
  Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const GetCurrentPersonProfileDetailsDocument = gql`
  query getCurrentPersonProfileDetails {
    effectiveUser {
      id
      person {
        __typename
        id
        ailoRN
        kycVerified
        firstName
        legalFirstName
        legalMiddleName
        preferredName
        lastName
        photo {
          id
          url
          thumbnailUrl
        }
        emailAddress
        pendingEmailAddress
        phoneNo
        unitStreetNumber
        streetName
        suburb
        state
        postcode
        country
        birthDate
      }
    }
  }
`;

/**
 * __useGetCurrentPersonProfileDetailsQuery__
 *
 * To run a query within a React component, call `useGetCurrentPersonProfileDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentPersonProfileDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentPersonProfileDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentPersonProfileDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentPersonProfileDetailsQuery,
    GetCurrentPersonProfileDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentPersonProfileDetailsQuery,
    GetCurrentPersonProfileDetailsQueryVariables
  >(GetCurrentPersonProfileDetailsDocument, baseOptions);
}
export function useGetCurrentPersonProfileDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentPersonProfileDetailsQuery,
    GetCurrentPersonProfileDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentPersonProfileDetailsQuery,
    GetCurrentPersonProfileDetailsQueryVariables
  >(GetCurrentPersonProfileDetailsDocument, baseOptions);
}
export type GetCurrentPersonProfileDetailsQueryHookResult = ReturnType<
  typeof useGetCurrentPersonProfileDetailsQuery
>;
export type GetCurrentPersonProfileDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentPersonProfileDetailsLazyQuery
>;
export type GetCurrentPersonProfileDetailsQueryResult = Apollo.QueryResult<
  GetCurrentPersonProfileDetailsQuery,
  GetCurrentPersonProfileDetailsQueryVariables
>;
export const SendEmailVerificationCodeDocument = gql`
  mutation sendEmailVerificationCode($emailAddress: String!) {
    sendEmailVerificationCode(emailAddress: $emailAddress) {
      __typename
      ... on SendEmailVerificationSuccessResponse {
        ok
      }
      ... on SendEmailVerificationErrorResponse {
        errorCode
      }
    }
  }
`;
export type SendEmailVerificationCodeMutationFn = Apollo.MutationFunction<
  SendEmailVerificationCodeMutation,
  SendEmailVerificationCodeMutationVariables
>;

/**
 * __useSendEmailVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationCodeMutation, { data, loading, error }] = useSendEmailVerificationCodeMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useSendEmailVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >
) {
  return Apollo.useMutation<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >(SendEmailVerificationCodeDocument, baseOptions);
}
export type SendEmailVerificationCodeMutationHookResult = ReturnType<
  typeof useSendEmailVerificationCodeMutation
>;
export type SendEmailVerificationCodeMutationResult =
  Apollo.MutationResult<SendEmailVerificationCodeMutation>;
export type SendEmailVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >;
export const UpdatePersonProfileDetailsDocument = gql`
  mutation updatePersonProfileDetails(
    $input: UpdatePersonProfileDetailsInput!
  ) {
    updatePersonProfileDetails(input: $input) {
      id
      ailoRN
      kycVerified
      firstName
      legalFirstName
      legalMiddleName
      preferredName
      lastName
      photo {
        id
        url
        thumbnailUrl
      }
      emailAddress
      phoneNo
      unitStreetNumber
      streetName
      suburb
      state
      postcode
      country
      birthDate
    }
  }
`;
export type UpdatePersonProfileDetailsMutationFn = Apollo.MutationFunction<
  UpdatePersonProfileDetailsMutation,
  UpdatePersonProfileDetailsMutationVariables
>;

/**
 * __useUpdatePersonProfileDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePersonProfileDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonProfileDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonProfileDetailsMutation, { data, loading, error }] = useUpdatePersonProfileDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePersonProfileDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonProfileDetailsMutation,
    UpdatePersonProfileDetailsMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePersonProfileDetailsMutation,
    UpdatePersonProfileDetailsMutationVariables
  >(UpdatePersonProfileDetailsDocument, baseOptions);
}
export type UpdatePersonProfileDetailsMutationHookResult = ReturnType<
  typeof useUpdatePersonProfileDetailsMutation
>;
export type UpdatePersonProfileDetailsMutationResult =
  Apollo.MutationResult<UpdatePersonProfileDetailsMutation>;
export type UpdatePersonProfileDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePersonProfileDetailsMutation,
    UpdatePersonProfileDetailsMutationVariables
  >;
export const WithdrawFromWalletDocument = gql`
  mutation withdrawFromWallet($withdrawInput: WithdrawInput!) {
    withdrawFromWallet(withdrawInput: $withdrawInput) {
      status
      businessTransaction {
        paymentChannel
      }
    }
  }
`;
export type WithdrawFromWalletMutationFn = Apollo.MutationFunction<
  WithdrawFromWalletMutation,
  WithdrawFromWalletMutationVariables
>;

/**
 * __useWithdrawFromWalletMutation__
 *
 * To run a mutation, you first call `useWithdrawFromWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawFromWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawFromWalletMutation, { data, loading, error }] = useWithdrawFromWalletMutation({
 *   variables: {
 *      withdrawInput: // value for 'withdrawInput'
 *   },
 * });
 */
export function useWithdrawFromWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawFromWalletMutation,
    WithdrawFromWalletMutationVariables
  >
) {
  return Apollo.useMutation<
    WithdrawFromWalletMutation,
    WithdrawFromWalletMutationVariables
  >(WithdrawFromWalletDocument, baseOptions);
}
export type WithdrawFromWalletMutationHookResult = ReturnType<
  typeof useWithdrawFromWalletMutation
>;
export type WithdrawFromWalletMutationResult =
  Apollo.MutationResult<WithdrawFromWalletMutation>;
export type WithdrawFromWalletMutationOptions = Apollo.BaseMutationOptions<
  WithdrawFromWalletMutation,
  WithdrawFromWalletMutationVariables
>;
export const GetBillsDueSoonDocument = gql`
  query getBillsDueSoon(
    $payerAilorns: [AiloRN!]!
    $dueDate: LocalDateRangeCondition
  ) {
    bills(
      taxCategoryIdNotIn: ["MANAGEMENT_FEES"]
      paid: false
      status: [APPROVED, REFUNDED]
      dueDate: $dueDate
      payerId: $payerAilorns
      cursor: { pageSize: 10000 }
    ) {
      pageInfo {
        total
        hasNext
        nextCursor
      }
      items {
        id
        ailorn: ailoRN
        amount {
          cents
        }
      }
    }
  }
`;

/**
 * __useGetBillsDueSoonQuery__
 *
 * To run a query within a React component, call `useGetBillsDueSoonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillsDueSoonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillsDueSoonQuery({
 *   variables: {
 *      payerAilorns: // value for 'payerAilorns'
 *      dueDate: // value for 'dueDate'
 *   },
 * });
 */
export function useGetBillsDueSoonQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBillsDueSoonQuery,
    GetBillsDueSoonQueryVariables
  >
) {
  return Apollo.useQuery<GetBillsDueSoonQuery, GetBillsDueSoonQueryVariables>(
    GetBillsDueSoonDocument,
    baseOptions
  );
}
export function useGetBillsDueSoonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBillsDueSoonQuery,
    GetBillsDueSoonQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetBillsDueSoonQuery,
    GetBillsDueSoonQueryVariables
  >(GetBillsDueSoonDocument, baseOptions);
}
export type GetBillsDueSoonQueryHookResult = ReturnType<
  typeof useGetBillsDueSoonQuery
>;
export type GetBillsDueSoonLazyQueryHookResult = ReturnType<
  typeof useGetBillsDueSoonLazyQuery
>;
export type GetBillsDueSoonQueryResult = Apollo.QueryResult<
  GetBillsDueSoonQuery,
  GetBillsDueSoonQueryVariables
>;
export const GetWalletForTransferDocument = gql`
  query getWalletForTransfer($walletId: ID!) {
    walletById(walletId: $walletId) {
      id
      idempotencyKey
      availableBalance {
        cents
      }
      owner {
        id
        reference
        dueFeeAmount {
          cents
        }
      }
    }
  }
`;

/**
 * __useGetWalletForTransferQuery__
 *
 * To run a query within a React component, call `useGetWalletForTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletForTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletForTransferQuery({
 *   variables: {
 *      walletId: // value for 'walletId'
 *   },
 * });
 */
export function useGetWalletForTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWalletForTransferQuery,
    GetWalletForTransferQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWalletForTransferQuery,
    GetWalletForTransferQueryVariables
  >(GetWalletForTransferDocument, baseOptions);
}
export function useGetWalletForTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletForTransferQuery,
    GetWalletForTransferQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWalletForTransferQuery,
    GetWalletForTransferQueryVariables
  >(GetWalletForTransferDocument, baseOptions);
}
export type GetWalletForTransferQueryHookResult = ReturnType<
  typeof useGetWalletForTransferQuery
>;
export type GetWalletForTransferLazyQueryHookResult = ReturnType<
  typeof useGetWalletForTransferLazyQuery
>;
export type GetWalletForTransferQueryResult = Apollo.QueryResult<
  GetWalletForTransferQuery,
  GetWalletForTransferQueryVariables
>;
export const GetPaymentMethodForTransferDocument = gql`
  query getPaymentMethodForTransfer($paymentMethodId: ID!) {
    paymentMethodById(paymentMethodId: $paymentMethodId) {
      __typename
      ... on BankAccount {
        id
        accountName
        accountNumber
      }
    }
  }
`;

/**
 * __useGetPaymentMethodForTransferQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodForTransferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodForTransferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodForTransferQuery({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useGetPaymentMethodForTransferQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPaymentMethodForTransferQuery,
    GetPaymentMethodForTransferQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPaymentMethodForTransferQuery,
    GetPaymentMethodForTransferQueryVariables
  >(GetPaymentMethodForTransferDocument, baseOptions);
}
export function useGetPaymentMethodForTransferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPaymentMethodForTransferQuery,
    GetPaymentMethodForTransferQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPaymentMethodForTransferQuery,
    GetPaymentMethodForTransferQueryVariables
  >(GetPaymentMethodForTransferDocument, baseOptions);
}
export type GetPaymentMethodForTransferQueryHookResult = ReturnType<
  typeof useGetPaymentMethodForTransferQuery
>;
export type GetPaymentMethodForTransferLazyQueryHookResult = ReturnType<
  typeof useGetPaymentMethodForTransferLazyQuery
>;
export type GetPaymentMethodForTransferQueryResult = Apollo.QueryResult<
  GetPaymentMethodForTransferQuery,
  GetPaymentMethodForTransferQueryVariables
>;
export const GetWalletsForTransferFundsDocument = gql`
  query getWalletsForTransferFunds($legalEntityId: AiloRN!) {
    legalEntity(id: $legalEntityId) {
      __typename
      id
      ailoRN
      ... on Person {
        wallets {
          id
          name
          availableBalance {
            cents
          }
          autoWithdrawPlans(
            payerLegalEntityId: $legalEntityId
            enabled: true
            disableOwnerFilter: true
          ) {
            items {
              id
            }
          }
        }
      }
      ... on Company {
        wallets {
          id
          name
          availableBalance {
            cents
          }
          autoWithdrawPlans(
            payerLegalEntityId: $legalEntityId
            enabled: true
            disableOwnerFilter: true
          ) {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetWalletsForTransferFundsQuery__
 *
 * To run a query within a React component, call `useGetWalletsForTransferFundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletsForTransferFundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletsForTransferFundsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetWalletsForTransferFundsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWalletsForTransferFundsQuery,
    GetWalletsForTransferFundsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetWalletsForTransferFundsQuery,
    GetWalletsForTransferFundsQueryVariables
  >(GetWalletsForTransferFundsDocument, baseOptions);
}
export function useGetWalletsForTransferFundsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletsForTransferFundsQuery,
    GetWalletsForTransferFundsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetWalletsForTransferFundsQuery,
    GetWalletsForTransferFundsQueryVariables
  >(GetWalletsForTransferFundsDocument, baseOptions);
}
export type GetWalletsForTransferFundsQueryHookResult = ReturnType<
  typeof useGetWalletsForTransferFundsQuery
>;
export type GetWalletsForTransferFundsLazyQueryHookResult = ReturnType<
  typeof useGetWalletsForTransferFundsLazyQuery
>;
export type GetWalletsForTransferFundsQueryResult = Apollo.QueryResult<
  GetWalletsForTransferFundsQuery,
  GetWalletsForTransferFundsQueryVariables
>;
export const GetMyWalletsDocument = gql`
  query getMyWallets($legalEntityId: AiloRN!) {
    legalEntity(id: $legalEntityId) {
      __typename
      id
      ailoRN
      ... on Person {
        wallets {
          id
          name
          availableBalance {
            cents
          }
          totalBalance {
            cents
          }
          autoWithdrawPlans(
            payerLegalEntityId: $legalEntityId
            enabled: true
            disableOwnerFilter: true
          ) {
            items {
              id
            }
          }
        }
      }
      ... on Company {
        wallets {
          id
          name
          availableBalance {
            cents
          }
          totalBalance {
            cents
          }
          autoWithdrawPlans(
            payerLegalEntityId: $legalEntityId
            enabled: true
            disableOwnerFilter: true
          ) {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMyWalletsQuery__
 *
 * To run a query within a React component, call `useGetMyWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWalletsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetMyWalletsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyWalletsQuery,
    GetMyWalletsQueryVariables
  >
) {
  return Apollo.useQuery<GetMyWalletsQuery, GetMyWalletsQueryVariables>(
    GetMyWalletsDocument,
    baseOptions
  );
}
export function useGetMyWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyWalletsQuery,
    GetMyWalletsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetMyWalletsQuery, GetMyWalletsQueryVariables>(
    GetMyWalletsDocument,
    baseOptions
  );
}
export type GetMyWalletsQueryHookResult = ReturnType<
  typeof useGetMyWalletsQuery
>;
export type GetMyWalletsLazyQueryHookResult = ReturnType<
  typeof useGetMyWalletsLazyQuery
>;
export type GetMyWalletsQueryResult = Apollo.QueryResult<
  GetMyWalletsQuery,
  GetMyWalletsQueryVariables
>;
export const GetAllWalletsBalancesDocument = gql`
  query getAllWalletsBalances($legalEntityId: AiloRN!) {
    legalEntity(id: $legalEntityId) {
      __typename
      id
      ailoRN
      ... on Person {
        allWalletsTotalBalance {
          cents
        }
        allWalletsAvailableBalance {
          cents
        }
      }
      ... on Company {
        allWalletsTotalBalance {
          cents
        }
        allWalletsAvailableBalance {
          cents
        }
      }
    }
  }
`;

/**
 * __useGetAllWalletsBalancesQuery__
 *
 * To run a query within a React component, call `useGetAllWalletsBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllWalletsBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllWalletsBalancesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useGetAllWalletsBalancesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllWalletsBalancesQuery,
    GetAllWalletsBalancesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAllWalletsBalancesQuery,
    GetAllWalletsBalancesQueryVariables
  >(GetAllWalletsBalancesDocument, baseOptions);
}
export function useGetAllWalletsBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllWalletsBalancesQuery,
    GetAllWalletsBalancesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAllWalletsBalancesQuery,
    GetAllWalletsBalancesQueryVariables
  >(GetAllWalletsBalancesDocument, baseOptions);
}
export type GetAllWalletsBalancesQueryHookResult = ReturnType<
  typeof useGetAllWalletsBalancesQuery
>;
export type GetAllWalletsBalancesLazyQueryHookResult = ReturnType<
  typeof useGetAllWalletsBalancesLazyQuery
>;
export type GetAllWalletsBalancesQueryResult = Apollo.QueryResult<
  GetAllWalletsBalancesQuery,
  GetAllWalletsBalancesQueryVariables
>;
export const GetTotalClearedRentCountForUserDocument = gql`
  query getTotalClearedRentCountForUser($ownerRef: AiloRN!) {
    totalClearedRentReceivedInWallets(ownerRef: $ownerRef) {
      count
    }
  }
`;

/**
 * __useGetTotalClearedRentCountForUserQuery__
 *
 * To run a query within a React component, call `useGetTotalClearedRentCountForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalClearedRentCountForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalClearedRentCountForUserQuery({
 *   variables: {
 *      ownerRef: // value for 'ownerRef'
 *   },
 * });
 */
export function useGetTotalClearedRentCountForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTotalClearedRentCountForUserQuery,
    GetTotalClearedRentCountForUserQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTotalClearedRentCountForUserQuery,
    GetTotalClearedRentCountForUserQueryVariables
  >(GetTotalClearedRentCountForUserDocument, baseOptions);
}
export function useGetTotalClearedRentCountForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTotalClearedRentCountForUserQuery,
    GetTotalClearedRentCountForUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTotalClearedRentCountForUserQuery,
    GetTotalClearedRentCountForUserQueryVariables
  >(GetTotalClearedRentCountForUserDocument, baseOptions);
}
export type GetTotalClearedRentCountForUserQueryHookResult = ReturnType<
  typeof useGetTotalClearedRentCountForUserQuery
>;
export type GetTotalClearedRentCountForUserLazyQueryHookResult = ReturnType<
  typeof useGetTotalClearedRentCountForUserLazyQuery
>;
export type GetTotalClearedRentCountForUserQueryResult = Apollo.QueryResult<
  GetTotalClearedRentCountForUserQuery,
  GetTotalClearedRentCountForUserQueryVariables
>;
export const GetManagementLatestTenancyDetailsDocument = gql`
  query getManagementLatestTenancyDetails($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        liability {
          id
          reference
          effectivePaidToDate
          lastPaymentDate
        }
        currentRent {
          id
          amountInCents
          period
        }
      }
    }
  }
`;

/**
 * __useGetManagementLatestTenancyDetailsQuery__
 *
 * To run a query within a React component, call `useGetManagementLatestTenancyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementLatestTenancyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementLatestTenancyDetailsQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementLatestTenancyDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementLatestTenancyDetailsQuery,
    GetManagementLatestTenancyDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementLatestTenancyDetailsQuery,
    GetManagementLatestTenancyDetailsQueryVariables
  >(GetManagementLatestTenancyDetailsDocument, baseOptions);
}
export function useGetManagementLatestTenancyDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementLatestTenancyDetailsQuery,
    GetManagementLatestTenancyDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementLatestTenancyDetailsQuery,
    GetManagementLatestTenancyDetailsQueryVariables
  >(GetManagementLatestTenancyDetailsDocument, baseOptions);
}
export type GetManagementLatestTenancyDetailsQueryHookResult = ReturnType<
  typeof useGetManagementLatestTenancyDetailsQuery
>;
export type GetManagementLatestTenancyDetailsLazyQueryHookResult = ReturnType<
  typeof useGetManagementLatestTenancyDetailsLazyQuery
>;
export type GetManagementLatestTenancyDetailsQueryResult = Apollo.QueryResult<
  GetManagementLatestTenancyDetailsQuery,
  GetManagementLatestTenancyDetailsQueryVariables
>;
export const GetManagementLatestTenancyDetailsWithPaymentDatesDocument = gql`
  query getManagementLatestTenancyDetailsWithPaymentDates($managementId: ID!) {
    management(managementId: $managementId) {
      id
      mostRecentTenancy {
        id
        liability {
          id
          reference
          effectivePaidToDate
          firstPaymentDate
          lastPaymentDate
        }
        currentRent {
          id
          amountInCents
          period
        }
      }
    }
  }
`;

/**
 * __useGetManagementLatestTenancyDetailsWithPaymentDatesQuery__
 *
 * To run a query within a React component, call `useGetManagementLatestTenancyDetailsWithPaymentDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagementLatestTenancyDetailsWithPaymentDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagementLatestTenancyDetailsWithPaymentDatesQuery({
 *   variables: {
 *      managementId: // value for 'managementId'
 *   },
 * });
 */
export function useGetManagementLatestTenancyDetailsWithPaymentDatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagementLatestTenancyDetailsWithPaymentDatesQuery,
    GetManagementLatestTenancyDetailsWithPaymentDatesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetManagementLatestTenancyDetailsWithPaymentDatesQuery,
    GetManagementLatestTenancyDetailsWithPaymentDatesQueryVariables
  >(GetManagementLatestTenancyDetailsWithPaymentDatesDocument, baseOptions);
}
export function useGetManagementLatestTenancyDetailsWithPaymentDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagementLatestTenancyDetailsWithPaymentDatesQuery,
    GetManagementLatestTenancyDetailsWithPaymentDatesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetManagementLatestTenancyDetailsWithPaymentDatesQuery,
    GetManagementLatestTenancyDetailsWithPaymentDatesQueryVariables
  >(GetManagementLatestTenancyDetailsWithPaymentDatesDocument, baseOptions);
}
export type GetManagementLatestTenancyDetailsWithPaymentDatesQueryHookResult =
  ReturnType<typeof useGetManagementLatestTenancyDetailsWithPaymentDatesQuery>;
export type GetManagementLatestTenancyDetailsWithPaymentDatesLazyQueryHookResult =
  ReturnType<
    typeof useGetManagementLatestTenancyDetailsWithPaymentDatesLazyQuery
  >;
export type GetManagementLatestTenancyDetailsWithPaymentDatesQueryResult =
  Apollo.QueryResult<
    GetManagementLatestTenancyDetailsWithPaymentDatesQuery,
    GetManagementLatestTenancyDetailsWithPaymentDatesQueryVariables
  >;
export const GetAutoWithdrawPlansForOwnerDocument = gql`
  query getAutoWithdrawPlansForOwner(
    $walletOwnerReference: AiloRN!
    $currentUserLegalEntity: AiloRN!
  ) {
    walletByWalletOwnerReference(walletOwnerReference: $walletOwnerReference) {
      id
      autoWithdrawPlans(
        payerLegalEntityId: $currentUserLegalEntity
        enabled: true
        disableOwnerFilter: true
      ) {
        items {
          id
          enabled
        }
      }
    }
  }
`;

/**
 * __useGetAutoWithdrawPlansForOwnerQuery__
 *
 * To run a query within a React component, call `useGetAutoWithdrawPlansForOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoWithdrawPlansForOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoWithdrawPlansForOwnerQuery({
 *   variables: {
 *      walletOwnerReference: // value for 'walletOwnerReference'
 *      currentUserLegalEntity: // value for 'currentUserLegalEntity'
 *   },
 * });
 */
export function useGetAutoWithdrawPlansForOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAutoWithdrawPlansForOwnerQuery,
    GetAutoWithdrawPlansForOwnerQueryVariables
  >
) {
  return Apollo.useQuery<
    GetAutoWithdrawPlansForOwnerQuery,
    GetAutoWithdrawPlansForOwnerQueryVariables
  >(GetAutoWithdrawPlansForOwnerDocument, baseOptions);
}
export function useGetAutoWithdrawPlansForOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutoWithdrawPlansForOwnerQuery,
    GetAutoWithdrawPlansForOwnerQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetAutoWithdrawPlansForOwnerQuery,
    GetAutoWithdrawPlansForOwnerQueryVariables
  >(GetAutoWithdrawPlansForOwnerDocument, baseOptions);
}
export type GetAutoWithdrawPlansForOwnerQueryHookResult = ReturnType<
  typeof useGetAutoWithdrawPlansForOwnerQuery
>;
export type GetAutoWithdrawPlansForOwnerLazyQueryHookResult = ReturnType<
  typeof useGetAutoWithdrawPlansForOwnerLazyQuery
>;
export type GetAutoWithdrawPlansForOwnerQueryResult = Apollo.QueryResult<
  GetAutoWithdrawPlansForOwnerQuery,
  GetAutoWithdrawPlansForOwnerQueryVariables
>;
export const GetNumTransactionsDocument = gql`
  query getNumTransactions($ownerRef: AiloRN!, $pageSize: Int!) {
    walletEntriesWithStatusByOwnerRef(
      ownerRef: $ownerRef
      cursor: { pageSize: $pageSize }
    ) {
      pageInfo {
        total
      }
    }
  }
`;

/**
 * __useGetNumTransactionsQuery__
 *
 * To run a query within a React component, call `useGetNumTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNumTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNumTransactionsQuery({
 *   variables: {
 *      ownerRef: // value for 'ownerRef'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useGetNumTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNumTransactionsQuery,
    GetNumTransactionsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetNumTransactionsQuery,
    GetNumTransactionsQueryVariables
  >(GetNumTransactionsDocument, baseOptions);
}
export function useGetNumTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNumTransactionsQuery,
    GetNumTransactionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetNumTransactionsQuery,
    GetNumTransactionsQueryVariables
  >(GetNumTransactionsDocument, baseOptions);
}
export type GetNumTransactionsQueryHookResult = ReturnType<
  typeof useGetNumTransactionsQuery
>;
export type GetNumTransactionsLazyQueryHookResult = ReturnType<
  typeof useGetNumTransactionsLazyQuery
>;
export type GetNumTransactionsQueryResult = Apollo.QueryResult<
  GetNumTransactionsQuery,
  GetNumTransactionsQueryVariables
>;
export const GetPropertyWalletWithAutoWithdrawInfoDocument = gql`
  query getPropertyWalletWithAutoWithdrawInfo(
    $walletOwnerReference: AiloRN!
    $currentUserLegalEntity: AiloRN!
  ) {
    walletByWalletOwnerReference(walletOwnerReference: $walletOwnerReference) {
      id
      name
      availableBalance {
        cents
      }
      totalBalance {
        cents
      }
      owner {
        id
        dueFeeAmount {
          cents
        }
      }
      autoWithdrawPlans(
        payerLegalEntityId: $currentUserLegalEntity
        enabled: true
        disableOwnerFilter: true
      ) {
        items {
          id
          enabled
          owner
        }
      }
    }
  }
`;

/**
 * __useGetPropertyWalletWithAutoWithdrawInfoQuery__
 *
 * To run a query within a React component, call `useGetPropertyWalletWithAutoWithdrawInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPropertyWalletWithAutoWithdrawInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPropertyWalletWithAutoWithdrawInfoQuery({
 *   variables: {
 *      walletOwnerReference: // value for 'walletOwnerReference'
 *      currentUserLegalEntity: // value for 'currentUserLegalEntity'
 *   },
 * });
 */
export function useGetPropertyWalletWithAutoWithdrawInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPropertyWalletWithAutoWithdrawInfoQuery,
    GetPropertyWalletWithAutoWithdrawInfoQueryVariables
  >
) {
  return Apollo.useQuery<
    GetPropertyWalletWithAutoWithdrawInfoQuery,
    GetPropertyWalletWithAutoWithdrawInfoQueryVariables
  >(GetPropertyWalletWithAutoWithdrawInfoDocument, baseOptions);
}
export function useGetPropertyWalletWithAutoWithdrawInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPropertyWalletWithAutoWithdrawInfoQuery,
    GetPropertyWalletWithAutoWithdrawInfoQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetPropertyWalletWithAutoWithdrawInfoQuery,
    GetPropertyWalletWithAutoWithdrawInfoQueryVariables
  >(GetPropertyWalletWithAutoWithdrawInfoDocument, baseOptions);
}
export type GetPropertyWalletWithAutoWithdrawInfoQueryHookResult = ReturnType<
  typeof useGetPropertyWalletWithAutoWithdrawInfoQuery
>;
export type GetPropertyWalletWithAutoWithdrawInfoLazyQueryHookResult =
  ReturnType<typeof useGetPropertyWalletWithAutoWithdrawInfoLazyQuery>;
export type GetPropertyWalletWithAutoWithdrawInfoQueryResult =
  Apollo.QueryResult<
    GetPropertyWalletWithAutoWithdrawInfoQuery,
    GetPropertyWalletWithAutoWithdrawInfoQueryVariables
  >;
export const GetTenancyLiabilityDocument = gql`
  query getTenancyLiability($tenancyId: ID!, $payerLegalEntityId: AiloRN!) {
    tenancy(tenancyId: $tenancyId) {
      id
      liability {
        id
        reference
        paidTo
        overdueAmount {
          cents
        }
        dueDate
        nextDueDate
        nextDueAmount {
          cents
        }
        autoPaymentDetails {
          paymentMethod {
            id
          }
        }
        terminationAmountDue {
          cents
        }
        terminationDate
        paymentPlans(payerLegalEntityId: $payerLegalEntityId, enabled: true) {
          pageInfo {
            total
          }
        }
      }
      endDate
    }
  }
`;

/**
 * __useGetTenancyLiabilityQuery__
 *
 * To run a query within a React component, call `useGetTenancyLiabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyLiabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyLiabilityQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *      payerLegalEntityId: // value for 'payerLegalEntityId'
 *   },
 * });
 */
export function useGetTenancyLiabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyLiabilityQuery,
    GetTenancyLiabilityQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyLiabilityQuery,
    GetTenancyLiabilityQueryVariables
  >(GetTenancyLiabilityDocument, baseOptions);
}
export function useGetTenancyLiabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyLiabilityQuery,
    GetTenancyLiabilityQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyLiabilityQuery,
    GetTenancyLiabilityQueryVariables
  >(GetTenancyLiabilityDocument, baseOptions);
}
export type GetTenancyLiabilityQueryHookResult = ReturnType<
  typeof useGetTenancyLiabilityQuery
>;
export type GetTenancyLiabilityLazyQueryHookResult = ReturnType<
  typeof useGetTenancyLiabilityLazyQuery
>;
export type GetTenancyLiabilityQueryResult = Apollo.QueryResult<
  GetTenancyLiabilityQuery,
  GetTenancyLiabilityQueryVariables
>;
export const GetTenancyDepositDetailsDocument = gql`
  query getTenancyDepositDetails($tenancyId: ID!) {
    tenancy(tenancyId: $tenancyId) {
      id
      deposit {
        id
        status
        amount {
          cents
        }
        paidAt
        liability {
          id
          entries {
            items {
              ... on PaymentLiabilityEntry {
                businessTransaction {
                  expectedClearedAt
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetTenancyDepositDetailsQuery__
 *
 * To run a query within a React component, call `useGetTenancyDepositDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenancyDepositDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenancyDepositDetailsQuery({
 *   variables: {
 *      tenancyId: // value for 'tenancyId'
 *   },
 * });
 */
export function useGetTenancyDepositDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenancyDepositDetailsQuery,
    GetTenancyDepositDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetTenancyDepositDetailsQuery,
    GetTenancyDepositDetailsQueryVariables
  >(GetTenancyDepositDetailsDocument, baseOptions);
}
export function useGetTenancyDepositDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenancyDepositDetailsQuery,
    GetTenancyDepositDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetTenancyDepositDetailsQuery,
    GetTenancyDepositDetailsQueryVariables
  >(GetTenancyDepositDetailsDocument, baseOptions);
}
export type GetTenancyDepositDetailsQueryHookResult = ReturnType<
  typeof useGetTenancyDepositDetailsQuery
>;
export type GetTenancyDepositDetailsLazyQueryHookResult = ReturnType<
  typeof useGetTenancyDepositDetailsLazyQuery
>;
export type GetTenancyDepositDetailsQueryResult = Apollo.QueryResult<
  GetTenancyDepositDetailsQuery,
  GetTenancyDepositDetailsQueryVariables
>;
