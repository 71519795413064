import React from "react";
import { useGlobal } from "reactn";
import { PlatformFeatureId } from "local/graphql";
import { withScreenComponent } from "local/common";
import { BaseAllowNotificationsKeyActionScreen } from "./BaseAllowNotificationsKeyActionScreen";

export const AllowNotificationsKeyActionScreen = withScreenComponent(
  (): React.ReactElement => {
    const [availableFeatures] = useGlobal("availableFeatures");
    const isInvestor = availableFeatures.includes(
      PlatformFeatureId.InvestorAccess
    );
    const isTenant = availableFeatures.includes(PlatformFeatureId.TenantAccess);

    return (
      <BaseAllowNotificationsKeyActionScreen
        isInvestor={isInvestor}
        isTenant={isTenant}
      />
    );
  }
);
