import React, { FC } from "react";
import { LeftChevronIcon, HelpIcon, Text } from "@ailo/primitives";
import styled from "styled-components/native";
import { Button } from "@ailo/ui";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useOpenAuthorizedHelpInBrowser } from "@ailo/domains";
interface Props {
  scrollBackFunction?(): void;
  currentSlideIndex?: number;
}

const HeaderOverlay: FC<Props> = ({
  scrollBackFunction,
  currentSlideIndex
}) => {
  const { top } = useSafeAreaInsets();
  const openHelpInBrowser = useOpenAuthorizedHelpInBrowser();

  return (
    <OverlayContainer
      style={{
        height: top + 35
      }}
    >
      {scrollBackFunction && currentSlideIndex !== 0 ? (
        <StyledButton onPress={scrollBackFunction}>
          <LeftChevronIcon />
        </StyledButton>
      ) : null}
      <View />
      <StyledButton
        leftIcon={HelpIcon}
        onPress={openHelpInBrowser}
        style={{ alignSelf: "flex-end" }}
      >
        <Text.BodyM weight={"medium"}>{"Get Help"}</Text.BodyM>
      </StyledButton>
    </OverlayContainer>
  );
};

const OverlayContainer = styled(View)`
  position: absolute;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledButton = styled(Button.Text)`
  margin-left: 16px;
  margin-right: 16px;
`;

export { HeaderOverlay };
