import React, { FC } from "react";
import styled from "styled-components/native";
import {
  useRoute,
  Screens,
  ScreenComponent,
  useNavigation,
  useNavigateToThisScreen
} from "local/common";
import { UpcomingBillsList } from "local/domain/bill";

const UpcomingBillsScreen: FC = () => {
  const { reference, address } = useRoute<Screens.UpcomingBills>().params;

  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();
  const onBillPress = (billId: string): void => {
    navigation.navigate(Screens.Bill, {
      billId,
      onClose: returnToThisScreen
    });
  };

  return (
    <Container testID={"BillsScreen"}>
      <UpcomingBillsList
        reference={reference}
        address={address}
        onBillPress={onBillPress}
        lazyLoad
      />
    </Container>
  );
};

const Container = styled(ScreenComponent)`
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export { UpcomingBillsScreen };
