import { Screens, useNavigation, useRoute } from "local/common";
import React, { FC, useCallback } from "react";
import { DetailsEditComponent } from "../../components";
import {
  CreateLiabilityPaymentPlanInput,
  useCreateLiabilityPaymentPlanMutation
} from "local/graphql";
import { alert } from "@ailo/ui";
import { useAutoPayAnalytics } from "local/domain/liabilityPaymentPlan";
import { capitalCase } from "capital-case";

const ConfirmDetailsScreen: FC = () => {
  const navigation = useNavigation<Screens.LiabilityPlanConfirmDetails>();
  const params = useRoute<Screens.LiabilityPlanConfirmDetails>().params;
  const [createLiabilityPaymentPlan] = useCreateLiabilityPaymentPlanMutation();
  const analytics = useAutoPayAnalytics();

  const onPressPaymentMethodChange = useCallback(
    (paymentMethodId: string) => {
      navigation.navigate(Screens.LiabilityPlanConfirmDetails, {
        ...params,
        paymentMethodId
      });
    },
    [navigation, params]
  );

  const showErrorAlert = useCallback((): void => {
    navigation.setParams({ showLoadingOverlay: false });

    alert(
      "Error",
      "We were unable to create an automatic payment. Please try again and if the problem persists, contact Ailo support",
      [{ text: "Dismiss" }]
    );
  }, [navigation]);

  const onSaveClick = useCallback(
    (
      liabilityPaymentPlanInput: CreateLiabilityPaymentPlanInput
    ): Promise<any> => {
      navigation.setParams({ showLoadingOverlay: true });

      return createLiabilityPaymentPlan({
        variables: {
          createLiabilityPaymentPlanInput: liabilityPaymentPlanInput
        }
      })
        .then(({ data }) => {
          const plan = data?.createLiabilityPaymentPlan;

          if (plan) {
            analytics.track(true, capitalCase(plan.details.frequency), plan.id);
            navigation.navigate(Screens.LiabilityPlanSave, params);
          } else {
            showErrorAlert();
          }
        })
        .catch(showErrorAlert);
    },
    [navigation, showErrorAlert, analytics, createLiabilityPaymentPlan, params]
  );

  return (
    <DetailsEditComponent
      {...params}
      editAllDetails={true}
      onPaymentMethodChange={onPressPaymentMethodChange}
      onSaveClick={onSaveClick}
    />
  );
};

export { ConfirmDetailsScreen };
