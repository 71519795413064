import React, { FC, useCallback } from "react";
import { Badge, ListItem } from "@ailo/ui";
import { Screens, useRoute, useNavigation } from "local/common";
import { Colors, RightChevronIcon, Text } from "@ailo/primitives";
import { View } from "react-native";
import styled from "styled-components/native";

const SelectCreditDebitCardListItem: FC = () => {
  const { liabilityId, amount } =
    useRoute<Screens.TenancyDepositAddPaymentMethod>().params;

  const navigation = useNavigation<Screens.TenancyDepositAddPaymentMethod>();

  const addCreditDebitCard = useCallback(() => {
    const onSuccess = (paymentMethodId: string): void => {
      navigation.navigate(Screens.TenancyDepositConfirmPayment, {
        liabilityId,
        amount,
        paymentMethodId
      });
    };
    navigation.navigate(Screens.TenancyDepositEnterCreditDebitCard, {
      onSuccess
    });
  }, [navigation, liabilityId, amount]);

  return (
    <ListItem
      header={
        <FlexRowContainer>
          <Text.BodyM weight={"medium"}>{"Credit or Debit Card"}</Text.BodyM>
          <Badge type={"success"} style={{ marginLeft: 8 }}>
            {"Fastest"}
          </Badge>
        </FlexRowContainer>
      }
      subHeader={
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY} weight={"book"}>
          {"1-2 hours to clear"}
        </Text.BodyS>
      }
      testID={"credit-card-list-item"}
      onPress={addCreditDebitCard}
      style={{ alignItems: "center" }}
      rightComponent={<RightChevronIcon width={20} height={20} />}
    />
  );
};

const FlexRowContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export { SelectCreditDebitCardListItem };
