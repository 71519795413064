import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AFC } from "@ailo/primitives";

const PhoneNumberFormContext: AFC = ({ children }) => {
  const formMethods = useForm<FormData>();
  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export { PhoneNumberFormContext };
