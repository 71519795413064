import React, { FC } from "react";
import { isEmpty } from "lodash";
import { View } from "react-native";
import styled from "styled-components/native";
import { Text, Colors, opacify } from "@ailo/primitives";

interface Props {
  errors: object;
}

const ErrorBox: FC<Props> = ({ errors }) => {
  const errorMessages = Object.entries(errors).map(
    ([, value]) => value.message
  );
  const hasErrors = !isEmpty(errors);
  return hasErrors ? (
    <Container>
      {errorMessages.map((message) => (
        <ListItem key={message}>
          <Bullet>{"•"}</Bullet>
          <ListItemText>{message}</ListItemText>
        </ListItem>
      ))}
    </Container>
  ) : null;
};

const Container = styled(View)`
  background: ${opacify(Colors.STATUS.ERROR, 0.25)};
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 4px;
`;

const ListItem = styled(View)`
  flex-direction: row;
`;

const ListItemText = styled(Text.BodyS)`
  color: ${Colors.TEXT.DARK.PRIMARY};
`;

const Bullet = styled(ListItemText)`
  margin-right: 12px;
`;

export { ErrorBox };
