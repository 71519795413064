import React from "react";
import { View, ViewStyle } from "react-native";
import { Text, Colors, useSynchronisedLoad } from "@ailo/primitives";
import { useOnFocus } from "@ailo/services";
import { useGetBillAttachmentsQuery } from "local/graphql";
import { FileListItem } from "@ailo/ui";
import { isPresent } from "ts-is-present";
import { fragmentToFileDetails } from "@ailo/domains";

interface Props {
  billId: string;
  style?: ViewStyle;
}

export function BillAttachments({
  billId,
  style
}: Props): React.ReactElement | null {
  const { data, refetch, loading } = useGetBillAttachmentsQuery({
    variables: { billId }
  });

  useOnFocus(refetch);

  const isLoading = useSynchronisedLoad(loading && !data);
  if (isLoading) {
    return (
      <View style={[style, { marginBottom: 32 }]}>
        <Text.BodyM.Loading
          width={80}
          style={{ marginLeft: 16, marginBottom: 16 }}
        />
        <FileListItem.Loading />
      </View>
    );
  }

  if (!data) {
    return null;
  }

  const files = data.billById.attachments
    .map((a) => a.file)
    .map(fragmentToFileDetails)
    .filter(isPresent);

  if (files.length === 0) {
    return null;
  }

  return (
    <View style={[style, { marginBottom: 32 }]} testID={"BillAttachments"}>
      <Text.BodyM
        color={Colors.TEXT.DARK.SECONDARY}
        style={{ marginLeft: 16, marginBottom: 16 }}
      >
        {"Attachments"}
      </Text.BodyM>
      {files.map((file) => (
        <FileListItem
          key={file.id}
          fileName={file.fileName}
          fileSize={file.fileSize}
          fileUrl={file.url}
        />
      ))}
    </View>
  );
}
