import { useHasFeature } from "@ailo/domains";
import { PlatformFeatureId } from "local/graphql";
import React from "react";
import { TextInputProps, View } from "react-native";
import { FeesDisclaimerDisplay } from "../FeesDisclaimerDisplay";
import { PaymentAmountInputField } from "./components";
import { SFC, Text } from "@ailo/primitives";
import { LiabilityFormContext } from "./usePayLiabilityForm";
import { FormProvider } from "react-hook-form";

interface Props {
  context: LiabilityFormContext;
  editable: boolean;
}

interface LoadingProps {
  editable: boolean;
}

interface Statics {
  Loading: SFC<LoadingProps>;
}

const PaymentAmountInput: SFC<Props & TextInputProps> & Statics = ({
  style,
  context,
  editable,
  ...rest
}) => {
  const enableFees = useHasFeature(PlatformFeatureId.TransactionFees);
  const { amountWithoutFeesDisplay, amountWithFeesDisplay } = context.amounts;

  return (
    <View style={style}>
      {editable ? (
        <FormProvider {...context.form}>
          <PaymentAmountInputField
            accessibilityLabel={"payment-amount-input"}
            {...rest}
          />
        </FormProvider>
      ) : (
        <Text.DisplayL>{amountWithoutFeesDisplay}</Text.DisplayL>
      )}

      {enableFees && (
        <FeesDisclaimerDisplay
          dollarAmount={amountWithFeesDisplay}
          style={{ marginTop: 16 }}
        />
      )}
    </View>
  );
};

const Loading: SFC<LoadingProps> = ({ style, editable }) => {
  const enableFees = useHasFeature(PlatformFeatureId.TransactionFees);

  return (
    <View style={style}>
      {editable ? (
        <PaymentAmountInputField.Loading />
      ) : (
        <Text.DisplayL.Loading width={80} />
      )}

      {enableFees && (
        <FeesDisclaimerDisplay.Loading style={{ marginTop: 16 }} />
      )}
    </View>
  );
};

PaymentAmountInput.Loading = Loading;

export { PaymentAmountInput };
