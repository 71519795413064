import React from "react";
import styled from "styled-components/native";
import { AddOnceOffBankAccountListItem } from "./components";
import { withScreenComponent, Screens, useRoute } from "local/common";
import { ListItemHeader } from "@ailo/ui";
import { Colors, Text } from "@ailo/primitives";

const OtherPaymentMethodsScreen = withScreenComponent(
  (): React.ReactElement => {
    const { onSuccess } = useRoute<Screens.OtherPaymentMethodsScreen>().params;

    return (
      <>
        <ListItemHeader text={"Other"} />
        <AddOnceOffBankAccountListItem onSuccess={onSuccess} />
        <Disclaimer color={Colors.TEXT.DARK.SECONDARY}>
          {`The one off transfer method will not be stored for next time when you need to pay.`}
        </Disclaimer>
      </>
    );
  }
);

const Disclaimer = styled(Text.BodyM)`
  padding: 12px 16px 16px;
`;

export { OtherPaymentMethodsScreen };
