import React from "react";
import { CloseIcon, Colors, opacify } from "@ailo/primitives";
import { View, ViewStyle } from "react-native";
import { Button } from "@ailo/ui";
import styled from "styled-components/native";

interface Props {
  onClose: () => void;
  hideShadow?: boolean;
  size?: "small" | "medium";
  containerStyle?: ViewStyle;
  buttonStyle?: ViewStyle;
}

export const CloseButton: React.FC<Props> = ({
  onClose,
  hideShadow,
  size = "medium",
  containerStyle,
  buttonStyle
}) => {
  const Container = hideShadow ? BorderContainer : ShadowContainer;
  const containerSize = size == "medium" ? 32 : 24;
  const iconSize = size == "medium" ? 20 : 16;

  return (
    <Container
      style={{
        overflow: "visible",
        zIndex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
        minWidth: containerSize,
        minHeight: containerSize,
        maxWidth: containerSize,
        maxHeight: containerSize,
        width: containerSize,
        height: containerSize,
        borderRadius: containerSize,
        ...containerStyle
      }}
    >
      <Button.Secondary
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
          margin: 0,
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 0,
          marginRight: 0,
          minWidth: containerSize,
          minHeight: containerSize,
          maxWidth: containerSize,
          maxHeight: containerSize,
          width: containerSize,
          height: containerSize,
          borderRadius: containerSize,
          borderWidth: hideShadow ? 1 : 0,
          ...buttonStyle
        }}
        onPress={onClose}
      >
        <CloseIcon width={iconSize} height={iconSize} />
      </Button.Secondary>
    </Container>
  );
};

const ShadowContainer = styled(View)`
  box-shadow: 0 2px 12px ${opacify(Colors.SPACE_BLACK, 0.2)};
`;

const BorderContainer = styled(View)`
  border: solid 1px ${opacify(Colors.SPACE_BLACK, 0.1)};
`;
