import React, { FC } from "react";
import { SetupAutoRentPayment } from "@ailo/primitives";
import { FeatureShowcaseSlide } from "local/domain/onboarding";

const Slide: FC = () => {
  return (
    <FeatureShowcaseSlide
      titleText={`Would you like to setup\nan auto rent payment?`}
      descriptionText={
        "Pay your rent automatically when it's due, or setup a schedule that works for you."
      }
      imageSource={SetupAutoRentPayment}
    />
  );
};

export { Slide };
