import { AiloRN } from "@ailo/ailorn";
import { formatAddress } from "@ailo/domain-helpers";
import { Colors, PropertyIcon, SFC, WalletIcon } from "@ailo/primitives";
import { didQueryNotLoadYet, useEnvironment } from "@ailo/services";
import { Alert, BoxWithShadow, ListItem, ListItemHeader } from "@ailo/ui";
import {
  Property,
  Screens,
  useCurrentLegalEntity,
  useNavigation,
  useOnFocus,
  usePropertySelectorContext,
  useRoute,
  withScreenComponent
} from "local/common";
import { EnsureKycVerified } from "local/domain/authz";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import React, { FC, useEffect, useState } from "react";
import { View } from "react-native";
import { useGetDefaultWalletWithAutoWithdrawInfoQuery } from "local/graphql";

const useWalletSelectorData = (
  walletOwnerRef: AiloRN
): {
  result: ReturnType<typeof useGetDefaultWalletWithAutoWithdrawInfoQuery>;
  enabledAlready: boolean;
} => {
  const [legalEntity] = useCurrentLegalEntity();
  const result = useGetDefaultWalletWithAutoWithdrawInfoQuery({
    variables: {
      walletOwnerReference: walletOwnerRef.toString(),
      currentUserLegalEntity: legalEntity.id.toString()
    }
  });

  const enabledAlready =
    !!result?.data?.walletByWalletOwnerReference?.autoWithdrawPlans?.items?.find(
      (p) => p.enabled
    );

  return {
    result,
    enabledAlready
  };
};

const PropertyWalletSelectorItem: FC<{
  property: Property;
  onPress: (id: string | undefined) => void;
  onEnabled: () => void;
}> = ({ property, onPress, onEnabled }) => {
  const { result, enabledAlready } = useWalletSelectorData(
    property.management.id
  );

  useOnFocus(result?.refetch);

  useEffect(() => {
    !enabledAlready && onEnabled();
  }, [onEnabled, enabledAlready]);

  if (didQueryNotLoadYet(result)) {
    return <ListItem.Loading />;
  }

  if (enabledAlready) {
    return null;
  }

  return (
    <ListItem
      header={formatAddress(property?.address, { format: "street" })}
      subHeader={property?.address?.suburb}
      leftComponent={
        <BoxWithShadow style={{ marginRight: 16 }}>
          <PropertyIcon color={Colors.TEXT.DARK.PLACEHOLDER} />
        </BoxWithShadow>
      }
      onPress={() => onPress(result.data?.walletByWalletOwnerReference?.id)}
    />
  );
};

const SelectWalletScreenContent = (): React.ReactElement => {
  const navigation = useNavigation<Screens.AutoWithdrawPlanSelectWallet>();
  const params = useRoute<Screens.AutoWithdrawPlanSelectWallet>().params;
  const [legalEntity] = useCurrentLegalEntity();
  const { allProperties } = usePropertySelectorContext();
  const { ENABLE_AUTO_TRANSFER_FROM_PERSONAL_WALLET } = useEnvironment();
  const [anyPropertyWalletItemEnabled, setAnyPropertyWalletItemEnabled] =
    useState(false);
  const { enabledAlready, result } = useWalletSelectorData(legalEntity.id);
  useOnFocus(result?.refetch);

  const title = `Which property wallet would you like to setup an Auto Transfer from?`;

  if (didQueryNotLoadYet(result)) {
    return (
      <ScreenWithHeaderAndAction backgroundColor={"white"} header={title}>
        <ListItemHeader text={"Property wallets"} />
        <ListItem.Loading />
        <ListItemHeader text={"Personal wallets"} style={{ paddingTop: 24 }} />
        <ListItem.Loading />
      </ScreenWithHeaderAndAction>
    );
  }

  const onWalletSelected = (walletId: string | undefined): void => {
    navigation.navigate(Screens.AutoWithdrawPlanSelectPaymentDestinations, {
      ...params,
      walletId
    });
  };

  const propertiesWithFullOwnership = allProperties.filter(
    (p) => p.__typename === "OwnedProperty" && p.ownership.sharesOwnedRatio == 1
  );

  const propertyItems = propertiesWithFullOwnership.map((p) => {
    return (
      <PropertyWalletSelectorItem
        key={p.id.toString()}
        property={p}
        onEnabled={() => setAnyPropertyWalletItemEnabled(true)}
        onPress={(walletId) => onWalletSelected(walletId)}
      />
    );
  });

  const personalWalletItem = (
    ENABLE_AUTO_TRANSFER_FROM_PERSONAL_WALLET ? [legalEntity.id] : []
  )
    .filter(() => !enabledAlready)
    .map((le) => {
      return (
        <ListItem
          key={le.toString()}
          header={"Your personal wallet"}
          leftComponent={
            <View style={{ marginRight: 18 }}>
              <WalletIcon color={Colors.BLACK} />
            </View>
          }
          onPress={() =>
            onWalletSelected(result?.data?.walletByWalletOwnerReference?.id)
          }
        />
      );
    });

  return (
    <ScreenWithHeaderAndAction backgroundColor={"white"} header={title}>
      {anyPropertyWalletItemEnabled && (
        <ListItemHeader text={"Property wallets"} />
      )}
      {propertyItems}
      {!!personalWalletItem.length && (
        <ListItemHeader
          text={"Personal wallets"}
          style={{ paddingTop: anyPropertyWalletItemEnabled ? 24 : 0 }}
        />
      )}
      {personalWalletItem}
      {!anyPropertyWalletItemEnabled && !personalWalletItem.length && (
        <AllSetupInfo style={{ marginHorizontal: 16 }} />
      )}
    </ScreenWithHeaderAndAction>
  );
};

const AllSetupInfo: SFC = ({ style }) => (
  <Alert type={"neutral"} style={style}>
    {"Auto transfers already setup"}
  </Alert>
);

export const SelectWalletScreen = withScreenComponent(
  (): React.ReactElement => {
    return (
      <EnsureKycVerified>
        <SelectWalletScreenContent />
      </EnsureKycVerified>
    );
  }
);
