import React, { FC, useCallback } from "react";
import { ViewStyle } from "react-native";
import { useNavigation, Screens, useOnFocus } from "local/common";
import { Card } from "@ailo/ui";
import { WalletOwnerTransactionsList } from "@ailo/domains";
import { MyTransactionsHeader, ViewAllTransactionsButton } from "./components";
import { useGetNumTransactionsQuery } from "local/graphql";

interface Props {
  ownerRef: string;
  style?: ViewStyle;
}

interface Statics {
  Loading: FC<{ style?: ViewStyle }>;
  Empty: FC<{ style?: ViewStyle }>;
}

const Loading: FC<{ style?: ViewStyle }> = ({ style }) => {
  return (
    <Card style={style}>
      <MyTransactionsHeader />
      <WalletOwnerTransactionsList.Loading length={3} />
      <ViewAllTransactionsButton.Loading />
    </Card>
  );
};

const Empty: FC<{ style?: ViewStyle }> = ({ style }) => {
  return (
    <Card style={style}>
      <MyTransactionsHeader />
      <WalletOwnerTransactionsList.Empty style={{ marginBottom: 20 }} />
    </Card>
  );
};

const TransactionsCard: FC<Props> & Statics = ({ ownerRef, style }) => {
  const navigation = useNavigation();
  const navigateToAllTransactions = useCallback(() => {
    navigation.navigate(Screens.AllInvestorTransactions, {
      ownerRef
    });
  }, [navigation, ownerRef]);

  const { loading, data, refetch } = useGetNumTransactionsQuery({
    variables: { ownerRef, pageSize: 5 }
  });

  useOnFocus(refetch);

  if (!data && loading) {
    return <Loading style={style} />;
  }

  const totalTransactions =
    data?.walletEntriesWithStatusByOwnerRef?.pageInfo?.total;

  if (!totalTransactions) {
    return <Empty style={style} />;
  }

  return (
    <Card style={style}>
      <MyTransactionsHeader />
      <WalletOwnerTransactionsList
        ownerRef={ownerRef}
        length={3}
        bounces={false}
      />
      <ViewAllTransactionsButton onPress={navigateToAllTransactions} />
    </Card>
  );
};

TransactionsCard.Loading = Loading;
TransactionsCard.Empty = Empty;

export { TransactionsCard };
