import * as accounting from "accounting";

/**
 * @deprecated Use `Money` from `@ailo/ui` instead.
 */
class MoneyDeprecated {
  constructor(private cents: number) {}

  public format(): string {
    return `${this.cents < 0 ? "-" : ""}${accounting.formatMoney(
      accounting.toFixed(Math.abs(this.cents) / 100, 2),
      {
        thousand: ""
      }
    )}`;
  }
}

export { MoneyDeprecated };
