import React from "react";
import { View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import { Colors, HouseWithGradient, StyleProps, Text } from "@ailo/primitives";
import { Card } from "@ailo/ui";

interface Props {
  style?: StyleProps<ViewStyle>;
}

export function EmptyRentHistoryCard({ style }: Props): React.ReactElement {
  return (
    <Card style={style}>
      <Container>
        <Text.BodyM weight={"medium"}>{"No Rent History"}</Text.BodyM>
        <Text.BodyS color={Colors.TEXT.DARK.SECONDARY} style={{ marginTop: 4 }}>
          {"Your rent payments will appear here"}
        </Text.BodyS>
      </Container>
      <HouseWithGradient
        style={{ height: 80, marginTop: 8 }}
        houseProps={{
          width: 117,
          height: 80
        }}
      />
      <BottomBar />
    </Card>
  );
}

const Container = styled(View)`
  align-items: center;
  padding-top: 20px;
  padding-bottom: 36px;
`;

const BottomBar = styled(View)`
  height: 12px
  background-color: rgba(204, 216, 237, 1);
`;
