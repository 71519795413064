import { AFC } from "@ailo/primitives";
import { withScreenComponent, useOnFocus } from "@ailo/services";
import { SpinnerOverlay } from "@ailo/ui";
import { useLayout } from "@react-native-community/hooks";
import {
  useRoute,
  Screens,
  useTitle,
  useAnalytics,
  useCurrentLegalEntity
} from "local/common";
import React, { useState, useEffect } from "react";
import { SafeAreaView, ScrollView } from "react-native";
import styled from "styled-components/native";
import { PaymentMethodListItem, formatPaymentMethodName } from "@ailo/domains";
import {
  PaymentMethodDetailsError,
  RemoveListItem,
  RemovePaymentMethodBottomSheet
} from "./components";
import { useGetPaymentMethodByIdQuery } from "local/graphql";

export const PaymentMethodDetailsScreen = withScreenComponent(
  (): React.ReactElement => {
    const [legalEntity] = useCurrentLegalEntity();
    const { onLayout } = useLayout();
    const { params } = useRoute<Screens.PaymentMethodDetails>();
    const { paymentMethodId, title: titleFromRouteParams } = params;

    const [isRemoveBottomSheetOpen, setIsRemoveBotttomSheetOpen] =
      useState(false);

    const analytics = useAnalytics();
    useEffect(() => {
      if (isRemoveBottomSheetOpen) {
        analytics.trackScreenVisited("Remove Payment Method Confirmation");
      }
    }, [analytics, isRemoveBottomSheetOpen]);

    const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

    const handleRemovePress = (): void => {
      setIsRemoveBotttomSheetOpen(true);
    };

    const handleRemoveFetchStart = (): void => setShowLoadingOverlay(true);
    const handleRemoveFetchSuccess = (): void => setShowLoadingOverlay(false);
    const handleRemoveFetchError = (): void => setShowLoadingOverlay(false);

    const handleOnClose = (): void => {
      setIsRemoveBotttomSheetOpen(false);
    };

    const { data, loading, refetch } = useGetPaymentMethodByIdQuery({
      variables: { paymentMethodId, legalEntityId: legalEntity.id.toString() }
    });

    const numPaymentMethods =
      data?.legalEntity?.paymentMethods?.pageInfo?.total;
    const isAutoPay = data?.paymentMethodById?.isAutoPay;
    const isAutoWithdraw = data?.paymentMethodById?.isAutoWithdraw;

    const paymentMethodDetails = data?.paymentMethodById;

    useOnFocus(refetch);

    const paymentMethodName =
      !loading && paymentMethodDetails
        ? formatPaymentMethodName(paymentMethodDetails)
        : titleFromRouteParams || "";

    useTitle(paymentMethodName);

    if (loading) {
      return <Loading />;
    }

    if (
      numPaymentMethods == null ||
      isAutoPay == null ||
      isAutoWithdraw == null ||
      paymentMethodDetails == null
    ) {
      return <Error onRetry={refetch} />;
    }

    return (
      <>
        <StyledSafeAreaView>
          <ScrollView onLayout={onLayout}>
            <PaymentMethodListItem
              key={paymentMethodId}
              paymentMethodId={paymentMethodId}
              showAutoBadges={true}
              style={{ paddingVertical: 20 }}
            />
            <RemoveListItem onPress={handleRemovePress} />
          </ScrollView>
        </StyledSafeAreaView>
        <RemovePaymentMethodBottomSheet
          open={isRemoveBottomSheetOpen}
          onClose={handleOnClose}
          numPaymentMethods={numPaymentMethods}
          isAutoPay={isAutoPay}
          isAutoWithdraw={isAutoWithdraw}
          paymentMethodDetails={paymentMethodDetails}
          onRemoveFetchStart={handleRemoveFetchStart}
          onRemoveFetchSuccess={handleRemoveFetchSuccess}
          onRemoveFetchError={handleRemoveFetchError}
        />
        {showLoadingOverlay && <SpinnerOverlay />}
      </>
    );
  }
);

const Loading: AFC = () => {
  const { onLayout } = useLayout();
  return (
    <StyledSafeAreaView>
      <ScrollView onLayout={onLayout}>
        <PaymentMethodListItem.Loading />
        <RemoveListItem.Loading />
      </ScrollView>
    </StyledSafeAreaView>
  );
};

const Error: AFC<{ onRetry: () => void }> = ({ onRetry }) => {
  return (
    <StyledSafeAreaView>
      <PaymentMethodDetailsError onRetry={onRetry} />
    </StyledSafeAreaView>
  );
};

const StyledSafeAreaView = styled(SafeAreaView)`
  flex: 1;
  background: white;
`;
