import {
  Screens,
  useNavCloseButton,
  useNavigation,
  useNavigateToThisScreen,
  useRoute
} from "local/common";
import React, { FC, useCallback } from "react";
import { View } from "react-native";
import { SaveAutoPayRentButton, TurnOffAutoPayRentButton } from "./components";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import { useGetAutoPayTenancyDetails } from "./hooks";
import { useGetPaymentMethodWithFees } from "local/domain/ledger/hooks";
import {
  FeeCalculator,
  FeeUIHelpers,
  PaymentMethodListItem
} from "@ailo/domains";
import { FeesDisclaimerDisplay } from "../PayBillLiabilityScreen/components/PaymentDisplay/components/FeesDisclaimerDisplay";
import {
  EditableListItemWithLeftHeading,
  ListItemValue,
  Separator
} from "@ailo/ui";

const SetupAutoPayRentScreen: FC = () => {
  const navigation = useNavigation<Screens.SetupAutoPayRent>();
  const { params, name } = useRoute<Screens.SetupAutoPayRent>();
  const {
    paymentMethodId,
    liabilityId,
    tenancyId,
    showLoadingOverlay,
    autoPaymentEnabled,
    onSuccess
  } = params;

  useNavCloseButton();
  const returnToThisScreen = useNavigateToThisScreen();

  const { loading, address, nextChargeAmount } = useGetAutoPayTenancyDetails({
    tenancyId
  });

  const { topUpFee } = useGetPaymentMethodWithFees(paymentMethodId || "");
  const feeCalculator = new FeeCalculator(topUpFee);
  const dueCents = feeCalculator.apply(nextChargeAmount || 0);

  const onPaymentMethodEditPress = useCallback((): void => {
    navigation.navigate(Screens.ChangePaymentMethod, {
      onClose: returnToThisScreen,
      onSuccess: (paymentMethodId: string): void => {
        navigation.navigate(name, { ...params, paymentMethodId });
      }
    });
  }, [name, params, navigation, returnToThisScreen]);

  const saveButton = (
    <SaveAutoPayRentButton
      onSuccess={onSuccess}
      paymentMethodId={paymentMethodId}
      liabilityId={liabilityId}
      autoPaymentEnabled={autoPaymentEnabled}
    />
  );

  const turnOffButton = autoPaymentEnabled && (
    <TurnOffAutoPayRentButton
      liabilityId={liabilityId}
      loading={showLoadingOverlay || false}
      onClose={onSuccess}
    />
  );

  const body = (
    <View>
      <Separator />
      <EditableListItemWithLeftHeading name={"Property"}>
        {!loading && <ListItemValue value={address || "Unknown"} />}
        {loading && <ListItemValue.Loading />}
      </EditableListItemWithLeftHeading>

      <EditableListItemWithLeftHeading name={"Amount"}>
        {!loading && (
          <ListItemValue
            value={FeeUIHelpers.format(nextChargeAmount || 0) || "Unknown"}
          />
        )}
        {loading && <ListItemValue.Loading />}
        <FeesDisclaimerDisplay
          dollarAmount={FeeUIHelpers.format(dueCents)}
          addLineBreakBeforeLink={true}
        />
      </EditableListItemWithLeftHeading>

      <EditableListItemWithLeftHeading
        name={"Payment Method"}
        editable={true}
        onEditClick={onPaymentMethodEditPress}
      >
        <PaymentMethodListItem.Small
          paymentMethodId={paymentMethodId || "Unknown"}
        />
      </EditableListItemWithLeftHeading>

      <EditableListItemWithLeftHeading name={"Frequency"}>
        <ListItemValue value={"When rent is due"} />
      </EditableListItemWithLeftHeading>
    </View>
  );

  return (
    <ScreenWithHeaderAndAction
      buttons={[turnOffButton, saveButton]}
      header={autoPaymentEnabled ? undefined : "Confirm details"}
      subHeader={"Tap to edit details"}
      showLoadingOverlay={showLoadingOverlay}
    >
      {body}
    </ScreenWithHeaderAndAction>
  );
};

export { SetupAutoPayRentScreen };
