import { useHasFeature } from "@ailo/domains";
import { LeftChevronIcon } from "@ailo/primitives";
import { Button } from "@ailo/ui";
import { PaymentMethodListContainer } from "local/domain/ledger";
import { PlatformFeatureId } from "local/graphql";
import {
  Screens,
  useNavigateToThisScreen,
  useNavigation,
  useRoute
} from "local/common";
import React, { FunctionComponent, useCallback, useEffect } from "react";
import { OtherPaymentMethodListItem } from "./components";

const ChangePaymentMethodScreen: FunctionComponent = () => {
  const route = useRoute<Screens.ChangePaymentMethod>();
  const { onClose, onSuccess, showOtherPaymentOptions } = route.params;
  const navigation = useNavigation<Screens.ChangePaymentMethod>();

  const HeaderLeft = useCallback(
    () => (
      <Button.Text
        onPress={onClose}
        leftIcon={LeftChevronIcon}
        style={{ marginLeft: 16 }}
      />
    ),
    [onClose]
  );
  useEffect(() => {
    navigation.setOptions({
      headerLeft: HeaderLeft
    });
  }, [HeaderLeft, navigation]);

  const enableFeeFreeRent = useHasFeature(PlatformFeatureId.FeeFreeRent);

  const otherPaymentMethodsSection = {
    title: "Other",
    data: enableFeeFreeRent
      ? [<OtherPaymentMethodListItem key={"1"} onSuccess={onSuccess} />]
      : []
  };

  const returnToThisScreen = useNavigateToThisScreen();

  const openAddPaymentMethodModal = useCallback(() => {
    navigation.navigate(Screens.SetupPaymentMethod, {
      onClose: returnToThisScreen,
      onSuccess: returnToThisScreen
    });
  }, [navigation, returnToThisScreen]);

  return (
    <PaymentMethodListContainer
      onPaymentMethodSelection={onSuccess}
      onAddPaymentMethodPress={openAddPaymentMethodModal}
      moreSections={showOtherPaymentOptions ? [otherPaymentMethodsSection] : []}
    />
  );
};

export { ChangePaymentMethodScreen };
