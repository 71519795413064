import { useCallback } from "react";
import { Screens } from "local/common";
import {
  CommonActions,
  NavigationState,
  useNavigation
} from "@react-navigation/native";
import { useOnNavigationCompleted, useThreadContext } from "@ailo/domains";

function defaultStateToParentChatPath(
  state: NavigationState,
  parentChatId: string
): CommonActions.Action {
  if (state.routes.length !== 1 || state.routes[0].name !== Screens.Messages) {
    return CommonActions.reset(state);
  }

  return CommonActions.reset({
    index: 2,
    routes: [
      {
        name: Screens.TabNavigator,
        state: { routes: [{ name: Screens.ChatTab }] }
      },
      {
        name: Screens.Threads,
        params: { chatId: parentChatId }
      },
      {
        name: state.routes[0].name,
        key: state.routes[0].key,
        params: state.routes[0].params
      }
    ]
  });
}

export function useEnsureMessageScreenCanGoBack(): void {
  const navigation = useNavigation();
  const {
    selectedThread: { thread }
  } = useThreadContext();
  const parentChatId = thread?.parentChatAilorn.internalId;

  const focusListener = useCallback(() => {
    if (!parentChatId) return;
    navigation.dispatch((state) =>
      defaultStateToParentChatPath(state, parentChatId)
    );
  }, [navigation, parentChatId]);

  useOnNavigationCompleted(focusListener);
}
