import React, { useState, useCallback, useEffect } from "react";
import { View, StatusBar } from "react-native";
import styled from "styled-components/native";
import { SFC, Colors, opacify } from "@ailo/primitives";
import { TextInput } from "@ailo/ui";
import { Country } from "react-native-country-picker-modal";
import { CallingCode, CountryPicker } from "./components";

interface Props {
  onChange?: (phoneNumber: string) => void;
  hasError?: boolean;
}

const DEFAULT_CALLING_CODE = "61";
const DEFAULT_COUNTRY_CODE = "AU";
// the library does not provide the code for some countries
const FALLBACK_CODES: Record<string, string> = {
  Antarctica: "672"
};

export const PhoneInput: SFC<Props> = ({
  style,
  onChange,
  hasError = false
}) => {
  const [country, setCountry] = useState<Country | undefined>(undefined);
  const [callingCode, setCallingCode] = useState<string>(DEFAULT_CALLING_CODE);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [focused, setFocused] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const getCountryCallingCode = useCallback((): string => {
    if (!country) return DEFAULT_CALLING_CODE;

    const countryName = country.name.toString();
    if (Object.keys(FALLBACK_CODES).includes(countryName))
      return FALLBACK_CODES[countryName];

    return country.callingCode[0];
  }, [country]);

  useEffect((): void => {
    setCallingCode(getCountryCallingCode());
  }, [country, getCountryCallingCode]);

  useEffect((): void => {
    onChange && onChange(`+${callingCode}${phoneNumber}`);
  }, [callingCode, onChange, phoneNumber]);

  useEffect((): void => {
    StatusBar.setBarStyle(modalVisible ? "light-content" : "dark-content");
  }, [modalVisible]);

  const onCodeChange = useCallback((country: Country): void => {
    setCountry(country);
  }, []);

  const onNumberChange = useCallback((number: string): void => {
    setPhoneNumber(number);
  }, []);

  const toggleModalVisible = useCallback((state: boolean) => {
    return (): void => {
      setModalVisible(state);
    };
  }, []);

  const toggleFocused = useCallback((state: boolean) => {
    return (): void => {
      setFocused(state);
    };
  }, []);

  return (
    <StyledContainer style={style} focused={focused} hasError={hasError}>
      <CountryPicker
        countryCode={country?.cca2 || DEFAULT_COUNTRY_CODE}
        onSelect={onCodeChange}
        onOpen={toggleModalVisible(true)}
        onClose={toggleModalVisible(false)}
        modalVisible={modalVisible}
      />
      <CallingCode
        onPress={toggleModalVisible(true)}
        callingCode={callingCode}
      />
      <View style={{ flex: 1 }}>
        <StyledTextInput
          keyboardType={"number-pad"}
          maxLength={15}
          value={phoneNumber}
          digitsOnly
          placeholder={"412 345 678"}
          onFocus={toggleFocused(true)}
          onBlur={toggleFocused(false)}
          onChangeText={onNumberChange}
        />
      </View>
    </StyledContainer>
  );
};

const StyledTextInput = styled(TextInput)`
  margin: 0;
  line-height: 19.5px;
  padding-left: 8px;
  border: 0;
`;

interface ContainerProps {
  focused: boolean;
  hasError: boolean;
}

const StyledContainer = styled(View)<ContainerProps>`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 52px;
  border: ${({ focused, hasError }: ContainerProps): string =>
    hasError
      ? `2px solid ${Colors.STATUS.ERROR}`
      : focused
      ? `2px solid ${Colors.OCEAN}`
      : `1px solid ${opacify(Colors.SPACE_BLACK, 0.2)}`};
  margin: ${({ focused, hasError }: ContainerProps): string =>
    focused || hasError ? `0 -1px` : `0`};
  border-radius: 4px;
  overflow: hidden;
`;
