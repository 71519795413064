import React from "react";
import {
  AutoPayBottomSheetContent,
  AutoTransferBottomSheetContent,
  ConfirmBottomSheetContent
} from "./components";
import { LegacyBottomSheet } from "@ailo/ui";
import { PaymentMethodDomainType } from "@ailo/domains";

interface Props {
  numPaymentMethods: number;
  isAutoPay: boolean;
  isAutoWithdraw: boolean;
  paymentMethodDetails: PaymentMethodDomainType;
  open?: boolean;
  onClose?(): void;
  onRemoveFetchStart?(): void;
  onRemoveFetchSuccess?(): void;
  onRemoveFetchError?(): void;
}

export const RemovePaymentMethodBottomSheet: React.FC<Props> = ({
  numPaymentMethods,
  isAutoPay,
  isAutoWithdraw,
  paymentMethodDetails,
  open,
  onClose,
  onRemoveFetchStart,
  onRemoveFetchSuccess,
  onRemoveFetchError
}) => {
  const renderContent = (): React.ReactElement => {
    if (isAutoPay) return <AutoPayBottomSheetContent onClose={onClose} />;
    if (isAutoWithdraw)
      return <AutoTransferBottomSheetContent onClose={onClose} />;
    return (
      <ConfirmBottomSheetContent
        onClose={onClose}
        paymentMethodDetails={paymentMethodDetails}
        onRemoveFetchStart={onRemoveFetchStart}
        onRemoveFetchSuccess={onRemoveFetchSuccess}
        onRemoveFetchError={onRemoveFetchError}
        isLastPaymentMethod={numPaymentMethods === 1}
      />
    );
  };

  return (
    <LegacyBottomSheet open={open} onClose={onClose}>
      {renderContent()}
    </LegacyBottomSheet>
  );
};
