import { AiloRN, ID } from "@ailo/ailorn";
import { applyFeatureOverride } from "@ailo/domains";
import {
  didQueryNotLoadYet,
  MappedQueryResult,
  useAuth,
  useEnvironment
} from "@ailo/services";
import { flatMap } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { Linking } from "react-native";
import usePromise from "react-promise";
import { getGlobal, setGlobal, useGlobal } from "reactn";
import { State as GlobalState } from "reactn/default";
import { SetupData, useGetSetupData } from "./useGetSetupData";

export type { SetupData };

function getContacts(
  data: SetupData
): Map<ID<"authz:organisation">, AiloRN<"contact:contact"> | undefined> {
  const contacts = new Map<
    ID<"authz:organisation">,
    AiloRN<"contact:contact"> | undefined
  >();

  const toContactAilorn = (
    ailorn: string | undefined
  ): AiloRN<"contact:contact"> | undefined =>
    AiloRN.from(ailorn, { expected: "contact:contact" });

  if (data.managements?.items) {
    data.managements.items.forEach((mgt) => {
      if (mgt.managingEntity) {
        contacts.set(
          mgt.managingEntity.organisationId as ID<"authz:organisation">,
          toContactAilorn(
            mgt.managingEntity?.organisation?.effectiveUserContact?.ailorn
          )
        );
      }
    });
  }

  if (data.tenancies?.items) {
    data.tenancies.items.forEach((tenancy) => {
      if (tenancy.managingEntity) {
        contacts.set(
          tenancy.managingEntity.organisationId as ID<"authz:organisation">,
          toContactAilorn(
            tenancy.managingEntity?.organisation?.effectiveUserContact?.ailorn
          )
        );
      }
    });
  }

  return contacts;
}

export function useSetupData(): {
  result: MappedQueryResult<SetupData>;
  /**
   * After setup data is loaded,
   * we still need to wait until it is set in the global state and it propagates to all the component.
   * (Look for code docs on `authenticatedUserSetupDataSet` why is it needed.)
   */
  awaitingGlobalStateSetupDataSet: boolean;
} {
  const { isAuthenticated, authEventObservable } = useAuth();
  const { ENABLE_PASSWORDLESS } = useEnvironment();

  const result = useGetSetupData({
    skip: !isAuthenticated,
    notifyOnNetworkStatusChange: true
  });

  const awaitingGlobalStateSetupDataSetRef = useRef(false);

  const resetGlobalState = useCallback(
    (overrides: Partial<GlobalState> = {}) => {
      setGlobal<GlobalState>({ ...DEFAULT_GLOBAL, ...overrides });
      awaitingGlobalStateSetupDataSetRef.current = false;
    },
    []
  );

  useEffect(() => {
    const unsubscribe = authEventObservable.subscribeObserver(
      "postLogout",
      () => resetGlobalState({ usePasswordLogin: getGlobal().usePasswordLogin })
    );
    return unsubscribe;
  }, [authEventObservable, resetGlobalState]);

  useEffect(() => {
    const unsubscribe = authEventObservable.subscribeObserver("preLogin", () =>
      resetGlobalState({ usePasswordLogin: getGlobal().usePasswordLogin })
    );
    return unsubscribe;
  }, [authEventObservable, resetGlobalState]);

  useEffect(() => {
    resetGlobalState({ usePasswordLogin: !ENABLE_PASSWORDLESS });
  }, [ENABLE_PASSWORDLESS, resetGlobalState]);

  const resultLoaded = !didQueryNotLoadYet(result);

  const { value: appInitialUrl } = usePromise(Linking.getInitialURL);

  useEffect(() => {
    if (!resultLoaded) {
      return;
    }

    const data = result.data;
    if (result.error || !data) {
      setGlobal<GlobalState>({
        authenticatedUserSetupDataSet: true,
        availableFeatures: []
      });
      return;
    }
    const currentUser = data.effectiveUser;

    let availableFeatures = flatMap(currentUser.organisations, (org) =>
      org.availableFeatures.map((feature) => feature.id)
    );

    availableFeatures = applyFeatureOverride(availableFeatures, appInitialUrl);

    awaitingGlobalStateSetupDataSetRef.current = true;
    setGlobal<GlobalState>({
      authenticatedUserSetupDataSet: true,
      availableFeatures,
      currentUser: {
        id: currentUser.id,
        ailoRN: AiloRN.of("authz:user", currentUser.id).toString(),
        person: {
          ...currentUser.person,
          ailoRN: currentUser.person.ailoRN,
          firstName: currentUser.person.firstName,
          lastName: currentUser.person.lastName || undefined,
          emailAddress: currentUser.person.emailAddress || undefined
        },
        contacts: getContacts(data)
      }
    });
  }, [appInitialUrl, result.data, result.error, resultLoaded]);

  const [authenticatedUserSetupDataSet] = useGlobal(
    "authenticatedUserSetupDataSet"
  );
  const awaitingGlobalStateSetupDataSet =
    isAuthenticated && awaitingGlobalStateSetupDataSetRef.current
      ? !authenticatedUserSetupDataSet
      : false;

  return {
    result,
    awaitingGlobalStateSetupDataSet
  };
}

const DEFAULT_GLOBAL = {
  authenticatedUserSetupDataSet: false,
  availableFeatures: [],
  usePasswordLogin: false,
  cooldown: {}
};
setGlobal<GlobalState>(DEFAULT_GLOBAL);
