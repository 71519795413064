import { useAnalytics } from "local/common";

interface ReturnType {
  track: (enabled: boolean, frequency: string, id: string) => void;
}
export const useAutoPayAnalytics = (): ReturnType => {
  const analytics = useAnalytics();

  const track = (enabled: boolean, frequency: string, id: string): void => {
    analytics.track(`Auto Pay Rent Schedule ${enabled ? "Added" : "Removed"}`, {
      autoPaySchedule: frequency,
      autoPayScheduleId: id
    });
  };
  return { track };
};
