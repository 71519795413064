import { Screens, useNavigation, useRoute } from "local/common";
import React, { FC } from "react";
import {
  SelectedProperty,
  SelectPropertyScreenComponent
} from "./components/SelectPropertyScreenComponent";

const SelectLiabilityPropertyScreen: FC = () => {
  const navigation = useNavigation<Screens.LiabilityPlanSelectProperty>();
  const params = useRoute<Screens.LiabilityPlanSelectProperty>().params;

  const onPropertySelected = (tenancy: SelectedProperty): void => {
    navigation.navigate(Screens.LiabilityPlanSelectAmount, {
      ...params,
      tenancy
    });
  };

  return (
    <SelectPropertyScreenComponent onPropertySelected={onPropertySelected} />
  );
};

export { SelectLiabilityPropertyScreen };
