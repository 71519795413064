import { Colors, opacify, PropertyIcon } from "@ailo/primitives";
import { ListItem, RadioListItem, RadioListItemProps } from "@ailo/ui";
import { OwnedProperty, Property, RentedProperty } from "local/common";
import React from "react";
import { Platform, View } from "react-native";
import styled from "styled-components/native";
import { DateTimeWithTimeZone, LocalDate } from "@ailo/date";

export function PropertyListItem({
  property,
  checked,
  style,
  onPress
}: {
  property: Property;
  checked?: boolean;
  style?: RadioListItemProps["style"];
  onPress?(): void;
}): React.ReactElement {
  return (
    <RadioListItem
      header={[property.address.unitStreetNumber, property.address.streetName]
        .filter(Boolean)
        .join(" ")}
      style={{
        verticalAlignRight: "middle",
        ...style
      }}
      subHeader={getPropertySubheader(property)}
      leftComponent={
        <ShadowContainer>
          <View
            style={{
              backgroundColor: Colors.WHITE,
              borderRadius: 4,
              width: 40,
              height: 40,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <PropertyIcon
              color={Colors.TEXT.DARK.PLACEHOLDER}
              width={24}
              height={24}
            />
          </View>
        </ShadowContainer>
      }
      checked={checked}
      onPress={onPress}
    />
  );
}

PropertyListItem.Loading = function PropertyListItemLoading({
  style
}: Pick<RadioListItemProps, "style">): React.ReactElement {
  return <ListItem.Loading leftComponent style={style} />;
};

function getPropertySubheader(property: Property): string {
  return property.__typename === "RentedProperty"
    ? getRentedPropertySubheader(property)
    : getOwnedPropertySubheader(property);
}

function getRentedPropertySubheader(property: RentedProperty): string {
  return `My Home ${getFormerPropertyDescription(
    property.tenancy.endDate,
    property.agency.timezone
  )}`;
}

function getOwnedPropertySubheader(property: OwnedProperty): string {
  return `Investment ${getFormerPropertyDescription(
    property.management.endDate,
    property.agency.timezone
  )}`;
}

function getFormerPropertyDescription(
  endDate?: string,
  timezone?: string
): string {
  const now = DateTimeWithTimeZone.now(timezone).toLocalDate();
  const endLocalDate = endDate ? new LocalDate(endDate) : undefined;
  return endLocalDate && endLocalDate.isBefore(now) ? "· Former" : "";
}

const ShadowContainer = styled(View)`
  box-shadow: 0 2px 2px ${opacify(Colors.SPACE_BLACK, 0.2)};
  ${Platform.select({
    android: `elevation: 2;`
  }) || ""};
  margin-right: 16px;
`;
