import React, { FC } from "react";
import { Alert } from "@ailo/ui";
import { ScreenWithHeaderAndAction } from "local/domain/liabilityPaymentPlan";
import { Screens, useNavigation } from "local/common";
import { useNavigateToThisScreen } from "@ailo/services";

interface Props {
  onPaymentMethodAdded: (paymentMethodId: string) => void;
}

const AddPaymentMethodInfo: FC<Props> = ({ onPaymentMethodAdded }) => {
  const navigation = useNavigation();
  const returnToThisScreen = useNavigateToThisScreen();

  const addPaymentMethodClick = (): void => {
    navigation.navigate(Screens.SetupPaymentMethod, {
      onClose: returnToThisScreen,
      onSuccess: onPaymentMethodAdded
    });
  };

  return (
    <ScreenWithHeaderAndAction
      primaryAction={{
        text: "Add Payment Method",
        onClick: addPaymentMethodClick
      }}
    >
      <Alert type={"warning"} style={{ marginHorizontal: 16, marginTop: 20 }}>
        {"Please add a payment method first"}
      </Alert>
    </ScreenWithHeaderAndAction>
  );
};

export { AddPaymentMethodInfo };
